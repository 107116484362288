import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { useTheme } from '@mui/material';

import { ChevronDownIcon } from '@radix-ui/react-icons';

export const MultiSelect = ({ onChange, options, value, label, loading, disabled }) => {
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    MenuListProps: {
      sx: {
        pt: '2',
        pb: '0',
      },
    },
    PaperProps: {
      style: {
        color: theme.palette.baseColors.grey,
      },
      sx: {
        width: '10rem',
        maxHeight: '20rem',
        minHeight: 'auto',
        backgroundColor: '#F6F8F9',
      },
    },
  };
  const checkboxSx = {
    color: '#00000033',
    '&.Mui-checked': {
      color: '#EB8C00',
    },
  };
  const menuItemSx = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '1.75rem',
    minHeight: '1.75rem',
    fontSize: '0.875rem',
    padding: '0 1rem',
    p: 0,
    m: 0,
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: '10px',
    '&:hover': {
      backgroundColor: '#F6F8F9',
    },
    '&.Mui-selected': {
      backgroundColor: '#F6F8F9',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#F6F8F9',
    },
    '&.Mui-selected.Mui-focusVisible': {
      backgroundColor: '#F6F8F9',
    },
  };

  return (
    <FormControl sx={{ width: '100%', maxHeight: '3.25rem' }}>
      <Select
        className='slct'
        displayEmpty
        inputProps={{ 'aria-label': 'Select option' }}
        disabled={(label === 'Obligation group' && loading) || disabled}
        id='demo-multiple-checkbox'
        multiple
        value={value}
        IconComponent={ChevronDownIcon}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          `is ${
            selected.length > 0
              ? selected.join(' or ').length > 30
                ? selected.join(' or ').slice(0, 30) + '...'
                : selected.join(' or ')
              : 'any value'
          }`
        }
        MenuProps={MenuProps}
        sx={{
          height: '2rem',
          fontSize: '0.875rem',
          paddingTop: '-.25rem',
          color:
            value.length === 0
              ? theme.palette.secondaryColors[300]
              : theme.palette.baseColors.black,
          backgroundColor: value.length === 0 ? theme.palette.baseColors.white : '#F4E6D1',
          border: '1px solid #BDBEBF !important',
          "& +.Mui-focused": { border: '2px solid #EB8C00 !important' },
          "&:hover": { border: '1px solid #EB8C00 !important' },

          '& legend': { display: 'none', width: 'unset' },
          '& fieldset': { top: 0, border: 'none' },
        }}>
        {options?.map((name) => (
          <MenuItem
            sx={menuItemSx}
            key={name}
            value={name}>
            <Checkbox
              checked={value.indexOf(name) > -1}
              sx={checkboxSx}
              disableRipple
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
