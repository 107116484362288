import { SvgIcon } from '@mui/material';

export const DownArrowIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9607 12.1727L16.0152 9.34375L17.5436 10.7577L12.9607 15.0007L8.37782 10.7577L9.90617 9.34375L12.9607 12.1727Z" fill="#252C32" />
            </svg>
        </SvgIcon>
    )
};
