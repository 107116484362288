import React from "react";
import { Stack, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { RedWarningOutlinedIcon } from "../../../../components/common/CustomIcons/RedWarningOutlinedIcon";

export const TaxTreatmentDropdown = ({
  label,
  name,
  value,
  data,
  theme,
  onChange,
  error,
  relatedFields,
}) => (
  <Stack component="form" gap="0.5rem">
    <InputLabel
      sx={{
        fontWeight: 500,
        cursor: "pointer",
        color: theme.palette.baseColors.grey,
        width: "max-content",
      }}

      // required={!showError && required}
    >
      {label ?? name}&nbsp;
    </InputLabel>
    <Select
      id="tax_treatments_tax_treatment_select"
      name={name}
      value={value}
      title={value}
      onChange={onChange}
      sx={{
        backgroundColor: relatedFields?.includes(name)
          ? theme.palette.primaryColors[100]
          : "",
      }}
    >
      {data &&
        data?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
    {error && (
      <Stack direction="row" alignItems="center" gap="0.25rem">
        <RedWarningOutlinedIcon sx={{ width: "1.25rem", height: "1.25rem" }} />
        <Typography variant="body2" color={theme.palette.functional.error}>
          {error}
        </Typography>
      </Stack>
    )}
  </Stack>
);
