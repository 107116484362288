import { Stack, Typography, useTheme } from '@mui/material';
import { Modal } from '../../../components/common/Modal/Modal';
import { EditableInput } from '../../../components/common/Input/EditableInput';
import { TextArea } from '../../../components/common/Input/TextArea';
import { useEffect, useState } from 'react';
import { ReadOnlyInput } from '../../../components/common/Input/ReadOnlyInput';
import { FileUploader } from '../../../components/common/FileUploader/FileUploader';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_EXTENSIONS,
  FORMAT_TO_EXTENSIONS_MAPPING,
} from '../../../components/common/FileUploader/constants';
import { ListUploadedFiles } from './ListUploadedFiles';
import { useObligation, useUser } from '../../../context';
import { WarningPopup } from '../../../components/common/Modal/WarningPopup';
import { useNavigate } from 'react-router-dom';

const MAX_FILES_ALLOWED = 50;
const MAX_FILE_SIZE = 10240; //10 MB = 10,240 KB

export const CreateNote = ({
  tabName,
  closePopupHandler,
  obligationTaxType,
  entityCode,
  obligationId,
  noteAdditionSuccessfulHandler,
  noteAdditionFailureHandler,
}) => {
  const [noteTitle, setNoteTitle] = useState('');
  const [noteDesc, setNoteDesc] = useState('');
  const [files, setFiles] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const theme = useTheme();
  const [cancel, setCancel] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const gmtDayjs = dayjs().tz('GMT');
  const { userName, userId } = useUser();
  const { userToken } = useUser();

  const { isGroupSelected } = useObligation();

  useEffect(() => {
    setNoteTitle(tabName?.charAt(0)?.toUpperCase() + tabName?.slice(1));
  }, [tabName]);

  const sortFileDataHandler = (colName) => {
    if (colName === 'Attachment') {
      setSortColumn('Attachment name');
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...files].sort(
        (a, b) => attachmentNameSortOrder * a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setFiles(sortedData);
    } else if (colName === 'Attachment type') {
      setSortColumn('Attachment type');
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...files].sort((a, b) => {
        const extensionA = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === a.type
        )?.extension;
        const extensionB = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === b.type
        )?.extension;
        return attachmentTypeSortOrder * extensionA.localeCompare(extensionB);
      });
      setFiles(sortedData);
    }
  };

  const fileDeleteHandler = (name) => {
    const remainingFiles = [...files].filter((f) => f.name !== name);
    setFiles(remainingFiles);
  };

  const fileUploadHandler = (e) => {
    const uploadedPreviously = [...files];
    const uploadedNow = e.target.files;
    const remainingCapacity = MAX_FILES_ALLOWED - uploadedPreviously.length;
    if (uploadedNow.length > remainingCapacity) {
      setUploadError(
        `File upload failed. You can only upload a maximum of ${MAX_FILES_ALLOWED} files.`
      );
      return;
    }
    const isInvalidFileType = [...e.target.files].some((f) => {
      if (!ALLOWED_FILE_TYPES.includes(f.type) || f.name?.split('.')?.length > 2) {
        return true;
      }
      return false;
    });
    if (isInvalidFileType) {
      setUploadError(
        `File upload failed. You can only upload ${ALLOWED_EXTENSIONS.join(
          ', '
        )} file formats. Multi-extension files are not allowed.`
      );
      return;
    }
    const isInvalidFileSize = [...e.target.files].some((f) => {
      const fileSizeInKB = f.size / 1024;
      if (fileSizeInKB > MAX_FILE_SIZE) {
        return true;
      }
      return false;
    });
    if (isInvalidFileSize) {
      setUploadError(`File upload failed. You can upload files with size up to 10 MB only`);
      return;
    }
    setUploadError('');
    const totalUniqueFiles = [];
    for (const file of uploadedNow) {
      const res = uploadedPreviously.filter((f) => f.name === file.name);
      if (res?.length === 0) {
        totalUniqueFiles.push(file);
      }
    }
    setFiles((prev) => [...prev, ...totalUniqueFiles]);
  };

  const noteDescChangeHandler = (e) => {
    setNoteDesc(e.target.value);
  };

  const noteTitleChangeHandler = (e) => {
    setNoteTitle(e.target.value);
  };

  const saveHandler = () => {
    setIsSubmitted(true);
    const hasInvalidFile = files.some(
      (f) => f.name?.split('.').length > 2 || !ALLOWED_FILE_TYPES.includes(f.type)
    );
    if (noteTitle.trim().length === 0 || noteDesc.trim().length === 0 || hasInvalidFile) {
      return;
    }
    setCreating(true);
    setIsSubmitted(false);

    const dateAdded = gmtDayjs.format('YYYY-MM-DDTHH:mm:ss.SSS');
    const formData = new FormData();
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    }
    fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/note/createnote?obligation_id=${obligationId}&added_by=${userId}&note_title=${noteTitle}&note_description=${noteDesc}&relates_to=${tabName?.charAt(0)?.toUpperCase() + tabName?.slice(1)
      }&date_added=${dateAdded}&tax_type=${obligationTaxType}${isGroupSelected ? '' : '&entity=' + entityCode
      }`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: files.length > 0 ? formData : null,
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 400) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (data.status === 'Error') {
          if (data.detail.includes('Invalid document type')) {
            noteAdditionFailureHandler(data.detail);
          } else throw new Error(`Something went wrong.`);
        } else {
          noteAdditionSuccessfulHandler();
        }
      })
      .catch((err) => {
        console.error(err);
        noteAdditionFailureHandler();
      })
      .finally(() => {
        setCreating(false);
        closePopupHandler();
      });
  };
  return (
    <Modal
      title='New Note'
      submitHandler={saveHandler}
      cancelHandler={() => {
        setCancel(true);
      }}
      loading={creating}>
      <Stack
        width='100%'
        mt='2rem'
        gap='2.5rem'
        sx={{ height: '65vh', overflowY: 'scroll' }}
        pb='0.5rem'>
        <Stack
          direction='row'
          gap='1.5rem'>
          <ReadOnlyInput
            name='Relates to'
            id='relates-to'
            value={tabName?.charAt(0)?.toUpperCase() + tabName?.slice(1)}
            width='25%'
          />
          <ReadOnlyInput
            name='User'
            id='user'
            value={userName}
            width='25%'
            textTransform='capitalize'
          />
          <ReadOnlyInput
            name='Date added'
            id='date-added'
            width='25%'
            value={gmtDayjs.format('DD MMM YYYY')}
          />
          <ReadOnlyInput
            name='Date modified'
            id='date-modified'
            value='-'
            width='25%'
          />
        </Stack>
        <Stack>
          <EditableInput
            id='note-title'
            name='Note title'
            required={true}
            // showError
            placeholder='Enter note title...'
            width='34%'
            value={noteTitle}
            onChange={noteTitleChangeHandler}
            error={isSubmitted && noteTitle.trim().length === 0 ? 'This is a required field' : null}
          />
        </Stack>
        <Stack>
          <TextArea
            id='note-desc'
            name='Note description'
            rows={2}
            placeholder='Enter your note...'
            hint={`${noteDesc.length}/2000`}
            value={noteDesc}
            onChange={noteDescChangeHandler}
            maxLength={2000}
            required={true}
            error={isSubmitted && noteDesc.trim().length === 0 ? 'This is a required field.' : null}
          />
        </Stack>
        <FileUploader
          fileSize='10MB'
          errorMsg={uploadError}
          onChange={fileUploadHandler}
          disabled={files.length === MAX_FILES_ALLOWED}
        />
        <Stack gap='1rem'>
          {files?.length > 0 && (
            <>
              <Typography
                fontWeight={700}
                color={theme.palette.baseColors.grey}>
                Attachments
              </Typography>
              <ListUploadedFiles
                uploadedFiles={files}
                fileDeleteHandler={fileDeleteHandler}
                sortFileDataHandler={sortFileDataHandler}
                sortColumn={sortColumn}
                attachmentNameSortOrder={attachmentNameSortOrder}
                attachmentTypeSortOrder={attachmentTypeSortOrder}
                extensionProcessingRequired={true}
              />
            </>
          )}
        </Stack>
      </Stack>
      {cancel && (
        <WarningPopup
          closeWarningPopupHandler={() => {
            setCancel(false);
          }}
          continueHandler={() => {
            setCancel(false);
            closePopupHandler();
          }}
        />
      )}
    </Modal>
  );
};
