import { AppBar, Box, useTheme } from '@mui/material';
import { PrimaryNavbar } from '../Navbar/PrimaryNavbar';
import { SecondaryNavbar } from '../Navbar/SecondaryNavbar';
import { NAV_LINKS_MAPPING } from '../Navbar/constants';
import { useLocation } from 'react-router-dom';

export const Header = () => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const showHeader = pathname !== "/authorize" && pathname !== "/login" && pathname !== "/policy-acceptance";

    return (
        showHeader && <AppBar
            position='sticky'
            top={0}
            sx={{
                backgroundColor: theme.palette.baseColors.white,
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
            }}
        >
            <PrimaryNavbar />
            <SecondaryNavbar linksMapping={NAV_LINKS_MAPPING} />
        </AppBar>
    )
};
