import { SvgIcon } from '@mui/material';

export const TotalPurchasesSubjectToVatIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2V16H18V2H2ZM1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM13 4L16.5 7L13 10V8H9V6H13V4ZM7 14L3.5 11L7 8V10H11V12H7V14Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};