import { useEffect, useState } from 'react';
import { Skeleton, Stack, Typography, useTheme } from '@mui/material';

import { WhiteCard } from '../../WhiteCard';
import { fetchData, reconciliationBaseUrl, typographyStyle } from '../index';
import { ColorCard } from '../Utility/ColorCard';
import { Warning } from '../../../../components/common/CustomIcons/FilledWarning';
import { UnreconciledItemList } from './UnreconciledItemList';
import { ReconciledItemList } from './ReconciledItemList';
import { useReconciliation } from '../../../../context/Reconciliation/ReconciliationContext';
import { formatAmount } from '../Utility/helperFunctions';
import { summaryPageValues } from '../Constants/ReconciliationSummaryConstants';
import { useObligation, useUser } from '../../../../context';
import { Toast } from '../../Toast';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlinedIcon } from '../../../../components/common/CustomIcons/CheckCircleOutlinedIcon';

export const ReconciliationSummary = ({ summaryPage }) => {
  const theme = useTheme();
  const { userToken } = useUser();
  const { selectedObgDetails } = useObligation();
  const { modalToast } = useReconciliation();
  const [summaryData, setSummaryData] = useState({});
  const [toast, setToast] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    if (userToken && selectedObgDetails?.obligation_id) {
      setLoading(true);
      let url = '';
      switch (summaryPage) {
        case 1:
          url = `/reconciliation/trialbalancevariance?${reconciliationBaseUrl(selectedObgDetails)}`;
          break;
        case 2:
          url = `/reconciliation/vatglvariance?${reconciliationBaseUrl(selectedObgDetails)}`;
          break;
        case 3:
          url = `/reconciliation/vatglwithouttaxcodevariance?${reconciliationBaseUrl(
            selectedObgDetails
          )}`;
          break;
        case 4:
          url = `/reconciliation/inputvatwithouttaxcodevariance?${reconciliationBaseUrl(
            selectedObgDetails
          )}`;
          break;
        default:
          break;
      }
      fetchData(
        navigate,
        userToken,
        process.env.REACT_APP_BACKEND_URL + url,
        setSummaryData,
        'summary data',
        setToast,
        setLoading
      );
    }
  }, [selectedObgDetails, setSummaryData, userToken, summaryPage, modalToast]);

  return (
    <>
      <Stack
        padding='0 2.5rem'
        mb='2.5rem'
        gap='1.5rem'>
        <WhiteCard>
          <Typography sx={typographyStyle('1.125rem', 700, theme.palette.baseColors.grey)}>
            Reconciliation Summary
          </Typography>
          {loading ? (
            <Skeleton
              height={100}
              sx={{ transform: 'none' }}
            />
          ) : (
            <Stack
              direction={'row'}
              display={'flex'}
              gap={2}
              alignItems={'center'}>
              <ColorCard style={{ backgroundColor: theme.palette.random[1500] }}>
                <Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                  {summaryPageValues[summaryPage - 1]}
                </Typography>
                <Typography sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey)}>
                  {formatAmount(
                    summaryPage === 1 ? summaryData?.Trial_Balance : summaryData?.VAT_GL,
                    summaryData?.Currency_Code
                  )}
                </Typography>
              </ColorCard>
              <Typography sx={typographyStyle('1.5rem', 500, theme.palette.baseColors.grey)}>
                -
              </Typography>
              <ColorCard style={{ backgroundColor: theme.palette.random[1600] }}>
                <Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                  Transactions
                </Typography>
                <Typography sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey)}>
                  {formatAmount(summaryData?.Transactions, summaryData?.Currency_Code)}
                </Typography>
              </ColorCard>
              <Typography sx={typographyStyle('1.5rem', 500, theme.palette.baseColors.grey)}>
                =
              </Typography>
              <ColorCard
                style={
                  summaryData?.Variance === 0
                    ? {
                      border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      backgroundColor: theme.palette.secondaryColors[100],
                    }
                    : {
                      border: `1px solid ${theme.palette.functional.partial}`,
                      backgroundColor: theme.palette.functional.partialAccent,
                    }
                }>
                <Stack
                  gap={1}
                  direction={'row'}>
                  {summaryData?.Variance === 0 ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : (
                    <Warning />
                  )}
                  <Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                    Variance
                  </Typography>
                </Stack>

                <Typography sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey)}>
                  {formatAmount(summaryData?.Variance, summaryData?.Currency_Code)}
                </Typography>
              </ColorCard>
            </Stack>
          )}
        </WhiteCard>

        <UnreconciledItemList
          summaryPage={summaryPage}
          summaryData={summaryData}
        />

        <ReconciledItemList summaryPage={summaryPage} />
      </Stack>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
