export const dummyData = [
  {
    id: "14845e0b-6b51-45da-ad24-67eb2c804de1",
    source: "AP",
    flow: "Output VAT",
    invoiceno: "15485850",
    invoicedate: "2023-04-01",
    statnetamount: "95312",
    statvatamount: "9531.2",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "P1",
    taxcodedescription: "Capital Purchase Tax",
    suppliercountrycode: "AU",
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "1414 Degrees Ltd",
    statgrossamount: "104843.2",
    stattotaltaxamount: "9531.2",
    suppliername: "1414 Degrees Ltd",
    customername: null,
    entrydate: "2024-04-24",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "May202335",
    invoicelineno: 1,
    txvatamount: "9531.2",
    entitycountrycode: "AU",
    customercountrycode: null,
    glaccountno: "10001",
    glaccountdescription: "GST Receivable",
    taxpointdate: "2024-04-24",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: "Europe",
    compliancecounterpartyvatregistrationno: "16074639366",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: "Sale - Goods - Reduced Rate",
    erpreportingcountrycode: "AU",
    compliancereportingdate: "2024-04-26",
    compliancecurrencycode: "USD",
    compliancenetamount: "0",
    compliancevatamount: "0",
    compliancegrossamount: "0",
    compliancerecoverablevatamount: "0",
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: "0",
    statnonrecoverablevatamount: "0",
    filename: "AP_23012015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023May202335",
  },
  {
    id: "31123c0e-2496-4629-aba1-833492e1f155",
    source: "AR",
    flow: "Input VAT",
    invoiceno: "INV900037",
    invoicedate: "2023-04-01",
    statnetamount: "6000",
    statvatamount: "600",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "S2",
    taxcodedescription: "Sale GST Free",
    suppliercountrycode: null,
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "Adriatic Metals Plc",
    statgrossamount: "6600",
    stattotaltaxamount: "600",
    suppliername: null,
    customername: "Adriatic Metals Plc",
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "MayAR202337",
    invoicelineno: 1,
    txvatamount: "600",
    entitycountrycode: "AU",
    customercountrycode: "AU",
    glaccountno: "20001",
    glaccountdescription: "GST Payable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "11000045509",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AR_01222015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023MayAR202337",
  },
  {
    id: "3373eab8-7aec-44b3-8e75-a74572028efb",
    source: "AP",
    flow: "Output VAT",
    invoiceno: "91781954",
    invoicedate: "2023-04-01",
    statnetamount: "591",
    statvatamount: "59.1",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "P1",
    taxcodedescription: "Capital Purchase Tax",
    suppliercountrycode: "AU",
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "1414 Degrees Ltd",
    statgrossamount: "650.1",
    stattotaltaxamount: "59.1",
    suppliername: "1414 Degrees Ltd",
    customername: null,
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "May202337",
    invoicelineno: 1,
    txvatamount: "59.1",
    entitycountrycode: "AU",
    customercountrycode: null,
    glaccountno: "10001",
    glaccountdescription: "GST Receivable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "49129395946",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AP_23012015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023May202337",
  },
  {
    id: "6a8226b0-0da5-4069-830e-db8bf98005e5",
    source: "AP",
    flow: "Output VAT",
    invoiceno: "607368",
    invoicedate: "2023-04-01",
    statnetamount: "99351",
    statvatamount: "9935.1",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "P1",
    taxcodedescription: "Capital Purchase Tax",
    suppliercountrycode: "AU",
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "1414 Degrees Ltd",
    statgrossamount: "109286.1",
    stattotaltaxamount: "9935.1",
    suppliername: "1414 Degrees Ltd",
    customername: null,
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "May202333",
    invoicelineno: 1,
    txvatamount: "9935.1",
    entitycountrycode: "AU",
    customercountrycode: null,
    glaccountno: "10001",
    glaccountdescription: "GST Receivable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "65003049296",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AP_23012015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023May202333",
  },
  {
    id: "6ff4b211-b8f3-49ee-a9c2-8dc9b090a65d",
    source: "AR",
    flow: "Input VAT",
    invoiceno: "INV900033",
    invoicedate: "2023-04-01",
    statnetamount: "6000",
    statvatamount: "600",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "S2",
    taxcodedescription: "Sale GST Free",
    suppliercountrycode: "AU",
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "Adriatic Metals Plc",
    statgrossamount: "6600",
    stattotaltaxamount: "600",
    suppliername: null,
    customername: "Adriatic Metals Plc",
    entrydate: "2024-04-24",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "MayAR202333",
    invoicelineno: 1,
    txvatamount: "600",
    entitycountrycode: "AU",
    customercountrycode: "AU",
    glaccountno: "20001",
    glaccountdescription: "GST Payable",
    taxpointdate: "2024-04-06",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: "Europe",
    compliancecounterpartyvatregistrationno: "11000045509",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: "Sale - Services - Exempt",
    erpreportingcountrycode: "AU",
    compliancereportingdate: "2024-04-28",
    compliancecurrencycode: "USD",
    compliancenetamount: "0",
    compliancevatamount: "0",
    compliancegrossamount: "0",
    compliancerecoverablevatamount: "0",
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: "0",
    statnonrecoverablevatamount: "0",
    filename: "AR_01222015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023MayAR202333",
  },
  {
    id: "7720a030-289b-4e21-a9d6-3926b3cd7f28",
    source: "AP",
    flow: "Output VAT",
    invoiceno: "150805",
    invoicedate: "2023-04-01",
    statnetamount: "152",
    statvatamount: "15.2",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "P1",
    taxcodedescription: "Capital Purchase Tax",
    suppliercountrycode: "AU",
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "1414 Degrees Ltd",
    statgrossamount: "167.2",
    stattotaltaxamount: "15.2",
    suppliername: "1414 Degrees Ltd",
    customername: null,
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "May202340",
    invoicelineno: 1,
    txvatamount: "15.2",
    entitycountrycode: "AU",
    customercountrycode: null,
    glaccountno: "10001",
    glaccountdescription: "GST Receivable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "37004654111",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AP_23012015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023May202340",
  },
  {
    id: "8566114f-227d-42d4-968f-f72def3e480e",
    source: "AP",
    flow: "Output VAT",
    invoiceno: "LB500621",
    invoicedate: "2023-04-01",
    statnetamount: "315.77",
    statvatamount: "31.577",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "P1",
    taxcodedescription: "Capital Purchase Tax",
    suppliercountrycode: "AU",
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "1414 Degrees Ltd",
    statgrossamount: "347.347",
    stattotaltaxamount: "31.577",
    suppliername: "1414 Degrees Ltd",
    customername: null,
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "May202339",
    invoicelineno: 1,
    txvatamount: "31.58",
    entitycountrycode: "AU",
    customercountrycode: null,
    glaccountno: "10001",
    glaccountdescription: "GST Receivable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "20003279534",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AP_23012015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023May202339",
  },
  {
    id: "869e5965-162f-412c-91b9-2a596ff1f16e",
    source: "AR",
    flow: "Input VAT",
    invoiceno: "INV900038",
    invoicedate: "2023-04-01",
    statnetamount: "30",
    statvatamount: "3",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "S1",
    taxcodedescription: "Sale Tax",
    suppliercountrycode: null,
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "Aeris Environmental Ltd",
    statgrossamount: "33",
    stattotaltaxamount: "3",
    suppliername: null,
    customername: "Aeris Environmental Ltd",
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "MayAR202338",
    invoicelineno: 1,
    txvatamount: "3",
    entitycountrycode: "AU",
    customercountrycode: "AU",
    glaccountno: "20001",
    glaccountdescription: "GST Payable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "11000047950",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AR_01222015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023MayAR202338",
  },
  {
    id: "8cf6bb39-1a42-439b-ba81-d08f012eaae7",
    source: "AR",
    flow: "Input VAT",
    invoiceno: "INV900035",
    invoicedate: "2023-04-01",
    statnetamount: "6000",
    statvatamount: "600",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "S2",
    taxcodedescription: "Sale GST Free",
    suppliercountrycode: null,
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "Adriatic Metals Plc",
    statgrossamount: "6600",
    stattotaltaxamount: "600",
    suppliername: null,
    customername: "Adriatic Metals Plc",
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "MayAR202335",
    invoicelineno: 1,
    txvatamount: "600",
    entitycountrycode: "AU",
    customercountrycode: "AU",
    glaccountno: "20001",
    glaccountdescription: "GST Payable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "11000045509",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AR_01222015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023MayAR202335",
  },
  {
    id: "a2e2d4a1-1297-4c84-be61-1ba1f0ff7da9",
    source: "AR",
    flow: "Input VAT",
    invoiceno: "INV900040",
    invoicedate: "2023-04-01",
    statnetamount: "152",
    statvatamount: "15",
    entityname: "PwC AU Consulting Pty Ltd",
    taxcode: "S1",
    taxcodedescription: "Sale Tax",
    suppliercountrycode: null,
    payercountrycode: null,
    statcurrencycode: "AUD",
    compliancecounterpartycompanyname: "Affinity Energy and Health Ltd",
    statgrossamount: "167",
    stattotaltaxamount: "15",
    suppliername: null,
    customername: "Affinity Energy and Health Ltd",
    entrydate: "2023-04-01",
    obligation_id: "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
    entitycode: "PwC0001",
    fiscalyear: 2023,
    erpdocumentno: "MayAR202340",
    invoicelineno: 1,
    txvatamount: "15",
    entitycountrycode: "AU",
    customercountrycode: "AU",
    glaccountno: "20001",
    glaccountdescription: "GST Payable",
    taxpointdate: "2023-04-02",
    complianceentityvatregistrationno: "5278043357/003",
    complianceentitycompanyname: "PwC AU Consulting Pty Ltd",
    compliancereportingcountrycode: "AU",
    compliancereportingcountryregion: null,
    compliancecounterpartyvatregistrationno: "11000048760",
    compliancecounterpartycountrycode: "AU",
    taxtreatment: null,
    erpreportingcountrycode: null,
    compliancereportingdate: "2023-04-02",
    compliancecurrencycode: null,
    compliancenetamount: null,
    compliancevatamount: null,
    compliancegrossamount: null,
    compliancerecoverablevatamount: null,
    statrecoverablevatamount: "10",
    compliancenonrecoverablevatamount: null,
    statnonrecoverablevatamount: null,
    filename: "AR_01222015_OCT",
    entity_details: {
      name: "PwC AU Consulting Pty Ltd",
      code: "PwC0001",
      date_modified: "2023-02-01",
      modified_by: "Jennifer, John",
      valid_from: "2023-01-01",
      valid_to: null,
    },
    transaction_features: null,
    transactionconcatenate: "PwC00012023MayAR202340",
  },
];
