import React from "react";
import { DatePicker } from "rsuite";
import { Stack, InputLabel, Typography } from "@mui/material";
import { RedWarningOutlinedIcon } from "../CustomIcons/RedWarningOutlinedIcon";
import "./DatepickerStyle.css";

export const Datepicker = ({
  label,
  name,
  value,
  theme,
  onChange,
  error,
  gap,
  placeholder,
  isEditable = true,
}) => (
  <Stack component="form" gap={gap ?? "0.5rem"}>
    <InputLabel
      sx={{
        fontWeight: 500,
        cursor: "pointer",
        color: theme.palette.baseColors.grey,
        width: "max-content",
      }}
      // required={!showError && required}
    >
      {label ?? name}&nbsp;
    </InputLabel>
    <DatePicker
      className={`customDatePicker ${value ? "dateSelected" : ""}`}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(value) => onChange({ target: { name, value } })}
      format="dd MMM yyyy"
      editable={isEditable} // Allow or Prevent user from typing within the input
    />
    {error && (
      <Stack direction="row" alignItems="center" gap="0.25rem">
        <RedWarningOutlinedIcon sx={{ width: "1.25rem", height: "1.25rem" }} />
        <Typography variant="body2" color={theme.palette.functional.error}>
          {error}
        </Typography>
      </Stack>
    )}
  </Stack>
);
