import { SvgIcon } from '@mui/material';
import React from 'react'

export const FullscreenIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 0H20V6H18V2H14V0ZM0 0H6V2H2V6H0V0ZM18 16V12H20V18H14V16H18ZM2 16H6V18H0V12H2V16Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};
