import { SvgIcon } from '@mui/material'
import React from 'react'

export const StatsIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2.66663H14V3.99996H2V2.66663ZM2 7.33329H14V8.66663H2V7.33329ZM2 12H14V13.3333H2V12Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}