import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { BtnStandard } from '../../components/common/Buttons/BtnStandard';
import { BtnFilled } from '../../components/common/Buttons/BtnFilled';
import { useUser } from '../user';
const InactivityContext = createContext();

export const InactivityProvider = ({ children }) => {
  const timeout = 900_000;
  const promptBeforeIdle = 60_000;
  const navigate = useNavigate();

  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);
  const [openLgModal, setOpenlgModal] = useState(false);
  const [timer, setTimer] = useState(0);
  const [userHasToken, setUserHasToken] = useState(false);

  const handleClose = () => {};
  const { userToken, RefreshToken } = useUser();
  const user = localStorage.getItem('myUserid');
  const token = localStorage.getItem('myRefreshToken');
  const audit_Id = localStorage.getItem('audit_id');
  useEffect(() => {
    if (user) {
      activate();
    }
  }, [user]);

  const handleClose2 = () => {
    setOpenlgModal(false);

    onIdle();
  };

  const onIdle = () => {
    setState('Idle');
    setTimer(0);
    pause();
    setOpenlgModal(false);
    const audit_Id = localStorage.getItem('audit_id');
    fetch(process.env.REACT_APP_BACKEND_URL + `/auth/updateuseraudit?audit_id=${audit_Id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: 'inactive', event_type: 'Logged_Out' }),
    })
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        const ssoUrl = `${process.env.REACT_APP_SSO_BASE_URL}&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SSO_REDIRECT_URI}`;
        window.location.href = ssoUrl;
      });
  };

  const onActive = () => {
    setState('Active');
    setTimer(0);
    setOpen(false);
  };

  const onPrompt = () => {
    setState('Prompted');
    setTimer(0);
    setOpenlgModal(true);
  };

  const { getRemainingTime, activate, pause, resume } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []); // TODO: Empty dependency array here??

  const handleStillHere = () => {
    setOpenlgModal(false);
    setTimer(0);

    fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/auth/refreshusertoken?token=${token}&user_id=${user}&audit_id=${audit_Id}`,
      {
        method: 'GET',
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.status === 403) {
          navigate('/login');
        }
        return res.json();
      })
      .then((data) => {
        localStorage.setItem('myToken', data?.access_token);
        localStorage.setItem('myRefreshToken', data?.refresh_token);
        localStorage.setItem('myIdToken', data.id_token);
        RefreshToken(data?.access_token);
      })
      .catch((err) => {
        if (err === 'Token Expired') {
          onIdle();
          return;
        }
        console.error(err);
      });
    activate();
  };

  useEffect(() => {
    if (state === 'Active' && user && userToken) {
      const timerInterval = setInterval(() => {
        const token = localStorage.getItem('myRefreshToken');
        const audit_Id = localStorage.getItem('audit_id');
        fetch(
          process.env.REACT_APP_BACKEND_URL +
            `/auth/refreshusertoken?token=${token}&user_id=${user}&audit_id=${audit_Id}`,
          {
            method: 'GET',
            credentials: 'include',
          }
        )
          .then((res) => {
            if (res.status === 403) {
              navigate('/login');
            }
            return res.json();
          })
          .then((data) => {
            localStorage.setItem('myToken', data?.access_token);
            localStorage.setItem('myRefreshToken', data?.refresh_token);
            localStorage.setItem('myIdToken', data.id_token);
            RefreshToken(data?.access_token);
          })
          .catch((err) => {
            if (err === 'Token Expired') {
              onIdle();
              return;
            }
            console.error(err);
          });
      }, 810000); //A timer for 13 mins 30 secs 810000 mili seconds

      return () => clearInterval(timerInterval);
    }
  }, [state, user, userToken]);

  return (
    <InactivityContext.Provider value={state}>
      <>
        <Dialog
          open={openLgModal}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>
            {'Due to Security Reasons, Access Timed Out?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              You will automatically be logged out in {remaining} seconds.
              <br />
              Click 'Continue' if you want to continue working on this page.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ marginBottom: '2VH' }}>
            <BtnStandard
              btnTxt='Cancel'
              onClick={handleClose2}
            />
            <BtnFilled
              size='large'
              btnTxt='Continue'
              onClick={handleStillHere}
            />
          </DialogActions>
        </Dialog>
        {children}
      </>
    </InactivityContext.Provider>
  );
};
