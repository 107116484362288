import { Select, MenuItem, useTheme } from '@mui/material';
import { ChevronDownIcon } from '@radix-ui/react-icons';

export const SimpleSelect = (props) => {
  const theme = useTheme();
  const itemSx = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '3.875rem',
    fontSize: '0.875rem',
    padding: '1.25rem 1.5rem',
  };

  return (
    <Select
      {...props}
      IconComponent={(props) => (
        <ChevronDownIcon
          {...props}
          color={theme.palette.baseColors.grey}
        />
      )}
      sx={{
        height: '3.25rem',
        fontSize: '0.875rem',
        color: theme.palette.random[200],
        backgroundColor: theme.palette.baseColors.white,
        ...props.styling,
        // THIS OVERRIDES THE BORDER COLORS FOR THE MAIN SELECT OBJECT.
        '.MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
          borderColor: theme.palette.random[500],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
          borderColor: theme.palette.baseColors.grey,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
          borderColor: '#00000033',
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: '39.0625rem',
            '& .MuiMenuItem-root.Mui-selected': { backgroundColor: 'white !important' },
            '& .MuiMenuItem-root:hover': { backgroundColor: '#E6E8E8 !important' },
            '& .MuiMenuItem-root.Mui-selected:hover': { backgroundColor: '#E6E8E8 !important' },
            ...props.menStyling,
          },
        },
        // The Mui-selected class just wouldn't play nice, so I'm using !important. I'm sorry.
        MenuListProps: {
          sx: {
            pt: '0',
            pb: '0',
            '& .Mui-hovered': { backgroundColor: '#E6E8E8 !important' },
            '& .Mui-selected': { backgroundColor: 'white !important' },
          },
        },
      }}>
      {props.options.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={itemSx}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};
