export const EXCEPTIONS_CARD_INFO = [
    {
        noZoom: {
        title: "Tax Exception by Tax Code",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_TAX_CODE,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        },
        zoomedIn: {
        title: "Tax Exception by Tax Code",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_TAX_CODE_ZOOMEDIN,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        }
    },
    {
        noZoom: {
        title: "Tax Exception by Test",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_TEST,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        },
        zoomedIn: {
        title: "Tax Exception by Test",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_TEST_ZOOMEDIN,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        }
    },
    {
        noZoom: {
        title: "Tax Exception by Entity Code",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_ENTITY_CODE,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        },
        zoomedIn: {
        title: "Tax Exception by Entity Code",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_ENTITY_CODE_ZOOMEDIN,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        }
    },
    {
        noZoom: {
            title: "Tax Exception by Posting Period",
            dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_POSTING_PERIOD,
            extraParameters: {
                "Result+Adjustment+Status": "UNRESOLVED",
            },
            noZoomIn: true
        }
    },
    {
        noZoom: {
        title: "Tax Exception by G/L Account No",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_GL_ACCOUNT_NO,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        },
        zoomedIn: {
        title: "Tax Exception by G/L Account No",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_GL_ACCOUNT_NO_ZOOMEDIN,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        }
    },
    {
        noZoom: {
            title: "Tax Exception by Entity Country Code",
            dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_ENTITY_COUNTRY_CODE,
            extraParameters: {
                "Result+Adjustment+Status": "UNRESOLVED",
            }
        },
        zoomedIn: {
        title: "Tax Exception by Entity Country Code",
        dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS_BY_ENTITY_COUNTRY_CODE_ZOOMEDIN,
        extraParameters: {
            "Result+Adjustment+Status": "UNRESOLVED",
        }
        }
    }
]

export const TRANSACTIONS_LOADED_CARD_INFO = [
    {
        noZoom: {
            title: "Entity Codes (count)",
            dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_LOADED_ENTITY_CODES,
            height: "404px",
            doubleDecker: true
        },
        zoomedIn: {
            title: "Entity Codes (count)",
            dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_LOADED_ENTITY_CODES_ZOOMEDIN,
        }
    },
    {
        noZoom: {
            title: "Transactions Loaded This Period (count)",
            dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_LOADED_THIS_PERIOD,
            height: "976px",
            noZoomIn: true
        }
    },
    {
        noZoom: {
            title: "Transactions Loaded Last Period (count)",
            dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_LOADED_LAST_PERIOD,
            height: "976px",
            noZoomIn: true
        }
    },
]

export const TAX_BREAKDOWN_CARD_INFO = [
    {
        noZoom: {
            title: "Purchase Net & Tax Values by Tax Code",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_PURCHASE_BY_TAX_CODE,
            extraParameters: {
                "Source": "AP",
            }
        },
        zoomedIn: {
            title: "Purchase Net & Tax Values by Tax Code",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_PURCHASE_BY_TAX_CODE_ZOOMEDIN,
            extraParameters: {
                "Source": "AP",
            }
        }
    },
    {
        noZoom: {
            title: "Purchase Net & Tax Values by Tax Rate",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_PURCHASE_BY_TAX_RATE,
            extraParameters: {
                "Source": "AP",
            }
        },
        zoomedIn: {
            title: "Purchase Net & Tax Values by Tax Rate",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_PURCHASE_BY_TAX_RATE_ZOOMEDIN,
            extraParameters: {
                "Source": "AP",
            }
        }
    },
    {
        noZoom: {
            title: "Sales Net & Tax Values by Tax Code",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_SALES_BY_TAX_CODE,
            extraParameters: {
                "Source": "AR",
            }
        },
        zoomedIn: {
            title: "Sales Net & Tax Values by Tax Code",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_SALES_BY_TAX_CODE_ZOOMEDIN,
            extraParameters: {
                "Source": "AR",
            }
        }
    },
    {
        noZoom: {
            title: "Sales Net & Tax Values by Tax Rate",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_SALES_BY_TAX_RATE,
            extraParameters: {
                "Source": "AR",
            }
        },
        zoomedIn: {
            title: "Sales Net & Tax Values by Tax Rate",
            dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN_SALES_BY_TAX_RATE_ZOOMEDIN,
            extraParameters: {
                "Source": "AR",
            }
        }
    },
]

export const INCONSISTENT_CODING_CARD_INFO = [
    {
        noZoom: {
            title: "Purchase Tax Code Frequency by Net GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_PURCHASE_FREQUENCY_BY_NET
        },
        zoomedIn: {
            title: "Purchase Tax Code Frequency by Net GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_PURCHASE_FREQUENCY_BY_NET_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Supply Tax Code Frequency by Net GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_SUPPLY_FREQUENCY_BY_NET
        },
        zoomedIn: {
            title: "Supply Tax Code Frequency by Net GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_SUPPLY_FREQUENCY_BY_NET_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Purchase Tax Code Frequency by Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_PURCHASE_FREQUENCY_BY_RECOVERABLE
        },
        zoomedIn: {
            title: "Purchase Tax Code Frequency by Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_PURCHASE_FREQUENCY_BY_RECOVERABLE_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Supply Tax Code Frequency by Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_SUPPLY_FREQUENCY_BY_RECOVERABLE
        },
        zoomedIn: {
            title: "Supply Tax Code Frequency by Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_SUPPLY_FREQUENCY_BY_RECOVERABLE_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Purchase Tax Code Frequency by Non-Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_PURCHASE_FREQUENCY_BY_NON_RECOVERABLE
        },
        zoomedIn: {
            title: "Purchase Tax Code Frequency by Non-Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_PURCHASE_FREQUENCY_BY_NON_RECOVERABLE_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Supply Tax Code Frequency by Non-Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_SUPPLY_FREQUENCY_BY_NON_RECOVERABLE
        },
        zoomedIn: {
            title: "Supply Tax Code Frequency by Non-Recoverable GST GL Account Number",
            dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING_SUPPLY_FREQUENCY_BY_NON_RECOVERABLE_ZOOMEDIN
        }
    },
]

export const TRENDS_AND_VARIANCES_CARD_INFO = [
    {
        noZoom: {
            title: "Trends by Posting Period",
            dashboard: process.env.REACT_APP_LOOKER_TRENDS_AND_VARIANCES_TRENDS_BY_POSTING_PERIOD,
            height: "508px",
            doubleDecker: true,
            noZoomIn: true
        },
    },
]

export const TRANSACTION_ANALYTICS_CARD_INFO = {
    Value: [
        {
            noZoom: {
                title: "Transactions by Legal Entity Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_LEGAL_ENTITY_CODE
            },
            zoomedIn: {
                title: "Transactions by Legal Entity Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_LEGAL_ENTITY_CODE_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Posting Period",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_POSTING_PERIOD,
                noZoomIn: true
            }
        },
        {
            noZoom: {
                title: "Transaction Country Flow",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_COUNTRY_FLOW
            },
            zoomedIn: {
                title: "Transaction Country Flow",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_COUNTRY_FLOW_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Tax Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_TAX_CODE
            },
            zoomedIn: {
                title: "Transactions by Tax Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_TAX_CODE_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by G/L Account No",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_GL_ACCOUNT_NO
            },
            zoomedIn: {
                title: "Transactions by G/L Account No",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_GL_ACCOUNT_NO_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Compliance Counterparty Name",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_COMPLIANCE_COUNTERPARTY
            },
            zoomedIn: {
                title: "Transactions by Compliance Counterparty Name",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_COMPLIANCE_COUNTERPARTY_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Business Unit",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_BUSINESS_UNIT
            },
            zoomedIn: {
                title: "Transactions by Business Unit",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_BUSINESS_UNIT_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transaction by Entity Country Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE_BY_ENTITY_COUNTRY_CODE,
                height: "377px",
                noZoomIn: true
            }
        },
    ],
    Count: [
        {
            noZoom: {
                title: "Transactions by Legal Entity Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_LEGAL_ENTITY_CODE
            },
            zoomedIn: {
                title: "Transactions by Legal Entity Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_LEGAL_ENTITY_CODE_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Posting Period",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_POSTING_PERIOD,
                noZoomIn: true
            }
        },
        {
            noZoom: {
                title: "Transaction Country Flow",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_COUNTRY_FLOW
            },
            zoomedIn: {
                title: "Transaction Country Flow",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_COUNTRY_FLOW_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Tax Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_TAX_CODE
            },
            zoomedIn: {
                title: "Transactions by Tax Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_TAX_CODE_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by G/L Account No",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_GL_ACCOUNT_NO
            },
            zoomedIn: {
                title: "Transactions by G/L Account No",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_GL_ACCOUNT_NO_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Compliance Counterparty Name",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_COMPLIANCE_COUNTERPARTY
            },
            zoomedIn: {
                title: "Transactions by Compliance Counterparty Name",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_COMPLIANCE_COUNTERPARTY_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transactions by Business Unit",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_BUSINESS_UNIT
            },
            zoomedIn: {
                title: "Transactions by Business Unit",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_BUSINESS_UNIT_ZOOMEDIN
            }
        },
        {
            noZoom: {
                title: "Transaction by Entity Country Code",
                dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT_BY_ENTITY_COUNTRY_CODE,
                height: "377px",
                noZoomIn: true
            }
        },
    ]
}

export const UNUSUAL_SUPPLIERS_CARD_INFO = [
    {
        noZoom: {
            title: "Suppliers by Credit Note Gross Value",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_SUPPLIERS_BY_GROSS_VALUE
        },
        zoomedIn: {
            title: "Suppliers by Credit Note Gross Value",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_SUPPLIERS_BY_GROSS_VALUE_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Domestic Suppliers Without GST",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_SUPPLIERS_WITHOUT_GST
        },
        zoomedIn: {
            title: "Domestic Suppliers Without GST",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_SUPPLIERS_WITHOUT_GST_ZOOMEDIN
        }
    },
]

export const UNUSUAL_CUSTOMERS_CARD_INFO = [
    {
        noZoom: {
            title: "Customers by Credit Note Gross Value",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_CUSTOMERS_BY_GROSS_VALUE
        },
        zoomedIn: {
            title: "Customers by Credit Note Gross Value",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_CUSTOMERS_BY_GROSS_VALUE_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Domestic Customers Without GST",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_CUSTOMERS_WITHOUT_GST
        },
        zoomedIn: {
            title: "Domestic Customers Without GST",
            dashboard: process.env.REACT_APP_LOOKER_UNUSUAL_CUSTOMERS_WITHOUT_GST_ZOOMEDIN
        }
    },
]

export const SIGNIFICANT_SUPPLIERS_CARD_INFO = [
    {
        noZoom: {
            title: "Distinct Suppliers",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_SUPPLIERS_DISTINCT,
            height: "144px",
            noZoomIn: true
        }
    },
    { placeholder: true },
    {
        noZoom: {
            title: "Suppliers by Value",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_SUPPLIERS_BY_VALUE
        },
        zoomedIn: {
            title: "Suppliers by Value",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_SUPPLIERS_BY_VALUE_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Suppliers by Transaction Volume",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_SUPPLIERS_BY_TRANSACTION_VOLUME
        },
        zoomedIn: {
            title: "Suppliers by Transaction Volume",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_SUPPLIERS_BY_TRANSACTION_VOLUME_ZOOMEDIN
        }
    },
]

export const SIGNIFICANT_CUSTOMERS_CARD_INFO = [
    {
        noZoom: {
            title: "Distinct Customers",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_CUSTOMERS_DISTINCT,
            height: "144px",
            noZoomIn: true
        }
    },
    { placeholder: true },
    {
        noZoom: {
            title: "Customers by Value",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_CUSTOMERS_BY_VALUE
        },
        zoomedIn: {
            title: "Customers by Value",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_CUSTOMERS_BY_VALUE_ZOOMEDIN
        }
    },
    {
        noZoom: {
            title: "Customers by Transaction Volume",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_CUSTOMERS_BY_TRANSACTION_VOLUME
        },
        zoomedIn: {
            title: "Customers by Transaction Volume",
            dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_CUSTOMERS_BY_TRANSACTION_VOLUME_ZOOMEDIN
        }
    },
]

export const ATTRIBUTION_CARD_INFO = [
    {
        noZoom: {
            title: "Late Posted Invoices",
            dashboard: process.env.REACT_APP_LOOKER_ATTRIBUTION_LATE_POSTED_INVOICES,
            noZoomIn: true
        }
    },
    {
        noZoom: {
            title: "Clearing Date vs Invoice Date",
            dashboard: process.env.REACT_APP_LOOKER_ATTRIBUTION_CLEARING_DATE_VS_INVOICE_DATE,
            noZoomIn: true
        }
    },
]