import { useEffect, useState, useMemo } from "react";
import { Stack } from "@mui/material";
import { useUser, useObligation } from "../../../context";
import { ListNotes } from "../Notes/ListNotes";
import { SummaryCards } from "./SummaryCards";
import { AdjustmentsTable } from "./AdjustmentsTable";
import { LoadingView } from "./LoadingView";
import { dummyData } from "./dummyData";
import { Toast } from "../Toast";
import { conditionalEntityCodeDefaultQuery, conditionalEntityCodeQuery } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";

export const Adjustments = () => {
  const navigate = useNavigate()
  const { userToken } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [adjustmentsSummaryData, setAdjustmentsSummaryData] = useState([]);
  const [toast, setToast] = useState({ type: "", message: "" });
  const { id, member } = useParams();
  const { selectedObgDetails } = useObligation();
  const obligation = selectedObgDetails;

  const [filters, setFilters] = useState({
    adjustmentType: {
      field: "adjustment_type",
      conditionOperator: "eq",
      value: undefined,
    },
    createdBy: {
      field: "created_by",
      conditionOperator: "eq",
      value: undefined,
    },
    createdOn: {
      field: "entry_date",
      conditionOperator: "eq",
      value: undefined,
    },
    member: {
      field: "entityname",
      conditionOperator: "eq",
      value: undefined,
    },
    input: {
      field: "input",
      conditionOperator: "eq",
      value: undefined,
    },
  });

  const entityCodeQuery = conditionalEntityCodeQuery(obligation);
  const entityCodeDefaultTableQuery = conditionalEntityCodeDefaultQuery(obligation);

  useEffect(() => {
    if (userToken && obligation && obligation.obligation_id) {
      setIsLoading(true);

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/adjustment/adjustmentsummary?obligation_id=${id}${entityCodeQuery}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          credentials: "include",
        }
      )
        .then((res) => {

          return res.json()
        })
        .then((adjustmentsSummaryData) => {
          setAdjustmentsSummaryData(adjustmentsSummaryData?.adjustment);
        })
        .catch((err) => {
          console.error("Error fetching Adjustments Summary data:", err);
          setToast({
            type: "failure",
            message: "Failed to fetch Adjustments Summary data.",
          });
          setAdjustmentsSummaryData(dummyData.summary);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userToken, id, member, obligation, entityCodeQuery]);

  const customFilters = Object.values(filters)
    .filter((filter) => filter.value !== undefined)
    .map((filter) => ({
      field: { mapping: filter.field },
      condition: { operator: filter.conditionOperator },
      queryValue: filter.value,
    }));

  return (
    <Stack spacing={"2.5rem"}>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <SummaryCards
            data={adjustmentsSummaryData}
            filters={filters}
            setFilters={setFilters}
          />
          <AdjustmentsTable
            filters={filters}
            setFilters={setFilters}
            customFilters={customFilters}
            entityCodeDefaultTableQuery={entityCodeDefaultTableQuery}
          />
          {toast?.message && (
            <Toast
              closeAlertHandler={() => {
                setToast({
                  type: "",
                  message: "",
                });
              }}
              toast={toast}
            />
          )}
        </>
      )}
      <ListNotes />
    </Stack>
  );
};
