import { Link, ListItem, ListItemButton, ListItemText as NavLinkText, Skeleton, styled } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TextTag from '../Text Tags/TextTag';
import { useObligation } from '../../../context';

export const NavLink = styled(ListItemButton)(({ theme, customColor }) => ({
    color: theme.palette.baseColors.grey,
    // Little hack.
    marginBottom: "-2px",
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    gap: '0.25rem',
    '&:hover': {
        backgroundColor: 'inherit',
        borderBottom: '1px solid grey'
    },
    '&.Mui-selected': {
        color: customColor ?? theme.palette.primaryColors[200],
        backgroundColor: 'inherit',
        borderBottom: '1px solid',
        paddingLeft: 0,
        paddingRight: 0,
        '&:hover': {
            backgroundColor: 'inherit'
        }
    },

    '&.Mui-focusVisible': {
        backgroundColor: 'none',
        paddingLeft: 0,
        paddingRight: 0
    },
    '&.MuiListItemButton-root:hover': {
        backgroundColor: 'none',
        paddingLeft: 0,
        paddingRight: 0
    },
    '&.Mui-disabled': {
        color: theme.palette.secondaryColors[200],
        opacity: 1
    }
}));



export const NavMenu = ({ linksMapping, availableTabs, currentTab, useParamsTab = true, disabledOverride = false }) => {
    const navigate = useNavigate();
    const loc = useLocation();
    const pathname = loc?.pathname;
    const { tab } = useParams();
    let _currentTab = tab === undefined ? currentTab : !useParamsTab ? currentTab : tab;
    const { selectedObgDetails } = useObligation();

    return (
        <>
            {linksMapping ? linksMapping.map((item) => (
                <ListItem disablePadding key={item.name} sx={{ width: 'auto' }}>
                    <NavLink
                        component={Link}
                        onClick={item.onClick ? item.onClick : () => { navigate(item.path) }}
                        selected={pathname === item.path || pathname.startsWith(item.path)} disableRipple
                        sx={{
                            // Little hack.
                            marginBottom: "-2px"
                        }}
                    >
                        <NavLinkText
                            primary={item.name}
                            primaryTypographyProps={{
                                fontWeight: pathname === item.path ? 500 : 400,
                                fontSize: '0.875rem',
                            }}
                        />

                    </NavLink>
                </ListItem>
            ))
                :
                availableTabs?.map((item) => {
                    const isCurrentTab = _currentTab === item.name?.toLowerCase()
                    const isItemDisabled = disabledOverride || item.state === 'disabled'
                    return <ListItem disablePadding key={item.name} sx={{ width: 'auto' }}>
                        <NavLink
                            component={Link}
                            onClick={item.onClick ? item.onClick : () => { navigate(item.link) }}
                            selected={_currentTab === item.name?.toLowerCase()}
                            disableRipple
                            disabled={isItemDisabled}
                        >
                            {item.icon && item.icon}
                            <NavLinkText
                                primary={item.name}
                                primaryTypographyProps={{
                                    fontWeight: isCurrentTab ? 500 : 400,
                                    fontSize: '0.875rem',
                                }}
                            />
                            {(item.count !== null && item.count !== undefined) && (item.count === "loading" ?
                                <Skeleton variant='rectangular' width="3.25rem" height="1.8125rem" />
                                :
                                <TextTag text={item.count} variant={isItemDisabled ? "grey" : isCurrentTab ? "primary" : "grey"} size="m" />
                            )}
                        </NavLink>
                    </ListItem>
                })
            }

        </>
    )
}