import { Stack, Typography, useTheme, Box, Grid, Divider } from '@mui/material';
import { BtnLink } from '../../../components/common/Buttons/BtnLink';
import { useNavigate } from 'react-router-dom';
import { formatAmount } from '../Reconciliations/Utility/helperFunctions';
import { currencyFormatterWithoutSymbol } from '../../../utils';

const formatKey = (key) => {
  // Split the string into words
  const words = key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/(_)([a-z])/gi, ' $2')
    // Capitalize the first letter of each word after split
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Capitalize the first letter of each word and join them with a space
  const capitalizedWords = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  const modifiedString = capitalizedWords.includes('Vat')
    ? capitalizedWords.replace('Vat', 'VAT')
    : capitalizedWords.includes('Item')
      ? capitalizedWords.replace('Item', 'Items')
      : capitalizedWords;

  return modifiedString;
};

const CardInfoSlices = ({ data, format }) => (
  <Grid
    container
    columns={2}
    direction='row'
    sx={{
      marginBottom: '25px',
    }}>
    {Object.entries(data).map(([key, value], index) => {
      if (key === 'status' || key === 'currency_code') {
        return null;
      } else
        return (
          <Grid
            item
            key={key}
            sx={{ display: 'flex' }}>
            <Box>
              <Typography sx={{ fontSize: '0.875rem', color: '#979797' }}>
                {formatKey(key)}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 700,
                  lineHeight: '1.875rem',
                }}>
                {format?.indexOf(key) >= 0
                  ? formatAmount(value, data.currency_code)
                  : currencyFormatterWithoutSymbol(value)}
              </Typography>
            </Box>
            {index !== Object.entries(data).length - 1 && (
              <Divider
                orientation='vertical'
                variant='middle'
                sx={{ margin: '0px 15px' }}
              />
            )}
          </Grid>
        );
    })}
  </Grid>
);

export const InfoCard = ({ children, title, data, format, text, link }) => {

  const navigate = useNavigate();

  const theme = useTheme();
  const go = () => navigate(link.url);

  return (
    <Stack
      sx={{
        borderRadius: '0.5rem',
        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
        backgroundColor: theme.palette.baseColors.white,
        padding: '1.5rem',
        color: theme.palette.secondaryColors[600],
        alignItems: 'flex-start',
        height: '100%',
      }}>
      <Stack
        direction='row'
        alignItems='center'
        spacing='.75rem'
        sx={{
          marginBottom: '.5rem',
        }}>
        {children}
        <Typography
          gutterBottom
          component='h4'
          color={theme.palette.baseColors.grey}
          sx={{ fontWeight: 700, fontSize: '1.125rem' }}>
          {title}
        </Typography>
      </Stack>
      {text && (
        <Typography
          color={theme.palette.random[1000]}
          sx={{
            fontSize: '0.875rem',
            marginBottom: '1.5rem',
            height: 1,
          }}>
          {text}
        </Typography>
      )}
      {data && (
        <CardInfoSlices
          data={data}
          format={format}
        />
      )}
      <BtnLink
        btnTxt={link.text}
        onClick={go}
        styling={{
          color: '#D04A02',
          fontSize: '1rem',
          textDecoration: 'underline',
        }}
      />
    </Stack>
  );
};
