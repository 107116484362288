import { SvgIcon } from '@mui/material';

export const DueWithinOneWeekIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.99962 18V12C1.99962 9.87827 2.84247 7.84344 4.34276 6.34315C5.84305 4.84285 7.87788 4 9.99962 4C12.1213 4 14.1562 4.84285 15.6565 6.34315C17.1568 7.84344 17.9996 9.87827 17.9996 12V18H18.9996V20H0.999617V18H1.99962ZM3.99962 18H15.9996V12C15.9996 10.4087 15.3675 8.88258 14.2423 7.75736C13.117 6.63214 11.5909 6 9.99962 6C8.40832 6 6.88219 6.63214 5.75698 7.75736C4.63176 8.88258 3.99962 10.4087 3.99962 12V18ZM8.99962 0H10.9996V3H8.99962V0ZM17.7776 2.808L19.1916 4.222L17.0716 6.343L15.6566 4.929L17.7776 2.808ZM0.807617 4.222L2.22162 2.808L4.34262 4.928L2.92962 6.344L0.807617 4.222ZM4.99962 12C4.99962 10.6739 5.5264 9.40215 6.46408 8.46447C7.40176 7.52678 8.67353 7 9.99962 7V9C9.20397 9 8.44091 9.31607 7.8783 9.87868C7.31569 10.4413 6.99962 11.2044 6.99962 12H4.99962Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};