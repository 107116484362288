import { Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { UploadIcon } from '../CustomIcons/UploadIcon';
import { RedWarningIcon } from '../CustomIcons/RedWarningIcon';
import { ALLOWED_FILE_TYPES } from './constants';

const VisuallyHiddenInput = styled('input')(({ theme }) => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: 1,
  fontSize: '1rem',
}));

export const FileUploader = ({ errorMsg, onChange, isUploading, disabled, fileSize }) => {
  const theme = useTheme();
  return (
    <Stack gap='1rem'>
      <Stack
        direction='row'
        alignItems='center'
        gap='1rem'>
        <Button
          component='label'
          variant='contained'
          startIcon={isUploading ? null : <UploadIcon />}
          disableElevation
          disableRipple
          disabled={disabled}
          sx={{
            backgroundColor: theme.palette.primaryColors[200],
            color: theme.palette.secondaryColors[100],
            borderRadius: '0.326rem',
            textTransform: 'none',
            minWidth: 'max-content',
            whiteSpace: 'nowrap',
            height: '3.25rem',
            fontSize: '1rem',
            padding: '0.5rem 1.5rem',
            '&:hover': {
              backgroundColor: theme.palette.primaryColors[100],
              color: theme.palette.primaryColors[200],
            },
            '&:disabled': {
              backgroundColor: theme.palette.secondaryColors[200],
              color: theme.palette.secondaryColors[400],
            },
          }}>
          Upload attachments
          <VisuallyHiddenInput
            type='file'
            multiple={true}
            accept={ALLOWED_FILE_TYPES}
            onChange={onChange}
          />
        </Button>
        <Typography color={theme.palette.baseColors.grey}>Maximum file size of {fileSize}</Typography>
      </Stack>
      {errorMsg && (
        <Stack
          direction='row'
          alignItems='center'
          gap='0.25rem'>
          <RedWarningIcon />
          <Typography
            variant='body2'
            sx={{
              fontWeight: 500,
              color: theme.palette.functional.error,
            }}>
            {errorMsg}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
