import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import 'rsuite/DateRangePicker/styles/index.css';
import './Datestyle.css';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useEffect } from 'react';
import { useTheme } from '@mui/material';

const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left',
  },
  {
    label: 'Yesterday',

    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
  },

  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left',
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  },
];

const SingleInputDateRangePicker = forwardRef((props, ref) => {
  const [DateVal, setDateval] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (props?.erase === 1) {
      setDateval(props?.defaultRange ?? null);
      props.eraseval(0);
    }
  }, [props]);

  useEffect(() => {
    setDateval(props?.defaultRange ?? null)
    props.dueDatesHandler(props?.defaultRange ?? null)
  }, [])

  // RSuite style overrides.
  useEffect(() => {
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-50', theme.palette.primaryColors[100]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-500', theme.palette.primaryColors[200]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-100', theme.palette.primaryColors[300]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-700', theme.palette.primaryColors[400]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-600', theme.palette.primaryColors[400]);
  }, []);

  useImperativeHandle(ref, () => ({
    overrideRange: range => {
      if (range) {
        setDateval(range);
        props.dueDatesHandler(range);
      } else {
        setDateval(props?.defaultRange ?? null);
        props.dueDatesHandler(props?.defaultRange ?? null);
      }
    }
  }))

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: props?.width ?? '15%' }}>
      <DateRangePicker
        placement='auto'
        disabled={props.disabled}
        showHeader={false}
        caretAs={ChevronDownIcon}
        size='lg'
        format='dd-MM-yyyy'
        value={DateVal}
        defaultValue={DateVal}
        ranges={predefinedRanges}
        placeholder={props.placeholder ?? 'Due Date'}
        style={{
          width: '100%',
          borderRadius: '0',
          ...props.style,
        }}
        onShortcutClick={(event) => {
          // console.log(event.value);
        }}
        onChange={(e) => {
          if (e) {
            setDateval(e);
            let a = [];
            e.forEach((dt) => {
              a.push(dt.toLocaleDateString('en-GB').replaceAll('/', '-'));
            });
            props.dueDatesHandler(a);
          } else {
            setDateval(e);
            props.dueDatesHandler();
          }
        }}
      />
    </div>
  );
}, []);
export default SingleInputDateRangePicker;
