export const fetchStages = (
  userToken,
  selectedObgDetails,
  navigate,
  stages,
  setLoader,
  setToast
) => {
  const fetchPromises = stages.map((stage) => {
    return fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/obligationtask/checklistlistall?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26org_heirarchy_code__eq=${selectedObgDetails?.org_heirarchy_code}%26stage__eq=${stage.stage}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    ).then((res) => {
      if (!res.ok) {
        throw new Error('Failed to fetch checklist task data');
      }
      return res.json();
    });
  });

  Promise.all(fetchPromises)
    .then(([dataLoadData, prepareData, reviewData, approveData]) => {
      stages?.map((stage) => {
        if (stage.response === 'dataLoadData') stage.setterFn(dataLoadData?.checklist);
        else if (stage.response === 'prepareData') stage.setterFn(prepareData?.checklist);
        else if (stage.response === 'reviewData') stage.setterFn(reviewData?.checklist);
        else if (stage.response === 'approveData') stage.setterFn(approveData?.checklist);
      });
    })
    .catch((err) => {
      console.error(err);
      setToast({
        type: 'failure',
        message: 'Failed to fetch checklist data.',
      });
    })
    .finally(() => {
      setLoader(false);
    });
};
