import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { WhiteCard } from '../../../pages/Obligations/WhiteCard';
import { FullscreenIcon } from '../CustomIcons/FullscreenIcon';
import { useState } from 'react';
import { Looker } from './Looker';
import { BtnFilled } from '../Buttons/BtnFilled';
import { DownloadIcon } from '../CustomIcons/DownloadIcon';
import { lookerEmbedDomain } from '../../../utils';
import styled from 'styled-components';
import { SimpleSelect } from '../Dropdowns/SimpleSelect';
import { GenericModal } from '../Modal/GenericModal';
import { formatISO } from 'date-fns';
import { SimpleCheckbox } from '../Checkboxes/SimpleCheckbox';

const LookerCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem !important',
  fontWeight: '700 !important',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const ExportDialog = ({ open, onClose, baseExportURL }) => {
  const [pageSize, setPageSize] = useState('Fit Page to Dashboard');
  const PAGE_SIZE_MAPPINGS = {
    'Fit Page to Dashboard': 'fitPageToDashboard',
    Letter: 'letter',
    Legal: 'legal',
    Tabloid: 'tabloid',
    A0: 'a0',
    A1: 'a1',
    A2: 'a2',
    A3: 'a3',
    A4: 'a4',
    A5: 'a5',
  };
  const [orientation, setOrientation] = useState('Portrait');
  const ORIENTATION_OPTIONS = ['Portrait', 'Landscape'];
  const [isSingleColumn, setIsSingleColumn] = useState(false);

  const parameterizedExportURL =
    baseExportURL +
    '&pdf_page_size=' +
    PAGE_SIZE_MAPPINGS[pageSize] +
    (isSingleColumn ? '&single_column=true' : '') +
    `&pdf_landscape=${orientation === 'Landscape' || pageSize === 'Fit Page to Dashboard'}`;
  const printURL = parameterizedExportURL + '&download=no';
  const downloadURL = parameterizedExportURL + '&download=yes';
  const addTimestampToURL = (url) => url.replace('[TIMESTAMP]', formatISO(new Date()));

  return (
    <GenericModal
      open={open}
      title='Export Dashboard'
      btnText='Download'
      secondaryBtnText='Open in Browser'
      closeHandler={onClose}
      cancelHandler={onClose}
      submitHandler={() => {
        window.open(addTimestampToURL(downloadURL), '_blank').focus();
        onClose();
      }}
      secondaryHandler={() => {
        window.open(addTimestampToURL(printURL), '_blank').focus();
        onClose();
      }}
      width='40rem'>
      <Stack gap='1rem'>
        <Stack>
          Paper Size
          <SimpleSelect
            id='pageSize'
            options={Object.keys(PAGE_SIZE_MAPPINGS)}
            value={pageSize}
            onChange={(event) => {
              setPageSize(event.target.value);
            }}
            sx={{
              width: '10rem !important',
            }}
          />
        </Stack>
        {pageSize !== 'Fit Page to Dashboard' && (
          <Stack>
            Orientation
            <SimpleSelect
              id='orientation'
              options={ORIENTATION_OPTIONS}
              value={orientation}
              onChange={(event) => {
                setOrientation(event.target.value);
              }}
              sx={{
                width: '10rem !important',
              }}
            />
          </Stack>
        )}
        <SimpleCheckbox
          onChange={() => setIsSingleColumn((prev) => !prev)}
          checked={isSingleColumn}
          id='isSingleColumn'
          label='Arrange dashboard tiles in a single column'
        />
      </Stack>
    </GenericModal>
  );
};

export const ExpandableLookerGrid = ({
  cards,
  dashboardParametersStringOverride,
  dashboardParameters,
  downloadConstants,
  actionBar,
  hideActionBar = false,
  defaultCardHeight = '360px',
  hideDownload,
  actionBarStyling = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}) => {
  const TWO_COLUMN_MIN_WIDTH = '750px';
  const columnAmount = useMediaQuery(`(min-width:${TWO_COLUMN_MIN_WIDTH})`) ? 2 : 1;
  const generateCardURL = (card) => {
    if (!card) return '';

    let parameterObject = {};
    Object.assign(parameterObject, dashboardParameters);
    Object.assign(parameterObject, card.extraParameters);
    parameterObject.theme = card.theme ?? 'TCC_Expanded_Visual';
    const parameterString = new URLSearchParams(parameterObject).toString();    
    return `/embed/${card.dashboard}?${parameterString}`;
  };

  const hideFilterString = dashboardParameters
    ? Object.keys(dashboardParameters)
        .map((param) => '&hide_filter=' + param.replaceAll(' ', '+'))
        .join('')
    : '';
  const baseExportURL = !dashboardParametersStringOverride
    ? `https://${process.env.REACT_APP_LOOKER_HOST}/render/process/wd/1680/1/embed/${
        downloadConstants.dashboard
      }.pdf?filename=${downloadConstants.filename}+[TIMESTAMP].pdf&longTables=false&title=${
        downloadConstants.title
      }&print_mode=true&embed_domain=${lookerEmbedDomain}&${new URLSearchParams(
        dashboardParameters
      ).toString()}${hideFilterString}`
    : `https://${process.env.REACT_APP_LOOKER_HOST}/render/process/wd/1680/1/embed/${downloadConstants.dashboard}.pdf?filename=${downloadConstants.filename}+[TIMESTAMP].pdf&longTables=false&title=${downloadConstants.title}&print_mode=true&embed_domain=${lookerEmbedDomain}&${dashboardParametersStringOverride}`;

  const [currentDrilldown, setCurrentDrilldown] = useState(null);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  return (
    <>
      <Stack gap='1.5rem'>
        {!hideActionBar && (
          <WhiteCard>
            <Stack
              width={1}
              sx={actionBarStyling}>
              <Box sx={{ width: '100%' }}>{actionBar}</Box>
              {!hideDownload && (
                <BtnFilled
                  btnTxt='Export Dashboard'
                  icon={<DownloadIcon />}
                  iconPosition='start'
                  styling={{
                    width: 'min-content',
                  }}
                  onClick={() => setIsExportDialogOpen(true)}
                />
              )}
            </Stack>
          </WhiteCard>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${columnAmount}, 1fr)`,
            justifyItems: 'flex-start',
            gap: '1.5rem',
          }}>
          {cards.map((card) =>
            card.placeholder ? (
              <Box
                width={1}
                height={1}
              />
            ) : (
              <WhiteCard
                key={card.noZoom.title}
                style={{
                  flexGrow: 1,
                  width: 1,
                  gap: 0,
                  p: 0,
                  overflow: 'hidden',
                  ...(card.noZoom.doubleDecker ? { gridColumnEnd: 'span 2' } : {}),
                }}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  height='2.75rem'
                  p='.5rem .5rem .25rem'>
                  <LookerCardTitle>{card.noZoom.title}</LookerCardTitle>
                  {!card.noZoom.noZoomIn && (
                    <Box
                      height={1}
                      sx={{
                        fontSize: '.75rem',
                        cursor: 'pointer',
                        pointerEvents: 'auto',
                        pr: '.25rem',
                      }}
                      display='inline-flex'
                      alignItems='center'
                      justifyContent='center'
                      onClick={() => {
                        setCurrentDrilldown(card.zoomedIn);
                      }}>
                      <FullscreenIcon />
                    </Box>
                  )}
                </Stack>

                <Box
                  sx={{
                    height: card.noZoom.height ?? defaultCardHeight,
                  }}>
                  <Looker
                    header={false}
                    iframeTitle={card.noZoom.title}
                    dashboard={generateCardURL(card.noZoom)}
                  />
                </Box>
              </WhiteCard>
            )
          )}
        </Box>
      </Stack>

      <GenericModal
        open={currentDrilldown !== null}
        title={currentDrilldown?.title}
        closeHandler={() => setCurrentDrilldown(null)}
        height='80%'
        width='80%'
        disableChildrenPadding
        disableActionBar>
        <Looker
          header={false}
          iframeTitle={currentDrilldown?.title}
          dashboard={generateCardURL(currentDrilldown)}
          disableDynamicHeight
        />
      </GenericModal>

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setIsExportDialogOpen(false)}
        baseExportURL={baseExportURL}
      />
    </>
  );
};
