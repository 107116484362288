import * as mui from '@mui/material';
import { WhiteCard } from '../../../WhiteCard';
import { typographyStyle } from '../../../Reconciliations';
import { saveAs } from 'file-saver';
import { CloseIcon } from '../../../../../components/common/CustomIcons/CloseIcon';
import { BtnFilled } from '../../../../../components/common/Buttons/BtnFilled';
import { useEffect, useState } from 'react';
import { DownloadIcon } from '../../../../../components/common/CustomIcons/DownloadIcon';
import { SortIcon } from '../../../../../components/common/CustomIcons/SortIcon';
import { SortDescIcon } from '../../../../../components/common/CustomIcons/SortDescIcon';
import { SortAscIcon } from '../../../../../components/common/CustomIcons/SortAscIcon';
import { useUser } from '../../../../../context';
import { useNavigate } from 'react-router-dom';


const StyledTableHeadingCell = mui.styled(mui.TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0.75rem 1rem',
  cursor: 'pointer',
}));

const StyledTableBodyCell = mui.styled(mui.TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.75rem 1rem',
  fontWeight: 500,
  // todo: check below styling again
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  width: 100,
  maxWidth: 100,
}));

export const ReasonModal = ({ setOpen, reasonException, reasonData, setReasonException }) => {
  const theme = mui.useTheme();
  const HeaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1244px',
    padding: '32px',
    pb: 0,
  };
  const style = {
    width: '100%',
    height: '30%',
    border: 'none',
    padding: '2px',
    pr: '1rem',
    pb: '5rem',
    overflow: 'auto',
  };

  const [files, setFiles] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(1);
  const navigate = useNavigate()

  useEffect(() => {
    if (reasonData?.attachments) {
      const attachments = reasonData.attachments?.map((attachment) => {
        const arr = attachment?.split('/');
        const fileName = arr[arr.length - 1];
        const extension = fileName.split('.').pop();
        const filePath = attachment;
        const obj = { name: fileName, extension, path: filePath };
        return obj;
      });
      setFiles(attachments);
    }
  }, [reasonData]);

  const { userToken } = useUser();
  const sortFileDataHandler = (colName) => {
    if (colName === 'Attachment') {
      setSortColumn('Attachment name');
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...files].sort(
        (a, b) => attachmentNameSortOrder * a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setFiles(sortedData);
    } else if (colName === 'Attachment type') {
      setSortColumn('Attachment type');
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...files].sort(
        (a, b) =>
          attachmentTypeSortOrder *
          a.extension.toLowerCase().localeCompare(b.extension.toLowerCase())
      );
      setFiles(sortedData);
    }
  };

  const fileDownloadHandler = (no_of_files, path) => {
    let url;
    const noteId = reasonData.note_id;
    if (no_of_files === 'single') {
      url = `/note/getattachment?note_id=${noteId}&files=${path}`;
    } else {
      let allFiles = [];
      files.forEach((f) => {
        allFiles.push(f.path);
      });
      allFiles = allFiles.join(',');
      url = `/note/getattachment?note_id=${noteId}&files=${allFiles}`;
    }
    fetch(process.env.REACT_APP_BACKEND_URL + url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        const blob = res.blob();
        if (!res.ok || !blob) {
          throw new Error(`Something went wrong, status code: ${res.status}`);
        }
        const contentDisposition = res.headers.get("Content-Disposition");
        const requiredIndex = contentDisposition?.indexOf("filename=") + 9;
        const fileName = contentDisposition?.slice(requiredIndex);

        return blob.then((blob) => ({ blob, fileName }));

      })
      .then(({ blob, fileName }) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <mui.Modal open={true}>
      <WhiteCard style={HeaderStyle}>
        <mui.Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          sx={{
            borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
          }}>
          <mui.Typography
            id='modal-modal-title'
            sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey)}>
            {reasonException?.rule_description}
          </mui.Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </mui.Stack>
        <WhiteCard style={style}>
          <mui.Stack gap={'2rem'}>
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                Reason for acceptance
              </mui.Typography>
              <mui.Typography variant='body2'>{reasonData?.reason}</mui.Typography>
            </mui.Stack>
            <mui.Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'>
              <mui.Typography
                fontWeight={700}
                color={theme.palette.baseColors.grey}>
                Attachments
              </mui.Typography>
              {files?.length > 0 && (
                <BtnFilled
                  size='small'
                  iconPosition='start'
                  btnTxt='Download all attachments'
                  icon={<DownloadIcon />}
                  onClick={() => {
                    fileDownloadHandler('multiple', files);
                  }}
                />
              )}
            </mui.Stack>
            <mui.Stack gap='1rem'>
              {files?.length > 0 ? (
                <mui.TableContainer
                  component={mui.Paper}
                  sx={{ boxShadow: 'none', overflow: 'initial' }}>
                  <mui.Table
                    aria-label='selected files'
                    sx={{
                      borderRadius: '0.5rem',
                      border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      overflow: 'scroll',
                    }}>
                    <mui.TableHead
                      sx={{
                        backgroundColor: theme.palette.neutralColors.black[100],
                        height: '2.5rem',
                      }}>
                      <mui.TableRow variant='body2'>
                        <StyledTableHeadingCell
                          style={{ width: '65%', maxWidth: '65%' }}
                          onClick={() => {
                            sortFileDataHandler('Attachment');
                          }}>
                          Attachment
                          <mui.IconButton disableRipple>
                            {sortColumn === 'Attachment name' ? (
                              attachmentNameSortOrder === 1 ? (
                                <SortAscIcon />
                              ) : (
                                <SortDescIcon />
                              )
                            ) : (
                              <SortIcon />
                            )}
                          </mui.IconButton>
                        </StyledTableHeadingCell>
                        <StyledTableHeadingCell
                          style={{ width: '30%', maxWidth: '30%' }}
                          onClick={() => {
                            sortFileDataHandler('Attachment type');
                          }}>
                          Attachment type
                          <mui.IconButton disableRipple>
                            {sortColumn === 'Attachment type' ? (
                              attachmentTypeSortOrder === 1 ? (
                                <SortAscIcon />
                              ) : (
                                <SortDescIcon />
                              )
                            ) : (
                              <SortIcon />
                            )}
                          </mui.IconButton>
                        </StyledTableHeadingCell>
                        <StyledTableHeadingCell
                          style={{ width: '5%', maxWidth: '5%' }}></StyledTableHeadingCell>
                      </mui.TableRow>
                    </mui.TableHead>
                    <mui.TableBody>
                      {files?.map((file) => (
                        <mui.TableRow
                          key={file.name}
                          sx={{
                            height: '3rem',
                            border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                          }}>
                          <StyledTableBodyCell
                            sx={{
                              color: theme.palette.primaryColors[200],
                              textDecoration: 'underline',
                            }}>
                            {file.name}
                          </StyledTableBodyCell>

                          <StyledTableBodyCell>{file.extension.toUpperCase()}</StyledTableBodyCell>

                          <StyledTableBodyCell>
                            <DownloadIcon
                              sx={{
                                cursor: 'pointer',
                                color: theme.palette.primaryColors[200],
                              }}
                              onClick={() => {
                                fileDownloadHandler('single', file.path);
                              }}
                            />
                          </StyledTableBodyCell>
                        </mui.TableRow>
                      ))}
                    </mui.TableBody>
                  </mui.Table>
                </mui.TableContainer>
              ) : (
                'No attachments available'
              )}
            </mui.Stack>
          </mui.Stack>
        </WhiteCard>
        <mui.Stack
          flexDirection='row'
          justifyContent='flex-end'
          gap='0.5rem'
          pb='2rem'>
          <BtnFilled
            btnTxt={'Close'}
            onClick={handleClose}
            styling={{
              height: '2.75rem',
              padding: '0.5rem 1.5rem',
              fontSize: '1rem',
            }}
          />
        </mui.Stack>
      </WhiteCard>
    </mui.Modal>
  );
};
