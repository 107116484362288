import React, { useContext } from 'react';
import { Box, Chip, Stack, Typography, styled, useTheme } from '@mui/material';
import { BtnLink } from '../../../../components/common/Buttons/BtnLink';
import { Cross1Icon } from '@radix-ui/react-icons';

import { FilterSelect } from '../../Adjustments/FilterSelect';
import { FilterContext } from '../../FilterContext';
import { BtnOutlined } from '../../../../components/common/Buttons/BtnOutlined';

const CustomChip = styled(Chip)(({ theme, active }) => ({
  mt: '1rem',
  ml: '1rem',
  color: theme.palette.baseColors.black,
  backgroundColor: theme.palette.grey[900],
  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
  padding: '1rem 0.5rem',
  borderRadius: '5px',
  height: '2.25rem',
  '.MuiChip-deleteIcon': {
    color: theme.palette.baseColors.black,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  height: '3rem',
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  flexShrink: 0,
}));

export const IndividualTestTransactionFilters = ({
  selectedTransactionRows,
  isObligationLocked,
  setAcceptModal,
  data,
  styling,
}) => {
  const theme = useTheme();
  const generateSpecialFilterValues = (data, labelKey, valueKey) => {
    const uniqueValues = new Set();
    const filterOptions = [];
    data &&
      data?.length &&
      data.forEach((item) => {
        const value = item[valueKey];
        if (!uniqueValues.has(value)) {
          uniqueValues.add(value);
          filterOptions.push({
            label: item[labelKey],
            value: value,
          });
        }
      });

    return filterOptions;
  };
  const { filters, setFilters } = useContext(FilterContext);

  const testResultOptions = ['RESOLVED', 'UNRESOLVED', 'PASSED'];
  const entityOptions = generateSpecialFilterValues(data, 'entityname', 'entitycode');

  const filteredDetails = (mapping) => {
    const filteredDetails = filters?.filter((condition) => condition.field.mapping === mapping);
    return filteredDetails;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newFilters = [];
    if (filteredDetails(name)?.length > 0) {
      newFilters = filters.map((filter) => {
        if (filter.field.mapping === name) {
          let obj = { ...filter };
          obj.queryValue = value;
          obj.displayValue =
            name === 'entitycode'
              ? entityOptions.find((entity) => entity.value === value).label
              : value;
          return obj;
        } else {
          return filter;
        }
      });
      setFilters(newFilters);
    } else {
      newFilters = [...filters];
      let newFilter = {
        field: {
          column_name: name === 'entitycode' ? 'Entity Code' : 'Test Result',
          mapping: name === 'entitycode' ? 'entitycode' : 'Test_Result',
          type: 'text',
        },
        condition: {
          label: 'Equals to',
          operator: 'eq',
        },
        queryValue: value,
        displayValue:
          name === 'entitycode'
            ? entityOptions.find((entity) => entity.value === value).label
            : value,
      };
      newFilters.push(newFilter);
      setFilters(newFilters);
    }
  };

  const handleDelete = (name, value, clearAll, displayValue) => {
    if (clearAll) {
      setFilters([]);
    } else {
      setFilters(
        filters.filter((f) => f.field.column_name !== filteredDetails(name)[0].field.column_name)
      );
    }
  };

  return (
    <Stack
      sx={{
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...styling,
      }}>
      <Stack sx={{ width: '100%' }}>
        <Container sx={{ height: '3.25rem', justifyContent: 'space-between' }}>
          <Container sx={{ width: '100%' }}>
            {/* Test Result Select */}
            <FilterSelect
              labelId='Test_Result-select-label'
              id='Test_Result-select'
              name='Test_Result'
              value={filteredDetails('Test_Result')[0]?.queryValue || ''}
              label='Test Result'
              onChange={handleChange}
              options={testResultOptions}
            />
            {/* Entity Select */}
            <FilterSelect
              labelId='entitycode-select-label'
              id='entitycode-select'
              name='entitycode'
              value={filteredDetails('entitycode')[0]?.queryValue || ''}
              displayValue={filteredDetails('entitycode')[0]?.displayValue}
              label='Entity'
              onChange={handleChange}
              specialOptions={entityOptions}
            />
          </Container>
        </Container>
        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
          <Box
            sx={{ display: 'flex' }}
            gap='1rem'>
            {filters &&
              Object.keys(filters).map(
                (key) =>
                  filters[key].queryValue && (
                    <CustomChip
                      sx={{
                        mt: '1rem',
                        backgroundColor: '#F6F8F9',
                      }}
                      key={filters[key].field.mapping}
                      label={
                        <>
                          {filters[key].field.column_name}
                          <span style={{ color: theme.palette.secondaryColors[300] }}>
                            {' '}
                            Equals to{' '}
                          </span>
                          {filters[key].displayValue ?? filters[key].queryValue}
                        </>
                      }
                      onDelete={() => handleDelete(filters[key]?.field?.mapping, undefined)}
                      deleteIcon={
                        <Cross1Icon
                          sx={{
                            marginLeft: '0.5rem',
                          }}
                        />
                      }
                    />
                  )
              )}
          </Box>
          {Object.keys(filters).some((key) => filters[key].queryValue) && (
            <Typography sx={{ textDecoration: 'underline', mt: '1.5rem', ml: '1rem' }}>
              <BtnLink
                size='small'
                btnTxt='Clear all'
                styling={{ textDecoration: 'underline', fontSize: '1rem' }}
                onClick={() => {
                  handleDelete('', '', true);
                }}
              />
            </Typography>
          )}
        </Box>
      </Stack>
      <BtnOutlined
        size='medium'
        disabled={
          selectedTransactionRows?.length <= 0 ||
          isObligationLocked ||
          filteredDetails('Test_Result')[0]?.queryValue === 'RESOLVED' ||
          filteredDetails('Test_Result')[0]?.queryValue === 'PASSED'
        }
        onClick={() => {
          setAcceptModal(true);
        }}
        disabledStyling={{
          border: 'solid 1px',
          bgcolor: theme.palette.baseColors.white,
          cursor: 'initial',
        }}
        sx={{
          width: 104,
          height: 32,
          mt: '4.4rem',
          mr: '1rem',
          color: theme.palette.primaryColors[200],
          borderColor: theme.palette.primaryColors[200],
        }}
        btnTxt={'Accept Exceptions'}
      />
    </Stack>
  );
};
