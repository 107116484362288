export const palette = {
  baseColors: {
    white: '#FFFFFF',
    black: '#0C0C0C',
    grey: '#474747',
    blue: {
      //below two colors are not from palette
      100: '#0E73F6',
      200: '#0e73f614',
    },
    yellow: {
      //below two colors are not from palette
      100: '#FFECD9',
      200: '#AD5700',
    },
  },

  primaryColors: {
    100: '#D04A021A', // Very transparent
    200: '#D04A02', // Base
    300: '#D04A0201', // Very very transparent
    400: '#a32f08', // Slightly darker
  },
  secondaryColors: {
    100: '#F6F8F9',
    200: '#CBCBCB',
    300: '#A5A5A5',
    400: '#888888',
    500: '#353845',
    600: '#666E89',
  },
  functional: {
    tableLink: '#474747',
    primaryLink: '#D04A02',
    partialAccent: '#FEEDD1',
    partial: '#FAA61A',
    successAccent: '#EDFFED',
    success: '#008000',
    errorAccent: '#FFE7E9',
    error: '#E6000E',
  },
  neutralColors: {
    black: {
      100: '#0000000D',
      200: '#0000001A',
      300: '#00000033',
      400: '#00000066',
      500: '#00000099',
      600: '#000000CC',
      700: '#00000040',
    },
    white: {
      100: '#FFFFFF0D',
      200: '#FFFFFF1A',
      300: '#FFFFFF33',
      400: '#FFFFFF66',
      500: '#FFFFFF99',
      600: '#FFFFFFCC',
    },
  },
  graph: {
    accentSecondary: '#EB8C00',
    accentPrimary: '#DB536A',
    secondary: '#FFB600',
    primary: '#D04A02',
  },
  random: {
    //below color is not from palette
    100: '#000D3B',
    200: '#585858',
    300: '#252C32',
    400: '#8D8D8D',
    500: '#CCCFD8',
    600: '#898989',
    700: '#DDDDDD',
    800: '#111111',
    900: '#E5E9EB',
    1000: '#979797',
    1100: '#F2F3F5',
    1200: '#E6E8E8',
    1300: '#6E7C87',
    1400: '#838383',
    1500: '#D9395433',
    1600: '#DEDEDE',
    1700: '#8B8B8B',
    1800: '#5C5C5C',
  },
};
