import { Box, Divider, Typography, Stack, InputLabel, Chip } from '@mui/material';
import { Cross1Icon } from '@radix-ui/react-icons';
import { PlusIcon } from '@radix-ui/react-icons';
import { BtnFilled } from '../Buttons/BtnFilled';
import { useEffect, useState, useContext } from 'react';
import { WhiteCard } from '../../../pages/Obligations/WhiteCard';
import { CloseIcon } from '../CustomIcons/CloseIcon';
import { SimpleSelect } from '../Dropdowns/SimpleSelect';
import { BtnLink } from '../Buttons/BtnLink';
import { useTheme } from '@emotion/react';
import { EditableInput } from '../Input/EditableInput';
import { FilterContext } from '../../../pages/Obligations/FilterContext';
import SingleInputDateRangePicker from './DateRangePicker';
import { formatDate, reverseDate } from '../../../utils';
import { StyledAutocomplete } from '../Dropdowns/StyledAutocomplete';

export const TransactionFilter = ({ fields, specialFilterHandler, autocompleteProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { filters, setFilters } = useContext(FilterContext);

  // STYLING
  const theme = useTheme();
  const clearAllSx = {
    textDecoration: 'none',
    color: theme.palette.secondaryColors[300],
    display: 'inline',
  };
  const selectedFilterChipSx = {
    mt: '1rem',
    mr: '1rem',
    backgroundColor: theme.palette.secondaryColors[100],
    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
    //color: theme.palette.neutralColors.dark[300], // TODO: Revise this.
    padding: '0.5rem 0rem',
    borderRadius: '6px',
    height: '2.25rem',
    fontSize: '0.875rem',
    textAlign: 'left',
  };

  //CHANGE HANDLING
  const CONDITIONS = {
    null: [],
    numeric: [
      { label: 'Equals to', operator: 'eq' },
      { label: 'Does not equal', operator: 'not_eq' },
      { label: 'Is greater than', operator: 'gt' },
      { label: 'Is less than', operator: 'lt' },
    ],
    text: [
      { label: 'Equals to', operator: 'ilike' },
      { label: 'Does not equal', operator: 'not_eq' },
      { label: 'Contains', operator: 'contains' },
      {
        label: 'Does not contain',
        operator: 'not_like',
        valueTransform: (value) => `%25${value}%25`,
      },
    ],
    text_case_sensitive: [
      { label: 'Equals to', operator: 'eq' },
      { label: 'Does not equal', operator: 'not_eq' },
      { label: 'Contains', operator: 'contains' },
      {
        label: 'Does not contain',
        operator: 'not_like',
        valueTransform: (value) => `%25${value}%25`,
      },
    ],
    date: [
      //{ label: "Equals to", operator: "eq" },
      //{ label: "Does not equal", operator: "not_eq" },
      { label: 'Between', operator: 'between' },
    ],
    exceptionTag: [
      { label: 'Equals to', operator: 'eq' },
    ]
  };

  const [field, setField] = useState('');
  const [availableConditions, setAvailableConditions] = useState([]);
  const [condition, setCondition] = useState('');
  const [value, setValue] = useState('');
  const [eraseDateSignal, setEraseDateSignal] = useState(0);

  const getFieldObj = (fieldname) => fields.find((f) => f.column_name === fieldname);
  const getConditionObj = (condname, fieldtype) =>
    CONDITIONS[fieldtype].find((c) => c.label === condname);

  const handleFieldChange = (event) => {
    const newField = event.target.value;
    const newFieldType = fields.find((f) => f.column_name === newField).type;
    setField(newField);
    setAvailableConditions(CONDITIONS[newFieldType]);
    setCondition('');
  };

  const handleConditionChange = (event) => {
    const newCondition = event.target.value;
    setCondition(newCondition);
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleApplyFilter = () => {
    const fieldObj = getFieldObj(field);
    const conditionObj = getConditionObj(condition, fieldObj.type);

    if (specialFilterHandler && field === specialFilterHandler.fieldName) return specialFilterHandler.apply(value, setFilters, filters, handleClear, setIsOpen);

    // You can format and transform the value before it gets sent into the query.
    // First, the value transformed by passing it to the function defined on the valueTranform field of the fieldObj.
    // ↓↓ Transform function for date-type fields. ↓↓
    if (fieldObj.type === 'date')
      fieldObj.valueTransform ??= (dateArray) => {
        const newDateArray = dateArray.map((date) => reverseDate(date, '-'));
        switch (newDateArray.length) {
          case 0:
            return '';
          case 1:
            return `${newDateArray[0]},${newDateArray[0]}`;
          case 2:
            return `${newDateArray[0]},${newDateArray[1]}`;
          default:
            throw Error('Date range with too many items.');
        }
      };
    // Default value for valueTransform. Don't apply any changes to the value.
    fieldObj.valueTransform ??= (value) => value;
    conditionObj.valueTransform ??= (value) => value;

    // Transform the value with the field's valueTransform function, and then with the condition's same function.
    let queryValue = fieldObj.valueTransform(value);
    queryValue = conditionObj.valueTransform(queryValue);

    const newFilters = [...filters];
    let newFilter = {
      field: fieldObj,
      condition: conditionObj,
      queryValue: queryValue,
      // We always display whatever value we got from the user on the Chips without any modifications.
      displayValue: value,
    };
    // ...unless its a date-type field.
    // If it is, format the dates correctly and then join them with a " and " instead of with a comma.
    if (fieldObj.type === 'date')
      newFilter.displayValue = queryValue
        .split(',')
        .map((date) => formatDate(reverseDate(date, '-'), '-'))
        .join(' and ');

    // Push the new filter we got from the user into the Filters array, and set it in state.
    newFilters.push(newFilter);
    setFilters(newFilters);

    handleClear();
    setIsOpen(false);
  };

  const handleDeleteFilter = (filterToRemove) => {
    setFilters(
      filters.filter(
        (f) =>
          f.field.column_name !== filterToRemove.field.column_name ||
          f.condition.label !== filterToRemove.condition.label ||
          f.value !== filterToRemove.value
      )
    );
  };

  const handleClear = () => {
    setField('');
    setAvailableConditions([]);
    setCondition('');
    setValue('');
  };

  // #region Value Component.
  let valueComponent
  if (getFieldObj(field)?.type === 'date')
    valueComponent = (
      <Stack
        component='form'
        gap='0.5rem'
        width={0.3}
      >
        <InputLabel
          htmlFor='value'
          sx={{
            fontWeight: 500,
            cursor: 'pointer',
            color: theme.palette.baseColors.grey,
            width: 'max-content',
          }}>
          Value
        </InputLabel>

        <SingleInputDateRangePicker
          id='value'
          placeholder='Enter value...'
          disabled={field === '' || condition === ''}
          dueDatesHandler={setValue}
          erase={eraseDateSignal}
          eraseval={setEraseDateSignal}
          width='100%'
          style={{
            paddingTop: '.25rem',
          }}
        />
      </Stack>
    )
  else if (getFieldObj(field)?.options !== undefined && ["Equals to", "Does not equal"].includes(condition))
    valueComponent = (
      <Stack
        component='form'
        gap='0.5rem'
        width={0.3}
      >
        <InputLabel
          htmlFor='value'
          sx={{
            fontWeight: 500,
            cursor: 'pointer',
            color: theme.palette.baseColors.grey,
            width: 'max-content',
          }}>
          Value
        </InputLabel>

        <StyledAutocomplete
          id='value'
          name='Value'
          options={getFieldObj(field)?.options}
          onChange={(event, value, reason) => { setValue(value) }}
          loading={autocompleteProps.isLoading}
          placeholder='Enter value...'
          disabled={field === '' || condition === ''}
        />
      </Stack>
    )
  else
    valueComponent = (
      <EditableInput
        id='value'
        name='Value'
        placeholder='Enter value...'
        width={0.3}
        value={value}
        disabled={field === '' || condition === ''}
        onChange={handleValueChange}
      />
    )
  // #endregion

  return (
    <Box
      sx={{
        position: 'relative',
      }}>
      <Box
        sx={{
          display: 'inline',
        }}>
        <BtnFilled
          btnTxt={`Filter${filters.length > 0 ? ` (${filters.length})` : ''}`}
          size='small'
          iconPosition='start'
          icon=<PlusIcon />
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </Box>

      <Stack direction='row'>
        {filters.map((filter, idx) => (
          <Chip
            sx={selectedFilterChipSx}
            key={'filter ' + idx}
            label={
              <>
                {filter.field.column_name}
                <span style={{ color: theme.palette.secondaryColors[300] }}>
                  {' '}
                  {filter.condition.label}{' '}
                </span>
                {filter.displayValue}
              </>
            }
            onDelete={() => handleDeleteFilter(filter)}
            deleteIcon={
              <Cross1Icon sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }} />
            }
            disabled={filter.field.permanent ?? false}
          />
        ))}
        {filters.length > 0 && (
          <Typography
            sx={{
              textDecoration: 'underline',
              mt: '1.5rem',
            }}>
            <BtnLink
              size='small'
              btnTxt='Clear all'
              styling={{
                textDecoration: 'underline',
                fontSize: '1rem',
              }}
              // Clear All is disabled if all remaining filters are permanent.
              disabled={filters.every(filter => filter.field.permanent ?? false)}
              // Clear All clears all but the permanent filters.
              onClick={() => setFilters(filters.filter(filter => filter.field.permanent))}
            />
          </Typography>
        )}
      </Stack>

      {isOpen && (
        <WhiteCard
          style={{
            boxShadow: '0px 4px 16px -2px #4747473D',
            position: 'absolute',
            top: '2.5rem',
            width: '68rem',
            zIndex: 20,
          }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            mb='-1rem'>
            <Typography
              sx={{
                fontSize: '1.25rem',
                fontWeight: 500,
              }}>
              Add Filter
            </Typography>
            <CloseIcon
              onClick={() => setIsOpen(false)}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
          <Divider orientation='horizontal' />
          <Stack
            direction='row'
            gap='1.5rem'>
            <Stack
              gap='.5rem'
              width={1 / 3}>
              <InputLabel
                htmlFor='field'
                sx={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  color: theme.palette.baseColors.grey,
                  width: 'max-content',
                }}>
                Field
              </InputLabel>
              <SimpleSelect
                id='field'
                // We only want the names of the Fields that have not been applied to any Filter
                options={fields
                  .filter(
                    (field) =>
                      !filters.find((filter) => filter.field.column_name === field.column_name)
                  )
                  .map((f) => f.column_name)}
                value={field}
                onChange={handleFieldChange}
                sx={{
                  width: 1,
                }}
              />
            </Stack>

            <Stack
              gap='.5rem'
              width={1 / 3}>
              <InputLabel
                htmlFor='condition'
                sx={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  color: theme.palette.baseColors.grey,
                  width: 'max-content',
                }}>
                Condition
              </InputLabel>
              <SimpleSelect
                id='condition'
                options={availableConditions.map((c) => c.label)}
                value={condition}
                disabled={field === ''}
                onChange={handleConditionChange}
                sx={{
                  width: 1,
                }}
              />
            </Stack>

            {valueComponent}
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <BtnLink
              btnTxt='Clear all'
              disabled={field === ""}
              onClick={handleClear}
              size='small'
              styling={{
                ...clearAllSx,
                '&:hover': {
                  ...clearAllSx,
                },
              }}
            />
            <BtnFilled
              btnTxt='Apply'
              size='medium'
              disabled={field === '' || condition === '' || value === ''}
              onClick={handleApplyFilter}
            />
          </Stack>
        </WhiteCard>
      )}
    </Box>
  );
};