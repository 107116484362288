import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import "./Datestyle.css";
import { useTheme } from "@mui/material";

import { ChevronDownIcon } from "@radix-ui/react-icons";

export const Customselect = ({ onChange, options, value, label,loading, disabled, height, style }) => {
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    MenuListProps: {
      sx: {
        pt: "0",
        pb: "0"
      }
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 225,
        color: theme.palette.baseColors.grey,
      },
    },
  };
  const checkboxSx = {
    color: "#00000033",
    '&.Mui-checked': {
      color: theme.palette.primaryColors[200]
    },
  }
  const menuItemSx = {
    boxSizing: "content-box",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderTop: `1px solid ${theme.palette.baseColors.white}`,
    borderBottom: `1px solid ${theme.palette.baseColors.white}`,

    "&:hover": {
      backgroundColor: theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.random[1200]}`,
      borderBottom: `1px solid ${theme.palette.random[1200]}`,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
      borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
    },
    "&.Mui-selected:hover": {
      backgroundColor:  theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
      borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
    },
    "&.Mui-selected.Mui-focusVisible": {
      backgroundColor: theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
      borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
    }
  }

  return (
    <FormControl sx={{ width: "20%", maxHeight: height ?? "3.25rem" }}>
      <Select
        className="slct"
        displayEmpty
        inputProps={{ "aria-label": "Select option" }}
        disabled={loading || disabled}
        id="demo-multiple-checkbox"
        multiple
        value={value}
        IconComponent={ChevronDownIcon}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          `${label} ${selected?.length ? `(${selected?.length})` : ""}`
        }
        MenuProps={MenuProps}
        sx={{
          height: "1",
          border: `1px solid ${theme.palette.random[500]} `,
          color: value.length === 0 ? theme.palette.secondaryColors[300] : theme.palette.baseColors.black,
          backgroundColor: value.length === 0 ? theme.palette.baseColors.white : theme.palette.neutralColors.black[100],
          "& legend": { display: "none", width: "unset" },
          "& fieldset": { top: 0, border: "none" },
          ...style
        }}
      >
        {options?.map((name) => (
          <MenuItem
            sx={menuItemSx}
            key={name}
            value={name}
          >
            <Checkbox
              checked={value.indexOf(name) > -1}
              sx={checkboxSx}
              disableRipple
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
