import * as mui from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { WhiteCard } from '../../../WhiteCard';
import { typographyStyle } from '../..';
import { LockIcon } from '../../../../../components/common/CustomIcons/LockIcon';
import { SquareIcon } from '../../../../../components/common/CustomIcons/SquareIcon';
import { SquareIconChecked } from '../../../../../components/common/CustomIcons/SquareIconChecked';
import { TextArea } from '../../../../../components/common/Input/TextArea';
import { FileUploader } from '../../../../../components/common/FileUploader/FileUploader';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_EXTENSIONS,
  FORMAT_TO_EXTENSIONS_MAPPING,
} from '../../../../../components/common/FileUploader/constants';
import { ListUploadedFiles } from '../../../Notes/ListUploadedFiles';
import { CloseIcon } from '../../../../../components/common/CustomIcons/CloseIcon';
import { WarningPopup } from '../../../../../components/common/Modal/WarningPopup';
import { BtnFilled } from '../../../../../components/common/Buttons/BtnFilled';
import { BtnStandard } from '../../../../../components/common/Buttons/BtnStandard';
import { useObligation, useUser } from '../../../../../context';
import { getCurrencySymbol, getDropDownOptions } from '../../Utility/helperFunctions';
import { useParams } from 'react-router-dom';
import { useReconciliation } from '../../../../../context/Reconciliation/ReconciliationContext';

export const ManualAdjustmentModal = ({
  preValues,
  item,
  currencyCode,
  handleResolveStatus,
  setPrePopulateValues,
}) => {
  const theme = mui.useTheme();
  const HeaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1244px',
    padding: '32px',
    pb: 0,
  };
  const style = {
    width: '100%',
    height: '50vh',
    border: 'none',
    padding: '2px',
    pb: '5rem',
    pr: '1rem',
    overflow: 'auto',
  };
  const TextFieldStyle = {
    width: '368px',
    height: '50px',
    borderRadius: '4px',
    pt: 0,
    border: `1px solid ${theme.palette.secondaryColors[400]}`,
    bgcolor: theme.palette.random[1100],
  };
  const { selectedObgDetails } = useObligation();
  const { userToken, userId } = useUser();
  const { modalNo, setModalNo } = useReconciliation();

  const { id } = useParams();
  const navigate = useNavigate();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const gmtDayjs = dayjs().tz('GMT');

  const InputFields = [
    { name: 'Adjustment ID', value: preValues?.transaction_id },
    { name: 'GL Account', value: preValues?.glaccount },
    { name: 'Entry Date', value: dayjs(preValues?.entry_date).format('MM/DD/YYYY') },
  ];
  const RadioFields = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  const [adjustmentTypeDropDownOption, setAdjustmentTypeDropDownOption] = useState([]);
  const [adjustmentType, setAdjustmentType] = useState('');
  const [postingDate, setPostingDate] = useState(gmtDayjs.format('YYYY-MM-DD'));
  const [varianceAmount, setVarianceAmount] = useState(item.Difference);
  const [carryForward, setCarryForward] = useState(true);
  const [notes, setNotes] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(-1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState(null);
  const [cancel, setCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);

  const handleClose = () => setCancel(true);

  const handleAdjustmentTypeChange = (event) => {
    setAdjustmentType(event.target.value);
  };

  const fileUploadHandler = (e) => {
    const uploadedPreviously = [...attachments];
    const uploadedNow = e.target.files;
    const remainingCapacity = 50 - uploadedPreviously.length;
    if (uploadedNow.length > remainingCapacity) {
      setUploadError(`File upload failed. You can only upload a maximum of ${50} files.`);
      return;
    }
    const isInvalidFileType = [...e.target.files].some((f) => {
      if (!ALLOWED_FILE_TYPES.includes(f.type) || f.name?.split('.')?.length > 2) {
        return true;
      }
      return false;
    });
    if (isInvalidFileType) {
      setUploadError(
        `File upload failed. You can only upload ${ALLOWED_EXTENSIONS.join(
          ', '
        )} file formats. Multi-extension files are not allowed.`
      );
      return;
    }
    const isInvalidFileSize = [...e.target.files].some((f) => {
      const fileSizeInKB = f.size / 1024;
      if (fileSizeInKB > 5120) {
        return true;
      }
      return false;
    });
    if (isInvalidFileSize) {
      setUploadError(`File upload failed. You can upload files with size upto 10 MB only`);
      return;
    }
    setUploadError('');
    const totalUniqueFiles = [];
    for (const file of uploadedNow) {
      const res = uploadedPreviously.filter((f) => f.name === file.name);
      if (res?.length === 0) {
        totalUniqueFiles.push(file);
      }
    }
    setAttachments((prev) => [...prev, ...totalUniqueFiles]);
  };

  const sortFileDataHandler = (colName) => {
    if (colName === 'Attachment') {
      setSortColumn('Attachment name');
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...attachments].sort(
        (a, b) => attachmentNameSortOrder * a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setAttachments(sortedData);
    } else if (colName === 'Attachment type') {
      setSortColumn('Attachment type');
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...attachments].sort((a, b) => {
        const extensionA = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === a.type
        )?.extension;
        const extensionB = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === b.type
        )?.extension;
        return attachmentTypeSortOrder * extensionA.localeCompare(extensionB);
      });
      setAttachments(sortedData);
    }
  };

  const fileDeleteHandler = (name) => {
    const remainingFiles = [...attachments].filter((f) => f.name !== name);
    setAttachments(remainingFiles);
  };

  useEffect(() => {
    if (userToken && modalNo === 2) {
      let url = '';
      url = process.env.REACT_APP_BACKEND_URL + '/reconciliation/adjustment_types';

      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setAdjustmentTypeDropDownOption(getDropDownOptions(data));
        })
        .catch((err) => { })
        .finally(() => { });
    }
  }, [modalNo, userToken]);

  const resolveHandler = () => {
    if (parseFloat(varianceAmount) === 0) {
      setWarningPopup(true);
    }
    if (userToken && parseFloat(varianceAmount) !== 0) {
      const hasInvalidFile = attachments.some(
        (f) => f.name?.split('.').length > 2 || !ALLOWED_FILE_TYPES.includes(f.type)
      );
      if (hasInvalidFile) {
        return;
      }
      setLoading(true);
      let url = '';
      url =
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/createmanualadjustment?directory_name=recociliation/manualadjustment/&entity=${item?.Entity
        }&glaccount=${preValues?.glaccount}
&entry_date=${preValues?.entry_date}&posting_date=${dayjs(postingDate).format(
          'YYYY-MM-DD'
        )}&variance_amount=${varianceAmount}&carry_forward=${carryForward}&notes=${notes}&obligation_id=${id}
&adjustment_type=${adjustmentType}&transaction_id=${preValues?.transaction_id}&difference=${item?.Difference
        }&statcurrencycode=${currencyCode}
`;

      const formData = new FormData();
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          formData.append('files', attachments[i]);
        }
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        body: attachments.length > 0 ? formData : null,
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setModalNo(-1);
          setPrePopulateValues({});
          if (data.status === 'Error') {
            if (data.detail.includes('Invalid document type')) {
              handleResolveStatus('failure', data.detail);
            } else throw new Error(`Something went wrong`);
          } else if ((data.status = 'success'))
            handleResolveStatus('success', 'Manual Adjustment transaction success');
          else throw new Error('something went wrong');
        })
        .catch((err) => {
          handleResolveStatus('failure', 'Manual Adjustment transaction failure');
        })
        .finally(() => {
          setLoading(false);
        });
      const dateAdded = gmtDayjs.format('YYYY-MM-DDTHH:mm:ss.SSS');
      if (notes) {
        fetch(
          process.env.REACT_APP_BACKEND_URL +
          `/note/createnote?obligation_id=${id}&added_by=${userId}&note_title=Manual Adjustment&note_description=${notes}&relates_to=Reconciliations&date_added=${dateAdded}
&tax_type=${selectedObgDetails.tax_type}&entity=${item.Entity.split(' ')[0]}`,
          {
            method: 'POST',
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              Authorization: `Bearer ${userToken}`,
            },
            body: attachments.length > 0 ? formData : null,
            credentials: 'include',
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            }
            throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
          })
          .then((data) => {
            if (data.status === 'Error') {
              if (data.detail.includes('Invalid document type')) {
                handleResolveStatus('failure', data.detail);
              } else throw new Error(`Something went wrong.`);
            } else {
              handleResolveStatus('success', 'Manual Adjustment notes addition success', 'notes');
            }
          })
          .catch((err) => {
            handleResolveStatus('failure', 'Manual Adjustment notes addition failed', 'notes');
          })
          .finally(() => { });
      }
    }
  };

  return (
    <mui.Modal
      open={true}
      style={{ position: 'fixed' }}>
      <WhiteCard style={HeaderStyle}>
        <mui.Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          sx={{ borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}` }}>
          <mui.Typography
            id='modal-modal-title'
            sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey, {
              borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
            })}>
            Manual Adjustment
          </mui.Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </mui.Stack>

        <WhiteCard style={style}>
          <mui.Stack gap={'2rem'}>
            <mui.Grid
              container
              width={'100%'}
              direction={'row'}
              spacing={2}>
              {InputFields.map((inputField) => {
                return (
                  <mui.Grid
                    item
                    xs={4}>
                    <mui.FormControl>
                      <mui.FormHelperText
                        id='helper-text'
                        sx={{ m: 0 }}>
                        <mui.Typography
                          sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                          {inputField.name}
                        </mui.Typography>
                      </mui.FormHelperText>
                      <mui.FilledInput
                        style={TextFieldStyle}
                        sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                        id='filled-adornment-weight'
                        endAdornment={
                          <mui.InputAdornment position='end'>
                            <LockIcon />
                          </mui.InputAdornment>
                        }
                        aria-describedby='helper-text'
                        disableUnderline
                        hiddenLabel
                        disabled
                        value={inputField.value}
                      />
                    </mui.FormControl>
                  </mui.Grid>
                );
              })}
            </mui.Grid>
            <mui.Grid
              container
              spacing={2}>
              <mui.Grid item>
                <mui.FormHelperText
                  id='helper-text'
                  sx={{ m: 0 }}>
                  <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                    {'Adjustment Type'}
                  </mui.Typography>
                </mui.FormHelperText>
                <mui.Select
                  displayEmpty
                  style={TextFieldStyle}
                  value={adjustmentType}
                  onChange={handleAdjustmentTypeChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{
                    borderWidth: '0px !important',
                    // THIS OVERRIDES THE BORDER WIDTH FOR THE MAIN SELECT OBJECT.
                    '.MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px !important',
                    },
                    '.MuiSelect-nativeInput': {
                      borderWidth: '1px !important',
                    },
                  }}>
                  <mui.MenuItem
                    disabled
                    value=''>
                    Select Adjustment Type
                  </mui.MenuItem>
                  {adjustmentTypeDropDownOption?.map((option) => {
                    return <mui.MenuItem value={option.value}>{option.name}</mui.MenuItem>;
                  })}
                </mui.Select>
              </mui.Grid>
              <mui.Grid item>
                <mui.FormHelperText
                  id='helper-text'
                  sx={{ m: 0 }}>
                  <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                    {'Posting Date'}
                  </mui.Typography>
                </mui.FormHelperText>
                <mui.OutlinedInput
                  style={{
                    width: '368px',
                    height: '50px',
                    borderRadius: '4px',
                    pt: 0,
                  }}
                  type='date'
                  sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                  id='filled-adornment-weight'
                  aria-describedby='helper-text'
                  onChange={(e) => {
                    setPostingDate(e.target.value);
                  }}
                  disableUnderline
                  hiddenLabel
                  value={postingDate}
                />
              </mui.Grid>
              <mui.Grid item>
                <mui.FormHelperText
                  id='helper-text'
                  sx={{ m: 0 }}>
                  <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                    {'Amount'}
                  </mui.Typography>
                </mui.FormHelperText>
                <mui.OutlinedInput
                  style={{
                    width: '368px',
                    height: '50px',
                    borderRadius: '4px',
                    pt: 0,
                  }}
                  sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                  id='filled-adornment-weight'
                  startAdornment={
                    <mui.InputAdornment position='start'>
                      {getCurrencySymbol(currencyCode)}
                    </mui.InputAdornment>
                  }
                  aria-describedby='helper-text'
                  disableUnderline
                  hiddenLabel
                  type='number'
                  autoComplete='off'
                  onChange={(e) => setVarianceAmount(e.target.value)}
                  value={varianceAmount}
                />
              </mui.Grid>
            </mui.Grid>
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1.125rem', 500, theme.palette.baseColors.grey)}>
                Do you want to carry forward this adjustment as a reversal in the next period?
              </mui.Typography>
              <mui.RadioGroup
                name='use-radio-group'
                value={carryForward}
                onChange={(e) => {
                  setCarryForward(e.target.value);
                }}
                style={{ m: 0, p: 0 }}
                sx={{ width: 'max-content' }}>
                {RadioFields.map((radioItem) => {
                  return (
                    <mui.FormControlLabel
                      value={radioItem.value}
                      label={radioItem.name}
                      control={
                        <mui.Radio
                          icon={<SquareIcon />}
                          checkedIcon={<SquareIconChecked />}
                        />
                      }
                    />
                  );
                })}
              </mui.RadioGroup>
            </mui.Stack>
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                Notes
              </mui.Typography>
              <TextArea onChange={(e) => setNotes(e.target.value)} />
            </mui.Stack>
            <FileUploader
              fileSize='5MB'
              onChange={fileUploadHandler}
              errorMsg={uploadError}
            />
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 700, theme.palette.baseColors.grey)}>
                Attachments
              </mui.Typography>
              <ListUploadedFiles
                uploadedFiles={attachments}
                fileDeleteHandler={fileDeleteHandler}
                sortFileDataHandler={sortFileDataHandler}
                sortColumn={sortColumn}
                attachmentNameSortOrder={attachmentNameSortOrder}
                attachmentTypeSortOrder={attachmentTypeSortOrder}
                extensionProcessingRequired={true}
              />
            </mui.Stack>
          </mui.Stack>
        </WhiteCard>
        <mui.Stack
          flexDirection='row'
          justifyContent='flex-end'
          gap='0.5rem'
          pb='2rem'>
          <BtnStandard
            btnTxt={'Cancel'}
            onClick={handleClose}
            disabled={loading}
            styling={{ height: '2.75rem', padding: '0.5rem 1.5rem', fontSize: '1rem' }}
          />
          <BtnFilled
            size='medium'
            styling={{
              '&:disabled': {
                backgroundColor: theme.palette.primaryColors[200],
                color: theme.palette.secondaryColors[100],
              },
            }}
            disabled={loading}
            loading={loading}
            btnTxt={'Create Adjustment'}
            onClick={resolveHandler}
          />
        </mui.Stack>
        {cancel && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setCancel(false);
            }}
            continueHandler={() => {
              setCancel(false);
              setPrePopulateValues({});
              setModalNo(-1);
            }}
            warningTitle={'Discard?'}
          />
        )}
        {warningPopup && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setWarningPopup(false);
            }}
            continueHandler={() => {
              setWarningPopup(false);
            }}
            warningTitle={'Warning!'}
            warningMessage={'variance amount cannot be 0'}
          />
        )}
      </WhiteCard>
    </mui.Modal>
  );
};
