import { createContext, useContext, useEffect, useState } from "react";

const ObligationContext = createContext();

export const useObligation = () => {
    return useContext(ObligationContext);
};

export const ObligationContextProvider = ({ children }) => {
    const [selectedObgDetails, setSelectedObgDetails] = useState({})
    const [sisterObligations, setSisterObligations] = useState([])
    const [groupObligation, setGroupObligation] = useState(null)

    const isGroupSelected = selectedObgDetails?.differentiator === "group"
    const isObligationLocked = selectedObgDetails?.stage === "Finalized"

    const [obligationReloadFlag, setObligationReloadFlag] = useState(false)
    const triggerObligationReload = () => { setObligationReloadFlag(true) }
    const acknowledgeObligationReload = () => { setObligationReloadFlag(false) }

    return (
        <ObligationContext.Provider value={{
            selectedObgDetails,
            selectedObgHandler: setSelectedObgDetails,
            sisterObligations,
            setSisterObligations,
            groupObligation,
            setGroupObligation,
            isGroupSelected,
            obligationReloadFlag,
            triggerObligationReload,
            acknowledgeObligationReload,
            isObligationLocked
        }}>
            {children}
        </ObligationContext.Provider>
    );
};
