import { Button, styled } from '@mui/material';

const StyledStandardBtn = styled(Button)(({ theme }) => ({
    backgroundColor: 'inherit',
    border: 'none',
    textTransform: 'none',
    color: theme.palette.baseColors.grey,
    borderRadius: '0.326rem',
    minWidth: 'max-content',
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: theme.palette.neutralColors.black[200],
        border: 'none'
    },
    '&:disabled': {
        backgroundColor: 'inherit',
        border: 'none',
        color: theme.palette.secondaryColors[400],
    }
}))
export const BtnStandard = ({ btnTxt, iconPosition, disabled, icon, onClick, size, styling, disabledStyling }) => {
    return (
        <StyledStandardBtn
            variant='outlined'
            startIcon={iconPosition === "start" ? icon : null}
            endIcon={iconPosition === "end" ? icon : null}
            disabled={disabled}
            onClick={onClick}
            sx={{
                height: size === "small" ? "2rem" : size === 'medium' ? '2.75rem' : "3.25rem",
                padding: size === "small" ? "0.5rem 1rem" : "0.5rem 1.5rem",
                fontSize: size === "small" ? "0.875rem" : "1rem",
                ...styling,
                '&:disabled': {
                    ...disabledStyling
                }
            }}
            disableRipple
        >
            {btnTxt}
        </StyledStandardBtn>
    )
}
