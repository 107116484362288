import { Stack, Typography } from '@mui/material';
import { currencyFormatterWithoutSymbol } from '../../../utils';

export const AdjustmentCard = ({ title, value, theme, isSelected, handleSelect }) => {
  return (
    <Stack
      sx={{
        borderRadius: '0.5rem',
        backgroundColor: theme.palette.baseColors.white,
        padding: '1.5rem',
        color: theme.palette.secondaryColors[600],
        alignItems: 'flex-start',
        height: '100%',
        border: isSelected
          ? '1px solid #D04A02'
          : `1px solid ${theme.palette.neutralColors.black[200]}`,
        background: isSelected ? '#D04A021A' : '',
        cursor: 'pointer',
      }}
      onClick={() => handleSelect(title)}>
      <Stack
        direction='row'
        alignItems='center'
        spacing='.75rem'
        sx={{
          marginBottom: '1rem',
        }}>
        <Typography
          gutterBottom
          component='h4'
          color={theme.palette.baseColors.grey}
          sx={{ fontWeight: 700, fontSize: '1.125rem' }}>
          {title}
        </Typography>
      </Stack>
      <Typography
        color='#12131A'
        sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
          lineHeight: '1.875rem',
          marginTop: '1.5rem',
        }}>
        {currencyFormatterWithoutSymbol(value)}
      </Typography>
    </Stack>
  );
};
