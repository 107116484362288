import React, { useEffect } from 'react';
import { Box, Chip, Typography, Skeleton, styled, useTheme } from '@mui/material';
import { BtnLink } from '../../../components/common/Buttons/BtnLink';
import { Cross1Icon } from '@radix-ui/react-icons';
import { FilterSelect } from './FilterSelect';
import { Datepicker } from '../../../components/common/Input/Datepicker';
import dayjs from 'dayjs';

const CustomChip = styled(Chip)(({ theme, active }) => ({
  backgroundColor: theme.palette.secondaryColors[100],
  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
  padding: '0.5rem 0rem',
  borderRadius: '6px',
  height: '2.25rem',
  fontSize: '0.875rem',
  textAlign: 'left',
}));

const Container = styled(Box)(({ theme }) => ({
  height: '3rem',
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  flexShrink: 0,
}));

export const AdjustmentsFilter = ({ filters, setFilters, data, isLoading, styling }) => {
  const theme = useTheme();

  useEffect(() => {
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-50', theme.palette.primaryColors[100]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-500', theme.palette.primaryColors[200]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-100', theme.palette.primaryColors[300]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-700', theme.palette.primaryColors[400]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-600', theme.palette.primaryColors[400]);
  }, []);

  const getFilterValuesByKey = (data, key) => {
    const allValues = data
      ?.map((item) => item[key])
      .filter((value) => value !== '' && value != null);
    const uniqueValues = [...new Set(allValues)];
    return uniqueValues;
  };

  const capitalizeNames = (name) => {
    return name
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const getUserSpecialOptions = (data) => {
    const userValues = data
      ?.map((item) => ({
        label: capitalizeNames(item.user.user_name),
        value: item.user.user_id,
      }))
      .filter((user) => user.label !== '' && user.value != null);

    const uniqueUserOptions = Array.from(
      new Set(userValues?.map((user) => JSON.stringify(user)))
    )?.map((user) => JSON.parse(user));

    return uniqueUserOptions;
  };

  const adjustmentTypeOptions = getFilterValuesByKey(data, 'adjustment_type');
  const createdByOptions = getUserSpecialOptions(data);
  const memberOptions = getFilterValuesByKey(data, 'entityname');

  const sanitizeDateFilter = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns month from 0 to 11
    const day = date.getDate();

    // Pad the month and day with leading zero if necessary
    const formattedMonth = month < 10 ? '0' + month : month;
    const formattedDay = day < 10 ? '0' + day : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value === null) {
      return setFilters({
        ...filters,
        [name]: {
          ...filters[name],
          value: undefined,
        },
      });
    } else if (name === 'createdOn') {
      return setFilters({
        ...filters,
        [name]: {
          ...filters[name],
          value: sanitizeDateFilter(value),
        },
      });
    } else if (name === 'createdBy') {
      return setFilters({
        ...filters,
        [name]: {
          ...filters[name],
          value,
          displayValue: createdByOptions.find((item) => item.value === value)?.label,
        },
      });
    }
    return setFilters({
      ...filters,
      [name]: {
        ...filters[name],
        value,
      },
    });
  };

  return (
    <Box
      sx={{
        height: 'auto',
        ...styling,
      }}>
      {isLoading ? (
        <Skeleton
          variant='rounded'
          width='70%'
          height={50}
        />
      ) : (
        <>
          <Container sx={{ height: '3.25rem', justifyContent: 'space-between' }}>
            <Container sx={{ width: '100%' }}>
              {/* Adjustment Type Select */}
              <FilterSelect
                labelId='adjustmentType-select-label'
                id='adjustmentType-select'
                name='adjustmentType'
                value={filters.adjustmentType.value || ''}
                label='Adjustment Type'
                onChange={handleChange}
                options={adjustmentTypeOptions}
              />
              {/* Created By Select */}
              <FilterSelect
                labelId='createdBy-select-label'
                id='createdBy-select'
                name='createdBy'
                displayValue={filters.createdBy.displayValue || ''}
                value={filters.createdBy.value || ''}
                label='Created By'
                onChange={handleChange}
                specialOptions={createdByOptions}
              />
              {/* Date Range Picker */}
              <Datepicker
                name='createdOn'
                placeholder='Created On'
                value={
                  filters.createdOn.value ? new Date(filters.createdOn.value + 'T00:00:00') : ''
                }
                onChange={handleChange}
                theme={theme}
                isEditable={false}
                gap='0'
              />
              {/* Member Select */}
              <FilterSelect
                labelId='member-select-label'
                id='member-select'
                name='member'
                value={filters.member.value || ''}
                label='Member'
                onChange={handleChange}
                options={memberOptions}
              />
            </Container>
          </Container>
        </>
      )}
      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex' }}>
          {filters?.adjustmentType?.value && (
            <CustomChip
              sx={{
                mt: '1rem',
                mr: '1rem',
              }}
              key={filters.adjustmentType.field}
              label={filters.adjustmentType.value}
              onDelete={() =>
                setFilters({
                  ...filters,
                  adjustmentType: {
                    ...filters.adjustmentType,
                    value: undefined,
                  },
                })
              }
              deleteIcon={
                <Cross1Icon
                  sx={{
                    marginLeft: '0.5rem',
                    color: theme.palette.baseColors.white,
                  }}
                />
              }
            />
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {filters?.createdOn?.value && (
            <CustomChip
              sx={{
                mt: '1rem',
                mr: '1rem',
              }}
              key={filters.createdOn.field}
              label={dayjs(filters.createdOn.value)?.format('DD MMM YYYY')}
              onDelete={() =>
                setFilters({
                  ...filters,
                  createdOn: {
                    ...filters.createdOn,
                    value: undefined,
                  },
                })
              }
              deleteIcon={
                <Cross1Icon
                  sx={{
                    marginLeft: '0.5rem',
                    color: theme.palette.baseColors.white,
                  }}
                />
              }
            />
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {filters?.createdBy?.value && (
            <CustomChip
              sx={{
                mt: '1rem',
                mr: '1rem',
              }}
              key={filters.createdBy.field}
              label={filters.createdBy.displayValue}
              onDelete={() =>
                setFilters({
                  ...filters,
                  createdBy: {
                    ...filters.createdBy,
                    value: undefined,
                  },
                })
              }
              deleteIcon={
                <Cross1Icon
                  sx={{
                    marginLeft: '0.5rem',
                    color: theme.palette.baseColors.white,
                  }}
                />
              }
            />
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {filters?.member?.value && (
            <CustomChip
              sx={{
                mt: '1rem',
                mr: '1rem',
              }}
              key={filters.member.field}
              label={filters.member.value}
              onDelete={() =>
                setFilters({
                  ...filters,
                  member: { ...filters.member, value: undefined },
                })
              }
              deleteIcon={
                <Cross1Icon
                  sx={{
                    marginLeft: '0.5rem',
                    color: theme.palette.baseColors.white,
                  }}
                />
              }
            />
          )}
        </Box>
        {(filters?.createdOn?.value ||
          filters?.adjustmentType?.value ||
          filters?.createdBy?.value ||
          filters?.member?.value) && (
          <Typography sx={{ textDecoration: 'underline', mt: '1.5rem' }}>
            <BtnLink
              size='small'
              btnTxt='Clear all'
              styling={{ textDecoration: 'underline', fontSize: '1rem' }}
              onClick={() => {
                setFilters({
                  createdOn: { ...filters.createdOn, value: undefined },
                  adjustmentType: {
                    ...filters.adjustmentType,
                    value: undefined,
                  },
                  createdBy: { ...filters.createdBy, value: undefined },
                  member: { ...filters.member, value: undefined },
                  input: { ...filters.input, value: undefined },
                });
              }}
            />
          </Typography>
        )}
      </Box>
    </Box>
  );
};
