import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import { Typography, Box, useTheme } from '@mui/material';
import { DatabaseIcon } from '../../../../components/common/CustomIcons/DatabaseIcon';
import TextTag from '../../../../components/common/Text Tags/TextTag';
import { styled } from '@mui/material/styles';
import { formatDate, reverseDate } from '../../../../utils';

const StyledTimeline = styled(Timeline)(({ theme }) => ({
  padding: 0,
  '& .MuiTimelineItem-root': {
    '&:before': {
      display: 'none',
    },
  },
}));

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
  '& .MuiTimelineContent-root': {
    paddingLeft: theme.spacing(2),
  },
}));

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  textAlign: 'left',
}));

export const TransactionHistory = ({ transactions }) => {
  const theme = useTheme();
  const isLastItem = (index) => index === transactions.length - 1;
  const transactionHistory = [...transactions].reverse();

  return (
    <Box
      elevation={3}
      sx={{ p: 2 }}>
      <StyledTimeline>
        {transactionHistory.map((transaction, index) => (
          <StyledTimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                color='inherit'
                sx={{ margin: 0 }}>
                <DatabaseIcon color='action' />
              </TimelineDot>
              {!isLastItem(index) && (
                <TimelineConnector
                  sx={{
                    minHeight: '75px',
                    backgroundColor: theme.palette.neutralColors.black[200],
                  }}
                />
              )}
            </TimelineSeparator>
            <StyledTimelineContent>
              <TextTag
                text={
                  transaction.input === 'original_transaction'
                    ? 'ORIGINAL TRANSACTION'
                    : 'MANUAL TRANSACTION'
                }
                variant={transaction.input === 'original_transaction' ? 'green' : 'yellow'}
                size='xs'
              />
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  color: theme.palette.secondaryColors[400],
                }}>
                {formatDate(reverseDate(transaction.entrydate, '-'), '-')}{' '}
                {transaction?.user_name && `(${transaction.user_name})`}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: theme.palette.baseColors.grey,
                }}>
                {transaction.description}
              </Typography>
            </StyledTimelineContent>
          </StyledTimelineItem>
        ))}
      </StyledTimeline>
    </Box>
  );
};
