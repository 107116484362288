import { Typography } from '@mui/material';
import { WhiteCard } from '../WhiteCard';

export const CheckAuthorization = (userToken, setAuthorized, obligationId, setLoader) => {
  if (userToken && obligationId) {
    setLoader(true);
    fetch(process.env.REACT_APP_BACKEND_URL + `/obligation/obligationaccess/${obligationId}`, {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        setAuthorized('Authorized');
      })
      .catch((err) => {
        setAuthorized('');
      })
      .finally(() => {
        setLoader(false);
      });
  }
};

export const AuthError = () => {
  return (
    <WhiteCard style={{ height: 'auto', px: '10px', m: '2rem' }}>
      <Typography sx={{ fontSize: '2rem', fontWeight: '200' }}>
        Access Denied: Your account does not have the necessary permissions to access this feature.
      </Typography>
    </WhiteCard>
  );
};
