import { Avatar, Box, Divider, Stack, Typography, styled, useTheme } from '@mui/material';
import { Logo } from '../CustomIcons/Logo';
import { useNavigate } from 'react-router-dom';
import { CLIENT } from './constants';
import { useUser } from '../../../context';
import { BtnFilled } from '../Buttons/BtnFilled';

const PrimaryNavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.625rem 1.25rem',
  width: '100%',
  backgroundColor: theme.palette.random[800],
  height: '4rem',
}));

const HeaderDivider = styled(Divider)(({ theme }) => ({
  opacity: 0.32,
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primaryColors[200],
  fontSize: '0.875rem',
  width: '2.75rem',
  height: '2.75rem',
  fontWeight: 700,
  textTransform: 'uppercase',
}));

function jsonToUrlParam(json) {
  return Object.keys(json)
    .map((key) => key + '=' + json[key])
    .join('&');
}

export const PrimaryNavbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const homepageNavigationHandler = () => {
    navigate('/home');
  };
  const userSettingsNavigationHandler = () => {
    navigate('/user-settings');
  };
  const { userInitials, auditId } = useUser();

  return (
    <PrimaryNavContainer>
      <Stack
        direction='row'
        sx={{ alignItems: 'center' }}
        spacing={1.2}>
        <Logo onClick={homepageNavigationHandler} />
        <HeaderDivider
          flexItem
          orientation='vertical'
          color={theme.palette.baseColors.white}
        />
        <Typography
          sx={{
            color: theme.palette.baseColors.white,
            fontWeight: 700,
            cursor: 'pointer',
            letterSpacing: '-0.025rem',
          }}
          component='a'
          onClick={homepageNavigationHandler}>
          {CLIENT}
        </Typography>
      </Stack>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'>

      </Stack>
    </PrimaryNavContainer>
  );
};
