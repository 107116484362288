import React, { useMemo } from "react";
import { Stack, Typography, useTheme, Grid, Divider, Skeleton } from "@mui/material";

const formatKey = (key) => {
  // Split the camelCase string into words
  const words = key.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  // Capitalize the first letter of each word and join them with a space
  const capitalizedWords = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedWords;
};

const CardInfoSlices = ({ isSelected, theme, data }) => (
  <Grid container columns={2} direction="row" sx={{ marginBottom: "25px" }}>
    {Object.entries(data).map(([key, value], index) => (
      <>
        <Grid item key={key} sx={{ margin: "-1rem 0 .5rem 0" }}>
          <Typography sx={{ fontSize: "0.875rem" }} color={isSelected ? "#FFFFFFCC" : theme.palette.random[1700]}>
            {formatKey(key)}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 700,
              marginTop: "-.5rem"
            }}
            color={isSelected ? theme.palette.baseColors.white : theme.palette.baseColors.grey}
          >
            {value}
          </Typography>
        </Grid>
        {index !== Object.entries(data).length - 1 && (
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{ margin: "-1rem 1rem" }}
            color={isSelected ? "#FFFFFFCC" : "#0000001A"}
          />
        )}
      </>
    ))}
  </Grid>
);

export const InfoCard = ({
  children,
  title,
  data,
  value,
  selectedCard,
  handleCardSelection,
  width = "auto",
  isLoading = false
}) => {
  const theme = useTheme();

  const isSelected = useMemo(
    () => title === selectedCard,
    [title, selectedCard]
  );

  return (
    <Stack
      sx={{
        borderRadius: "0.5rem",
        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
        backgroundColor: isSelected
          ? "#000000CC"
          : theme.palette.baseColors.white,
        padding: ".75rem 1.5rem",
        color: isSelected
          ? theme.palette.baseColors.white
          : theme.palette.secondaryColors[600],
        alignItems: "flex-start",
        height: "6.5rem",
        minWidth: width,
        cursor: "pointer",
        flex: "1 0 0"
      }}
      onClick={() => handleCardSelection(title)}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing=".75rem"
        color={
          isSelected
            ? theme.palette.baseColors.white
            : theme.palette.secondaryColors[400]
        }
        sx={{
          marginBottom: ".5rem",
        }}
      >
        {children}
        <Typography
          gutterBottom
          component="h4"
          sx={{
            fontWeight: 500,
            fontSize: "1.125rem"
          }}
        >
          {title}
        </Typography>
      </Stack>
      {isLoading && <Skeleton variant="rounded" height="3rem" width="10rem"/>}
      <Typography
        color={isSelected ? theme.palette.baseColors.white : theme.palette.baseColors.grey}
        sx={{
          fontSize: "1.5rem",
          fontWeight: 700,
          lineHeight: "2rem",
          margin: ".5rem 0 .5rem 0"
        }}
      >
        {value}
      </Typography>
      {data && !isLoading && <CardInfoSlices {...{ isSelected, theme, data }} />}
    </Stack>
  );
};
