import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme, active }) => ({
  color: theme.palette.secondaryColors[300],
  backgroundColor: active ? '#000000CC' : 'transparent',
  borderColor: '#0000001A',
  minWidth: '90.75px !important',
  '&:hover': {
    backgroundColor: active ? theme.palette.grey[900] : theme.palette.action.hover,
  },
  ...(active && {
    color: theme.palette.common.white,
  }),
  height: '3rem',
}));

const FilterButtons = ({ filters, updateFilters }) => {
  const selectedFilter = filters.taxType;
  const filterOptions = [
    { label: 'All', key: 'All' },
    { label: 'VAT', key: 'IDT' },
    { label: 'WHT', key: 'WHT' },
    { label: 'CIT', key: 'CIT' },
  ];

  const handleClick = (filter) => {
    updateFilters(draft => {
      draft.taxType = filter
    })
  };

  return (
    <ButtonGroup
      disableElevation
      variant='text'
      color='primary'
      aria-label='filter button group'
      sx={{
        minWidth: '363px',
        '.MuiButton-outlined': {
          borderColor: '#0000001A',
          '&:hover': { borderColor: '#0000001A' },
        },
      }}>
      {filterOptions.map((filter) => (
        <CustomButton
          key={filter.key}
          active={selectedFilter === filter.key ? 'true' : ''}
          onClick={() => handleClick(filter.key)}
          variant={selectedFilter === filter.key ? 'contained' : 'outlined'}
          disabled={filter.key !== "IDT"}
        >
          {filter.label}
        </CustomButton>
      ))}
    </ButtonGroup>
  );
};

export default FilterButtons;
