import { Button, styled } from "@mui/material";

const StyledLinkBtn = styled(Button)(({ theme }) => ({
    backgroundColor: 'inherit',
    border: 'none',
    textTransform: 'none',
    color: theme.palette.primaryColors[200],
    minWidth: 'max-content',
    whiteSpace: 'nowrap',
    padding: "0",
    textDecoration: 'underline',
    '&:hover': {
        border: 'none',
        backgroundColor: 'inherit',
        textDecoration: 'underline',
    }
}))
export const BtnLink = ({ btnTxt, disabled, onClick, size, styling }) => {
    return (
        <StyledLinkBtn
            disableRipple
            variant='outlined'
            disabled={disabled}
            onClick={onClick}
            sx={{
                height: size === "small" ? "0.875rem" : "1rem",
                fontSize: size === "small" ? "0.875rem" : "1rem",
                ...styling
            }}
        >
            {btnTxt}
        </StyledLinkBtn>
    )
}
