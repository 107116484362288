import { SvgIcon } from '@mui/material';

export const CircleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle
          cx='10'
          cy='10'
          r='9.5'
          stroke='currentColor'
        />
      </svg>
    </SvgIcon>
  );
};
