import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { WhiteCard } from '../WhiteCard';
import { BtnFilled } from '../../../components/common/Buttons/BtnFilled';
import { AddIcon } from '../../../components/common/CustomIcons/AddIcon';
import { SortIcon } from '../../../components/common/CustomIcons/SortIcon';
import { AttachmentIcon } from '../../../components/common/CustomIcons/AttachmentIcon';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CreateNote } from './CreateNote';
import { useUser } from '../../../context';
import { EditNote } from './EditNote';
import { SortAscIcon } from '../../../components/common/CustomIcons/SortAscIcon';
import { SortDescIcon } from '../../../components/common/CustomIcons/SortDescIcon';
import { Toast } from '../Toast';
import Paginator from '../../../components/common/Paginator/Paginator';
import { DownloadAttachments } from './DownloadAttachments';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useObligation } from '../../../context';
import { StyledLinearProgress } from '../../../components/common/Progress/StyledLinearProgress';

const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.baseColors.grey,
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  cursor: 'pointer',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 400,
  // todo: check below styling again
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  width: 100,
  maxWidth: 100,
}));
//reduced padding in table headers cell and body cell for this component by 0.5rem
export const ListNotes = ({ style }) => {
  const theme = useTheme();
  const [obligationNotes, setObligationNotes] = useState([]);
  const [createNewNoteProps, setCreateNewNoteProps] = useState(null);
  const [editNoteProps, setEditNoteProps] = useState(null);
  const [downloadNoteProps, setDownloadNoteProps] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [dateSortingOrder, setDateSortingOrder] = useState('desc');
  const [loading, setLoading] = useState(false);
  const { userToken } = useUser();
  const [toast, setToast] = useState({ type: '', message: '' });
  const [totalRecords, setTotalRecords] = useState(null);
  const { pageSize } = useUser();
  const [pageNo, setPageNo] = useState(1);
  const { tab } = useParams();
  const { selectedObgDetails, isObligationLocked, isGroupSelected } = useObligation();
  const navigate = useNavigate();
  // const obligation = selectedObgDetails

  useEffect(() => {
    if (
      (sortingOrder || dateSortingOrder) &&
      sortedColumn &&
      selectedObgDetails.obligation_id &&
      userToken &&
      tab &&
      pageNo &&
      pageSize
    ) {
      setLoading(true);
      const url =
        tab === 'overview'
          ? process.env.REACT_APP_BACKEND_URL +
          `/note/listall?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26${isGroupSelected
            ? 'entity__is_=null'
            : `entity__eq=${selectedObgDetails?.org_heirarchy_code}`
          }&sort_column=${sortedColumn}&sort_dir=${sortedColumn === 'date_modified' || sortedColumn === 'date_added'
            ? dateSortingOrder
            : sortingOrder
          }&limit=${pageSize}&page=${pageNo}`
          : process.env.REACT_APP_BACKEND_URL +
          `/note/listall?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26${isGroupSelected
            ? 'entity__is_=null'
            : `entity__eq=${selectedObgDetails?.org_heirarchy_code}`
          }%26relates_to__eq=${tab?.charAt(0)?.toUpperCase() + tab?.slice(1)
          }&sort_column=${sortedColumn}&sort_dir=${sortedColumn === 'date_modified' || sortedColumn === 'date_added'
            ? dateSortingOrder
            : sortingOrder
          }&limit=${pageSize}&page=${pageNo}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setObligationNotes(data?.notes);
          setTotalRecords(data?.count);
        })
        .catch((err) => {
          console.error(err);
          setToast({
            type: 'failure',
            message: 'Data could not be sorted.',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (selectedObgDetails.obligation_id && userToken && tab && pageNo && pageSize) {
      setLoading(true);
      const url =
        tab === 'overview'
          ? process.env.REACT_APP_BACKEND_URL +
          `/note/listall?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26${isGroupSelected
            ? 'entity__is_=null'
            : `entity__eq=${selectedObgDetails?.org_heirarchy_code}`
          }&sort_column=date_added&sort_dir=desc&limit=${pageSize}&page=${pageNo}`
          : process.env.REACT_APP_BACKEND_URL +
          `/note/listall?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26${isGroupSelected
            ? 'entity__is_=null'
            : `entity__eq=${selectedObgDetails?.org_heirarchy_code}`
          }%26relates_to__eq=${tab?.charAt(0)?.toUpperCase() + tab?.slice(1)
          }&sort_column=date_added&sort_dir=desc&limit=${pageSize}&page=${pageNo}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setObligationNotes(data?.notes);
          setTotalRecords(data?.count);
        })
        .catch((err) => {
          console.error(err);
          setToast({
            type: 'failure',
            message: 'Could not fetch data.',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    sortingOrder,
    dateSortingOrder,
    sortedColumn,
    userToken,
    tab,
    pageNo,
    pageSize,
    selectedObgDetails,
  ]);

  const getSortedDataHandler = (col_name) => {
    if (col_name === 'date_added' || col_name === 'date_modified') {
      if (col_name === sortedColumn) {
        setDateSortingOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortedColumn(col_name);
        setDateSortingOrder('desc');
      }
    } else {
      if (col_name === sortedColumn) {
        setSortingOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortedColumn(col_name);
        setSortingOrder('asc');
      }
    }
  };

  const noteEditSuccessfulHandler = (note_id, new_title, new_desc, new_modification_date) => {
    const editedNotes = obligationNotes.map((obj) => {
      if (obj.note_id === note_id) {
        return {
          ...obj,
          note_title: new_title,
          note_description: new_desc,
          date_modified: new_modification_date,
        };
      }
      return obj;
    });
    setObligationNotes(editedNotes);
    setToast({
      type: 'success',
      message: 'Your note has been edited.',
    });
  };

  const noteAdditionSuccessfulHandler = () => {
    setToast({
      type: 'success',
      message: 'Your note has been saved.',
    });
    setLoading(true);
    let sortInfo = `&sort_column=date_added&sort_dir=desc`;
    if (sortedColumn) {
      sortInfo = `&sort_column=${sortedColumn}&sort_dir=${sortingOrder}`;
    }
    const url =
      tab === 'overview'
        ? process.env.REACT_APP_BACKEND_URL +
        `/note/listall?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26${isGroupSelected
          ? 'entity__is_=null'
          : `entity__eq=${selectedObgDetails?.org_heirarchy_code}`
        }${sortInfo}&limit=${pageSize}&page=${pageNo}`
        : process.env.REACT_APP_BACKEND_URL +
        `/note/listall?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26${isGroupSelected
          ? 'entity__is_=null'
          : `entity__eq=${selectedObgDetails?.org_heirarchy_code}`
        }%26relates_to__eq=${tab?.charAt(0)?.toUpperCase() + tab?.slice(1)
        }${sortInfo}&limit=${pageSize}&page=${pageNo}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        setObligationNotes(data?.notes);
        setTotalRecords(data?.count);
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Failed to fetch data.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const noteAdditionFailureHandler = (msg) => {
    setToast({
      type: 'failure',
      message: msg ?? 'Your note could not be saved.',
    });
  };

  const noteEditFailureHandler = () => {
    setToast({
      type: 'failure',
      message: 'Your note could not be edited.',
    });
  };
  const pageNoChangeHandler = (new_page_no) => {
    setPageNo(new_page_no);
  };
  return (
    <WhiteCard style={style}>
      <Stack
        direction='row'
        justifyContent='space-between'>
        <Typography
          sx={{
            color: theme.palette.random[300],
            fontWeight: 700,
            fontSize: '1.125rem',
          }}>
          Notes
        </Typography>
        <BtnFilled
          size='small'
          iconPosition='start'
          btnTxt='Add a note'
          icon={<AddIcon />}
          onClick={() => {
            setCreateNewNoteProps({
              obligationName: selectedObgDetails?.obligation_name,
              obligationId: selectedObgDetails?.obligation_id,
              obligationTaxType: selectedObgDetails?.tax_type,
              entityCode: selectedObgDetails?.org_heirarchy_code,
            });
          }}
          disabled={isObligationLocked}
        />
      </Stack>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 'none', overflow: 'initial' }}>
        <Table
          aria-label='selected files'
          sx={{
            borderRadius: '0.5rem',
            border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            overflow: 'scroll',
          }}>
          <TableHead
            sx={{
              backgroundColor: theme.palette.secondaryColors[100],
              height: '2.5rem',
            }}>
            <TableRow variant='body2'>
              <StyledTableHeadingCell
                style={{ width: '12%', maxWidth: '12%' }}
                onClick={() => {
                  getSortedDataHandler('note_title');
                }}>
                Note Title
                <IconButton disableRipple>
                  {sortedColumn === 'note_title' ? (
                    sortingOrder === 'asc' ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell
                style={{ width: '12%', maxWidth: '12%' }}
                onClick={() => {
                  getSortedDataHandler('relates_to');
                }}>
                Relates To
                <IconButton disableRipple>
                  {sortedColumn === 'relates_to' ? (
                    sortingOrder === 'asc' ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell
                style={{ width: '12%', maxWidth: '12%' }}
                onClick={() => {
                  getSortedDataHandler('entity');
                }}>
                Entity
                <IconButton disableRipple>
                  {sortedColumn === 'entity' ? (
                    sortingOrder === 'asc' ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell
                style={{ width: '18%', maxWidth: '18%' }}
                onClick={() => {
                  getSortedDataHandler('note_description');
                }}>
                Note Description
                <IconButton disableRipple>
                  {sortedColumn === 'note_description' ? (
                    sortingOrder === 'asc' ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell
                style={{ width: '12%', maxWidth: '12%' }}
                onClick={() => {
                  getSortedDataHandler('added_by');
                }}>
                Added By
                <IconButton disableRipple>
                  {sortedColumn === 'added_by' ? (
                    sortingOrder === 'asc' ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell
                style={{ width: '12%', maxWidth: '12%' }}
                onClick={() => {
                  getSortedDataHandler('date_added');
                }}>
                Date Added
                <IconButton disableRipple>
                  {sortedColumn === 'date_added' ? (
                    dateSortingOrder === 'asc' ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell
                style={{ width: '14%', maxWidth: '14%' }}
                onClick={() => {
                  getSortedDataHandler('date_modified');
                }}>
                Date Modified
                <IconButton disableRipple>
                  {sortedColumn === 'date_modified' ? (
                    dateSortingOrder === 'asc' ? (
                      <SortDescIcon />
                    ) : (
                      <SortAscIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell style={{ width: '8%', maxWidth: '8%' }}>
                Attachments
              </StyledTableHeadingCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={12}
                  sx={{ p: 0 }}>
                  <StyledLinearProgress />
                </TableCell>
              </TableRow>
            )}
            {obligationNotes?.length > 0 ? (
              obligationNotes?.map((note) => (
                <TableRow
                  key={note.note_id}
                  sx={{
                    height: '3rem',
                    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  }}>
                  <StyledTableBodyCell
                    sx={{
                      color: theme.palette.primaryColors[200],
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      setEditNoteProps({
                        note: note,
                        closePopupHandler: () => {
                          setEditNoteProps(null);
                        },
                        noteEditSuccessfulHandler: noteEditSuccessfulHandler,
                        noteEditFailureHandler: noteEditFailureHandler,
                      })
                    }>
                    {note.note_title}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>{note.relates_to}</StyledTableBodyCell>
                  <StyledTableBodyCell>{note.entity_details?.name}</StyledTableBodyCell>
                  <StyledTableBodyCell>{note.note_description}</StyledTableBodyCell>
                  <StyledTableBodyCell sx={{ textTransform: 'capitalize' }}>
                    {note.added_by_details?.user_name}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {note.date_added
                      ? dayjs(note.date_added?.split('T')?.[0])?.format('DD MMM YYYY')
                      : '-'}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {note.date_modified
                      ? dayjs(note.date_modified?.split('T')?.[0])?.format('DD MMM YYYY')
                      : '-'}
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {note.attachments.length > 0 && (
                      <IconButton disableRipple>
                        <AttachmentIcon
                          sx={{
                            cursor: 'pointer',
                            color: theme.palette.baseColors.grey,
                            '&:hover': {
                              color: theme.palette.primaryColors[200],
                            },
                          }}
                          onClick={() => {
                            setDownloadNoteProps({
                              note: note,
                              closePopupHandler: () => {
                                setDownloadNoteProps(null);
                              },
                              downloadFailureHandler: () => {
                                setToast({
                                  type: 'failure',
                                  message: 'File downloaded failed.',
                                });
                              },
                            });
                          }}
                        />
                      </IconButton>
                    )}
                    {note.attachments?.length > 1 && `+${note.attachments?.length - 1}`}
                  </StyledTableBodyCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  height: '3rem',
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                }}>
                <TableCell
                  colSpan={12}
                  sx={{ textAlign: 'center' }}>
                  {loading ? 'Fetching data...' : 'No data to display'}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Paginator
          totalRecords={totalRecords}
          pageNoChangeHandler={pageNoChangeHandler}
          pageNo={pageNo}
        />
      </TableContainer>
      {createNewNoteProps && (
        <CreateNote
          closePopupHandler={() => {
            setCreateNewNoteProps(null);
          }}
          tabName={tab}
          {...createNewNoteProps}
          noteAdditionSuccessfulHandler={noteAdditionSuccessfulHandler}
          noteAdditionFailureHandler={noteAdditionFailureHandler}
        />
      )}
      {editNoteProps && <EditNote {...editNoteProps} />}
      {downloadNoteProps && <DownloadAttachments {...downloadNoteProps} />}

      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </WhiteCard>
  );
};
