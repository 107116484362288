import { useLocation } from "react-router-dom";
import { IframeScreen } from "../../../../components/common/Iframe/IframeScreen";
import { GENERAL_ANALYTICS_CARDS_DATA, getGeneralAnalyticsCardDescription, getOperatingAnalyticsCardDescription } from "../constants";
import { useState, useEffect } from "react";
import { useObligation } from "../../../../context";
import { Looker } from "../../../../components/common/Looker/Looker";
import { lookerEmbedDomain, lookerEntityCodeList } from "../../../../utils";
import { INCONSISTENT_CODING_CARD_INFO } from "../../lookerConstants";
import { PageSkeleton } from "../PageSkeleton";
import { ExpandableLookerGrid } from "../../../../components/common/Looker/ExpandableLookerGrid";

export const InconsistentCoding = () => {
  const { isGroupSelected, sisterObligations, selectedObgDetails } = useObligation();

  return (
    <PageSkeleton
      title="Inconsistent Coding"
      description={getGeneralAnalyticsCardDescription("Inconsistent Coding")}
      url=".."
      isURLRelative
    >
      <ExpandableLookerGrid
        cards={INCONSISTENT_CODING_CARD_INFO}
        dashboardParameters={{
          "Obligation ID": selectedObgDetails?.obligation_id,
          "Entitycode": lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails)
        }}
        downloadConstants={{
          dashboard: process.env.REACT_APP_LOOKER_INCONSISTENT_CODING,
          filename: `${selectedObgDetails?.obligation_name}_Inconsistent_Coding`,
          title: "Inconsistent Coding"
        }}
      />
    </PageSkeleton>
  )
};