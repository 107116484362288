import { Button, styled } from '@mui/material';

const StyledLinkBtnWithIcon = styled(Button)(({ theme }) => ({
    backgroundColor: 'inherit',
    border: 'none',
    textTransform: 'none',
    color: theme.palette.primaryColors[200],
    minWidth: 'max-content',
    whiteSpace: 'nowrap',
    textDecoration: "underline",
    padding: "0.25rem 0.5rem",
    '&:hover': {
        border: 'none',
        backgroundColor: 'inherit',
        textDecoration: 'underline',
    }
}))
export const BtnLinkWithIcon = ({ btnTxt, disabled, icon, onClick, size }) => {
    return (
        <StyledLinkBtnWithIcon
            variant='outlined'
            endIcon={icon}
            disabled={disabled}
            onClick={onClick}
            sx={{
                height: size === "small" ? "0.875rem" : "1rem",
                fontSize: size === "small" ? "0.875rem" : "1rem",
            }}
            disableRipple
        >
            {btnTxt}
        </StyledLinkBtnWithIcon>
    )
}
