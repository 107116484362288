import { useEffect, useState } from 'react';

import CryptoES from 'crypto-es';
import { stringify } from 'qs';
import { useUser } from '../../../context';
import { forceUnicodeEncoding, nonce } from '../../../utils';
import { IframeScreen } from '../../common/Iframe/IframeScreen';
//import { OPERATING_ANALYTICS_CARDS_DATA } from "../../pages/Obligations/Analytics/constants";

// Adapted from this https://github.com/looker/looker_embed_sso_examples/blob/master/node_example.js

const signEmbedUrl = (dashboard, user) => {
  // looker options
  const secret = process.env.REACT_APP_LOOKER_EMBED_SECRET;
  const host = process.env.REACT_APP_LOOKER_HOST;

  // user options
  const json_external_user_id = JSON.stringify(user);
  const json_first_name = JSON.stringify('');
  const json_last_name = JSON.stringify('');
  const json_permissions = JSON.stringify(['access_data', 'see_looks']);
  const json_models = JSON.stringify([process.env.REACT_APP_LOOKER_MODEL]);
  const json_group_ids = JSON.stringify([process.env.REACT_APP_LOOKER_GROUP_ID]);
  const json_external_group_id = JSON.stringify('');
  const json_user_attributes = JSON.stringify({});
  const json_access_filters = JSON.stringify({});

  // url/session specific options
  const embed_path = '/login/embed/' + encodeURIComponent(dashboard);
  const json_session_length = JSON.stringify(3600);
  const json_force_logout_login = JSON.stringify(true);

  // computed options
  const json_time = JSON.stringify(Math.round(+new Date() / 1000));
  const json_nonce = JSON.stringify(nonce(16));

  // compute signature
  let string_to_sign = '';
  string_to_sign += host + '\n';
  string_to_sign += embed_path + '\n';
  string_to_sign += json_nonce + '\n';
  string_to_sign += json_time + '\n';
  string_to_sign += json_session_length + '\n';
  string_to_sign += json_external_user_id + '\n';
  string_to_sign += json_permissions + '\n';
  string_to_sign += json_models + '\n';
  string_to_sign += json_group_ids + '\n';
  string_to_sign += json_external_group_id + '\n';
  string_to_sign += json_user_attributes + '\n';
  string_to_sign += json_access_filters;

  let signature = CryptoES.HmacSHA1(forceUnicodeEncoding(string_to_sign), secret);
  signature = CryptoES.enc.Base64.stringify(signature).trim();

  // construct query string
  const query_params = {
    nonce: json_nonce,
    time: json_time,
    session_length: json_session_length,
    external_user_id: json_external_user_id,
    permissions: json_permissions,
    models: json_models,
    access_filters: json_access_filters,
    first_name: json_first_name,
    last_name: json_last_name,
    group_ids: json_group_ids,
    external_group_id: json_external_group_id,
    user_attributes: json_user_attributes,
    force_logout_login: json_force_logout_login,
    signature: signature,
  };

  const query_string = stringify(query_params);

  return host + embed_path + '?' + query_string;
};

const signedEmbedUrlGenerator = (dashboard, user) => {
  const url = signEmbedUrl(dashboard, user);
  return 'https://' + url;
};

export const Looker = ({
  dashboard,
  urlLock = false,
  urlUnlock,
  title,
  description,
  iframeTitle,
  header = true,
  onIframeMessage,
  disableDynamicHeight,
  children
  // , aspectRatio ?
}) => {
  const { userId } = useUser();
  const [embedURL, setEmbedURL] = useState('');
  const [dynamicHeight, setDynamicHeight] = useState(500);

  useEffect(() => {
    if (!userId) return
    if (urlLock) return urlUnlock()

    let _embedURL = signedEmbedUrlGenerator(dashboard, userId);
    setEmbedURL(_embedURL);
  }, [userId, dashboard]);

  return (
    <IframeScreen
      title={title}
      description={description}
      iframeTitle={iframeTitle}
      iframeLink={embedURL}
      height={disableDynamicHeight ? "100%" : dynamicHeight + 'px'}
      header={header}
      onIframeMessage={(data) => {
        if (onIframeMessage) onIframeMessage(data);
        if (data?.type === 'page:properties:changed' && !disableDynamicHeight) setDynamicHeight(data?.height);
      }}
    >
      {children}
    </IframeScreen>
  );
};
