import * as mui from '@mui/material';
import { BtnStandard } from '../../../components/common/Buttons/BtnStandard';
import { ArrowLeftIcon } from '../../../components/common/CustomIcons/ArrowLeftIcon';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { WhiteCard } from '../WhiteCard';
import TextTag from '../../../components/common/Text Tags/TextTag';
import { PaginatedTable } from '../../../components/Paginated Table/PaginatedTable';
import { TransactionFilter } from '../../../components/common/Filters/TransactionFilter';
import { useEffect, useState } from 'react';
import { useObligation, useUser } from '../../../context';
import {
  formatDate,
  reverseDate,
  conditionalEntityCodeDefaultQuery,
  getCurrentRelativePath,
  currencyFormatterWithoutSymbol,
  nullableInvoiceNumber,
} from '../../../utils';
import { handleSpecialFilters } from './utils';
import { StyledLinearProgress } from '../../../components/common/Progress/StyledLinearProgress';
import { BackButton } from '../../../components/common/Buttons/Utility/BackButton';
import { UseAuth } from '../../../context/AuthContext';
import { AuthError, CheckAuthorization } from '../Authorization/Authorization';
import { Toast } from '../Toast';

export const ListTransaction = () => {
  const StyledTableBodyCell = mui.styled(mui.TableCell)(({ theme }) => ({
    fontSize: '0.875rem',
    padding: '0.25rem 0.5rem',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.baseColors.grey,
    height: '3rem',
  }));

  const theme = mui.useTheme();
  const navigate = useNavigate();
  const { userToken } = useUser();
  const { id, member } = useParams();
  const { selectedObgDetails, selectedObgHandler } = useObligation();
  const [searchParams] = useSearchParams();
  const [customFilters, setCustomFilters] = useState([]);
  const [tableDefaultFilters, setTableDefaultFilters] = useState([]);
  const [isLoadingParams, setIsLoadingParams] = useState(true);
  const [toast, setToast] = useState({ type: '', message: '' });
  const [heading, setHeading] = useState('');
  const backAddress = searchParams.get('backAddress');
  const allowedSearchParams = ['exceptionTag', 'tab', 'glNo'];
  const { authorized, setAuthorized, authLoader, setAuthLoader } = UseAuth();

  useEffect(() => {
    setIsLoadingParams(true);
    const params = {};
    searchParams.forEach((value, key) => {
      if (!allowedSearchParams.includes(key)) return;
      params[key] = value;
    });

    let newCustomFilters = [...customFilters];

    if (params.glNo) {
      newCustomFilters.push({
        condition: { label: 'Equal to', operator: 'eq' },
        field: { column_name: 'GL Account No', mapping: 'glaccountno', type: 'text' },
        displayValue: params.glNo,
        queryValue: params.glNo,
      });
    }

    // Handling exceptionTag to create filters directly
    if (params.exceptionTag) {
      const exceptionTag = params.exceptionTag.toUpperCase();
      if (exceptionTag === 'PASSED') {
        newCustomFilters.push({
          condition: { label: 'Equals to', operator: 'eq' },
          field: {
            column_name: 'Exception Tag',
            mapping: 'test_result',
            type: 'exceptionTag',
            permanent: true,
          },
          displayValue: 'Passed',
          queryValue: 'PASSED',
        });
        setHeading('Passed Transactions');
      } else if (exceptionTag === 'RESOLVED') {
        newCustomFilters.push({
          condition: { label: 'Equals to', operator: 'eq' },
          field: {
            column_name: 'Exception Tag',
            mapping: 'test_result',
            type: 'exceptionTag',
            permanent: true,
          },
          displayValue: 'Resolved',
          queryValue: 'RESOLVED',
        });
        setHeading('Resolved Transactions');
      } else if (exceptionTag === 'UNRESOLVED') {
        newCustomFilters.push({
          condition: { label: 'Equals to', operator: 'eq' },
          field: {
            column_name: 'Exception Tag',
            mapping: 'test_result',
            type: 'exceptionTag',
            permanent: true,
          },
          displayValue: 'Unresolved',
          queryValue: 'UNRESOLVED',
        });
        setHeading('Unresolved Transactions');
      } else {
        console.error('Unknown exception tag!', exceptionTag);
        setHeading('All Transactions');
      }
    } else {
      setHeading('All Transactions');
    }

    // Iterate through newCustomFilters to update "Not Available" values
    newCustomFilters = newCustomFilters.map((filter) => {
      if (filter.displayValue === 'Not Available') {
        return {
          ...filter,
          queryValue: null,
          displayValue: 'Not Available',
        };
      }
      return filter;
    });

    setCustomFilters(newCustomFilters);

    const defaultQueries = [
      `obligation_id__eq=${id}`,
      conditionalEntityCodeDefaultQuery(selectedObgDetails),
    ];

    setTableDefaultFilters(defaultQueries);
    setIsLoadingParams(false);
  }, [searchParams]);

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (!userToken) return;

    const url =
      process.env.REACT_APP_BACKEND_URL +
      `/obligationsummary/listall?limit=1&filter_query=obligation_id__eq=${id}%26member__eq=${member}`;
    let _obligation;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        if (res.ok) {
          return res.json();
        }
        throw new Error(
          `Something went wrong while fetching Obligation ID ${id}, Member ${member}. Status code: ${res.statusCode}`
        );
      })
      .then((data) => {
        if (data?.summary.length === 0)
          throw new Error(
            `Obligation with ID ${id} and Member ${member} could not be found in the Database!`
          );
        _obligation = data?.summary[0];
      })
      .catch((err) => {
        setToast({
          type: 'failure',
          message: 'Obligation data could not be fetched.',
        });
      })
      .finally(() => {
        selectedObgHandler(_obligation);
      });
  }, [userToken, id, member]);

  // #region Filter options.
  const [taxcodeOptions, setTaxcodeOptions] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);

  const [areOptionsLoading, setAreOptionsLoading] = useState(false);

  useEffect(() => {
    if (!userToken) return;

    setAreOptionsLoading(true);
    setTaxcodeOptions([]);
    setEntityOptions([]);

    const taxcodeOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL + `/transaction/distincttaxcodes?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {

        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setTaxcodeOptions(data);
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    const entityOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/obligationentities/getdistinctmember?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {

        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setEntityOptions(data.member_names.map((entity) => entity.name));
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    Promise.all([taxcodeOptionsPromise, entityOptionsPromise]).finally(() => {
      setAreOptionsLoading(false);
    });
  }, [userToken, id]);
  // #endregion

  useEffect(() => {
    if (!userToken) return;
    CheckAuthorization(userToken, setAuthorized, id, setAuthLoader);
  }, [userToken]);

  const COLUMNS = [
    { label: 'Inv No.', property: 'invoiceno' },
    { label: 'Flow', property: 'flow' },
    { label: 'Entity', property: 'entityname' },
    { label: 'Statutory Currency', property: 'statcurrencycode' },
    { label: 'Gross', property: 'statgrossamount' },
    { label: 'Net', property: 'statnetamount' },
    { label: 'VAT', property: 'statvatamount' },
    { label: 'Inv Date', property: 'invoicedate' },
    { label: 'Posting Date', property: 'postingdate' },
    { label: 'Tax Code', property: 'taxcode' },
    { label: 'Counterparty', property: 'compliancecounterpartycompanyname' },
  ];

  if (isLoadingParams) {
    return <StyledLinearProgress />;
  }
  return (
    !authLoader &&
    (authorized === 'Authorized' ? (
      <>
        <mui.Stack>
          <mui.Box
            padding={'1.875rem 2.5rem'}
            sx={{
              backgroundColor: theme.palette.baseColors.white,
            }}>
            <BackButton backAddress={backAddress} />
            <mui.Typography
              component='h1'
              sx={{
                color: theme.palette.random[300],
                fontSize: '2.25rem',
                fontWeight: '700',
              }}>
              {heading}
            </mui.Typography>
          </mui.Box>

          <WhiteCard style={{ m: '2.5rem', p: '24px 24px 24px 24px' }}>
            <PaginatedTable
              api={{
                endpoint: '/exception/unresolved_exceptions',
                records: 'exception_summary',
                count: 'count',
              }}
              defaultColumn={false}
              columns={COLUMNS}
              filterInfo={{
                component: (
                  <TransactionFilter
                    fields={[
                      { column_name: 'Transaction ID', mapping: 'id', type: 'text_case_sensitive' },
                      { column_name: 'Flow', mapping: 'flow', type: 'text' },
                      { column_name: 'Inv No', mapping: 'invoiceno', type: 'text' },
                      { column_name: 'Inv Date', mapping: 'invoicedate', type: 'date' },
                      { column_name: 'Net', mapping: 'statnetamount', type: 'numeric' },
                      {
                        column_name: 'VAT',
                        mapping: 'statvatamount',
                        type: 'numeric',
                      },
                      {
                        column_name: 'Entity',
                        mapping: 'entityname',
                        type: 'text',
                        options: entityOptions,
                      },
                      {
                        column_name: 'Counterparty Name',
                        mapping: 'compliancecounterpartycompanyname',
                        type: 'text',
                      },
                      {
                        column_name: 'Tax Code',
                        mapping: 'taxcode',
                        type: 'text',
                        options: taxcodeOptions,
                      },
                      //{ column_name: 'Exception Tag', mapping: 'Test_Result', type: 'exceptionTag' },
                      { column_name: 'GL Account No', mapping: 'glaccountno', type: 'text' },
                      {
                        column_name: 'Entity Country Code',
                        mapping: 'entitycountrycode',
                        type: 'text',
                      },
                      { column_name: 'Entity Code', mapping: 'entitycode', type: 'text' },
                      { column_name: 'Posting Period', mapping: 'postingdate', type: 'date' },
                    ]}
                    autocompleteProps={{
                      isLoading: areOptionsLoading,
                    }}
                    specialFilterHandler={{
                      fieldName: 'Exception Tag',
                      apply: handleSpecialFilters,
                    }}
                  />
                ),
                default: tableDefaultFilters,
              }}
              a11y={{
                tableAriaLabel: 'All transaction list',
                rowPrefix: 'transaction',
              }}
              customFilters={customFilters}
              hasCustomFilters
              rowItem={(exceptionData) => {
                // TODO: Modify this once API response is modelled
                const goToTransaction = (exception) =>
                  navigate(
                    `/obligations/${id}/${member}/individual-transaction?id=${exception.id}&code=${exception.entitycode
                    }&backAddress=${getCurrentRelativePath()}`
                  );
                return [
                  <StyledTableBodyCell>
                    <mui.Typography
                      onClick={() => goToTransaction(exceptionData)}
                      sx={{
                        display: 'inline-block',
                        fontSize: '0.875rem',
                        color: theme.palette.functional.tableLink,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}>
                      {nullableInvoiceNumber(exceptionData.invoiceno, exceptionData.erpdocumentno)}
                    </mui.Typography>
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>{exceptionData.flow}</StyledTableBodyCell>,
                  <StyledTableBodyCell>{exceptionData.entityname}</StyledTableBodyCell>,
                  <StyledTableBodyCell>{exceptionData.statcurrencycode}</StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {currencyFormatterWithoutSymbol(exceptionData.statgrossamount, false, {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {currencyFormatterWithoutSymbol(exceptionData.statnetamount, false, {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {currencyFormatterWithoutSymbol(exceptionData.statvatamount, false, {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableBodyCell>,

                  <StyledTableBodyCell>
                    {formatDate(reverseDate(exceptionData.invoicedate, '-'), '-')}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {formatDate(reverseDate(exceptionData.postingdate, '-'), '-')}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>{exceptionData.taxcode}</StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {exceptionData.compliancecounterpartycompanyname}
                  </StyledTableBodyCell>,
                ];
              }}
            />
          </WhiteCard>
        </mui.Stack>

        {toast?.message && (
          <Toast
            closeAlertHandler={() => {
              setToast({
                type: '',
                message: '',
              });
            }}
            toast={toast}
          />
        )}
      </>
    ) : (
      <AuthError />
    ))
  );
};
