import React, { useEffect, useState } from 'react';
import { ListNotes } from '../Notes/ListNotes';
import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { WhiteCard } from '../WhiteCard';
import { StagesAccordian } from '../../../components/common/AccordianPanel/StagesAccordian';
import { useObligation, useUser } from '../../../context';
import { Toast } from '../Toast';
import { objectHasKey } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { fetchStages } from './ChecklistStages';

export const Checklist = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Add settting context here
  const {
    selectedObgHandler,
    selectedObgDetails,
    sisterObligations,
    isGroupSelected,
    isObligationLocked,
    triggerObligationReload,
  } = useObligation();
  // const obligation = selectedObgDetails
  const [toast, setToast] = useState({ type: '', message: '' });
  const [stages, setStages] = useState([]);
  const { userId, userToken, rolesByEntity } = useUser();
  const [savedStage, setSavedStage] = useState(null);
  const [sentBackStage, setSentBackStage] = useState(null);
  const [gettingStages, setGettingStages] = useState(true);
  const [dataLoadSteps, setDataLoadSteps] = useState([]);
  const [prepareSteps, setPrepareSteps] = useState([]);
  const [reviewSteps, setReviewSteps] = useState([]);
  const [approveSteps, setApproveSteps] = useState([]);
  const [stepsLoader, setStepsLoader] = useState(true);

  const stageToRole = {
    'Data Load': 'data_load',
    Prepare: 'preparer',
    Review: 'reviewer',
    Approve: 'approver',
    Completed: 'finalize',
  };

  const isStandAlone = sisterObligations?.length > 0 && sisterObligations.length === 1;
  const canSendStage =
    (!isObligationLocked && !isGroupSelected) || isStandAlone
      ? // For entities, check if the user has roles for the selected entity, and if so, if for that entity they have the role corresponding to that entity's stage.
      objectHasKey(rolesByEntity, selectedObgDetails.org_heirarchy_code) &&
      rolesByEntity[selectedObgDetails.org_heirarchy_code].find(
        (role) => role === stageToRole[selectedObgDetails.stage]
      )
      : // For groups, check if the group has ANY entity for which the user has any roles for that entity, and for which the user has a role on it that matches the GROUP's stage.
      sisterObligations?.length > 0 &&
      rolesByEntity &&
      sisterObligations.some(
        (sister) =>
          sister.differentiator === 'member' &&
          objectHasKey(rolesByEntity, sister.org_heirarchy_code) &&
          rolesByEntity[sister.org_heirarchy_code].find(
            (role) => role === stageToRole[selectedObgDetails.stage]
          )
      );
  const canSendBackStage =
    !isObligationLocked && !isGroupSelected
      ? // For entities, check if the user has access to the selected entity (it has the entity as a key in their rolesByEntity)
      objectHasKey(rolesByEntity, selectedObgDetails.org_heirarchy_code)
      : // For groups, check if the user has access to any entity in the group.
      sisterObligations.some(
        (sister) =>
          sister.differentiator === 'member' &&
          objectHasKey(rolesByEntity, sister.org_heirarchy_code)
      );
  // Anyone who can approve can also submit.
  const canSubmitStage =
    !isObligationLocked &&
    sisterObligations.some(
      (sister) =>
        objectHasKey(rolesByEntity, sister.org_heirarchy_code) &&
        rolesByEntity[sister.org_heirarchy_code].find((role) => role === stageToRole['Approve'])
    );

  const stepsArr = [
    { stage: 'data_load', setterFn: setDataLoadSteps, response: 'dataLoadData' },
    { stage: 'preparer', setterFn: setPrepareSteps, response: 'prepareData' },
    { stage: 'reviewer', setterFn: setReviewSteps, response: 'reviewData' },
    { stage: 'approver', setterFn: setApproveSteps, response: 'approveData' },
  ];

  useEffect(() => {
    if (userToken && selectedObgDetails?.member && selectedObgDetails?.obligation_id) {
      setStepsLoader(true);
      fetchStages(userToken, selectedObgDetails, navigate, stepsArr, setStepsLoader, setToast);
    }
  }, [
    userToken,
    selectedObgDetails,
    selectedObgDetails?.member,
    selectedObgDetails?.obligation_id,
  ]);

  useEffect(() => {
    if (
      userToken &&
      selectedObgDetails?.member &&
      selectedObgDetails?.obligation_id &&
      sisterObligations?.length > 0 &&
      !stepsLoader
    ) {
      setGettingStages(true);
      let res = [];
      fetch(
        process.env.REACT_APP_BACKEND_URL +
        `/obligationtask/listall?filter_query=obligation__eq=${selectedObgDetails?.obligation_id}%26org_heirarchy_node__eq=${selectedObgDetails?.member}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          credentials: 'include',
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error('Something went wrong');
        })
        .then((data) => {
          res = data?.obligationtasks;
          const dataLoadStageData = res?.find((obj) => obj.authority === 'data_load');
          const prepareStageData = res?.find((obj) => obj.authority === 'preparer');
          const reviewStageData = res?.find((obj) => obj.authority === 'reviewer');
          const approveStageData = res?.find((obj) => obj.authority === 'approver');
          const approveStageDuplicateData = res?.find((obj) => obj.authority === 'approver');
          const completedStageData = {
            ...approveStageDuplicateData,
            authority: 'completed',
            status:
              approveStageDuplicateData.status === 'COMPLETED' &&
                approveStageDuplicateData.checklist_status === 'SUBMITTED'
                ? 'COMPLETED'
                : approveStageDuplicateData.status === 'COMPLETED' &&
                  approveStageDuplicateData.checklist_status === ''
                  ? 'IN_PROGRESS'
                  : 'NOT_STARTED',
          };
          let arr = [];
          if (dataLoadStageData) {
            arr.push({
              stage: 'Data Load',
              status:
                dataLoadStageData.status === 'COMPLETED'
                  ? 'Done'
                  : dataLoadStageData.status === 'IN_PROGRESS'
                    ? 'In Progress'
                    : 'Not Started',
              accordianState: dataLoadStageData.status === 'IN_PROGRESS',
              checkboxes: dataLoadSteps?.map((step) => ({
                step: step,
                checked: step?.option_selected,
                edited: false,
                checkDisabled: !canSendStage || dataLoadStageData.status === 'COMPLETED',
              })),
              enableSave: dataLoadStageData.status !== 'COMPLETED' && canSendStage,
              enableSendBack: false,
            });
          }
          if (prepareStageData) {
            arr.push({
              stage: 'Prepare',
              status:
                prepareStageData.status === 'COMPLETED'
                  ? 'Done'
                  : prepareStageData.status === 'IN_PROGRESS'
                    ? 'In Progress'
                    : 'Not Started',
              accordianState: prepareStageData.status === 'IN_PROGRESS',
              checkboxes: prepareSteps?.map((step) => ({
                step: step,
                checked: step?.option_selected,
                edited: false,
                checkDisabled:
                  !canSendStage ||
                  prepareStageData.status === 'COMPLETED' ||
                  dataLoadStageData.status !== 'COMPLETED',
              })),
              enableSave:
                dataLoadStageData.status === 'COMPLETED' &&
                prepareStageData.status !== 'COMPLETED' &&
                canSendStage,
              enableSendBack:
                prepareStageData.status === 'COMPLETED' &&
                reviewStageData.status !== 'COMPLETED' &&
                canSendStage,
            });
          }
          if (reviewStageData) {
            arr.push({
              stage: 'Review',
              status:
                reviewStageData.status === 'COMPLETED'
                  ? 'Done'
                  : reviewStageData.status === 'IN_PROGRESS'
                    ? 'In Progress'
                    : 'Not Started',
              accordianState: reviewStageData.status === 'IN_PROGRESS',
              checkboxes: reviewSteps?.map((step) => ({
                step: step,
                checked: step?.option_selected,
                edited: false,
                checkDisabled:
                  !canSendStage ||
                  reviewStageData.status === 'COMPLETED' ||
                  prepareStageData.status !== 'COMPLETED',
              })),

              enableSave:
                prepareStageData.status === 'COMPLETED' &&
                reviewStageData.status !== 'COMPLETED' &&
                canSendStage,

              enableSendBack:
                reviewStageData.status === 'COMPLETED' &&
                approveStageData.status !== 'COMPLETED' &&
                canSendStage,
            });
          }
          if (approveStageData) {
            arr.push({
              stage: 'Approve',
              status:
                approveStageData.status === 'COMPLETED'
                  ? 'Done'
                  : approveStageData.status === 'IN_PROGRESS'
                    ? 'In Progress'
                    : 'Not Started',
              accordianState: approveStageData.status === 'IN_PROGRESS',
              checkboxes: approveSteps?.map((step) => ({
                step: step,
                checked: step?.option_selected,
                edited: false,
                checkDisabled:
                  !canSendStage ||
                  approveStageData.status === 'COMPLETED' ||
                  reviewStageData.status !== 'COMPLETED',
              })),
              enableSave:
                reviewStageData.status === 'COMPLETED' &&
                approveStageData.status !== 'COMPLETED' &&
                canSendStage,

              enableSendBack:
                approveStageData.status === 'COMPLETED' &&
                completedStageData.status !== 'COMPLETED' &&
                canSendStage,
            });
          }
          if (completedStageData) {
            arr.push({
              stage: 'Completed',
              status:
                completedStageData.status === 'COMPLETED'
                  ? 'Done'
                  : completedStageData.status === 'IN_PROGRESS'
                    ? 'In Progress'
                    : 'Not Started',
              accordianState: false,
              checkboxes: [],
              enableSave:
                approveStageData.status === 'COMPLETED' &&
                completedStageData.status !== 'COMPLETED' &&
                canSubmitStage,
              enableSendBack: false,
            });
          }
          setStages(arr);
        })
        .catch((err) => {
          console.error(err);
          setToast({
            type: 'failure',
            message: 'Failed to fetch stage data.',
          });
        })
        .finally(() => {
          setGettingStages(false);
        });
    }
  }, [userToken, selectedObgDetails, stepsLoader, sisterObligations]);

  const saveStage = (url) => {
    fetch(process.env.REACT_APP_BACKEND_URL + url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        if (res.status === 404) return res.json();
        else if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then((data) => {
        if (data.status === 'success') {
          setToast({
            type: 'success',
            message: 'Stage updated successfully!',
          });
        } else if (data.status === 'Error') {
          if (data.detail?.includes('Not Authorized')) {
            setToast({
              type: 'failure',
              message: 'You are not authorized to perform this action.',
            });
          } else {
            setToast({
              type: 'failure',
              message: 'Member group cannot move ahead of member entity.',
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'User stage could not be saved.',
        });
      })
      .finally(() => {
        setGettingStages(true);
        setSavedStage(null);
        setStepsLoader(true);
        triggerObligationReload();
      });
  };

  const saveHandler = (authority, allChecked, editedArr) => {
    let url = `/obligationtask/updatestatusdone?obligation=${selectedObgDetails?.obligation_id}&org_heirarchy_code=${selectedObgDetails?.org_heirarchy_code}&authority=${authority}&standalone=${isStandAlone}`;
    if (authority === 'Completed') {
      url = `/obligationtask/updatestatusonsubmit?obligation=${selectedObgDetails?.obligation_id}&org_heirarchy_code=${selectedObgDetails?.org_heirarchy_code}&authority=approver&standalone=${isStandAlone}`;
    }
    let stepUpdateURL = `/obligationtask/checklistpayloadupdate?obligation=${selectedObgDetails?.obligation_id}&org_heirarchy_code=${selectedObgDetails?.org_heirarchy_code}&authority=${authority}`;
    const requestBody = { data: editedArr };
    if (editedArr?.length > 0) {
      fetch(process.env.REACT_APP_BACKEND_URL + stepUpdateURL, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error('Something went wrong');
        })
        .then((data) => {
          if (!allChecked) {
            saveStage(url);
          }
          if (data.status === 'success') {
            setToast({
              type: 'success',
              message: 'Stage checklists updated successfully!',
            });
          } else throw new Error('Something went wrong');
        })
        .catch((err) => {
          console.error(err);
          setToast({
            type: 'failure',
            message: 'User Stage checklists could not be updated.',
          });
        })
        .finally(() => {
          if (allChecked) {
            setSavedStage(null);
            setStepsLoader(true);
            setGettingStages(true);
            fetchStages(userToken, selectedObgDetails, navigate, stepsArr, setStepsLoader);
          }
        });
    } else if (!allChecked) saveStage(url);
    else setSavedStage(null);
  };

  const sendbackHandler = (authority) => {
    fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/obligationtask/updatestatusrollback?obligation=${selectedObgDetails?.obligation_id}&org_heirarchy_code=${selectedObgDetails?.org_heirarchy_code}&authority=${authority}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then((data) => {
        if (data.status === 'success') {
          setToast({
            type: 'success',
            message: 'Stage updated successfully!',
          });
        } else if (data.status === 'Error' && data.detail?.includes('Not Authorized')) {
          setToast({
            type: 'failure',
            message: 'You are not authorized to perform this action.',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'User stage could not be sent back.',
        });
      })
      .finally(() => {
        setSentBackStage(null);
        setGettingStages(true);
        setStepsLoader(true);
        triggerObligationReload();
      });
  };

  const checkboxHandler = (stage, index, checkboxVal) => {
    const modifiedStages = stages.map((s) => {
      if (s.stage === stage) {
        let obj = { ...s };
        obj.checkboxes[index].checked = checkboxVal;
        obj.checkboxes[index].edited = true;
        return obj;
      } else {
        return s;
      }
    });
    setStages(modifiedStages);
  };

  const stageSwitchHandler = (stage) => {
    const chosenStage = stages.find((obj) => obj.stage === stage);
    let newData;
    if (chosenStage.accordianState) {
      newData = stages.map((obj) =>
        obj.stage === stage ? { ...obj, accordianState: false } : obj
      );
    } else {
      newData = stages.map((obj) =>
        obj.stage === stage ? { ...obj, accordianState: true } : { ...obj, accordianState: false }
      );
    }
    setStages(newData);
  };

  const clearStageCheckBoxesHandler = (stage) => {
    let steps = [];
    if (stage === 'Data Load') {
      steps = dataLoadSteps;
    } else if (stage === 'Prepare') {
      steps = prepareSteps;
    } else if (stage === 'Review') {
      steps = reviewSteps;
    } else if (stage === 'Approve') {
      steps = approveSteps;
    }
    const newStages = stages.map((obj) => {
      if (obj.stage === stage) {
        return {
          ...obj,
          checkboxes: steps.map((step) => ({
            step: step,
            checked: step.option_selected,
            edited: false,
            checkDisabled: false,
          })),
        };
      }
      return obj;
    });
    setStages(newStages);
  };

  return (
    <>
      <Box>
        <WhiteCard style={{ padding: '1.5rem' }}>
          <Stack gap='1.5rem'>
            <Stack
              direction='row'
              gap='1rem'>
              {gettingStages ? (
                <Typography
                  sx={{ fontSize: '1.125rem', fontWeight: 700, color: theme.palette.random[300] }}>
                  Getting latest checklist status
                </Typography>
              ) : (
                <Typography
                  sx={{ fontSize: '1.125rem', fontWeight: 700, color: theme.palette.random[300] }}>
                  Prepare Checklist
                </Typography>
              )}
              {gettingStages && (
                <CircularProgress
                  size='1.5rem'
                  sx={{ marginRight: '0.5rem', color: theme.palette.random[300] }}
                />
              )}
            </Stack>
            {!gettingStages && (
              <Stack gap='1rem'>
                {stages
                  ?.filter((obj) => obj.stage !== 'Completed')
                  ?.map((obj, index) => (
                    <StagesAccordian
                      heading={`${index + 1}. ${obj.stage}`}
                      status={obj.status}
                      isOpen={obj.accordianState}
                      key={obj.stage}
                      stageSwitchHandler={stageSwitchHandler}
                      stage={obj.stage}
                      checkboxes={obj.checkboxes}
                      enableSave={obj.enableSave}
                      enableSendBack={obj.enableSendBack}
                      doneBy={obj.doneBy}
                      doneDate={obj.doneDate}
                      checkboxHandler={checkboxHandler}
                      saveHandler={saveHandler}
                      sendbackHandler={sendbackHandler}
                      isGroup={
                        selectedObgDetails?.member === selectedObgDetails?.obligation_group_name
                      }
                      savedStage={savedStage}
                      setSavedStage={setSavedStage}
                      sentBackStage={sentBackStage}
                      setSentBackStage={setSentBackStage}
                      completedStageData={stages.find((obj) => obj.stage === 'Completed')}
                      clearStageCheckBoxesHandler={clearStageCheckBoxesHandler}
                    />
                  ))}
              </Stack>
            )}
          </Stack>
          {toast?.message && (
            <Toast
              closeAlertHandler={() => {
                setToast({
                  type: '',
                  message: '',
                });
              }}
              toast={toast}
            />
          )}
        </WhiteCard>
      </Box>
      <ListNotes />
    </>
  );
};
