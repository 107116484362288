import { useLocation } from 'react-router-dom';
import { IframeScreen } from '../../../../components/common/Iframe/IframeScreen';
import { GENERAL_ANALYTICS_CARDS_DATA, getGeneralAnalyticsCardDescription } from '../constants';
import { useObligation, useUser } from '../../../../context';
import { Looker } from '../../../../components/common/Looker/Looker';
import { lookerEmbedDomain, lookerEntityCodeList } from '../../../../utils';
import { useState, useEffect } from 'react';
import { MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { MultiSelect } from '../../../../components/common/MultiSelect/MultiSelect';
import { ExpandableLookerGrid } from '../../../../components/common/Looker/ExpandableLookerGrid';
import { PageSkeleton } from '../PageSkeleton';
import { TRENDS_AND_VARIANCES_CARD_INFO } from '../../lookerConstants';
import { Customselect } from '../../../../components/common/Filters/Select';
import { SimpleSelect } from '../../../../components/common/Dropdowns/SimpleSelect';

export const TrendsVariances = () => {
  const theme = useTheme();
  const { isGroupSelected, sisterObligations, selectedObgDetails } = useObligation();
  const itemSx = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '1.75rem',
    minHeight: '1.75rem',
    fontSize: '0.875rem',
    padding: '0 1rem',
  };
  // const obligation = selectedObgDetails
  const { userToken } = useUser();

  const [taxCode, setTaxCode] = useState([]);
  const [measure, setMeasure] = useState('Gross Value');
  const [source, setSource] = useState('');
  const [optionsTaxes, setOptionsTaxes] = useState([]);
  const [toast, setToast] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(true);

  const measureOptions = ['Gross Value', 'Tax Value', 'Net Value'];
  const transactionTypeOptions = ['Purchase', 'Supply'];

  useEffect(() => {
    if (userToken && selectedObgDetails.obligation_id) {
      let url =
        process.env.REACT_APP_BACKEND_URL +
        `/transaction/distincttaxcodes?obligation_id=${selectedObgDetails.obligation_id}`;
      let _summaryDetails = {};
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          _summaryDetails = data;
        })
        .catch((err) => {
          setToast({
            type: 'failure',
            message: '',
          });
        })
        .finally(() => {
          setOptionsTaxes(_summaryDetails);
          setLoading(false);
        });
    }
  }, [selectedObgDetails.obligation_id, userToken]);

  return (
    <PageSkeleton
      title='Trends and Variances'
      description={getGeneralAnalyticsCardDescription('Trends and Variances')}
      url='..'
      isURLRelative>
      <ExpandableLookerGrid
        cards={TRENDS_AND_VARIANCES_CARD_INFO}
        dashboardParameters={{
          'Obligation ID': selectedObgDetails?.obligation_id,
          Entitycode: lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails),
          'Tax Code': taxCode.join(','),
          'Transaction Type': source,
          Value: measure.replace(' ', '^_'),
        }}
        downloadConstants={{
          dashboard: process.env.REACT_APP_LOOKER_TRENDS_AND_VARIANCES,
          filename: `${selectedObgDetails?.obligation_name}_Trends_and_Variances`,
          title: 'Trends and Variances',
        }}
        actionBar={
          <Stack
            direction='row'
            gap='1rem'>
            <Customselect
              options={optionsTaxes}
              value={taxCode}
              onChange={(e) => setTaxCode(e.target.value)}
              label='Tax Code'
              loading={loading}
              style={{
                fontSize: '0.875rem',
              }}
            />
            <SimpleSelect
              id='value'
              options={measureOptions}
              value={measure}
              onChange={(e) => setMeasure(e.target.value)}
              renderValue={(value) => (
                <>
                  <span style={{ color: theme.palette.secondaryColors[300] }}>Show by:</span>{' '}
                  {value}
                </>
              )}
            />
            <SimpleSelect
              id='value'
              options={transactionTypeOptions}
              value={source}
              displayEmpty
              onChange={(e) => setSource(e.target.value)}
              renderValue={(value) => (
                <>
                  <span style={{ color: theme.palette.secondaryColors[300] }}>
                    Transaction Type{value ? ':' : ''}
                  </span>{' '}
                  {value}
                </>
              )}
            />
          </Stack>
        }
      />
    </PageSkeleton>
  );
};
