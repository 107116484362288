import { useState } from "react"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BtnFilled } from "../Buttons/BtnFilled";
import { DownArrowIconColored } from "../CustomIcons/ColoredDownArrowIcon";

export const DropdownMenu = ({ buttonText, options, disabled }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (disabled) return
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <BtnFilled
        btnTxt={buttonText}
        size="small"
        onClick={handleClick}
        iconPosition="end"
        icon={<DownArrowIconColored sx={{ width: 15, height: 10, ml: 0.5 }}/>}
        disabled={disabled}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options?.map(option => (
          <MenuItem 
            key={option}
            onClick={() => {
              handleClose()
              option.callback()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}