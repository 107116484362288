export const unresolvedData = [
  {
    test_group: "A01",
    rule_id: 1,
    test_name: "Foreign currency with VAT (Purchases)",
    passed_transactions: 4553,
    unresolved_exceptions: 10,
    resolved_exceptions: 40,
  },
  {
    test_group: "A02",
    rule_id: 2,
    test_name: "Taxable but no VAT (Purchases)",
    passed_transactions: 4401,
    unresolved_exceptions: 91,
    resolved_exceptions: 1,
  },
  {
    test_group: "A03",
    rule_id: 3,
    test_name: "Taxable but VAT not 20% (Purchases)",
    passed_transactions: 4450,
    unresolved_exceptions: 52,
    resolved_exceptions: 3,
  },
];

export const resolvedData = [
  {
    test_group: "A01",
    rule_id: 1,
    test_name: "Foreign currency with VAT (Purchases)",
    passed_transactions: 4453,
    unresolved_exceptions: 0,
    resolved_exceptions: 500,
    reason: "Random test reason 1"
  },
  {
    test_group: "R01",
    rule_id: 2,
    test_name: "Vendor Tax Code Outlier (Purchases)",
    passed_transactions: 4487,
    unresolved_exceptions: 0,
    resolved_exceptions: 500,
    reason: "Random test reason 2"
  },
  {
    test_group: "A02",
    rule_id: 3,
    test_name: "Taxable but no VAT (Purchases)",
    passed_transactions: 4401,
    unresolved_exceptions: 0,
    resolved_exceptions: 500,
    reason: "Random test reason 3"
  },
  {
    test_group: "PQ",
    rule_id: 4,
    test_name: "Taxable but VAT not 20% (Purchases)",
    passed_transactions: 5000,
    unresolved_exceptions: 0,
    resolved_exceptions: 40,
    reason: "Random test reason 4"
  },
  {
    test_group: "P02",
    rule_id: 5,
    test_name: "Taxable but VAT not 15% (Purchases)",
    passed_transactions: 3452,
    unresolved_exceptions: 0,
    resolved_exceptions: 365,
    reason: "Random test reason 5"
  },
  {
    test_group: "X21",
    rule_id: 6,
    test_name: "Taxable (Purchases)",
    passed_transactions: 4876,
    unresolved_exceptions: 0,
    resolved_exceptions: 426,
    reason: "Random test reason 6"
  },
];

export const individualTestTransactionData = [
  {
    transaction_id: "AP-transaction-001",
    flow: "Input VAT",
    entity: "PwC Canada",
    invoice_number: "INV222427",
    net: "2873.71",
    vat: "27.76",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-002",
    flow: "Input VAT",
    entity: "PwC Germany",
    invoice_number: "INV924610",
    net: "7765.68",
    vat: "75.74",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-003",
    flow: "Input VAT",
    entity: "PwC United States",
    invoice_number: "INV315772",
    net: "6260.86",
    vat: "76.71",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-004",
    flow: "Input VAT",
    entity: "PwC Canada",
    invoice_number: "INV722980",
    net: "8101.53",
    vat: "58.29",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-005",
    flow: "Input VAT",
    entity: "PwC Canada",
    invoice_number: "INV636285",
    net: "8034.18",
    vat: "17.94",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-006",
    flow: "Input VAT",
    entity: "PwC Canada",
    invoice_number: "INV975990",
    net: "8313.86",
    vat: "47.37",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-007",
    flow: "Input VAT",
    entity: "PwC Australia",
    invoice_number: "INV166068",
    net: "1088.96",
    vat: "30.72",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-008",
    flow: "Input VAT",
    entity: "PwC Australia",
    invoice_number: "INV592530",
    net: "9746.87",
    vat: "80.22",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-009",
    flow: "Input VAT",
    entity: "PwC Canada",
    invoice_number: "INV142148",
    net: "7348.36",
    vat: "77.02",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-010",
    flow: "Input VAT",
    entity: "PwC Germany",
    invoice_number: "INV181050",
    net: "8536.12",
    vat: "62.75",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-011",
    flow: "Input VAT",
    entity: "PwC Australia",
    invoice_number: "INV405104",
    net: "5624.64",
    vat: "90.53",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-012",
    flow: "Input VAT",
    entity: "PwC Australia",
    invoice_number: "INV157308",
    net: "8712.83",
    vat: "51.18",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-013",
    flow: "Input VAT",
    entity: "PwC Australia",
    invoice_number: "INV779737",
    net: "3866.44",
    vat: "63.15",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-014",
    flow: "Input VAT",
    entity: "PwC Canada",
    invoice_number: "INV906657",
    net: "8222.40",
    vat: "66.69",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-015",
    flow: "Input VAT",
    entity: "PwC Great Britain",
    invoice_number: "INV978613",
    net: "6111.96",
    vat: "99.71",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-016",
    flow: "Input VAT",
    entity: "PwC Germany",
    invoice_number: "INV659346",
    net: "1092.06",
    vat: "69.84",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-017",
    flow: "Input VAT",
    entity: "PwC Great Britain",
    invoice_number: "INV443607",
    net: "1645.04",
    vat: "43.81",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-018",
    flow: "Input VAT",
    entity: "PwC Germany",
    invoice_number: "INV185192",
    net: "6608.66",
    vat: "88.00",
    test_result: "Unresolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-019",
    flow: "Input VAT",
    entity: "PwC Australia",
    invoice_number: "INV276518",
    net: "1580.13",
    vat: "71.49",
    test_result: "Resolved",
    currency_code: "GBP",
  },
  {
    transaction_id: "AP-transaction-020",
    flow: "Input VAT",
    entity: "PwC Canada",
    invoice_number: "INV391945",
    net: "6524.93",
    vat: "34.16",
    test_result: "Resolved",
    currency_code: "GBP",
  },
];

export const generateDummyData = () => {
  const data = [];
  const currencyCode = "GBP";

  for (let i = 1; i <= 20; i++) {
    let testResult = Math.random() < 0.5 ? "Resolved" : "Unresolved";

    data.push({
      transaction_id: `AP-transaction-${String(i).padStart(3, "0")}`,
      flow: "Input VAT",
      entity: `PwC ${
        ["Great Britain", "Australia", "United States", "Germany", "Canada"][
          Math.floor(Math.random() * 5)
        ]
      }`,
      invoice_number: `INV${Math.floor(100000 + Math.random() * 900000)}`, // Random 6 digit number
      net: `${(1000 + Math.random() * 9000).toFixed(2)}`,
      vat: `${(10 + Math.random() * 90).toFixed(2)}`,
      test_result: testResult,
      currency_code: currencyCode,
    });
  }

  return data;
};
