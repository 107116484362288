import React, { useMemo } from 'react';
import { StatusCard } from './StatusCard';
import { Grid } from '@mui/material';

const updateStageStatus = (currentStage) => {
  if (!currentStage) return;
  const stages = ['data_load', 'preparer', 'reviewer', 'approver'];

  const currentIndex = stages
    .map((stage) => stage.toLowerCase())
    .indexOf(currentStage.toLowerCase());

  const data = stages.map((stage, index) => ({
    name:
      stage === 'data_load'
        ? 'Data Load'
        : stage === 'preparer'
          ? 'Prepare'
          : stage === 'reviewer'
            ? 'Review'
            : 'Approve',
    status:
      index < currentIndex ? 'Completed' : index === currentIndex ? 'In Progress' : 'Not Started',
    key: stage.toLowerCase().replace(/ /g, '_'),
  }));
  const dataloadData = data.find((s) => s.name === 'Data Load');
  const prepareData = data.find((s) => s.name === 'Prepare');
  const reviewData = data.find((s) => s.name === 'Review');
  const approvedData = data.find((s) => s.name === 'Approve');
  return [dataloadData, prepareData, reviewData, approvedData];
};

export const ObligationStages = ({ data }) => {

  return (
    <Grid
      container
      columns={4}
      direction='row'>
      {data?.map((stage, index) => (
        <Grid
          item
          xs={1}
          sx={{ width: '25%', paddingLeft: index !== 0 ? '15px' : '' }}>
          <StatusCard
            title={
              stage?.stage === 'data_load'
                ? 'Data Load'
                : stage?.stage === 'preparer'
                  ? 'Prepare'
                  : stage?.stage === 'reviewer'
                    ? 'Review'
                    : 'Approve'
            }
            status={stage?.status}
            completionDate={stage?.completion_date}
            isCurrent={stage?.status === 'IN_PROGRESS'}
          />
        </Grid>
      ))}
    </Grid>
  );
};
