import { SvgIcon } from '@mui/material';

export const TotalExceptionsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8656 0.999956L21.3916 17.5C21.4794 17.652 21.5256 17.8244 21.5256 18C21.5256 18.1755 21.4794 18.3479 21.3916 18.4999C21.3038 18.652 21.1776 18.7782 21.0256 18.866C20.8736 18.9537 20.7011 19 20.5256 19H1.47361C1.29807 19 1.12563 18.9537 0.973616 18.866C0.821601 18.7782 0.695366 18.652 0.607601 18.4999C0.519836 18.3479 0.473632 18.1755 0.473633 18C0.473634 17.8244 0.51984 17.652 0.607607 17.5L10.1336 0.999956C10.2214 0.847949 10.3476 0.721722 10.4996 0.633962C10.6516 0.546202 10.8241 0.5 10.9996 0.5C11.1751 0.5 11.3476 0.546202 11.4996 0.633962C11.6516 0.721722 11.7778 0.847949 11.8656 0.999956ZM3.20561 17H18.7936L10.9996 3.49996L3.20561 17ZM9.99961 14H11.9996V16H9.99961V14ZM9.99961 6.99996H11.9996V12H9.99961V6.99996Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};