import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Stack, Grid, Typography, useTheme, Link, Skeleton } from '@mui/material';
import { WhiteCard } from '../WhiteCard';
import { CheckCircleOutlinedIcon } from '../../../components/common/CustomIcons/CheckCircleOutlinedIcon';
import { fetchData, reconciliationBaseUrl, typographyStyle } from './index';
import { useObligation, useUser } from '../../../context';
import { VarianceColorTheme } from './Utility/ColorCard';
import { Warning } from '../../../components/common/CustomIcons/FilledWarning';
import { formatAmount } from './Utility/helperFunctions';
import { Toast } from '../Toast';

export const GeneralLedgerReconciliations = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { userToken } = useUser();
  const { selectedObgDetails } = useObligation();
  const [vatGlVarianceSummary, setVatGlVarianceSummary] = useState({});
  const [vatGlWithoutTaxCodeVarianceSummary, setVatGlWithoutTaxCodeVarianceSummary] = useState({});
  const [inputVatWithoutTaxCodeVarianceSummary, setInputVatWithoutTaxCodeVarianceSummary] =
    useState({});
  const [toast, setToast] = useState({ type: '', message: '' });
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (userToken && selectedObgDetails.obligation_id) {
      setLoading1(true);
      fetchData(
        navigate,
        userToken,
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/vatglvariance?${reconciliationBaseUrl(selectedObgDetails)}`,
        setVatGlVarianceSummary,
        'VAT GL vs Transactions',
        setToast,
        setLoading1
      );
      setLoading2(true);
      fetchData(
        navigate,
        userToken,
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/vatglwithouttaxcodevariance?${reconciliationBaseUrl(
          selectedObgDetails
        )}`,
        setVatGlWithoutTaxCodeVarianceSummary,
        'VAT GL vs Transactions without Tax Code',
        setToast,
        setLoading2
      );
      setLoading3(true);
      fetchData(
        navigate,
        userToken,
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/inputvatwithouttaxcodevariance?${reconciliationBaseUrl(
          selectedObgDetails
        )}`,
        setInputVatWithoutTaxCodeVarianceSummary,
        'Input VAT Reconciliation without Tax Code',
        setToast,
        setLoading3
      );
    }
  }, [selectedObgDetails, userToken]);

  const cards = [
    {
      heading: 'VAT GL vs Transactions',
      value: vatGlVarianceSummary,
      url: 'vat-gl-vs-transactions-summary',
      loading: loading1,
    },
    {
      heading: 'VAT GL vs Transactions without Tax Code',
      value: vatGlWithoutTaxCodeVarianceSummary,
      url: 'vat-gl-vs-transactions-without-tax-code-summary',
      loading: loading2,
    },
    {
      heading: 'Input VAT Reconciliation without Tax Code',
      value: inputVatWithoutTaxCodeVarianceSummary,
      url: 'input-vat-reconciliation-without-tax-code-summary',
      loading: loading3,
    },
  ];

  return (
    <>
      <WhiteCard>
        <Stack
          direction={'row'}
          gap={'0.5rem'}>
          <Typography
            component='h4'
            sx={typographyStyle('1.125rem', '700', theme.palette.baseColors.grey)}>
            General Ledger Reconciliations
          </Typography>
          <Typography sx={typographyStyle('1.125rem', 400, theme.palette.neutralColors.black[400])}>
            (3)
          </Typography>
        </Stack>
        <Grid
          container
          direction='row'
          columns={3}
          gap={'1.5rem'}>
          {cards.map((card) => {
            return card.loading ? (
              <Skeleton
                height={200}
                width={'31.8%'}
                sx={{ transform: 'none' }}
              />
            ) : (
              <Grid
                item
                width='31.8%'>
                <WhiteCard style={{ padding: '1rem' }}>
                  <Grid
                    container
                    gap={'1.5rem'}>
                    <Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                      {card.heading}
                    </Typography>
                    <Grid
                      item
                      sx={VarianceColorTheme(card.value?.Variance === 0 ? '' : 'Warning')}>
                      <Stack
                        direction={'row'}
                        gap={'4px'}>
                        {card.value?.Variance === 0 ? (
                          <CheckCircleOutlinedIcon
                            style={{ color: 'green' }}
                            sx={{ width: '1rem', height: '1rem' }}
                          />
                        ) : (
                          <Warning sx={{ width: 16, height: 16 }} />
                        )}
                        <Typography
                          sx={typographyStyle(
                            '.875rem',
                            '700',
                            theme.palette.neutralColors.black[400]
                          )}>
                          Variance
                        </Typography>
                      </Stack>
                      <Typography
                        sx={typographyStyle('1.5rem', '400', theme.palette.baseColors.grey)}>
                        {formatAmount(card.value?.Variance, card.value?.Currency_Code) ?? '-'}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      display={'flex'}
                      gap={'3px'}
                      sx={{ padding: '0 .75rem ' }}>
                      <Grid sx={{ borderRight: `1px solid `, width: 186, mr: '5px' }}>
                        <Typography
                          sx={typographyStyle(
                            '.875rem',
                            '400',
                            theme.palette.neutralColors.black[400]
                          )}>
                          {'VAT GL'}
                        </Typography>
                        <Typography
                          sx={typographyStyle('1.5rem', '400', theme.palette.baseColors.grey)}>
                          {formatAmount(card.value?.VAT_GL, card.value?.Currency_Code) ?? '-'}
                        </Typography>
                      </Grid>
                      <Grid sx={{ width: '11.625rem' }}>
                        <Typography
                          sx={typographyStyle(
                            '.875rem',
                            '400',
                            theme.palette.neutralColors.black[400]
                          )}>
                          Transactions
                        </Typography>
                        <Typography
                          sx={typographyStyle('1.5rem', '400', theme.palette.baseColors.grey)}>
                          {formatAmount(card.value?.Transactions, card.value?.Currency_Code) ?? '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Link
                    onClick={() => {
                      navigate(
                        `/obligations/${selectedObgDetails['obligation_id']}/${selectedObgDetails['member']}/reconciliations/${card.url}`
                      );
                    }}
                    sx={typographyStyle('1rem', '500', theme.palette.functional.primaryLink, {
                      cursor: 'pointer',
                    })}
                    color='inherit'>
                    {'View Reconciliation'}
                  </Link>
                </WhiteCard>
              </Grid>
            );
          })}
        </Grid>
      </WhiteCard>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
