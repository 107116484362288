import { Box, Stack, Typography, useTheme } from "@mui/material";
import { WhiteCard } from "../../../pages/Obligations/WhiteCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { BtnStandard } from "../../../components/common/Buttons/BtnStandard";
import { ArrowLeftIcon } from "../../../components/common/CustomIcons/ArrowLeftIcon";
import { IframeCard } from "./IframeCard"
import { useObligation } from "../../../context";
import { StyledLinearProgress } from "../Progress/StyledLinearProgress";
import { BackButton } from "../Buttons/Utility/BackButton";

export const IframeScreen = ({
  title,
  description,
  iframeLink,
  iframeTitle,
  height,
  aspectRatio,
  header = true,
  onIframeMessage,
  children
}) => {
  const theme = useTheme();
  const { id, member, tab } = useParams();
  const { selectedObgDetails } = useObligation();
  // const obligation = selectedObgDetails
  const [loadingIframe, setLoadingIframe] = useState(true);
  const navigate = useNavigate();

  const iframe = useRef(null)

  useEffect(() => {
    setLoadingIframe(true)
  }, [iframeLink])

  useEffect(() => {
    // Don't bother making an onIframeMessage event listener if we don't need to do anything with it.
    if (!onIframeMessage) return

    const handleIframeMessage = event => {
      // Don't handle messages that don't come from the iframe.
      // They may be malicious in nature!
      if (event.source !== iframe.current?.contentWindow || event.origin !== `https://${process.env.REACT_APP_LOOKER_HOST}`) return
      onIframeMessage(JSON.parse(event.data))
    }

    window.addEventListener("message", handleIframeMessage)
    return () => { window.removeEventListener("message", handleIframeMessage) } // Cleanup.
  }, [])

  return (
    <Stack height={1}>
      {header && (
        <Box
          padding='1.875rem 2.5rem'
          sx={{
            backgroundColor: theme.palette.baseColors.white,
          }}>
          <BackButton backAddress={`/obligations/${id}/${member}/${tab}`} />
          <Typography
            component='h1'
            sx={{
              color: theme.palette.random[300],
              fontSize: '2.25rem',
              fontWeight: '700',
            }}>
            {title}
          </Typography>
          <Typography
            component='h5'
            sx={{
              mt: '.25rem',
              color: theme.palette.random[600],
              fontSize: '0.875rem',
              fontWeight: '500',
            }}>
            {description}
          </Typography>
        </Box>
      )}

      {children}

      <Box flexGrow={1}>
        <WhiteCard
          style={{
            padding: 0,
            gap: 0,
            height: height,
            aspectRatio: aspectRatio,
            border: 'none',
            width: 1,
            backgroundColor: 'transparent',
          }}>
          {loadingIframe && <StyledLinearProgress />}
          <IframeCard
            src={iframeLink}
            title={iframeTitle}
            allowFullScreen
            onLoad={() => {
              setLoadingIframe(false)
            }}
            ref={iframe}
          />
        </WhiteCard>
      </Box>
    </Stack>
  );
};
