import React, { useEffect, useState } from 'react';
import { Backdrop, Button, CircularProgress, TableCell, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TextTag from '../../../../../components/common/Text Tags/TextTag';
import { SelectableRowsTable } from './SelectableRowsTable';
import { AcceptModal } from './AcceptModal';
import { ReasonModal } from './ReasonModal';
import { useObligation, useUser } from '../../../../../context';
import {
  unresolvedData as mockUnresolvedData,
  resolvedData as mockResolvedData,
} from '../../PurchaseTaxExceptions/dummyData';
import { CheckIcon } from '../../../../../components/common/CustomIcons/CheckIcon';
import { EditBoxLineIcon } from '../../../../../components/common/CustomIcons/EditBoxLineIcon';
import { BtnOutlined } from '../../../../../components/common/Buttons/BtnOutlined';
import { conditionalEntityCodeDefaultQuery } from '../../../../../utils';
import { Toast } from '../../../Toast';

// STYLING
const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  height: '3rem',
}));

export const UnresolvedExceptionListsTable = ({
  id,
  member,
  reload,
  setReload,
  tableType,
  resolveUrl,
  acceptModal,
  setAcceptModal,
  reasonModal,
  setReasonModal,
  selectedUnresolvedRows,
  selectedResolvedRows,
  setSelectedUnresolvedRows,
  setSelectedResolvedRows,
  setTableSnapshot,
  setResolvedData,
  setUnresolvedData,
}) => {
  const theme = useTheme();
  const { userToken } = useUser();
  const navigate = useNavigate();
  const { selectedObgDetails, isObligationLocked } = useObligation();
  const [reasonException, setReasonException] = useState(undefined);
  const [reasonData, setReasonData] = useState({});
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('id');
  const COLUMNS = [
    { label: '', property: '', sortable: false, cellWidth: 5 },
    { label: 'Test Group', property: 'group_name', cellWidth: 15 },
    { label: 'Test Name', property: 'rule_description', cellWidth: 50 },
    {
      label: 'Action Required',
      property: '',
      cellWidth: 30,
      sortable: false,
      centeredHeader: true,
    },
  ];
  const [loading, setLoading] = useState(false);

  const [toast, setToast] = useState();
  const handleResolveStatus = (type, message) => {
    setToast({
      type: type,
      message: message,
    });
    setReload(!reload);
  };

  const getReason = (id) => {
    setLoading(true);
    let encodedId = encodeURIComponent(id);
    if (userToken) {
      let url = process.env.REACT_APP_BACKEND_URL + `/exception/getacceptreason?id=${encodedId}`;
      fetch(url, {
        method: 'get',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setReasonData(data);
        })
        .catch((err) => {
          setToast('Failed to get reason data');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {selectedObgDetails.obligation_id && (
        <SelectableRowsTable
          reload={reload}
          dummyData={tableType === 'resolved' ? mockResolvedData : mockUnresolvedData}
          api={{
            endpoint: '/exception/listallexceptions',
            records: 'exception',
            count: 'count',
          }}
          columns={COLUMNS}
          defaultColumn='rule_id'
          paginated={false}
          setTableSnapshot={tableType === 'resolved' ? setResolvedData : setUnresolvedData}
          filterInfo={{
            default:
              tableType === 'resolved'
                ? [
                  `group_id__eq=Taxrule`,
                  `tax_item_id__eq=${transactionId}`,
                  `result_adjustment_status__eq%3DRESOLVED`,
                ]
                : [
                  `group_id__eq=Taxrule`,
                  `tax_item_id__eq=${transactionId}`,
                  `result_adjustment_status__eq%3DUNRESOLVED`,
                ],
          }}
          hasFilterComponent={false}
          a11y={{
            tableAriaLabel: 'adjustment list',
            rowPrefix: 'adjustment',
          }}
          rowItem={(exception) => [
            <StyledTableBodyCell key='icon-cell'></StyledTableBodyCell>,
            <StyledTableBodyCell
              key='testgroup-cell'
              sx={{ width: '15%' }}>
              <TextTag
                text={exception.group_name}
                variant='grey'
                size='m'
                style={{ padding: '4px 8px', borderRadius: '2px' }}
              />
            </StyledTableBodyCell>,
            <StyledTableBodyCell
              key='testname-cell'
              sx={{ width: '60%' }}>
              {exception.rule_description}
            </StyledTableBodyCell>,
            tableType === 'unresolved' ? (
              <StyledTableBodyCell
                key='action-cell'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}>
                <BtnOutlined
                  size='medium'
                  btnTxt='Resolve'
                  disabledStyling={{
                    border: 'solid 1px',
                    bgcolor: theme.palette.baseColors.white,
                    cursor: 'initial',
                  }}
                  disabled={selectedUnresolvedRows?.length > 1 || isObligationLocked}
                  icon={<EditBoxLineIcon />}
                  iconPosition='start'
                  sx={{
                    width: 104,
                    height: 32,
                    mr: 1,
                  }}
                  onClick={() => {
                    navigate(
                      `${resolveUrl}&resolveException=[${encodeURIComponent(exception?.id)}]`
                    );
                  }}
                />
                <BtnOutlined
                  size='medium'
                  disabled={selectedUnresolvedRows?.length > 1 || isObligationLocked}
                  btnTxt='Accept'
                  disabledStyling={{
                    border: 'solid 1px',
                    bgcolor: theme.palette.baseColors.white,
                    cursor: 'initial',
                  }}
                  icon={<CheckIcon />}
                  iconPosition='start'
                  sx={{
                    width: 104,
                    height: 32,
                    color: theme.palette.primaryColors[200],
                    borderColor: theme.palette.primaryColors[200],
                  }}
                  onClick={() => {
                    setAcceptModal(true);
                    setSelectedUnresolvedRows([exception.id]);
                  }}
                />
              </StyledTableBodyCell>
            ) : (
              <StyledTableBodyCell
                key='action-cell'
                sx={{ width: '10%' }}>
                <BtnOutlined
                  size='medium'
                  btnTxt='View Reason'
                  sx={{
                    width: 104,
                    height: 32,
                    mr: 1,
                  }}
                  onClick={() => {
                    setReasonModal(true);
                    getReason(exception?.id);
                    setReasonException(exception);
                  }}
                />
              </StyledTableBodyCell>
            ),
          ]}
          isSelectable={true}
          selectedRows={tableType === 'unresolved' ? selectedUnresolvedRows : selectedResolvedRows}
          setSelectedRows={
            tableType === 'unresolved' ? setSelectedUnresolvedRows : setSelectedResolvedRows
          }
        />
      )}
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
      {acceptModal && (
        <AcceptModal
          transactionId={transactionId}
          setOpen={setAcceptModal}
          handleResolveStatus={handleResolveStatus}
          selectedRows={tableType === 'unresolved' ? selectedUnresolvedRows : selectedResolvedRows}
        />
      )}
      {loading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => { }}>
          <CircularProgress sx={{ color: theme.palette.baseColors.white }} />
        </Backdrop>
      )}
      {reasonModal && !loading && (
        <ReasonModal
          setOpen={setReasonModal}
          reasonData={reasonData}
          reasonException={reasonException}
          setReasonException={setReasonException}
        />
      )}
    </>
  );
};

export default UnresolvedExceptionListsTable;
