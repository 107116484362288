import { useTheme, Typography, Stack, Box } from '@mui/material';
import { WhiteCard } from '../../WhiteCard';
import React, { useMemo, useState, useEffect } from 'react';
import {
  PaginatedTable,
  StyledTableBodyCell,
} from '../../../../components/Paginated Table/PaginatedTable';
import { TransactionFilter } from '../../../../components/common/Filters/TransactionFilter';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  reverseDate,
  formatDate,
  getCurrentRelativePath,
  currencyFormatterWithoutSymbol,
  nullableInvoiceNumber,
} from '../../../../utils';
import { dummyData } from './dummyData';
import { BackButton } from '../../../../components/common/Buttons/Utility/BackButton';
import { useUser } from '../../../../context';
import { Toast } from '../../Toast';

export const AnalyticsTransactionDashboard = () => {
  const theme = useTheme();
  const { userToken } = useUser();
  const navigate = useNavigate();
  const { id, query } = useParams();
  const [searchParams] = useSearchParams();
  const backAddress = searchParams.get('backAddress');
  const [toast, setToast] = useState({ type: '', message: '' });

  const convertObjectToArray = (obj) => {
    const columnNameMapping = {
      id: 'Transaction ID',
      invoiceno: 'Inv No',
      invoicedate: 'Inv Date',
      netamount: 'Net',
      statvatamount: 'VAT',
      entityname: 'Entity Name',
      entitycode: 'Entity Code',
      taxcode: 'Tax Code',
      source: 'Source',
      compliancecounterpartyname: 'Counterparty',
      entitycountrycode: 'Entity Country Code',
      postingperiod: 'Posting Period',
      postingdate: 'Posting Date',
      postingyear: 'Posting Year',
      compliancereportingcountrycode: 'Compliance Reporting Country Code',
      compliancecounterpartycountrycode: 'Compliance Counterparty Country Code',
      glaccountno: 'GL Account No',
      compliancecounterpartycompanyname: 'Compliance Counterparty Company Name',
      businessunitcode: 'Business Unit Code',
      suppliername: 'Supplier Name',
      customername: 'Customer Name',
      determinedvatrate: 'Determined VAT Rate',
      statgrossamount: 'Stat Gross Amount',
      customercountrycode: 'Customer Country Code',
      suppliercountrycode: 'Supplier Country Code',
      days_diff_type: true,
      months_posted_after_invoice_date: 'Months Posted after Invoice Date',
      transaction_type: 'Source',
      /* days_after_invoice_date: "Days After Invoice Date",
      days_after_invoice_date_credit_note: "Days After Invoice Date (Credit Note)",
      days_before_invoice_date: "Days Before Invoice Date",
      days_before_invoice_date_credit_note: "Days Before Invoice Date (Credit Note)" */
    };

    const days_diff_type_mappings = [
      { field: 'days_after_invoice_date', name: 'Days After Invoice Date' },
      {
        field: 'days_after_invoice_date_credit_note',
        name: 'Days After Invoice Date (Credit Note)',
      },
      { field: 'days_before_invoice_date', name: 'Days Before Invoice Date' },
      {
        field: 'days_before_invoice_date_credit_note',
        name: 'Days Before Invoice Date (Credit Note)',
      },
    ];

    return Object.entries(obj)
      .filter(([key, value]) => !!columnNameMapping[key] && value !== '') // Only get the keys that exist in the name dictionary.
      .map(([key, value]) => {
        switch (key) {
          case 'statgrossamount':
            return {
              field: {
                column_name: columnNameMapping[key] || key,
                mapping: key,
                type: 'text',
              },
              condition: {
                label: 'Is less than',
                operator: 'lt',
              },
              queryValue: 0,
              displayValue: 0,
            };
          case 'days_diff_type':
            const type = value.replaceAll('%20', ' ');
            const days = obj['days'];

            return {
              field: {
                column_name: type,
                mapping: days_diff_type_mappings.find((item) => item.name === type).field,
                type: 'text',
              },
              condition: {
                label: 'Equals to',
                operator: 'eq',
              },
              queryValue: encodeURIComponent(days.replace(/\s/g, '+')),
              displayValue: days.replace(/\s/g, '+'),
            };
          case 'transaction_type':
            return {
              field: {
                column_name: columnNameMapping[key],
                mapping: 'source',
                type: 'text',
              },
              condition: {
                label: 'Equals to',
                operator: 'eq',
              },
              queryValue: value === 'Purchase' ? 'AP' : 'AR',
              displayValue: value === 'Purchase' ? 'AP' : 'AR',
            };
          case 'months_posted_after_invoice_date':
            return {
              field: {
                column_name: columnNameMapping[key] || key,
                mapping: key,
                type: 'text',
              },
              condition: {
                label: 'Equals to',
                operator:
                  value === 'Not Available' ? 'is_' : value?.split(',')?.length > 1 ? 'in_' : 'eq',
              },
              queryValue:
                value === 'Not Available' ? null : encodeURIComponent(value.replace(/\s/g, '+')),
              displayValue: value === 'Not Available' ? 'Not Available' : value.replace(/\s/g, '+'),
            };
          default:
            return {
              field: {
                column_name: columnNameMapping[key] || key,
                mapping: key,
                type: 'text',
              },
              condition: {
                label: 'Equals to',
                operator:
                  value === 'Not Available' ? 'is_' : value?.split(',')?.length > 1 ? 'in_' : 'eq',
              },
              queryValue: value === 'Not Available' ? null : value,
              displayValue: value === 'Not Available' ? 'Not Available' : value,
            };
        }
      });
  };

  // #region Filter options.
  const [taxcodeOptions, setTaxcodeOptions] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);

  const [areOptionsLoading, setAreOptionsLoading] = useState(false);

  useEffect(() => {
    if (!userToken) return;

    setAreOptionsLoading(true);
    setTaxcodeOptions([]);
    setEntityOptions([]);

    const taxcodeOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL + `/transaction/distincttaxcodes?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setTaxcodeOptions(data);
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    const entityOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/obligationentities/getdistinctmember?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setEntityOptions(data.member_names.map((entity) => entity.name));
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    Promise.all([taxcodeOptionsPromise, entityOptionsPromise]).finally(() => {
      setAreOptionsLoading(false);
    });
  }, [userToken, id]);
  // #endregion

  const customFilters = useMemo(
    () => convertObjectToArray(Object.fromEntries(searchParams)),
    [searchParams]
  );
  const defaultFilters = useMemo(() => {
    return searchParams.get('transaction_type') === null ? [`obligation_id__eq=${id}`] : [];
  }, [searchParams]);

  const cols = [
    { label: 'Inv No.', property: 'invoiceno', cellWidth: -1 },
    { label: 'Flow', property: 'flow', cellWidth: -1 },
    { label: 'Entity', property: 'entityname', cellWidth: -1 },
    { label: 'Statutory Currency', property: 'statcurrencycode', cellWidth: -1 },
    { label: 'Gross', property: 'statgrossamount', cellWidth: -1 },
    { label: 'Net', property: 'statnetamount', cellWidth: -1 },
    { label: 'VAT', property: 'statvatamount', cellWidth: -1 },
    { label: 'Inv Date', property: 'invoicedate', cellWidth: -1 },
    { label: 'Posting Date', property: 'postingdate', cellWidth: -1 },
    { label: 'Tax Code', property: 'taxcode', cellWidth: -1 },
    {
      label: 'Counterparty',
      property: 'compliancecounterpartycompanyname',
      cellWidth: -1,
    },
  ];

  return (
    <>
      <Stack gap='1.5rem'>
        <Box
          padding={'1.875rem 2.5rem'}
          sx={{
            backgroundColor: theme.palette.baseColors.white,
          }}>
          {query === 'reconciliation' && <BackButton backAddress={backAddress} />}
          <Typography
            component='h1'
            sx={{
              color: theme.palette.random[300],
              fontSize: '2.25rem',
              fontWeight: '700',
            }}>
            All Transactions
          </Typography>
        </Box>
        <WhiteCard
          style={{
            padding: '1.5rem',
            justifyContent: 'space-between',
            margin: '1.875rem 2.5rem',
          }}>
          <PaginatedTable
            dummyData={dummyData}
            api={{
              endpoint:
                query === 'reconciliation'
                  ? '/transaction/listalltransactions'
                  : '/transaction/listall',
              records: 'transactions',
              count: 'count',
            }}
            columns={
              searchParams.get('transaction_type') === null
                ? cols
                : [
                    ...cols,
                    {
                      label: 'Obligation Name',
                      property: 'obligation_name',
                      cellWidth: -1,
                    },
                  ]
            }
            defaultColumn='invoiceno'
            customFilters={customFilters}
            filterInfo={{
              component: (
                <TransactionFilter
                  fields={[
                    { column_name: 'Transaction', mapping: 'id', type: 'text_case_sensitive' },
                    { column_name: 'Flow', mapping: 'flow', type: 'text' },
                    {
                      column_name: 'Inv No',
                      mapping: 'invoiceno',
                      type: 'text',
                    },
                    {
                      column_name: 'Inv Date',
                      mapping: 'invoicedate',
                      type: 'date',
                    },
                    {
                      column_name: 'Net',
                      mapping: 'statnetamount',
                      type: 'numeric',
                    },
                    {
                      column_name: 'VAT',
                      mapping: 'statvatamount',
                      type: 'numeric',
                    },
                    {
                      column_name: 'Entity',
                      mapping: 'entityname',
                      type: 'text',
                      options: entityOptions,
                    },
                    {
                      column_name: 'Tax Code',
                      mapping: 'taxcode',
                      type: 'text',
                      options: taxcodeOptions,
                    },
                    {
                      column_name: 'Counterparty',
                      mapping: 'compliancecounterpartycompanyname',
                      type: 'text',
                    },
                  ]}
                  autocompleteProps={{
                    isLoading: areOptionsLoading,
                  }}
                />
              ),
              default: defaultFilters,
            }}
            a11y={{
              tableAriaLabel: 'transaction list',
              rowPrefix: 'transaction',
            }}
            rowItem={(transaction) => {
              const formatFloat = (num) => parseFloat(num).toFixed(2).toLocaleString('en-AU');
              return [
                <StyledTableBodyCell>
                  <Typography
                    onClick={() =>
                      navigate(
                        `/obligations/${transaction.obligation_id}/${
                          transaction.entityname
                        }/individual-transaction?id=${transaction.id}&code=${
                          transaction.entitycode
                        }&backAddress=${getCurrentRelativePath()}`
                      )
                    }
                    sx={{
                      display: 'inline-block',
                      fontSize: '0.875rem',
                      color: theme.palette.functional.tableLink,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}>
                    {nullableInvoiceNumber(transaction.invoiceno, transaction.erpdocumentno)}
                  </Typography>
                </StyledTableBodyCell>,
                <StyledTableBodyCell>{transaction.flow}</StyledTableBodyCell>,
                <StyledTableBodyCell>{transaction.entityname}</StyledTableBodyCell>,
                <StyledTableBodyCell>{transaction.statcurrencycode}</StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {currencyFormatterWithoutSymbol(transaction.statgrossamount, false, {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {currencyFormatterWithoutSymbol(transaction.statnetamount, false, {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {currencyFormatterWithoutSymbol(transaction.statvatamount, false, {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {formatDate(reverseDate(transaction.invoicedate, '-'), '-')}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {formatDate(reverseDate(transaction.postingdate, '-'), '-')}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>{transaction.taxcode}</StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {transaction.compliancecounterpartycompanyname}
                </StyledTableBodyCell>,
                searchParams.get('transaction_type') !== null && (
                  <StyledTableBodyCell>{transaction?.obligation_name}</StyledTableBodyCell>
                ),
              ];
            }}
          />
        </WhiteCard>
      </Stack>

      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
