import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import { typography } from './typography';

const theme = createTheme({
  palette: { ...palette },
  typography: { ...typography },
  breakpoints: {
    values: {
      "sm-laptop": 1100,
    },
  },
});

export default theme;