import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useObligation, useUser } from '../../../context';
import { useTheme } from '@mui/material';
import { ListNotes } from '../Notes/ListNotes';
import { useParams } from 'react-router-dom';
import { ObligationStages } from './ObligationStages';
import { ObligationDetails } from './ObligationDetails';
import { ObligationSummary } from './ObligationSummary';
import { LoadingView } from './LoadingView';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import DUMMY_OVERVIEW_DATA from './overviewDummyData.json';
import { Toast } from '../Toast';

export const Overview = () => {
  const theme = useTheme();
  const { userToken } = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [overviewStatus, setOverviewStatus] = useState([]);
  const [detailsData, setDetailsData] = useState({});
  const [dataSummary, setDataSummary] = useState({});
  const [reconciliationSummary, setReconciliationSummary] = useState({});
  const [adjustmentSummary, setAdjustmentSummary] = useState({});
  const [exceptionSummary, setExceptionSummary] = useState({});
  const { selectedObgDetails } = useObligation();
  const { id, member } = useParams();
  const [toast, setToast] = useState({ type: '', message: '' });

  useEffect(() => {
    if (userToken && selectedObgDetails?.obligation_id && selectedObgDetails?.member) {
      setIsLoading(true);

      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const obligationId = selectedObgDetails?.obligation_id;
      const member = selectedObgDetails?.member;
      const obligationBaseUrl =
        selectedObgDetails.org_heirarchy_code === selectedObgDetails.obligation_group_id
          ? `obligation_id=${selectedObgDetails.obligation_id}`
          : `obligation_id=${selectedObgDetails.obligation_id}&entitycode=${selectedObgDetails.org_heirarchy_code}`;

      const endpoints = [
        `/obligationtask/listall?filter_query=obligation__eq=${obligationId}%26org_heirarchy_node__eq=${member}`,
        `/obligation/listall?filter_query=obligation_id__eq=${obligationId}`,
        `/datasummary/summaryofdata?${obligationBaseUrl}`,
        `/reconciliation/reconciledsummaryoverview?${obligationBaseUrl}`,
        `/adjustment/adjustmentsummaryoverview?${obligationBaseUrl}`,
        `/exception/exceptionsummaryoverview?${obligationBaseUrl}`
      ];

      const fetchPromises = endpoints.map((endpoint) =>
        fetch(`${baseUrl}${endpoint}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          credentials: 'include',
        }).then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch from ${endpoint}`);
          }
          return res.json();
        })
      );

      Promise.all(fetchPromises)
        .then(
          ([
            taskData,
            detailData,
            dataSummaryData,
            reconciledSummaryData,
            adjustmentSummaryData,
            exceptionSummaryData,
          ]) => {

            const statusData = taskData?.obligationtasks?.map((obj) => ({
              stage: obj.authority,
              status: obj.status,
              completion_date:
                obj.status === 'COMPLETED'
                  ? dayjs(obj.completion_date)?.format('DD MMM YYYY')
                  : null,
            }));
            const dataloadData = statusData.find((s) => s.stage === 'data_load');
            const prepareData = statusData.find((s) => s.stage === 'preparer');
            const reviewData = statusData.find((s) => s.stage === 'reviewer');
            const approvedData = statusData.find((s) => s.stage === 'approver');

            // Lol.
            adjustmentSummaryData.adjustment.output_vat = adjustmentSummaryData.adjustment.ouput_vat
            adjustmentSummaryData.adjustment.output_vat_net = adjustmentSummaryData.adjustment.ouput_vat_net
            delete adjustmentSummaryData.adjustment.ouput_vat
            delete adjustmentSummaryData.adjustment.ouput_vat_net

            setOverviewStatus([dataloadData, prepareData, reviewData, approvedData]);
            setDetailsData(detailData?.obligations?.[0]);
            setDataSummary(dataSummaryData);
            setReconciliationSummary(reconciledSummaryData);
            setAdjustmentSummary(adjustmentSummaryData);
            setExceptionSummary(exceptionSummaryData);
          }
        )
        .catch((err) => {
          console.error(err);
          setToast({
            type: 'failure',
            message: 'Failed to fetch data.',
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userToken, selectedObgDetails]);

  return (
    <Stack spacing={'2.5rem'}>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <ObligationStages data={overviewStatus} />
          <ObligationDetails
            data={detailsData}
            theme={theme}
          />
          <ObligationSummary
            id={id}
            member={member}
            data={DUMMY_OVERVIEW_DATA.obligationSummaryData}
            dataSummary={dataSummary}
            reconciliationSummary={reconciliationSummary}
            adjustmentSummary={adjustmentSummary}
            exceptionSummary={exceptionSummary}
            theme={theme}
          />
        </>
      )}
      <ListNotes />
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </Stack>
  );
};