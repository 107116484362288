import { GENERAL_ANALYTICS_CARDS_DATA, getGeneralAnalyticsCardData, getGeneralAnalyticsCardDescription } from '../constants';
import { useObligation } from '../../../../context';
import { Looker } from '../../../../components/common/Looker/Looker';
import { Box, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { StyledButtonGroup } from '../../../../components/common/Buttons/ButtonGroup';
import { useState } from 'react';
import { SimpleSelect } from '../../../../components/common/Dropdowns/SimpleSelect';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { lookerEmbedDomain, lookerEntityCodeList } from '../../../../utils';
import { ExpandableLookerGrid } from '../../../../components/common/Looker/ExpandableLookerGrid';
import { PageSkeleton } from '../PageSkeleton';
import { TRANSACTION_ANALYTICS_CARD_INFO } from '../../lookerConstants';

export const TransactionAnalytics = () => {
  const theme = useTheme()

  const { isGroupSelected, sisterObligations, selectedObgDetails } = useObligation();
  const [measure, setMeasure] = useState('Value');
  const dashboardByMeasure = {
    Value: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_VALUE,
    Count: process.env.REACT_APP_LOOKER_TRANSACTION_ANALYTICS_BY_COUNT,
  }[measure]

  const measureOptions = ['Value', 'Count'];

  return (
    <PageSkeleton
      title="Transaction Analytics"
      description={getGeneralAnalyticsCardDescription("Transaction Analytics")}
      url=".."
      isURLRelative
    >
      <ExpandableLookerGrid
        cards={TRANSACTION_ANALYTICS_CARD_INFO[measure]}
        dashboardParameters={{
          "Obligation ID": selectedObgDetails?.obligation_id,
          "Entitycode": lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails)
        }}
        downloadConstants={{
          dashboard: dashboardByMeasure,
          filename: `${selectedObgDetails?.obligation_name}_Transaction_Analytics`,
          title: "Transaction Analytics"
        }}
        actionBar={
          <Stack width="10rem">
            <SimpleSelect
                id="show_by"
                options={measureOptions}
                value={measure}
                onChange={e => setMeasure(e.target.value)}
                renderValue={value => <><span style={{ color: theme.palette.secondaryColors[300] }}>Show by:</span> {value}</>}
            />
          </Stack>
        }
      />
    </PageSkeleton>
  )
};
