import { WhiteCard } from "../../WhiteCard";
import {
  Stack,
  Box,
  Typography,
  
  useTheme,
  Skeleton,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useUser } from "../../../../context";
import { PageSkeleton } from "../PageSkeleton";
import { GENERAL_ANALYTICS_CARDS_DATA, OPERATING_ANALYTICS_CARDS_DATA, getOperatingAnalyticsCardData, getOperatingAnalyticsCardDescription } from "../constants";
import { useObligation } from "../../../../context";
import { Looker } from "../../../../components/common/Looker/Looker";
import { lookerEmbedDomain, lookerEntityCodeList } from "../../../../utils";
import { ExpandableLookerGrid } from "../../../../components/common/Looker/ExpandableLookerGrid";
import { SIGNIFICANT_CUSTOMERS_CARD_INFO } from "../../lookerConstants";

export default function SignificantCustomers() {
  const { isGroupSelected, sisterObligations, selectedObgDetails } = useObligation();

  return (
    <PageSkeleton
      title="Significant Customers"
      description={getOperatingAnalyticsCardDescription("Significant Customers")}
      url=".."
      isURLRelative
    >
      <ExpandableLookerGrid
        cards={SIGNIFICANT_CUSTOMERS_CARD_INFO}
        dashboardParameters={{
          "Obligation ID": selectedObgDetails?.obligation_id,
          "Entitycode": lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails)
        }}
        downloadConstants={{
          dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_CUSTOMERS,
          filename: `${selectedObgDetails?.obligation_name}_Significant_Customers`,
          title: "Significant Customers"
        }}
      />
    </PageSkeleton>
  )
}
