import React from "react";
import { AdjustmentCard } from "./AdjustmentCard";
import { Grid, Typography, useTheme } from "@mui/material";
import { WhiteCard } from "../WhiteCard";

export const SummaryCards = ({ data, filters, setFilters }) => {
  const theme = useTheme();

  const handleSelect = (value) => {
    setFilters({
      ...filters,
      input: {
        ...filters.input,
        value,
      },
    });
  };

  return (
    <WhiteCard style={{ height: "100%" }}>
      <Typography
        component="h4"
        sx={{
          color: theme.palette.random[300],
          fontSize: "1.125rem",
          fontWeight: "600",
          paddingBottom: "10px",
        }}
      >
        Summary
      </Typography>
      <Grid container columns={4} direction="row">
        <Grid item xs={1} sx={{ width: "33%" }}>
          <AdjustmentCard
            title="All"
            value={data?.all}
            theme={theme}
            isSelected={!filters.input?.value}
            handleSelect={() => handleSelect(undefined)}
          />
        </Grid>
        <Grid item xs={1} sx={{ width: "33%", paddingLeft: "15px" }}>
          <AdjustmentCard
            title="Manual Adjustments"
            value={data?.manual_adjustment}
            theme={theme}
            isSelected={filters.input?.value === "manual"}
            handleSelect={() => handleSelect("manual")}
          />
        </Grid>
        <Grid item xs={1} sx={{ width: "33%", paddingLeft: "15px" }}>
          <AdjustmentCard
            title="Edited Transactions"
            value={data?.edited_transaction}
            theme={theme}
            isSelected={filters.input?.value === "edited_transaction"}
            handleSelect={() => handleSelect("edited_transaction")}
          />
        </Grid>
      </Grid>
    </WhiteCard>
  );
};
