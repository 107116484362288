import React from "react";
import {
  InputBase,
  InputLabel,
  Stack,
  Typography,
  InputAdornment,
} from "@mui/material";
import { RedWarningOutlinedIcon } from "../../../../components/common/CustomIcons/RedWarningOutlinedIcon";
import { getCurrencySymbol } from "./constants";
import { preventReloadOnInputEnter } from "../../../../utils";

export const CurrencyInput = ({
  id,
  placeholder,
  name,
  label,
  required,
  error,
  width,
  value,
  onChange,
  disabled,
  showLabel = true,
  currencyCode,
  labelStyles,
  inputStyles,
  theme,
}) => {

const handleInputChange = (event) => {
  const { value } = event.target;

  // Allow only numbers, a single decimal point, and a negative sign at the start.
  const numericValue = value.replace(/[^0-9.-]/g, "");

  // Ensure that the negative sign is only at the start and not duplicated
  const matches = numericValue.match(/^-?\d*(\.\d{0,2})?/);
  const correctedValue = matches ? matches[0] : "";

  // Validate the corrected value
  if (correctedValue === "" || /^-?\d*(\.\d{0,2})?$/.test(correctedValue)) {
    onChange(name, correctedValue);
  }
};

  return (
    <Stack component="form" gap="0.5rem" width={width}>
      {showLabel && (
        <InputLabel
          htmlFor={id}
          sx={{
            fontWeight: 500,
            cursor: "pointer",
            color: theme.palette.baseColors.grey,
            width: "max-content",
            ...labelStyles,
          }}

          // required={!showError && required}
        >
          {label ?? name}&nbsp;
          {required && (
            <Typography
              component="span"
              sx={{
                fontWeight: 400,
                color: theme.palette.functional.error,
              }}
            >
              (Required)
            </Typography>
          )}
        </InputLabel>
      )}
      <InputBase
        id={id}
        placeholder={placeholder}
        sx={{
          height: "3.25rem",
          padding: "1rem",
          borderRadius: "0.25rem",
          border: error
            ? `1px solid ${theme.palette.functional.error}`
            : `1px solid ${theme.palette.random[500]}`,
          color: theme.palette.baseColors.grey,
          ...inputStyles,
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: theme.palette.secondaryColors[400],
              opacity: 1,
            },
          },
        }}
        startAdornment={
          <InputAdornment sx={{ marginRight: "8px" }}>
            {getCurrencySymbol(currencyCode)}
          </InputAdornment>
        }
        spellCheck="false"
        value={value}
        name={name}
        onChange={handleInputChange}
        onKeyDown={preventReloadOnInputEnter}
        disabled={disabled}
      />
      {error && (
        <Stack direction="row" alignItems="center" gap="0.25rem">
          <RedWarningOutlinedIcon
            sx={{ width: "1.25rem", height: "1.25rem" }}
          />
          <Typography variant="body2" color={theme.palette.functional.error}>
            {error}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
