import { useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useParams } from 'react-router-dom';
import * as mui from '@mui/material';

import { WhiteCard } from '../../../WhiteCard';
import { typographyStyle } from '../../index';
import { LockIcon } from '../../../../../components/common/CustomIcons/LockIcon';
import { SquareIcon } from '../../../../../components/common/CustomIcons/SquareIcon';
import { SquareIconChecked } from '../../../../../components/common/CustomIcons/SquareIconChecked';
import { TextArea } from '../../../../../components/common/Input/TextArea';
import { FileUploader } from '../../../../../components/common/FileUploader/FileUploader';
import { useNavigate } from 'react-router-dom';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_EXTENSIONS,
  FORMAT_TO_EXTENSIONS_MAPPING,
} from '../../../../../components/common/FileUploader/constants';
import { ListUploadedFiles } from '../../../Notes/ListUploadedFiles';
import { CloseIcon } from '../../../../../components/common/CustomIcons/CloseIcon';
import { BtnStandard } from '../../../../../components/common/Buttons/BtnStandard';
import { BtnFilled } from '../../../../../components/common/Buttons/BtnFilled';
import { WarningPopup } from '../../../../../components/common/Modal/WarningPopup';
import { useObligation, useUser } from '../../../../../context';
import { getCurrencySymbol } from '../../Utility/helperFunctions';
import { useReconciliation } from '../../../../../context/Reconciliation/ReconciliationContext';

export const AcceptVarianceModal = ({
  preValues,
  item,
  currencyCode,
  handleResolveStatus,
  setPrePopulateValues,
}) => {
  const theme = mui.useTheme();
  const HeaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1244px',
    padding: '32px',
    pb: 0,
  };
  const style = {
    width: '100%',
    height: '50vh',
    border: 'none',
    padding: '2px',
    pr: '1rem',
    pb: '5rem',
    overflow: 'auto',
  };
  const TextFieldStyle = {
    width: '368px',
    height: '50px',
    borderRadius: '4px',
    pt: 0,
    border: `1px solid ${theme.palette.secondaryColors[400]}`,
    bgcolor: theme.palette.random[1100],
  };

  const { userToken, userId } = useUser();
  const { selectedObgDetails } = useObligation();
  const { id } = useParams();
  const { setModalNo } = useReconciliation();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const gmtDayjs = dayjs().tz('GMT');
  const InputFields = [
    { name: 'Transaction ID', value: preValues?.transaction_id },
    { name: 'GL No.', value: preValues?.glaccount },
    { name: 'Entry Date', value: dayjs(preValues?.entry_date).format('MM/DD/YYYY') },
  ];
  const RadioFields = [
    { name: 'Full Amount', value: 'full' },
    { name: 'Partial', value: 'partial' },
  ];

  const [attachments, setAttachments] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(-1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState(null);
  const [cancel, setCancel] = useState(false);
  const [varianceAcceptance, setVarianceAcceptance] = useState('full');
  const [varianceAmount, setVarianceAmount] = useState(item.Difference);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);

  const handleClose = () => setCancel(true);
  const navigate = useNavigate();

  const fileUploadHandler = (e) => {
    const uploadedPreviously = [...attachments];
    const uploadedNow = e.target.files;
    const remainingCapacity = 50 - uploadedPreviously.length;
    if (uploadedNow.length > remainingCapacity) {
      setUploadError(`File upload failed. You can only upload a maximum of ${50} files.`);
      return;
    }
    const isInvalidFileType = [...e.target.files].some((f) => {
      if (!ALLOWED_FILE_TYPES.includes(f.type) || f.name?.split('.')?.length > 2) {
        return true;
      }
      return false;
    });
    if (isInvalidFileType) {
      setUploadError(
        `File upload failed. You can only upload ${ALLOWED_EXTENSIONS.join(
          ', '
        )} file formats. Multi-extension files are not allowed.`
      );
      return;
    }
    const isInvalidFileSize = [...e.target.files].some((f) => {
      const fileSizeInKB = f.size / 1024;
      if (fileSizeInKB > 5120) {
        return true;
      }
      return false;
    });
    if (isInvalidFileSize) {
      setUploadError(`File upload failed. You can upload files with size upto 10 MB only`);
      return;
    }
    setUploadError('');
    const totalUniqueFiles = [];
    for (const file of uploadedNow) {
      const res = uploadedPreviously.filter((f) => f.name === file.name);
      if (res?.length === 0) {
        totalUniqueFiles.push(file);
      }
    }
    setAttachments((prev) => [...prev, ...totalUniqueFiles]);
  };

  const sortFileDataHandler = (colName) => {
    if (colName === 'Attachment') {
      setSortColumn('Attachment name');
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...attachments].sort(
        (a, b) => attachmentNameSortOrder * a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setAttachments(sortedData);
    } else if (colName === 'Attachment type') {
      setSortColumn('Attachment type');
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...attachments].sort((a, b) => {
        const extensionA = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === a.type
        )?.extension;
        const extensionB = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === b.type
        )?.extension;
        return attachmentTypeSortOrder * extensionA.localeCompare(extensionB);
      });
      setAttachments(sortedData);
    }
  };

  const fileDeleteHandler = (name) => {
    const remainingFiles = [...attachments].filter((f) => f.name !== name);
    setAttachments(remainingFiles);
  };

  const handleChange = (event) => {
    if (event.target.value === 'full') setVarianceAmount(item.Difference);
    setVarianceAcceptance(event.target.value);
  };

  const resolveHandler = () => {
    if (parseFloat(varianceAmount) === 0) {
      setWarningPopup(true);
    }
    if (userToken && parseFloat(varianceAmount) !== 0) {
      const hasInvalidFile = attachments.some(
        (f) => f.name?.split('.').length > 2 || !ALLOWED_FILE_TYPES.includes(f.type)
      );
      if (hasInvalidFile) {
        return;
      }
      setLoading(true);
      let url = '';
      url =
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/createacceptvariance?directory_name=recociliation/acceptvariance/&entity=${item?.Entity}&glaccount=${preValues?.glaccount}&entry_date=${preValues?.entry_date}&variance_acceptance=${varianceAcceptance}&variance_amount=${varianceAmount}&notes=${notes}
&obligation_id=${id}&transaction_id=${preValues?.transaction_id}&difference=${item?.Difference}&statcurrencycode=${currencyCode}`;

      const formData = new FormData();
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          formData.append('files', attachments[i]);
        }
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        body: attachments.length > 0 ? formData : null,
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setPrePopulateValues({});
          if (data.status === 'Error') {
            if (data.detail.includes('Invalid document type')) {
              handleResolveStatus('failure', data.detail);
            } else throw new Error(`Something went wrong`);
          } else if (data.status === 'success') {
            handleResolveStatus('success', 'Accept Variance success');
          } else throw new Error('Something went wrong.');
        })
        .catch((err) => {
          handleResolveStatus('failure', 'Accept Variance Failure');
        })
        .finally(() => {
          setModalNo(-1);
          setLoading(false);
        });
      const dateAdded = gmtDayjs.format('YYYY-MM-DDTHH:mm:ss.SSS');
      if (notes) {
        fetch(
          process.env.REACT_APP_BACKEND_URL +
          `/note/createnote?obligation_id=${id}&added_by=${userId}&note_title=AcceptVariance&note_description=${notes}&relates_to=Reconciliations&date_added=${dateAdded}
&tax_type=${selectedObgDetails?.tax_type}&entity=${item.Entity.split(' ')[0]}`,
          {
            method: 'POST',
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              Authorization: `Bearer ${userToken}`,
            },
            body: attachments.length > 0 ? formData : null,
            credentials: 'include',
          }
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else if (res.status === 400) {
              return res.json();
            }
            throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
          })
          .then((data) => {
            if (data.status === 'Error') {
              if (data.detail.includes('Invalid document type')) {
                handleResolveStatus('failure', data.detail);
              } else throw new Error(`Something went wrong.`);
            } else {
              handleResolveStatus('success', 'Accept Variance notes addition success', 'notes');
            }
          })
          .catch((err) => {
            handleResolveStatus('failure', 'Accept Variance notes addition failed', 'notes');
          })
          .finally(() => { });
      }
    }
  };

  return (
    <mui.Modal open={true}>
      <WhiteCard style={HeaderStyle}>
        <mui.Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          sx={{ borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}` }}>
          <mui.Typography
            id='modal-modal-title'
            sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey)}>
            Accept Variance
          </mui.Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </mui.Stack>
        <WhiteCard style={style}>
          <mui.Stack gap={'2rem'}>
            <mui.Grid
              container
              width={'100%'}
              direction={'row'}
              spacing={2}>
              {InputFields.map((inputField) => {
                return (
                  <mui.Grid
                    item
                    xs={4}>
                    <mui.FormControl>
                      <mui.FormHelperText
                        id='helper-text'
                        sx={{ m: 0 }}>
                        <mui.Typography
                          sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                          {inputField.name}
                        </mui.Typography>
                      </mui.FormHelperText>
                      <mui.FilledInput
                        style={TextFieldStyle}
                        sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                        id='filled-adornment-weight'
                        endAdornment={
                          <mui.InputAdornment position='end'>
                            <LockIcon />
                          </mui.InputAdornment>
                        }
                        aria-describedby='helper-text'
                        disableUnderline
                        hiddenLabel
                        disabled
                        value={inputField.value}
                      />
                    </mui.FormControl>
                  </mui.Grid>
                );
              })}
            </mui.Grid>
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1.125rem', 500, theme.palette.baseColors.grey)}>
                How much of the variance would you like to accept?
              </mui.Typography>
              <mui.RadioGroup
                name='use-radio-group'
                defaultValue='full'
                style={{ m: 0, p: 0 }}
                sx={{ width: 'max-content' }}>
                {RadioFields.map((radioItem) => {
                  return (
                    <mui.FormControlLabel
                      value={radioItem.value}
                      label={radioItem.name}
                      onChange={handleChange}
                      control={
                        <mui.Radio
                          icon={<SquareIcon />}
                          checkedIcon={<SquareIconChecked />}
                        />
                      }
                    />
                  );
                })}
              </mui.RadioGroup>
            </mui.Stack>
            <mui.FormControl>
              <mui.FormHelperText
                id='helper-text'
                sx={{ m: 0 }}>
                <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                  {'Variance'}
                </mui.Typography>
              </mui.FormHelperText>
              <mui.FilledInput
                style={{
                  ...TextFieldStyle,
                  bgcolor: varianceAcceptance === 'full' ? theme.palette.random[1100] : '#fff',
                }}
                type='number'
                sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                id='filled-adornment-weight'
                endAdornment={
                  varianceAcceptance === 'full' && (
                    <mui.InputAdornment position='end'>
                      <LockIcon />
                    </mui.InputAdornment>
                  )
                }
                startAdornment={
                  <mui.InputAdornment position='start'>
                    {getCurrencySymbol(currencyCode)}
                  </mui.InputAdornment>
                }
                aria-describedby='helper-text'
                disableUnderline
                hiddenLabel
                onChange={(e) => {
                  setVarianceAmount(e.target.value);
                }}
                disabled={varianceAcceptance === 'full'}
                value={varianceAmount}
                autoComplete='off'
              />
            </mui.FormControl>
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                Notes
              </mui.Typography>
              <TextArea onChange={(e) => setNotes(e.target.value)} />
            </mui.Stack>
            <FileUploader
              fileSize='5MB'
              onChange={fileUploadHandler}
              errorMsg={uploadError}
            />
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 700, theme.palette.baseColors.grey)}>
                Attachments
              </mui.Typography>
              <ListUploadedFiles
                uploadedFiles={attachments}
                fileDeleteHandler={fileDeleteHandler}
                sortFileDataHandler={sortFileDataHandler}
                sortColumn={sortColumn}
                attachmentNameSortOrder={attachmentNameSortOrder}
                attachmentTypeSortOrder={attachmentTypeSortOrder}
                extensionProcessingRequired={true}
              />
            </mui.Stack>
          </mui.Stack>
        </WhiteCard>
        <mui.Stack
          flexDirection='row'
          justifyContent='flex-end'
          gap='0.5rem'
          pb='2rem'>
          <BtnStandard
            btnTxt={'Cancel'}
            onClick={handleClose}
            disabled={loading}
            styling={{ height: '2.75rem', padding: '0.5rem 1.5rem', fontSize: '1rem' }}
          />
          <BtnFilled
            size='medium'
            styling={{
              '&:disabled': {
                backgroundColor: theme.palette.primaryColors[200],
                color: theme.palette.secondaryColors[100],
              },
            }}
            btnTxt={'Resolve'}
            loading={loading}
            disabled={loading}
            onClick={resolveHandler}
          />
        </mui.Stack>
        {cancel && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setCancel(false);
            }}
            continueHandler={() => {
              setCancel(false);
              setPrePopulateValues({});
              setModalNo(-1);
            }}
            warningTitle={'Discard?'}
          />
        )}
        {warningPopup && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setWarningPopup(false);
            }}
            continueHandler={() => {
              setWarningPopup(false);
            }}
            warningTitle={'Warning!'}
            warningMessage={'variance amount cannot be 0'}
          />
        )}
      </WhiteCard>
    </mui.Modal>
  );
};
