import { SvgIcon } from '@mui/material';
import React from 'react'

export const TrendsAndVariancesIcon = (props) => {
    return (
        <SvgIcon {...props}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1298_10112)">
        <path d="M6.66667 4V25.3333H28V28H4V4H6.66667ZM26.5867 7.92L29.4133 10.7467L21.3333 18.8293L17.3333 14.8293L12.08 20.0827L9.25333 17.2533L17.3333 9.17333L21.3333 13.1733L26.5867 7.92Z" fill="#474747"/>
        </g>
        <defs>
        <clipPath id="clip0_1298_10112">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </SvgIcon>
    )
};
