import { InputBase, InputLabel, Paper, Stack, Input, useTheme, Typography } from "@mui/material"
import { LockIcon } from '../../common/CustomIcons/LockIcon';

export const ReadOnlyInput = ({ id, name, label, width, value, textTransform, labelStyles, inputStyles }) => {
  const theme = useTheme();
  return (
    <Stack component='form' gap='0.5rem' width={width}>
      <InputLabel
        htmlFor={id}
        sx={{
          fontWeight: 500,
          cursor: 'pointer',
          color: theme.palette.baseColors.grey,
          width: 'max-content',
          ...labelStyles
        }}
      >
        {label ?? name}
      </InputLabel>
      <InputBase
        id={id}
        sx={{
          height: '3.25rem',
          padding: '1rem',
          borderRadius: '0.25rem',
          border: `1px solid ${theme.palette.secondaryColors[400]}`,
          backgroundColor: theme.palette.random[1100],
          ...inputStyles

        }}
        inputProps={{
          sx: {
            '&:disabled': {
              WebkitTextFillColor: theme.palette.secondaryColors[400],
              textTransform: textTransform ? textTransform : 'none',
            }
          }
        }}
        spellCheck='false'
        value={value}
        disabled
        endAdornment={<LockIcon />}
      />
    </Stack>
  )
}