import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { pagesData } from "./pagesData";

export const PageRouter = () => {
  const { pathname } = useLocation();
  const hasUserStored = localStorage.getItem('myToken') && localStorage.getItem('myUserid');
  const needNotRedirect = pathname === "/authorize" || pathname === "/policy-acceptance" || pathname === "/login"
  const pageRoutes = pagesData.map(obj => {
    if (obj.nested) {
      const subRoutes = obj.nestedRoutes;
      return (
        <Route key={obj.title} path={obj.path}>
          <Route index element={obj.element} key={`${obj.title}-main`} />
          {subRoutes?.map(subRoute => <Route key={subRoute.title} path={subRoute.path} element={subRoute.element} />)}
        </Route>
      );
    }
    return <Route key={obj.title} element={obj.element} path={obj.path} />
  });


  return (
    <Routes>
      <Route exact path="/" element={<Navigate to='/home' />} />
      {pageRoutes}
    </Routes>
  )
}
