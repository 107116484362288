import { useTheme, Typography, Stack, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Toast } from '../Toast';
import { StatsIcon } from '../../../components/common/CustomIcons/Exceptions';
import { GraphIcon } from '../../../components/common/CustomIcons/Exceptions';
import { IconToogle } from '../../../components/common/Toggle/Toggle';
import { WhiteCard } from '../WhiteCard';
import { PageSkeleton } from '../Analytics/PageSkeleton';
import {
  PaginatedTable,
  StyledTableBodyCell,
} from '../../../components/Paginated Table/PaginatedTable';
import { TransactionFilter } from '../../../components/common/Filters/TransactionFilter';
import { useNavigate, useParams } from 'react-router-dom';
import { Looker } from '../../../components/common/Looker/Looker';
import { useObligation, useUser } from '../../../context';
import { formatNumberWithCommas } from '../Exceptions/TransactionDetail/constants';
import {
  currencyFormatterWithoutSymbol,
  formatDate,
  getCurrentRelativePath,
  lookerEmbedDomain,
  nullableInvoiceNumber,
  reverseDate,
  conditionalEntityCodeQuery,
  conditionalEntityCodeDefaultQuery,
  lookerEntityCodeList,
} from '../../../utils';
import { TRANSACTIONS_LOADED_CARD_INFO } from '../lookerConstants';
import { ExpandableLookerGrid } from '../../../components/common/Looker/ExpandableLookerGrid';

export const TransactionDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userToken } = useUser();
  const { id, member } = useParams();
  const { isGroupSelected, sisterObligations, selectedObgDetails: obligation } = useObligation();

  const [loadingCount, setLoadingCount] = useState(true);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const [view, setView] = useState('table');
  const viewHandler = (v) => {
    setView(v);
  };
  const [toast, setToast] = useState({ type: '', message: '' });

  useEffect(() => {
    setLoadingCount(true);
    if (!userToken || !member) return;

    const url =
      process.env.REACT_APP_BACKEND_URL +
      `/datasummary/summaryofdata?obligation_id=${obligation.obligation_id
      }${conditionalEntityCodeQuery(obligation)}`;

    let _count;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        _count = data?.Total_transaction_loaded;
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Transaction total could not be fetched.',
        });
      })
      .finally(() => {
        setTotalTransactions(_count);
        setLoadingCount(false);
      });
  }, [userToken, obligation]);

  // #region Filter options.
  const [transactionIdOptions, setTransactionIdOptions] = useState([]);
  const [invoiceNumberOptions, setInvoiceNumberOptions] = useState([]);
  const [taxcodeOptions, setTaxcodeOptions] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [counterpartyOptions, setCounterpartyOptions] = useState([]);

  const [areOptionsLoading, setAreOptionsLoading] = useState(false);

  useEffect(() => {
    if (!userToken) return;

    setAreOptionsLoading(true);
    setTransactionIdOptions([]);
    setInvoiceNumberOptions([]);
    setTaxcodeOptions([]);
    setEntityOptions([]);
    setCounterpartyOptions([]);

    const taxcodeOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL + `/transaction/distincttaxcodes?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setTaxcodeOptions(data);
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    const entityOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/obligationentities/getdistinctmember?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setEntityOptions(data.member_names.map((entity) => entity.name));
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    Promise.all([taxcodeOptionsPromise, entityOptionsPromise]).finally(() => {
      setAreOptionsLoading(false);
    });
  }, [userToken, id]);
  // #endregion

  return (
    <PageSkeleton
      title='Total Transactions Loaded'
      url={`/obligations/${id}/${member}/data`}>
      <Stack gap='1.5rem'>
        <WhiteCard
          style={{
            padding: '1.5rem',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography
            component='h4'
            sx={{
              fontWeight: 700,
              fontSize: '1.125rem',
              color: theme.palette.baseColors.black,
            }}>
            All Transactions
            <span style={{ fontWeight: 400 }}>
              {' '}
              {loadingCount ? (
                <Skeleton sx={{ display: 'inline-block' }}>
                  <span>{'(1,000)'}</span>
                </Skeleton>
              ) : (
                `(${formatNumberWithCommas(totalTransactions)})`
              )}
            </span>
          </Typography>
          <IconToogle
            viewHandler={viewHandler}
            view={view}
            leftData={{
              icon: (
                <StatsIcon
                  sx={{
                    color:
                      view === 'table'
                        ? theme.palette.primaryColors[200]
                        : theme.palette.secondaryColors[400],
                  }}
                />
              ),
              view: 'table',
            }}
            rightData={{
              icon: (
                <GraphIcon
                  sx={{
                    color:
                      view === 'graphs'
                        ? theme.palette.primaryColors[200]
                        : theme.palette.secondaryColors[400],
                  }}
                />
              ),
              view: 'graphs',
            }}
          />
        </WhiteCard>
        {view === 'table' && (
          <>
            <WhiteCard style={{ padding: '1.5rem', justifyContent: 'space-between' }}>
              <PaginatedTable
                api={{
                  endpoint: '/transaction/listall',
                  records: 'transactions',
                  count: 'count',
                }}
                columns={[
                  { label: 'Inv No.', property: 'invoiceno', cellWidth: -1 },
                  { label: 'Flow', property: 'flow', cellWidth: -1 },
                  { label: 'Entity', property: 'entityname', cellWidth: -1 },
                  { label: 'Statutory Currency', property: 'statcurrencycode', cellWidth: -1 },
                  { label: 'Gross', property: 'statgrossamount', cellWidth: -1 },
                  { label: 'Net', property: 'statnetamount', cellWidth: -1 },
                  {
                    label: 'VAT',
                    property: 'statvatamount',
                    cellWidth: -1,
                  },
                  { label: 'Inv Date', property: 'invoicedate', cellWidth: -1 },
                  { label: 'Posting Date', property: 'postingdate', cellWidth: -1 },
                  { label: 'Tax Code', property: 'taxcode', cellWidth: -1 },
                  {
                    label: 'Counterparty',
                    property: 'compliancecounterpartycompanyname',
                    cellWidth: -1,
                  },
                ]}
                defaultColumn='invoiceno'
                filterInfo={{
                  component: (
                    <TransactionFilter
                      fields={[
                        {
                          column_name: 'Transaction ID',
                          mapping: 'id',
                          type: 'text_case_sensitive',
                        },
                        {
                          column_name: 'Flow',
                          mapping: 'flow',
                          type: 'text',
                        },
                        {
                          column_name: 'Inv No',
                          mapping: 'invoiceno',
                          type: 'text',
                        },
                        {
                          column_name: 'Inv Date',
                          mapping: 'invoicedate',
                          type: 'date',
                        },
                        {
                          column_name: 'Net',
                          mapping: 'statnetamount',
                          type: 'numeric',
                        },
                        {
                          column_name: 'VAT',
                          mapping: 'statvatamount',
                          type: 'numeric',
                        },
                        {
                          column_name: 'Entity',
                          mapping: 'entityname',
                          type: 'text',
                          options: entityOptions,
                        },
                        {
                          column_name: 'Tax Code',
                          mapping: 'taxcode',
                          type: 'text',
                          options: taxcodeOptions,
                        },
                        {
                          column_name: 'Counterparty',
                          mapping: 'compliancecounterpartycompanyname',
                          type: 'text',
                        },
                      ]}
                      autocompleteProps={{
                        isLoading: areOptionsLoading,
                      }}
                    />
                  ),
                  default: [
                    `obligation_id__eq=${id}`,
                    conditionalEntityCodeDefaultQuery(obligation),
                  ],
                }}
                a11y={{
                  tableAriaLabel: 'transaction list',
                  rowPrefix: 'transaction',
                }}
                rowItem={(transaction) => {
                  const formatFloat = (num) => parseFloat(num).toFixed(2).toLocaleString('en-AU');
                  return [
                    <StyledTableBodyCell>
                      <Typography
                        onClick={() =>
                          navigate(
                            `/obligations/${id}/${member}/individual-transaction?id=${transaction.id
                            }&code=${transaction.entitycode
                            }&backAddress=${getCurrentRelativePath()}`
                          )
                        }
                        sx={{
                          display: 'inline-block',
                          fontSize: '0.875rem',
                          color: theme.palette.functional.tableLink,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}>
                        {nullableInvoiceNumber(transaction.invoiceno, transaction.erpdocumentno)}
                      </Typography>
                    </StyledTableBodyCell>,
                    <StyledTableBodyCell>{transaction.flow}</StyledTableBodyCell>,
                    <StyledTableBodyCell>{transaction.entityname}</StyledTableBodyCell>,
                    <StyledTableBodyCell>{transaction.statcurrencycode}</StyledTableBodyCell>,
                    <StyledTableBodyCell>
                      {currencyFormatterWithoutSymbol(transaction.statgrossamount, false, {
                        minimumFractionDigits: 2,
                      })}
                    </StyledTableBodyCell>,
                    <StyledTableBodyCell>
                      {currencyFormatterWithoutSymbol(transaction.statnetamount, false, {
                        minimumFractionDigits: 2,
                      })}
                    </StyledTableBodyCell>,
                    <StyledTableBodyCell>
                      {currencyFormatterWithoutSymbol(transaction.statvatamount, false, {
                        minimumFractionDigits: 2,
                      })}
                    </StyledTableBodyCell>,
                    <StyledTableBodyCell>
                      {formatDate(reverseDate(transaction.invoicedate, '-'), '-')}
                    </StyledTableBodyCell>,
                    <StyledTableBodyCell>
                      {formatDate(reverseDate(transaction.postingdate, '-'), '-')}
                    </StyledTableBodyCell>,
                    <StyledTableBodyCell>{transaction.taxcode}</StyledTableBodyCell>,
                    <StyledTableBodyCell>
                      {transaction.compliancecounterpartycompanyname}
                    </StyledTableBodyCell>,
                  ];
                }}
              />
            </WhiteCard>
          </>
        )}
        {view === 'graphs' && (
          <ExpandableLookerGrid
            cards={TRANSACTIONS_LOADED_CARD_INFO}
            dashboardParameters={{
              'Obligation ID': obligation?.obligation_id,
              Entitycode: lookerEntityCodeList(isGroupSelected, sisterObligations, obligation),
            }}
            downloadConstants={{
              dashboard: process.env.REACT_APP_LOOKER_TRANSACTION_LOADED,
              filename: `${obligation?.obligation_name}_Transaction_Loaded`,
              title: 'Transaction Loaded',
            }}
          />
        )}
      </Stack>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </PageSkeleton>
  );
};
