import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack, Typography, useTheme } from '@mui/material';

import { Back } from '../../../../components/common/CustomIcons/BackNavigateIcon';
import { BtnStandard } from '../../../../components/common/Buttons/BtnStandard';
import { ArrowLeftIcon } from '../../../../components/common/CustomIcons/ArrowLeftIcon';
import { ReconciliationSummary } from './ReconciliationSummary';
import { ReconciliationContextProvider } from '../../../../context/Reconciliation/ReconciliationContext';
import {
  SummaryPageHeadings,
  summaryPageSubHeadings,
} from '../Constants/ReconciliationSummaryConstants';
import { useState } from 'react';
import { BackButton } from '../../../../components/common/Buttons/Utility/BackButton';

export const ReconciliationSummaryPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id, member, subTab } = useParams();
  const [summaryPage] = useState(
    subTab === 'trial-balances-vs-transactions-summary'
      ? 1
      : subTab === 'vat-gl-vs-transactions-summary'
      ? 2
      : subTab === 'vat-gl-vs-transactions-without-tax-code-summary'
      ? 3
      : 4
  );

  return (
    <Stack gap={'2.5rem'}>
      <Box
        padding={'1.875rem 2.5rem'}
        sx={{
          backgroundColor: theme.palette.baseColors.white,
        }}>
        <BackButton backAddress={`/obligations/${id}/${member}/reconciliations`} />
        <Typography
          component='h1'
          sx={{
            color: theme.palette.random[300],
            fontSize: '2.25rem',
            fontWeight: '700',
          }}>
          {SummaryPageHeadings[summaryPage - 1]}
        </Typography>
        <Typography
          component='h5'
          sx={{
            mt: '.25rem',
            color: theme.palette.random[600],
            fontSize: '0.875rem',
            fontWeight: '500',
          }}>
          {summaryPageSubHeadings[summaryPage - 1]}
        </Typography>
      </Box>

      <ReconciliationContextProvider>
        <ReconciliationSummary summaryPage={summaryPage} />
      </ReconciliationContextProvider>
    </Stack>
  );
};
