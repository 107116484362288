export const dummyData = {
  summary: [
    { name: "All", value: 11 },
    { name: "Manual Adjustments", value: 1 },
    { name: "Edited Transaction", value: 11 },
    { name: "Manual Transaction", value: 2 },
  ],
  table: [
    {
      adjustment_id: "ADJ-198011256",
      description: "Current probably full meeting happy body everyone.",
      created_by: "John Barrett",
      created_on: "05-Jun-2023",
      member: "PwC Dolphin",
      net_amount: 4055,
      vat_amount: 9217,
    },
    {
      adjustment_id: "ADJ-315785433",
      description: "Peace air actually buy forget reflect.",
      created_by: "Zoe Washington",
      created_on: "06-May-2023",
      member: "PwC Elephant",
      net_amount: 1521,
      vat_amount: 5387,
    },
    {
      adjustment_id: "ADJ-724816817",
      description: "Market point nor determine design moment chair item.",
      created_by: "Renee Peterson",
      created_on: "12-Sep-2024",
      member: "PwC Wolf",
      net_amount: 4224,
      vat_amount: 8647,
    },
    {
      adjustment_id: "ADJ-757279902",
      description: "Recognize develop since agreement.",
      created_by: "Jacqueline Reed",
      created_on: "19-May-2024",
      member: "PwC Eagle",
      net_amount: 2038,
      vat_amount: 9435,
    },
    {
      adjustment_id: "ADJ-735795478",
      description: "Mind civil last eight against sister.",
      created_by: "Russell Green",
      created_on: "16-Jun-2024",
      member: "PwC Lion",
      net_amount: 1572,
      vat_amount: 1575,
    },
    {
      adjustment_id: "ADJ-538044519",
      description: "North bank option word soon.",
      created_by: "Kimberly Gray",
      created_on: "28-May-2024",
      member: "PwC Wolf",
      net_amount: 2990,
      vat_amount: 6303,
    },
    {
      adjustment_id: "ADJ-477175826",
      description: "Land difference challenge fear every.",
      created_by: "Jennifer Williams",
      created_on: "02-Mar-2024",
      member: "PwC Shark",
      net_amount: 4132,
      vat_amount: 9624,
    },
    {
      adjustment_id: "ADJ-529561366",
      description: "Knowledge to air.",
      created_by: "Elizabeth Patel",
      created_on: "18-Oct-2024",
      member: "PwC Elephant",
      net_amount: 2147,
      vat_amount: 275,
    },
    {
      adjustment_id: "ADJ-512571791",
      description: "Brother into analysis join get green along.",
      created_by: "Daniel Mcmahon",
      created_on: "24-Sep-2024",
      member: "PwC Lion",
      net_amount: 3852,
      vat_amount: 3956,
    },
  ],
};
