import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { NavMenu } from '../../components/common/Navbar/NavMenu';
import { useObligation } from '../../context';
import AvatarIconsGroup from '../../components/Avatar Groups/AvatarIconsGroup';
import TextTag from '../../components/common/Text Tags/TextTag';
import { getDaysDifference } from '../../utils';
import EntityDropdown from './EntityDropdown';

export const Common = ({ availableTabs, isFetchingData, setIsFetchingData }) => {
  const theme = useTheme();
  const { selectedObgDetails } = useObligation();
  const todaysDate = useMemo(() => new Date(), []);
  let isCompleted = undefined;
  let isDueToday = undefined;
  let isOverdue = undefined;
  if (selectedObgDetails) {
    isCompleted =
      selectedObgDetails.stage?.toLowerCase() === 'completed' ||
      selectedObgDetails.stage?.toLowerCase() === 'finalized';
    isDueToday = selectedObgDetails.due_date
      ? todaysDate?.setHours(0, 0, 0, 0) ===
        new Date(selectedObgDetails.due_date)?.setHours(0, 0, 0, 0)
      : false;
    isOverdue = selectedObgDetails.obligation_status === 'OVERDUE';
  }

  const isLoading =
    !selectedObgDetails ||
    !selectedObgDetails?.obligation_name ||
    isCompleted === undefined ||
    !isDueToday === undefined ||
    !isOverdue === undefined ||
    !todaysDate;

  return (
    <Stack
      gap='1rem'
      sx={{
        p: '2.5rem',
        pb: '0',
        border: `1px solid ${theme.palette.random[900]}`,
        borderTop: 'none',
      }}
      bgcolor={theme.palette.baseColors.white}>
      <Stack
        width='100%'
        direction='row'
        alignItems='center'
        justifyContent='space-between'>
        <Stack>
          {isLoading ? (
            <Skeleton>
              <TextTag
                size='s'
                text='DUE IN 300 DAYS'
                variant='green'
              />
            </Skeleton>
          ) : (
            <Stack
              direction='row'
              gap='.5rem'>
              <TextTag
                size='s'
                text={
                  isCompleted
                    ? selectedObgDetails?.stage
                    : isDueToday
                    ? 'Due today'
                    : isOverdue
                    ? 'Overdue'
                    : `Due in ${getDaysDifference(
                        todaysDate,
                        new Date(selectedObgDetails['due_date'])
                      )} days`
                }
                variant={
                  isCompleted ? 'green' : isDueToday ? 'yellow' : isOverdue ? 'red' : 'yellow'
                }
              />
              {selectedObgDetails?.new_data === true && (
                <TextTag
                  size='s'
                  text='New Data'
                  variant='grey'
                />
              )}
            </Stack>
          )}
          {isLoading ? (
            <Skeleton>
              <Typography
                sx={{ fontSize: '2.25rem', fontWeight: 700, color: theme.palette.random[300] }}>
                PLACEHOLDER OBLIGATION
              </Typography>
            </Skeleton>
          ) : (
            <Typography
              sx={{ fontSize: '2.25rem', fontWeight: 700, color: theme.palette.random[300] }}>
              {selectedObgDetails?.obligation_name}
            </Typography>
          )}
          {selectedObgDetails && (
            <EntityDropdown
              current={selectedObgDetails?.member}
              isFetchingData={isFetchingData}
              setIsFetchingData={setIsFetchingData}
            />
          )}
        </Stack>
        <AvatarIconsGroup />
      </Stack>
      <Box width='100%'>
        <Stack
          direction='row'
          display='flex'
          width='100%'
          sx={{
            gap: '1.5rem',
            height: '3rem',
            mb: '-1px',
          }}>
          {isFetchingData ? (
            <Skeleton
              height={48}
              width={'100%'}
              sx={{ transform: 'none' }}
            />
          ) : (
            <NavMenu availableTabs={availableTabs} />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
