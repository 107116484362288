import { SvgIcon } from '@mui/material';

export const SquareIconChecked = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect
          width='16'
          height='16'
          rx='4'
          fill='#D04A02'
        />
        <rect
          x='0.5'
          y='0.5'
          width='15'
          height='15'
          rx='3.5'
          stroke='black'
          stroke-opacity='0.1'
        />
        <path
          d='M4.50024 8L7.00024 10.5L12.0002 5.5'
          stroke='white'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};
