import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BtnStandard } from "../../../components/common/Buttons/BtnStandard";
import { BackButton } from "../../../components/common/Buttons/Utility/BackButton";
import { ArrowLeftIcon } from "../../../components/common/CustomIcons/ArrowLeftIcon";
import { useObligation } from "../../../context";

export const PageSkeleton = ({ title, description, children, url, isURLRelative, hasBackButton = true }) => {
    const theme = useTheme();
    
    return (
        <Stack height={1}>
            <Box
                padding={'1.875rem 2.5rem'}
                sx={{
                    backgroundColor: theme.palette.baseColors.white
                }}>
                {hasBackButton && <BackButton backAddress={url} isBackAddressRelative={isURLRelative} />}
                <Typography
                    component="h1"
                    sx={{
                        color: theme.palette.random[300], fontSize: '2.25rem',
                        fontWeight: '700'
                    }}>
                    {title}
                </Typography>
                <Typography
                    component="h5"
                    sx={{
                        mt: ".25rem",
                        color: theme.palette.random[600],
                        fontSize: '0.875rem',
                        fontWeight: '500',
                    }}>
                    {description}
                </Typography>
            </Box>

            <Stack
                padding='2.5rem'
                gap='1.5rem'
            >
                {children}
            </Stack>

        </Stack>

    );
};
