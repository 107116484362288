import { Stack, Typography, useTheme } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { EditableInput } from "../../../components/common/Input/EditableInput";
import { TextArea } from "../../../components/common/Input/TextArea";
import { useEffect, useState } from "react";
import { ReadOnlyInput } from "../../../components/common/Input/ReadOnlyInput";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ListUploadedFiles } from "./ListUploadedFiles";
import { useObligation, useUser } from "../../../context";
import { FileUploader } from "../../../components/common/FileUploader/FileUploader";
import { useNavigate } from "react-router-dom";

export const EditNote = ({
  closePopupHandler,
  noteEditSuccessfulHandler,
  note,
  noteEditFailureHandler,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const gmtDayjs = dayjs().tz("GMT");
  const { userToken } = useUser();
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDesc, setNoteDesc] = useState("");
  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { userId } = useUser();
  const [sortColumn, setSortColumn] = useState(null);
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(1);
  const [editing, setEditing] = useState(false);
  const [enableEditing, setEnableEditing] = useState(false);

  const { isObligationLocked } = useObligation()
  const navigate = useNavigate()

  useEffect(() => {
    if (note?.note_title) {
      setNoteTitle(note.note_title);
    }
    if (note?.note_description) {
      setNoteDesc(note.note_description);
    }
    if (note?.attachments) {
      const attachments = note.attachments?.map((attachment) => {
        const arr = attachment?.split("/");
        const fileName = arr[arr.length - 1];
        const extension = fileName?.split(".")?.pop()?.toUpperCase();
        const obj = { name: fileName, extension };
        return obj;
      });
      setFiles(attachments);
    }
  }, [note]);

  const sortFileDataHandler = (colName) => {
    if (colName === "Attachment") {
      setSortColumn("Attachment name");
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...files].sort(
        (a, b) =>
          attachmentNameSortOrder *
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setFiles(sortedData);
    } else if (colName === "Attachment type") {
      setSortColumn("Attachment type");
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...files].sort(
        (a, b) =>
          attachmentTypeSortOrder *
          a.extension.toLowerCase().localeCompare(b.extension.toLowerCase())
      );
      setFiles(sortedData);
    }
  };
  const noteDescChangeHandler = (e) => {
    setNoteDesc(e.target.value);
  };

  const noteTitleChangeHandler = (e) => {
    setNoteTitle(e.target.value);
  };

  const editHandler = () => {
    setIsSubmitted(true);
    if (noteTitle.trim().length === 0 || noteDesc.trim().length === 0) {
      return;
    }
    setEditing(true);
    setIsSubmitted(false);

    const dateModified = gmtDayjs.format("YYYY-MM-DDTHH:mm:ss.SSS");
    const reqBody = JSON.stringify({
      note_title: noteTitle,
      note_description: noteDesc,
      date_modified: dateModified,
      modified_by: userId,
    });
    fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/note/updatenote?note_id=${note?.note_id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: reqBody,
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        closePopupHandler();
        noteEditSuccessfulHandler(
          note?.note_id,
          noteTitle,
          noteDesc,
          dateModified
        );
      })
      .catch((err) => {
        console.error(err);
        noteEditFailureHandler();
        closePopupHandler();
      })
      .finally(() => {
        setEditing(false);
      });
  };
  const enableEditingHandler = () => {
    setEnableEditing(true);
  };
  return (
    <Modal
      title="View Note"
      submitHandler={editHandler}
      cancelHandler={closePopupHandler}
      loading={editing}
      secondaryBtnText="Edit"
      enableEditingHandler={enableEditingHandler}
      enableEditing={enableEditing}
      disableSecondaryButton={isObligationLocked || note?.added_by !== userId}
    >
      <Stack
        width="100%"
        mt="2rem"
        gap="2.5rem"
        sx={{ height: "65vh", overflowY: "scroll" }}
        pb="0.5rem"
      >
        <Stack direction="row" gap="1.5rem">
          <ReadOnlyInput
            name="Relates to"
            id="relates-to"
            value={note?.relates_to}
            width="25%"
          />
          <ReadOnlyInput
            name="User"
            id="user"
            value={note?.added_by_details?.user_name}
            width="25%"
            textTransform="capitalize"
          />
          <ReadOnlyInput
            name="Date added"
            id="date-added"
            width="25%"
            value={
              note?.date_added
                ? dayjs(note.date_added?.split("T")?.[0])?.format("DD MMM YYYY")
                : "-"
            }
          />
          <ReadOnlyInput
            name="Date modified"
            id="date-modified"
            value={
              note?.date_modified
                ? dayjs(note.date_modified?.split("T")?.[0])?.format(
                  "DD MMM YYYY"
                )
                : "-"
            }
            width="25%"
          />
        </Stack>
        <Stack>
          <EditableInput
            id="note-title"
            name="Note title"
            required={true}
            placeholder="Enter note title..."
            width="34%"
            value={noteTitle}
            onChange={note?.added_by === userId && noteTitleChangeHandler}
            error={
              isSubmitted && noteTitle.trim().length === 0
                ? "This is a required field"
                : null
            }
            disabled={!enableEditing}
          />
        </Stack>
        <Stack>
          <TextArea
            id="note-desc"
            name="Note description"
            disabled={!enableEditing}
            rows={2}
            placeholder="Enter your note..."
            hint={`${noteDesc.length}/2000`}
            value={noteDesc}
            onChange={note?.added_by === userId && noteDescChangeHandler}
            maxLength={2000}
            required={true}
            error={
              isSubmitted && noteDesc.trim().length === 0
                ? "This is a required field."
                : null
            }
          />
        </Stack>
        <FileUploader fileSize="10MB" disabled={true} />
        <Stack gap="1rem">
          {files?.length > 0 && (
            <>
              <Typography
                fontWeight={700}
                color={theme.palette.baseColors.grey}
              >
                Attachments
              </Typography>

              <ListUploadedFiles
                uploadedFiles={files}
                sortFileDataHandler={sortFileDataHandler}
                sortColumn={sortColumn}
                attachmentNameSortOrder={attachmentNameSortOrder}
                attachmentTypeSortOrder={attachmentTypeSortOrder}
                extensionProcessingRequired={false}
              />
            </>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
