import { SvgIcon } from '@mui/material';

export const TotalOutputVatIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.67175 2.25718C7.59975 -0.96182 13.4047 -0.73782 17.0717 2.92918C20.9767 6.83418 20.9767 13.1662 17.0717 17.0712C13.1667 20.9762 6.83475 20.9762 2.92975 17.0712C1.73138 15.8744 0.856822 14.3928 0.388206 12.7653C-0.0804095 11.1378 -0.12771 9.41795 0.250746 7.76718L0.327746 7.45418L2.26175 7.96418C1.78229 9.78644 1.96144 11.7195 2.76762 13.4226C3.57381 15.1257 4.95528 16.4896 6.66858 17.2739C8.38188 18.0582 10.317 18.2125 12.133 17.7098C13.949 17.207 15.5292 16.0794 16.5952 14.5256C17.6612 12.9718 18.1445 11.0917 17.96 9.21644C17.7755 7.34121 16.935 5.5913 15.5866 4.27508C14.2382 2.95887 12.4686 2.16084 10.5894 2.02165C8.71029 1.88245 6.84233 2.41101 5.31475 3.51418L5.09375 3.68018L6.11075 4.69718L1.51475 5.75718L2.57475 1.16118L3.67075 2.25718H3.67175ZM11.0007 4.00018V6.00018H13.5007V8.00018H8.00075C7.8758 7.99995 7.7553 8.04651 7.66296 8.13069C7.57063 8.21487 7.51316 8.33057 7.50187 8.455C7.49058 8.57944 7.52629 8.70359 7.60197 8.80301C7.67764 8.90243 7.7878 8.96992 7.91075 8.99218L8.00075 9.00018H12.0007C12.6638 9.00018 13.2997 9.26357 13.7685 9.73241C14.2374 10.2013 14.5007 10.8371 14.5007 11.5002C14.5007 12.1632 14.2374 12.7991 13.7685 13.2679C13.2997 13.7368 12.6638 14.0002 12.0007 14.0002H11.0007V16.0002H9.00075V14.0002H6.50075V12.0002H12.0007C12.1257 12.0004 12.2462 11.9539 12.3385 11.8697C12.4309 11.7855 12.4883 11.6698 12.4996 11.5454C12.5109 11.4209 12.4752 11.2968 12.3995 11.1973C12.3239 11.0979 12.2137 11.0304 12.0907 11.0082L12.0007 11.0002H8.00075C7.33771 11.0002 6.70182 10.7368 6.23298 10.2679C5.76414 9.79911 5.50075 9.16322 5.50075 8.50018C5.50075 7.83714 5.76414 7.20125 6.23298 6.73241C6.70182 6.26357 7.33771 6.00018 8.00075 6.00018H9.00075V4.00018H11.0007Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};