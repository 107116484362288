import * as React from 'react';
import { useEffect, useContext } from 'react';
import { Customselect } from './Select';
import { Box, Chip, Typography, styled, useTheme } from '@mui/material';
import { BtnLink } from '../Buttons/BtnLink';
import { useUser } from '../../../../src/context';

import { Cross1Icon } from '@radix-ui/react-icons';
import SingleInputDateRangePicker from './DateRangePicker';
import RegionCountry from './RegionCountry';
import { useDateInputState } from 'rsuite/esm/DateInput';
import { formatDate } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from '../../../pages/Obligations/FilterContext';
import {
  DUMMY_GROUP_NAMES,
  DUMMY_MEMBER_NAMES,
  DUMMY_REGIONS_DATA,
} from '../../../pages/Obligations/dummyData';

const optionsTaxes = ['IDT', 'WHT', 'CIT', 'FA'];

const optionsobligationgroup = [
  'AU Obligations group',
  'IND Obligations group',
  'US Obligations group',
];
const optionsStatus = ['New data', 'Due this week', 'Due next week', 'Overdue'];

const Container = styled(Box)(({ theme }) => ({
  height: '3rem',
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  backgroundColor: theme.palette.baseColors.white,
  flexShrink: 0,
}));
export const Filter = () => {
  const [tax, settax] = React.useState([]);
  const [dueDate, setdueDate] = React.useState([]);
  const [region, setregion] = React.useState([]);
  const [country, setcountry] = React.useState([]);
  const [obligationsData, setObligationsData] = React.useState();
  const [obligationgroup, setobligationgroup] = React.useState([]);
  const [loadingObligationsData, setLoadingObligationsData] = React.useState(true);
  const [status, setstatus] = React.useState([]);
  const [dateEraseVal, setDateErase] = React.useState(0);
  const [RegionCountryData, setData] = React.useState({});
  const [loadingRegions, setLoadingRegions] = React.useState(true);
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const theme = useTheme();

  const { userToken } = useUser();
  const navigate = useNavigate();

  const getRegionName = (regionId) => {
    return RegionCountryData.regions.find((region) => region.id === regionId)?.desc || '';
  };

  // HANDLE CONTEXT
  const { filters, setFilters, disabledFilters } = useContext(FilterContext);

  // Overrides from Context
  useEffect(() => {
    settax(filters.tax ?? []);
    setdueDate(filters.dueDate ?? []);
    setregion(filters.region ?? []);
    setcountry(filters.country ?? []);
    setobligationgroup(filters.obligationgroup ?? []);
    setstatus(filters.status ?? []);
    setSelectedRegions(filters.selectedRegions ?? []);
    setSelectedCountries(filters.selectedCountries ?? []);
    // Reset Date Picker if there is no due date range selected.
    if (!filters.dueDate) setDateErase(1);
  }, [filters]);

  useEffect(() => {
    if (!userToken) return;

    let _groups;
    let _regionData;

    setLoadingObligationsData(true);
    setLoadingRegions(true);

    fetch(process.env.REACT_APP_BACKEND_URL + `/obligationentities/getdistinctgroupnames`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        _groups = data?.group_names.map((g) => g['group_name']);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setObligationsData(_groups);
        setLoadingObligationsData(false);
      });

    fetch(process.env.REACT_APP_BACKEND_URL + `/filterconfig/regions-and-countries`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        return res.json();
      })
      .then((data) => {
        _regionData = data;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setData(_regionData);
        setLoadingRegions(false);
      });
  }, [userToken]);

  const getCountryName = (countryId) => {
    for (const regionId of Object.keys(RegionCountryData)) {
      const country = RegionCountryData[regionId].find((country) => country.desc === countryId);
      if (country) {
        return country.desc;
      }
    }
    return '';
  };

  // CHANGE HANDLERS
  // Generic
  const handleChange = (event, variable) => {
    const {
      target: { value },
    } = event;
    const varDictionary = [
      { var: 'tax', setter: settax },
      { var: 'dueDate', setter: setdueDate },
      { var: 'region', setter: setregion },
      { var: 'country', setter: setcountry },
      { var: 'obligationgroup', setter: setobligationgroup },
      { var: 'status', setter: setstatus },
      { var: 'selectedRegions', setter: setDateErase },
      { var: 'selectedCountries', setter: setSelectedCountries },
    ];
    const changedVar = varDictionary.find((v) => v.var === variable);
    const actualVal = typeof value === 'string' ? value.split(',') : value;

    // Change State
    changedVar.setter(actualVal);
    // Change Context
    let newFilters = { ...filters };
    newFilters[changedVar.var] = actualVal;
    setFilters(newFilters);
  };

  const dueDatesHandler = (e) => {
    setdueDate(e);
    setFilters({ ...filters, dueDate: e });
  };

  const handleRegionChange = (event) => {
    const selectedRegions = event.target.value;
    setSelectedRegions(selectedRegions);

    // Filter out countries that do not belong to the selected regions
    const selectedCountries1 = selectedCountries.filter((countryId) => {
      const countryRegion = Object.keys(RegionCountryData).find((regionId) =>
        RegionCountryData[regionId].some((country) => country.id === countryId)
      );
      return selectedRegions.includes(countryRegion);
    });
    setSelectedCountries(selectedCountries1);
    setFilters({
      ...filters,
      selectedRegions: selectedRegions,
      selectedCountries: selectedCountries1,
    });
  };

  const handleCountryChange = (event) => {
    const selectedCountries = event.target.value;
    setSelectedCountries(selectedCountries);
    setFilters({ ...filters, selectedCountries: selectedCountries });
  };

  const handleRemoveRegion = (regionId) => {
    setSelectedRegions((prevSelected) => prevSelected.filter((region) => region !== regionId));
    setSelectedCountries((prevSelected) =>
      prevSelected.filter((country) => !RegionCountryData[regionId].find((c) => c.id === country))
    );
    // Same as before, but operating over the filters object.
    setFilters((prev) => ({
      ...prev,
      selectedRegions: prev.selectedRegions.filter((region) => region !== regionId),
      selectedCountries: prev.selectedCountries.filter(
        (country) => !RegionCountryData[regionId].find((c) => c.id === country)
      ),
    }));
  };

  const handleRemoveCountry = (countryId) => {
    setSelectedCountries((prevSelected) => prevSelected.filter((country) => country !== countryId));
    // Same as before, but operating over the filters object.
    setFilters((prev) => ({
      ...filters,
      selectedCountries: prev.selectedCountries.filter((country) => country !== countryId),
    }));
  };

  const handleDelete = (res, lbl) => {
    if (lbl === 'tax') {
      let result = tax.filter((data) => data !== res);
      settax(result);
      setFilters({ ...filters, tax: result });
    }

    if (lbl === 'region') {
      let result = region.filter((data) => data !== res);
      setregion(result);
      setFilters({ ...filters, region: result });
    }

    if (lbl === 'country') {
      let result = country.filter((data) => data !== res);
      setcountry(result);
      setFilters({ ...filters, country: result });
    }

    if (lbl === 'group') {
      let result = obligationgroup.filter((data) => data !== res);
      setobligationgroup(result);
      setFilters({ ...filters, obligationgroup: result });
    }

    if (lbl === 'status') {
      let result = status.filter((data) => data !== res);
      setstatus(result);
      setFilters({ ...filters, status: result });
    }
  };

  const selectedFilterChipSx = {
    mt: '1rem',
    mr: '1rem',
    backgroundColor: theme.palette.secondaryColors[100],
    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
    //color: theme.palette.neutralColors.dark[300], // TODO: Revise this.
    padding: '0.5rem 0rem',
    borderRadius: '6px',
    height: '2.25rem',
    fontSize: '0.875rem',
    textAlign: 'left',
  };

  return (
    <Box sx={{ height: 'auto', backgroundColor: theme.palette.baseColors.white }}>
      <Container sx={{ height: '3.25rem' }}>
        <SingleInputDateRangePicker
          dueDatesHandler={dueDatesHandler}
          erase={dateEraseVal}
          eraseval={setDateErase}
          disabled={disabledFilters.includes('Due Date')}
        />
        <RegionCountry
          data={RegionCountryData}
          loading={loadingRegions}
          getCountryName={getCountryName}
          getRegionName={getRegionName}
          selectedRegions={selectedRegions}
          selectedCountries={selectedCountries}
          handleRegionChange={handleRegionChange}
          handleCountryChange={handleCountryChange}
          handleRemoveRegion={handleRemoveRegion}
          handleRemoveCountry={handleRemoveCountry}
          disabled={disabledFilters.includes('Region') || disabledFilters.includes('Country')}
        />
        <Customselect
          options={optionsTaxes?.sort()}
          onChange={(e) => handleChange(e, 'tax')}
          label={'Tax Type'}
          value={tax}
          setstate={settax}
          disabled={disabledFilters.includes('Tax')}
        />
        <Customselect
          options={obligationsData?.sort()}
          onChange={(e) => handleChange(e, 'obligationgroup')}
          label={'Group'}
          loading={loadingObligationsData}
          value={obligationgroup}
          setstate={setobligationgroup}
          disabled={disabledFilters.includes('Group')}
        />
        <Customselect
          options={optionsStatus?.sort()}
          onChange={(e) => handleChange(e, 'status')}
          label={'Status'}
          value={status}
          setstate={setstatus}
          disabled={disabledFilters.includes('Status')}
        />
      </Container>
      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        {dueDate?.length > 1 && (
          <Box sx={{ display: 'flex' }}>
            {dueDate && dueDate[0] === dueDate[1] && (
              <Chip
                sx={selectedFilterChipSx}
                key={dueDate[0]}
                label={`${formatDate(dueDate[0], '-')}`}
                onDelete={() => {
                  setDateErase(1);
                  setdueDate([]);
                  setFilters({ ...filters, dueDate: [] });
                }}
                deleteIcon={
                  <Cross1Icon
                    sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }}
                  />
                }
                disabled={disabledFilters.includes('Due Date')}
              />
            )}
            {dueDate && dueDate[0] !== dueDate[1] && (
              <Chip
                sx={selectedFilterChipSx}
                key={dueDate[0]}
                label={`${formatDate(dueDate[0], '-')} ~ ${formatDate(dueDate[1], '-')}`}
                onDelete={() => {
                  setDateErase(1);
                  setdueDate([]);
                  setFilters({ ...filters, dueDate: [] });
                }}
                deleteIcon={
                  <Cross1Icon
                    sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }}
                  />
                }
                disabled={disabledFilters.includes('Due Date')}
              />
            )}
          </Box>
        )}

        <Box sx={{ display: 'flex' }}>
          {tax.map((res, id) => {
            return (
              <Chip
                sx={selectedFilterChipSx}
                key={id}
                label={`${res}`}
                onDelete={() => handleDelete(res, 'tax')}
                deleteIcon={
                  <Cross1Icon
                    sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }}
                  />
                }
                disabled={disabledFilters.includes('Tax')}
              />
            );
          })}
        </Box>

        <Box sx={{ display: 'flex' }}>
          {selectedRegions.map((regionId) => {
            return (
              <Chip
                sx={selectedFilterChipSx}
                key={regionId}
                label={regionId}
                onDelete={() => handleRemoveRegion(regionId)}
                deleteIcon={
                  <Cross1Icon
                    sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }}
                  />
                }
                disabled={disabledFilters.includes('Region')}
              />
            );
          })}
        </Box>

        <Box sx={{ display: 'flex' }}>
          {selectedCountries.map((countryId) => {
            return (
              <Chip
                sx={selectedFilterChipSx}
                key={countryId}
                label={`${getCountryName(countryId)}`}
                onDelete={() => handleRemoveCountry(countryId)}
                deleteIcon={
                  <Cross1Icon
                    sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }}
                  />
                }
                disabled={disabledFilters.includes('Country')}
              />
            );
          })}
        </Box>

        <Box sx={{ display: 'flex' }}>
          {obligationgroup.map((res, id) => {
            return (
              <Chip
                sx={selectedFilterChipSx}
                key={id}
                label={`${res}`}
                onDelete={() => handleDelete(res, 'group')}
                deleteIcon={
                  <Cross1Icon
                    sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }}
                  />
                }
                disabled={disabledFilters.includes('Group')}
              />
            );
          })}
        </Box>

        <Box sx={{ display: 'flex' }}>
          {status.map((res, id) => {
            return (
              <Chip
                sx={selectedFilterChipSx}
                key={id}
                label={`${res}`}
                onDelete={() => handleDelete(res, 'status')}
                deleteIcon={
                  <Cross1Icon
                    sx={{ marginLeft: '0.5rem', color: theme.palette.baseColors.black }}
                  />
                }
                disabled={disabledFilters.includes('Status')}
              />
            );
          })}

          {(tax.length > 0 ||
            selectedCountries?.length > 0 ||
            selectedRegions?.length > 0 ||
            dueDate?.length > 0 ||
            obligationgroup?.length > 0 ||
            status.length > 0) && (
              <Typography
                sx={{
                  textDecoration: 'underline',
                  mt: '1.5rem',
                }}>
                <BtnLink
                  size='small'
                  btnTxt='Clear all'
                  styling={{
                    textDecoration: 'underline',
                    fontSize: '1rem',
                    //color: theme.palette.primaryColors.red[100]
                  }}
                  onClick={() => {
                    // TODO: Refactor this in the future.
                    let newFilters = { ...filters };
                    if (!disabledFilters.includes('Tax')) {
                      settax([]);
                      newFilters['tax'] = [];
                    }
                    if (!disabledFilters.includes('Region') && !disabledFilters.includes('Country')) {
                      setSelectedRegions([]);
                      setSelectedCountries([]);
                      newFilters['selectedRegions'] = [];
                      newFilters['selectedCountries'] = [];
                    }
                    if (!disabledFilters.includes('Group')) {
                      setobligationgroup([]);
                      newFilters['obligationgroup'] = [];
                    }
                    if (!disabledFilters.includes('Due Date')) {
                      setdueDate([]);
                      setDateErase(1);
                      newFilters['dueDate'] = [];
                    }
                    if (!disabledFilters.includes('Status')) {
                      setstatus([]);
                      newFilters['status'] = [];
                    }
                    // Reset Context
                    setFilters(newFilters);
                  }}
                  disabled={
                    // Check if at least a non-disabled filter is selected. If so, it should NOT be disabled.
                    // Idk man. You refactor it.
                    !(
                      (!disabledFilters.includes('Tax') && tax.length !== 0) ||
                      (!disabledFilters.includes('Region') && selectedRegions.length !== 0) ||
                      (!disabledFilters.includes('Country') && selectedCountries.length !== 0) ||
                      (!disabledFilters.includes('Group') && obligationgroup.length !== 0) ||
                      (!disabledFilters.includes('Due Date') && dueDate.length !== 0) ||
                      (!disabledFilters.includes('Status') && status.length !== 0)
                    )
                  }
                />
              </Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
};
