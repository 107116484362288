import { SvgIcon } from '@mui/material';

export const CheckIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M3.75 9L7.5 12.75L15 5.25'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};
