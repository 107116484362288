import { Stack, Typography, useTheme, Box } from '@mui/material';
import { styled } from 'styled-components';
import { CheckCircleIcon } from '../../../components/common/CustomIcons/CheckCircleIcon';
import { CircleIcon } from '../../../components/common/CustomIcons/Circle';

const StatusText = styled(Typography)`
display: flex,
alignItems: center,
fontSize: 1rem,
fontWeight:500,
marginLeft: 5px,
`;

const ItemStatusDisplay = ({ status, theme }) =>
  status === 'COMPLETED' ? (
    <StatusText sx={{ color: 'green' }}>
      <CheckCircleIcon style={{ height: '1.2rem' }} /> Completed
    </StatusText>
  ) : status === 'IN_PROGRESS' ? (
    <StatusText sx={{ color: theme.palette.baseColors.grey }}>
      <CircleIcon style={{ height: '1rem', color: theme.palette.primaryColors[200] }} /> In Progress
    </StatusText>
  ) : (
    <StatusText sx={{ color: theme.palette.secondaryColors[400] }}>
      <CircleIcon style={{ height: '1rem', color: theme.palette.secondaryColors[400] }} /> Not
      Started
    </StatusText>
  );

export const StatusCard = ({ children, title, text, status, isCurrent, completionDate }) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        borderRadius: '0.5rem',
        backgroundColor: theme.palette.baseColors.white,
        padding: '1.5rem',
        color: theme.palette.secondaryColors[600],
        alignItems: 'flex-start',
        height: '100%',
        border: isCurrent
          ? '1px solid #D04A02'
          : `1px solid ${theme.palette.neutralColors.black[200]}`,
        background: isCurrent ? '#D04A021A' : '',
      }}>
      <Stack
        direction='row'
        alignItems='center'
        spacing='.75rem'
        sx={{
          marginBottom: '1rem',
        }}>
        <ItemStatusDisplay
          status={status}
          theme={theme}
        />
      </Stack>
      <Typography
        gutterBottom
        component='h4'
        color={
          status === 'NOT_STARTED'
            ? theme.palette.secondaryColors[400]
            : theme.palette.baseColors.grey
        }
        sx={{ fontWeight: 700, fontSize: '1.125rem' }}>
        {title}
      </Typography>
      <Typography
        variant='body2'
        color={theme.palette.random[1000]}
        sx={{
          marginBottom: '1.5rem',
          height: 1,
        }}>
        {completionDate}
      </Typography>
    </Stack>
  );
};
