import { SvgIcon } from '@mui/material';

export const RedWarningIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path opacity="0.3" d="M2.97998 13.1665H13.02L7.99998 4.49316L2.97998 13.1665ZM8.66665 12.4998H7.33331V11.1665H8.66665V12.4998ZM8.66665 9.83316H7.33331V7.1665H8.66665V9.83316Z" fill="#E6000E" />
                <path d="M0.666626 14.5002H15.3333L7.99996 1.8335L0.666626 14.5002ZM2.97996 13.1668L7.99996 4.4935L13.02 13.1668H2.97996ZM7.33329 11.1668H8.66663V12.5002H7.33329V11.1668ZM7.33329 7.16683H8.66663V9.8335H7.33329V7.16683Z" fill="#E6000E" />
            </svg>
        </SvgIcon>
    )
};
