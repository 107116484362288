import * as mui from '@mui/material';

export const ColorCard = ({ children, style }) => {
  return (
    <mui.Stack
      sx={{
        borderRadius: '0.5rem',
        gap: '.75rem',
        padding: '1rem',
        height: '100px',
        width: '444px',
        ...style,
      }}>
      {children}
    </mui.Stack>
  );
};

export const VarianceColorTheme = (type) => {
  const theme = mui.useTheme();
  let bgcolor =
    type === 'Warning'
      ? theme.palette.functional.partialAccent
      : theme.palette.secondaryColors[100];

  let borderColor =
    type === 'Warning' ? theme.palette.functional.partial : theme.palette.neutralColors.black[200];
  return {
    width: '100%',
    minWidth: '10rem',
    height: 84,
    borderRadius: '0.25rem',
    border: `1px solid ${borderColor}`,
    padding: '0.75rem 1.5rem',
    gap: '8px',
    bgcolor: bgcolor,
  };
};
