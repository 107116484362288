import { Box, Divider, Link, Stack, Typography, styled, useTheme } from '@mui/material';
import { FOOTER_LINKS_OPTIONS, PWC_STRUCTURE_LINK } from './constants';
import { useLocation } from 'react-router-dom';

const StyledFooter = styled(Box)(({ theme }) => ({
  padding: '2.5rem',
  borderTop: `1px solid ${theme.palette.neutralColors.black[100]}`,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  backgroundColor: theme.palette.baseColors.white,
  flexShrink: 0,
  letterSpacing: '-0.0125rem'
}))

export const Footer = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const showFooter = pathname !== "/authorize" && pathname !== "/login" && pathname !== "/policy-acceptance";
  return (
    showFooter && <StyledFooter component='footer'>

      <Typography variant='body2' color={theme.palette.baseColors.grey}>
        © 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see&nbsp;
        <Link
          sx={{
            textDecorationColor: theme.palette.primaryColors[200],
            color: theme.palette.primaryColors[200]
          }}
          href={PWC_STRUCTURE_LINK}
          target='_blank'
        >
          www.pwc.com/structure
        </Link>
        &nbsp;for further details.
      </Typography>

      <Stack
        direction='row'
        alignItems='center'
        spacing='0.5rem'
        divider={
          <Divider
            orientation='vertical'
            color={theme.palette.baseColors.grey}
            sx={{ borderRightWidth: 1.5, height: '0.875rem' }}
          />
        }
      >
        {FOOTER_LINKS_OPTIONS?.map((obj, index) =>
          <Link key={obj.linkName} href={obj.linkContent} target='_blank' variant='body2'
            sx={{
              textDecorationColor: theme.palette.primaryColors[200],
              color: theme.palette.primaryColors[200]
            }}>
            {obj.linkName}
          </Link>
        )}
      </Stack>

    </StyledFooter>
  );
}