import {
  useTheme,
  Stack,
  Typography,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  IconButton,
  Skeleton,
} from '@mui/material';
import { SortAscIcon } from '../../../components/common/CustomIcons/SortAscIcon';
import { SortDescIcon } from '../../../components/common/CustomIcons/SortDescIcon';
import { SortIcon } from '../../../components/common/CustomIcons/SortIcon';
import { Toast } from '../Toast';
import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { WhiteCard } from '../WhiteCard';
import { BtnLink } from '../../../components/common/Buttons/BtnLink';
import { useNavigate, Link } from 'react-router-dom';
import { CheckCircleOutlinedIcon } from '../../../components/common/CustomIcons/CheckCircleOutlinedIcon';
import { AccountsReceivableIcon } from '../../../components/common/CustomIcons/Data/AccountsReceivableIcon';
import { ListNotes } from '../Notes/ListNotes';
import { AccordionPanel } from '../../../components/common/AccordianPanel/AccordianPanel';
import { useRef } from 'react';
import { AccountsPayableIcon } from '../../../components/common/CustomIcons/Data/AccountsPayableIcon';
import { TrialBalanceIcon } from '../../../components/common/CustomIcons/Data/TrialBalanceIcon';
import { GeneralLedgerIcon } from '../../../components/common/CustomIcons/Data/GeneralLedgerIcon';
import { useObligation, useUser } from '../../../context';
import { DUMMY_DATATAB_DATA } from '../dummyData';
import {
  conditionalEntityCodeQuery,
  currencyFormatterWithoutSymbol,
  formatDate,
  reverseDate,
} from '../../../utils';
import { StyledLinearProgress } from '../../../components/common/Progress/StyledLinearProgress';
import { DownloadLink } from '../../../components/common/Buttons/Utility/DownloadLink';

// STYLING
const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.baseColors.grey,
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0 1rem',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));
const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.75rem 1rem',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  height: '3rem',
}));

export const Data = () => {
  // BACKEND DATA
  const { selectedObgDetails } = useObligation();
  const obligation = selectedObgDetails;
  const [summaryStats, setSummaryStats] = useState({});
  const [errorLog, setErrorLog] = useState(null);
  const [accountsReceivable, setAccountsReceivable] = useState({});
  const [accountsPayable, setAccountsPayable] = useState({});
  const [generalLedger, setGeneralLedger] = useState({});
  const [trialBalance, setTrialBalance] = useState({});
  const [files, setFiles] = useState([]);

  // UTILITY
  const [loading, setLoading] = useState(true);
  const [filesLoading, setFilesLoading] = useState(true);
  const [toast, setToast] = useState({ type: '', message: '' });
  const theme = useTheme();
  const navigate = useNavigate();
  const { userToken } = useUser();
  const filesAccordionBox = useRef(null);
  const filesAccordion = useRef(null);

  // FILES TABLE
  const COLUMNS = [
    { label: 'File Name', property: 'Filename', cellWidth: -1 },
    { label: 'File Type', property: 'Filetype', cellWidth: -1 },
    { label: 'Entity', property: 'entity', cellWidth: -1 },
    { label: 'Date Uploaded', property: 'lastaccessfile', cellWidth: -1 },
    { label: 'Uploaded By', property: 'Uploaded_by', cellWidth: -1 },
  ];
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortingOrder, setSortingOrder] = useState('asc');

  const getSortedDataHandler = (col_name) => {
    if (col_name === sortedColumn) {
      setSortingOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortedColumn(col_name);
      // Due Date has the special case of starting to sort in Descending Order FIRST.
      setSortingOrder(col_name === 'due_date' ? 'desc' : 'asc');
    }
  };

  // Get data from API.
  // #region Get summary data.
  useEffect(() => {
    if (!userToken || !obligation?.member || !obligation?.obligation_id) return;

    setLoading(true);
    let _summaryStats, _accountsReceivable, _accountsPayable, _generalLedger, _trialBalance;

    const summaryURL =
      process.env.REACT_APP_BACKEND_URL +
      `/datasummary/summaryofdata?obligation_id=${obligation.obligation_id
      }${conditionalEntityCodeQuery(obligation)}`;
    const errorLogURL =
      process.env.REACT_APP_BACKEND_URL +
      `/datasummary/download?obligation_id=${obligation.obligation_id}${conditionalEntityCodeQuery(
        obligation
      )}&filename=Total_import_errors.xls`;
    const cardsURL =
      process.env.REACT_APP_BACKEND_URL +
      `/datasummary/detaildata?obligation_id=${obligation.obligation_id
      }${conditionalEntityCodeQuery(obligation)}`;

    // Summary card.
    let summaryPromise = fetch(summaryURL, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        _summaryStats = data;
      })
      .catch((err) => {
        _summaryStats = DUMMY_DATATAB_DATA.summary;
      })
      .finally(() => {
        setSummaryStats(_summaryStats);
      });

    // Error log.
    let errorLogPromise = fetch(errorLogURL, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        const blob = res.blob();
        if (!res.ok || !blob)
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        return blob;
      })
      .then((blob) => window.URL.createObjectURL(blob))
      .then((urlObject) => setErrorLog(urlObject))
      .catch((err) => {
        console.error(err);
      });

    // Stats card.
    let cardsPromise = fetch(cardsURL, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        _accountsPayable = data?.detailed_summary?.accounts_payable;
        _accountsReceivable = data?.detailed_summary?.accounts_receivable;
        _generalLedger = data?.detailed_summary?.general_ledger;
        _trialBalance = data?.detailed_summary?.trial_balance;
      })
      .catch((err) => {
        _accountsPayable = DUMMY_DATATAB_DATA.detailTab.accounts_payable;
        _accountsReceivable = DUMMY_DATATAB_DATA.detailTab.accounts_receivable;
        _generalLedger = DUMMY_DATATAB_DATA.detailTab.general_ledger;
        _trialBalance = DUMMY_DATATAB_DATA.detailTab.trial_balance;
      })
      .finally(() => {
        setAccountsReceivable(_accountsReceivable);
        setAccountsPayable(_accountsPayable);
        setGeneralLedger(_generalLedger);
        setTrialBalance(_trialBalance);
      });

    Promise.all([summaryPromise, errorLogPromise, cardsPromise])
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Data could not be fetched.',
        });
      })
      .finally(() => {
        setLoading(false);
      });

    // Clean up the URL object created for the error log.
    return () => {
      files.forEach((file) => window.URL.revokeObjectURL(file.downloadURL));
      if (errorLog) window.URL.revokeObjectURL(errorLog);
    };
  }, [userToken, obligation]);
  // #endregion

  // #region Get files.
  useEffect(() => {
    if (!userToken || !obligation?.member || !obligation?.obligation_id) return;

    setFilesLoading(true);

    let _files;
    const filesURL =
      process.env.REACT_APP_BACKEND_URL +
      `/datasummary/displaylastdates?obligation_id=${obligation.obligation_id
      }${conditionalEntityCodeQuery(
        obligation
      )}&sort_column=${sortedColumn}&sort_dir=${sortingOrder}`;

    // Files accordion.
    fetch(filesURL, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        _files = [...data];
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Files data could not be fetched.',
        });
      })
      .finally(async () => {
        if (!_files) {
          setFilesLoading(false);
          return;
        }
        const filePromises = _files.map(async (file) => {
          if (!file.Entitycode) return;
          const requestURL =
            process.env.REACT_APP_BACKEND_URL +
            `/datasummary/download?obligation_id=${obligation.obligation_id}&entityname=${file.Entitycode}&filename=${file.Filename}&lastaccessfile=${file.lastaccessfile}`;
          return {
            ...file,
            downloadURL: await fetch(requestURL, {
              method: 'GET',
              headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${userToken}`,
              },
              credentials: 'include',
            })
              .then((res) => {
                const blob = res.blob();
                if (!res.ok || !blob)
                  throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
                return blob;
              })
              .then((blob) => window.URL.createObjectURL(blob))
              .catch((err) => {
                console.error(err);
                return undefined;
              }),
          };
        });
        _files = await Promise.all(filePromises);
        setFiles(_files);
        setFilesLoading(false);
      });

    // Clean up all the URL objects created for the uploaded files.
    return () => {
      files.forEach((file) => window.URL.revokeObjectURL(file.downloadURL));
    };
  }, [userToken, obligation, sortedColumn, sortingOrder]);
  // #endregion

  // #region Cards.
  const summaryCards = [
    {
      title: 'Total Transactions Loaded',
      data: currencyFormatterWithoutSymbol(summaryStats?.Total_transaction_loaded),
      action: () => navigate('transactions'),
    },
    {
      title: 'Files Uploaded',
      data: `${currencyFormatterWithoutSymbol(summaryStats?.files_uploaded)}/4`,
      action: () => {
        filesAccordionBox.current?.scrollIntoView({ behavior: 'smooth' });
        filesAccordion.current?.open();
      },
    },
    {
      title: 'Total Import Errors',
      data: `${currencyFormatterWithoutSymbol(summaryStats?.Total_import_errors)}`,
    },
  ];
  const transactionCards = [
    {
      icon: <AccountsReceivableIcon />,
      title: 'Accounts Receivable',
      wasUploaded: accountsReceivable?.uploaded,
      slices: {
        'Import Date':
          accountsReceivable?.import_date === 'NA'
            ? 'NA'
            : reverseDate(formatDate(accountsReceivable?.import_date, '-'), ' '),
        'Import Errors': currencyFormatterWithoutSymbol(accountsReceivable?.import_errors),
        'Not Reportable': currencyFormatterWithoutSymbol(accountsReceivable?.not_reportable),
        Transactions: currencyFormatterWithoutSymbol(accountsReceivable?.total_transactions),
        'New Transactions': currencyFormatterWithoutSymbol(accountsReceivable?.new_transactions),
      },
    },
    {
      icon: <AccountsPayableIcon />,
      title: 'Accounts Payable',
      wasUploaded: accountsPayable?.uploaded,
      slices: {
        'Import Date':
          accountsPayable?.import_date === 'NA'
            ? 'NA'
            : reverseDate(formatDate(accountsPayable?.import_date, '-'), ' '),
        'Import Errors': currencyFormatterWithoutSymbol(accountsPayable?.import_errors),
        'Not Reportable': currencyFormatterWithoutSymbol(accountsPayable?.not_reportable),
        Transactions: currencyFormatterWithoutSymbol(accountsPayable?.total_transactions),
        'New Transactions': currencyFormatterWithoutSymbol(accountsPayable?.new_transactions),
      },
    },
  ];
  const accountingCards = [
    {
      icon: <TrialBalanceIcon />,
      title: 'Trial Balance',
      wasUploaded: trialBalance?.uploaded,
      slices: {
        'Import Date':
          trialBalance?.import_date === 'NA'
            ? 'NA'
            : reverseDate(formatDate(trialBalance?.import_date, '-'), ' '),
        'Import Errors': currencyFormatterWithoutSymbol(trialBalance?.import_errors),
        'New Import': currencyFormatterWithoutSymbol(trialBalance?.new_transactions),
      },
    },
    {
      icon: <GeneralLedgerIcon />,
      title: 'General Ledger',
      wasUploaded: generalLedger?.uploaded,
      slices: {
        'Import Date':
          generalLedger?.import_date === 'NA'
            ? 'NA'
            : reverseDate(formatDate(generalLedger?.import_date, '-'), ' '),
        'Import Errors': currencyFormatterWithoutSymbol(generalLedger?.import_errors),
        'New Import': currencyFormatterWithoutSymbol(generalLedger?.new_transactions),
      },
    },
  ];
  // #endregion

  return (
    <>
      <Stack
        gap='1.5rem'
        flexGrow={1}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Skeleton
              variant='rounded'
              width='95%'
              height={225}
              sx={{ margin: 3 }}
            />
            <Skeleton
              variant='rounded'
              width='95%'
              height={225}
              sx={{ margin: 3 }}
            />
            <Skeleton
              variant='rounded'
              width='95%'
              height={225}
              sx={{ margin: 3 }}
            />
          </Box>
        ) : (
          <>
            <WhiteCard>
              <Typography
                sx={{
                  color: theme.palette.baseColors.grey,
                  fontWeight: 700,
                  fontSize: '1.125rem',
                }}>
                Summary of Data
              </Typography>
              <Stack
                direction='row'
                gap='1.5rem'>
                {summaryCards.map((card) => (
                  <WhiteCard
                    key={card.title}
                    style={{
                      alignItems: 'flex-start',
                      padding: '1rem',
                      flex: '1 1 0px',
                    }}>
                    <Typography
                      sx={{
                        color: theme.palette.baseColors.grey,
                        fontWeight: 500,
                        fontSize: '1rem',
                        mb: '-.5rem',
                      }}>
                      {card.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.baseColors.grey,
                        fontWeight: 700,
                        fontSize: '2rem',
                        mb: '-1rem',
                      }}>
                      {card.data}
                    </Typography>
                    {card.title === 'Total Import Errors' ? (
                      <DownloadLink
                        filename='Total Import Errors'
                        urlObject={errorLog}
                        toastSetter={setToast}
                        sx={{
                          fontSize: '1rem',
                          color: theme.palette.primaryColors[200],
                        }}>
                        View
                      </DownloadLink>
                    ) : (
                      <BtnLink
                        btnTxt='View'
                        onClick={card.action}
                      />
                    )}
                  </WhiteCard>
                ))}
              </Stack>
            </WhiteCard>

            <Stack
              direction='row'
              gap='1.5rem'
              flexGrow={1}>
              <WhiteCard style={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    color: theme.palette.baseColors.grey,
                    fontWeight: 700,
                    fontSize: '1.125rem',
                  }}>
                  Transaction Data
                </Typography>
                {transactionCards.map((card) => (
                  <WhiteCard
                    key={card.title}
                    style={{
                      gap: '.75rem',
                      padding: '1.5rem',
                    }}>
                    <Stack
                      direction='row'
                      justifyContent='space-around'>
                      <Stack
                        direction='row'
                        flexGrow={1}
                        gap='1rem'>
                        {card.icon}
                        <Typography
                          sx={{
                            color: theme.palette.baseColors.grey,
                            fontWeight: 500,
                            fontSize: '1rem',
                          }}>
                          {card.title}
                        </Typography>
                      </Stack>
                      {card.wasUploaded && (
                        <Stack
                          direction='row'
                          gap='.5rem'>
                          <CheckCircleOutlinedIcon
                            style={{ color: theme.palette.functional.success }}
                          />
                          <Typography
                            sx={{
                              color: theme.palette.baseColors.grey,
                              fontWeight: 700,
                              fontSize: '1rem',
                            }}>
                            Uploaded
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Divider
                      orientation='horizontal'
                      flexItem
                    />
                    <Stack
                      direction='row'
                      gap='1rem'
                      mt='.75rem'>
                      {Object.entries(card.slices).map((slice, idx) => {
                        const sliceTitle = slice[0];
                        const sliceData = slice[1];
                        return (
                          <Fragment key={idx}>
                            <Stack
                              sx={{
                                pr: '0.75rem',
                              }}>
                              <Typography
                                sx={{
                                  color: theme.palette.random[1400],
                                  fontWeight: 400,
                                  fontSize: '0.875rem',
                                }}>
                                {sliceTitle}
                              </Typography>
                              <Typography
                                sx={{
                                  color: theme.palette.baseColors.grey,
                                  fontWeight: 400,
                                  fontSize: '1rem',
                                }}>
                                {sliceData}
                              </Typography>
                            </Stack>
                            {idx !== Object.entries(card.slices).length - 1 && (
                              <Divider orientation='vertical' />
                            )}
                          </Fragment>
                        );
                      })}
                    </Stack>
                  </WhiteCard>
                ))}
              </WhiteCard>

              <WhiteCard style={{ flexGrow: 1 }}>
                <Typography
                  sx={{
                    color: theme.palette.baseColors.grey,
                    fontWeight: 700,
                    fontSize: '1.125rem',
                  }}>
                  Accounting
                </Typography>
                {accountingCards.map((card) => (
                  <WhiteCard
                    key={card.title}
                    style={{
                      gap: '.75rem',
                      padding: '1.5rem',
                    }}>
                    <Stack
                      direction='row'
                      justifyContent='space-around'>
                      <Stack
                        direction='row'
                        flexGrow={1}
                        gap='1rem'>
                        {card.icon}
                        <Typography
                          sx={{
                            color: theme.palette.baseColors.grey,
                            fontWeight: 500,
                            fontSize: '1rem',
                          }}>
                          {card.title}
                        </Typography>
                      </Stack>
                      {card.wasUploaded && (
                        <Stack
                          direction='row'
                          gap='.5rem'>
                          <CheckCircleOutlinedIcon
                            style={{ color: theme.palette.functional.success }}
                          />
                          <Typography
                            sx={{
                              color: theme.palette.baseColors.grey,
                              fontWeight: 700,
                              fontSize: '1rem',
                            }}>
                            Uploaded
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Divider
                      orientation='horizontal'
                      flexItem
                    />
                    <Stack
                      direction='row'
                      gap='1rem'
                      mt='.75rem'>
                      {Object.entries(card.slices).map((slice, idx) => {
                        const sliceTitle = slice[0];
                        const sliceData = slice[1];
                        return (
                          <Fragment key={idx}>
                            <Stack
                              sx={{
                                pr: '0.75rem',
                              }}>
                              <Typography
                                sx={{
                                  color: theme.palette.random[1400],
                                  fontWeight: 400,
                                  fontSize: '0.875rem',
                                }}>
                                {sliceTitle}
                              </Typography>
                              <Typography
                                sx={{
                                  color: theme.palette.baseColors.grey,
                                  fontWeight: 400,
                                  fontSize: '1rem',
                                }}>
                                {sliceData}
                              </Typography>
                            </Stack>
                            {idx !== Object.entries(card.slices).length - 1 && (
                              <Divider orientation='vertical' />
                            )}
                          </Fragment>
                        );
                      })}
                    </Stack>
                  </WhiteCard>
                ))}
              </WhiteCard>
            </Stack>

            <Box ref={filesAccordionBox}>
              <AccordionPanel
                heading='Files Uploaded'
                amount={`${currencyFormatterWithoutSymbol(files?.length)} File${files?.length !== 1 ? 's' : ''
                  }`}
                ref={filesAccordion}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: 'none',
                    overflow: 'hidden',
                    pb: '.5rem',
                  }}>
                  <Box
                    sx={{
                      overflowX: 'scroll !important',
                    }}>
                    <Table
                      aria-label='files list'
                      sx={{
                        borderRadius: '0.5rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <TableHead
                        sx={{
                          backgroundColor: theme.palette.neutralColors.black[100],
                          height: '2.5rem',
                        }}>
                        <TableRow variant='body2'>
                          {/* Automatically generate the header cells. Width values calculated as % of 1296px */}
                          {COLUMNS.map((column) => (
                            <StyledTableHeadingCell
                              key={column.property}
                              style={
                                column.cellWidth !== -1 ? { maxWidth: column.cellWidth + '%' } : {}
                              }
                              onClick={() => {
                                getSortedDataHandler(column.property);
                              }}>
                              {column.label}
                              <IconButton disableRipple>
                                {sortedColumn === column.property ? (
                                  sortingOrder === 'asc' ? (
                                    <SortDescIcon />
                                  ) : (
                                    <SortAscIcon />
                                  )
                                ) : (
                                  <SortIcon />
                                )}
                              </IconButton>
                            </StyledTableHeadingCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filesLoading && (
                          <TableRow>
                            <TableCell
                              colSpan={COLUMNS.length}
                              sx={{ p: 0 }}>
                              <StyledLinearProgress />
                            </TableCell>
                          </TableRow>
                        )}
                        {files?.length > 0 ? (
                          files?.map((file) => {
                            // Generate obligation row.
                            return (
                              <TableRow
                                key={file?.Filename}
                                sx={{
                                  height: '3rem',
                                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                                }}>
                                <StyledTableBodyCell>
                                  <DownloadLink
                                    urlObject={file?.downloadURL}
                                    toastSetter={setToast}>
                                    {file?.Filename}
                                  </DownloadLink>
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>
                                  {file?.Filetype?.toUpperCase()}
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>{file?.Entity}</StyledTableBodyCell>
                                <StyledTableBodyCell>
                                  {reverseDate(formatDate(file?.Datauploaded, '-'), ' ')}
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>{file?.Uploaded_by}</StyledTableBodyCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow
                            sx={{
                              height: '3rem',
                              border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                            }}>
                            <TableCell
                              colSpan={COLUMNS.length}
                              sx={{ textAlign: 'center' }}>
                              {loading ? 'Fetching data...' : 'No data found'}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </TableContainer>
              </AccordionPanel>
            </Box>
          </>
        )}
        <ListNotes />
      </Stack>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
