import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as mui from '@mui/material';

import { WhiteCard } from '../../WhiteCard';
import { reconciliationBaseUrl, typographyStyle } from '../index';
import { DownArrowIcon } from '../../../../components/common/CustomIcons/DownArrowIcon';
import { SortIcon } from '../../../../components/common/CustomIcons/SortIcon';
import { getUnReconciledItemColumns } from '../Constants/ReconciliationSummaryConstants';
import { useReconciliation } from '../../../../context/Reconciliation/ReconciliationContext';
import { useObligation, useUser } from '../../../../context';
import { SortDescIcon } from '../../../../components/common/CustomIcons/SortDescIcon';
import { SortAscIcon } from '../../../../components/common/CustomIcons/SortAscIcon';
import { Toast } from '../../Toast';
import { formatAmount } from '../Utility/helperFunctions';
import { AcceptVarianceModal } from './Modals/AcceptVarianceModal';
import { ManualAdjustmentModal } from './Modals/ManualAdjustmentModal';
import { CreateNewTransactionModal } from './Modals/CreateNewTransactionModal';
import { DropdownMenu } from '../../../../components/common/Dropdowns/DropdownMenu';
import { StyledLinearProgress } from '../../../../components/common/Progress/StyledLinearProgress';
import { LeftArrowIcon } from '../../../../components/common/CustomIcons/LeftArrowIcon';
import { getCurrentRelativePath } from '../../../../utils';

export const UnreconciledItemList = ({ summaryPage, summaryData }) => {
  const theme = mui.useTheme();
  const StyledTableHeadingCell = mui.styled(mui.TableCell)(({ theme }) => ({
    color: theme.palette.secondaryColors[600],
    fontWeight: 700,
    fontSize: '0.875rem',
    padding: '0 1rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  }));
  const StyledTableBodyCell = mui.styled(mui.TableCell)(({ theme }) => ({
    fontSize: '0.875rem',
    padding: '0 1rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.baseColors.grey,
    height: '3rem',
    border: 'none',
  }));

  const { id, member } = useParams();
  const { selectedObgDetails, isObligationLocked } = useObligation();
  const { userToken } = useUser();
  const { modalToast, modalNo, setModalNo, setModalToast } = useReconciliation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [preValuesLoader, setPreValuesLoader] = useState(false);
  const [toast, setToast] = useState({ type: '', message: '' });
  const [unReconciledItems, setUnReconciledItems] = useState([]);
  const [sortColumn, setSortColumn] = useState('entity');
  const [sortOrder, setSortOrder] = useState('asc');
  const [clicked, setClicked] = useState(-1);
  const [selectedItem, setSelectedItem] = useState({});
  const [prePopulateValues, setPrePopulateValues] = useState();

  const menueItem = [
    { name: 'Accept Variance', value: 1, type: 'accept' },
    { name: 'Create Manual Adjustment', value: 2, type: 'manual' },
    // { name: 'Create New Transaction', value: 3, type: 'transaction' },
  ];

  const sortHandler = (col_name) => {
    if (col_name === sortColumn) {
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(col_name);
      // Due Date has the break;
      setSortOrder('desc');
    }
  };

  const handleResolveStatus = (type, message, tab) => {
    if (tab) {
      setToast({ type: type, message: message });
    } else {
      setModalToast({
        type: type,
        message: message,
      });
    }
  };

  const getPrePopulateValues = (adjustmentType, item) => {
    if (userToken && item.Entity) {
      let url = '';
      url =
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/prepopulatevalues?obligation_id=${id}&entity=${item.Entity}&glaccount=${item.GLAccount}&adjustment_type=${adjustmentType}`;
      let _summaryDetails;
      setPreValuesLoader(true);
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          _summaryDetails = data;
        })
        .catch((err) => {
          setToast({
            type: 'failure',
            message: 'Pre population Data could not be fetched.',
          });
        })
        .finally(() => {
          setPreValuesLoader(false);
          setPrePopulateValues(_summaryDetails);
        });
    }
  };

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (userToken && selectedObgDetails?.obligation_id) {
      let url = '';
      setLoading(true);
      let baseUrl = '';
      switch (summaryPage) {
        case 1:
          baseUrl = '/reconciliation/trialbalance/unrecouncileditemlistall';
          break;
        case 2:
          baseUrl = '/reconciliation/vatgl/unrecouncileditemlistall';
          break;
        case 3:
          baseUrl = '/reconciliation/vatglwithouttaxcode/unrecouncileditemlistall';
          break;
        case 4:
          baseUrl = '/reconciliation/inputvat/unrecouncileditemlistall';
          break;
        default:
          break;
      }
      url = sortColumn
        ? process.env.REACT_APP_BACKEND_URL +
        `${baseUrl}?sort_column=${sortColumn}&sort_dir=${sortOrder}&${reconciliationBaseUrl(
          selectedObgDetails
        )}`
        : process.env.REACT_APP_BACKEND_URL +
        `${baseUrl}?${reconciliationBaseUrl(selectedObgDetails)}`;
      let _summaryDetails;
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          _summaryDetails = data;
        })
        .catch((err) => {
          setToast({
            type: 'failure',
            message: 'UnReconciled item list Data could not be fetched.',
          });
        })
        .finally(() => {
          setUnReconciledItems(_summaryDetails);
          setLoading(false);
        });
    }
  }, [selectedObgDetails, sortColumn, sortOrder, userToken, modalToast]);

  return (
    <>
      <WhiteCard>
        <mui.Accordion
          disableGutters
          sx={{
            border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            borderRadius: '0.5rem',
            flexDirection: 'row-reverse',
            boxShadow: 'none',
          }}>
          <mui.AccordionSummary
            disableGutters
            expandIcon={<LeftArrowIcon />}
            sx={{
              flexDirection: 'row-reverse',
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
              },
            }}
            aria-controls='panel1-content'
            id='panel1-header'>
            <mui.Stack
              direction={'row'}
              sx={{ justifyContent: 'space-between', width: '100% ' }}>
              <mui.Typography sx={typographyStyle('1.125rem', 500, theme.palette.random[300])}>
                Unreconciled Items{' '}
                {unReconciledItems?.transactionanalytics?.length >= 0
                  ? '(' + unReconciledItems?.transactionanalytics?.length + ')'
                  : ''}
              </mui.Typography>
              <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.secondaryColors[400])}>
                {formatAmount(summaryData?.Variance, summaryData?.Currency_Code)}
              </mui.Typography>
            </mui.Stack>
          </mui.AccordionSummary>
          <mui.AccordionDetails
            sx={{
              borderTop: `1px solid ${theme.palette.neutralColors.black[200]}`,
              margin: '0 1rem',
              px: 0,
            }}>
            <mui.TableContainer
              component={mui.Paper}
              sx={{
                boxShadow: 'none',
                overflowY: 'hidden',
              }}>
              <mui.Table
                aria-label='selected files'
                sx={{
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  borderRadius: '0.5rem',
                }}>
                <mui.TableHead
                  sx={{
                    backgroundColor: theme.palette.neutralColors.black[100],
                    height: '2.5rem',
                  }}>
                  <mui.TableRow variant='body2'>
                    {getUnReconciledItemColumns(summaryPage, selectedObgDetails?.country_code).map(
                      (column) =>
                        column.sorting ? (
                          <StyledTableHeadingCell
                            sx={typographyStyle(
                              '.875rem',
                              700,
                              theme.palette.secondaryColors[600],
                              {
                                textAlign: column.align,
                              }
                            )}
                            width={column.cellWidth}
                            key={column.label}
                            onClick={() => sortHandler(column.name)}>
                            {column.label}
                            <mui.IconButton disableRipple>
                              {sortColumn === column.name ? (
                                sortOrder === 'asc' ? (
                                  <SortDescIcon />
                                ) : (
                                  <SortAscIcon />
                                )
                              ) : (
                                <SortIcon />
                              )}
                            </mui.IconButton>
                          </StyledTableHeadingCell>
                        ) : (
                          <StyledTableHeadingCell
                            key={column.label}
                            sx={typographyStyle(
                              '.875rem',
                              700,
                              theme.palette.secondaryColors[600],
                              {
                                textAlign: column.align,
                              }
                            )}
                            width={column.cellWidth}>
                            {column.label}
                          </StyledTableHeadingCell>
                        )
                    )}
                  </mui.TableRow>
                </mui.TableHead>
                <mui.TableBody
                  sx={{
                    overflowX: 'scroll',
                  }}>
                  {loading && (
                    <mui.TableRow>
                      <mui.TableCell
                        colSpan={getUnReconciledItemColumns(summaryPage).length}
                        sx={{ p: 0 }}>
                        <StyledLinearProgress />
                      </mui.TableCell>
                    </mui.TableRow>
                  )}
                  {unReconciledItems?.transactionanalytics?.length > 0 ? (
                    unReconciledItems?.transactionanalytics?.map((unReconciledItem, index) => {
                      return (
                        <>
                          <mui.TableRow
                            key={unReconciledItem.Entity + index}
                            sx={{
                              height: '3rem',
                              borderBottom: `1px solid #f3f3f3f3`,
                            }}>
                            <StyledTableBodyCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                clicked === index ? setClicked(-1) : setClicked(index)
                              }>
                              <mui.Stack
                                sx={{ p: 0, m: 0, alignItems: 'end' }}
                                direction={'row'}>
                                {clicked === index ? <DownArrowIcon /> : <LeftArrowIcon />}
                                <mui.Typography
                                  sx={typographyStyle(
                                    '.875rem',
                                    700,
                                    theme.palette.baseColors.grey
                                  )}>
                                  {unReconciledItem.Entity}
                                </mui.Typography>
                              </mui.Stack>
                            </StyledTableBodyCell>
                            <StyledTableBodyCell></StyledTableBodyCell>
                            <StyledTableBodyCell
                              sx={typographyStyle('.875rem', 700, theme.palette.baseColors.grey, {
                                bgcolor: theme.palette.random[1500],
                                textAlign: 'right',
                              })}>
                              {formatAmount(
                                summaryPage === 1
                                  ? unReconciledItem.Trial_Balance
                                  : unReconciledItem?.Vat_GL,
                                unReconciledItems?.currency_code
                              )}
                            </StyledTableBodyCell>
                            <StyledTableBodyCell
                              sx={typographyStyle('.875rem', 700, theme.palette.baseColors.grey, {
                                bgcolor: theme.palette.random[1600],
                                textAlign: 'right',
                              })}>
                              {formatAmount(
                                unReconciledItem.Transaction,
                                unReconciledItems?.currency_code
                              )}
                            </StyledTableBodyCell>
                            <StyledTableBodyCell
                              sx={typographyStyle('.875rem', 700, theme.palette.functional.error, {
                                textAlign: 'right',
                              })}>
                              {formatAmount(
                                unReconciledItem.Difference,
                                unReconciledItems?.currency_code
                              )}
                            </StyledTableBodyCell>
                            <StyledTableBodyCell></StyledTableBodyCell>
                          </mui.TableRow>
                          {index === clicked &&
                            unReconciledItem?.Entity_List?.map((item, i) => {
                              return (
                                <mui.TableRow
                                  key={i + item.Entity}
                                  sx={{
                                    height: '3rem',
                                    borderBottom: `1px solid #f3f3f3f3`,
                                  }}>
                                  <StyledTableBodyCell></StyledTableBodyCell>
                                  <StyledTableBodyCell
                                    sx={typographyStyle(
                                      '0.875',
                                      400,
                                      theme.palette.baseColors.grey,
                                      {
                                        fontStyle: 'italic',
                                      }
                                    )}>
                                    {item.GLAccount}
                                  </StyledTableBodyCell>
                                  <StyledTableBodyCell
                                    sx={typographyStyle(
                                      '0.875',
                                      400,
                                      theme.palette.baseColors.grey,
                                      {
                                        fontStyle: 'italic',
                                        bgcolor: theme.palette.random[1500],
                                        textAlign: 'right',
                                      }
                                    )}>
                                    {formatAmount(
                                      summaryPage === 1
                                        ? item.Trial_Balance
                                        : summaryPage === 4
                                          ? item?.Input_VAT
                                          : item?.Vat_GL,
                                      unReconciledItems?.currency_code
                                    )}
                                  </StyledTableBodyCell>
                                  <StyledTableBodyCell
                                    sx={typographyStyle(
                                      '0.875',
                                      400,
                                      theme.palette.baseColors.grey,
                                      {
                                        fontStyle: 'italic',
                                        bgcolor: theme.palette.random[1600],
                                        textAlign: 'right',
                                      }
                                    )}>
                                    {formatAmount(
                                      item.Transaction,
                                      unReconciledItems?.currency_code
                                    )}
                                  </StyledTableBodyCell>
                                  <StyledTableBodyCell
                                    sx={typographyStyle(
                                      '0.875',
                                      400,
                                      theme.palette.functional.error,
                                      {
                                        fontStyle: 'italic',
                                        textAlign: 'right',
                                      }
                                    )}>
                                    {formatAmount(
                                      item.Difference,
                                      unReconciledItems?.currency_code
                                    )}
                                  </StyledTableBodyCell>
                                  <StyledTableBodyCell
                                    style={{ display: 'flex', alignItems: 'center' }}>
                                    <mui.Button
                                      disableRipple
                                      disableTouchRipple
                                      disableFocusRipple
                                      onClick={() => {
                                        navigate(
                                          `/obligations/${id}/transaction-dashboard/reconciliation?glaccountno=${item?.GLAccount?.split(' ')[0]
                                          }&entitycode=${unReconciledItem?.Entity?.split(' ')[0]
                                          }&differentiator=${member}&backAddress=${getCurrentRelativePath()}`
                                        );
                                      }}
                                      variant='outlined'
                                      color='inherit'
                                      sx={typographyStyle(
                                        '.875',
                                        500,
                                        theme.palette.functional.primaryLink,
                                        {
                                          width: 172,
                                          height: 32,
                                          color: theme.palette.primaryColors[200],
                                          mr: 2,
                                          textTransform: 'capitalize',
                                        }
                                      )}>
                                      View Transactions
                                    </mui.Button>
                                    <DropdownMenu
                                      buttonText='Resolve'
                                      options={menueItem.map((menue) => ({
                                        label: menue.name,
                                        callback: () => {
                                          setSelectedItem(item);
                                          getPrePopulateValues(menue.type, item);
                                          setModalNo(menue.value);
                                        },
                                      }))}
                                      disabled={isObligationLocked}
                                    />
                                  </StyledTableBodyCell>
                                </mui.TableRow>
                              );
                            })}
                        </>
                      );
                    })
                  ) : (
                    <mui.TableRow
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <mui.TableCell
                        colSpan={getUnReconciledItemColumns(summaryPage).length}
                        sx={{ textAlign: 'center' }}>
                        {loading ? 'Fetching data...' : 'No data found'}
                      </mui.TableCell>
                    </mui.TableRow>
                  )}
                </mui.TableBody>
              </mui.Table>
            </mui.TableContainer>
          </mui.AccordionDetails>
        </mui.Accordion>
      </WhiteCard>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
      {modalToast?.message && (
        <Toast
          closeAlertHandler={() => {
            setModalToast({
              type: '',
              message: '',
            });
          }}
          toast={modalToast}
        />
      )}
      {preValuesLoader && (
        <mui.Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => { }}>
          <mui.CircularProgress sx={{ color: theme.palette.baseColors.white }} />
        </mui.Backdrop>
      )}
      {modalNo === 1 && !preValuesLoader && (
        <AcceptVarianceModal
          preValues={prePopulateValues}
          item={selectedItem}
          currencyCode={unReconciledItems?.currency_code}
          handleResolveStatus={handleResolveStatus}
          setPrePopulateValues={setPrePopulateValues}
        />
      )}
      {modalNo === 2 && !preValuesLoader && (
        <ManualAdjustmentModal
          preValues={prePopulateValues}
          item={selectedItem}
          currencyCode={unReconciledItems?.currency_code}
          handleResolveStatus={handleResolveStatus}
          setPrePopulateValues={setPrePopulateValues}
        />
      )}
      {modalNo === 3 && !preValuesLoader && (
        <CreateNewTransactionModal
          preValues={prePopulateValues}
          item={selectedItem}
          handleResolveStatus={handleResolveStatus}
          setPrePopulateValue={setPrePopulateValues}
        />
      )}
    </>
  );
};
