import { SvgIcon } from '@mui/material';

export const OutlinedCircleIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12.9225" r="9.5" stroke='currentColor' />
            </svg>
        </SvgIcon>
    )
};
