import { SvgIcon } from '@mui/material';
import React from 'react'

export const UploadIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" d="M3.5 19H21.5V21H3.5V19ZM13.5 5.828V17H11.5V5.828L5.429 11.9L4.015 10.486L12.5 2L20.985 10.485L19.571 11.899L13.5 5.83V5.828Z" fill="currentColor" />

            </svg>

        </SvgIcon>
    )
};
