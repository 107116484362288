import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../user';

// Create a context to manage active users' state
const ActiveUsers = createContext();

export const UseActive = () => {
  return useContext(ActiveUsers);
};

const ActiveUserProvider = ({ children }) => {
  const [activeUsers, setActiveUsers] = useState([]);
  const { pathname } = useLocation();
  const { userId, userToken } = useUser();
  //const obligation_id = pathname.split("/").filter((x) => x)[1];
  const [pathnameArr, setPathArr] = useState([]);
  const [isInObligation, setIsInObligation] = useState(false);
  const [obligation_id, setObligation_id] = useState(undefined);
  const navigate = useNavigate()

  useEffect(() => {
    if (!pathname) return;
    setPathArr(pathname.split('/').filter((x) => x));
  }, [pathname]);

  // Function to insert the active user
  const insertActiveUser = (obligation_id) => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/obligationuser/upsertobligationuser', {
      method: 'POST',
      body: JSON.stringify({
        obligation_id: obligation_id,
        user_id: userId,
        current_access: 'active',
        locked: false,
      }),
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Error inserting active user');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Function to fetch the active users
  const fetchActiveUsers = () => {
    fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/obligationuser/getactiveobligationusers/${obligation_id}`,
      {
        method: 'GET',

        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => {

        if (!res.ok) {
          throw new Error('500 Err Code');
        }
        return res.json();
      })
      .then((data) => {
        setActiveUsers(data?.obligationusers);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Function to delete the active user
  const deleteActiveUser = (obligation_id) => {

    // Return early if request would be malformed.
    if (!userId || !obligation_id) return;

    fetch(process.env.REACT_APP_BACKEND_URL + `/obligationuser/upsertobligationuser`, {
      method: 'POST',
      body: JSON.stringify({
        obligation_id: obligation_id,
        user_id: userId,
        current_access: 'inactive',
        locked: false,
      }),
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      keepalive: true,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('500 Err Code');
        }
        return res.json();
      })
      .then((data) => {
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!userId || !userToken) return;

    // Insert active user when component mounts and route matches '/obligations'
    if (!isInObligation && pathnameArr?.[0] === 'obligations' && pathnameArr?.length >= 4) {
      const newObligationID = pathname.split('/').filter((x) => x)[1];
      insertActiveUser(newObligationID);
      setObligation_id(newObligationID);
      setIsInObligation(true);
    }

    if (isInObligation && pathnameArr?.length === 1) {
      deleteActiveUser(obligation_id);
      setObligation_id(undefined);
      setIsInObligation(false);
    }

    // Fetch active users every 30 seconds while on the '/obligations' route
    if (isInObligation && pathnameArr?.length === 4) {
      const intervalDuration = 30;
      const intervalId = setInterval(fetchActiveUsers, intervalDuration * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [pathnameArr, userId, userToken, isInObligation]); // Trigger effect when route changes

  useEffect(() => {
    // Always try to delete the Active User when component unmounts.
    // This will make it so that the Active User gets deleted if we switch directly from one Obligation to another.
    const handleUnload = () => {
      deleteActiveUser(obligation_id);
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [obligation_id]); // We need this dependency since the Event Handler callback is determined once when the useEffect happens.

  const contextValue = useMemo(() => ({ activeUsers }), [activeUsers]);

  return <ActiveUsers.Provider value={contextValue}>{children}</ActiveUsers.Provider>;
};

export { ActiveUsers, ActiveUserProvider };
