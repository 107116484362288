import { SvgIcon } from '@mui/material';

export const AttachmentIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M14.6667 13.5V8C14.6667 6.93913 14.2453 5.92172 13.4952 5.17157C12.745 4.42143 11.7276 4 10.6667 4C9.60588 4 8.58847 4.42143 7.83832 5.17157C7.08818 5.92172 6.66675 6.93913 6.66675 8V13.5C6.66675 15.2239 7.35157 16.8772 8.57055 18.0962C9.78954 19.3152 11.4428 20 13.1667 20C14.8907 20 16.544 19.3152 17.7629 18.0962C18.9819 16.8772 19.6667 15.2239 19.6667 13.5V4H21.6667V13.5C21.6667 15.7543 20.7712 17.9163 19.1772 19.5104C17.5831 21.1045 15.4211 22 13.1667 22C10.9124 22 8.7504 21.1045 7.15634 19.5104C5.56228 17.9163 4.66675 15.7543 4.66675 13.5V8C4.66675 6.4087 5.29889 4.88258 6.42411 3.75736C7.54933 2.63214 9.07545 2 10.6667 2C12.258 2 13.7842 2.63214 14.9094 3.75736C16.0346 4.88258 16.6667 6.4087 16.6667 8V13.5C16.6667 14.4283 16.298 15.3185 15.6416 15.9749C14.9852 16.6313 14.095 17 13.1667 17C12.2385 17 11.3483 16.6313 10.6919 15.9749C10.0355 15.3185 9.66675 14.4283 9.66675 13.5V8H11.6667V13.5C11.6667 13.8978 11.8248 14.2794 12.1061 14.5607C12.3874 14.842 12.7689 15 13.1667 15C13.5646 15 13.9461 14.842 14.2274 14.5607C14.5087 14.2794 14.6667 13.8978 14.6667 13.5Z" fill="currentColor" />
            </svg>
        </SvgIcon>
    )
};
