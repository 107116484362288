import React, { useEffect, useState } from 'react';
import { WhiteCard } from '../WhiteCard';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useUser } from '../../../context';
import { SummaryCard } from './SummaryCard';
import { ExceptionsTestCard } from './ExceptionsTestCard';
import { IconToogle } from '../../../components/common/Toggle/Toggle';
import { ListNotes } from '../Notes/ListNotes';
import { StatsIcon, GraphIcon } from '../../../components/common/CustomIcons/Exceptions';
import { useParams } from 'react-router-dom';
import { useObligation } from '../../../context';
import { Looker } from '../../../components/common/Looker/Looker';
import { LoadingView } from './LoadingView';
import {
  lookerEmbedDomain,
  getCurrentRelativePath,
  currencyFormatterWithoutSymbol,
} from '../../../utils';
import { ExceptionsLooker } from './ExceptionsLooker';
import { useNavigate } from 'react-router-dom';

export const Exceptions = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const { userToken } = useUser();
  const { id, member } = useParams();
  const [summary, setSummary] = useState({
    exceptions_tests_run: null,
    passed_transactions: null,
    unresolved_exceptions: null,
    resolved_exceptions: null,
  });
  const [view, setView] = useState('stats');
  const [isLoading, setIsLoading] = useState(true);
  const { selectedObgDetails } = useObligation();

  const [purchases, setPurchases] = useState([
    { id: 0, value: 0, color: 'green', label: 'Passed' },
    { id: 1, value: 0, color: 'lightgray', label: 'Resolved' },
    { id: 2, value: 0, color: 'orange', label: 'Unresolved' },
  ]);
  const [supplies, setSupplies] = useState([
    { id: 0, value: 0, color: 'green', label: 'Passed' },
    { id: 1, value: 0, color: 'lightgray', label: 'Resolved' },
    { id: 2, value: 0, color: 'orange', label: 'Unresolved' },
  ]);
  const [businesses, setBusinesses] = useState([
    { id: 0, value: 0, color: 'green', label: 'Passed' },
    { id: 1, value: 0, color: 'lightgray', label: 'Resolved' },
    { id: 2, value: 0, color: 'orange', label: 'Unresolved' },
  ]);

  const viewHandler = (v) => {
    setView(v);
  };
  const obligationBaseUrl =
    selectedObgDetails.org_heirarchy_code === selectedObgDetails.obligation_group_id
      ? `obligation_id=${selectedObgDetails.obligation_id}`
      : `obligation_id=${selectedObgDetails.obligation_id}&entitycode=${selectedObgDetails.org_heirarchy_code}`;

  useEffect(() => {
    if (!userToken || !selectedObgDetails?.obligation_id) return;

    setIsLoading(true);

    fetch(process.env.REACT_APP_BACKEND_URL + `/exception/counts?${obligationBaseUrl}`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        setSummary({
          exceptions_tests_run: data.exception_tests_run ? data.exception_tests_run : 0,
          passed_transactions: data.passed_transactions ? data.passed_transactions : 0,
          unresolved_exceptions: data.unresolved_exceptions ? data.unresolved_exceptions : 0,
          resolved_exceptions: data.resolved_exceptions ? data.resolved_exceptions : 0,
        });
        setPurchases([
          {
            id: 0,
            value: data.passed_transactions_purchase,
            color: 'green',
            label: 'Passed',
          },
          {
            id: 1,
            value: data.resolved_exceptions_purchase,
            color: 'lightgray',
            label: 'Resolved',
          },
          {
            id: 2,
            value: data.unresolved_exceptions_purchase,
            color: 'orange',
            label: 'Unresolved',
          },
        ]);
        setSupplies([
          {
            id: 0,
            value: data.passed_transactions_supply,
            color: 'green',
            label: 'Passed',
          },
          {
            id: 1,
            value: data.resolved_exceptions_supply,
            color: 'lightgray',
            label: 'Resolved',
          },
          {
            id: 2,
            value: data.unresolved_exceptions_supply,
            color: 'orange',
            label: 'Unresolved',
          },
        ]);
        setBusinesses([
          {
            id: 0,
            value: data.passed_business_number_validations,
            color: 'green',
            label: 'Passed',
          },
          {
            id: 1,
            value: data.resolved_business_number_validations,
            color: 'lightgray',
            label: 'Resolved',
          },
          {
            id: 2,
            value: data.unresolved_business_number_validations,
            color: 'orange',
            label: 'Unresolved',
          },
        ]);
      })
      .catch((err) => {
        console.error('Error:', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userToken, selectedObgDetails?.obligation_id, selectedObgDetails?.member]); // Only re-run effect if these specific fields change

  const [loadingIframe, setLoadingIframe] = useState(true);

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <WhiteCard
            style={{
              padding: '1.5rem',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography
              component='h4'
              color={theme.palette.baseColors.black}
              sx={{
                fontWeight: 700,
                fontSize: '1.125rem',
              }}>
              Exceptions
            </Typography>
            <IconToogle
              viewHandler={viewHandler}
              view={view}
              leftData={{
                icon: (
                  <StatsIcon
                    sx={{
                      color:
                        view === 'stats'
                          ? theme.palette.primaryColors[200]
                          : theme.palette.secondaryColors[400],
                    }}
                  />
                ),
                view: 'stats',
              }}
              rightData={{
                icon: (
                  <GraphIcon
                    sx={{
                      color:
                        view === 'graphs'
                          ? theme.palette.primaryColors[200]
                          : theme.palette.secondaryColors[400],
                    }}
                  />
                ),
                view: 'graphs',
              }}
            />
          </WhiteCard>
          {view === 'stats' && (
            <>
              <WhiteCard style={{ padding: '1.5rem', justifyContent: 'space-between' }}>
                <Typography
                  component='h4'
                  color={theme.palette.baseColors.black}
                  sx={{
                    fontWeight: 700,
                    fontSize: '1.125rem',
                  }}>
                  Summary
                </Typography>
                <Box>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    gap='1.5rem'>
                    <SummaryCard
                      title='Exception Tests Run'
                      value={currencyFormatterWithoutSymbol(summary.exceptions_tests_run)}
                    />
                    <SummaryCard
                      title='Passed Transactions'
                      value={summary.passed_transactions}
                      url={`/obligations/${id}/${member}/all-transactions?exceptionTag=PASSED&backAddress=${getCurrentRelativePath()}`}
                    />
                    <SummaryCard
                      title='Unresolved Transactions'
                      value={currencyFormatterWithoutSymbol(summary.unresolved_exceptions)}
                      url={`/obligations/${id}/${member}/all-transactions?exceptionTag=UNRESOLVED&backAddress=${getCurrentRelativePath()}`}
                    />
                    <SummaryCard
                      title='Resolved Transactions'
                      value={currencyFormatterWithoutSymbol(summary.resolved_exceptions)}
                      url={`/obligations/${id}/${member}/all-transactions?exceptionTag=RESOLVED&backAddress=${getCurrentRelativePath()}`}
                    />
                  </Stack>
                </Box>
              </WhiteCard>
              <WhiteCard style={{ padding: '1.5rem', justifyContent: 'space-between' }}>
                <Typography
                  component='h4'
                  color={theme.palette.baseColors.black}
                  sx={{
                    fontWeight: 700,
                    fontSize: '1.125rem',
                  }}>
                  Exception Tests
                </Typography>
                <Box>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    gap='1.5rem'>
                    <ExceptionsTestCard
                      title='Purchase Tax Exceptions'
                      chartData={purchases}
                      url={`/obligations/${id}/${member}/exceptions/purchase-tax-exceptions`}
                    />
                    <ExceptionsTestCard
                      title='Supply Tax Exceptions'
                      chartData={supplies}
                      url={`/obligations/${id}/${member}/exceptions/supply-tax-exceptions`}
                    />
                    <ExceptionsTestCard
                      title='Business Number Validation'
                      chartData={businesses}
                      url={`/obligations/${id}/${member}/exceptions/business-number-validation`}
                    />
                  </Stack>
                </Box>
              </WhiteCard>
              <ListNotes />
            </>
          )}

          {view === 'graphs' && <ExceptionsLooker />}
        </>
      )}
    </>
  );
};
