import { useUser } from '../../context';
import { useObligation } from '../../context';
import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Toast } from './Toast';
import { Box } from '@mui/material';
import ErrorBadge from '../../components/common/Error Badges/ErrorBadge';
import { AuthError, CheckAuthorization } from './Authorization/Authorization';
import { UseAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const OrphanTable = ({ children }) => {
  const [toast, setToast] = useState({ type: '', message: '' });
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedObgDetails, selectedObgHandler, isObligationLocked } = useObligation();
  const { userToken } = useUser();
  const [searchParams] = useSearchParams();
  const differentiator = searchParams.get('differentiator');
  const { authorized, setAuthorized, authLoader, setAuthLoader } = UseAuth();

  useEffect(() => {
    // Delete the Obligation Context.
    return () => {
      selectedObgHandler({});
    };
  }, []);

  useEffect(() => {
    if (!userToken) return;

    const url = differentiator
      ? process.env.REACT_APP_BACKEND_URL +
      `/obligationsummary/listall?limit=1&filter_query=obligation_id__eq=${id}%26member__eq=${differentiator}`
      : process.env.REACT_APP_BACKEND_URL +
      `/obligationsummary/listall?limit=1&filter_query=obligation_id__eq=${id}`;
    let _obligation;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(
          `Something went wrong while fetching Obligation ID ${id}. Status code: ${res.statusCode}`
        );
      })
      .then((data) => {
        if (data?.summary.length === 0)
          throw new Error(`Obligation with ID ${id} could not be found in the Database!`);
        _obligation = data?.summary[0];
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Obligation data could not be fetched.',
        });
      })
      .finally(() => {
        selectedObgHandler(_obligation);
      });
  }, [userToken, id]);

  useEffect(() => {
    if (!userToken) return;
    CheckAuthorization(userToken, setAuthorized, id, setAuthLoader);
  }, [userToken]);

  return (
    !authLoader &&
    (authorized === 'Authorized' ? (
      <>
        <ErrorBadge
          open={isObligationLocked}
          variant='locked'>
          This obligation is now locked for editing.
        </ErrorBadge>
        {children}
        {toast?.message && (
          <Toast
            closeAlertHandler={() => {
              setToast({
                type: '',
                message: '',
              });
            }}
            toast={toast}
          />
        )}
      </>
    ) : (
      <AuthError />
    ))
  );
};
