import { Stack, Grid, Typography, useTheme, Link, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { WhiteCard } from '../WhiteCard';
import { Warning } from '../../../components/common/CustomIcons/FilledWarning';
import { fetchData, reconciliationBaseUrl, typographyStyle } from './index';
import { useObligation, useUser } from '../../../context';
import { Toast } from '../Toast';
import { VarianceColorTheme } from './Utility/ColorCard';
import { CheckCircleOutlinedIcon } from '../../../components/common/CustomIcons/CheckCircleOutlinedIcon';
import { formatAmount } from './Utility/helperFunctions';

export const TrialBalanceReconciliations = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { selectedObgDetails } = useObligation();
  const { userToken } = useUser();
  const [trialBalanceVarianceSummary, setTrialBalanceVarianceSummary] = useState({});
  const [toast, setToast] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (userToken && selectedObgDetails?.obligation_id) {
      setLoading(true);
      let url =
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/trialbalancevariance?${reconciliationBaseUrl(selectedObgDetails)}`;
      fetchData(
        navigate,
        userToken,
        url,
        setTrialBalanceVarianceSummary,
        'Trial Balances vs Transactions',
        setToast,
        setLoading
      );
    }
  }, [selectedObgDetails, userToken]);

  return (
    <>
      <WhiteCard>
        <Stack
          direction={'row'}
          gap={'.5rem'}>
          <Typography
            component='h4'
            sx={typographyStyle('1.125rem', '700', theme.palette.baseColors.grey)}>
            Trial Balance Reconciliations
          </Typography>
          <Typography sx={typographyStyle('1.125rem', 400, theme.palette.neutralColors.black[400])}>
            (1)
          </Typography>
        </Stack>
        {loading ? (
          <Skeleton
            height={200}
            width={'31.8%'}
            sx={{ transform: 'none' }}
          />
        ) : (
          <Grid
            container
            direction='row'
            columns={3}
            gap={'1.5rem'}>
            <Grid
              item
              width='31.8%'>
              <WhiteCard style={{ padding: '1rem' }}>
                <Grid
                  container
                  gap={'1.5rem'}>
                  <Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                    Trial Balances vs Transactions
                  </Typography>
                  <Grid
                    item
                    sx={VarianceColorTheme(
                      trialBalanceVarianceSummary?.Variance === 0 ? '' : 'Warning'
                    )}>
                    <Stack
                      direction={'row'}
                      gap={'4px'}>
                      {trialBalanceVarianceSummary?.Variance === 0 ? (
                        <CheckCircleOutlinedIcon
                          style={{ color: 'green' }}
                          sx={{ width: '1rem', height: '1rem' }}
                        />
                      ) : (
                        <Warning sx={{ width: 16, height: 16 }} />
                      )}
                      <Typography
                        sx={typographyStyle(
                          '.875rem',
                          '700',
                          theme.palette.neutralColors.black[400]
                        )}>
                        Variance
                      </Typography>
                    </Stack>
                    <Typography
                      sx={typographyStyle('1.5rem', '400', theme.palette.baseColors.grey)}>
                      {formatAmount(
                        trialBalanceVarianceSummary?.Variance,
                        trialBalanceVarianceSummary?.Currency_Code
                      ) ?? '-'}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    display={'flex'}
                    gap={'3px'}
                    sx={{ padding: '0 .75rem ' }}>
                    <Grid sx={{ borderRight: `1px solid `, width: 186, mr: '5px' }}>
                      <Typography
                        sx={typographyStyle(
                          '.875rem',
                          '400',
                          theme.palette.neutralColors.black[400]
                        )}>
                        Trial Balance
                      </Typography>
                      <Typography
                        sx={typographyStyle('1.5rem', '400', theme.palette.baseColors.grey)}>
                        {formatAmount(
                          trialBalanceVarianceSummary?.Trial_Balance,
                          trialBalanceVarianceSummary?.Currency_Code
                        ) ?? '-'}
                      </Typography>
                    </Grid>
                    <Grid sx={{ width: '11.625rem' }}>
                      <Typography
                        sx={typographyStyle(
                          '.875rem',
                          '400',
                          theme.palette.neutralColors.black[400]
                        )}>
                        Transactions
                      </Typography>
                      <Typography
                        sx={typographyStyle('1.5rem', '400', theme.palette.baseColors.grey)}>
                        {formatAmount(
                          trialBalanceVarianceSummary?.Transactions,
                          trialBalanceVarianceSummary?.Currency_Code
                        ) ?? '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Link
                  sx={typographyStyle('1rem', '500', theme.palette.functional.primaryLink, {
                    cursor: 'pointer',
                  })}
                  onClick={() => {
                    navigate(
                      `/obligations/${selectedObgDetails['obligation_id']}/${selectedObgDetails['member']}/reconciliations/trial-balances-vs-transactions-summary`
                    );
                  }}
                  color='inherit'>
                  {'View Reconciliation'}
                </Link>
              </WhiteCard>
            </Grid>
          </Grid>
        )}
      </WhiteCard>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
