import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";

export const ScrollableStack = ({ children }) => {
  const location = useLocation();
  const stackRef = useRef(null);

  useEffect(() => {
    // When the location changes, scroll to the top of the Stack
    if (stackRef.current) {
      stackRef.current.scrollTop = 0;
    }
  }, [location.pathname]); // Depends on location.pathname to trigger the effect

  return (
    <Stack ref={stackRef} flexGrow={1} sx={{ overflowY: "auto" }}>
      {children}
    </Stack>
  );
};
