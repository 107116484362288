import { Stack, Typography, useTheme } from '@mui/material';
import { ObligationTable } from './ObligationTable';
import { WhiteCard } from './WhiteCard';
import { NavMenu } from '../../components/common/Navbar/NavMenu';
import { useEffect, useState } from 'react';
import { useUser } from '../../context';
import { FilterContext } from './FilterContext';
import { useNavigate } from 'react-router-dom';
import { DUMMY_COUNTS_DATA } from './dummyData';

export const Obligations = () => {
  const theme = useTheme();
  const { userToken } = useUser();
  const navigate = useNavigate();
  // This creates a tabProps object you can use for the NavMenu component, from a counts dictionary.
  const tabPropsFromCounts = (counts) =>
    Object.keys(counts).map((k) => {
      return {
        name: k.charAt(0).toUpperCase() + k.slice(1),
        count: counts[k],
        onClick: () => {
          setCurrentTab(k);
          setFilters(tabSpecificFilters[k]);
          setDisabledFilters(tabSpecificDisabledFilters[k]);
        },
      };
    });

  const [tabProps, setTabProps] = useState(
    tabPropsFromCounts({
      all: 'loading',
      open: 'loading',
      approved: 'loading',
      overdue: 'loading',
    })
  );

  const defaultTab = 'all';
  const tabSpecificFilters = {
    all: {},
    open: {
      _currentTab: 'open',
    },
    overdue: {
      status: ['Overdue'],
    },
    approved: {
      _currentTab: 'approved',
    },
  };
  const tabSpecificDisabledFilters = {
    all: [],
    open: [],
    overdue: ['Status'],
    approved: [],
  };

  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [filters, setFilters] = useState(tabSpecificFilters[defaultTab]);
  const [disabledFilters, setDisabledFilters] = useState(tabSpecificDisabledFilters[defaultTab]);

  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!userToken) return;

    // GET TAB COUNTS DATA
    let _counts;
    const countsReqUrl =
      process.env.REACT_APP_BACKEND_URL + `/obligationsummary/getobligationsummarycounts`;
    fetch(countsReqUrl, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        if (res.ok) return res.json();

        throw new Error(
          `Something went wrong while fetching Obligation counts. Status code: ${res.statusCode}`
        );
      })
      .then((data) => {
        _counts = data;
      })
      .catch((err) => {
        _counts = DUMMY_COUNTS_DATA;
        console.error(err);
      })
      .finally(() => {
        setTabProps(tabPropsFromCounts(_counts));
        setIsLoading(false);
      });
  }, [userToken]);

  return (
    <Stack>
      <Stack
        sx={{
          height: '9.625rem',
          padding: '2.5rem',
          backgroundColor: 'white',
          borderBottom: '1px solid #DEE0E1',
          position: 'relative',
        }}>
        <Typography sx={{ fontSize: '2.25rem', fontWeight: 700, color: theme.palette.random[300] }}>
          Obligations
        </Typography>
        <Stack
          direction='row'
          display='flex'
          width='100%'
          sx={{
            gap: '1.5rem',
            height: '3rem',
            position: 'absolute',
            bottom: '-1px',
            left: '-2.5rem',
            paddingLeft: '5rem',
          }}>
          <NavMenu
            availableTabs={tabProps}
            currentTab={currentTab}
            disabledOverride={isTableLoading}
          />
        </Stack>
      </Stack>

      <Stack
        gap='1.5rem'
        alignItems='flex-start'
        p='2.5rem'>
        <WhiteCard
          style={{
            width: 1,
          }}>
          <Typography
            sx={{
              color: theme.palette.random[300],
              fontWeight: 700,
              fontSize: '1.125rem',
            }}>
            {currentTab === "all" ? "All Obligations" : currentTab === "open" ? "Open Obligations" : currentTab === "approved" ? "Approved Obligations" : "Overdue Obligations"}
          </Typography>

          <FilterContext.Provider
            value={{ filters, setFilters, disabledFilters, setIsTableLoading }}>
            <ObligationTable />
          </FilterContext.Provider>
        </WhiteCard>
      </Stack>
    </Stack>
  );
};
