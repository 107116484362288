import { useTheme, InputLabel, Checkbox, Stack, Typography } from "@mui/material"

export const SimpleCheckbox = (props) => {
    const theme = useTheme()
    return (
        <Stack direction="row" alignItems="center">
            <Checkbox
                {...props}
                sx={{
                    color: "#00000033",
                    padding: "0.0625rem",
                    paddingRight: "0.625rem",
                    //display: "inline-block",
                    '&.Mui-checked': {
                        color: theme.palette.primaryColors[200]
                    },
                }}
                disableRipple
            />
            <InputLabel
                htmlFor={props.id}
                sx={{
                fontWeight: 500,
                cursor: 'pointer',
                color: theme.palette.baseColors.grey,
                width: 'max-content',
                }}
            >
                {props.label}&nbsp;
                {props.required && <Typography component="span"
                    sx={{
                        fontWeight: 400,
                        color: theme.palette.functional.error
                    }}
                >
                    (Required)
                </Typography>}
            </InputLabel>
        </Stack>
    )
}