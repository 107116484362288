export const NAV_LINKS_MAPPING = [
    {
        name: 'Homepage',
        path: '/home'
    },
    {
        name: 'Global Dashboard',
        path: '/global-dashboard'
    },
    {
        name: 'Obligations',
        path: '/obligations'
    },
];

export const CLIENT = `Tax Control Centre`;