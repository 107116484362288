import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import "../../../components/common/Filters/Datestyle.css";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { useTheme } from "@mui/material";

export const FilterSelect = ({
  onChange,
  options,
  value,
  label,
  displayValue,
  loading,
  specialOptions,
  children,
  ...props
}) => {
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        color: theme.palette.baseColors.grey,
      },
    },
  };

  return (
    <FormControl sx={{ width: "15%", maxHeight: "3.25rem" }}>
      <Select
        displayEmpty
        disabled={loading}
        value={value}
        IconComponent={ChevronDownIcon}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          `${selected?.length ? `${displayValue?.length ? displayValue : selected}` : label}`
        }
        MenuProps={MenuProps}
        sx={{
          height: "3rem",
          border: `1px solid ${theme.palette.random[500]} `,
          color: value.length === 0 ? theme.palette.secondaryColors[300] : theme.palette.baseColors.black,
          backgroundColor: value.length === 0 ? theme.palette.baseColors.white : theme.palette.neutralColors.black[100],
          "& legend": { display: "none", width: "unset" },
          "& fieldset": { top: 0, border: "none" },
        }}
        {...props}
      >
        {specialOptions && specialOptions.length
          ? specialOptions.map((option) => (
            <MenuItem
              sx={{
                whiteSpace: "normal",
                textOverflow: "ellipsis",
                overflow: "hidden",

                "&:hover": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                },
                "&.Mui-selected": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                  borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                  borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                },
                "&.Mui-selected.Mui-focusVisible": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                  borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                },
              }}
              key={option.label}
              value={option.value}
            >
              <ListItemText
                sx={{ textTransform: "capitalize" }}
                primary={option.label}
              />
            </MenuItem>
          ))
          : options?.map((name) => (
            <MenuItem
              sx={{
                whiteSpace: "normal",
                textOverflow: "ellipsis",
                overflow: "hidden",

                "&:hover": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                },
                "&.Mui-selected": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                  borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                  borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                },
                "&.Mui-selected.Mui-focusVisible": {
                  backgroundColor: theme.palette.neutralColors.black[300],
                  borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                },
              }}
              key={name}
              value={name}
            >
              <ListItemText primary={name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
