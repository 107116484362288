import { styled, Button, ButtonGroup } from "@mui/material";

const CustomButton = styled(Button)(({ theme, active }) => ({
    color: theme.palette.secondaryColors[300],
    backgroundColor: active ? '#000000CC' : 'transparent',
    borderColor: '#0000001A',
    minWidth: '90.75px !important',
    '&:hover': {
      backgroundColor: active ? theme.palette.grey[900] : theme.palette.action.hover,
    },
    ...(active && {
      color: theme.palette.common.white,
    }),
    height: '3rem',
}));

export const StyledButtonGroup = ({ options, currentOption, setOption, sx }) => {
    return (
        <ButtonGroup
            disableElevation
            variant='text'
            color='primary'
            aria-label='option button group'
            sx={{
                '.MuiButton-outlined': {
                    borderColor: '#0000001A',
                    '&:hover': { borderColor: '#0000001A' },
                },
                ...sx
            }}
        >
            {options?.map((option) => {
                const isString = typeof option === 'string' || option instanceof String
                
                const key = isString ? option : option.key
                const label = isString ? option : option.label

                return (
                    <CustomButton
                        key={key}
                        active={currentOption === key ? 'true' : ''}
                        onClick={() => setOption(key)}
                        variant={currentOption === key ? 'contained' : 'outlined'}>
                        {label}
                    </CustomButton>
                )
            })}
        </ButtonGroup>
    )
}