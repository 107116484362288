// Function for all-transactions (ListTransaction) table to apply multiple query values depending on the selected filter for Exception Tag
export const handleSpecialFilters = (
  inputValue,
  setFilters,
  currentFilters,
  handleClear,
  setIsOpen
) => {
  let filteredExistingFilters = currentFilters.filter(
    (filter) =>
      filter.field.mapping !== "status_code" &&
      filter.field.mapping !== "result_adjustment_status"
  );

  const inputValueLower = inputValue.toLowerCase();

  if (inputValueLower === "passed") {
    filteredExistingFilters.push({
      condition: { label: "Equals to", operator: "eq" },
      field: {
        column_name: "Exception Tag",
        mapping: "status_code",
        type: "exceptionTag",
        permanent: true
      },
      displayValue: "Passed",
      queryValue: 0,
    });
  } else if (inputValueLower === "resolved") {
    filteredExistingFilters.push({
      condition: { label: "Equals to", operator: "eq" },
      field: {
        column_name: "Exception Tag",
        mapping: "result_adjustment_status",
        type: "exceptionTag",
        permanent: true
      },
      displayValue: "Resolved",
      queryValue: "RESOLVED%26status_code__eq=1",
    });
  } else if (inputValueLower === "unresolved") {
    filteredExistingFilters.push({
      condition: { label: "Equals to", operator: "eq" },
      field: {
        column_name: "Exception Tag",
        mapping: "result_adjustment_status",
        type: "exceptionTag",
        permanent: true
      },
      displayValue: "Unresolved",
      queryValue: "UNRESOLVED%26status_code__eq=1",
    });
  }

  setFilters(filteredExistingFilters);
  handleClear();
  setIsOpen(false);
};
