import { SvgIcon } from '@mui/material';
import React from 'react'

export const TaxBreakdownIcon = (props) => {
    return (
        <SvgIcon {...props}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3925_3392)">
        <path d="M16 4V9.33333H4V4H16ZM21.3333 22.6667V28H4V22.6667H21.3333ZM29.3333 13.3333V18.6667H4V13.3333H29.3333Z" fill="#474747"/>
        </g>
        <defs>
        <clipPath id="clip0_3925_3392">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
        </SvgIcon>
    )
};
