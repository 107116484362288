import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import OutlinedInput from "@mui/material/OutlinedInput";

const RegionCountry = (props) => {
  const initialData = {
    regions: [
      { id: "LACA", desc: "Latin-America-and-Caribbean" },
      { id: "EMEA", desc: "Europe-MiddleEast-Africa" },
      { id: "APAC", desc: "Asia Pacific" },
      { id: "NA", desc: "North America" },
    ],
    LACA: [],
    EMEA: [],
    APAC: [
      { id: "AU", desc: "Australia" },
      { id: "IN", desc: "India" },
      { id: "HK", desc: "Hongkong" },
      { id: "CHN", desc: "China" },
      { id: "INDN", desc: "Indonesia" },
    ],
    NA: [],
  };

  const [data, setData] = useState();
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [loading, setLoading] = useState();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const theme = useTheme();
  const MenuProps = {
    MenuListProps: {
      sx: {
        pt: "0",
        pb: "0"
      }
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 225,
        color: theme.palette.baseColors.grey,
      },
    },
  };
  // TODO: Come back to this
  /* const checkboxSx = {
    color: "#00000033",
    backgroundColor: "white",
    '& .MuiSvgIcon-root': {
      fill: "white"
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        fill: theme.palette.primaryColors[200]
      },
    },
  } */
  const selectSx = anySelected => ({
    height: 1,
    border: `1px solid ${theme.palette.random[500]} `,
    color: anySelected ? theme.palette.baseColors.black : theme.palette.secondaryColors[300],
    backgroundColor: anySelected ? theme.palette.neutralColors.black[100] : theme.palette.baseColors.white,
    "& legend": { display: "none", width: "unset" },
    "& fieldset": { top: 0, border: "none" },
    "&.Mui-disabled": { backgroundColor: theme.palette.neutralColors.black[100] },
    // '& .MuiInputLabel-shrink':{opacity:0, transition:"all 0.1s ease-in"},
    // '& .MuiInputLabel-root':{display:'none'}
  })
  const checkboxSx = {
    color: "#00000033",
    '&.Mui-checked': {
      color: theme.palette.primaryColors[200]
    },
  }
  const menuItemSx = {
    boxSizing: "content-box",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderTop: `1px solid ${theme.palette.baseColors.white}`,
    borderBottom: `1px solid ${theme.palette.baseColors.white}`,

    "&:hover": {
      backgroundColor: theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.random[1200]}`,
      borderBottom: `1px solid ${theme.palette.random[1200]}`,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
      borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
      borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
    },
    "&.Mui-selected.Mui-focusVisible": {
      backgroundColor: theme.palette.random[1200],
      borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
      borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
    }
  }

  useEffect(() => {
    setData(props.data);
    setSelectedRegions(props.selectedRegions);
    setSelectedCountries(props.selectedCountries);
    setLoading(props.loading);
  }, [props]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "40%",
        height: "3.5rem",
      }}
    >
      <FormControl sx={{ width: "50%", maxHeight: "3.25rem" }}>
        <Select
          className="slct"
          displayEmpty
          inputProps={{ "aria-label": "Select option" }}
          disabled={loading || props.disabled}
          id="demo-multiple-checkbox"
          multiple
          value={selectedRegions ? selectedRegions : []}
          IconComponent={ChevronDownIcon}
          onChange={(e) => {
            props.handleRegionChange(e);
          }}
          input={<OutlinedInput label="Region" />}
          renderValue={(selected) =>
            `Region ${selected?.length ? `(${selected?.length})` : ""}`
          }
          MenuProps={MenuProps}
          sx={selectSx(selectedRegions.length !== 0)}
        >
          {data?.regions?.sort((r1, r2) => r1.id.localeCompare(r2.id)).map((region) => (
            <MenuItem
              sx={menuItemSx}
              key={region.id}
              value={region.id}
            >
              <Checkbox
                checked={selectedRegions?.includes(region.id)}
                sx={checkboxSx}
                disableRipple
              />
              <ListItemText primary={region.id} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: "50%", ml: 1, maxHeight: "3.25rem" }}>
        <Select
          className="slct"
          displayEmpty
          inputProps={{ "aria-label": "Select option" }}
          disabled={loading || props.disabled}
          id="demo-multiple-checkbox"
          multiple
          value={selectedCountries ? selectedCountries : []}
          IconComponent={ChevronDownIcon}
          onChange={(e) => {
            props.handleCountryChange(e);
          }}
          input={<OutlinedInput label="Country" />}
          renderValue={(selected) =>
            `Country ${selected?.length ? `(${selected?.length})` : ""}`
          }
          MenuProps={MenuProps}
          sx={selectSx(selectedCountries.length !== 0)}
        >
          {selectedRegions?.length === 0
            ? data?.regions
              ?.flatMap((region) => data[region.id])?.sort((r1, r2) => r1.desc.localeCompare(r2.desc))
              ?.map((country) => (
                <MenuItem
                  sx={menuItemSx}
                  key={country.id}
                  value={country.desc}
                >
                  <Checkbox
                    checked={selectedCountries.includes(country.desc)}
                    sx={checkboxSx}
                    disableRipple
                  />
                  <ListItemText primary={country.desc} />
                </MenuItem>
              ))
            : data?.regions
              ?.filter((region) => selectedRegions.includes(region.id))
              ?.flatMap((region) => data[region.id])?.sort((r1, r2) => r1.desc.localeCompare(r2.desc))
              ?.map((country) => (
                <MenuItem key={country.id} value={country.desc}>
                  <Checkbox
                    checked={selectedCountries.includes(country.desc)}
                    sx={checkboxSx}
                    disableRipple
                  />
                  <ListItemText primary={country.desc} />
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default RegionCountry;
