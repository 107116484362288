import { Stack, Typography, useTheme } from '@mui/material';
import { BtnLink } from '../../../components/common/Buttons/BtnLink';
import { useNavigate } from "react-router-dom";

export const ActionCard = ({ children, title, text, link }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const go = () => navigate(link.url);

    return (
        <Stack
            sx={{
                borderRadius: '0.5rem',
                border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                backgroundColor: theme.palette.baseColors.white,
                padding: '1.5rem',
                color: theme.palette.random[300],
                alignItems: "flex-start",
                height: "100%",
            }}
        >
            <Stack direction="row" alignItems="center" spacing=".75rem" sx={{
                marginBottom: '.5rem'
            }}>
                {children}
                <Typography gutterBottom component="h4" color={theme.palette.baseColors.grey} sx={{
                    fontWeight: 700, fontSize: '1.125rem',
                }}>{title}</Typography>
            </Stack>
            <Typography variant="body2" color={theme.palette.random[1000]} sx={{
                marginBottom: "1.5rem",
                height: 1
            }}>{text}</Typography>
            <BtnLink btnTxt={link.text} onClick={go} />
        </Stack>
    )
}
