import { Stack, useTheme } from '@mui/material';

export const WhiteCard = ({ children, style }) => {
    const theme = useTheme();
    return (
        <Stack
            sx={{
                borderRadius: '0.5rem',
                border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                backgroundColor: theme.palette.baseColors.white,
                gap: '1.5rem',
                padding: '2rem',
                color: theme.palette.random[300],
                ...style
            }}
        >
            {children}
        </Stack>
    )
}
