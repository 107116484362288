import { SvgIcon } from '@mui/material';

export const SquareIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect
          width='16'
          height='16'
          rx='4'
          fill='white'
        />
        <rect
          x='0.5'
          y='0.5'
          width='15'
          height='15'
          rx='3.5'
          stroke='black'
          stroke-opacity='0.2'
        />
      </svg>
    </SvgIcon>
  );
};
