import { Stack, Typography, useTheme } from '@mui/material';
import { Modal as MuiModal } from '@mui/material';
import { CloseIcon } from '../../../components/common/CustomIcons/CloseIcon';
import { BtnStandard } from '../../../components/common/Buttons/BtnStandard';
import { BtnFilled } from '../../../components/common/Buttons/BtnFilled';

export const SubmitChecklistConfirmation = ({
  saveHandler,
  closePopupHandler,
  stage,
  loading,
  setSavedStage,
}) => {
  const theme = useTheme();
  return (
    <MuiModal
      open={true}
      onClose={closePopupHandler}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      sx={{ border: 'none' }}>
      <Stack
        padding='2rem'
        sx={{
          borderRadius: '0.25rem',
          backgroundColor: theme.palette.baseColors.white,
          border: `1px solid ${theme.palette.random[700]}`,
          borderTop: 'none',
          width: '60vw',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 'none',
        }}>
        <Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'>
          <Typography
            variant='h5'
            fontWeight='medium'
            color={theme.palette.baseColors.grey}
            sx={{
              paddingBottom: '0.75rem',
              borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
            }}>
            Submit Checklist
          </Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={closePopupHandler}
          />
        </Stack>
        <Stack
          width='100%'
          mt='2rem'
          gap='2.5rem'
          sx={{ height: 'auto' }}
          pb='0.5rem'>
          <Typography>Are you sure you want to submit this checklist?</Typography>
        </Stack>
        <Stack
          mt='2rem'
          flexDirection='row'
          justifyContent='flex-start'
          gap='0.5rem'>
          <BtnFilled
            size='large'
            btnTxt='Yes'
            onClick={() => {
              setSavedStage(stage);
              saveHandler('Completed', false);
            }}
            loading={loading}
          />
          <BtnStandard
            btnTxt='No'
            onClick={closePopupHandler}
          />
        </Stack>
      </Stack>
    </MuiModal>
  );
};
