import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  styled,
  TableCell,
  IconButton,
  TableBody,
  Button,
} from '@mui/material';
import { WhiteCard } from '../WhiteCard';
import { useNavigate } from 'react-router-dom';
import { SortIcon } from '../../../components/common/CustomIcons/SortIcon';
import { useEffect, useState } from 'react';
import Paginator from '../../../components/common/Paginator/Paginator';
import { typographyStyle } from './index';
import { CloseIcon } from '../../../components/common/CustomIcons/CloseIcon';
import { CheckIcon } from '../../../components/common/CustomIcons/CheckIcon';
import { useObligation, useUser } from '../../../context';
import { SortDescIcon } from '../../../components/common/CustomIcons/SortDescIcon';
import { SortAscIcon } from '../../../components/common/CustomIcons/SortAscIcon';
import { formatAmount } from './Utility/helperFunctions';
import { Toast } from '../Toast';
import { adjustmentsColumns } from './Constants/ReconciliationSummaryConstants';
import { StyledLinearProgress } from '../../../components/common/Progress/StyledLinearProgress';
import { formatDate, reverseDate } from '../../../utils';

export const AdjustmentForReversalList = () => {
  const theme = useTheme();

  const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.secondaryColors[600],
    fontWeight: 700,
    fontSize: '0.875rem',
    padding: '0.25rem 0.5rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  }));
  const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    fontSize: '0.875rem',
    padding: '0.25rem 0.5rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.baseColors.grey,
    height: '3rem',
  }));

  const { userToken, pageSize } = useUser();
  const { selectedObgDetails, selectedObgHandler, isObligationLocked } = useObligation();
  const navigate = useNavigate();
  const [adjustmentList, setAdjustmentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [toast, setToast] = useState({ type: '', message: '' });
  const [disableButton, setDisableButton] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const sortHandler = (col_name) => {
    if (col_name === sortColumn) {
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(col_name);
      // Due Date has the special case of starting to sort in Descending Order FIRST.
      setSortOrder('desc');
    }
  };

  const pageNoChangeHandler = (new_page_no) => {
    setPageNo(new_page_no);
  };

  const ActionHandler = (action, adjustmentItem) => {
    if (userToken) {
      let url = '';
      url =
        action === 'CARRY_FORWARD'
          ? process.env.REACT_APP_BACKEND_URL +
          `/reconciliation/adjustmentreversal_action?transaction_id=${adjustmentItem.transaction_id}&action=${action}&
obligation_id=${adjustmentItem.obligation_id}&previous_transaction_id=${adjustmentItem.previous_transaction_id}&next_obligation_id=${adjustmentItem.next_obligation_id}&
original_obligation_id=${adjustmentItem.original_obligation_id}`
          : process.env.REACT_APP_BACKEND_URL +
          `/reconciliation/adjustmentreversal_action?transaction_id=${adjustmentItem.transaction_id}&action=${action}&
obligation_id=${adjustmentItem.obligation_id}`;

      fetch(url, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setToast({
            type: 'success',
            message: `Adjustment for reversal transaction ${action === 'ACCEPTED'
              ? 'accepted'
                ? action === 'CARRY_FORWARD'
                : 'carry forwarded'
              : 'rejected'
              } successfully.`,
          });
        })
        .catch((err) => {
          setToast({
            type: 'failure',
            message: 'Adjustment for reversal transaction action failed',
          });
        })
        .finally(() => {
          selectedObgHandler({ ...selectedObgDetails });
          setLoading(false);
          setDisableButton(false);
        });
    }
  };

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (userToken && selectedObgDetails?.obligation_id) {
      let url = '';
      let baseUrl =
        selectedObgDetails.org_heirarchy_code === selectedObgDetails.obligation_group_id
          ? `next_obligation_id__eq=${selectedObgDetails.obligation_id}`
          : `next_obligation_id__eq=${selectedObgDetails.obligation_id}%26entity__contains=${selectedObgDetails.org_heirarchy_code}`;
      setLoading(true);
      url = sortColumn
        ? process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/adjustmentreversal/listall?filter_query=${baseUrl}%26action__eq=&
limit=${pageSize}&page=${pageNo}&sort_column=${sortColumn}&sort_dir=${sortOrder}`
        : process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/adjustmentreversal/listall?filter_query=${baseUrl}%26action__eq=&
limit=${pageSize}&page=${pageNo}`;

      let _summaryDetails;
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          _summaryDetails = data;
        })
        .catch((err) => {
          setToast({
            type: 'failure',
            message: 'Adjustment for reversal list data could not be fetched.',
          });
        })
        .finally(() => {
          setAdjustmentList(_summaryDetails);
          setLoading(false);
        });
    }
  }, [selectedObgDetails, pageNo, pageSize, sortColumn, sortOrder, userToken]);

  return (
    <>
      <WhiteCard>
        <Typography sx={typographyStyle('1.125', '700', theme.palette.baseColors.grey)}>
          Adjustments for reversal
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 'none',
            overflowY: 'hidden',
          }}>
          <Table
            aria-label='selected files'
            sx={{
              border: `1px solid ${theme.palette.neutralColors.black[200]}`,
              borderRadius: '0.5rem',
            }}>
            <TableHead
              sx={{
                backgroundColor: theme.palette.neutralColors.black[100],
                height: '2.5rem',
              }}>
              <TableRow variant='body2'>
                {adjustmentsColumns?.map((column) =>
                  column.sorting ? (
                    <StyledTableHeadingCell
                      key={column.label}
                      style={column.cellWidth !== -1 ? { maxWidth: column.cellWidth + '%' } : {}}
                      onClick={() => sortHandler(column.name)}>
                      {column.label}
                      <IconButton disableRipple>
                        {sortColumn === column.name ? (
                          sortOrder === 'asc' ? (
                            <SortDescIcon />
                          ) : (
                            <SortAscIcon />
                          )
                        ) : (
                          <SortIcon />
                        )}
                      </IconButton>
                    </StyledTableHeadingCell>
                  ) : (
                    <StyledTableHeadingCell
                      key={column.label}
                      style={column.cellWidth !== -1 ? { maxWidth: column.cellWidth + '%' } : {}}>
                      {column.label}
                    </StyledTableHeadingCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflowX: 'scroll',
              }}>
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={adjustmentsColumns?.length}
                    sx={{ p: 0 }}>
                    <StyledLinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {adjustmentList?.adjustmentreversal?.length > 0 ? (
                adjustmentList?.adjustmentreversal?.map((adjustmentItem, index) => {
                  return (
                    <TableRow
                      key={adjustmentItem.AdjustmentId}
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <StyledTableBodyCell>
                        <Typography
                          onClick={() => { }}
                          sx={{
                            display: 'inline-block',
                            fontSize: '0.875rem',
                            color: theme.palette.primaryColors[200],
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}>
                          {adjustmentItem.transaction_id}
                        </Typography>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>{adjustmentItem.adjustment_type}</StyledTableBodyCell>
                      <StyledTableBodyCell sx={{ textTransform: 'capitalize' }}>
                        {adjustmentItem?.user?.user_name}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {formatDate(reverseDate(adjustmentItem.created_on, '-'), '-')}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {formatAmount(
                          adjustmentItem.statnetamount,
                          adjustmentItem.statcurrencycode
                        )}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {formatAmount(
                          adjustmentItem.variance_amount,
                          adjustmentItem.statcurrencycode
                        )}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <Button
                          disableRipple
                          disableTouchRipple
                          disableFocusRipple
                          disabled={isObligationLocked || disableButton}
                          onClick={() => {
                            setDisableButton(true);
                            ActionHandler('ACCEPTED', adjustmentItem);
                          }}
                          variant='outlined'
                          color='inherit'
                          sx={{
                            width: 104,
                            height: 32,
                            color: theme.palette.primaryColors[200],
                            mr: 2,
                          }}>
                          <CheckIcon />
                          Accept
                        </Button>
                        <Button
                          disableRipple
                          disableTouchRipple
                          disableFocusRipple
                          disabled={isObligationLocked || disableButton}
                          onClick={() => {
                            setDisableButton(true);
                            ActionHandler('REJECTED', adjustmentItem);
                          }}
                          variant='outlined'
                          color='inherit'
                          sx={{
                            width: 104,
                            height: 32,
                            color: theme.palette.primaryColors[200],
                            mr: 2,
                          }}>
                          <CloseIcon />
                          Reject
                        </Button>
                        {!adjustmentItem.carry_forward_status && (
                          <Button
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            disabled={isObligationLocked || disableButton}
                            onClick={() => {
                              setDisableButton(true);
                              ActionHandler('CARRY_FORWARD', adjustmentItem);
                            }}
                            variant='outlined'
                            color='inherit'
                            sx={{
                              width: 140,
                              height: 32,
                              color: theme.palette.primaryColors[200],
                            }}>
                            Next Period
                          </Button>
                        )}
                      </StyledTableBodyCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  sx={{
                    height: '3rem',
                    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  }}>
                  <TableCell
                    colSpan={adjustmentsColumns?.length}
                    sx={{ textAlign: 'center' }}>
                    {loading ? 'Fetching data...' : 'No data found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Paginator
            totalRecords={adjustmentList?.count}
            pageNoChangeHandler={pageNoChangeHandler}
            pageNo={pageNo}
            disabled={loading}
          />
        </TableContainer>
      </WhiteCard>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
