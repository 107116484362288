import { Alert, Box, Snackbar, Stack, ThemeProvider } from '@mui/material';
import './App.css';
import { GlobalStyle, theme } from './styles/index';
import { NotificationProvider, ObligationContextProvider, UserProvider } from './context';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { PageRouter } from './pages/PageRouter';
import { Header } from './components/common/Header/Header';
import { Footer } from './components/common/Footer/Footer';
import { Notification } from './components/common/Notifications/Notification';
import BreadcrumbsComp from './components/BreadCrumbs/BreadCrumbComp';
import { InactivityProvider } from './context/Inactivity/InactivityContext';
import { ActiveUserProvider } from './context/Active Users/ActiveUsersContext';
import { ScrollableStack } from './utils/ScrollableStack';
import { ReconciliationContextProvider } from './context/Reconciliation/ReconciliationContext';
import { AuthContextProvider } from './context/AuthContext';
import { useEffect, useState } from 'react';

function App() {
  console.log('package issue fixed')
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NotificationProvider>
        <UserProvider>
          <BrowserRouter>
            <InactivityProvider>
              <ActiveUserProvider>
                <ObligationContextProvider>
                  <AuthContextProvider>
                    <ReconciliationContextProvider>
                      <Stack
                        flex={1}
                        sx={{
                          height: '100vh',
                          position: 'relative',
                        }}>
                        <Header />
                        <ScrollableStack>
                          <BreadcrumbsComp />
                          <Box flexGrow={1}>
                            <Notification />
                            <PageRouter />
                          </Box>
                          <Footer />
                        </ScrollableStack>
                      </Stack>
                    </ReconciliationContextProvider>
                  </AuthContextProvider>
                </ObligationContextProvider>
              </ActiveUserProvider>
            </InactivityProvider>
          </BrowserRouter>
        </UserProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
