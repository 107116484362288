import React from 'react';
import { Box, InputLabel, Stack, useTheme, Typography } from '@mui/material';
import { EditableInput } from '../../../../components/common/Input/EditableInput';
import { ReadOnlyInput } from '../../../../components/common/Input/ReadOnlyInput';
import { CurrencyInput } from './CurrencyInput';
import { TaxTreatmentDropdown } from './TaxTreatmentDropdown';
import { Datepicker } from '../../../../components/common/Input/Datepicker';
import { formatAsCurrency } from './constants';
import dayjs from 'dayjs';

export const FieldCell = ({
  name,
  value,
  label,
  onChange,
  isEditable,
  isPermanent,
  isCurrency,
  isTaxTreatment,
  isDatePicker,
  currencyCode,
  showLabel = true,
  highlightable = true,
  fieldStyles,
  relatedFields,
  originalFormData,
  data,
  hideIfNull = false,
}) => {
  const theme = useTheme();
  const nullableValues = [
    'taxtreatment',
    'customercountrycode',
    'entrydate',
    'entityname',
    'entitycountycode',
    'entitycode',
    'billtocountrycode',
    'suppliercountrycode',
    'customerindustry',
    'supplierno',
    'customerno',
    'shiptono',
    'shiptoname',
    'shipfromaddress',
    'shiptocountrycode',
    'shipfromno',
    'shipfromname',
    'shipfromaddress',
    'shipfromcity',
    'shipfrompostcode',
    'shipfromcountrycode',
    'shiptoaddress',
    'observedvatrate',
    'jurisdictionalvatrate',
    'postingdate',
    'invoicedate',
    'determinedvatrate',
    'shiptocity',
    'shiptopostcode',
  ];

  const formatDateForInput = (dateStr) => {
    if (!dateStr) return null;

    if (dateStr instanceof Date) {
      return dateStr;
    }

    if (typeof dateStr === 'string') {
      const [year, month, day] = dateStr.split('-').map((part) => parseInt(part, 10));
      // Create a date object at noon UTC to avoid any timezone-related shifts
      return new Date(Date.UTC(year, month - 1, day, 12));
    }

    return null;
  };

  const labelStyles = {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.baseColors.grey,
  };
  return (
    <Box mt={2}>
      {isEditable && isPermanent ? (
        <ReadOnlyInput
          label={label}
          name={name}
          value={value}
          showLabel={showLabel}
          labelStyles={labelStyles}
          inputStyles={{
            backgroundColor:
              highlightable && relatedFields?.includes(name)
                ? theme.palette.primaryColors[100]
                : '',
          }}
        />
      ) : isEditable && isTaxTreatment ? (
        <TaxTreatmentDropdown
          label={label}
          name={name}
          value={value}
          data={data}
          theme={theme}
          onChange={onChange}
          relatedFields={relatedFields}
        />
      ) : isEditable && isDatePicker ? (
        <Datepicker
          label={label}
          name={name}
          value={formatDateForInput(value)}
          data={data}
          theme={theme}
          onChange={onChange}
          relatedFields={relatedFields}
          error={!value && !nullableValues.includes(name) ? 'This field cannot be empty' : null}
          isEditable={false}
        />
      ) : isEditable & isCurrency ? (
        <CurrencyInput
          label={label}
          name={name}
          value={value}
          theme={theme}
          onChange={onChange}
          showLabel={showLabel}
          labelStyles={labelStyles}
          inputStyles={{
            backgroundColor:
              highlightable && relatedFields?.includes(name)
                ? theme.palette.primaryColors[100]
                : '',
          }}
          currencyCode={currencyCode}
          error={
            value === '' && !nullableValues.includes(name) ? 'This field cannot be empty' : null
          }
        />
      ) : isEditable ? (
        <EditableInput
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          showLabel={showLabel}
          labelStyles={labelStyles}
          inputStyles={{
            backgroundColor:
              highlightable && relatedFields?.includes(name)
                ? theme.palette.primaryColors[100]
                : '',
          }}
          error={
            (!value || value.trim().length === 0) && !nullableValues.includes(name)
              ? 'This field cannot be empty'
              : null
          }
        />
      ) : (
        <Stack
          component='form'
          gap='0.5rem'>
          {showLabel && (
            <InputLabel
              sx={{
                fontSize: '.875rem',
                fontWeight: 700,
                cursor: 'pointer',
                color: theme.palette.baseColors.grey,
                width: 'max-content',
              }}>
              {label}
            </InputLabel>
          )}
          <Typography
            sx={{
              fontSize: '.875rem',
              fontWeight: name?.includes('gross') ? 700 : 400,
              fontStyle: name?.includes('gross') ? 'italic' : '',
              color: theme.palette.baseColors.grey,
              minHeight: '25px',
              lineHeight: '20px',
              padding: '5px 0px',
              backgroundColor:
                highlightable && relatedFields?.includes(name)
                  ? theme.palette.primaryColors[100]
                  : '',
              ...fieldStyles,
            }}>
            {hideIfNull && value === null
              ? ''
              : isCurrency
              ? formatAsCurrency(
                  data[name],
                  data[
                    `${
                      name?.startsWith('statutory')
                        ? 'statutory_reporting_currency'
                        : 'compliance_currency'
                    }`
                  ]
                )
              : isDatePicker
              ? dayjs(isPermanent ? value : originalFormData?.[name])?.format('DD MMM YYYY')
              : isPermanent
              ? value
              : originalFormData?.[name]}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
