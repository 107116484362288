import React, { useState, useMemo, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, Chip, Typography, styled, useTheme, Stack } from '@mui/material';
import { BtnLink } from '../../components/common/Buttons/BtnLink';
import { useUser } from '../../context';
import { Cross1Icon } from '@radix-ui/react-icons';
import { formatDate } from '../../utils';
import FilterButtons from './FilterButtons';
import { DashboardSelect } from './DashboardSelect';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import { parse, set, sub } from 'date-fns';

const CustomChip = styled(Chip)(({ theme, active }) => ({
  marginRight: '1rem',
  color: theme.palette.baseColors.white,
  backgroundColor: theme.palette.grey[900],
  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
  padding: '1rem 0.5rem',
  borderRadius: '5px',
  height: '2.25rem',
  '.MuiChip-deleteIcon': {
    color: theme.palette.baseColors.white,
  },
  fontSize: '.875rem',
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  flexShrink: 0,
}));

const predefinedRanges = [
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  },
  {
    label: 'Last 3 months',
    value: [startOfMonth(addMonths(new Date(), -4)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  },
  {
    label: 'Last 6 months',
    value: [startOfMonth(addMonths(new Date(), -7)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  },
  {
    label: 'Last 12 months',
    value: [startOfMonth(addMonths(new Date(), -13)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  },
  {
    label: 'Current FY',
    value: [
      sub(set(new Date(), { month: 6, date: 1 }), { years: 1 }),
      set(new Date(), { month: 5, date: 30 }),
    ],
    placement: 'left',
  },
  {
    label: 'Last FY',
    value: [
      sub(set(new Date(), { month: 6, date: 1 }), { years: 2 }),
      sub(set(new Date(), { month: 5, date: 30 }), { years: 1 }),
    ],
    placement: 'left',
  },

  /* {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left',
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left',
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left',
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left',
  }, */
];

const StyledDateRangePicker = ({
  value,
  setValue,
  displayFormat = 'dd-MM-yyyy',
  disabled = false,
  placeholder = '',
  sx,
}) => {
  const theme = useTheme();
  // RSuite style overrides.
  useEffect(() => {
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-50', theme.palette.primaryColors[100]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-500', theme.palette.primaryColors[200]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-100', theme.palette.primaryColors[300]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-700', theme.palette.primaryColors[400]);
    document
      .querySelector(':root')
      .style.setProperty('--rs-primary-600', theme.palette.primaryColors[400]);
  }, []);

  return (
    <DateRangePicker
      value={value}
      onChange={setValue}
      placement='auto'
      disabled={disabled}
      showHeader={false}
      caretAs={ChevronDownIcon}
      size='lg'
      format={displayFormat}
      ranges={predefinedRanges}
      placeholder={placeholder}
      style={{
        width: '100%',
        borderRadius: '0',
        ...sx,
      }}
    />
  );
};

export const DashboardFilter = forwardRef(
  (
    {
      filterOptions,
      isLoading,
      regionCountryData,
      filters,
      updateFilters,
      defaultFilters,
      defaultDates,
      styling,
    },
    ref
  ) => {
    const theme = useTheme();

    const sortAlphabetically = (s1, s2) => s1.localeCompare(s2);
    const regions = filterOptions?.region
      ?.flatMap((region) => region.code)
      ?.sort(sortAlphabetically);
    const countries = filterOptions?.country
      ?.flatMap((country) => country.description)
      ?.sort(sortAlphabetically);
    const entities = filterOptions?.entity
      ?.flatMap((entity) => entity.description)
      ?.sort(sortAlphabetically);

    // Function to find region by country. Checks all region objects to see if any has the country.
    // Has to use extra API call from parent.
    const findRegionByCountry = (country) =>
      regionCountryData?.regions.find((region) =>
        regionCountryData[region.id].some((c) => c.desc === country)
      )?.id;

    const handleChange = (filter, event) => {
      updateFilters((draft) => {
        // When the filter is not a date range, the event parameter is a PointerEvent. Otherwise, it's just a raw value.
        const value = filter !== 'date' ? event.target.value : event;
        if (filter !== 'date' && value) draft[filter] = value;

        switch (filter) {
          case 'date':
            draft.dateRange = value ? [...value] : [...defaultDates];
            break;
          case 'region':
            draft.country = '';
            draft.entity = '';
            break;
          case 'country':
            draft.region = filters.region || findRegionByCountry(value);
            break;
          case 'entity':
            break;
          default:
            console.error('Unknown filter!', filter);
            break;
        }
      });
    };

    const clearFilter = (filter) => {
      updateFilters((draft) => {
        switch (filter) {
          // Use fall-through.
          case 'region':
            draft.region = undefined;
          case 'country':
            draft.country = undefined;
          case 'entity':
            draft.entity = undefined;
            break;
          default:
            console.err('Unknown filter!', filter);
            break;
        }
      });
    };

    const clearAll = () => {
      updateFilters((draft) => {
        Object.assign(draft, defaultFilters);
        draft.dateRange = defaultDates;
      });
    };

    useImperativeHandle(ref, () => ({
      overrideRange: (range) => {
        updateFilters((draft) => {
          draft.dateRange = [...range];
        });
      },
    }));

    const hasAnyFilters = filters?.region || filters?.country || filters?.entity;

    return (
      <Box
        sx={{
          height: 'auto',
          width: 1,
          mb: '1.5rem',
          ...styling,
        }}>
        <Typography
          sx={{
            fontSize: '1.125rem',
            fontWeight: 500,
            mb: '.75rem',
          }}>
          Filter
        </Typography>
        <Container sx={{ justifyContent: 'space-between', alignContent: 'center' }}>
          <Stack
            direction='row'
            gap='1rem'>
            {/* Date Range Picker */}
            <Box
              height='3.25rem'
              width='15rem'>
              <StyledDateRangePicker
                value={filters?.dateRange}
                setValue={(value) => {
                  handleChange('date', value);
                }}
                returnFormat='dd-MM-yyyy'
              />
            </Box>

            {/* Region Select */}
            <DashboardSelect
              labelId='region-select-label'
              id='region-select'
              name='region'
              value={filters.region || ''}
              label='Region'
              onChange={(value) => {
                handleChange('region', value);
              }}
              options={regions}
              loading={isLoading}
            />

            {/* Country Select */}
            <DashboardSelect
              labelId='country-select-label'
              id='country-select'
              name='country'
              value={filters.country || ''}
              label='Country'
              onChange={(value) => {
                handleChange('country', value);
              }}
              options={countries}
              loading={isLoading}
            />

            {/* Entity Select */}
            <DashboardSelect
              labelId='entity-select-label'
              id='entity-select'
              name='entity'
              value={filters.entity || ''}
              disabled={!filters.country}
              label='Entity'
              onChange={(value) => {
                handleChange('entity', value);
              }}
              options={entities}
              loading={isLoading}
            />
          </Stack>
          <FilterButtons
            filters={filters}
            updateFilters={updateFilters}
          />
        </Container>

        {hasAnyFilters && (
          <Stack
            direction='row'
            flexWrap='wrap'
            width={1}
            alignItems='center'
            alignContent='center'
            mt='1rem'>
            {filters?.region && (
              <CustomChip
                key={filters.region}
                label={filters.region}
                onDelete={() => {
                  clearFilter('region');
                }}
                deleteIcon={
                  <Cross1Icon
                    sx={{
                      marginLeft: '0.5rem',
                      color: theme.palette.baseColors.white,
                    }}
                  />
                }
              />
            )}
            {filters?.country && (
              <CustomChip
                key={filters.country}
                label={filters.country}
                onDelete={() => {
                  clearFilter('country');
                }}
                deleteIcon={
                  <Cross1Icon
                    sx={{
                      marginLeft: '0.5rem',
                      color: theme.palette.baseColors.white,
                    }}
                  />
                }
              />
            )}
            {filters?.entity && (
              <CustomChip
                key={filters.entity}
                label={filters.entity}
                onDelete={() => {
                  clearFilter('entity');
                }}
                deleteIcon={
                  <Cross1Icon
                    sx={{
                      marginLeft: '0.5rem',
                      color: theme.palette.baseColors.white,
                    }}
                  />
                }
              />
            )}
            {hasAnyFilters && (
              <Typography>
                <BtnLink
                  size='small'
                  btnTxt='Clear all'
                  styling={{
                    textDecoration: 'underline',
                    fontSize: '1rem',
                    color: theme.palette.baseColors.grey,
                  }}
                  onClick={clearAll}
                />
              </Typography>
            )}
          </Stack>
        )}
      </Box>
    );
  },
  []
);
