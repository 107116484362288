import { Stack, Grid, Typography } from "@mui/material";
import { WhiteCard } from "../WhiteCard";
import { ActionCard } from "./ActionCard";
import { useTheme } from "@mui/material";
import { useObligation } from "../../../context";
import { GENERAL_ANALYTICS_CARDS_DATA, OPERATING_ANALYTICS_CARDS_DATA } from "./constants";

export const Analytics = () => {
    const theme = useTheme();
    const { selectedObgDetails } = useObligation()
    // const obligation = selectedObgDetails
    return (
        <Stack spacing={"2.5rem"}>
            <WhiteCard>
                <Typography
                    component="h4"
                    sx={{
                        color: theme.palette.random[300], fontSize: '1.125rem',
                        fontWeight: '600',
                    }}
                >
                    General Analytics
                </Typography>
                <Grid
                    container
                    spacing={"1.5rem"}
                    columns={4}
                    direction="row"
                >
                    {GENERAL_ANALYTICS_CARDS_DATA?.map(obj => (
                        <Grid item xs={1} key={obj.title} width='25%'>
                            <ActionCard
                                title={obj.title}
                                text={obj.description}
                                link={{
                                    text: "View Analytics >",
                                    url: `/obligations/${selectedObgDetails?.obligation_id}/${selectedObgDetails?.member}/analytics${obj.navigate_to}`
                                }}
                            >
                                {obj.icon}
                            </ActionCard>
                        </Grid>
                    ))}
                </Grid>
            </WhiteCard>

            <WhiteCard>
                <Typography
                    component="h4"
                    sx={{
                        color: theme.palette.random[300], fontSize: '1.125rem',
                        fontWeight: '600'
                    }}
                >
                    Operating Analytics
                </Typography>
                <Grid
                    container
                    spacing={"1.5rem"}
                    columns={4}
                    direction="row"
                    alignItems="stretch"
                >
                    {OPERATING_ANALYTICS_CARDS_DATA?.map(obj => (
                        <Grid item xs={1} key={obj.title} width='25%'>
                            <ActionCard
                                title={obj.title}
                                text={obj.description}
                                link={{
                                    text: "View Analytics >",
                                    url: `/obligations/${selectedObgDetails?.obligation_id}/${selectedObgDetails?.member}/analytics${obj.navigate_to}`
                                }}
                            >
                                {obj.icon}
                            </ActionCard>
                        </Grid>
                    ))}
                </Grid>
            </WhiteCard>
        </Stack>
    );
};