import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Stack, Typography } from "@mui/material";
import { useUser } from "../../../context/user";
import { useTheme } from "@emotion/react";
import { useNavigate } from 'react-router-dom'

const CardTypeC = () => {
  let arr = ["A", "s", "d", "f", "g", "a"];
  const { userToken } = useUser();
  const [loading, setLoading] = useState(true);
  const [Tabdata, setData] = useState([]);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (Tabdata?.length === 0) {
      setIsError(false);
      fetch(process.env.REACT_APP_BACKEND_URL + `/obligationentities/listall`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        credentials: "include",
      })
        .then((res) => {

          return res.json()
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setIsError(true);
        });
    }
  }, []);
  const theme = useTheme()
  return (
    <Stack
      sx={{
        minHeight: "100%",
        width: "100%",
        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
        backgroundColor: theme.palette.baseColors.white,
        borderRadius: "0.5rem",
        padding: "0.9rem 2rem 0.9rem 1.5rem ",
      }}
    >
      <Typography sx={{ display: "flex" }}>
        <h3 style={{ marginTop: "0", marginBottom: "1vh", fontSize: "115%" }}>Your Entities</h3>{" "}
        <Typography
          sx={{
            ml: "0.5rem",

            color: "rgba(165, 165, 165, 1)",
            fontSize: "115%",
          }}
        >

          {Tabdata.status === "success" ? `(${Tabdata.count})` : null}
        </Typography>
      </Typography>

      <Stack
        sx={{
          width: "95%",
          height: "95%",

          margin: "auto",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0 1vw 0 0vw",
        }}
      >
        {loading && (
          <>
            {" "}
            {arr.map((a) => {
              return <Skeleton variant="text" sx={{ fontSize: "6VW" }} />;
            })}
          </>
        )}
        {
          !loading &&
          Tabdata?.obligationusers?.length > 0 && (
            <>
              {Tabdata?.obligationusers?.map((d) => {
                return (
                  <Stack
                    sx={{
                      borderBottom: `1px solid ${theme.palette.neutralColors.black[100]} `,
                      maxHeight: "auto",
                      paddingBottom: "1vh",
                      mt: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "62.5%",
                        fontWeight: "bold",
                        backgroundColor: theme.palette.neutralColors.black[100],
                        color: theme.palette.random[100],
                        width: "fit-content",
                        padding: "0 0.2rem 0 0.2rem",
                      }}
                    >
                      {d.group_name}

                    </Typography>
                    <Typography sx={{ fontSize: "87.5%", mt: "1vh", color: `1px solid ${theme.palette.baseColors.grey} ` }}>
                      {d.entity.name}

                    </Typography>
                  </Stack>
                );
              })}
            </>
          )}
        {!loading && Tabdata?.obligationusers?.length === 0 && <Typography sx={{ textAlign: "center", width: 1, fontSize: ".875rem" }}>
          {"No data to display"}
        </Typography>}
        {!loading && isError && <Typography sx={{ textAlign: "center", width: 1, fontSize: ".875rem" }}>
          Error fetching data
        </Typography>}
      </Stack>
    </Stack>
  );
};

export default CardTypeC;
