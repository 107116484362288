import React from "react";
import { Box, Typography } from "@mui/material";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme, selected }) => ({
  marginTop: "15px",
  padding: "10px",
  backgroundColor: selected ? theme.palette.primaryColors[100] : "inherit",
  "&:before": {
    backgroundColor: "transparent",
  },
  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
  borderRadius: "8px",
  boxShadow: "none",
}));

export const TaxTreatments = ({ taxTreatments }) => (
  <>
    {taxTreatments.map((issue, index) => (
      <StyledBox
        expandIcon={<CaretDownIcon />}
        aria-controls={`panel${index}bh-content`}
        id={`panel${index}bh-header`}
      >
        <Typography
          sx={{ fontSize: "0.875rem", fontWeight: 700, flexShrink: 0 }}
        >
          {issue}
        </Typography>
      </StyledBox>
    ))}
  </>
);
