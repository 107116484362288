export const DUMMY_OBLIGATIONS_DATA = [
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Apr 2023",
        "start_date": "2023-04-01",
        "end_date": "2023-04-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Completed",
        "stage_status": null,
        "due_date": "2023-05-21",
        "obligation_status": "",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Apr 2023",
        "start_date": "2023-04-01",
        "end_date": "2023-04-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Completed",
        "stage_status": null,
        "due_date": "2023-05-21",
        "obligation_status": "",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Apr 2023",
        "start_date": "2023-04-01",
        "end_date": "2023-04-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Review",
        "stage_status": "IN_PROGRESS",
        "due_date": "2023-05-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jan 2024",
        "start_date": "2024-01-01",
        "end_date": "2024-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Review",
        "stage_status": "IN_PROGRESS",
        "due_date": "2023-05-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "1946b52d-cd47-4aa1-a2e5-4b486e601c7c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2024",
        "start_date": "2023-10-01",
        "end_date": "2023-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-05-21",
        "obligation_status": "Due within 68 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "e86e1c9b-9b1c-49fa-8133-3dc4f4b9407e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Apr 2024",
        "start_date": "2024-04-01",
        "end_date": "2024-04-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-05-21",
        "obligation_status": "Due within 68 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "e86e1c9b-9b1c-49fa-8133-3dc4f4b9407e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Apr 2024",
        "start_date": "2024-04-01",
        "end_date": "2024-04-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-05-21",
        "obligation_status": "Due within 68 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "e86e1c9b-9b1c-49fa-8133-3dc4f4b9407e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Apr 2024",
        "start_date": "2024-04-01",
        "end_date": "2024-04-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-05-21",
        "obligation_status": "Due within 68 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "e86e1c9b-9b1c-49fa-8133-3dc4f4b9407e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Aug 2023",
        "start_date": "2023-08-01",
        "end_date": "2023-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-09-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "1b247121-a408-4d23-a4c1-a0b3b113966c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Aug 2023",
        "start_date": "2023-08-01",
        "end_date": "2023-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-09-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "1b247121-a408-4d23-a4c1-a0b3b113966c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Aug 2023",
        "start_date": "2023-08-01",
        "end_date": "2023-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-09-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "1b247121-a408-4d23-a4c1-a0b3b113966c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Aug 2023",
        "start_date": "2023-08-01",
        "end_date": "2023-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-09-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "1b247121-a408-4d23-a4c1-a0b3b113966c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Aug 2024",
        "start_date": "2024-08-01",
        "end_date": "2024-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-09-21",
        "obligation_status": "Due within 191 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "f3d0c9ab-cc79-4e86-831d-617c7ad653e6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Aug 2024",
        "start_date": "2024-08-01",
        "end_date": "2024-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-09-21",
        "obligation_status": "Due within 191 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "f3d0c9ab-cc79-4e86-831d-617c7ad653e6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Aug 2024",
        "start_date": "2024-08-01",
        "end_date": "2024-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-09-21",
        "obligation_status": "Due within 191 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "f3d0c9ab-cc79-4e86-831d-617c7ad653e6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Aug 2024",
        "start_date": "2024-08-01",
        "end_date": "2024-08-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Finalized",
        "stage_status": "COMPLETED",
        "due_date": "2024-09-21",
        "obligation_status": "",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "f3d0c9ab-cc79-4e86-831d-617c7ad653e6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Dec 2023",
        "start_date": "2023-12-01",
        "end_date": "2023-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-01-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "4fe87623-0759-42ec-9975-fde67ed3c51d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Dec 2023",
        "start_date": "2023-12-01",
        "end_date": "2023-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-01-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "4fe87623-0759-42ec-9975-fde67ed3c51d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Dec 2023",
        "start_date": "2023-12-01",
        "end_date": "2023-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-01-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "4fe87623-0759-42ec-9975-fde67ed3c51d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Dec 2023",
        "start_date": "2023-12-01",
        "end_date": "2023-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-01-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "4fe87623-0759-42ec-9975-fde67ed3c51d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Dec 2024",
        "start_date": "2024-12-01",
        "end_date": "2024-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-01-21",
        "obligation_status": "Due within 313 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Dec 2024",
        "start_date": "2024-12-01",
        "end_date": "2024-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-01-21",
        "obligation_status": "Due within 313 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Dec 2024",
        "start_date": "2024-12-01",
        "end_date": "2024-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Completed",
        "stage_status": null,
        "due_date": "2025-01-21",
        "obligation_status": "",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Dec 2024",
        "start_date": "2024-12-01",
        "end_date": "2024-12-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Completed",
        "stage_status": null,
        "due_date": "2025-01-21",
        "obligation_status": "",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Feb 2023",
        "start_date": "2023-02-01",
        "end_date": "2023-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-03-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "0c3d0812-3009-4e0b-b17d-8647c68271ea",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2023",
        "start_date": "2023-02-01",
        "end_date": "2023-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-03-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "0c3d0812-3009-4e0b-b17d-8647c68271ea",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2023",
        "start_date": "2023-02-01",
        "end_date": "2023-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-03-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "0c3d0812-3009-4e0b-b17d-8647c68271ea",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2023",
        "start_date": "2023-02-01",
        "end_date": "2023-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-03-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "0c3d0812-3009-4e0b-b17d-8647c68271ea",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Feb 2024",
        "start_date": "2024-02-01",
        "end_date": "2024-02-29",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-03-21",
        "obligation_status": "Due within 14 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "c30cffe4-d5bf-432b-be29-ae420945e86e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2024",
        "start_date": "2024-02-01",
        "end_date": "2024-02-29",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-03-21",
        "obligation_status": "Due within 14 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "c30cffe4-d5bf-432b-be29-ae420945e86e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2024",
        "start_date": "2024-02-01",
        "end_date": "2024-02-29",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-03-21",
        "obligation_status": "Due within 14 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "c30cffe4-d5bf-432b-be29-ae420945e86e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2024",
        "start_date": "2024-02-01",
        "end_date": "2024-02-29",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-03-21",
        "obligation_status": "Due within 14 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "c30cffe4-d5bf-432b-be29-ae420945e86e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2025",
        "start_date": "2025-02-01",
        "end_date": "2025-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Completed",
        "stage_status": null,
        "due_date": "2025-03-21",
        "obligation_status": "",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "94750151-9d36-469c-b923-5c455ef929a9",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2025",
        "start_date": "2025-02-01",
        "end_date": "2025-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Approve",
        "stage_status": "IN_PROGRESS",
        "due_date": "2025-03-21",
        "obligation_status": "Due within 372 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "94750151-9d36-469c-b923-5c455ef929a9",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Feb 2025",
        "start_date": "2025-02-01",
        "end_date": "2025-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Completed",
        "stage_status": null,
        "due_date": "2025-03-21",
        "obligation_status": "",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "94750151-9d36-469c-b923-5c455ef929a9",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Feb 2025",
        "start_date": "2025-02-01",
        "end_date": "2025-02-28",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Prepare",
        "stage_status": "IN_PROGRESS",
        "due_date": "2025-03-21",
        "obligation_status": "Due within 372 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "94750151-9d36-469c-b923-5c455ef929a9",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2023",
        "start_date": "2023-01-01",
        "end_date": "2023-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "65559923-94c9-42b7-bf9d-b93ca57221c1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jan 2023",
        "start_date": "2023-01-01",
        "end_date": "2023-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "65559923-94c9-42b7-bf9d-b93ca57221c1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2023",
        "start_date": "2023-01-01",
        "end_date": "2023-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "65559923-94c9-42b7-bf9d-b93ca57221c1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2023",
        "start_date": "2023-01-01",
        "end_date": "2023-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "65559923-94c9-42b7-bf9d-b93ca57221c1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2024",
        "start_date": "2024-01-01",
        "end_date": "2024-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Review",
        "stage_status": "IN_PROGRESS",
        "due_date": "2024-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "c8d995f7-2e7b-4939-9d6f-dffcb8d2f165",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jan 2024",
        "start_date": "2024-01-01",
        "end_date": "2024-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "c8d995f7-2e7b-4939-9d6f-dffcb8d2f165",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2024",
        "start_date": "2024-01-01",
        "end_date": "2024-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "c8d995f7-2e7b-4939-9d6f-dffcb8d2f165",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2024",
        "start_date": "2024-01-01",
        "end_date": "2024-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-02-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "c8d995f7-2e7b-4939-9d6f-dffcb8d2f165",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2025",
        "start_date": "2025-01-01",
        "end_date": "2025-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-02-21",
        "obligation_status": "Due within 344 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "85094f5e-1863-4ae2-aba4-3e7ba184363f",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jan 2025",
        "start_date": "2025-01-01",
        "end_date": "2025-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-02-21",
        "obligation_status": "Due within 344 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "85094f5e-1863-4ae2-aba4-3e7ba184363f",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2025",
        "start_date": "2025-01-01",
        "end_date": "2025-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-02-21",
        "obligation_status": "Due within 344 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "85094f5e-1863-4ae2-aba4-3e7ba184363f",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Jan 2025",
        "start_date": "2025-01-01",
        "end_date": "2025-01-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-02-21",
        "obligation_status": "Due within 344 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "85094f5e-1863-4ae2-aba4-3e7ba184363f",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Jul 2023",
        "start_date": "2023-07-01",
        "end_date": "2023-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-08-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "f3cadf8f-dc60-44ad-86b3-9295cadb03a6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jul 2023",
        "start_date": "2023-07-01",
        "end_date": "2023-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-08-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "f3cadf8f-dc60-44ad-86b3-9295cadb03a6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jul 2023",
        "start_date": "2023-07-01",
        "end_date": "2023-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-08-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "f3cadf8f-dc60-44ad-86b3-9295cadb03a6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Jul 2023",
        "start_date": "2023-07-01",
        "end_date": "2023-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-08-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "f3cadf8f-dc60-44ad-86b3-9295cadb03a6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jul 2024",
        "start_date": "2024-07-01",
        "end_date": "2024-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-08-21",
        "obligation_status": "Due within 160 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "1650d583-bd26-46bf-a34f-c79bc542fa5c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Jul 2024",
        "start_date": "2024-07-01",
        "end_date": "2024-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-08-21",
        "obligation_status": "Due within 160 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "1650d583-bd26-46bf-a34f-c79bc542fa5c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jul 2024",
        "start_date": "2024-07-01",
        "end_date": "2024-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-08-21",
        "obligation_status": "Due within 160 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "1650d583-bd26-46bf-a34f-c79bc542fa5c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Jul 2024",
        "start_date": "2024-07-01",
        "end_date": "2024-07-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-08-21",
        "obligation_status": "Due within 160 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "1650d583-bd26-46bf-a34f-c79bc542fa5c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jun 2023",
        "start_date": "2023-06-01",
        "end_date": "2023-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-07-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "01288b6c-c8ac-4ca0-bc09-3fe9be326869",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Jun 2023",
        "start_date": "2023-06-01",
        "end_date": "2023-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-07-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "01288b6c-c8ac-4ca0-bc09-3fe9be326869",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jun 2023",
        "start_date": "2023-06-01",
        "end_date": "2023-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-07-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "01288b6c-c8ac-4ca0-bc09-3fe9be326869",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Jun 2023",
        "start_date": "2023-06-01",
        "end_date": "2023-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-07-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "01288b6c-c8ac-4ca0-bc09-3fe9be326869",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Jun 2024",
        "start_date": "2024-06-01",
        "end_date": "2024-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-07-21",
        "obligation_status": "Due within 129 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "0ccab5f5-2af5-4d48-af07-453e1a445fe8",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Jun 2024",
        "start_date": "2024-06-01",
        "end_date": "2024-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-07-21",
        "obligation_status": "Due within 129 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "0ccab5f5-2af5-4d48-af07-453e1a445fe8",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Jun 2024",
        "start_date": "2024-06-01",
        "end_date": "2024-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-07-21",
        "obligation_status": "Due within 129 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "0ccab5f5-2af5-4d48-af07-453e1a445fe8",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Jun 2024",
        "start_date": "2024-06-01",
        "end_date": "2024-06-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-07-21",
        "obligation_status": "Due within 129 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "0ccab5f5-2af5-4d48-af07-453e1a445fe8",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2023",
        "start_date": "2023-03-01",
        "end_date": "2023-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-04-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "8cf77306-962b-4711-9040-b19de5324820",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Mar 2023",
        "start_date": "2023-03-01",
        "end_date": "2023-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-04-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "8cf77306-962b-4711-9040-b19de5324820",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2023",
        "start_date": "2023-03-01",
        "end_date": "2023-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-04-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "8cf77306-962b-4711-9040-b19de5324820",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2023",
        "start_date": "2023-03-01",
        "end_date": "2023-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-04-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "8cf77306-962b-4711-9040-b19de5324820",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Mar 2024",
        "start_date": "2024-03-01",
        "end_date": "2024-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-04-21",
        "obligation_status": "Due within 38 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "9e0ecdb1-fefe-46fb-b2d9-5dea2fa1f88d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2024",
        "start_date": "2024-03-01",
        "end_date": "2024-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-04-21",
        "obligation_status": "Due within 38 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "9e0ecdb1-fefe-46fb-b2d9-5dea2fa1f88d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2024",
        "start_date": "2024-03-01",
        "end_date": "2024-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-04-21",
        "obligation_status": "Due within 38 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "9e0ecdb1-fefe-46fb-b2d9-5dea2fa1f88d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2024",
        "start_date": "2024-03-01",
        "end_date": "2024-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-04-21",
        "obligation_status": "Due within 38 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "9e0ecdb1-fefe-46fb-b2d9-5dea2fa1f88d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2025",
        "start_date": "2025-03-01",
        "end_date": "2025-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-04-21",
        "obligation_status": "Due within 403 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "95772d93-372b-4abe-970a-7d4de9c718f6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2025",
        "start_date": "2025-03-01",
        "end_date": "2025-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-04-21",
        "obligation_status": "Due within 403 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "95772d93-372b-4abe-970a-7d4de9c718f6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Mar 2025",
        "start_date": "2025-03-01",
        "end_date": "2025-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-04-21",
        "obligation_status": "Due within 403 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "95772d93-372b-4abe-970a-7d4de9c718f6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Mar 2025",
        "start_date": "2025-03-01",
        "end_date": "2025-03-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2025-04-21",
        "obligation_status": "Due within 403 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "95772d93-372b-4abe-970a-7d4de9c718f6",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC May 2023",
        "start_date": "2023-05-01",
        "end_date": "2023-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-06-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "0fb197d3-7c3e-4404-805f-c00cb44aa358",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC May 2023",
        "start_date": "2023-05-01",
        "end_date": "2023-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-06-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "0fb197d3-7c3e-4404-805f-c00cb44aa358",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC May 2023",
        "start_date": "2023-05-01",
        "end_date": "2023-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-06-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "0fb197d3-7c3e-4404-805f-c00cb44aa358",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC May 2023",
        "start_date": "2023-05-01",
        "end_date": "2023-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-06-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "0fb197d3-7c3e-4404-805f-c00cb44aa358",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC May 2024",
        "start_date": "2024-05-01",
        "end_date": "2024-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-06-21",
        "obligation_status": "Due within 99 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "9f1897e5-0b07-4798-a165-32bcfa78ed60",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC May 2024",
        "start_date": "2024-05-01",
        "end_date": "2024-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-06-21",
        "obligation_status": "Due within 99 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "9f1897e5-0b07-4798-a165-32bcfa78ed60",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC May 2024",
        "start_date": "2024-05-01",
        "end_date": "2024-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-06-21",
        "obligation_status": "Due within 99 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "9f1897e5-0b07-4798-a165-32bcfa78ed60",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC May 2024",
        "start_date": "2024-05-01",
        "end_date": "2024-05-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-06-21",
        "obligation_status": "Due within 99 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "9f1897e5-0b07-4798-a165-32bcfa78ed60",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Nov 2023",
        "start_date": "2023-11-01",
        "end_date": "2023-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-12-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "8abc4a0b-a36b-4ead-949e-480d54ceb4fe",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Nov 2023",
        "start_date": "2023-11-01",
        "end_date": "2023-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-12-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "8abc4a0b-a36b-4ead-949e-480d54ceb4fe",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Nov 2023",
        "start_date": "2023-11-01",
        "end_date": "2023-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-12-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "8abc4a0b-a36b-4ead-949e-480d54ceb4fe",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Nov 2023",
        "start_date": "2023-11-01",
        "end_date": "2023-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-12-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "8abc4a0b-a36b-4ead-949e-480d54ceb4fe",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Nov 2024",
        "start_date": "2024-11-01",
        "end_date": "2024-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-12-21",
        "obligation_status": "Due within 282 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "71c0cd6a-c961-4682-8ed6-01ec006daed1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Nov 2024",
        "start_date": "2024-11-01",
        "end_date": "2024-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-12-21",
        "obligation_status": "Due within 282 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "71c0cd6a-c961-4682-8ed6-01ec006daed1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Nov 2024",
        "start_date": "2024-11-01",
        "end_date": "2024-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-12-21",
        "obligation_status": "Due within 282 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "71c0cd6a-c961-4682-8ed6-01ec006daed1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Nov 2024",
        "start_date": "2024-11-01",
        "end_date": "2024-11-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-12-21",
        "obligation_status": "Due within 282 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "71c0cd6a-c961-4682-8ed6-01ec006daed1",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Oct 2023",
        "start_date": "2023-10-01",
        "end_date": "2023-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Approve",
        "stage_status": "IN_PROGRESS",
        "due_date": "2023-11-21",
        "obligation_status": "OVERDUE",
        "new_data": true,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "ca274b2f-0384-45cf-9ddb-49e38a8ed47c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Oct 2023",
        "start_date": "2023-10-01",
        "end_date": "2023-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-11-21",
        "obligation_status": "OVERDUE",
        "new_data": true,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "ca274b2f-0384-45cf-9ddb-49e38a8ed47c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Oct 2023",
        "start_date": "2023-10-01",
        "end_date": "2023-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Review",
        "stage_status": "IN_PROGRESS",
        "due_date": "2023-11-21",
        "obligation_status": "OVERDUE",
        "new_data": true,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "ca274b2f-0384-45cf-9ddb-49e38a8ed47c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Oct 2023",
        "start_date": "2023-10-01",
        "end_date": "2023-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Completed",
        "stage_status": null,
        "due_date": "2023-11-21",
        "obligation_status": "",
        "new_data": true,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "ca274b2f-0384-45cf-9ddb-49e38a8ed47c",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Oct 2024",
        "start_date": "2024-10-01",
        "end_date": "2024-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-11-21",
        "obligation_status": "Due within 252 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "a65eea86-030d-4cb0-9952-54531800379d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Oct 2024",
        "start_date": "2024-10-01",
        "end_date": "2024-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-11-21",
        "obligation_status": "Due within 252 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "a65eea86-030d-4cb0-9952-54531800379d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Oct 2024",
        "start_date": "2024-10-01",
        "end_date": "2024-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-11-21",
        "obligation_status": "Due within 252 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "a65eea86-030d-4cb0-9952-54531800379d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Oct 2024",
        "start_date": "2024-10-01",
        "end_date": "2024-10-31",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-11-21",
        "obligation_status": "Due within 252 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "a65eea86-030d-4cb0-9952-54531800379d",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Sep 2023",
        "start_date": "2023-09-01",
        "end_date": "2023-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-10-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "d8a78af6-b62b-45cb-91a9-3eea1a588f4e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Sep 2023",
        "start_date": "2023-09-01",
        "end_date": "2023-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-10-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "d8a78af6-b62b-45cb-91a9-3eea1a588f4e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Sep 2023",
        "start_date": "2023-09-01",
        "end_date": "2023-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-10-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "d8a78af6-b62b-45cb-91a9-3eea1a588f4e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Sep 2023",
        "start_date": "2023-09-01",
        "end_date": "2023-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2023-10-21",
        "obligation_status": "OVERDUE",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "d8a78af6-b62b-45cb-91a9-3eea1a588f4e",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Financial Advisory Pty Ltd",
        "obligation_name": "AU GST PwC Sep 2024",
        "start_date": "2024-09-01",
        "end_date": "2024-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-10-21",
        "obligation_status": "Due within 221 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0003",
        "obligation_id": "e2f07500-bd59-4d03-a58f-fb2e1b1276aa",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Consulting Pty Ltd",
        "obligation_name": "AU GST PwC Sep 2024",
        "start_date": "2024-09-01",
        "end_date": "2024-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-10-21",
        "obligation_status": "Due within 221 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0001",
        "obligation_id": "e2f07500-bd59-4d03-a58f-fb2e1b1276aa",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Group",
        "obligation_name": "AU GST PwC Sep 2024",
        "start_date": "2024-09-01",
        "end_date": "2024-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-10-21",
        "obligation_status": "Due within 221 days",
        "new_data": null,
        "org_heirarchy_code": "17aec25e-9560-4f60-8c6e-42f1ee34ea80",
        "obligation_id": "e2f07500-bd59-4d03-a58f-fb2e1b1276aa",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    },
    {
        "member": "PwC AU Assurance Pty Ltd",
        "obligation_name": "AU GST PwC Sep 2024",
        "start_date": "2024-09-01",
        "end_date": "2024-09-30",
        "tax_type": "IDT",
        "obligation_group_name": "PwC AU Group",
        "region": "APAC",
        "country": "Australia",
        "stage": "Data Load",
        "stage_status": "NOT_STARTED",
        "due_date": "2024-10-21",
        "obligation_status": "Due within 221 days",
        "new_data": null,
        "org_heirarchy_code": "PwC0002",
        "obligation_id": "e2f07500-bd59-4d03-a58f-fb2e1b1276aa",
        "obligation_group_id": "17aec25e-9560-4f60-8c6e-42f1ee34ea80"
    }
]

export const DUMMY_COUNTS_DATA = {
	"all" : 218,
	"open" : 10 ,
	"overdue" : 5 ,
	"approved" : 4
}

export const DUMMY_REGIONS_DATA = {
    "regions": [
        {
            "id": "LACA",
            "desc": "Latin-America-and-Carribean"
        },
        {
            "id": "EMEA",
            "desc": "Europe-MiddleEast-Africa"
        },
        {
            "id": "APAC",
            "desc": "Asia Pacific"
        },
        {
            "id": "NA",
            "desc": "North America"
        }
    ],
    "LACA": [],
    "EMEA": [],
    "APAC": [
        {
            "id": "AU",
            "desc": "Australia"
        },
        {
            "id": "IN",
            "desc": "India"
        },
        {
            "id": "HK",
            "desc": "Hongkong"
        },
        {
            "id": "CHN",
            "desc": "China"
        },
        {
            "id": "INDN",
            "desc": "Indonesia"
        }
    ],
    "NA": []
}

export const DUMMY_GROUP_NAMES = ["PWC AU Group", "PWC AU Group 2", "PWC AU Group 3"]
export const DUMMY_MEMBER_NAMES = ["PWC AU Group", "PwC AU Assurance Pty Ltd", "PwC AU Consulting Pty Ltd", "PwC AU Financial Advisory Pty Ltd"]

export const DUMMY_DATATAB_DATA = {
    summary: {
        Total_transaction_loaded: 83288,
        files_uploaded: 5,
        Total_import_errors: 4,
        errorLogContent: "hhhh"
    },
    detailed_summary: {
        accounts_receivable: {
          uploaded: false,
          import_date: "NA",
          import_errors: 0,
          not_reportable: 0,
          total_transactions: 0,
          new_transactions: 0,
        },
        accounts_payable: {
          uploaded: false,
          import_date: "NA",
          import_errors: 0,
          not_reportable: 0,
          total_transactions: 0,
          new_transactions: 0,
        },
        trial_balance: {
          uploaded: false,
          import_date: "NA",
          import_errors: 0,
          new_transactions: 0,
        },
        general_ledger: {
          uploaded: false,
          import_date: "NA",
          import_errors: 0,
          new_transactions: 0,
        },
      },
    files: [
        {
          "Filename": "Account_Payable.xls",
          "Filetype": "xls",
          "AP_lastaccessfile": "AP_21122023_JAN",
          "AP_Datauploaded": "2024-04-03",
          "Uploaded_by": "",
          "Entity": "PwC AU Consulting Pty Ltd"
        },
        {
          "Filename": "Account_Receiable.xls",
          "Filetype": "xls",
          "AR_lastaccessfile": "AR_01222024_Jan-2828",
          "AR_Datauploaded": "2024-04-03",
          "Uploaded_by": "",
          "Entity": "PwC AU Consulting Pty Ltd"
        },
        {
          "Filename": "General_Ledger.xls",
          "Filetype": "xls",
          "GL_lastaccessfile": "GL_GL_Data_VVC",
          "GL_Datauploaded": "2024-04-02",
          "Uploaded_by": "",
          "Entity": "PwC AU Consulting Pty Ltd"
        },
        {
          "Filename": "Trial_Balance.xls",
          "Filetype": "xls",
          "TB_lastaccessfile": "TB_202305_May-2837_0401",
          "TB_Datauploaded": "2024-04-02",
          "Uploaded_by": "",
          "Entity": "PwC AU Consulting Pty Ltd"
        }
    ]
}

export const DUMMY_DATATAB_TRANSACTIONS_DATA =  [
    {
        "id": "fd48a8a3-2b56-4b78-bcbd-37f0698401eb",
        "source": "AP",
        "flow": "Output VAT",
        "invoiceno": "LB502308",
        "invoicedate": "2024-12-01",
        "statnetamount": "233.15",
        "statvatamount": "23.315",
        "entityname": "PwC AU Assurance Pty Ltd",
        "taxcode": "P2",
        "suppliercountrycode": "AU",
        "payercountrycode": null,
        "statcurrencycode": "AUD",
        "compliancecounterpartycompanyname": "Arena REIT",
        "statgrossamount": "256.465",
        "stattotaltaxamount": "23.315",
        "suppliername": "Arena REIT",
        "customername": null,
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "entitycode": "PwC0002",
        "fiscalyear": 2024,
        "erpdocumentno": "May202492",
        "invoicelineno": 1,
        "txvatamount": "23.32",
        "entitycountrycode": "AU",
        "customercountrycode": null,
        "glaccountno": "10001",
        "glaccountdescription": "GST Receivable",
        "taxpointdate": "2024-12-02",
        "complianceentityvatregistrationno": "5278043357/002",
        "complianceentitycompanyname": "PwC AU Assurance Pty Ltd",
        "compliancereportingcountrycode": "AU",
        "compliancereportingcountryregion": null,
        "compliancecounterpartyvatregistrationno": "20003279534",
        "compliancecounterpartycountrycode": "AU",
        "taxtreatment": null,
        "erpreportingcountrycode": null,
        "compliancereportingdate": "2024-12-02",
        "compliancecurrencycode": null,
        "compliancenetamount": null,
        "compliancevatamount": null,
        "compliancegrossamount": null,
        "compliancerecoverablevatamount": null,
        "statrecoverablevatamount": null,
        "compliancenonrecoverablevatamount": null,
        "statnonrecoverablevatamount": null,
        "entity_details": {
            "name": "PwC AU Assurance Pty Ltd",
            "code": "PwC0002",
            "date_modified": "2023-02-01",
            "modified_by": "Chris, Anthony",
            "valid_from": "2023-01-01",
            "valid_to": null
        },
        "transactionconcatenate": "PwC00022024May202492"
    },
    {
        "id": "f92fe493-c96c-417d-890e-65035861c64e",
        "source": "AR",
        "flow": "Input VAT",
        "invoiceno": "INV900231",
        "invoicedate": "2024-12-01",
        "statnetamount": "316",
        "statvatamount": "32",
        "entityname": "PwC AU Assurance Pty Ltd",
        "taxcode": "S1",
        "suppliercountrycode": null,
        "payercountrycode": null,
        "statcurrencycode": "AUD",
        "compliancecounterpartycompanyname": "Austar Gold Ltd",
        "statgrossamount": "347",
        "stattotaltaxamount": "32",
        "suppliername": null,
        "customername": "Austar Gold Ltd",
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "entitycode": "PwC0002",
        "fiscalyear": 2024,
        "erpdocumentno": "MayAR202531",
        "invoicelineno": 1,
        "txvatamount": "32",
        "entitycountrycode": "AU",
        "customercountrycode": "AU",
        "glaccountno": "20001",
        "glaccountdescription": "GST Payable",
        "taxpointdate": "2024-12-02",
        "complianceentityvatregistrationno": "5278043357/002",
        "complianceentitycompanyname": "PwC AU Assurance Pty Ltd",
        "compliancereportingcountrycode": "AU",
        "compliancereportingcountryregion": null,
        "compliancecounterpartyvatregistrationno": "11000355313",
        "compliancecounterpartycountrycode": "AU",
        "taxtreatment": null,
        "erpreportingcountrycode": null,
        "compliancereportingdate": "2024-12-02",
        "compliancecurrencycode": null,
        "compliancenetamount": null,
        "compliancevatamount": null,
        "compliancegrossamount": null,
        "compliancerecoverablevatamount": null,
        "statrecoverablevatamount": null,
        "compliancenonrecoverablevatamount": null,
        "statnonrecoverablevatamount": null,
        "entity_details": {
            "name": "PwC AU Assurance Pty Ltd",
            "code": "PwC0002",
            "date_modified": "2023-02-01",
            "modified_by": "Chris, Anthony",
            "valid_from": "2023-01-01",
            "valid_to": null
        },
        "transactionconcatenate": "PwC00022024MayAR202531"
    },
    {
        "id": "ec5a9985-6d44-44ca-8e98-2a5f8a2b96f0",
        "source": "AP",
        "flow": "Output VAT",
        "invoiceno": "9038450550",
        "invoicedate": "2024-12-01",
        "statnetamount": "22.87",
        "statvatamount": "2.287",
        "entityname": "PwC AU Assurance Pty Ltd",
        "taxcode": "P2",
        "suppliercountrycode": "AU",
        "payercountrycode": null,
        "statcurrencycode": "AUD",
        "compliancecounterpartycompanyname": "ARQ Group Ltd",
        "statgrossamount": "25.157",
        "stattotaltaxamount": "2.287",
        "suppliername": "ARQ Group Ltd",
        "customername": null,
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "entitycode": "PwC0002",
        "fiscalyear": 2024,
        "erpdocumentno": "May202498",
        "invoicelineno": 1,
        "txvatamount": "2.29",
        "entitycountrycode": "AU",
        "customercountrycode": null,
        "glaccountno": "10001",
        "glaccountdescription": "GST Receivable",
        "taxpointdate": "2024-12-02",
        "complianceentityvatregistrationno": "5278043357/002",
        "complianceentitycompanyname": "PwC AU Assurance Pty Ltd",
        "compliancereportingcountrycode": "AU",
        "compliancereportingcountryregion": null,
        "compliancecounterpartyvatregistrationno": "88605001278",
        "compliancecounterpartycountrycode": "AU",
        "taxtreatment": null,
        "erpreportingcountrycode": null,
        "compliancereportingdate": "2024-12-02",
        "compliancecurrencycode": null,
        "compliancenetamount": null,
        "compliancevatamount": null,
        "compliancegrossamount": null,
        "compliancerecoverablevatamount": null,
        "statrecoverablevatamount": null,
        "compliancenonrecoverablevatamount": null,
        "statnonrecoverablevatamount": null,
        "entity_details": {
            "name": "PwC AU Assurance Pty Ltd",
            "code": "PwC0002",
            "date_modified": "2023-02-01",
            "modified_by": "Chris, Anthony",
            "valid_from": "2023-01-01",
            "valid_to": null
        },
        "transactionconcatenate": "PwC00022024May202498"
    },
    {
        "id": "e0f96902-3393-4538-80e6-d0d4be94957d",
        "source": "AP",
        "flow": "Output VAT",
        "invoiceno": "9038458607",
        "invoicedate": "2024-12-01",
        "statnetamount": "60.88",
        "statvatamount": "6.088",
        "entityname": "PwC AU Assurance Pty Ltd",
        "taxcode": "P2",
        "suppliercountrycode": "AU",
        "payercountrycode": null,
        "statcurrencycode": "AUD",
        "compliancecounterpartycompanyname": "Astro Resources NL",
        "statgrossamount": "66.968",
        "stattotaltaxamount": "6.088",
        "suppliername": "Astro Resources NL",
        "customername": null,
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "entitycode": "PwC0002",
        "fiscalyear": 2024,
        "erpdocumentno": "May202497",
        "invoicelineno": 1,
        "txvatamount": "6.09",
        "entitycountrycode": "AU",
        "customercountrycode": null,
        "glaccountno": "10001",
        "glaccountdescription": "GST Receivable",
        "taxpointdate": "2024-12-02",
        "complianceentityvatregistrationno": "5278043357/002",
        "complianceentitycompanyname": "PwC AU Assurance Pty Ltd",
        "compliancereportingcountrycode": "AU",
        "compliancereportingcountryregion": null,
        "compliancecounterpartyvatregistrationno": "88605001278",
        "compliancecounterpartycountrycode": "AU",
        "taxtreatment": null,
        "erpreportingcountrycode": null,
        "compliancereportingdate": "2024-12-02",
        "compliancecurrencycode": null,
        "compliancenetamount": null,
        "compliancevatamount": null,
        "compliancegrossamount": null,
        "compliancerecoverablevatamount": null,
        "statrecoverablevatamount": null,
        "compliancenonrecoverablevatamount": null,
        "statnonrecoverablevatamount": null,
        "entity_details": {
            "name": "PwC AU Assurance Pty Ltd",
            "code": "PwC0002",
            "date_modified": "2023-02-01",
            "modified_by": "Chris, Anthony",
            "valid_from": "2023-01-01",
            "valid_to": null
        },
        "transactionconcatenate": "PwC00022024May202497"
    },
    {
        "id": "cb067a38-d312-446a-a6a6-c5921a7ceb03",
        "source": "AP",
        "flow": "Output VAT",
        "invoiceno": "9038452178",
        "invoicedate": "2024-12-01",
        "statnetamount": "118.92",
        "statvatamount": "11.892",
        "entityname": "PwC AU Assurance Pty Ltd",
        "taxcode": "P2",
        "suppliercountrycode": "AU",
        "payercountrycode": null,
        "statcurrencycode": "AUD",
        "compliancecounterpartycompanyname": "Alt Resources Ltd",
        "statgrossamount": "130.812",
        "stattotaltaxamount": "11.892",
        "suppliername": "Alt Resources Ltd",
        "customername": null,
        "obligation_id": "a4ef47a4-c54a-4b8f-89aa-a521411d5122",
        "entitycode": "PwC0002",
        "fiscalyear": 2024,
        "erpdocumentno": "May202499",
        "invoicelineno": 1,
        "txvatamount": "11.89",
        "entitycountrycode": "AU",
        "customercountrycode": null,
        "glaccountno": "10001",
        "glaccountdescription": "GST Receivable",
        "taxpointdate": "2024-12-02",
        "complianceentityvatregistrationno": "5278043357/002",
        "complianceentitycompanyname": "PwC AU Assurance Pty Ltd",
        "compliancereportingcountrycode": "AU",
        "compliancereportingcountryregion": null,
        "compliancecounterpartyvatregistrationno": "88605001278",
        "compliancecounterpartycountrycode": "AU",
        "taxtreatment": null,
        "erpreportingcountrycode": null,
        "compliancereportingdate": "2024-12-02",
        "compliancecurrencycode": null,
        "compliancenetamount": null,
        "compliancevatamount": null,
        "compliancegrossamount": null,
        "compliancerecoverablevatamount": null,
        "statrecoverablevatamount": null,
        "compliancenonrecoverablevatamount": null,
        "statnonrecoverablevatamount": null,
        "entity_details": {
            "name": "PwC AU Assurance Pty Ltd",
            "code": "PwC0002",
            "date_modified": "2023-02-01",
            "modified_by": "Chris, Anthony",
            "valid_from": "2023-01-01",
            "valid_to": null
        },
        "transactionconcatenate": "PwC00022024May202499"
    }
]