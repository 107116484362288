import { SvgIcon } from '@mui/material';

export const Logo = (props) => {
    return (
        <SvgIcon {...props} sx={{ width: '2.625rem', cursor: 'pointer' }}>
            <svg width="42" height="17" viewBox="0 0 42 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Logo Wrap">
                    <path id="Logo" fillRule="evenodd" clipRule="evenodd" d="M29.621 0.5C28.9636 0.5 28.3657 0.804637 28.0867 1.14897V5.40694L25.4152 9.03778V0.783799H22.8635L18.6381 7.90159V0.783799H17.1829L13.3554 1.73641V2.7307L15.4496 2.95397V11.6932H18.1602L22.2061 4.92071V11.6932H25.1762L29.2826 6.30001C30.9173 4.21121 31.4948 3.35982 31.4948 2.34469C31.4948 1.33155 30.6979 0.5 29.621 0.5ZM38.631 9.78736C36.7378 9.78736 35.502 8.16495 35.502 5.75266C35.502 3.33938 36.4383 1.98091 38.2526 1.67628L38.233 4.59663C38.8505 4.96179 39.3489 5.12354 39.9468 5.12354C41.1231 5.12354 41.9005 4.37336 41.9005 3.29869C41.9005 1.63559 40.2258 0.500397 37.7736 0.500397C34.0867 0.500397 31.575 2.83231 31.575 6.29942C31.575 7.8613 32.093 9.26045 33.1288 10.3143C34.1462 11.3493 35.382 11.8355 37.1562 11.8355C38.791 11.8355 39.9663 11.5517 42 10.5981V8.65118C40.3253 9.48273 39.5079 9.78736 38.631 9.78736ZM5.3032 10.314C5.58216 10.3348 5.72066 10.3348 5.90111 10.3348C8.07428 10.3348 9.24962 8.95552 9.24962 6.30011C9.24962 4.06941 8.29277 2.8717 6.53804 2.8717C6.25631 2.8717 5.97458 2.90884 5.42378 2.98144C5.39106 2.98575 5.35738 2.99019 5.3227 2.99475L5.3032 10.314ZM5.3032 1.98062C7.27641 0.783898 7.95333 0.581468 8.95018 0.581468C11.2833 0.581468 12.9971 2.77148 12.9971 5.79305C12.9971 9.30084 10.7049 11.6536 7.07645 11.6536C6.67849 11.6536 5.98012 11.6337 5.28271 11.5524L5.3032 15.08L7.05695 15.4859V16.5H0.180447V15.4859L1.75473 15.08V2.8717H0V1.81788L4.20686 0.519945H5.3032V1.98062Z" fill="white" />
                </g>
            </svg>

        </SvgIcon>
    )
};
