import {
  AttributionIcon,
  HighValuePurchasesIcon,
  HighValueSuppliesIcon,
  InconsistentCodingIcon,
  SignificantCustomersIcon,
  SignificantSupliersIcon,
  TaxBreakdownIcon,
  TransactionAnalyticsIcon,
  TrendsAndVariancesIcon,
} from '../../../components/common/CustomIcons/Analytics';

export const getGeneralAnalyticsCardData = title => GENERAL_ANALYTICS_CARDS_DATA.find((card) => card.title === title)

export const getGeneralAnalyticsCardDescription = title => getGeneralAnalyticsCardData(title)?.description

export const getOperatingAnalyticsCardData = title => OPERATING_ANALYTICS_CARDS_DATA.find((card) => card.title === title)

export const getOperatingAnalyticsCardDescription = title => getOperatingAnalyticsCardData(title)?.description

export const GENERAL_ANALYTICS_CARDS_DATA = [
  {
    title: 'Tax Breakdown',
    description: 'Breakdown of Supply and Purchase transactions by Tax Code and Tax Rates.',
    navigate_to: `/tax-breakdown`,
    icon: <TaxBreakdownIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Inconsistent Coding',
    description: 'Identify inconsistency in the Tax Code usage by Tax codes and GL number.',
    navigate_to: '/inconsistent-coding',
    icon: <InconsistentCodingIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Trends and Variances',
    description: 'Trend and variance analysis over current period and prior periods.',
    navigate_to: '/trends-and-variances',
    icon: <TrendsAndVariancesIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Transaction Analytics',
    description: 'Overview of the Supply and purchases transactions, including value and count. ',
    navigate_to: '/transaction-analytics',
    icon: <TransactionAnalyticsIcon sx={{ fontSize: '2rem' }} />,
  },
];

export const OPERATING_ANALYTICS_CARDS_DATA = [
  {
    title: 'High Value Supplies',
    description: 'Supply transactions with highest values by varying views.',
    navigate_to: `/high-value-supplies`,
    icon: <HighValueSuppliesIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'High Value Purchases',
    description: 'Purchase transactions with highest values by varying views.',
    navigate_to: '/high-value-purchase',
    icon: <HighValuePurchasesIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Unusual Suppliers',
    description: 'Unusual Purchase transactions categorised into different groups.',
    navigate_to: '/unusual-suppliers',
    icon: <InconsistentCodingIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Unusual Customers',
    description: 'Unusual Supply transactions categorised into different groups.',
    navigate_to: '/unusual-customers',
    icon: <InconsistentCodingIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Significant Suppliers',
    description: 'Purchase transactions with highest Net value and transaction volume.',
    navigate_to: '/significant-suppliers',
    icon: <SignificantSupliersIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Significant Customers',
    description: 'Supply transactions with highest Net value and transaction volume.',
    navigate_to: '/significant-customers',
    icon: <SignificantCustomersIcon sx={{ fontSize: '2rem' }} />,
  },
  {
    title: 'Attribution',
    description: 'Posted invoices by date for VAT Attribution purposes.',
    navigate_to: '/attribution',
    icon: <AttributionIcon sx={{ fontSize: '2rem' }} />,
  },
];

export const NO_DATA = 'Not Available';