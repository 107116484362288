import { useState } from 'react';
import * as mui from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useObligation, useUser } from '../../../../context';
import { WhiteCard } from '../../WhiteCard';
import { typographyStyle } from '../../Reconciliations';
import { CloseIcon } from '../../../../components/common/CustomIcons/CloseIcon';
import { TextArea } from '../../../../components/common/Input/TextArea';
import { BtnStandard } from '../../../../components/common/Buttons/BtnStandard';
import { BtnFilled } from '../../../../components/common/Buttons/BtnFilled';
import { WarningPopup } from '../../../../components/common/Modal/WarningPopup';

export const TransactionAcceptModal = ({ setOpen, selectedRows, handleResolveStatus, ruleId }) => {
  const theme = mui.useTheme();
  const navigate = useNavigate();
  const { userToken, userId } = useUser();
  const { selectedObgDetails } = useObligation();

  const HeaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1244px',
    padding: '32px',
    pb: 0,
  };
  const style = {
    width: '100%',
    height: '30%',
    border: 'none',
    padding: '2px',
    pr: '1rem',
    pb: '5rem',
    overflow: 'auto',
  };

  const [cancel, setCancel] = useState(false);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => setCancel(true);

  const resolveHandler = () => {
    if (userToken) {
      setLoading(true);
      let url = '';
      url =
        process.env.REACT_APP_BACKEND_URL +
        `/exception/transactionacceptexception?obligation_id=${selectedObgDetails.obligation_id}&added_by=${userId}&note_description=${reason}&rule_id=${ruleId}`;
      const formData = new FormData();
      if (selectedRows.length > 0) {
        for (const element of selectedRows) {
          formData.append('transaction_ids', element);
        }
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setOpen(false);
          handleResolveStatus('success', 'Exceptions accepted successfully');
        })
        .catch((err) => {
          handleResolveStatus('failure', 'Exceptions acceptance failed');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <mui.Modal open={true}>
      <WhiteCard style={HeaderStyle}>
        <mui.Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          sx={{
            borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
          }}>
          <mui.Typography
            id='modal-modal-title'
            sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey)}>
            {`Accepting ${selectedRows?.length} exception(s)`}
          </mui.Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </mui.Stack>
        <WhiteCard style={style}>
          <mui.Stack gap={'2rem'}>
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                Reason for acceptance
              </mui.Typography>
              <TextArea
                onChange={(e) => setReason(e.target.value)}
                placeholder={'Explain why you are comfortable accepting this exception...'}
              />
            </mui.Stack>
          </mui.Stack>
        </WhiteCard>
        <mui.Stack
          flexDirection='row'
          justifyContent='flex-end'
          gap='0.5rem'
          pb='2rem'>
          <BtnStandard
            btnTxt={'Cancel'}
            onClick={handleClose}
            disabled={loading}
            styling={{
              height: '2.75rem',
              padding: '0.5rem 1.5rem',
              fontSize: '1rem',
            }}
          />
          <BtnFilled
            size='medium'
            styling={{
              '&:disabled': {
                backgroundColor: theme.palette.primaryColors[200],
                color: theme.palette.secondaryColors[100],
              },
            }}
            btnTxt={'Resolve'}
            loading={loading}
            disabled={loading}
            onClick={resolveHandler}
          />
        </mui.Stack>
        {cancel && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setCancel(false);
            }}
            continueHandler={() => {
              setCancel(false);
              setOpen(false);
            }}
            warningTitle={'Discard?'}
          />
        )}
      </WhiteCard>
    </mui.Modal>
  );
};
