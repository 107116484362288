import { Alert, Snackbar, useTheme } from "@mui/material"
import { CheckCircleIcon } from "../../components/common/CustomIcons/CheckCircleIcon";
import { RedWarningIcon } from "../../components/common/CustomIcons/RedWarningIcon";

export const Toast = ({ closeAlertHandler, toast }) => {
    const theme = useTheme();
    return (
        <Snackbar
            open={true}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={closeAlertHandler}
        >
            <Alert
                onClose={closeAlertHandler}
                sx={{
                    // width: '100%',
                    backgroundColor: toast?.type === "success" ? theme.palette.functional.successAccent : theme.palette.functional.errorAccent,
                    color: theme.palette.baseColors.grey,
                    fontSize: '1rem',
                    borderRadius: '0.25rem',
                    border: `1px solid ${theme.palette.neutralColors.black[300]}`
                }}
                iconMapping={{
                    success: toast?.type === 'success' ? <CheckCircleIcon fontSize="inherit" sx={{ width: '24px', height: '24px' }} /> : <RedWarningIcon />,
                }}
            >
                {toast?.message}
            </Alert>
        </Snackbar>
    )
}
