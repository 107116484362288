import React, { useEffect, useState, useRef } from 'react';
import { ListNotes } from './Notes/ListNotes';
import { useNavigate, useParams } from 'react-router-dom';
import { useObligation, useUser } from '../../context';
import { Common } from './Common';
import { Box, Stack, useTheme } from '@mui/material';
import { Toast } from './Toast';
import { CheckCircleOutlinedIcon } from '../../components/common/CustomIcons/CheckCircleOutlinedIcon';
import { LockIcon } from '../../components/common/CustomIcons/LockIcon';
import { Analytics } from './Analytics';
import { TaxBreakdown } from './Analytics/Tax Breakdown';
import { TransactionAnalytics } from './Analytics/Transaction Analytics';

import SignificantCustomers from './Analytics/Significant Customers';
import { TrendsVariances } from './Analytics/Trends and Variances';
import { Exceptions } from './Exceptions';
import { HighValPurchase } from './Analytics/HighValPurchase';
import { HighValueSupplies } from './Analytics/HighValSupplies';
import { InconsistentCoding } from './Analytics/Inconsistent Coding';
import { Checklist } from './Checklist';
import { SignificantSuppliers } from './Analytics/SignificantSuppliers';
import { UnusualSuppliers } from './Analytics/UnusualSuppliers';
import { UnusualCustomers } from './Analytics/UnusualCustomers';
import { Export } from './Export';
import { Overview } from './Overview';
import { Adjustments } from './Adjustments';
import { Attribution } from './Analytics/Attribution';
import TransactionDetail from './Exceptions/TransactionDetail';
import { PurchaseTaxExceptions } from './Exceptions/PurchaseTaxExceptions';
import { Data } from './Data';
import { IndividualTestTransactionTable } from './Exceptions/IndividualTestTransaction/IndividualTestTransactionTable';
import { SupplyTaxExceptions } from './Exceptions/SupplyTaxExceptions';
import { BusinessNumberValidation } from './Exceptions/BusinessNumberValidation';
import { TransactionDashboard } from './Data/TransactionDashboard';
import { Reconciliations } from './Reconciliations';
import { ReconciliationSummaryPage } from './Reconciliations/ReconciliationsDrillDown';

import { DownloadIcon } from '../../components/common/CustomIcons/DownloadIcon';
import ErrorBadge from '../../components/common/Error Badges/ErrorBadge';
import { UnresolvedExceptionLists } from './Exceptions/TransactionDetail/ResolveDrilldown/UnresolvedExceptionLists';
import { ListTransaction } from './Transactions/ListTransaction';
import { AuthError, CheckAuthorization } from './Authorization/Authorization';
import { UseAuth } from '../../context/AuthContext';

export const IndividualObligation = () => {
  const theme = useTheme();
  const { setAuthorized, authorized, authLoader, setAuthLoader } = UseAuth();
  const [toast, setToast] = useState({ type: '', message: '' });
  const [isFetchingData, setIsFetchingData] = useState(true);

  const { id, member, tab, subTab, childTab, detailTab, nestedTab } = useParams();
  const {
    selectedObgDetails,
    selectedObgHandler,
    obligationReloadFlag,
    acknowledgeObligationReload,
    isObligationLocked,
    setSisterObligations,
    setGroupObligation,
  } = useObligation();
  const { userToken } = useUser();
  const navigate = useNavigate();

  const layoutRef = useRef();

  useEffect(() => {
    layoutRef.current?.scrollIntoView(true, { behavior: 'smooth' }); // Go to the top of the page on page load (useful)
    if (!tab) {
      navigate('overview');
    }
    // Delete the Obligation Context.
    return () => {
      selectedObgHandler({});
      setSisterObligations([]);
      setGroupObligation({});
    };
  }, []);

  const loadObligation = () => {
    const url =
      process.env.REACT_APP_BACKEND_URL +
      `/obligationsummary/listall?limit=1&filter_query=obligation_id__eq=${id}%26member__eq=${member}`;
    let _obligation;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(
          `Something went wrong while fetching Obligation ID ${id}, Member ${member}. Status code: ${res.statusCode}`
        );
      })
      .then((data) => {
        if (data?.summary.length === 0)
          throw new Error(
            `Obligation with ID ${id} and Member ${member} could not be found in the Database!`
          );
        _obligation = data?.summary[0];
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Obligation data could not be fetched.',
        });
      })
      .finally(() => {
        selectedObgHandler(_obligation);
        loadSisterObligations(_obligation);
      });
  };

  const loadSisterObligations = (mainObligation) => {
    if (!mainObligation || !mainObligation?.obligation_id) return;
    setIsFetchingData(true);
    const url =
      process.env.REACT_APP_BACKEND_URL +
      `/obligationsummary/getobligationsummarybyobligation?obligation_id=${mainObligation.obligation_id}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        if (!res.ok) throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        return res.json();
      })
      .then((data) => {
        let _sisterObligations = data?.summary;
        let _groupObligation = _sisterObligations.find(
          (o) => o.member === mainObligation.obligation_group_name
        );

        _sisterObligations = _sisterObligations.filter((o) => o !== _groupObligation).sort();
        if (_groupObligation) _sisterObligations.unshift(_groupObligation);

        setSisterObligations(_sisterObligations);
        setGroupObligation(_groupObligation);
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Member data could not be loaded.',
        });
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  useEffect(() => {
    if (!userToken) return;

    loadObligation();
  }, [userToken, id, member]);

  useEffect(() => {
    if (!userToken) return;
    CheckAuthorization(userToken, setAuthorized, id, setAuthLoader);
  }, [userToken]);

  useEffect(() => {
    if (!obligationReloadFlag || !userToken) return;

    acknowledgeObligationReload();
    loadObligation();
  }, [obligationReloadFlag]);

  const obligationBasePath = `/obligations/${id}/${member}`;
  const [availableTabs, setAvailableTabs] = useState([]);

  useEffect(() => {
    if (!selectedObgDetails) return;

    const shouldExportBeEnabled =
      selectedObgDetails.differentiator === 'group' &&
      ['Completed', 'Finalized'].includes(selectedObgDetails.stage);
    setAvailableTabs([
      {
        name: 'Overview',
        state: 'enabled',
        link: `${obligationBasePath}/overview`,
        icon: null,
      },
      {
        name: 'Data',
        state: 'enabled',
        link: `${obligationBasePath}/data`,
        icon: null,
      },
      {
        name: 'Reconciliations',
        state: 'enabled',
        link: `${obligationBasePath}/reconciliations`,
        icon: null,
      },
      {
        name: 'Exceptions',
        state: 'enabled',
        link: `${obligationBasePath}/exceptions`,
        icon: null,
      },
      {
        name: 'Analytics',
        state: 'enabled',
        link: `${obligationBasePath}/analytics`,
        icon: null,
      },
      {
        name: 'Adjustments',
        state: 'enabled',
        link: `${obligationBasePath}/adjustments`,
        icon: null,
      },
      {
        name: 'Checklist',
        state: 'enabled',
        link: `${obligationBasePath}/checklist`,
        icon: (
          <CheckCircleOutlinedIcon
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              '&:hover': {
                color: theme.palette.primaryColors[200],
              },
            }}
          />
        ),
      },
      {
        name: 'Export',
        state: shouldExportBeEnabled ? 'enabled' : 'disabled',
        link: `${obligationBasePath}/export`,
        icon: shouldExportBeEnabled ? (
          <DownloadIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
        ) : (
          <LockIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
        ),
      },
    ]);
  }, [obligationBasePath, selectedObgDetails, theme.palette.primaryColors]);

  const tabComponents = {
    analytics: <Analytics />,
    exceptions: <Exceptions />,
    export: <Export />,
    overview: <Overview />,
    checklist: <Checklist />,
    adjustments: <Adjustments />,
    reconciliations: <Reconciliations />,
    data: <Data />,
    'all-transactions': <ListTransaction />,
    'individual-transaction': <TransactionDetail />,
  };
  // Dictionary of dictionaries. Each inner dictionary maps to a tab, and its dictionary maps subtabs to their components.
  const subtabComponents = {
    analytics: {
      'tax-breakdown': <TaxBreakdown />,
      'inconsistent-coding': <InconsistentCoding />,
      'trends-and-variances': <TrendsVariances />,
      'transaction-analytics': <TransactionAnalytics />,
      'high-value-purchase': <HighValPurchase />,
      'high-value-supplies': <HighValueSupplies />,
      'significant-customers': <SignificantCustomers />,
      'significant-suppliers': <SignificantSuppliers />,
      'unusual-customers': <UnusualCustomers />,
      'unusual-suppliers': <UnusualSuppliers />,
      attribution: <Attribution />,
    },
    exceptions: {
      'purchase-tax-exceptions': <PurchaseTaxExceptions />,
      'supply-tax-exceptions': <SupplyTaxExceptions />,
      'business-number-validation': <BusinessNumberValidation />,
      'all-transactions': <ListTransaction />,
    },
    reconciliations: {
      'trial-balances-vs-transactions-summary': <ReconciliationSummaryPage />,
      'vat-gl-vs-transactions-summary': <ReconciliationSummaryPage />,
      'vat-gl-vs-transactions-without-tax-code-summary': <ReconciliationSummaryPage />,
      'input-vat-reconciliation-without-tax-code-summary': <ReconciliationSummaryPage />,
    },
    data: {
      transactions: <TransactionDashboard />,
    },
    'individual-transaction': {
      'transaction-lists': <UnresolvedExceptionLists />,
    },
  };

  const childTabComponents = {
    'purchase-tax-exceptions': {
      'individual-test': <IndividualTestTransactionTable />,
    },
    'supply-tax-exceptions': {
      'individual-test': <IndividualTestTransactionTable />,
    },
    'business-number-validation': {
      'individual-test': <IndividualTestTransactionTable />,
    },
  };

  return (
    <>
      <Stack
        width='100%'
        gap='2.5rem'
        ref={layoutRef}>
        <Box mb='-2.5rem'>
          <ErrorBadge
            open={isObligationLocked}
            variant='locked'>
            This obligation is now locked for editing.
          </ErrorBadge>
        </Box>

        {/* Static content */}
        {!subTab && (
          <Common
            availableTabs={availableTabs}
            isFetchingData={isFetchingData}
            setIsFetchingData={setIsFetchingData}
          />
        )}
        {!authLoader ? (
          <>
            {/* Tab rendering */}
            {!subTab &&
              (authorized === 'Authorized' ? (
                <Stack
                  padding='0 2.5rem'
                  mb='2.5rem'
                  gap='1.5rem'>
                  {tabComponents[tab] ?? <ListNotes />}
                </Stack>
              ) : (
                <AuthError />
              ))}

            {/* Subtab rendering */}
            {subTab &&
              !childTab &&
              (authorized === 'Authorized' ? (
                subtabComponents[tab][subTab] ?? 'Page not found'
              ) : (
                <AuthError />
              ))}

            {/*Childtab rendering */}
            {subTab &&
              childTab &&
              !detailTab &&
              (authorized === 'Authorized' ? (
                childTabComponents[subTab][childTab] ?? 'Page not found'
              ) : (
                <AuthError />
              ))}
          </>
        ) : (
          <></>
        )}
      </Stack>

      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
