import React from "react";
import { Box, Skeleton } from "@mui/material";

export const LoadingView = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Skeleton
        variant="rounded"
        width="100%"
        height={100}
        sx={{ margin: 3 }}
      />
      <Skeleton
        variant="rounded"
        width="100%"
        height={250}
        sx={{ margin: 3 }}
      />
      <Skeleton
        variant="rounded"
        width="100%"
        height={350}
        sx={{ margin: 3 }}
      />
      <Skeleton
        variant="rounded"
        width="100%"
        height={250}
        sx={{ margin: 3 }}
      />
    </Box>
  );
};
