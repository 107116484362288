import { InputBase, InputLabel, Stack, useTheme, Typography } from "@mui/material"
import { RedWarningOutlinedIcon } from "../CustomIcons/RedWarningOutlinedIcon";
import { preventReloadOnInputEnter } from "../../../utils";


export const EditableInput = ({ id, placeholder, name, label, required, error, width, value, onChange, disabled, labelStyles, inputStyles, showLabel = true, type, adornment }) => {
  const theme = useTheme();
  return (
    <Stack component='form' gap='0.5rem' width={width}>
      {showLabel && (
        <InputLabel
          htmlFor={id}
          sx={{
            fontWeight: 500,
            cursor: "pointer",
            color: theme.palette.baseColors.grey,
            width: "max-content",
            ...labelStyles,
          }}

          // required={!showError && required}
        >
          {label ?? name}&nbsp;
          {required && (
            <Typography
              component="span"
              sx={{
                fontWeight: 400,
                color: theme.palette.functional.error,
              }}
            >
              (Required)
            </Typography>
          )}
        </InputLabel>
      )}
      <InputBase
        id={id}
        placeholder={placeholder}
        sx={{
          height: '3.25rem',
          padding: '1rem',
          borderRadius: '0.25rem',
          border: error ? `1px solid ${theme.palette.functional.error}` :
            `1px solid ${theme.palette.random[500]}`,
          color: theme.palette.baseColors.grey,
          ...inputStyles
        }}
        inputProps={{
          sx: {
            '&::placeholder': {
              color: theme.palette.secondaryColors[400],
              opacity: 1
            }
          }
        }}
        spellCheck='false'
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        type={type}
        endAdornment={adornment}
        onKeyDown={preventReloadOnInputEnter}
      />
      {error && <Stack direction='row' alignItems='center' gap='0.25rem'>
        <RedWarningOutlinedIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
        <Typography variant="body2" color={theme.palette.functional.error}>
          {error}
        </Typography>
      </Stack>}
    </Stack>
  )
}
