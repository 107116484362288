import { SvgIcon } from '@mui/material';
import React from 'react'

export const AttributionIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7113_3234)">
                    <path d="M22.6667 4.00004H28.0001C28.3537 4.00004 28.6928 4.14052 28.9429 4.39056C29.1929 4.64061 29.3334 4.97975 29.3334 5.33337V26.6667C29.3334 27.0203 29.1929 27.3595 28.9429 27.6095C28.6928 27.8596 28.3537 28 28.0001 28H4.00008C3.64646 28 3.30732 27.8596 3.05727 27.6095C2.80722 27.3595 2.66675 27.0203 2.66675 26.6667V5.33337C2.66675 4.97975 2.80722 4.64061 3.05727 4.39056C3.30732 4.14052 3.64646 4.00004 4.00008 4.00004H9.33342V1.33337H12.0001V4.00004H20.0001V1.33337H22.6667V4.00004ZM26.6667 14.6667H5.33341V25.3334H26.6667V14.6667ZM20.0001 6.66671H12.0001V9.33337H9.33342V6.66671H5.33341V12H26.6667V6.66671H22.6667V9.33337H20.0001V6.66671ZM8.00008 17.3334H10.6667V20H8.00008V17.3334ZM14.6667 17.3334H17.3334V20H14.6667V17.3334ZM21.3334 17.3334H24.0001V20H21.3334V17.3334Z" fill="#474747" />
                </g>
                <defs>
                    <clipPath id="clip0_7113_3234">
                        <rect width="32" height="32" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
};
