import { Autocomplete, Paper, TextField, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { useUser } from "../../../context"
import { ChevronDownIcon } from "@radix-ui/react-icons"

const AutocompleteInput = (params) => {
    const theme = useTheme()

    return <TextField
        {...params}
        sx={{
            backgroundColor: theme.palette.baseColors.white
        }}
    />
}

export const StyledAutocomplete = (props) => {
    const theme = useTheme()

    return <Autocomplete
        {...props}
        renderInput={params => <AutocompleteInput {...params} />}
        loadingText="Loading suggestions..."

        IconComponent={props => <ChevronDownIcon {...props} color={theme.palette.baseColors.grey} />}
        sx={{
            fontSize: "0.875rem",
            color: theme.palette.random[200],
            backgroundColor: theme.palette.baseColors.white,
            '.MuiOutlinedInput-root': {
                height: "3.3125rem",
                fontSize: "0.875rem",
            },
            // THIS OVERRIDES THE BORDER COLORS FOR THE MAIN SELECT OBJECT.
            '.MuiOutlinedInput-notchedOutline': {
                borderWidth: "1px !important",
                borderColor: theme.palette.random[500],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: "1px !important",
                borderColor: theme.palette.baseColors.grey + " !important",
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: "1px !important",
                borderColor: '#00000033',
            },
            "& + .MuiAutocomplete-popper .MuiAutocomplete-listbox": {
                padding: 0
            },
            "& + .MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option": {
                fontSize: "0.875rem",
                "& + [aria-selected=true]" : { backgroundColor: theme.palette.primaryColors[100] + "!important" },
                "& + .Mui-focused" : { backgroundColor: "#E6E8E8 !important" },
            },
            // I don't know, man.
            "& + .MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": { backgroundColor: "#E6E8E8 !important" }
        }}

        disablePortal
    />
}