import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const UseAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);
  const [authLoader, setAuthLoader] = useState(true);

  return (
    <AuthContext.Provider
      value={{
        authorized,
        setAuthorized,
        authLoader,
        setAuthLoader,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
