export const FOOTER_LINKS_OPTIONS = [
    {
        linkName: `Terms of Use`,
        linkContent: `https://www.pwc.com.au/about-us/code-of-conduct.html`
    },
    {
        linkName: `Privacy`,
        linkContent: `https://www.pwc.com.au/contact-us/privacy-policy.html`
    }
];

export const PWC_STRUCTURE_LINK='https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html';