import { Button, CircularProgress, styled, useTheme } from '@mui/material';

const StyledOutlinedBtn = styled(Button)(({ theme }) => ({
    backgroundColor: 'inherit',
    borderColor: theme.palette.neutralColors.black[200],
    textTransform: 'none',
    color: theme.palette.baseColors.grey,
    borderRadius: '0.326rem',
    height: '2rem',
    padding: '0.5rem 1rem',
    fontSize: '0.875rem',
    minWidth: 'max-content',
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: theme.palette.neutralColors.black[300],
        borderColor: theme.palette.neutralColors.black[200],
    },
    '&:disabled': {
        backgroundColor: theme.palette.secondaryColors[200],
        color: theme.palette.secondaryColors[400],
        border: 'none'
    }
}))
export const BtnOutlined = ({ btnTxt, iconPosition, disabled, icon, onClick, sx, loading, progressColor, disabledStyling }) => {
    const theme = useTheme();
    return (
        <StyledOutlinedBtn
            variant='outlined'
            startIcon={iconPosition === "start" ? icon : null}
            endIcon={iconPosition === "end" ? icon : null}
            disabled={disabled}
            onClick={onClick}
            disableRipple
            sx={{ ...sx, '&:disabled': { ...disabledStyling } }}
        >
            {loading && <CircularProgress size='1.2rem' sx={{ marginRight: '0.5rem', color: progressColor ? progressColor : theme.palette.primaryColors[200] }} />}
            {btnTxt}
        </StyledOutlinedBtn>
    )
}
