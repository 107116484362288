import { SvgIcon } from '@mui/material';
import React from 'react'

export const HighValueSuppliesIcon = (props) => {
    return (
        <SvgIcon {...props}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1298_10114)">
        <path d="M16 29.3334C8.63596 29.3334 2.66663 23.3641 2.66663 16.0001C2.66663 8.63608 8.63596 2.66675 16 2.66675C23.364 2.66675 29.3333 8.63608 29.3333 16.0001C29.3333 23.3641 23.364 29.3334 16 29.3334ZM16 26.6667C18.8289 26.6667 21.542 25.5429 23.5424 23.5426C25.5428 21.5422 26.6666 18.8291 26.6666 16.0001C26.6666 13.1711 25.5428 10.458 23.5424 8.45761C21.542 6.45722 18.8289 5.33341 16 5.33341C13.171 5.33341 10.4579 6.45722 8.45749 8.45761C6.4571 10.458 5.33329 13.1711 5.33329 16.0001C5.33329 18.8291 6.4571 21.5422 8.45749 23.5426C10.4579 25.5429 13.171 26.6667 16 26.6667ZM11.3333 18.6667H18.6666C18.8434 18.6667 19.013 18.5965 19.138 18.4715C19.2631 18.3465 19.3333 18.1769 19.3333 18.0001C19.3333 17.8233 19.2631 17.6537 19.138 17.5287C19.013 17.4037 18.8434 17.3334 18.6666 17.3334H13.3333C12.4492 17.3334 11.6014 16.9822 10.9763 16.3571C10.3511 15.732 9.99996 14.8841 9.99996 14.0001C9.99996 13.116 10.3511 12.2682 10.9763 11.6431C11.6014 11.0179 12.4492 10.6667 13.3333 10.6667H14.6666V8.00008H17.3333V10.6667H20.6666V13.3334H13.3333C13.1565 13.3334 12.9869 13.4037 12.8619 13.5287C12.7369 13.6537 12.6666 13.8233 12.6666 14.0001C12.6666 14.1769 12.7369 14.3465 12.8619 14.4715C12.9869 14.5965 13.1565 14.6667 13.3333 14.6667H18.6666C19.5507 14.6667 20.3985 15.0179 21.0236 15.6431C21.6488 16.2682 22 17.116 22 18.0001C22 18.8841 21.6488 19.732 21.0236 20.3571C20.3985 20.9822 19.5507 21.3334 18.6666 21.3334H17.3333V24.0001H14.6666V21.3334H11.3333V18.6667Z" fill="#474747"/>
        </g>
        <defs>
        <clipPath id="clip0_1298_10114">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </SvgIcon>
    )
};
