import { List, styled } from '@mui/material';
import { NavMenu } from './NavMenu';

export const Navbar = styled(List)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    padding: '0 2.5rem',
    borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
    gap: '1.5rem',
    height: '3rem',
}));

export const SecondaryNavbar = ({ linksMapping }) => {
    return (
        <Navbar flex={1}>
            <NavMenu linksMapping={linksMapping} />
        </Navbar>
    )
};
