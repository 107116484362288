import { useTheme, Box, Typography, Stack } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DashboardCards } from './DashboardCards';
import { DashboardFilter } from './DashboardFilter';
import { Looker } from '../../components/common/Looker/Looker';
import { lookerEmbedDomain, objectHasKey, reverseDate } from '../../utils';
import { format, isValid, parse } from 'date-fns';
import { StyledButtonGroup } from '../../components/common/Buttons/ButtonGroup';
import { useImmer } from 'use-immer';
import { Toast } from '../Obligations/Toast';
import { useUser } from '../../context';
import { useNavigate } from 'react-router-dom';
import { ExpandableLookerGrid } from '../../components/common/Looker/ExpandableLookerGrid';

export const GlobalDashboards = () => {
  const theme = useTheme();
  const { userToken } = useUser();
  const [toast, setToast] = useState({ type: '', message: '' });
  /* const defaultDateRange = [
    `01-01-${new Date().getFullYear()}`,
    `31-12-${new Date().getFullYear()}`,
  ]; */
  const defaultDateRange = [
    `01-01-2023`,
    `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
  ];
  const [defaultDates, setDefaultDates] = useState([]);
  const defaultFilters = {
    dateRange: null, // Default is start current year to date.
    region: undefined,
    country: undefined,
    entity: undefined,
    taxType: 'IDT',
    selectedCardGroup: 'Financial',
    selectedCard: 'Net VAT Position',
    period: 'Month',
  };
  const [filters, updateFilters] = useImmer({ ...defaultFilters });
  const [lookerJustDrilledDown, setLookerJustDrilledDown] = useState(false);
  const navigate = useNavigate();

  // #region Filter dropdown data.
  const [filterData, setFilterData] = useState();
  const [regionCountryData, setRegionCountryData] = useState();
  const [isFilterDataLoading, setIsFilterDataLoading] = useState(true);
  const [isRegionCountryDataLoading, setIsRegionCountryDataLoading] = useState(true);
  const areFiltersLoading = isFilterDataLoading || isRegionCountryDataLoading;

  const encodeFilterName = (name, type) => {
    const knownFilters = ['country', 'region', 'entity'];
    try {
      if (!filterData || !filterData[type])
        throw {
          user: `Unknown ${type}.`,
          debug: `Couldn't translate filter name to filter code.\ntype = ${type}, name = ${name}`,
        };
      if (!knownFilters.includes(type))
        throw {
          user: `Unknown filter.`,
          debug: `Couldn't find filter ${type} in known filters ${knownFilters}.`,
        };

      const candidateObject = filterData[type].find((candidate) => candidate.description === name);
      if (!candidateObject)
        throw {
          user: `Unknown ${type}.`,
          debug: `Couldn't translate filter name to filter code. No ${type} object with such name found.\ntype = ${type}, name = ${name}`,
        };

      return candidateObject.code;
    } catch (err) {
      setToast({
        type: 'failure',
        message: err.user,
      });
      console.error(err.debug);
    }
  };

  useEffect(() => {
    if (!userToken) return;
    setIsFilterDataLoading(true);

    const filterDataQuery = [
      filters.region ? 'region=' + filters.region : null,
      filters.country ? 'country=' + encodeFilterName(filters.country, 'country') : null,
    ]
      .filter((p) => p)
      .join('&');
    let _filterData = {};
    let min_date = '';
    let max_date = '';

    fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/transaction/getfilterdata${filterDataQuery ? '?' : ''}${filterDataQuery}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {

        return res.json();
      })
      .then((data) => {
        _filterData = data?.filter_data;
        min_date = data.min_date;
        max_date = data.max_date;
      })
      .catch((err) => {
        console.error('There was an error fetching filter data for Global Dashboard.', err);
        setToast({
          type: 'failure',
          message: 'Filter data could not be fetched.',
        });
      })
      .finally(() => {
        setFilterData(_filterData);
        setIsFilterDataLoading(false);
        let dates = [reverseDate(min_date, '-'), reverseDate(max_date, '-')];
        updateFilters((draft) => {
          draft.dateRange = dates.map((date) => parse(date, 'dd-MM-yyyy', new Date()));
        });
        setDefaultDates(dates.map((date) => parse(date, 'dd-MM-yyyy', new Date())));
      });
  }, [userToken, filters.region, filters.country]);

  useEffect(() => {
    if (!userToken) return;
    setIsRegionCountryDataLoading(true);

    let _regionCountryData;
    fetch(process.env.REACT_APP_BACKEND_URL + `/filterconfig/regions-and-countries`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        return res.json();
      })
      .then((data) => {
        _regionCountryData = data;
      })
      //.catch((err) => {})
      .finally(() => {
        setRegionCountryData(_regionCountryData);
        setIsRegionCountryDataLoading(false);
      });
  }, [userToken]);
  // #endregion

  const [iframeURL, setIframeURL] = useState('');

  const queryEncode = (str) => encodeURIComponent(str).replaceAll('%20', '+');
  const getToDate = (toDate) => {
    const date = new Date(toDate);
    date.setDate(date.getDate() + 1);
    return format(date, 'yyyy/MM/dd');
  };

  useEffect(() => {
    const baseURL = '/embed/dashboards';

    const dashboardNos = {
      Financial: {
        default: {
          global: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_FINANCIAL_GLOBAL,
          region: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_FINANCIAL_REGION,
          country: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_FINANCIAL_COUNTRY,
          entity: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_FINANCIAL_ENTITY,
        },
      },
      Obligations: {
        open_submitted: {
          global: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_GLOBAL_OPEN_SUBMITTED,
          region: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_REGION_OPEN_SUBMITTED,
          country: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_COUNTRY_OPEN_SUBMITTED,
          entity: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_ENTITY_OPEN_SUBMITTED,
        },
        pie: {
          global: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_GLOBAL_PIE,
          region: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_REGION_PIE,
          country: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_COUNTRY_PIE,
          entity: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_ENTITY_PIE,
        },
        default: {
          global: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_GLOBAL,
          region: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_REGION,
          country: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_COUNTRY,
          entity: process.env.REACT_APP_LOOKER_GLOBAL_DASHBOARD_OBLIGATION_ENTITY,
        },
      },
    };

    const pieChartCards = ['Overdue', 'Due within 7 days', 'Due within 14 days', 'In Review'];
    const dashboardCase =
      filters.selectedCardGroup === 'Financial'
        ? 'default'
        : ['Open', 'Submitted', 'Approved'].includes(filters.selectedCard)
          ? 'open_submitted'
          : pieChartCards.includes(filters.selectedCard)
            ? 'pie'
            : 'default';
    const zoomLevel = filters.entity
      ? 'entity'
      : filters.country
        ? 'country'
        : filters.region
          ? 'region'
          : 'global';

    const dashboardNo = dashboardNos[filters.selectedCardGroup][dashboardCase][zoomLevel];
    const filterQuery = [
      filters.region ? `Region=${queryEncode(filters.region)}` : null,
      filters.country ? `Country=${queryEncode(filters.country)}` : null,
      filters.entity ? `Entityname=${queryEncode(filters.entity)}` : null,
      // Selected card.
      `${filters.selectedCardGroup === 'Financial'
        ? 'Select+Measure'
        : dashboardCase === 'open_submitted'
          ? 'Obl+Status+Measure'
          : dashboardCase === 'pie'
            ? 'Status+Param'
            : 'Obl+Select+Measure'
      }=${queryEncode(filters.selectedCard)}`,
      // Date range.
      filters.dateRange
        ? `Postingdate+Date=${format(filters.dateRange[0], 'yyyy/MM/dd')}+to+${getToDate(
          filters.dateRange[1]
        )}`
        : null,
      !pieChartCards.includes(filters.selectedCard) ? `Pm+Dim+Button=${filters.period}` : null,
      // TODO: Add Tax Type filter, eventually.
    ]
      .filter((f) => f) // Filter out the nulls.
      .join('&');

    const extraSetupDictionary = {
      Financial: {
        default:
          'hide_filter=Region&hide_filter=Country&hide_filter=Entity&hide_filter=Select+Measure&hide_filter=Postingdate+Date&hide_filter=Pm+Dim+Button&hide_filter=Entityname',
      },
      Obligations: {
        open_submitted:
          'hide_filter=Region&hide_filter=Country&hide_filter=Entity&hide_filter=Status+Param&hide_filter=Postingdate+Date&hide_filter=Entityname&hide_filter=Obl+Status+Measure&hide_filter=Pm+Dim+Button',
        pie: 'hide_filter=Region&hide_filter=Country&hide_filter=Entit&hide_filter=Status+Param&hide_filter=Postingdate+Date&hide_filter=Entityname',
        default:
          'hide_filter=Region&hide_filter=Country&hide_filter=Entity&hide_filter=Postingdate+Date&hide_filter=Pm+Dim+Button&hide_filter=Obl+Select+Measure&hide_filter=Entityname',
      },
    };
    const extraSetup = extraSetupDictionary[filters.selectedCardGroup][dashboardCase];

    const finalURL = `${baseURL}/${dashboardNo}?embed_domain=${lookerEmbedDomain}&${filterQuery}${filterQuery ? '&' : ''
      }${extraSetup}`;

    setIframeURL(finalURL);
  }, [filters]);

  const dateRangePickRef = useRef(null);

  const [lookerFilters, setLookerFilters] = useState(null);

  const onLookerMessage = (data) => {
    const _lookerFilters = data?.dashboard?.dashboard_filters;

    if (
      data.type !== 'dashboard:run:complete' ||
      !objectHasKey(data, 'dashboard') ||
      !objectHasKey(data.dashboard, 'dashboard_filters') ||
      Object.keys(_lookerFilters).length === 0
    )
      return;
    const undefinedIfEmpty = (str) => (str === '' ? undefined : str);

    const newDateRange = _lookerFilters['Postingdate Date']
      .split(' to ')
      .map((date) => parse(date, 'yyyy/MM/dd', new Date()));
    if (newDateRange.some((date) => !isValid(date)))
      return setToast({ type: 'failure', message: "Couldn't set filters from Looker." });

    //setFilterLock(true)
    setLookerFilters({
      //dateRange: [...newDateRange],
      entity: undefinedIfEmpty(_lookerFilters['Entityname']),
      region: undefinedIfEmpty(_lookerFilters['Region']),
      country: undefinedIfEmpty(_lookerFilters['Country']),
    });
  };

  useEffect(() => {
    if (!lookerFilters) return;
    const isDrillingDown =
      (lookerFilters.entity && !filters.entity) ||
      (lookerFilters.country && !filters.country) ||
      (lookerFilters.region && !filters.region);
    if (!isDrillingDown) return;

    setLookerJustDrilledDown(true);

    updateFilters((draft) => {
      Object.assign(draft, lookerFilters);
    });
    //dateRangePickRef?.current?.overrideRange([...lookerFilters.dateRange]);
  }, [lookerFilters]);

  return (
    <Stack>
      <Box
        padding='1.875rem 2.5rem'
        sx={{
          backgroundColor: theme.palette.baseColors.white,
        }}>
        <Stack
          direction='row'
          alignItems='baseline'
          gap='.5rem'>
          <Typography
            component='h1'
            sx={{
              color: theme.palette.random[300],
              fontSize: '2.25rem',
              fontWeight: '700',
            }}>
            Global Dashboard
          </Typography>
          <Typography
            component='h1'
            sx={{
              color: theme.palette.secondaryColors[400],
              fontSize: '1.25rem',
              fontWeight: '500',
            }}>
            (Viewing USD)
          </Typography>
        </Stack>
      </Box>

      <Stack
        p='2.5rem'
        gap='1.5rem'>
        <Stack>
          <DashboardFilter
            filterOptions={filterData}
            isLoading={areFiltersLoading}
            regionCountryData={regionCountryData}
            filterNameEncoder={encodeFilterName}
            filters={filters}
            updateFilters={updateFilters}
            defaultFilters={defaultFilters}
            defaultDates={defaultDates}
            ref={dateRangePickRef}
          />
          <DashboardCards
            filterNameEncoder={encodeFilterName}
            filters={filters}
            updateFilters={updateFilters}
          />
        </Stack>
        <Box sx={{ position: 'relative' }}>
          {/* <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            gap='1.5rem'
            mb='-1.5rem'
            height='4.5rem'
            sx={{
              position: 'absolute',
              top: '-1.4rem',
              right: 0,
            }}>
            {['Overdue', 'Due within 7 days', 'Due within 14 days', 'In Review'].includes(
              filters.selectedCard
            ) || (
                <StyledButtonGroup
                  currentOption={filters.period}
                  options={['YTD', 'Year', 'Month']}
                  setOption={(option) => {
                    updateFilters((draft) => {
                      draft.period = option;
                    });
                  }}
                />
              )}
          </Stack> */}
          <Stack>
            <ExpandableLookerGrid
              cards={[]}
              actionBar={
                <Stack
                  direction='row-reverse'
                  alignItems='center'
                  gap='1.5rem'
                  height='1'>
                  {['Overdue', 'Due within 7 days', 'Due within 14 days', 'In Review'].includes(
                    filters.selectedCard
                  ) || (
                      <StyledButtonGroup
                        currentOption={filters.period}
                        sx={{
                          '.MuiButton-outlined': {
                            borderColor: '#0000001A',
                            height: '2.5rem',
                            '&:hover': { borderColor: '#0000001A' },
                          },
                          '.MuiButton-contained': {
                            height: '2.5rem',
                          },
                          mb: '5px',
                        }}
                        options={['YTD', 'Year', 'Month']}
                        setOption={(option) => {
                          updateFilters((draft) => {
                            draft.period = option;
                          });
                        }}
                      />
                    )}
                </Stack>
              }
              actionBarStyling={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
              dashboardParametersStringOverride={iframeURL.split('&').slice(1).join('&')} // Get everything after the first ampersand (after the embed domain)
              downloadConstants={{
                dashboard: iframeURL.split('?')[0].substring(6), // Get everything before the query parameters' question mark but after "embed/"
                filename: `Global_Dashboard`,
                title: 'Global Dashboard',
              }}
              hideDownload={false}
            />
            <Box mt='-1.5rem'>
              <Looker
                dashboard={iframeURL}
                urlLock={lookerJustDrilledDown}
                urlUnlock={() => {
                  setLookerJustDrilledDown(false);
                }}
                title='Global Dashboard'
                description='Global Dashboard summaries'
                iframeTitle='Global Dashboard summaries'
                aspectRatio={1.7}
                header={false}
                onIframeMessage={onLookerMessage}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </Stack>
  );
};
