import { createContext, useContext, useEffect, useState } from "react";

const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [notificationInfo,setNotificationInfo] = useState({
        type:'',
        message:'',
        icon:null
    });
    return (
        <NotificationContext.Provider value={{ show,setShow,notificationInfo,setNotificationInfo}}>
            {children}
        </NotificationContext.Provider>
    )
}