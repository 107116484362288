import { SvgIcon } from '@mui/material';

export const TotalSupplyIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 14H16V2H6V4H14V14ZM14 16V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3841C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.01 4H4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14ZM4 14V16H6V17H8V16H8.5C9.16304 16 9.79893 15.7366 10.2678 15.2678C10.7366 14.7989 11 14.163 11 13.5C11 12.837 10.7366 12.2011 10.2678 11.7322C9.79893 11.2634 9.16304 11 8.5 11H5.5C5.36739 11 5.24021 10.9473 5.14645 10.8536C5.05268 10.7598 5 10.6326 5 10.5C5 10.3674 5.05268 10.2402 5.14645 10.1464C5.24021 10.0527 5.36739 10 5.5 10H10V8H8V7H6V8H5.5C4.83696 8 4.20107 8.26339 3.73223 8.73223C3.26339 9.20107 3 9.83696 3 10.5C3 11.163 3.26339 11.7989 3.73223 12.2678C4.20107 12.7366 4.83696 13 5.5 13H8.5C8.63261 13 8.75979 13.0527 8.85355 13.1464C8.94732 13.2402 9 13.3674 9 13.5C9 13.6326 8.94732 13.7598 8.85355 13.8536C8.75979 13.9473 8.63261 14 8.5 14H4Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};