import { SvgIcon } from '@mui/material';

export const SortIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 18L8 14H16L12 18Z" fill="currentColor" />
                <path d="M12 6L16 10L8 10L12 6Z" fill="currentColor" />
            </svg>
        </SvgIcon>
    )
};
