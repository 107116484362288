//This Card will be Used in Home screen to Show recent Edited Obligation on Home Page
import { Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useUser } from "../../../context/user";
import { useNavigate } from 'react-router-dom'
import { useObligation } from "../../../context";
const CardTypeB = (props) => {
  const { userToken } = useUser();
  const [Tabdata, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate()
  const theme = useTheme()
  let arr = ["A", "s", "j", "k"];
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (Tabdata?.length === 0) {
      setIsError(false);
      fetch(
        process.env.REACT_APP_BACKEND_URL +
        `/obligation/upcoming_obligations`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          credentials: "include",
        }
      )
        .then((res) => {

          return res.json()
        })
        .then((data) => {
          setData(data.obligations);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setIsError(true);
        });
    }
  }, []);

  const { selectedObgHandler } = useObligation();
  const navigate = useNavigate()
  return (
    <Stack
      sx={{
        maxHeight: "auto",
        minHeight: "19rem",
        width: "100%",
        border: `1px solid ${theme.palette.neutralColors.black[200]} `,
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "0.5rem",
        padding: "0.8rem 2rem 0rem 1.5rem ",
        gap: '0.5rem'
      }}
    >
      <h3 style={{ marginTop: "0", marginBottom: "0.5rem", fontSize: "115%" }}>
        Recently Edited Obligations{" "}
      </h3>
      <Stack sx={{ width: "95%", height: "95%", margin: "auto" }}>
        {loading && (
          <>
            {" "}
            {arr.map((a) => {
              return <Skeleton variant="text" sx={{ fontSize: "3rem", mt: "0.5rem" }} />;
            })}
          </>
        )}

        {!loading && Tabdata?.length === 0 && <Typography sx={{ textAlign: "center", width: 1, fontSize: ".875rem" }}>{isError ? "Error fetching data" : "No recent obligations found"}</Typography>}

        {
          !loading &&
          Tabdata?.length > 0 && (
            <>
              {Tabdata?.map((tab, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: index === 0 ? "0.75rem" : '',
                      mb: "0rem",
                      gap: '0.5rem',
                      // height: '3rem',
                      borderBottom: index === Tabdata.length - 1 ? 'none' : `1px solid ${theme.palette.neutralColors.black[200]}`
                    }}
                  >
                    <Stack>
                      <Typography sx={{ fontSize: "0.875rem", color: `${theme.palette.secondaryColors[300]}`, fontWeight: 500 }}>
                        {tab?.member}
                      </Typography>
                      <Typography sx={{ fontSize: "0.875rem", color: `${theme.palette.baseColors.grey}`, fontWeight: 500 }}>
                        {tab?.obligation_name}
                      </Typography>

                    </Stack>
                    <Button
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      onClick={() => {
                        navigate(`/obligations/${tab["obligation_id"]}/${tab["member"]}/overview`)

                      }}
                      variant="text"
                      sx={{
                        mt: "0rem",
                        width: "fit-content",
                        padding: "0",
                        color: theme.palette.primaryColors[200],
                        fontWeight: "500",
                        fontSize: "87.5%",
                        textTransform: "none",
                        textDecoration: "underline",
                        "&:hover": {
                          textDecoration: "underline",
                          backgroundColor: "transparent"
                        },
                      }}
                    >
                      View
                    </Button>
                  </div>
                );
              })}
            </>
          )}
      </Stack>
    </Stack >
  );
};

export default CardTypeB;
