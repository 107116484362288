import { SvgIcon } from '@mui/material';
import React from 'react'

export const HighValuePurchasesIcon = (props) => {
    return (
        <SvgIcon {...props}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.16335 6.01343C9.59895 3.8526 12.7434 2.66163 15.9993 2.66676C23.3633 2.66676 29.3327 8.6361 29.3327 16.0001C29.337 18.7253 28.5023 21.3858 26.942 23.6201L23.3327 16.0001H26.666C26.6661 13.8792 26.0339 11.8065 24.8502 10.0467C23.6665 8.28688 21.9851 6.91988 20.0207 6.12031C18.0564 5.32074 15.8982 5.1249 13.822 5.55782C11.7458 5.99074 9.8458 7.03276 8.36468 8.55077L7.16468 6.01476L7.16335 6.01343ZM24.8354 25.9868C22.3998 28.1476 19.2553 29.3386 15.9993 29.3334C8.63535 29.3334 2.66602 23.3641 2.66602 16.0001C2.66602 13.1668 3.55002 10.5401 5.05668 8.3801L8.66602 16.0001H5.33268C5.33264 18.121 5.96484 20.1937 7.14852 21.9535C8.3322 23.7133 10.0136 25.0803 11.978 25.8799C13.9423 26.6795 16.1005 26.8753 18.1767 26.4424C20.2529 26.0095 22.1529 24.9674 23.634 23.4494L24.834 25.9854L24.8354 25.9868ZM11.3327 18.6668H18.666C18.8428 18.6668 19.0124 18.5965 19.1374 18.4715C19.2624 18.3465 19.3327 18.1769 19.3327 18.0001C19.3327 17.8233 19.2624 17.6537 19.1374 17.5287C19.0124 17.4037 18.8428 17.3334 18.666 17.3334H13.3327C12.4486 17.3334 11.6008 16.9822 10.9757 16.3571C10.3505 15.732 9.99935 14.8842 9.99935 14.0001C9.99935 13.116 10.3505 12.2682 10.9757 11.6431C11.6008 11.018 12.4486 10.6668 13.3327 10.6668H14.666V9.33343H17.3327V10.6668H20.666V13.3334H13.3327C13.1559 13.3334 12.9863 13.4037 12.8613 13.5287C12.7363 13.6537 12.666 13.8233 12.666 14.0001C12.666 14.1769 12.7363 14.3465 12.8613 14.4715C12.9863 14.5965 13.1559 14.6668 13.3327 14.6668H18.666C19.5501 14.6668 20.3979 15.018 21.023 15.6431C21.6482 16.2682 21.9993 17.116 21.9993 18.0001C21.9993 18.8842 21.6482 19.732 21.023 20.3571C20.3979 20.9822 19.5501 21.3334 18.666 21.3334H17.3327V22.6668H14.666V21.3334H11.3327V18.6668Z" fill="#474747"/>
        </svg>
        </SvgIcon>
    )
};
