import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../../context/user';
import { WhiteCard } from '../../WhiteCard';
import { Box, Stack, Typography, styled } from '@mui/material';
import { BtnLink } from '../../../../components/common/Buttons/BtnLink';
import {
  conditionalEntityCodeQuery,
  currencyFormatter,
  currencyFormatterWithoutSymbol,
} from '../../../../utils';
import { GLOBAL_CURRENCY, GLOBAL_CURRENCY_SYMBOL } from '../../../../constants/constants';
import { useObligation } from '../../../../context';

const StyledCard = styled(Box)(({ theme }) => ({
  padding: '1rem',
  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
  flex: '1 1 0',
}));
const StatsCard = ({ data }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id, member } = useParams();
  return (
    <>
      {data?.map((obj) =>
        obj.type === 'link' ? (
          <StyledCard>
            <Typography
              mb='0.75rem'
              sx={{
                fontWeight: 500,
                color: theme.palette.baseColors.grey,
              }}>
              {obj.name}
            </Typography>
            <BtnLink
              btnTxt={obj.value}
              styling={{ fontSize: '1.5rem', fontWeight: 700, textDecoration: 'underline' }}
              onClick={() => {
                navigate(`/obligations/${id}/${member}/supply-transactions`);
              }}
            />
          </StyledCard>
        ) : (
          <StyledCard>
            <Typography
              mb='0.75rem'
              sx={{
                fontWeight: 500,
                color: theme.palette.baseColors.grey,
              }}>
              {obj.name}
            </Typography>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                color: theme.palette.baseColors.grey,
              }}>
              {obj.value}
            </Typography>
          </StyledCard>
        )
      )}
    </>
  );
};

export const Stats = () => {
  const { selectedObgDetails } = useObligation();
  // const obligation = selectedObgDetails
  const { userToken } = useUser();
  const navigate = useNavigate()
  const [cardsData, setCardsData] = useState([
    { name: 'Total Supply Transactions', value: null, type: 'link' },
    { name: 'Gross Supply Amount', value: null, type: 'text' },
    { name: 'Total Tax', value: null, type: 'text' },
    { name: 'Net Supply Amount', value: null, type: 'text' },
  ]);
  const theme = useTheme();

  useEffect(() => {
    if (selectedObgDetails?.obligation_id && userToken) {
      const entityCodeQuery = conditionalEntityCodeQuery(selectedObgDetails);
      fetch(
        process.env.REACT_APP_BACKEND_URL +
        `/transaction/gettransactionsummary?source=AR&obligation_id=${selectedObgDetails?.obligation_id}${entityCodeQuery}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setCardsData([
            {
              name: 'Total Supply Transactions',
              value: data.Total_transaction
                ? currencyFormatterWithoutSymbol(data.Total_transaction)
                : 0,
              type: 'link',
            },
            {
              name: 'Gross Supply Amount',
              value: data.Gross_Amount
                ? `${currencyFormatter(data.Gross_Amount, GLOBAL_CURRENCY)}`
                : `${GLOBAL_CURRENCY_SYMBOL}0`,
              type: 'text',
            },
            {
              name: 'Total Tax',
              value: data.Total_Tax
                ? `${currencyFormatter(data.Total_Tax, GLOBAL_CURRENCY)}`
                : `${GLOBAL_CURRENCY_SYMBOL}0`,
              type: 'text',
            },
            {
              name: 'Net Supply Amount',
              value: data.Net_Amount
                ? `${currencyFormatter(data.Net_Amount, GLOBAL_CURRENCY)}`
                : `${GLOBAL_CURRENCY_SYMBOL}0`,
              type: 'text',
            },
          ]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [userToken, selectedObgDetails]);
  return (
    <WhiteCard style={{ padding: '1.5rem', gap: 0 }}>
      <Typography
        gutterBottom
        component='h4'
        color={theme.palette.baseColors.grey}
        sx={{
          fontWeight: 700,
          fontSize: '1.125rem',
          marginBottom: '1.5rem',
        }}>
        Summary of Supply
      </Typography>
      <Stack
        direction='row'
        gap='1.5rem'>
        <StatsCard data={cardsData} />
      </Stack>
    </WhiteCard>
  );
};
