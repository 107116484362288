import { SvgIcon } from '@mui/material';
import React from 'react'

export const TransactionAnalyticsIcon = (props) => {
    return (
        <SvgIcon {...props}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1298_10111)">
        <path d="M14.6666 2.66675C21.2906 2.66675 26.6666 8.04275 26.6666 14.6667C26.6666 21.2907 21.2906 26.6667 14.6666 26.6667C8.04263 26.6667 2.66663 21.2907 2.66663 14.6667C2.66663 8.04275 8.04263 2.66675 14.6666 2.66675ZM14.6666 24.0001C19.8226 24.0001 24 19.8227 24 14.6667C24 9.50941 19.8226 5.33341 14.6666 5.33341C9.50929 5.33341 5.33329 9.50941 5.33329 14.6667C5.33329 19.8227 9.50929 24.0001 14.6666 24.0001ZM25.98 24.0947L29.752 27.8654L27.8653 29.7521L24.0946 25.9801L25.98 24.0947Z" fill="#474747"/>
        </g>
        <defs>
        <clipPath id="clip0_1298_10111">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </SvgIcon>
    )
};
