import React from 'react';
import { Box, Typography, Card, Divider, useTheme } from '@mui/material';
import { WhiteCard } from '../../WhiteCard';
import { FieldCell } from './FieldCell';
import { TransactionsTable } from './TransactionTable';
import dayjs from 'dayjs';

export const SourceData = ({ data }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        p: 2,
        margin: 'auto',
        overflow: 'hidden',
        width: '100%',
        borderRadius: '0.5rem',
      }}>
      <Box mt={2}>
        <Typography
          sx={{
            fontSize: '1.125rem',
            fontWeight: 500,
            color: '#000000',
          }}>
          Source Data Details
        </Typography>
        <Divider sx={{ mt: 1, mb: 2 }} />
      </Box>
      <Box
        display='grid'
        gridTemplateColumns='repeat(8, 1fr)'
        gridTemplateROws='repeat(10, 1fr)'>
        <Box gridArea='1/1/2/3'>
          <WhiteCard
            style={{
              height: '100px',
              marginRight: '20px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <FieldCell
              label='Source Platform'
              name='source_platform'
              value={data?.source_platform}
              fieldStyles={{ marginTop: 0 }}
              isPermanent
            />
          </WhiteCard>
        </Box>
        <Box gridArea='1/3/2/5'>
          <WhiteCard
            style={{
              height: '100px',
              marginRight: '10px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <FieldCell
              label='Source File Type'
              name='source'
              value={data?.source}
              fieldStyles={{ marginTop: 0 }}
              isPermanent
            />
          </WhiteCard>
        </Box>
        <Box gridArea='1/5/2/7'>
          <WhiteCard
            style={{
              height: '100px',
              marginLeft: '10px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <FieldCell
              label='Entered By'
              name='entered_by'
              value={data?.enteredby}
              fieldStyles={{ marginTop: 0 }}
              isPermanent
            />
          </WhiteCard>
        </Box>
        <Box gridArea='1/7/2/9'>
          <WhiteCard
            style={{
              height: '100px',
              marginLeft: '20px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <FieldCell
              label='Posting Date'
              name='posting_date'
              value={dayjs(data?.postingdate)?.format('DD MMM YYYY')}
              fieldStyles={{ marginTop: 0 }}
              isPermanent
            />
          </WhiteCard>
        </Box>
        <Box gridArea='2/1/4/5'>
          <WhiteCard
            style={{
              mt: 2,
              height: '200px',
              marginRight: '10px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 500,
                  color: '#000000',
                }}>
                Entity
              </Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>
            <Box
              display='grid'
              gridTemplateColumns='repeat(4, 1fr)'
              gridTemplateRows='1fr'>
              <Box gridArea='1 / 1 / 2 / 2'>
                <FieldCell
                  label='Code'
                  name='code'
                  value={data?.entitycode}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 2 / 2 / 3'>
                <FieldCell
                  label='Name'
                  name='entity_name'
                  value={data?.entityname}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 3 / 2 / 4'>
                <FieldCell
                  label='Org Region'
                  name='entity_org_region'
                  value={data?.organisationalregion}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 4 / 2 / 5'>
                <FieldCell
                  label='Country Code'
                  name='entity_country_code'
                  value={data?.entitycountrycode}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
            </Box>
          </WhiteCard>
        </Box>
        <Box gridArea='2/5/4/9'>
          <WhiteCard
            style={{
              mt: 2,
              height: '200px',
              marginLeft: '10px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 500,
                  color: '#000000',
                }}>
                {data?.source === "AP" ? "Supplier" : "Customer"}
              </Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>
            <Box
              display='grid'
              gridTemplateColumns='repeat(4, 1fr)'
              gridTemplateRows='1fr'>
              <Box gridArea='1 / 1 / 2 / 2'>
                <FieldCell
                  label='VAT Registration No.'
                  name='supplier_gst_registration_number'
                  value={data?.source === "AP" ? data?.suppliervatregistrationno : data?.customervatregistrationno}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 2 / 2 / 3'>
                <FieldCell
                  label='Name'
                  name='supplier_name'
                  value={data?.source === "AP" ? data?.suppliername : data?.customername}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 3 / 2 / 4'>
                <FieldCell
                  label='Org Region'
                  name='supplier_org_region'
                  value={data?.source === "AP" ? data?.supplierregion : data?.customerregion}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 4 / 2 / 5'>
                <FieldCell
                  label='Country Code'
                  name='supplier_country_code'
                  value={data?.source === "AP" ? data?.suppliercountrycode : data?.customercountrycode}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
            </Box>
          </WhiteCard>
        </Box>
        <Box gridArea='4/1/6/9'>
          <WhiteCard
            style={{
              mt: 2,
              height: '250px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 500,
                  color: '#000000',
                }}>
                Transaction
              </Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>
            <Box
              display='grid'
              gridTemplateColumns='repeat(4, 1fr)'
              gridTemplateRows='repeat(2, 1fr)'>
              <Box gridArea='1 / 1 / 2 / 2'>
                <FieldCell
                  label='Invoice Number'
                  name='transaction_invoice_number'
                  value={data?.invoiceno}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 2 / 2 / 3'>
                <FieldCell
                  label='ERP Document Number'
                  name='transaction_erp_document_number'
                  value={data?.transaction_erpdocumentno}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 3 / 2 / 4'>
                <FieldCell
                  label='General Ledger'
                  name='transaction_general_ledger'
                  value={data?.glaccountno}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 4 / 2 / 5'>
                <FieldCell
                  label='VAT Flow'
                  name='transaction_gst_flow'
                  value={data?.flow}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='2 / 1 / 3 / 2'>
                <FieldCell
                  label='Invoice Date'
                  name='transaction_invoice_date'
                  value={dayjs(data?.invoicedate)?.format('DD MMM YYYY')}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='2 / 2 / 3 / 3'>
                <FieldCell
                  label='Entry Date'
                  name='transaction_entry_date'
                  value={dayjs(data?.postingdate)?.format('DD MMM YYYY')}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='2 / 3 / 3 / 4'>
                <FieldCell
                  label='Fiscal Month No.'
                  name='transaction_fiscal_month_number'
                  value={data?.fiscalmonthno}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='2 / 4 / 3 / 5'>
                <FieldCell
                  label='Fiscal Year'
                  name='transaction_fiscal_year'
                  value={data?.fiscalyear}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
            </Box>
          </WhiteCard>
        </Box>
        <Box gridArea='6/1/7/9'>
          <WhiteCard
            style={{
              mt: 2,
              height: '150px',
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem',
              gap: 0,
            }}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 500,
                  color: '#000000',
                }}>
                Item
              </Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>
            <Box
              display='grid'
              gridTemplateColumns='repeat(4, 1fr)'
              gridTemplateRows='1fr'>
              <Box gridArea='1 / 1 / 2 / 2'>
                <FieldCell
                  label='ERP Document Item No.'
                  name='item_erp_document_item_number'
                  value={data?.erpdocumentitemno}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 2 / 2 / 3'>
                <FieldCell
                  label='Invoice Line Description'
                  name='item_invoice_line_description'
                  value={data?.invoicelinedescription}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 3 / 2 / 4'>
                <FieldCell
                  label='Compliance Currency Conversion Rate'
                  name='item_compliance_currency_conversion_rate'
                  value={data?.complianceconversionrate}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
              <Box gridArea='1 / 4 / 2 / 5'>
                <FieldCell
                  label='Currency Conversion Status'
                  name='item_currency_conversion_status'
                  value={data?.currencyconversionstatus}
                  fieldStyles={{ marginTop: 0 }}
                  isPermanent
                />
              </Box>
            </Box>
          </WhiteCard>
        </Box>
        <Box gridArea='7/1/11/9'>
          <WhiteCard
            style={{
              mt: 2,
              backgroundColor: theme.palette.secondaryColors[100],
              padding: '0rem 1rem 1rem 1rem',
              gap: 0,
              gap: 0,
            }}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 500,
                  color: '#000000',
                }}>
                Item
              </Typography>
              <Divider sx={{ mt: 1, mb: 2 }} />
            </Box>
            <TransactionsTable
              formData={data}
              backgroundColor={theme.palette.secondaryColors[100]}
              isSourceData
            />
          </WhiteCard>
        </Box>
      </Box>
    </Card>
  );
};
