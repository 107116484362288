import { Card, CardContent, Typography, useTheme } from '@mui/material';
import React from 'react';
import { BtnLink } from '../../../components/common/Buttons/BtnLink';
import { useNavigate } from 'react-router-dom';
import { currencyFormatterWithoutSymbol } from '../../../utils';

export const SummaryCard = ({ title, value, url }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        padding: '1rem',
        boxShadow: 'none',
        borderRadius: '0.5rem',
        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
        backgroundColor: theme.palette.baseColors.white,
        flex: '1 1 0',
        color: theme.palette.random[300],
      }}>
      <CardContent>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '1rem',
          }}>
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.5rem',
            marginTop: '1.5rem',
            marginBottom: '0.25rem',
          }}>
          {value}
        </Typography>
        {url && (
          <BtnLink
            size='small'
            btnTxt='View All'
            styling={{ textDecoration: 'underline', fontSize: '1rem' }}
            onClick={() => {
              if (url) {
                navigate(url);
              }
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};
