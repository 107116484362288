import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { CloseIcon } from "../CustomIcons/CloseIcon";
import { RedWarningIcon } from "../CustomIcons/RedWarningIcon";
import { YellowWarningIcon } from "../CustomIcons/YellowWarningIcon";
import { InfoIcon } from "../CustomIcons/InfoIcon";
import { CheckCircleOutlinedIcon } from "../CustomIcons/CheckCircleOutlinedIcon";
import { LockIcon } from "../CustomIcons/LockIcon";

export default function ErrorBadge(props) {
  const [open, setOpen] = React.useState(false);
  const [ErrVariant, setErrVariant] = React.useState();
  const [msg, setMsg] = React.useState(`This is a sample Alert!`)

  React.useEffect(() => {
    setOpen(props.open);
    setErrVariant(props.variant);
    setMsg(props.children)
  }, [props]);

  const BGcolorsArr = {
    'error': "#FFE7E9",
    'warning': '#FFECD9',
    'info': '#E6E8E8',
    'success': "#E6E8E8",
    'locked': '#E6E8E8'
  }

  const FontColorsArr = {
    'error': "#E6000E",
    'warning': '#AD5700',
    'info': '#474747',
    'success': "#474747",
    'locked': '#474747'
  }

  const iconStyle = {
    color: FontColorsArr[ErrVariant]
  }

  return (
    <Box sx={{
      width: "100%",
      position: "sticky",
      top: "0"
    }}>
      <Collapse in={open}>
        <Alert
          iconMapping={{
            error: <RedWarningIcon sx={iconStyle} />,
            warning: <YellowWarningIcon sx={iconStyle} />,
            // Locked is like Info but with a different icon.
            info: ErrVariant === "locked" ? <LockIcon sx={iconStyle} /> : <InfoIcon />,
            success: <CheckCircleOutlinedIcon sx={iconStyle} />
          }}
          severity={ErrVariant === "locked" ? "info" : ErrVariant}
          action={
            <Box sx={{ alignItems: "center" }}>
              <CloseIcon
                aria-label="close"
                onClick={() => {
                  setOpen(false)
                  if (props.onClose) props.onClose()
                }}
                sx={{
                  cursor: "pointer",
                  color: "inherit"
                }}
              />
            </Box>
          }
          sx={{
            //mb: 2,
            padding: "0 2.5rem",
            height: "3rem",
            backgroundColor: BGcolorsArr[ErrVariant],
            color: FontColorsArr[ErrVariant],
            fontWeight: "bold",
            alignItems: "center",
            borderRadius: "0"
          }}
        >
          {msg}
        </Alert>
      </Collapse>

    </Box>
  );
}
