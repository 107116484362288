import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../../context/user';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { WhiteCard } from '../../WhiteCard';
import { SortDescIcon } from '../../../../components/common/CustomIcons/SortDescIcon';
import { SortAscIcon } from '../../../../components/common/CustomIcons/SortAscIcon';
import { SortIcon } from '../../../../components/common/CustomIcons/SortIcon';
import dayjs from 'dayjs';
import { useObligation } from '../../../../context';
import { NO_DATA } from '../constants';
import {
  currencyFormatterWithoutSymbol,
  getCurrentRelativePath,
  nullableInvoiceNumber,
} from '../../../../utils';
import { StyledLinearProgress } from '../../../../components/common/Progress/StyledLinearProgress';

const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  cursor: 'pointer',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 500,
  // todo: check below styling again
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
}));

export const Top30NetTransactions = () => {
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [loadingNetTransactions, setLoadingNetTransactions] = useState(false);
  const [netTransactions, setNetTransactions] = useState([]);
  const { selectedObgDetails } = useObligation();
  // const obligation = selectedObgDetails
  const { userToken } = useUser();
  const theme = useTheme();
  const navigate = useNavigate();
  const { id, member } = useParams();

  useEffect(() => {
    if (sortedColumn && sortingOrder && selectedObgDetails?.obligation_id && userToken) {
      setLoadingNetTransactions(true);
      setNetTransactions([]);
      const entityCodeQuery =
        selectedObgDetails?.org_heirarchy_code === selectedObgDetails?.obligation_group_id
          ? ''
          : `%26entitycode__eq=${selectedObgDetails?.org_heirarchy_code}`;
      fetch(
        process.env.REACT_APP_BACKEND_URL +
        `/transaction/listtopntransactions?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26source__eq=AP${entityCodeQuery}&sort_column=${sortedColumn}&sort_dir=${sortingOrder}&limit=30&detail_column=statnetamount`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          credentials: 'include',
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setNetTransactions(data.transactions);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoadingNetTransactions(false);
        });
    } else if (selectedObgDetails?.obligation_id && userToken) {
      setLoadingNetTransactions(true);
      const entityCodeQuery =
        selectedObgDetails?.org_heirarchy_code === selectedObgDetails?.obligation_group_id
          ? ''
          : `%26entitycode__eq=${selectedObgDetails?.org_heirarchy_code}`;
      fetch(
        process.env.REACT_APP_BACKEND_URL +
        `/transaction/listtopntransactions?filter_query=obligation_id__eq=${selectedObgDetails?.obligation_id}%26source__eq=AP${entityCodeQuery}&sort_column=statnetamount&sort_dir=desc&limit=30&detail_column=statnetamount`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          credentials: 'include',
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setNetTransactions(data.transactions);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoadingNetTransactions(false);
        });
    }
  }, [userToken, selectedObgDetails, sortedColumn, sortingOrder]);

  const getSortedDataHandler = (col_name) => {
    if (col_name === sortedColumn) {
      setSortingOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortedColumn(col_name);
      setSortingOrder('asc');
    }
  };

  return (
    <Box>
      <WhiteCard style={{ padding: '1.5rem', gap: 0 }}>
        <Typography
          gutterBottom
          component='h4'
          color={theme.palette.baseColors.grey}
          sx={{
            fontWeight: 700,
            fontSize: '1.125rem',
            marginBottom: '1.5rem',
          }}>
          {'Top 30 VAT Net Transactions'}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'none', overflow: 'auto', maxHeight: '33rem' }}>
          <Table
            aria-label='selected files'
            stickyHeader
            sx={{
              borderRadius: '0.5rem',
              border: `1px solid ${theme.palette.neutralColors.black[200]}`,
              overflow: 'scroll',
            }}>
            <TableHead
              sx={{
                backgroundColor: theme.palette.neutralColors.black[100],
                height: '2.5rem',
              }}>
              <TableRow variant='body2'>
                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('invoiceno');
                  }}
                  sx={{
                    maxWidth: 140,
                    minWidth: 140,
                  }}>
                  Inv No.
                  <IconButton disableRipple>
                    {sortedColumn === 'invoiceno' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('entityname');
                  }}
                  sx={{
                    maxWidth: 160,
                    minWidth: 160,
                  }}>
                  Entity
                  <IconButton disableRipple>
                    {sortedColumn === 'entityname' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  // style={{ width: '200px' }}
                  onClick={() => {
                    getSortedDataHandler('statcurrencycode');
                  }}
                  sx={{
                    maxWidth: 200,
                    minWidth: 200,
                  }}>
                  Statutory Currency
                  <IconButton disableRipple>
                    {sortedColumn === 'statcurrencycode' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('statgrossamount');
                  }}
                  sx={{
                    maxWidth: 100,
                    minWidth: 100,
                  }}>
                  Gross
                  <IconButton disableRipple>
                    {sortedColumn === 'statgrossamount' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('statnetamount');
                  }}
                  sx={{
                    maxWidth: 100,
                    minWidth: 100,
                  }}>
                  Net
                  <IconButton disableRipple>
                    {sortedColumn === 'statnetamount' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('statvatamount');
                  }}
                  sx={{
                    maxWidth: 100,
                    minWidth: 100,
                  }}>
                  VAT
                  <IconButton disableRipple>
                    {sortedColumn === 'statvatamount' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('invoicedate');
                  }}
                  sx={{
                    maxWidth: 140,
                    minWidth: 140,
                  }}>
                  Inv Date
                  <IconButton disableRipple>
                    {sortedColumn === 'invoicedate' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('postingdate');
                  }}
                  sx={{
                    maxWidth: 160,
                    minWidth: 160,
                  }}>
                  Posting Date
                  <IconButton disableRipple>
                    {sortedColumn === 'postingdate' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('taxcode');
                  }}
                  sx={{
                    maxWidth: 140,
                    minWidth: 140,
                  }}>
                  Tax Code
                  <IconButton disableRipple>
                    {sortedColumn === 'taxcode' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('compliancecounterpartycompanyname');
                  }}
                  sx={{
                    maxWidth: 160,
                    minWidth: 160,
                  }}>
                  Counterparty
                  <IconButton disableRipple>
                    {sortedColumn === 'compliancecounterpartycompanyname' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>

                <StyledTableHeadingCell
                  onClick={() => {
                    getSortedDataHandler('suppliercountrycode');
                  }}
                  sx={{
                    maxWidth: 120,
                    minWidth: 120,
                  }}>
                  Country
                  <IconButton disableRipple>
                    {sortedColumn === 'suppliercountrycode' ? (
                      sortingOrder === 'asc' ? (
                        <SortDescIcon />
                      ) : (
                        <SortAscIcon />
                      )
                    ) : (
                      <SortIcon />
                    )}
                  </IconButton>
                </StyledTableHeadingCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingNetTransactions && (
                <TableRow>
                  <TableCell
                    colSpan={12}
                    sx={{ p: 0 }}>
                    <StyledLinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {netTransactions?.length > 0 ? (
                netTransactions?.map((transaction) => (
                  <TableRow
                    key={transaction.transactionconcatenate}
                    sx={{
                      height: '3rem',
                      border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                    }}>
                    <StyledTableBodyCell
                      sx={{
                        color: theme.palette.primaryColors[200],
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        maxWidth: 160,
                        minWidth: 160,
                      }}
                      onClick={() => {
                        navigate(
                          `/obligations/${id}/${member}/individual-transaction?id=${transaction.id
                          }&code=${transaction.entitycode}&backAddress=${getCurrentRelativePath()}`
                        );
                      }}>
                      {nullableInvoiceNumber(transaction.invoiceno, transaction.erpdocumentno)}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 160,
                        minWidth: 160,
                      }}>
                      {transaction.entityname ? transaction.entityname : NO_DATA}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 100,
                        minWidth: 100,
                      }}>
                      {transaction.statcurrencycode ? transaction.statcurrencycode : NO_DATA}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 100,
                        minWidth: 100,
                      }}>
                      {transaction.statgrossamount
                        ? currencyFormatterWithoutSymbol(transaction.statgrossamount, false, {
                          minimumFractionDigits: 2,
                        })
                        : NO_DATA}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 100,
                        minWidth: 100,
                      }}>
                      {transaction.statnetamount
                        ? currencyFormatterWithoutSymbol(transaction.statnetamount, false, {
                          minimumFractionDigits: 2,
                        })
                        : NO_DATA}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 100,
                        minWidth: 100,
                      }}>
                      {transaction.statvatamount
                        ? currencyFormatterWithoutSymbol(transaction.statvatamount, false, {
                          minimumFractionDigits: 2,
                        })
                        : NO_DATA}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 140,
                        minWidth: 140,
                      }}>
                      {transaction.invoicedate
                        ? dayjs(transaction.invoicedate).format('DD MMM YYYY')
                        : '-'}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        textTransform: 'capitalize',
                        maxWidth: 160,
                        minWidth: 160,
                      }}>
                      {transaction.postingdate
                        ? dayjs(transaction.postingdate).format('DD MMM YYYY')
                        : '-'}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 140,
                        minWidth: 140,
                      }}>
                      {transaction.taxcode ? transaction.taxcode : NO_DATA}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 160,
                        minWidth: 160,
                      }}>
                      {transaction.compliancecounterpartycompanyname
                        ? transaction.compliancecounterpartycompanyname
                        : NO_DATA}
                    </StyledTableBodyCell>

                    <StyledTableBodyCell
                      sx={{
                        maxWidth: 120,
                        minWidth: 120,
                      }}>
                      {transaction.suppliercountrycode ? transaction.suppliercountrycode : NO_DATA}
                    </StyledTableBodyCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{
                    height: '3rem',
                    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  }}>
                  <TableCell
                    colSpan={12}
                    sx={{ textAlign: 'center' }}>
                    {loadingNetTransactions ? 'Fetching data...' : 'No data to display'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </WhiteCard>
    </Box>
  );
};
