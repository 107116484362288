import { Typography, styled, useTheme, ToggleButtonGroup, ToggleButton } from '@mui/material';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: "0 1rem",
  fontWeight: "500",
  '&:hover': {
    backgroundColor: theme.palette.baseColors.white
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primaryColors[100]
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.primaryColors[100]
  }
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  height: "3rem",
  border: theme.palette.neutralColors.black[200],
  fontSize: "1.5rem",
  fontWeight: "bold",
  borderRadius: "8px"
}));

export const IconToogle = ({ viewHandler, view, leftData, rightData }) => {
  const theme = useTheme();
  const switchViewHandler = (e, newView) => {
    if (newView) {
      viewHandler(newView);
    }
  };
  return (
    <StyledToggleButtonGroup
      value={view}
      exclusive
      onChange={switchViewHandler}
      aria-label="switch views"
    >
      <StyledToggleButton
        disableRipple
        value={leftData.view}
        aria-label="view stats">

        {
          leftData.icon ? leftData.icon :
            <Typography sx={{
              textTransform: 'capitalize',
              color: theme.palette.primaryColors[200]
            }}>
              {leftData.view}
            </Typography>
        }

      </StyledToggleButton>
      <StyledToggleButton
        disableRipple
        value={rightData.view}
        aria-label="view graphs"
      >
        {
          rightData.icon ? rightData.icon :
            <Typography sx={{
              textTransform: 'capitalize',
              color: theme.palette.primaryColors[200]
            }}>
              {rightData.view}
            </Typography>
        }

      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
}