import { Box, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Looker } from '../../../components/common/Looker/Looker';
import { useObligation } from '../../../context';
import { lookerEmbedDomain, lookerEntityCodeList } from '../../../utils';
import { ExpandableLookerGrid } from '../../../components/common/Looker/ExpandableLookerGrid';
import { EXCEPTIONS_CARD_INFO } from '../lookerConstants';
import { SimpleSelect } from '../../../components/common/Dropdowns/SimpleSelect';

export const ExceptionsLooker = () => {
    const theme = useTheme()

    const { selectedObgDetails, isGroupSelected, sisterObligations } = useObligation();
    const [measure, setMeasure] = useState("Value")

    const measureOptions = ["Value", "Count"]
    const measureQuery = measure === "Value" ? 1 : 2

    return (
        <Stack>
            <ExpandableLookerGrid
                cards={EXCEPTIONS_CARD_INFO}
                dashboardParameters={{
                    "Obligation ID": selectedObgDetails?.obligation_id,
                    "Entitycode": lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails),
                    "Show By": measureQuery
                }}
                downloadConstants={{
                    dashboard: process.env.REACT_APP_LOOKER_EXCEPTIONS,
                    filename: `${selectedObgDetails?.obligation_name}_Exceptions`,
                    title: "Exceptions"
                }}
                actionBar={
                    <Stack width="10rem">
                        <SimpleSelect
                            id="show_by"
                            options={measureOptions}
                            value={measure}
                            onChange={e => setMeasure(e.target.value)}
                            renderValue={value => <><span style={{ color: theme.palette.secondaryColors[300] }}>Show by:</span> {value}</>}
                        />
                    </Stack>
                }
            />
      </Stack>
    )
};
