import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BtnStandard } from '../Buttons/BtnStandard';
import { BtnFilled } from '../Buttons/BtnFilled';
import { useTheme } from '@mui/material';

export const WarningPopup = ({
  continueHandler,
  closeWarningPopupHandler,
  warningTitle,
  warningMessage,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle
        id='alert-dialog-title'
        sx={{
          fontSize: '1.5rem',
          fontWeight: 700,
          color: theme.palette.baseColors.black,
        }}>
        {warningTitle ?? 'Discard note?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id='alert-dialog-description'
          sx={{ color: theme.palette.baseColors.grey }}>
          {warningMessage ??
            'Are you sure you want to discard your changes? Your unsaved edits will be lost.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BtnStandard
          btnTxt='Cancel'
          onClick={closeWarningPopupHandler}
        />
        <BtnFilled
          size='large'
          btnTxt='Continue'
          onClick={continueHandler}
        />
      </DialogActions>
    </Dialog>
  );
};
