import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BtnStandard } from "../../../../components/common/Buttons/BtnStandard";
import { BackButton } from "../../../../components/common/Buttons/Utility/BackButton";
import { ArrowLeftIcon } from "../../../../components/common/CustomIcons/ArrowLeftIcon";
import { NavMenu } from "../../../../components/common/Navbar/NavMenu";
import { StyledLinearProgress } from "../../../../components/common/Progress/StyledLinearProgress";

export const PageSkeleton = ({
  title,
  description,
  currentTab,
  availableTabs,
  hasNavMenu = true,
  isLoading,
  url,
  children,
}) => {
  const theme = useTheme();
  const { id, tab } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <Stack height={1}>
      <Box
        padding={hasNavMenu ? "1.875rem 2.5rem 0 2.5rem" : "1.875rem 2.5rem"}
        sx={{
          backgroundColor: theme.palette.baseColors.white,
        }}
      >
        <BackButton backAddress={url} />
        <Typography
          component="h1"
          sx={{
            color: theme.palette.random[300],
            fontSize: "2.25rem",
            fontWeight: "700",
          }}
        >
          {title}
        </Typography>
        <Typography
          component="h5"
          sx={{
            mt: ".25rem",
            color: theme.palette.random[600],
            fontSize: "0.875rem",
            fontWeight: "500",
          }}
        >
          {description}
        </Typography>
        {hasNavMenu && (
          <Box
            display="flex"
            width="100%"
            sx={{
              gap: "1.5rem",
              height: "3rem",
              mt: 3,
              mb: "-1px",
            }}
          >
            <NavMenu
              availableTabs={availableTabs}
              currentTab={currentTab}
              useParamsTab={false}
            />
          </Box>
        )}
      </Box>

      <Stack padding={!isLoading && "2.5rem"} gap="1.5rem">
        {isLoading ? <StyledLinearProgress /> : children}
      </Stack>
    </Stack>
  );
};
