import { LinearProgress, useTheme } from "@mui/material"

export const StyledLinearProgress = props => {
    const theme = useTheme()

    return <LinearProgress {...props} sx={{
        backgroundColor: theme.palette.primaryColors[100],
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.primaryColors[200]
        }
    }} />
}