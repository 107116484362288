import React, { useState, useMemo, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { WhiteCard } from '../Obligations/WhiteCard';
import { Typography, Box, Link, ListItem, ListItemText, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NavLink } from '../../components/common/Navbar/NavMenu';
import { InfoCard } from './InfoCard';
import { Navbar } from '../../components/common/Navbar/SecondaryNavbar';
import { NetVatPositionIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/NetVatPositionIcon';
import { TotalInputVatIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/TotalInputVatIcon';
import { VatUnderManagementIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/VatUnderManagementIcon';
import { TotalOutputVatIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/TotalOutputVatIcon';
import { TotalSupplyIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/TotalSupplyIcon';
import { TotalPurchaseIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/TotalPurchaseIcon';
import { TotalSupplySubjectToVatIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/TotalSupplySubjectToVatIcon';
import { TotalPurchasesSubjectToVatIcon } from '../../components/common/CustomIcons/Global Dashboard/Financial/TotalPurchasesSubjectToVatIcon';
import { TotalExceptionsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/TotalExceptionsIcon';
import { TotalCorrectionsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/TotalCorrectionsIcon';
import { PurchaseExceptionsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/PurchaseExceptionsIcon';
import { SupplyExceptionsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/SupplyExceptionsIcon';
import { OpenObligationsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/OpenObligationsIcon';
import { OverdueObligationsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/OverdueObligationsIcon';
import { SubmittedObligationsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/SubmittedObligationsIcon';
import { DueWithinOneWeekIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/DueWithinOneWeekIcon';
import { InReviewObligationsIcon } from '../../components/common/CustomIcons/Global Dashboard/Obligations/InReviewObligationsIcon';
import { useUser } from '../../context';
import { currencyFormatter, currencyFormatterWithoutSymbol } from '../../utils';
import { Toast } from '../Obligations/Toast';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { format } from 'date-fns';
import { StyledLinearProgress } from '../../components/common/Progress/StyledLinearProgress';

export const DashboardCards = ({ filterNameEncoder, filters, updateFilters }) => {
  const defaultCards = {
    Financial: 'Net VAT Position',
    Obligations: 'Total Exceptions',
  };

  const handleCardSelection = (card) => {
    updateFilters((draft) => {
      draft.selectedCard = card;
    });
  };

  const handleTabSelection = (tab) => {
    updateFilters((draft) => {
      draft.selectedCard = defaultCards[tab];
      draft.selectedCardGroup = tab;
    });
  };

  const initialCardData = {
    Financial: {
      'Current Position': {
        'Net VAT Position': undefined,
        'Total Input VAT': undefined,
        'VAT Under Management': undefined,
        'Total Output VAT': undefined,
      },
      'Supply and Purchases': {
        'Total Supply': undefined,
        'Total Purchases': undefined,
        'Total Supply Subject to VAT': undefined,
        'Total Purchases Subject to VAT': undefined,
      },
    },
    Obligations: {
      Exceptions: {
        'Total Exceptions': { amount: undefined, count: undefined },
        'Total Corrections': { amount: undefined, count: undefined },
        'Purchase Exceptions': { amount: undefined, count: undefined },
        'Supply Exceptions': { amount: undefined, count: undefined },
      },
      Status: {
        Open: undefined,
        Approved: undefined,
        Overdue: undefined,
        Submitted: undefined,
        'Due within 7 days': undefined,
        'In Review': undefined,
      },
    },
  };
  const [backendCardData, updateBackendCardData] = useImmer(initialCardData);
  const [currency, setCurrency] = useState('USD');

  const [areCardsLoading, setAreCardsLoading] = useState(true);
  const [toast, setToast] = useState({ type: '', message: '' });
  const theme = useTheme();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { userToken } = useUser();
  const navigate = useNavigate();

  const fetchData = (endpoint, dataType) => {
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`Something went wrong`);
        if (dataType === 'Exceptions') {
          updateBackendCardData((draft) => {
            draft.Obligations.Exceptions['Purchase Exceptions'].amount =
              data['Total_Purchase_Exception_Amount'];
            draft.Obligations.Exceptions['Purchase Exceptions'].count =
              data['Total_Purchase_Exception_Count'];
            draft.Obligations.Exceptions['Supply Exceptions'].amount =
              data['Total_Supply_Exception_Amount'];
            draft.Obligations.Exceptions['Supply Exceptions'].count =
              data['Total_Supply_Exception_Count'];
            draft.Obligations.Exceptions['Total Corrections'].amount =
              data['Total_Correction_Amount'];
            draft.Obligations.Exceptions['Total Corrections'].count =
              data['Total_Correction_Count'];
            draft.Obligations.Exceptions['Total Exceptions'].amount =
              data['Total_Exception_Amount'];
            draft.Obligations.Exceptions['Total Exceptions'].count = data['Total_Exception_Count'];
          });
          // setCurrency(data.Currencycode);
        }
        if (dataType === 'Financial') {
          updateBackendCardData((draft) => {
            draft.Financial['Current Position']['Net VAT Position'] =
              data?.finanicial_analytics_data?.Net_VAT_Position;
            draft.Financial['Current Position']['Total Input VAT'] =
              data?.finanicial_analytics_data?.TotalInputVAT;
            draft.Financial['Current Position']['VAT Under Management'] =
              data?.finanicial_analytics_data?.VAT_Under_Management;
            draft.Financial['Current Position']['Total Output VAT'] =
              data?.finanicial_analytics_data?.TotalOutputVAT;
            draft.Financial['Supply and Purchases']['Total Supply'] =
              data?.finanicial_analytics_data?.TotalSupply;
            draft.Financial['Supply and Purchases']['Total Purchases'] =
              data?.finanicial_analytics_data?.TotalPurchases;
            draft.Financial['Supply and Purchases']['Total Supply Subject to VAT'] =
              data?.finanicial_analytics_data?.Total_Supply_Subject_to_VAT;
            draft.Financial['Supply and Purchases']['Total Purchases Subject to VAT'] =
              data?.finanicial_analytics_data?.Total_Purchase_Subject_to_VAT;
          });
          // setCurrency(data?.finanicial_analytics_data?.Currencycode);
        }
        if (dataType === 'Status') {
          updateBackendCardData((draft) => {
            draft.Obligations.Status['Open'] = data?.Open;
            draft.Obligations.Status['Approved'] = data?.Approved;
            draft.Obligations.Status['Overdue'] = data?.Overdue;
            draft.Obligations.Status['Submitted'] = data?.Submitted;
            draft.Obligations.Status['Due within 7 days'] = data?.Due_within_7_days;
            draft.Obligations.Status['In Review'] = data?.In_Review;
          });
          //setCurrency(data?.Currencycode);
        }
      })
      .catch((err) => {
        console.error(err);
        if (dataType === 'Exceptions')
          updateBackendCardData((draft) => {
            draft.Obligations.Exceptions['Purchase Exceptions'].amount = undefined;
            draft.Obligations.Exceptions['Purchase Exceptions'].count = undefined;
            draft.Obligations.Exceptions['Supply Exceptions'].amount = undefined;
            draft.Obligations.Exceptions['Supply Exceptions'].count = undefined;
            draft.Obligations.Exceptions['Total Corrections'].amount = undefined;
            draft.Obligations.Exceptions['Total Corrections'].count = undefined;
            draft.Obligations.Exceptions['Total Exceptions'].amount = undefined;
            draft.Obligations.Exceptions['Total Exceptions'].count = undefined;
          });
        if (dataType === 'Financial')
          updateBackendCardData((draft) => {
            draft.Financial['Current Position']['Net VAT Position'] = undefined;
            draft.Financial['Current Position']['Total Input VAT'] = undefined;
            draft.Financial['Current Position']['VAT Under Management'] = undefined;
            draft.Financial['Current Position']['Total Output VAT'] = undefined;
            draft.Financial['Supply and Purchases']['Total Supply'] = undefined;
            draft.Financial['Supply and Purchases']['Total Purchases'] = undefined;
            draft.Financial['Supply and Purchases']['Total Supply Subject to VAT'] = undefined;
            draft.Financial['Supply and Purchases']['Total Purchases Subject to VAT'] = undefined;
          });
        if (dataType === 'Status')
          updateBackendCardData((draft) => {
            draft.Obligations.Status['Open'] = undefined;
            draft.Obligations.Status['Approved'] = undefined;
            draft.Obligations.Status['Overdue'] = undefined;
            draft.Obligations.Status['Submitted'] = undefined;
            draft.Obligations.Status['Due within 7 days'] = undefined;
            draft.Obligations.Status['In Review'] = undefined;
          });
        setToast({
          type: 'failure',
          message: 'Data could not be fetched.',
        });
      })
      .finally(() => {
        setAreCardsLoading(false);
      });
  };

  useEffect(() => {
    if (!userToken) return;
    setAreCardsLoading(true);
    let startDate;
    let endDate;
    if (filters.dateRange) {
      startDate = format(filters.dateRange[0], 'yyyy-MM-dd');
      endDate = format(filters.dateRange[1], 'yyyy-MM-dd');
    }

    const filterQuery = [
      filters.dateRange ? `postingdatestartrange=${startDate}&postingdateendrange=${endDate}` : '',
      filters.entity ? `entity=${filterNameEncoder(filters.entity, 'entity')}` : '',
      filters.country ? `country=${filterNameEncoder(filters.country, 'country')}` : '',
      filters.region ? `region=${filters.region}` : '',
      filters.taxType && filters.taxType !== 'All' ? `taxtype=${filters.taxType}` : '',
    ]
      .filter((f) => f) // Filter the non-nulls
      .join('&'); // Join with URL-encoded ampersands
    const exceptionsCardURL =
      process.env.REACT_APP_BACKEND_URL +
      '/exception/getexceptiondata' +
      (filterQuery ? '?' : '') +
      filterQuery;
    const financialCardUrl =
      process.env.REACT_APP_BACKEND_URL +
      '/transaction/getfinancialdata' +
      (filterQuery ? '?' : '') +
      filterQuery;
    const statusCardUrl =
      process.env.REACT_APP_BACKEND_URL +
      '/exception/getobligationdata' +
      (filterQuery ? '?' : '') +
      filterQuery;
    let endpoints = [
      { url: financialCardUrl, type: 'Financial' },
      { url: exceptionsCardURL, type: 'Exceptions' },
      { url: statusCardUrl, type: 'Status' },
    ];

    filters.dateRange !== null &&
      endpoints.map((endpoint) => {
        fetchData(endpoint.url, endpoint.type);
        return '';
      });
  }, [
    userToken,
    updateBackendCardData,
    filters.dateRange,
    filters.region,
    filters.country,
    filters.entity,
    filters.taxType,
  ]);
  // We unfold filters in these dependencies because we don't want changing the card or the tab to retrigger the queries.

  const currencyFormattingOptions = {
    notation: 'compact',
    compactDisplay: 'short',
    roundingMode: 'expand',
    maximumFractionDigits: 2,
    currencyDisplay: 'narrowSymbol',
    compactThreshold: 1,
  };
  const infoCardDetails = {
    Financial: [
      {
        title: 'Current Position',
        cardWidth: '22rem',
        cards: [
          {
            title: 'Net VAT Position',
            value: currencyFormatter(
              backendCardData.Financial['Current Position']['Net VAT Position'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <NetVatPositionIcon />,
          },
          {
            title: 'Total Input VAT',
            value: currencyFormatter(
              backendCardData.Financial['Current Position']['Total Input VAT'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <TotalInputVatIcon />,
          },
          {
            title: 'VAT Under Management',
            value: currencyFormatter(
              backendCardData.Financial['Current Position']['VAT Under Management'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <VatUnderManagementIcon />,
          },
          {
            title: 'Total Output VAT',
            value: currencyFormatter(
              backendCardData.Financial['Current Position']['Total Output VAT'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <TotalOutputVatIcon />,
          },
        ],
      },
      {
        title: 'Supplies and Purchases',
        cardWidth: '22rem',
        cards: [
          {
            title: 'Total Supplies',
            value: currencyFormatter(
              backendCardData.Financial['Supply and Purchases']['Total Supply'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <TotalSupplyIcon />,
          },
          {
            title: 'Total Purchases',
            value: currencyFormatter(
              backendCardData.Financial['Supply and Purchases']['Total Purchases'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <TotalPurchaseIcon />,
          },
          {
            title: 'Total Supplies Subject to VAT',
            value: currencyFormatter(
              backendCardData.Financial['Supply and Purchases']['Total Supply Subject to VAT'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <TotalSupplySubjectToVatIcon />,
          },
          {
            title: 'Total Purchases Subject to VAT',
            value: currencyFormatter(
              backendCardData.Financial['Supply and Purchases']['Total Purchases Subject to VAT'],
              currency,
              currencyFormattingOptions,
              true,
              true
            ),
            icon: <TotalPurchasesSubjectToVatIcon />,
          },
        ],
      },
    ],
    Obligations: [
      {
        title: 'Exceptions',
        cardWidth: '16rem',
        cards: [
          {
            title: 'Total Exceptions',
            data: {
              amount: currencyFormatter(
                backendCardData.Obligations.Exceptions['Total Exceptions'].amount,
                currency,
                currencyFormattingOptions,
                true,
                true
              ),
              count: currencyFormatterWithoutSymbol(
                backendCardData.Obligations.Exceptions['Total Exceptions'].count,
                true
              ),
            },
            icon: <TotalExceptionsIcon />,
          },
          {
            title: 'Total Corrections',
            data: {
              amount: currencyFormatter(
                backendCardData.Obligations.Exceptions['Total Corrections'].amount,
                currency,
                currencyFormattingOptions,
                true,
                true
              ),
              count: currencyFormatterWithoutSymbol(
                backendCardData.Obligations.Exceptions['Total Corrections'].count,
                true
              ),
            },
            icon: <TotalCorrectionsIcon />,
          },
          {
            title: 'Purchase Exceptions',
            data: {
              amount: currencyFormatter(
                backendCardData.Obligations.Exceptions['Purchase Exceptions'].amount,
                currency,
                currencyFormattingOptions,
                true,
                true
              ),
              count: currencyFormatterWithoutSymbol(
                backendCardData.Obligations.Exceptions['Purchase Exceptions'].count,
                true
              ),
            },
            icon: <PurchaseExceptionsIcon />,
          },
          {
            title: 'Supply Exceptions',
            data: {
              amount: currencyFormatter(
                backendCardData.Obligations.Exceptions['Supply Exceptions'].amount,
                currency,
                currencyFormattingOptions,
                true,
                true
              ),
              count: currencyFormatterWithoutSymbol(
                backendCardData.Obligations.Exceptions['Supply Exceptions'].count,
                true
              ),
            },
            icon: <SupplyExceptionsIcon />,
          },
        ],
      },
      {
        title: 'Status',
        cardWidth: '16rem',
        cards: [
          {
            title: 'Open',
            value: currencyFormatterWithoutSymbol(backendCardData.Obligations.Status['Open'], true),
            icon: <OpenObligationsIcon />,
          },
          {
            title: 'In Review',
            value: currencyFormatterWithoutSymbol(
              backendCardData.Obligations.Status['In Review'],
              true
            ),
            icon: <InReviewObligationsIcon />,
          },
          {
            title: 'Approved',
            value: currencyFormatterWithoutSymbol(
              backendCardData.Obligations.Status['Approved'],
              true
            ),
            icon: <OpenObligationsIcon />,
          },
          {
            title: 'Submitted',
            value: currencyFormatterWithoutSymbol(
              backendCardData.Obligations.Status['Submitted'],
              true
            ),
            icon: <SubmittedObligationsIcon />,
          },
          {
            title: 'Overdue',
            value: currencyFormatterWithoutSymbol(
              backendCardData.Obligations.Status['Overdue'],
              true
            ),
            icon: <OverdueObligationsIcon />,
          },

          {
            title: 'Due within 7 days',
            value: currencyFormatterWithoutSymbol(
              backendCardData.Obligations.Status['Due within 7 days'],
              true
            ),
            icon: <DueWithinOneWeekIcon />,
          },
        ],
      },
    ],
  };

  return (
    <>
      {areCardsLoading && (
        <Stack sx={{ p: 0, mb: '-.5rem' }}>
          <StyledLinearProgress style={{ marginTop: '-10px' }} />
        </Stack>
      )}
      <WhiteCard style={{ padding: 'none' }}>
        <Navbar
          flex={1}
          sx={{ marginTop: '.25rem', padding: '0 2rem !important' }}>
          <ListItem
            sx={{ width: 'fit-content' }}
            disablePadding>
            <NavLink
              component={Link}
              onClick={() => handleTabSelection('Financial')}
              selected={filters.selectedCardGroup === 'Financial'}
              customColor='#D04A02'
              disableRipple>
              <ListItemText
                primary='Financials'
                primaryTypographyProps={{
                  fontWeight: filters.selectedCardGroup === 'Financial' ? 500 : 400,
                  fontSize: '0.875rem',
                  textAlign: 'center',
                }}
              />
            </NavLink>
          </ListItem>
          <ListItem
            sx={{ width: 'fit-content' }}
            disablePadding>
            <NavLink
              component={Link}
              onClick={() => handleTabSelection('Obligations')}
              selected={filters.selectedCardGroup === 'Obligations'}
              customColor='#D04A02'
              disableRipple>
              <ListItemText
                primary='Obligations'
                primaryTypographyProps={{
                  fontWeight: filters.selectedCardGroup === 'Obligations' ? 500 : 400,
                  fontSize: '0.875rem',
                  textAlign: 'center',
                }}
              />
            </NavLink>
          </ListItem>
        </Navbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '0.25rem 2rem 2rem 1.5rem',
          }}>
          <Stack
            direction='row'
            gap={2}
            width={1}>
            {infoCardDetails[filters.selectedCardGroup].map((cardGroup) => (
              <Stack key={cardGroup.title}>
                <Typography
                  component='h4'
                  sx={{
                    color: theme.palette.baseColors.grey,
                    fontSize: '1.125rem',
                    fontWeight: '700',
                    paddingBottom: '10px',
                  }}>
                  {cardGroup.title}
                </Typography>
                <Stack
                  direction='row'
                  gap={2}
                  flexWrap='wrap'>
                  {cardGroup.cards.map((card) => (
                    <InfoCard
                      key={card.title}
                      title={card.title}
                      value={card.value}
                      data={card.data}
                      selectedCard={filters.selectedCard}
                      handleCardSelection={handleCardSelection}
                      children={card.icon}
                      width={cardGroup.cardWidth}
                      isLoading={areCardsLoading}
                    />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      </WhiteCard>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
