import { SvgIcon } from '@mui/material';
import React from 'react'

export const InconsistentCodingIcon = (props) => {
    return (
        <SvgIcon {...props}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1298_10113)">
        <path d="M16 29.3334C8.63596 29.3334 2.66663 23.3641 2.66663 16.0001C2.66663 8.63608 8.63596 2.66675 16 2.66675C23.364 2.66675 29.3333 8.63608 29.3333 16.0001C29.3333 23.3641 23.364 29.3334 16 29.3334ZM16 26.6667C18.8289 26.6667 21.542 25.5429 23.5424 23.5426C25.5428 21.5422 26.6666 18.8291 26.6666 16.0001C26.6666 13.1711 25.5428 10.458 23.5424 8.45761C21.542 6.45722 18.8289 5.33341 16 5.33341C13.171 5.33341 10.4579 6.45722 8.45749 8.45761C6.4571 10.458 5.33329 13.1711 5.33329 16.0001C5.33329 18.8291 6.4571 21.5422 8.45749 23.5426C10.4579 25.5429 13.171 26.6667 16 26.6667ZM14.6666 20.0001H17.3333V22.6667H14.6666V20.0001ZM14.6666 9.33342H17.3333V17.3334H14.6666V9.33342Z" fill="#474747"/>
        </g>
        <defs>
        <clipPath id="clip0_1298_10113">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </SvgIcon>
    )
};
