import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  
  Skeleton,
  useTheme,
  Stack,
} from "@mui/material";
import { useUser } from "../../../../context";
import { WhiteCard } from "../../WhiteCard";
import { useLocation } from "react-router-dom";
import { PageSkeleton } from "../PageSkeleton";
import { OPERATING_ANALYTICS_CARDS_DATA, getOperatingAnalyticsCardDescription } from "../constants";
import { useObligation } from "../../../../context";
import { Looker } from "../../../../components/common/Looker/Looker";
import { lookerEmbedDomain, lookerEntityCodeList } from "../../../../utils";
import { ExpandableLookerGrid } from "../../../../components/common/Looker/ExpandableLookerGrid";
import { DistinctCustomerSuppliersCard } from "../DistinctCustomersSuppliersCard";
import { SIGNIFICANT_SUPPLIERS_CARD_INFO } from "../../lookerConstants";

export const SignificantSuppliers = () => {
  const { isGroupSelected, sisterObligations, selectedObgDetails } = useObligation();

  return (
    <PageSkeleton
      title="Significant Suppliers"
      description={getOperatingAnalyticsCardDescription("Significant Suppliers")}
      url=".."
      isURLRelative
    >
      <ExpandableLookerGrid
        cards={SIGNIFICANT_SUPPLIERS_CARD_INFO}
        dashboardParameters={{
          "Obligation ID": selectedObgDetails?.obligation_id,
          "Entitycode": lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails)
        }}
        downloadConstants={{
          dashboard: process.env.REACT_APP_LOOKER_SIGNIFICANT_CUSTOMERS,
          filename: `${selectedObgDetails?.obligation_name}_Significant_Suppliers`,
          title: "Significant Suppliers"
        }}
      />
    </PageSkeleton>
  )
};