export const formatAmount = (amount, currencyCode) => {
  if (currencyCode && amount !== undefined) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    });
    return formatter.format(amount);
  }
  return amount;
};

export const getCurrencySymbol = (currency) => {
  if (currency)
    return (0)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  return;
};

export const getDropDownOptions = (options) => {
  const dropdownOptions = [];
  options.map((data) => {
    const object = {
      name: data.id,
      value: data.id,
    };
    dropdownOptions.push(object);
    return '';
  });
  return dropdownOptions;
};

export const roundOffValue = (number, v1, v2) => {
  let l1 = String(v1).split('.')[1]?.length ?? 0;
  let l2 = String(v2).split('.')[1]?.length ?? 0;
  if (v1) return number.toFixed(Math.max(l1, l2));
  else return number.toFixed(2);
};
