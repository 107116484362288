import React, { useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { PageSkeleton } from '../TransactionDetail/PageSkeleton';
import { ParentAccordion } from '../TransactionDetail/Accordion';
import { PurchaseTaxExceptionsTable } from './PurchaseTaxExceptionsTable';
import { useObligation, useUser } from '../../../../context';
import { saveAs } from 'file-saver';
import { conditionalEntityCodeQuery } from '../../../../utils';
import { BtnOutlined } from '../../../../components/common/Buttons/BtnOutlined';
import { DownloadIcon } from '../../../../components/common/CustomIcons/DownloadIcon';

import { BtnFilled } from '../../../../components/common/Buttons/BtnFilled';

export const PurchaseTaxExceptions = () => {
  const theme = useTheme();
  const [unresolvedData, setUnresolvedData] = useState([]);
  const [resolvedData, setResolvedData] = useState([]);
  const [noExceptionData, setNoExceptionData] = useState([]);
  const { id, member } = useParams();
  const { selectedObgDetails } = useObligation();
  const entityCodeQuery = conditionalEntityCodeQuery(selectedObgDetails);
  const { userToken } = useUser();
  const navigate = useNavigate()

  const resolvedExceptionsDownloadHandler = (source_data, entityCodeQuery) => {

    fetch(process.env.REACT_APP_BACKEND_URL + `/exception/export_exception?obligation_id=${selectedObgDetails?.obligation_id}&source_data=${source_data}${entityCodeQuery}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        const blob = res.blob();
        if (!res.ok || !blob) {
          throw new Error(`Something went wrong, status code: ${res.status}`);
        }
        const contentDisposition = res.headers.get("Content-Disposition");
        const requiredIndex = contentDisposition?.indexOf("filename=") + 9;
        const fileName = contentDisposition?.slice(requiredIndex);

        return blob.then((blob) => ({ blob, fileName }));

      })
      .then(({ blob, fileName }) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
      });
  }

  return (
    <PageSkeleton
      title='Purchase Tax Exceptions'
      description='View a list of purchase tax exceptions that have been run by the system.'
      hasNavMenu={false}
      url={`/obligations/${id}/${member}/exceptions`}>
      <Stack justifyContent='flex-end' flexDirection='row'>
        <BtnFilled
          size='small'
          btnTxt='Export'
          icon={<DownloadIcon />}
          iconPosition='start'
          styling={{
            width: 'fit-content'
          }}
          onClick={() => {
            resolvedExceptionsDownloadHandler("AP", entityCodeQuery)
          }}
        />
      </Stack>
      <ParentAccordion
        title='Unresolved Exceptions'
        theme={theme}
        count={unresolvedData?.length}
        expandOnRender>
        <PurchaseTaxExceptionsTable
          id={id}
          member={member}
          tableType='Unresolved'
          setTableSnapshot={setUnresolvedData}
        />
      </ParentAccordion>
      <ParentAccordion
        title='Resolved Exceptions'
        theme={theme}
        count={resolvedData?.length}
      >
        <PurchaseTaxExceptionsTable
          id={id}
          member={member}
          tableType='Resolved'
          setTableSnapshot={setResolvedData}

        />
      </ParentAccordion>
      <ParentAccordion
        title='No Exceptions'
        theme={theme}
        count={noExceptionData?.length}>
        <PurchaseTaxExceptionsTable
          id={id}
          member={member}
          tableType='Passed'
          setTableSnapshot={setNoExceptionData}
        />
      </ParentAccordion>
    </PageSkeleton>
  );
};
