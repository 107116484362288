import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

const TextTag = (props) => {
  const theme = useTheme()
  const [size, setSize] = useState()
  const [variant, setVar] = useState()
  const [text, setText] = useState()
  useEffect(() => {
    setSize(props.size)
    setText(props.text)
    setVar(props.variant)
  }, [props])

  const FontSizes = {
    xs: "0.625rem",
    s: "0.625rem",
    m: "0.875rem",
    l: "1rem"
  }

  const TagSizes = {
    xs: 0.125,
    s: 0.3,
    m: 0.4,
    l: 0.5
  }

  const bgColors = {
    red: theme.palette.functional.errorAccent,
    green: theme.palette.functional.successAccent,
    blue: theme.palette.neutralColors.black[100],
    yellow: theme.palette.baseColors.yellow[100],
    grey: theme.palette.neutralColors.black[100],
    primary: theme.palette.primaryColors[100]
  }

  const fontColor = {
    red: theme.palette.functional.error,
    green: theme.palette.functional.success,
    blue: theme.palette.random[100],
    yellow: theme.palette.baseColors.yellow[200],
    grey: theme.palette.baseColors.grey,
    primary: theme.palette.primaryColors[200]
  }
  return (
    <Box
      sx={{
        border: "none",
        color: fontColor[variant],
        backgroundColor: bgColors[variant],
        fontWeight: "700",
        fontSize: FontSizes[size],
        width: "fit-content",
        height: "auto",
        padding: `${TagSizes[size]}rem ${TagSizes[size] + 0.5}rem`,
        textTransform: 'uppercase',
        ...props.style
      }}
    >
      {text}
    </Box>
  )
}

export default TextTag