import React from 'react';
import { TableCell, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { formatNumberWithCommas } from '../TransactionDetail/constants';
import TextTag from '../../../../components/common/Text Tags/TextTag';
import { PaginatedTable } from '../../../../components/Paginated Table/PaginatedTable';
import {
  unresolvedData as mockUnresolvedData,
  resolvedData as mockResolvedData,
} from '../PurchaseTaxExceptions/dummyData';
import { useObligation } from '../../../../context';
import { conditionalEntityCodeDefaultQuery } from '../../../../utils';

// STYLING
const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  height: '3rem',
}));

// NOTE: This table is using the API from Purchase Tax Exceptions/Supply Tax Exceptions and will need to be adjusted once backend API is ready for Business Number Validation

export const BusinessNumberValidationTable = ({ id, member, tableType, setTableSnapshot }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { selectedObgDetails } = useObligation();

  // Adjust default filter query for the type of table "Resolved" vs "Unresolved" data.
  const tableTypeFilterQuery =
    tableType === 'Resolved'
      ? '&category=RESOLVED'
      : tableType === 'Passed'
      ? '&category=PASSED'
      : '&category=UNRESOLVED';

  const COLUMNS = [
    { label: 'Test Group', property: 'group_name', sortable: false, cellWidth: 12 },
    { label: 'Test Name', property: 'rule_id', cellWidth: 12 },
    {
      label: 'Passed Transactions',
      property: 'passed_transaction',
      cellWidth: 12,
    },
    {
      label: 'Unresolved Transactions',
      property: 'unresolved_exception',
      cellWidth: 15,
    },
    {
      label: 'Resolved Transactions',
      property: 'resolved_exception',
      cellWidth: 7,
    },
  ];

  return (
    selectedObgDetails.obligation_id && (
      <PaginatedTable
        dummyData={tableType === 'Resolved' ? mockResolvedData : mockUnresolvedData}
        api={{
          endpoint: '/exception/exceptiondetail',
          records: 'exceptiondetail',
          count: 'count',
        }}
        columns={COLUMNS}
        defaultColumn='rule_id'
        paginated={false}
        setTableSnapshot={setTableSnapshot}
        // source__eq:
        // Purchase Tax Exceptions = "AP"
        // Supply Tax Exceptions = "AR"
        filterInfo={{
          default: [
            `obligation_id__eq=${id}`,
            `rule_id__in_=invalidvatregistrationnumber,missingvatregistrationnumber`,
            conditionalEntityCodeDefaultQuery(selectedObgDetails),
          ],
        }}
        defaultParam={tableTypeFilterQuery}
        hasFilterComponent={false}
        a11y={{
          tableAriaLabel: 'adjustment list',
          rowPrefix: 'adjustment',
        }}
        rowItem={(exception) => [
          <StyledTableBodyCell>
            <TextTag
              text={exception.group_name}
              variant='grey'
              size='m'
              style={{ padding: '4px 8px', borderRadius: '2px' }}
            />
          </StyledTableBodyCell>,
          <StyledTableBodyCell>
            <Typography
              onClick={() =>
                navigate(
                  `/obligations/${id}/${member}/exceptions/business-number-validation/individual-test?ruleId=${
                    exception.rule_id
                  }&description=${
                    exception.rule_description
                  }&testResult=${tableType?.toUpperCase()}`
                )
              }
              sx={{
                display: 'inline-block',
                fontSize: '0.875rem',
                color: theme.palette.functional.tableLink,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}>
              {exception.rule_description}
            </Typography>
          </StyledTableBodyCell>,
          <StyledTableBodyCell>
            <TextTag
              text={formatNumberWithCommas(exception.passed_transaction)}
              variant='green'
              size='m'
            />
          </StyledTableBodyCell>,
          <StyledTableBodyCell>
            <TextTag
              text={formatNumberWithCommas(exception.unresolved_exception)}
              variant='red'
              size='m'
              style={{
                color: theme.palette.baseColors.white,
                backgroundColor: theme.palette.primaryColors[200],
              }}
            />
          </StyledTableBodyCell>,
          <StyledTableBodyCell>
            <TextTag
              text={formatNumberWithCommas(exception.resolved_exception)}
              variant='orange'
              size='m'
              style={{
                color: theme.palette.baseColors.white,
                backgroundColor: theme.palette.graph.accentSecondary,
              }}
            />
          </StyledTableBodyCell>,
        ]}
      />
    )
  );
};
