import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import { LeftArrowIcon } from '../../../../components/common/CustomIcons/LeftArrowIcon';


export const ParentAccordion = ({
  theme,
  title,
  button,
  count,
  children,
  expandOnRender,
  styles,
  enableExport,
  entityCodeQuery
}) => {
  const [expanded, setExpanded] = useState(expandOnRender ? 'panel1' : false);

  useEffect(() => {
    if (expandOnRender) {
      setExpanded('panel1');
    }
  }, [expandOnRender]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Stops the event from bubbling up to the Accordion
  };


  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '76px',
        width: '100%',
        marginBottom: '25px',
        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
        borderRadius: '8px !important',
        boxShadow: 'none',
        ...styles,
      }}>
      <AccordionSummary
        expandIcon={<LeftArrowIcon />}
        aria-controls='panel1-content'
        id='panel1-header'
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <Typography sx={{ fontSize: '1.125rem', fontWeight: 700 }}>{title}</Typography>
          {button && <Stack onClick={handleButtonClick}>{button}</Stack>}

          {count >= 0 && (

            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 700,
                paddingRight: '25px',
              }}>
              {count}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      {expanded && <Divider sx={{ width: '100%' }} />}

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
