import { SvgIcon } from '@mui/material';

export const Warning = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='16'
        height='13'
        viewBox='0 0 16 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.57902 1.0003L14.9297 12.0003C14.9882 12.1016 15.019 12.2166 15.019 12.3336C15.019 12.4506 14.9882 12.5656 14.9297 12.667C14.8712 12.7683 14.787 12.8525 14.6857 12.911C14.5843 12.9695 14.4694 13.0003 14.3524 13.0003H1.65102C1.534 13.0003 1.41904 12.9695 1.3177 12.911C1.21635 12.8525 1.1322 12.7683 1.07369 12.667C1.01518 12.5656 0.984374 12.4506 0.984375 12.3336C0.984376 12.2166 1.01518 12.1016 1.07369 12.0003L7.42436 1.0003C7.48287 0.898958 7.56703 0.814807 7.66837 0.7563C7.76972 0.697793 7.88467 0.666992 8.00169 0.666992C8.11871 0.666992 8.23367 0.697793 8.33501 0.7563C8.43635 0.814807 8.52051 0.898958 8.57902 1.0003ZM7.33502 9.66696V11.0003H8.66836V9.66696H7.33502ZM7.33502 5.0003V8.33363H8.66836V5.0003H7.33502Z'
          fill='#FAA61A'
        />
      </svg>
    </SvgIcon>
  );
};
