import React from 'react';
import { WhiteCard } from '../WhiteCard';
import { Grid, Typography, TextField } from '@mui/material';
import dayjs from 'dayjs';

const DetailsField = ({ label, value }) => (
  <TextField
    label={label}
    value={value}
    InputProps={{
      readOnly: true,
    }}
    variant='standard'
    InputLabelProps={{
      shrink: true,
    }}
    sx={{
      marginBottom: '20px',
      '& .MuiInputBase-input': {
        cursor: 'default',
      },
      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: '1px solid #E5E9EB',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid #E5E9EB',
      },
      '& .MuiFormLabel-root': {
        color: 'black',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'black !important',
      },
    }}
  />
);
export const ObligationDetails = ({ data, theme }) => (
  <WhiteCard>
    <Typography
      component='h4'
      sx={{
        color: theme.palette.random[300],
        fontSize: '1.125rem',
        fontWeight: '600',
        marginBottom: '20px',
      }}>
      Details
    </Typography>
    <Grid
      container
      columns={2}
      direction='row'
      sx={{ justifyContent: 'space-around' }}>
      <Grid
        item
        sx={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
        <DetailsField
          label='Obligation Name'
          value={data?.obligation_name}
        />
        <DetailsField
          label='Obligation Group'
          value={data?.obligation_group_details?.group_name}
        />
        <DetailsField
          label='Return'
          value={data?.return_type}
        />
      </Grid>
      <Grid
        item
        sx={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
        <DetailsField
          label='Period'
          value={
            data?.start_date && data?.end_date
              ? `${dayjs(data?.start_date)?.format('DD MMM YYYY')} - ${dayjs(
                  data?.end_date
                )?.format('DD MMM YYYY')}`
              : ''
          }
        />
        <DetailsField
          label='Due Date'
          value={data?.due_date ? dayjs(data?.due_date)?.format('DD MMM YYYY') : ''}
        />
        <DetailsField
          label='Last Data Upload'
          value={data?.last_data_upload ? dayjs(data?.last_data_upload)?.format('DD MMM YYYY') : ''}
        />
      </Grid>
    </Grid>
  </WhiteCard>
);
