import { Card, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { PieChart } from '@mui/x-charts';
import React from 'react'
import { BtnLink } from '../../../components/common/Buttons/BtnLink';
import { useNavigate } from "react-router-dom";

export const ExceptionsTestCard = ({ title, chartData, url }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    
    return (
        <Card
            sx={{
                padding: '1.5rem',
                boxShadow: 'none',
                borderRadius: '0.5rem',
                border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                backgroundColor: theme.palette.baseColors.white,
                flex: '1 1 0',
                color: theme.palette.random[300],
            }}
        >
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '1rem',
                }}
            >
                {title}
            </Typography>
            <Stack alignItems='center' justifyContent='center' sx={{ marginBottom: '1.5rem' }}>
                <PieChart
                    series={[
                        {
                            data: chartData,
                            innerRadius: 60,
                            outerRadius: 100,
                            paddingAngle: 0,
                            cornerRadius: -2,
                            startAngle: -90,
                            endAngle: 90,
                            cx: '190px',
                            cy: '120px',
                        },
                    ]}
                    height={useMediaQuery(theme.breakpoints.down("sm-laptop")) ? 240 : 180}
                    width={400}
                    slotProps={{
                        legend: {
                            direction: useMediaQuery(theme.breakpoints.down("sm-laptop")) ? 'column' : 'row',
                            position: { vertical: 'bottom', horizontal: 'middle' },
                            padding: 0,
                        },
                    }}
                />
            </Stack>
            <BtnLink
                size="small"
                btnTxt='View All'
                onClick={() => { if (url) { navigate(url) } }}
                styling={{ textDecoration: 'underline', fontSize: '1rem' }}
            />
        </Card>
    )
}
