import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  useTheme,
  IconButton,
  TableCell,
  TableContainer,
  Paper,
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
} from '@mui/material';
import { Toast } from '../../../../../pages/Obligations/Toast';
import { useNavigate } from 'react-router-dom';
import Paginator from '../../../../../components/common/Paginator/Paginator';
import { FilterContext } from '../../../../../pages/Obligations/FilterContext';
import { useUser } from '../../../../../context';
import { SortAscIcon } from '../../../../../components/common/CustomIcons/SortAscIcon';
import { SortDescIcon } from '../../../../../components/common/CustomIcons/SortDescIcon';
import { SortIcon } from '../../../../../components/common/CustomIcons/SortIcon';
import { StyledLinearProgress } from '../../../../../components/common/Progress/StyledLinearProgress';

// STYLING
const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

export const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  height: '3rem',
}));

export const SelectableRowsTable = ({
  dummyData = {},
  api = '',
  columns = [],
  defaultColumn = 'id',
  reload,
  paginated = true,
  filterInfo = {},
  a11y = {},
  rowItem,
  customFilters = [],
  defaultParam = '',
  setTableSnapshot = () => { },
  hasFilterComponent = true,
  hasCustomFilters = false,
  isSelectable = false, // Prop to enable row selection
  selectedRows = [], // Passed only when isSelectable is true
  setSelectedRows = () => { }, // Passed only when isSelectable is true
  disableCheckbox = false,
  checkBoxDisable,
  checkboxSelectAll,
}) => {
  // QUERY DATA
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null);
  const navigate = useNavigate();

  // SORTING AND PAGINATION
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortingOrder, setSortingOrder] = useState('asc');
  const { pageSize, userToken } = useUser();
  const [pageNo, setPageNo] = useState(1);

  // FILTERING
  const [filters, setFilters] = useState(customFilters);

  // UTILITY
  const [isLoading, setLoading] = useState(false);
  const [toast, setToast] = useState({ type: '', message: '' });
  const theme = useTheme();

  // Handle selection change for individual rows
  const handleRowSelectionChange = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const getSortedDataHandler = (col_name) => {
    if (col_name === sortedColumn) {
      // If the selected column was already selected, toggle its sorting order.
      setSortingOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      // Orderwise, set the sorting order for the selected column to its default.
      // If a default sort order is not specified, take it to be ascendant
      setSortedColumn(col_name);
      setSortingOrder(columns.find((c) => c.property === col_name).defaultSortOrder ?? 'asc');
    }
  };

  // Handle select all/deselect all functionality
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = disableCheckbox
        ? checkboxSelectAll(rows)?.map((row) => row.id)
        : rows.map((row) => row.id);
      setSelectedRows(newSelectedRows);
      return;
    }
    setSelectedRows([]);
  };

  useEffect(() => {
    setPageNo(1);
  }, [filters]);

  useEffect(() => {
    if (hasFilterComponent && hasCustomFilters) setFilters(customFilters);
  }, [hasFilterComponent, hasCustomFilters, customFilters]);

  useEffect(() => {
    if (userToken) {
      setLoading(true);
      if (!api?.endpoint) {
        setRows();
        setTotalRecords(0);
        setLoading(false);
        return;
      }
      const filterQuery =
        '&filter_query=' +
        [
          ...filters.map((filter) => {
            return `${filter.field.mapping}__${filter.condition.operator}=${filter.queryValue}`;
          }), // Add the query params from FilterContext.
          ...(filterInfo.default && filterInfo.default.length
            ? filterInfo.default.filter((item) => item !== '')
            : []), // Add the default query params.
        ].join('%26');
      let url = process.env.REACT_APP_BACKEND_URL + api.endpoint;
      // If sorting column has been picked.
      if (sortingOrder && sortedColumn && userToken && pageNo && pageSize)
        url += paginated
          ? `?sort_column=${sortedColumn}&sort_dir=${sortingOrder}&limit=${pageSize}&page=${pageNo}`
          : `?sort_column=${sortedColumn}&sort_dir=${sortingOrder}`;
      // Default case.
      else if (userToken && pageNo && pageSize)
        url += paginated
          ? `?${defaultColumn ? `sort_column=${defaultColumn}&sort_dir=asc&` : ''
          }limit=${pageSize}&page=${pageNo}`
          : `?${defaultColumn ? `sort_column=${defaultColumn}&sort_dir=asc` : ''}`;
      if (defaultParam !== '') url += defaultParam;
      if (filterInfo?.default?.length || filters.length) url += filterQuery;

      let _records;
      let _count;
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          if (!data) throw new Error(`API response empty.`);
          _records = data[api.records];
          _count = data[api.count];
        })
        .catch((err) => {
          setToast({
            type: 'failure',
            message: 'Data could not be sorted.',
          });
        })
        .finally(() => {
          setRows(_records);
          setTotalRecords(_count);

          setTableSnapshot(_records);

          setLoading(false);
        });
    }
    // setTimeout(() => {
    //   setRows(dummyData);
    //   setTotalRecords(dummyData.length);
    //   setTableSnapshot(dummyData);
    //   setLoading(false);
    // }, 1000);
  }, [sortingOrder, sortedColumn, userToken, pageNo, pageSize, filters, reload]);
  const Component = filterInfo?.componentWithData;
  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {filterInfo?.component}
      {Component && (
        <Component
          {...filterInfo?.componentProps}
          data={rows}
          isLoading={isLoading}
        />
      )}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          overflow: 'hidden',
        }}>
        <Box
          sx={{
            overflowX: 'scroll !important',
          }}>
          <Table
            aria-label={a11y.tableAriaLabel}
            sx={{
              borderRadius: '0.5rem',
              border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            }}>
            <TableHead
              sx={{
                backgroundColor: theme.palette.neutralColors.black[100],
                height: '2.5rem',
              }}>
              <TableRow variant='body2'>
                {isSelectable && (
                  <StyledTableHeadingCell>
                    <Checkbox
                      indeterminate={
                        selectedRows?.length > 0 &&
                        selectedRows?.length <
                        (disableCheckbox ? checkboxSelectAll(rows)?.length : rows.length)
                      }
                      checked={
                        rows?.length > 0 &&
                        selectedRows?.length > 0 &&
                        selectedRows?.length ===
                        (disableCheckbox ? checkboxSelectAll(rows)?.length : rows?.length)
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all transactions' }}
                      sx={{
                        '&.Mui-checked': {
                          color: '#D04A02',
                        },
                        '&.MuiCheckbox-indeterminate': {
                          color: '#D04A02',
                        },
                      }}
                    />
                  </StyledTableHeadingCell>
                )}
                {columns?.map((column) => {
                  const isSortable = (column.sortable ??= true);
                  const onClick = isSortable
                    ? () => getSortedDataHandler(column.property)
                    : undefined;
                  const style = {};
                  if (column.cellWidth !== -1) style['maxWidth'] = column.cellWidth + '%';
                  if (column.centeredHeader === true) style['textAlign'] = 'center';
                  if (isSortable === false) style['cursor'] = 'auto';

                  return (
                    <StyledTableHeadingCell
                      key={column.property}
                      style={style}
                      onClick={onClick}>
                      {column.label}
                      {isSortable && (
                        <IconButton disableRipple>
                          {sortedColumn === column.property ? (
                            sortingOrder === 'asc' ? (
                              <SortDescIcon />
                            ) : (
                              <SortAscIcon />
                            )
                          ) : (
                            <SortIcon />
                          )}
                        </IconButton>
                      )}
                    </StyledTableHeadingCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={columns?.length + 1}
                    sx={{ p: 0 }}>
                    <StyledLinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {rows?.length ? (
                rows?.map((row, index) => {
                  return (
                    <TableRow
                      key={`${a11y.rowPrefix}-${index}`}
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      {isSelectable && (
                        <StyledTableBodyCell>
                          <Checkbox
                            disabled={disableCheckbox ? checkBoxDisable(row) : false}
                            checked={selectedRows.includes(row.id)}
                            onChange={() => handleRowSelectionChange(row.id)}
                            inputProps={{ 'aria-label': `select ${row.id}` }}
                            sx={{
                              '&.Mui-checked': {
                                color: '#D04A02',
                              },
                              '&.MuiCheckbox-indeterminate': {
                                color: '#D04A02',
                              },
                            }}
                          />
                        </StyledTableBodyCell>
                      )}
                      {rowItem(row)}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  sx={{
                    height: '3rem',
                    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  }}>
                  <TableCell
                    colSpan={columns?.length + 1}
                    sx={{ textAlign: 'center', justifyContent: 'center' }}>
                    {isLoading ? 'Fetching data...' : 'No data found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <Box mt='-1.5rem'>
        <Paginator
          totalRecords={totalRecords}
          pageNoChangeHandler={setPageNo}
          pageNo={pageNo}
          disabled={isLoading}
        />
      </Box>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </FilterContext.Provider>
  );
};
