export const adjustmentsColumns = [
  { name: 'transaction_id', label: 'Adjustment ID', cellWidth: 12, sorting: false },
  { name: 'adjustment_type', label: 'Adjustment Type', cellWidth: 12, sorting: true },
  { name: 'created_by', label: 'Created By', cellWidth: 15, sorting: true },
  { name: 'created_on', label: 'Created On', cellWidth: 7, sorting: true },
  { name: 'statnetamount', label: 'Net Amount', cellWidth: 11, sorting: true },
  {
    name: 'variance_amount',
    label: 'VAT Amount',
    cellWidth: 7,
    sorting: true,
  },
  { name: 'actionRequired', label: 'Action Required', cellWidth: 8, sorting: false },
];

export const SummaryPageHeadings = [
  `Trial Balances vs Transactions`,
  'VAT GL vs Transactions',
  'VAT GL vs Transactions without Tax Code',
  'Input VAT Reconciliation without Tax Code',
];

export const summaryPageSubHeadings = [
  'Reconcile GL Postings against reportable Financial Transactions',
  'Reconcile VAT GL Postings against reportable Financial Transactions.',
  `Reconcile VAT GL Postings against reportable Financial Transactions. This reconciliation is designed to be used when the data doesn't include Tax Codes in one or both data sets.`,
  'Reconcile GL Input VAT Postings against Input VAT from reportable purchase transactions, including Self-Accounted Input VAT without Tax Code.',
];

export const summaryPageValues = ['Trial Balance', 'VAT GL', 'VAT GL', 'Input VAT'];

export const getUnReconciledItemColumns = (no) => {
  return [
    { label: 'Entity', name: 'entity', cellWidth: '25%', sorting: true },
    { label: 'GL No.', name: 'glaccount', cellWidth: '20%', sorting: true },
    {
      label: no === 1 ? 'Trial Balance' : no === 4 ? 'Input VAT' : 'VAT GL',
      cellWidth: '10%',
      sorting: false,
      align: 'right',
    },
    {
      label: no === 1 || no === 2 ? 'Transactions' : 'Transactions Without Tax Code',
      cellWidth: '10%',
      sorting: false,
      align: 'right',
    },
    { label: 'Difference', cellWidth: '10%', sorting: false, align: 'right' },
    { label: '', cellWidth: '25%', sorting: false },
  ];
};

export const getReconciledItemColumns = (no) => {
  return [
    { label: 'Entity', name: 'entity', cellWidth: 10, sorting: true },
    { label: 'GL No.', name: 'glaccount', cellWidth: 12, sorting: true },
    {
      label: no === 1 ? 'Trial Balance' : no === 4 ? 'Input VAT' : 'VAT GL',
      cellWidth: 12,
      sorting: false,
      align: 'right',
    },
    { label: 'Transactions', cellWidth: 12, sorting: false, align: 'right' },
    { label: 'Amount Reconciled', cellWidth: 12, sorting: false, align: 'right' },
    { label: 'Difference', cellWidth: 12, sorting: false, align: 'right' },
    { label: 'Adjustment ID', cellWidth: 24, sorting: false, align: 'left' },
  ];
};
