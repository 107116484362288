import { Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { WhiteCard } from '../WhiteCard';
import { AdjustmentsFilter } from './AdjustmentsFilter';
import {
  PaginatedTable,
  StyledTableBodyCell,
} from '../../../components/Paginated Table/PaginatedTable';
import { dummyData } from './dummyData';
import dayjs from 'dayjs';
import { formatAsCurrency } from '../Exceptions/TransactionDetail/constants';
import { getCurrentRelativePath } from '../../../utils';

export const AdjustmentsTable = ({
  filters,
  setFilters,
  customFilters,
  entityCodeDefaultTableQuery,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id, member } = useParams();
  const COLUMNS = [
    { label: 'Adjustment ID', property: 'transaction_id', cellWidth: -1 },
    { label: 'Description', property: 'description', cellWidth: -1 },
    { label: 'Created By', property: 'created_by', cellWidth: -1 },
    { label: 'Created On', property: 'entry_date', cellWidth: -1 },
    { label: 'Member', property: 'entityname', cellWidth: -1 },
    { label: 'Net Amount', property: 'netamount', cellWidth: -1 },
    {
      label: 'VAT Amount',
      property: 'vat_amount',
      cellWidth: -1,
    },
  ];

  const conditionalDefaultQueries = [`obligation_id__eq=${id}`, entityCodeDefaultTableQuery];

  return (
    <WhiteCard style={{ height: '100%' }}>
      <Typography
        component='h4'
        sx={{
          color: theme.palette.random[300],
          fontSize: '1.125rem',
          fontWeight: '600',
          paddingBottom: '10px',
        }}>
        Adjustments
      </Typography>
      <PaginatedTable
        dummyData={dummyData.table}
        api={{
          endpoint: '/adjustment/adjustmentdetails',
          records: 'summary',
          count: 'count',
        }}
        columns={COLUMNS}
        defaultColumn='transaction_id'
        customFilters={customFilters}
        hasCustomFilters
        filterInfo={{
          componentWithData: AdjustmentsFilter,
          componentProps: { filters, setFilters },
          default: conditionalDefaultQueries,
        }}
        a11y={{
          tableAriaLabel: 'adjustment list',
          rowPrefix: 'adjustment',
        }}
        rowItem={(adjustment) => {
          // Temporarily restrict linking into any Adjustment transaction ID, backend needs to look into why these transaction IDs are invalid.
          const isADJ = adjustment.transaction_id.includes('ADJ');
          return [
            <StyledTableBodyCell>
              <Typography
                onClick={() => {
                  if (!isADJ) {
                    navigate(
                      `/obligations/${id}/${member}/individual-transaction?id=${adjustment.transaction_id}&code=${adjustment.entitycode}&backAddress=${getCurrentRelativePath()}`
                    );
                  }
                }}
                sx={{
                  display: 'inline-block',
                  fontSize: '0.875rem',
                  color: theme.palette.functional.tableLink,
                  textDecoration: isADJ ? 'none' : 'underline',
                  cursor: isADJ ? 'default' : 'pointer',
                }}>
                {adjustment.transaction_id}
              </Typography>
            </StyledTableBodyCell>,
            <StyledTableBodyCell>{adjustment.description}</StyledTableBodyCell>,
            <StyledTableBodyCell sx={{ textTransform: 'capitalize' }}>
              {adjustment.user?.user_name}
            </StyledTableBodyCell>,
            <StyledTableBodyCell>
              {dayjs(adjustment.entry_date)?.format('DD MMM YYYY')}
            </StyledTableBodyCell>,
            <StyledTableBodyCell>{adjustment.entityname}</StyledTableBodyCell>,
            <StyledTableBodyCell>
              {formatAsCurrency(adjustment.netamount, adjustment.statcurrencycode)}
            </StyledTableBodyCell>,
            <StyledTableBodyCell>
              {formatAsCurrency(adjustment.vat_amount, adjustment.statcurrencycode)}
            </StyledTableBodyCell>,
          ];
        }}
      />
    </WhiteCard>
  );
};
