import * as mui from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { WhiteCard } from '../WhiteCard';
import { PaginatedTable } from '../../../components/Paginated Table/PaginatedTable';
import { TransactionFilter } from '../../../components/common/Filters/TransactionFilter';
import { useMemo, useState } from 'react';
import {
  currencyFormatterWithoutSymbol,
  formatDate,
  getCurrentRelativePath,
  nullableInvoiceNumber,
  reverseDate,
} from '../../../utils';

export const ExceptionTransactionTable = () => {
  const StyledTableBodyCell = mui.styled(mui.TableCell)(({ theme }) => ({
    fontSize: '0.875rem',
    padding: '0.25rem 0.5rem',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.baseColors.grey,
    height: '3rem',
  }));

  const theme = mui.useTheme();
  const navigate = useNavigate();
  const { id, query } = useParams();
  const [searchParams] = useSearchParams();
  const [tableDefaultFilters, setTableDefaultFilters] = useState([
    `obligation_id__eq=${id}`,
    `Test_Result__eq=UNRESOLVED`,
  ]);

  const convertObjectToArray = (obj) => {
    const columnNameMapping = {
      entitycode: 'Entity Code',
      postingperiod: 'Posting Period',
      entitycountrycode: 'Entity Country Code',
      taxcode: 'Tax Code',
      glaccountno: 'GL Account No',
      rule_id: 'Test',
    };
    const ruleDescription = obj['rule_description'];
    const res = Object.entries(obj)
      .filter(([key, value]) => !!columnNameMapping[key]) // Only get the keys that exist in the name dictionary.
      .map(([key, value]) =>
        key === 'rule_id'
          ? {
              field: {
                column_name: 'Test',
                mapping: key,
                type: 'text',
              },
              condition: {
                label: 'Equals to',
                operator: 'eq',
              },
              queryValue: value,
              displayValue: ruleDescription,
            }
          : {
              field: {
                column_name: columnNameMapping[key],
                mapping: key,
                type: 'text',
              },
              condition: {
                label: 'Equals to',
                operator:
                  value === 'Not Available' ? 'is_' : value?.split(',')?.length > 1 ? 'in_' : 'eq',
              },
              queryValue: value === 'Not Available' && key === 'glaccountno' ? null : value,
              displayValue: value === 'Not Available' ? 'Not Available' : value,
            }
      );

    return res;
  };

  const customFilters = useMemo(
    () => convertObjectToArray(Object.fromEntries(searchParams)),
    [searchParams]
  );

  const COLUMNS = [
    { label: 'Inv No.', property: 'invoiceno' },
    { label: 'Flow', property: 'flow' },
    { label: 'Test Name', property: 'rule_description' },
    { label: 'Entity', property: 'entityname' },
    { label: 'Statutory Currency', property: 'statcurrencycode' },
    { label: 'Gross', property: 'statgrossamount' },
    { label: 'Net', property: 'statnetamount' },
    { label: 'VAT', property: 'statvatamount' },
    { label: 'Inv Date', property: 'invoicedate' },
    { label: 'Posting Date', property: 'postingdate' },
    { label: 'Tax Code', property: 'taxcode' },
    { label: 'EntityCountryCode', property: 'entitycountrycode' },
  ];

  return (
    <mui.Stack>
      <mui.Box
        padding={'1.875rem 2.5rem'}
        sx={{
          backgroundColor: theme.palette.baseColors.white,
        }}>
        <mui.Typography
          component='h1'
          sx={{
            color: theme.palette.random[300],
            fontSize: '2.25rem',
            fontWeight: '700',
          }}>
          All Exceptions
        </mui.Typography>
      </mui.Box>

      <WhiteCard style={{ m: '2.5rem', p: '24px 24px 24px 24px' }}>
        <PaginatedTable
          api={{
            endpoint: '/exception/exception_rule',
            records: 'exception_summary',
            count: 'count',
          }}
          defaultColumn={'invoiceno'}
          columns={COLUMNS}
          filterInfo={{
            component: (
              <TransactionFilter
                fields={[
                  { column_name: 'Transaction ID', mapping: 'id', type: 'text' },
                  { column_name: 'Flow', mapping: 'flow', type: 'text' },
                  { column_name: 'Inv No', mapping: 'invoiceno', type: 'text' },
                  { column_name: 'Net', mapping: 'statnetamount', type: 'numeric' },
                  {
                    column_name: 'VAT',
                    mapping: 'statvatamount',
                    type: 'numeric',
                  },
                  { column_name: 'Entity', mapping: 'entityname', type: 'text' },
                  { column_name: 'Tax Code', mapping: 'taxcode', type: 'text' },
                  { column_name: 'GL Account No', mapping: 'glaccountno', type: 'text' },
                  {
                    column_name: 'Entity Country Code',
                    mapping: 'entitycountrycode',
                    type: 'text',
                  },
                  { column_name: 'Entity Code', mapping: 'entitycode', type: 'text' },
                  { column_name: 'Posting Period', mapping: 'postingperiod', type: 'numeric' },
                ]}
              />
            ),
            default: tableDefaultFilters,
          }}
          a11y={{
            tableAriaLabel: 'All transaction list',
            rowPrefix: 'transaction',
          }}
          customFilters={customFilters}
          hasCustomFilters
          rowItem={(exceptionData) => {
            // TODO: Modify this once API response is modelled
            const goToTransaction = (exception) =>
              navigate(
                `/obligations/${id}/${exception.entityname}/individual-transaction?id=${
                  exception.id
                }&code=${exception.entitycode}&backAddress=${getCurrentRelativePath()}`
              );
            return [
              <StyledTableBodyCell>
                <mui.Typography
                  onClick={() => goToTransaction(exceptionData)}
                  sx={{
                    display: 'inline-block',
                    fontSize: '0.875rem',
                    color: theme.palette.functional.tableLink,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}>
                  {nullableInvoiceNumber(exceptionData.invoiceno, exceptionData.id)}
                </mui.Typography>
              </StyledTableBodyCell>,
              <StyledTableBodyCell>{exceptionData.flow}</StyledTableBodyCell>,
              <StyledTableBodyCell>{exceptionData.rule_description}</StyledTableBodyCell>,
              <StyledTableBodyCell>{exceptionData.entityname}</StyledTableBodyCell>,
              <StyledTableBodyCell>{exceptionData.statcurrencycode}</StyledTableBodyCell>,
              <StyledTableBodyCell>
                {currencyFormatterWithoutSymbol(exceptionData.statgrossamount, false, {
                  minimumFractionDigits: 2,
                })}
              </StyledTableBodyCell>,
              <StyledTableBodyCell>
                {currencyFormatterWithoutSymbol(exceptionData.statnetamount, false, {
                  minimumFractionDigits: 2,
                })}
              </StyledTableBodyCell>,
              <StyledTableBodyCell>
                {currencyFormatterWithoutSymbol(exceptionData.statvatamount, false, {
                  minimumFractionDigits: 2,
                })}
              </StyledTableBodyCell>,
              <StyledTableBodyCell>
                {formatDate(reverseDate(exceptionData.invoicedate, '-'), '-')}
              </StyledTableBodyCell>,
              <StyledTableBodyCell>
                {formatDate(reverseDate(exceptionData.postingdate, '-'), '-')}
              </StyledTableBodyCell>,
              <StyledTableBodyCell>{exceptionData.taxcode}</StyledTableBodyCell>,
              <StyledTableBodyCell>{exceptionData.entitycountrycode}</StyledTableBodyCell>,
            ];
          }}
        />
      </WhiteCard>
    </mui.Stack>
  );
};
