import { SvgIcon } from '@mui/material';

export const GraphIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 13 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33333 0V10.6667H12V12H0V0H1.33333ZM11.5287 2.19533L12.4713 3.138L8.66667 6.94267L6.66667 4.94333L3.80467 7.80467L2.862 6.862L6.66667 3.05733L8.66667 5.05667L11.5287 2.19533Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
