import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useUser } from '../../../../context';
import { FieldCell } from './FieldCell';
import { StyledLinearProgress } from '../../../../components/common/Progress/StyledLinearProgress';

// STYLING
const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  height: '3rem',
}));

const isCurrencyField = (value) =>
  [
    'net',
    'gst',
    'gross',
    'recoverable',
    'non_recoverable',
    'vatamount',
    'netamount',
    'grossamount',
    'recoverablevatamount',
    'nonrecoverablevatamount',
  ].includes(value);

export const TransactionsTable = ({
  formData,
  isEditable,
  handleChange,
  handleCurrencyChange,
  relatedFields,
  originalFormData,
  backgroundColor,
  isSourceData = false,
}) => {
  const theme = useTheme();
  const { userToken } = useUser();
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([
    { label: '', property: 'rows', cellWidth: 10 },
    {
      label: 'Statutory Reporting',
      property: 'statutory_reporting',
      cellWidth: 7,
    },
    { label: 'Compliance', property: 'compliance', cellWidth: 7 },
  ]);

  const ROWS = [
    { label: 'Currency', value: 'currencycode' },
    { label: 'Net', value: 'netamount' },
    { label: 'VAT', value: 'vatamount' },
    { label: 'Gross', value: 'grossamount' },
    { label: 'Recoverable', value: 'recoverablevatamount', hideIfNull: true },
    { label: 'Non Recoverable', value: 'nonrecoverablevatamount', hideIfNull: true },
  ];

  const transactionPrefix = 'tx';
  const statutoryReportingPrefix = 'stat';
  const compliancePrefix = 'compliance';

  useEffect(() => {
    if (isSourceData)
      setColumns([
        { label: '', property: 'rows', cellWidth: 10 },
        {
          label: 'Transaction',
          property: 'transaction',
          cellWidth: 7,
        },
        {
          label: 'Statutory Reporting',
          property: 'statutory_reporting',
          cellWidth: 7,
        },
        { label: 'Compliance', property: 'compliance', cellWidth: 7 },
      ]);
  }, [isSourceData]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          overflow: 'hidden',
          backgroundColor: backgroundColor ? backgroundColor : '',
        }}>
        <Box
          sx={{
            overflowX: 'scroll !important',
          }}>
          <Table
            aria-label='obligations list'
            sx={{
              borderRadius: '0.5rem',
              border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            }}>
            <TableHead
              sx={{
                backgroundColor: theme.palette.neutralColors.black[100],
                height: '2.5rem',
              }}>
              <TableRow variant='body2'>
                {columns.map((column) => (
                  <StyledTableHeadingCell
                    key={column.property}
                    style={
                      column.cellWidth !== -1
                        ? { maxWidth: column.cellWidth + '%' }
                        : { minWidth: '33%' }
                    }>
                    {column.label}
                  </StyledTableHeadingCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <StyledTableBodyCell
                    colSpan={columns.length + 1}
                    sx={{ p: 0 }}>
                    <StyledLinearProgress />
                  </StyledTableBodyCell>
                </TableRow>
              )}
              {formData ? (
                ROWS.map((row) => (
                  <TableRow key={row.label}>
                    <StyledTableBodyCell
                      component='th'
                      scope='row'>
                      {row.label}
                    </StyledTableBodyCell>
                    {isSourceData && (
                      <StyledTableBodyCell
                        sx={{
                          backgroundColor:
                            !isEditable &&
                              relatedFields?.includes(`${transactionPrefix}${row.value}`)
                              ? theme.palette.primaryColors[100]
                              : '',
                          height: '20px',
                        }}>
                        <FieldCell
                          name={`${transactionPrefix}${row.value}`}
                          value={formData[`${transactionPrefix}${row.value}`]}
                          onChange={
                            isCurrencyField(row.value) ? handleCurrencyChange : handleChange
                          }
                          showLabel={false}
                          isEditable={isEditable}
                          isCurrency={isCurrencyField(row.value)}
                          isPermanent={!isCurrencyField(row.value) && isSourceData}
                          currencyCode={formData[`${transactionPrefix}currencycode`]}
                          highlightable={isEditable}
                          relatedFields={relatedFields}
                          originalFormData={originalFormData}
                          data={formData}
                          hideIfNull={row.hideIfNull}
                        />
                      </StyledTableBodyCell>
                    )}
                    <StyledTableBodyCell
                      sx={{
                        backgroundColor:
                          !isEditable &&
                            relatedFields?.includes(`${statutoryReportingPrefix}${row.value}`)
                            ? theme.palette.primaryColors[100]
                            : '',
                        height: '20px',
                      }}>
                      <FieldCell
                        name={`${statutoryReportingPrefix}${row.value}`}
                        value={formData[`${statutoryReportingPrefix}${row.value}`]}
                        onChange={isCurrencyField(row.value) ? handleCurrencyChange : handleChange}
                        showLabel={false}
                        isEditable={isEditable}
                        isCurrency={isCurrencyField(row.value)}
                        isPermanent={!isCurrencyField(row.value) && isSourceData}
                        currencyCode={formData[`${statutoryReportingPrefix}currencycode`]}
                        highlightable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        data={formData}
                        hideIfNull={row.hideIfNull}
                      />
                    </StyledTableBodyCell>
                    <StyledTableBodyCell
                      sx={{
                        backgroundColor:
                          !isEditable && relatedFields?.includes(`${compliancePrefix}${row.value}`)
                            ? theme.palette.primaryColors[100]
                            : '',
                      }}>
                      <FieldCell
                        name={`${compliancePrefix}${row.value}`}
                        value={formData[`${compliancePrefix}${row.value}`]}
                        onChange={isCurrencyField(row.value) ? handleCurrencyChange : handleChange}
                        showLabel={false}
                        isEditable={isEditable}
                        isCurrency={isCurrencyField(row.value)}
                        isPermanent={!isCurrencyField(row.value) && isSourceData}
                        currencyCode={formData[`${compliancePrefix}currencycode`]}
                        highlightable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        data={formData}
                        hideIfNull={row.hideIfNull}
                      />
                    </StyledTableBodyCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{
                    height: '3rem',
                    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  }}>
                  <StyledTableBodyCell
                    colSpan={columns.length}
                    sx={{ textAlign: 'center' }}>
                    {loading ? 'Fetching data...' : 'No data found'}
                  </StyledTableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </>
  );
};
