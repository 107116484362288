import {
  Stack,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
  styled,
  TableContainer,
  Paper,
  TableBody,
} from '@mui/material';
import { WhiteCard } from '../../WhiteCard';
import { useNavigate } from 'react-router-dom';
import { reconciliationBaseUrl, typographyStyle } from '../index';
import { SortIcon } from '../../../../components/common/CustomIcons/SortIcon';
import { useEffect, useState } from 'react';
import TextTag from '../../../../components/common/Text Tags/TextTag';
import { getReconciledItemColumns } from '../Constants/ReconciliationSummaryConstants';
import { useObligation, useUser } from '../../../../context';
import { formatAmount } from '../Utility/helperFunctions';
import { SortDescIcon } from '../../../../components/common/CustomIcons/SortDescIcon';
import { SortAscIcon } from '../../../../components/common/CustomIcons/SortAscIcon';
import { Toast } from '../../Toast';
import { useReconciliation } from '../../../../context/Reconciliation/ReconciliationContext';
import { StyledLinearProgress } from '../../../../components/common/Progress/StyledLinearProgress';
import { LeftArrowIcon } from '../../../../components/common/CustomIcons/LeftArrowIcon';

export const ReconciledItemList = ({ summaryPage }) => {
  const theme = useTheme();
  const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.secondaryColors[600],
    fontWeight: 700,
    fontSize: '0.875rem',
    padding: '0 1rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  }));
  const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    fontSize: '0.875rem',
    padding: '0 1rem',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.baseColors.grey,
    height: '3rem',
    border: 'none',
  }));

  const { userToken } = useUser();
  const navigate = useNavigate()
  const { selectedObgDetails } = useObligation();
  const { modalToast } = useReconciliation();

  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState({ type: '', message: '' });
  const [sortColumn, setSortColumn] = useState('entity');
  const [sortOrder, setSortOrder] = useState('asc');
  const [reconciledItems, setReconciledItems] = useState([]);

  const sortHandler = (col_name) => {
    if (col_name === sortColumn) {
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(col_name);
      // Due Date has the special case of starting to sort in Descending Order FIRST.
      setSortOrder('desc');
    }
  };

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (userToken && selectedObgDetails.obligation_id) {
      let url = '';
      setLoading(true);
      let baseUrl = '';
      switch (summaryPage) {
        case 1:
          baseUrl = '/reconciliation/trialbalance/recouncileditemlistall';
          break;
        case 2:
          baseUrl = '/reconciliation/vatgl/recouncileditemlistall';
          break;
        case 3:
          baseUrl = '/reconciliation/vatglwithouttaxcode/recouncileditemlistall';
          break;
        case 4:
          baseUrl = '/reconciliation/inputvat/recouncileditemlistall';
          break;
        default:
          break;
      }
      url = sortColumn
        ? process.env.REACT_APP_BACKEND_URL +
        `${baseUrl}?sort_column=${sortColumn}&sort_dir=${sortOrder}&${reconciliationBaseUrl(
          selectedObgDetails
        )}`
        : process.env.REACT_APP_BACKEND_URL +
        `${baseUrl}?${reconciliationBaseUrl(selectedObgDetails)}`;
      let _summaryDetails;
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          _summaryDetails = data;
        })
        .catch((err) => {
          setToast({
            type: 'failure',
            message: 'Reconciled item list Data could not be fetched.',
          });
        })
        .finally(() => {
          setReconciledItems(_summaryDetails);
          setLoading(false);
        });
    }
  }, [sortColumn, sortOrder, summaryPage, userToken, selectedObgDetails, modalToast]);

  return (
    <>
      <WhiteCard>
        <Accordion
          disableGutters
          sx={{
            border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            borderRadius: '0.5rem',
            flexDirection: 'row-reverse',
            boxShadow: 'none',
          }}>
          <AccordionSummary
            disableGutters
            expandIcon={<LeftArrowIcon />}
            sx={{
              flexDirection: 'row-reverse',
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg)',
              },
            }}
            aria-controls='panel1-content'
            id='panel1-header'>
            <Stack
              direction={'row'}
              sx={{ justifyContent: 'space-between', width: '100% ' }}>
              <Typography sx={typographyStyle('1.125rem', 500, theme.palette.random[300])}>
                Reconciled Items{' '}
                {reconciledItems?.transactionanalytics?.length >= 0
                  ? '(' + reconciledItems?.transactionanalytics?.length + ')'
                  : ''}
              </Typography>
              <Typography sx={typographyStyle('1rem', 500, theme.palette.secondaryColors[400])}>
                {formatAmount(reconciledItems?.total_sum, reconciledItems?.currency_code)}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              borderTop: `1px solid ${theme.palette.neutralColors.black[200]}`,
              margin: '0 1rem',
              px: 0,
            }}>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: 'none',
                overflowY: 'hidden',
              }}>
              <Table
                aria-label='selected files'
                sx={{
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  borderRadius: '0.5rem',
                }}>
                <TableHead
                  sx={{
                    backgroundColor: theme.palette.neutralColors.black[100],
                    height: '2.5rem',
                  }}>
                  <TableRow variant='body2'>
                    {getReconciledItemColumns(summaryPage, selectedObgDetails?.country_code).map(
                      (column) =>
                        column.sorting ? (
                          <StyledTableHeadingCell
                            sx={typographyStyle(
                              '.875rem',
                              700,
                              theme.palette.secondaryColors[600],
                              {
                                textAlign: column.align,
                              }
                            )}
                            style={
                              column.cellWidth !== -1 ? { minWidth: column.cellWidth + '%' } : {}
                            }
                            key={column.label}
                            onClick={() => sortHandler(column.name)}>
                            {column.label}
                            <IconButton disableRipple>
                              {sortColumn === column.name ? (
                                sortOrder === 'asc' ? (
                                  <SortDescIcon />
                                ) : (
                                  <SortAscIcon />
                                )
                              ) : (
                                <SortIcon />
                              )}
                            </IconButton>
                          </StyledTableHeadingCell>
                        ) : (
                          <StyledTableHeadingCell
                            key={column.label}
                            sx={typographyStyle(
                              '.875rem',
                              700,
                              theme.palette.secondaryColors[600],
                              {
                                textAlign: column.align,
                              }
                            )}
                            style={
                              column.cellWidth !== -1 ? { minWidth: column.cellWidth + '%' } : {}
                            }>
                            {column.label}
                          </StyledTableHeadingCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    overflowX: 'scroll',
                  }}>
                  {loading && (
                    <TableRow>
                      <TableCell
                        colSpan={getReconciledItemColumns(summaryPage).length}
                        sx={{ p: 0 }}>
                        <StyledLinearProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {reconciledItems?.transactionanalytics?.length > 0 ? (
                    reconciledItems?.transactionanalytics?.map((reconciledItem, index) => {
                      return (
                        <TableRow
                          key={reconciledItem.Entity}
                          sx={{
                            height: '3rem',
                            borderBottom: `1px solid #f3f3f3f3`,
                          }}>
                          <StyledTableBodyCell
                            sx={typographyStyle('.875rem', 700, theme.palette.baseColors.grey)}>
                            {reconciledItem.Entity}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            sx={typographyStyle('.875rem', 400, theme.palette.baseColors.grey, {
                              fontStyle: 'italic',
                            })}>
                            {reconciledItem.GLAccount}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            sx={typographyStyle('.875rem', 400, theme.palette.baseColors.grey, {
                              bgcolor: theme.palette.random[1500],
                              textAlign: 'right',
                              fontStyle: 'italic',
                            })}>
                            {formatAmount(
                              summaryPage === 1
                                ? reconciledItem.Trial_Balance
                                : reconciledItem.Vat_GL,
                              reconciledItems?.currency_code
                            )}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            sx={typographyStyle('.875rem', 400, theme.palette.baseColors.grey, {
                              bgcolor: theme.palette.random[1600],
                              textAlign: 'right',
                              fontStyle: 'italic',
                            })}>
                            {formatAmount(
                              reconciledItem.Transaction,
                              reconciledItems?.currency_code
                            )}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            sx={typographyStyle('.875rem', 400, theme.palette.baseColors.grey, {
                              textAlign: 'right',
                              fontStyle: 'italic',
                            })}>
                            {formatAmount(
                              reconciledItem.Amount_Reconciled,
                              reconciledItems?.currency_code
                            )}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            sx={typographyStyle('.875rem', 400, theme.palette.baseColors.grey, {
                              textAlign: 'right',
                              fontStyle: 'italic',
                            })}>
                            {formatAmount(
                              reconciledItem.Difference,
                              reconciledItems?.currency_code
                            )}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell
                            sx={typographyStyle('.875rem', 400, theme.palette.baseColors.grey, {
                              pl: 0,
                              textAlign: 'left',
                            })}>
                            <TextTag
                              text={reconciledItem.Adjustment_ID}
                              size='s'
                              style={{
                                backgroundColor: theme.palette.neutralColors.black[100],
                                color: theme.palette.random[100],
                                borderRadius: '2px',
                              }}
                            />
                          </StyledTableBodyCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <TableCell
                        colSpan={getReconciledItemColumns(summaryPage).length}
                        sx={{ textAlign: 'center' }}>
                        {loading ? 'Fetching data...' : 'No data found'}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </WhiteCard>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
