import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Card,
  Typography,
  Divider,
  Button,
  Alert,
  useTheme,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputLabel,
  Autocomplete,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { EditBoxLineIcon } from '../../../../components/common/CustomIcons/EditBoxLineIcon';
import { Cross2Icon } from '@radix-ui/react-icons';
import { TransactionsTable } from './TransactionTable';
import { ListNotes } from '../../Notes/ListNotes';
import { PageSkeleton } from './PageSkeleton';
import { SourceData } from './SourceData';
import { FieldCell } from './FieldCell';
import { ParentAccordion } from './Accordion';
import { UnresolvedExceptions } from './UnresolvedExceptions';
import { TaxTreatments } from './TaxTreatments';
import { TransactionHistory } from './TransactionHistory';
import { DatabaseIcon } from '../../../../components/common/CustomIcons/DatabaseIcon';
import { BillLineIcon } from '../../../../components/common/CustomIcons/Exceptions/BillLineIcon';
import { Toast } from '../../Toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useObligation, useUser } from '../../../../context';
import { WarningPopup } from '../../../../components/common/Modal/WarningPopup';
import { BtnFilled } from '../../../../components/common/Buttons/BtnFilled';
import { UseAuth } from '../../../../context/AuthContext';
import { AuthError, CheckAuthorization } from '../../Authorization/Authorization';
import { formatDate, getCurrentRelativePath, reverseDate } from '../../../../utils';
import { SimpleSelect } from '../../../../components/common/Dropdowns/SimpleSelect';

const TransactionDetail = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery('(min-width:1800px)');
  const { userToken } = useUser();
  const navigate = useNavigate();
  const { id: obligationId, member } = useParams();
  const {
    selectedObgDetails: obligation,
    selectedObgHandler,
    isObligationLocked,
  } = useObligation();
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('id');
  const entityCode = searchParams.get('code');
  const backAddress = searchParams.get('backAddress');
  const resolveExceptionsParam = searchParams.get('resolveException');
  const urlBase = process.env.REACT_APP_BACKEND_URL;
  const [isEditable, setIsEditable] = useState(false);
  const [selected, setSelected] = useState(null);
  const [relatedFields, setRelatedFields] = useState([]);
  const [currentTab, setCurrentTab] = useState('compliance');
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState({ type: '', message: '' });
  const [unresolvedExceptions, setUnresolvedExceptions] = useState([]);
  const [taxTreatments, setTaxTreatments] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [formData, setFormData] = useState({});
  const [originalFormData, setOriginalFormData] = useState({});
  const [dataNeedsReload, setDataNeedsReload] = useState(false);
  const [lockId, setLockId] = useState(null);
  const [editLockLoading, setEditLockLoading] = useState(false);
  const [intervalTracker, setIntervalTracker] = useState(null);
  const [changesSaved, setChangesSaved] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [showValidationPopup, setShowValidationPopup] = useState(false);
  const [resolveExceptionsArray, setResolveExceptionsArray] = useState([]);
  const { authorized, setAuthorized, authLoader, setAuthLoader } = UseAuth();
  const [taxTreatmentList, setTaxTreatmentList] = useState([]);
  const [taxCodeOptions, setTaxCodeOptions] = useState([]);
  const [taxTreatmentOptions, setTaxTreatmentOptions] = useState([]);
  const [obligationLoader, setObligationLoader] = useState(true);
  const nullableValues = [
    'taxtreatment',
    'customercountrycode',
    'entrydate',
    'entityname',
    'entitycountycode',
    'entitycode',
    'billtocountrycode',
    'suppliercountrycode',
    'customerindustry',
    'supplierno',
    'customerno',
    'shiptono',
    'shiptoname',
    'shipfromaddress',
    'shiptocountrycode',
    'shipfromno',
    'shipfromname',
    'shipfromaddress',
    'shipfromcity',
    'shipfrompostcode',
    'shipfromcountrycode',
    'shiptoaddress',
    'observedvatrate',
    'jurisdictionalvatrate',
    'postingdate',
    'invoicedate',
    'determinedvatrate',
    'shiptocity',
    'shiptopostcode',
  ];

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (!userToken) return;
    setObligationLoader(true);
    const url =
      process.env.REACT_APP_BACKEND_URL +
      `/obligationsummary/listall?limit=1&filter_query=obligation_id__eq=${obligationId}%26member__eq=${member}`;
    let _obligation;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {


        if (res.ok()) return res.json();
        throw new Error(
          `Something went wrong while fetching Obligation ID ${obligationId}, Member ${member}. Status code: ${res.statusCode}`
        );
      })
      .then((data) => {
        if (data?.summary.length === 0)
          throw new Error(
            `Obligation with ID ${obligationId} and Member ${member} could not be found in the Database!`
          );
        _obligation = data?.summary[0];
      })
      .catch((err) => {
        setToast({
          type: 'failure',
          message: 'Obligation data could not be fetched.',
        });
      })
      .finally(() => {
        selectedObgHandler(_obligation);
        setObligationLoader(false);
      });
  }, [userToken, obligationId, member]);

  useEffect(() => {
    if (!userToken) return;
    CheckAuthorization(userToken, setAuthorized, obligationId, setAuthLoader);
  }, [userToken]);

  const getTaxTreatment = (entitycountrycode) => {
    fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/transaction/transactionfeaturelistall?filter_query=countrycode__eq=${entitycountrycode}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {

        if (res.ok) return res.json();

        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        const taxTreatments = data?.transactionfeature?.map(
          ({ taxtreatment_id, taxtreatmentname, taxcode }) => ({
            taxtreatment_id,
            taxtreatmentname,
            taxcode,
          })
        );
        const taxTreatmentoptionList = data?.transactionfeature?.map((obj) => obj.taxtreatmentname);
        setTaxTreatmentList(taxTreatments);
        setTaxTreatmentOptions([...new Set(taxTreatmentoptionList)]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (resolveExceptionsParam) {
      const decoded = decodeURIComponent(resolveExceptionsParam);

      if (decoded.startsWith('[') && decoded.endsWith(']')) {
        // Remove the brackets and split by ',' ensuring not to cut off data after '#'
        const resolveExceptionArray = decoded.slice(1, -1).split(/,(?=[^\s])/);

        if (resolveExceptionArray.length > 0) {
          setResolveExceptionsArray(resolveExceptionArray);
          setIsEditable(true);

          setEditLockLoading(true);
          createLock(transactionId)
            .then((lockId) => {
              setLockId(lockId);
              setEditLockLoading(false);
            })
            .catch((error) => {
              console.error('Error obtaining lock:', error);
              setToast({ type: 'error', message: error.message });
              setEditLockLoading(false);
            });
        }
      }
    }
  }, [resolveExceptionsParam, transactionId]);

  const createLock = async (transactionId) => {
    setEditLockLoading(true);
    const url = `${urlBase}/transaction/createlock?transaction_id=${transactionId}`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { Authorization: `Bearer ${userToken}` },
        credentials: 'include',
      });

      const data = await response.json();

      if (!response.ok) {
        setEditLockLoading(false);
        if (response.status === 401 && data.detail.includes('currently locked by other user')) {
          setToast({
            type: 'error',
            message: data.detail,
          });
        } else {
          setToast({
            type: 'error',
            message: 'Failed to create edit lock.',
          });
        }
        throw new Error(data.detail || 'Failed to create lock');
      }

      setLockId(data?.editlock?.lock_id);
      setToast({
        type: 'success',
        message: 'Edit lock successfully created.',
      });
      setIsEditable(true);
      setEditLockLoading(false);
      return data?.editlock?.lock_id;
    } catch (error) {
      console.error('Error creating lock:', error);
      setEditLockLoading(false);
      throw error;
    }
  };

  const refreshLock = async (lockId) => {
    const url = `${urlBase}/transaction/refreshlock?lock_id=${lockId}`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: { Authorization: `Bearer ${userToken}` },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to refresh lock');
      }

      setToast({
        type: 'success',
        message: 'Edit lock successfully refreshed.',
      });
      return await response.json();
    } catch (error) {
      console.error('Error refreshing lock:', error);
      throw error;
    }
  };

  const removeLock = async (lockId) => {
    const url = `/api/transaction/removelock/${lockId}`;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${userToken}` },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to remove lock');
      }

      setToast({
        type: 'success',
        message: 'Edit lock successfully removed.',
      });
      clearInterval(intervalTracker);
      return await response.json();
    } catch (error) {
      console.error('Error removing lock:', error);
      throw error;
    }
  };

  const getRelatedFields = async (ruleId) => {
    const url = `${urlBase}/exception/highlighted_fields?rule_id=${ruleId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${userToken}` },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to get related fields.');
      }

      const data = await response.json();
      const fields = data.Highlightfields.split(',').map((field) => field.trim());
      setRelatedFields(fields);

      setToast({
        type: 'success',
        message: 'Related fields updated.',
      });
      return data;
    } catch (error) {
      console.error('Error fetching related fields:', error);
      setToast({
        type: 'error',
        message: 'Failed to fetch related fields.',
      });
      throw error;
    }
  };

  const resolveException = async (exceptionId) => {
    const requestBody = Array.isArray(exceptionId) ? exceptionId : [exceptionId];

    const url = `${urlBase}/exception/resolveexception`;
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error('Failed to resolve exception');
      }

      setToast({
        type: 'success',
        message: 'Exception successfully resolved.',
      });

      return await response.json();
    } catch (error) {
      setIsLoading(false);
      console.error('Error resolving exception:', error);
      setToast({
        type: 'error',
        message: 'Failed to resolve exception(s).',
      });
      throw error;
    }
  };

  const handleResolveException = async (exceptionId) => {
    try {
      await resolveException(exceptionId);
      setDataNeedsReload(!dataNeedsReload); // Toggle to force reload
    } catch (error) {
      console.error('Failed to resolve exception:', error);
    }
  };

  const handleTabChange = (newTab) => {
    if (newTab !== 'compliance') {
      setRelatedFields([]);
      setSelected(null);
    }
    setCurrentTab(newTab);
  };

  const sanitizeDate = (date) => {
    if (date instanceof Date !== true) return date;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedMonth = month < 10 ? '0' + month : month;
    const formattedDay = day < 10 ? '0' + day : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const prepareApiPayload = useCallback((input) => {
    const output = {
      flow: input.flow,
      source: input.source,
      statnetamount: input.statnetamount,
      statvatamount: input.statvatamount,
      taxcode: input.taxcode,
      suppliercountrycode: input.suppliercountrycode,
      statcurrencycode: input.statcurrencycode,
      compliancecounterpartycompanyname: input.compliancecounterpartycompanyname,
      statgrossamount: input.statgrossamount,
      txvatamount: input.txvatamount,
      glaccountno: input.glaccountno,
      taxpointdate: sanitizeDate(input.taxpointdate),
      complianceentityvatregistrationno: input.complianceentityvatregistrationno,
      complianceentitycompanyname: input.complianceentitycompanyname,
      compliancereportingcountrycode: input.compliancereportingcountrycode,
      compliancereportingcountryregion: input.compliancereportingcountryregion,
      compliancecounterpartyvatregistrationno: input.compliancecounterpartyvatregistrationno,
      compliancecounterpartycountrycode: input.compliancecounterpartycountrycode,
      taxtreatment: input.taxtreatment,
      customercountrycode: input.customercountrycode,
      erpreportingcountrycode: input.erpreportingcountrycode,
      compliancereportingdate: sanitizeDate(input.compliancereportingdate),
      compliancecurrencycode: input.compliancecurrencycode,
      compliancenetamount: input.compliancenetamount,
      compliancevatamount: input.compliancevatamount,
      compliancegrossamount: input.compliancegrossamount,
      compliancerecoverablevatamount: input.compliancerecoverablevatamount,
      statrecoverablevatamount: input.statrecoverablevatamount,
      compliancenonrecoverablevatamount: input.compliancenonrecoverablevatamount,
      statnonrecoverablevatamount: input.statnonrecoverablevatamount,
      entitycode: input.entitycode,
      entityname: input.entityname,
      entitycountrycode: input.entitycountrycode,
      billtocountrycode: input.billtocountrycode,
      customerindustry: input.customerindustry,
      supplierno: input.supplierno,
      customerno: input.customerno,
      jurisdictionalvatrate: input.jurisdictionalvatrate,
      observedvatrate: input.observedvatrate,
      shiptono: input.shiptono,
      shiptoname: input.shiptoname,
      shiptoaddress: input.shiptoaddress,
      shiptocountrycode: input.shiptocountrycode,
      shiptocity: input.shiptocity,
      shiptopostcode: input.shiptopostcode,
      shipfromno: input.shipfromno,
      shipfromname: input.shipfromname,
      shipfromaddress: input.shipfromaddress,
      shipfromcountrycode: input.shipfromcountrycode,
      shipfromcity: input.shipfromcity,
      shipfrompostcode: input.shipfrompostcode,
      postingdate: sanitizeDate(input.postingdate),
      invoicedate: sanitizeDate(input.invoicedate),
      determinedvatrate: input.determinedvatrate,
    };

    return output;
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setNoDataAvailable(false); // Reset the no data flag on each new request

    const urlBase = process.env.REACT_APP_BACKEND_URL;
    const authHeaders = {
      method: 'GET',
      headers: { Authorization: `Bearer ${userToken}` },
      credentials: 'include',
    };

    const safeFetch = async (url, options, fallback) => {
      try {
        const response = await fetch(url, options);
        if (!response.ok) throw new Error(`API responded with status ${response.status}`);
        return await response.json();
      } catch (error) {
        console.error(`Error fetching from ${url}:`, error);
        return fallback;
      }
    };

    const fetchTransactionDetails = safeFetch(
      `${urlBase}/transaction/listall?filter_query=obligation_id__eq=${obligation?.obligation_id}%26id__eq=${transactionId}%26entitycode__eq=${entityCode}`,
      authHeaders,
      { transactions: [] }
    );

    const fetchUnresolvedExceptions = safeFetch(
      `${urlBase}/exception/listallexceptions?filter_query=group_id__eq=Taxrule%26tax_item_id__eq=${transactionId}%26result_adjustment_status__eq%3DUNRESOLVED`,
      authHeaders,
      { exceptions: [] }
    );

    const fetchTaxTreatments = safeFetch(
      `${urlBase}/exception/taxtreatment?id=${transactionId}`,
      authHeaders,
      { keys: [] }
    );

    const fetchTransactionHistory = safeFetch(
      `${urlBase}/exception/transactionhistory?id=${transactionId}`,
      authHeaders,
      []
    );

    const fetchSourceData = safeFetch(
      `${urlBase}/exception/source_listall?filter_query=obligation_id__eq=${obligation?.obligation_id}%26id__eq=${transactionId}%26entitycode__eq=${entityCode}`,
      authHeaders,
      []
    );

    try {
      const [
        transactionDetails,
        unresolvedExceptions,
        taxTreatments,
        transactionHistory,
        sourceData,
      ] = await Promise.all([
        fetchTransactionDetails,
        fetchUnresolvedExceptions,
        fetchTaxTreatments,
        fetchTransactionHistory,
        fetchSourceData,
      ]);

      if (transactionDetails.transactions.length === 0) {
        setNoDataAvailable(true);
      } else {
        getTaxTreatment(transactionDetails.transactions[0].entitycountrycode);
        setFormData(transactionDetails.transactions[0]);
        setOriginalFormData(transactionDetails.transactions[0]);
      }

      setUnresolvedExceptions(unresolvedExceptions.exception || []);
      setTaxTreatments(taxTreatments.keys || []);
      setTransactionHistory(transactionHistory || []);
      setSourceData(sourceData || []);
    } catch (error) {
      console.error('Error during combined data fetch:', error);
      setToast({
        type: 'error',
        message: 'Failed to fetch data. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [userToken, obligation, transactionId, entityCode]);

  useEffect(() => {
    if (
      userToken &&
      obligation?.obligation_id &&
      !obligationLoader &&
      transactionId &&
      entityCode
    ) {
      fetchData();
    }
  }, [transactionId, userToken, obligation?.obligation_id, entityCode, obligationLoader]);

  useEffect(() => {
    if (dataNeedsReload) {
      fetchData();
      setDataNeedsReload(false);
    }
  }, [dataNeedsReload, fetchData]);

  useEffect(() => {
    let refreshInterval;
    if (lockId) {
      refreshInterval = setInterval(() => {
        refreshLock(lockId).catch((error) => {
          clearInterval(refreshInterval);
          setIsEditable(false);
          setToast({
            type: 'error',
            message: 'Lock refresh failed. Editing disabled.',
          });
        });
      }, 600000); // 10 minutes
      setIntervalTracker(refreshInterval);
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [lockId, isEditable]);

  const availableTabs = useMemo(
    () => [
      {
        name: 'Compliance',
        state: 'enabled',
        onClick: () => handleTabChange('compliance'),
        icon: (
          <BillLineIcon
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              color: currentTab === 'compliance' ? theme.palette.primaryColors[200] : '',
              '&:hover': {
                color: theme.palette.primaryColors[200],
              },
            }}
          />
        ),
      },
      {
        name: 'Source Data',
        state: 'enabled',
        onClick: () => handleTabChange('source data'),
        icon: (
          <DatabaseIcon
            sx={{
              width: '1.5rem',
              height: '1.5rem',
              color: currentTab === 'source data' ? theme.palette.primaryColors[200] : '',
              '&:hover': {
                color: theme.palette.primaryColors[200],
              },
            }}
          />
        ),
      },
    ],
    [currentTab, theme]
  );

  const toggleEditMode = () => {
    if (isEditable) {
      setIsEditable(false);
      setFormData({ ...originalFormData });
      removeLock(lockId);
    } else {
      setEditLockLoading(true);
      createLock(transactionId)
        .then((lockId) => {
          setLockId(lockId);
          setIsEditable(true);
          setEditLockLoading(false);
          getTaxTreatment(formData?.entitycountrycode);
        })
        .catch((error) => {
          setToast({ type: 'error', message: error.message });
          setEditLockLoading(false);
        });
    }
  };

  const handleSave = useCallback(() => {
    setOriginalFormData({ ...formData });
    setChangesSaved(true);
    setIsEditable(false);
  }, [formData]);

  const validateAmounts = useCallback(() => {
    const epsilon = 0.005;

    const areFloatsClose = (a, b) => {
      return Math.abs(a - b) < epsilon;
    };

    const sumStatutoryVAT =
      parseFloat(formData.statrecoverablevatamount) +
      parseFloat(formData.statnonrecoverablevatamount);
    const sumComplianceVAT =
      parseFloat(formData.compliancerecoverablevatamount) +
      parseFloat(formData.compliancenonrecoverablevatamount);
    const sumStatutoryGross =
      parseFloat(formData.statnetamount) + parseFloat(formData.statvatamount);
    const sumComplianceGross =
      parseFloat(formData.compliancenetamount) + parseFloat(formData.compliancevatamount);

    const isValidStatutoryVAT = areFloatsClose(sumStatutoryVAT, parseFloat(formData.statvatamount));
    const isValidStatutoryGross = areFloatsClose(
      sumStatutoryGross,
      parseFloat(formData.statgrossamount)
    );
    const isValidComplianceVAT = areFloatsClose(
      sumComplianceVAT,
      parseFloat(formData.compliancevatamount)
    );
    const isValidComplianceGross = areFloatsClose(
      sumComplianceGross,
      parseFloat(formData.compliancegrossamount)
    );

    return (
      isValidStatutoryVAT && isValidStatutoryGross && isValidComplianceVAT && isValidComplianceGross
    );
  }, [formData]);

  const handleSpecialSubmit = useCallback(() => {
    if (!validateAmounts()) {
      setShowValidationPopup(true);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const payload = prepareApiPayload(formData);

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/transaction/editedtransaction?transaction_id=${transactionId}&lock_id=${lockId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to submit transaction form: ${response.statusText}`);
        }
        return response.json();
      })
      .then(async () => {
        await resolveException(resolveExceptionsArray);

        setToast({
          type: 'success',
          message: 'Successfully updated transaction and resolved exceptions.',
        });
        setChangesSaved(false);
        setDataNeedsReload(true);
        setIsEditable(false);
      })
      .catch((error) => {
        console.error('Error submitting transaction form:', error);
        setToast({
          type: 'failure',
          message: 'Failed to update transaction and resolve exceptions, please try again.',
        });
        setIsLoading(false);
      });
  }, [
    transactionId,
    lockId,
    userToken,
    formData,
    validateAmounts,
    resolveExceptionsParam,
    resolveException,
  ]);

  const handleSubmit = useCallback(() => {
    if (resolveExceptionsParam) {
      handleSpecialSubmit();
    } else {
      if (!validateAmounts()) {
        setShowValidationPopup(true);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const payload = prepareApiPayload(formData);

      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transaction/editedtransaction?transaction_id=${transactionId}&lock_id=${lockId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          credentials: 'include',
          body: JSON.stringify(payload),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to submit transaction form: ${response.statusText}`);
          }
          return response.json();
        })
        .then(() => {
          setToast({
            type: 'success',
            message: 'Successfully updated transaction.',
          });
          setChangesSaved(false);
          setDataNeedsReload(true);
          setIsEditable(false);
        })
        .catch((error) => {
          console.error('Error submitting transaction form:', error);
          setToast({
            type: 'failure',
            message: 'Failed to update transaction, please try again.',
          });
          setIsLoading(false);
        });
    }
  }, [
    transactionId,
    lockId,
    userToken,
    formData,
    validateAmounts,
    resolveExceptionsParam,
    handleSpecialSubmit,
  ]);

  const handleChange = (e, isCurrency, currencyName, currencyValue) => {
    if (isCurrency && currencyName && currencyValue) {
      return setFormData((prevFormData) => ({
        ...prevFormData,
        [currencyName]: currencyValue,
      }));
    }

    const { name, value } = e.target;
    if (name.includes('currencycode')) {
      if (!/^[a-zA-Z]*$/.test(value)) {
        console.error('Invalid input: Currency code should only contain letters.');
        setToast({
          type: 'failure',
          message: 'Invalid input: Currency code should only contain letters.',
        });
        return;
      }
    }

    setChangesSaved(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTaxtreatmentChange = (e) => {
    let newField = e.target.value;
    let filteredOptions = [];
    filteredOptions = taxTreatmentList.filter((f) => f.taxtreatmentname === newField);
    if (filteredOptions?.length === 1) {
      setTaxCodeOptions([]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        taxtreatment: filteredOptions[0].taxtreatment_id,
        taxtreatmentname: newField,
        taxcode: filteredOptions[0].taxcode,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        taxtreatmentname: newField,
        taxtreatment: null,
        taxcode: null,
      }));
      setTaxCodeOptions(filteredOptions);
    }
  };

  const handleCurrencyChange = (currencyName, currencyValue) =>
    setFormData((prevFormData) => ({
      ...prevFormData,
      [currencyName]: currencyValue,
    }));

  const payloadHasChanges = (payload1, payload2) => {
    const keys = Object.keys(payload1);
    return keys.some((key) => {
      const val1 = payload1[key];
      const val2 = payload2[key];
      if (typeof val1 === 'number' && typeof val2 === 'number') {
        return val1 !== val2;
      } else if (val1 instanceof Date && val2 instanceof Date) {
        return val1.getTime() !== val2.getTime();
      } else {
        return val1 !== val2;
      }
    });
  };

  const preparedOriginal = useMemo(() => prepareApiPayload(originalFormData), [originalFormData]);
  const preparedCurrent = useMemo(() => prepareApiPayload(formData), [formData]);

  const isFormValid = useMemo(() => {
    return Object.entries(preparedCurrent).every(([key, value]) => {
      if (nullableValues.includes(key)) {
        // Allow taxtreatment and customercountrycode to be null or empty
        return true;
      }

      if (typeof value === 'string') {
        return value.trim() !== '';
      } else if (typeof value === 'number') {
        return !isNaN(value);
      } else if (value instanceof Date) {
        return !isNaN(value.getTime());
      } else {
        return value !== null;
      }
    });
  }, [preparedCurrent]);

  const hasChanges = useMemo(() => {
    return payloadHasChanges(preparedOriginal, preparedCurrent);
  }, [preparedOriginal, preparedCurrent]);

  if (noDataAvailable) {
    return (
      <PageSkeleton
        title='Transaction Details'
        isLoading={isLoading}
        hasNavMenu={false}>
        <Alert
          severity='warning'
          variant='outlined'
          sx={{
            width: '100%',
            fontSize: '1.25rem',
            p: 2,
          }}>
          No transaction data available.
        </Alert>
      </PageSkeleton>
    );
  }

  const pageTitle = [
    formData?.compliancecounterpartycompanyname,
    formData?.invoiceno,
    reverseDate(formatDate(formData?.invoicedate, '-'), ' '),
  ]
    .filter((p) => p)
    .join(' - ');

  return (
    !authLoader &&
    (authorized === 'Authorized' ? (
      <PageSkeleton
        title={pageTitle}
        availableTabs={availableTabs}
        currentTab={currentTab}
        isLoading={isLoading}
        url={backAddress}>
        {showValidationPopup && (
          <WarningPopup
            closeWarningPopupHandler={() => setShowValidationPopup(false)}
            continueHandler={() => setShowValidationPopup(false)}
            warningTitle={'Warning!'}
            warningMessage={
              <>
                <DialogTitle id='alert-dialog-title'>Amount Table Validation Error</DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id='alert-dialog-description'
                    sx={{ fontSize: '1rem' }}>
                    To ensure accuracy, please verify the following conditions:
                    <ul
                      style={{
                        paddingLeft: '20px',
                        listStyleType: 'circle',
                        marginTop: '0.5rem',
                      }}>
                      <li style={{ marginBottom: '0.5rem' }}>
                        The combined sum of Recoverable and Non-Recoverable VAT must match the total
                        VAT amount for both Statutory and Compliance sections.
                      </li>
                      <li style={{ marginBottom: '0.5rem' }}>
                        The sum of Net and VAT values must be equal to the Gross total for both
                        Statutory and Compliance sections.
                      </li>
                    </ul>
                  </DialogContentText>
                </DialogContent>
              </>
            }
          />
        )}
        {currentTab === 'compliance' ? (
          <Box
            display='grid'
            gridTemplateColumns='repeat(5,1fr)'
            gridTemplateRows='repeat(5, 1fr)'
            gap='1rem'>
            <Box gridArea='1/1/6/4'>
              <Card
                sx={{
                  p: 2,
                  margin: 'auto',
                  overflow: 'hidden',
                  width: '100%',
                  borderRadius: '0.5rem',
                }}>
                <Box
                  display='grid'
                  gap={2}
                  gridTemplateColumns='repeat(3, 1fr)'>
                  {/* Section 1: Overview */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <Box
                      gridColumn='span 3'
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'>
                      <Typography
                        sx={{
                          fontSize: '1.125rem',
                          fontWeight: 600,
                          color: '#000000',
                        }}>
                        Overview
                      </Typography>
                      <Button
                        variant='outlined'
                        startIcon={isEditable ? <Cross2Icon /> : <EditBoxLineIcon />}
                        onClick={toggleEditMode}
                        disabled={isObligationLocked || editLockLoading}
                        sx={{
                          borderColor: theme.palette.neutralColors.black[200],
                          color: 'grey',
                          '&:hover': {
                            backgroundColor: 'white',
                            borderColor: 'transparent',
                          },
                          borderRadius: 1,
                          textTransform: 'none',
                        }}>
                        {editLockLoading ? 'Processing...' : isEditable ? 'Cancel' : 'Edit'}
                      </Button>
                    </Box>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Box
                      display='grid'
                      gap={2}
                      gridTemplateColumns='repeat(3, 1fr)'>
                      <FieldCell
                        label='Transaction ID'
                        name='id'
                        value={formData?.id}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        isPermanent
                      />
                      <FieldCell
                        label='Entry Date'
                        name='entrydate'
                        value={formData?.postingdate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        isPermanent
                        isDatePicker
                      />
                      <FieldCell
                        label='Flow'
                        name='flow'
                        value={formData?.flow}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Source'
                        name='source'
                        value={formData?.source}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                    </Box>
                  </Box>

                  {/* Section 2: Compliance Entity */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        color: '#000000',
                      }}>
                      Compliance Entity
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Box
                      display='grid'
                      gap={2}
                      gridTemplateColumns='repeat(3, 1fr)'>
                      <FieldCell
                        label={'VAT Registration No.'}
                        name='complianceentityvatregistrationno'
                        value={formData?.complianceentityvatregistrationno}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Company Name'
                        name='complianceentitycompanyname'
                        value={formData?.complianceentitycompanyname}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Compliance Reporting Country Code'
                        name='compliancereportingcountrycode'
                        value={formData?.compliancereportingcountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Compliance Reporting Country Region'
                        name='compliancereportingcountryregion'
                        value={formData?.compliancereportingcountryregion}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Entity Name'
                        name='entityname'
                        value={formData?.entityname}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Entity County Code'
                        name='entitycountrycode'
                        value={formData?.entitycountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        isPermanent
                      />
                      <FieldCell
                        label='Entity Code'
                        name='entitycode'
                        value={formData?.entitycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                    </Box>
                  </Box>

                  {/* Section 3: Compliance Counterparty */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        color: theme.palette.baseColors.black,
                      }}>
                      Compliance Counterparty
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Box
                      display='grid'
                      gap={2}
                      gridTemplateColumns='repeat(3, 1fr)'>
                      <FieldCell
                        label={'VAT Registration No.'}
                        name='compliancecounterpartyvatregistrationno'
                        value={formData?.compliancecounterpartyvatregistrationno}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Company Name'
                        name='compliancecounterpartycompanyname'
                        value={formData?.compliancecounterpartycompanyname}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Country Code'
                        name='compliancecounterpartycountrycode'
                        value={formData?.compliancecounterpartycountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Bill to Country Code'
                        name='billtocountrycode'
                        value={formData?.billtocountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Supplier County Code'
                        name='suppliercountrycode'
                        value={formData?.suppliercountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Customer Industry'
                        name='customerindustry'
                        value={formData?.customerindustry}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Supplier Number'
                        name='supplierno'
                        value={formData?.supplierno}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Customer Number'
                        name='customerno'
                        value={formData?.customerno}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                    </Box>
                  </Box>

                  {/* Section 4: Ship To */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        lineHeight: '28px',
                        color: '#000000',
                      }}>
                      Ship To
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Box
                      display='grid'
                      gap={2}
                      gridTemplateColumns='repeat(3, 1fr)'>
                      <FieldCell
                        label='Number'
                        name='shiptono'
                        value={formData?.shiptono}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Name'
                        name='shiptoname'
                        value={formData?.shiptoname}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Address'
                        name='shiptoaddress'
                        value={formData?.shiptoaddress}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='County Code'
                        name='shiptocountrycode'
                        value={formData?.shiptocountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                    </Box>
                  </Box>

                  {/* Section 5: Ship From */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        lineHeight: '28px',
                        color: '#000000',
                      }}>
                      Ship From
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Box
                      display='grid'
                      gap={2}
                      gridTemplateColumns='repeat(3, 1fr)'>
                      <FieldCell
                        label='Number'
                        name='shipfromno'
                        value={formData?.shipfromno}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Name'
                        name='shipfromname'
                        value={formData?.shipfromname}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Address'
                        name='shipfromaddress'
                        value={formData?.shipfromaddress}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='City'
                        name='shipfromcity'
                        value={formData?.shipfromcity}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Post Code'
                        name='shipfrompostcode'
                        value={formData?.shipfrompostcode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='County Code'
                        name='shipfromcountrycode'
                        value={formData?.shipfromcountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                    </Box>
                  </Box>

                  {/* Section 6: Tax Treatments */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        lineHeight: '28px',
                        color: '#000000',
                      }}>
                      Tax Treatments
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <Box
                      display='grid'
                      gap={2}
                      gridTemplateColumns='repeat(3, 1fr)'>
                      <Box mt={2}>
                        <InputLabel
                          htmlFor='tax treatment'
                          sx={{
                            fontSize: '.875rem',
                            fontWeight: isEditable ? 500 : 700,
                            color: theme.palette.baseColors.grey,
                            width: 'max-content',
                          }}>
                          Tax Treatment
                        </InputLabel>
                        {isEditable ? (
                          <SimpleSelect
                            id='tax treatment'
                            options={taxTreatmentOptions}
                            value={formData?.taxtreatmentname}
                            disabled={!isEditable}
                            onChange={handleTaxtreatmentChange}
                            styling={{
                              width: isLargeScreen ? '100%' : '17rem',
                              pr: '0',
                              mt: '.6rem',
                            }}
                            menStyling={{ maxHeight: '14rem', width: '26rem' }}
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: 500,
                              cursor: 'pointer',
                              color: theme.palette.baseColors.grey,
                            }}>
                            {formData?.taxtreatmentname}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        mt={2}
                        gap={'1.5rem'}>
                        <InputLabel
                          htmlFor='tax code'
                          sx={{
                            fontSize: '.875rem',
                            fontWeight: isEditable ? 500 : 700,
                            color: theme.palette.baseColors.grey,
                          }}>
                          Tax Code
                        </InputLabel>
                        {isEditable ? (
                          <Autocomplete
                            id='tax treatment'
                            freeSolo
                            disableClearable={true}
                            value={formData?.taxcode ?? ''}
                            inputValue={formData?.taxcode ?? ''}
                            aria-disabled={true}
                            blurOnSelect
                            onChange={(e, value) => {
                              let selected = taxCodeOptions?.find((f) => f.taxcode === value);
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                taxtreatment: selected?.taxtreatment_id,
                                taxtreatmentname: selected?.taxtreatmentname,
                                taxcode: selected?.taxcode,
                              }));
                            }}
                            options={
                              taxCodeOptions?.length > 0
                                ? taxCodeOptions?.map((f) => f?.taxcode)
                                : []
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ padding: '6px' }}
                                readOnly
                                placeholder='select taxcode'
                                inputProps={{
                                  ...params.inputProps,
                                  style: { cursor: 'pointer' }, // Set the cursor property to none
                                  readOnly: true, // Set the readOnly attribute to true
                                }}
                                value={formData?.taxcode ?? ''}
                              />
                            )}
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: 500,
                              cursor: 'pointer',
                              color: theme.palette.baseColors.grey,
                            }}>
                            {formData?.taxcode}
                          </Typography>
                        )}
                      </Box>
                      <FieldCell
                        label='ERP Reporting Country Code'
                        name='erpreportingcountrycode'
                        value={formData.erpreportingcountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Country Code'
                        name='suppliercountrycode'
                        value={formData.suppliercountrycode}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Tax Point Date'
                        name='taxpointdate'
                        value={formData.taxpointdate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        isDatePicker
                      />
                      <FieldCell
                        label='Compliance Reporting Date'
                        name='compliancereportingdate'
                        value={formData.compliancereportingdate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        isDatePicker
                      />
                      <FieldCell
                        label='Observed VAT Rate'
                        name='observedvatrate'
                        value={formData?.observedvatrate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Jurisdiction AL VAT Rate'
                        name='jurisdictionalvatrate'
                        value={formData?.jurisdictionalvatrate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                      <FieldCell
                        label='Posting Date'
                        name='postingdate'
                        value={formData?.postingdate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        isDatePicker
                      />
                      <FieldCell
                        label='Invoice Date'
                        name='invoicedate'
                        value={formData?.invoicedate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                        isDatePicker
                      />
                      <FieldCell
                        label='Determined VAT Rate'
                        name='determinedvatrate'
                        value={formData?.determinedvatrate}
                        onChange={handleChange}
                        isEditable={isEditable}
                        relatedFields={relatedFields}
                        originalFormData={originalFormData}
                      />
                    </Box>
                  </Box>

                  {/* Section 5: Amount */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        color: '#000000',
                      }}>
                      Amount
                    </Typography>
                    <Divider sx={{ mt: 2, mb: 3 }} />
                    <TransactionsTable
                      formData={formData}
                      setFormData={setFormData}
                      isEditable={isEditable}
                      handleChange={handleChange}
                      handleCurrencyChange={handleCurrencyChange}
                      relatedFields={relatedFields}
                      originalFormData={originalFormData}
                    />
                  </Box>

                  {/* Notes Section */}
                  <Box
                    gridColumn='span 3'
                    sx={{ mt: 2 }}>
                    <ListNotes style={{ border: 'none', padding: '0' }} />
                  </Box>

                  {/* Footer Apply & Save buttons */}
                  {isEditable && (
                    <Box
                      gridColumn='span 3'
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                        marginTop: 2,
                      }}>
                      <Button
                        variant='outlined'
                        onClick={handleSave}
                        disabled={!isFormValid || !hasChanges}
                        sx={{
                          borderColor: theme.palette.neutralColors.black[200],
                          color: 'grey',
                          '&:hover': {
                            backgroundColor: 'white',
                            borderColor: 'transparent',
                          },
                          borderRadius: 1,
                          textTransform: 'none',
                        }}>
                        Save
                      </Button>
                      <Button
                        variant='outlined'
                        onClick={resolveExceptionsParam ? handleSpecialSubmit : handleSubmit}
                        disabled={!changesSaved && (!isFormValid || !hasChanges)}
                        sx={{
                          textTransform: 'none',
                          borderColor: theme.palette.primaryColors[200],
                          color: theme.palette.primaryColors[200],
                          '&:hover': {
                            backgroundColor: 'white',
                            borderColor: theme.palette.primaryColors[200],
                          },
                        }}>
                        Apply
                      </Button>
                    </Box>
                  )}
                </Box>
              </Card>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='end'
              gridArea='1/4/4/6'>
              <ParentAccordion
                theme={theme}
                title={`Unresolved Exceptions (${unresolvedExceptions?.length})`}
                button={
                  <BtnFilled
                    btnTxt={'Review'}
                    size='small'
                    onClick={() => {
                      navigate(
                        `/obligations/${obligationId}/${member}/individual-transaction/transaction-lists?id=${transactionId}&code=${entityCode}&backAddress=${getCurrentRelativePath(
                          'resolveException'
                        )}`
                      );
                    }}
                  />
                }>
                <UnresolvedExceptions
                  selected={selected}
                  setSelected={setSelected}
                  relatedFields={relatedFields}
                  setRelatedFields={setRelatedFields}
                  exceptions={unresolvedExceptions}
                  resolveExceptionsArray={resolveExceptionsArray}
                  handleResolveException={handleResolveException}
                  getRelatedFields={getRelatedFields}
                />
              </ParentAccordion>
              <ParentAccordion
                theme={theme}
                title={`Tax Treatments (${taxTreatments?.length})`}>
                <TaxTreatments taxTreatments={taxTreatments} />
              </ParentAccordion>
              <ParentAccordion
                theme={theme}
                title='Transaction History'>
                <TransactionHistory transactions={transactionHistory} />
              </ParentAccordion>
            </Box>
          </Box>
        ) : (
          <SourceData data={sourceData?.source?.[0]} />
        )}
        {toast?.message && (
          <Toast
            closeAlertHandler={() => {
              setToast({
                type: '',
                message: '',
              });
            }}
            toast={toast}
          />
        )}
      </PageSkeleton>
    ) : (
      <AuthError />
    ))
  );
};

export default TransactionDetail;
