import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { Button, Typography, useTheme } from "@mui/material";
import { ArrowRightIcon } from '@radix-ui/react-icons'
import { useState, useEffect } from "react";
import dayjs from 'dayjs'

import Checkbox from "@mui/material/Checkbox";

import Skeleton from '@mui/material/Skeleton';
import { useUser } from "../../context/user";
import { useNavigate } from 'react-router-dom'

import { formatDate } from "../../utils";
import TextTag from "../common/Text Tags/TextTag";
import { getDaysDifference } from "../../utils";


export default function StickyHeadTableNonPaginated(props) {
  const Navigate = useNavigate()


  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [FetchingTableData, setFetchingData] = useState(true);
  const [SelectedByCheck, setSelectedByCheck] = useState([]);
  const [Checks, setChecks] = useState(false);
  const [rowsCount, setRowsCount] = useState()

  const { userToken } = useUser();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);


  const reversedDateString = (dateString) => {
    let a = dateString.split("-").reverse().join("-")

    return a;
  }

  const DueCalc = (dateString) => {
    let currentDate = new Date();
    let dateParts = reversedDateString(dateString).split("-");
    let day = parseInt(dateParts[0], 10);
    let month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript  
    let year = parseInt(dateParts[2], 10);
    let reversedDate = new Date(year, month, day);
    let differenceInMilliseconds = reversedDate - currentDate;
    let differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }





  useEffect(() => {

    setColumns(props.columns);
    setChecks(props.checks);





  }, [props]);


  useEffect(() => {
    if (rows?.length === 0) {
      setIsError(false);
      fetch(process.env.REACT_APP_BACKEND_URL + `/obligationsummary/listall?sort_column=due_date&sort_dir=asc&limit=5`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${userToken}`
          },
          credentials: 'include',
        }
      )
        .then(res => {

          return res.json()
        })
        .then(data => {

          let arr = []
          setRows(data.summary);
          setFetchingData(false)

        }
        )
        .catch(err => {
          console.error(err)
          setFetchingData(false)
          setFetchingData(false)
          setIsError(true);
        });
    }
  }, []);



  const theme = useTheme()
  return (
    <>
      {columns?.length > 0 && (
        <Paper
          className='Homepage'
          sx={{
            width: "100%",

            margin: "auto",
            minHeight: "24.5rem",
            maxHeight: "24.5rem",
            padding: "1rem",
            paddingTop: "1rem",
            paddingBottom: "0rem",
            mt: "1rem",
            boxShadow: "none",
            border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            borderRadius: "0.5rem"

          }}
        >
          <Box sx={{ display: "flex" }}>
            <h3 style={{ marginTop: "0", fontSize: "112.5%" }}>Your Tasks</h3>
            {!FetchingTableData && rows?.length > 0 &&
              <Button onClick={() => { Navigate('/obligations') }} disableRipple disableFocusRipple sx={{
                ml: "auto", padding: "0.5vh 1rem", height: "fit-Content", border: `1px solid ${theme.palette.neutralColors.black[200]}`, color: theme.palette.baseColors.grey, fontSize: "0.9rem", fontWeight: "bold", textTransform: "none", "&:hover": {
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  backgroundColor: "transparent"
                },
              }} variant="outlined">View all</Button>
            }
          </Box>
          {FetchingTableData && <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", marginTop: "2vh" }}>
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
          </div>}

          {!FetchingTableData && rows?.length === 0 && <Typography sx={{ textAlign: "center", width: 1, fontSize: ".875rem" }}>
            {isError ? "Error fetching data" : "No data to display"}
          </Typography>
          }
          {!FetchingTableData && rows?.length > 0 && <TableContainer sx={{ maxHeight: "19rem", mt: "1rem", border: `1px solid ${theme.palette.neutralColors.black[200]}`, borderRadius: "8px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.secondaryColors[100] }}>
                  {Checks && (
                    <TableCell sx={{ backgroundColor: theme.palette.secondaryColors[100], paddingTop: "0.4rem", paddingBottom: "0.4rem" }}>
                      {" "}
                      <Checkbox
                        checked={SelectedByCheck?.length === rows?.length}
                        indeterminate={
                          SelectedByCheck?.length > 0 &&
                          SelectedByCheck?.length < rowsCount
                        }
                        onChange={(e) => {
                          if (e.target.checked === true) {
                            setSelectedByCheck(rows);
                          } else {
                            setSelectedByCheck([]);
                          }
                        }}
                      />{" "}
                    </TableCell>
                  )}

                  {columns?.map((column) => (
                    <TableCell
                      sx={{
                        fontSize: "87.5%",
                        fontWeight: "700",
                        color: theme.palette.baseColors.grey,
                        backgroundColor: theme.palette.secondaryColors[100]
                        , paddingTop: "0.8rem", paddingBottom: "0.8rem"
                      }}
                      key={column.id}
                      align={column.align}
                      style={{ width: column.minWidth }}
                    >
                      {column.label}{" "}

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {!FetchingTableData && (
                <TableBody>
                  {rows?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >

                        {columns?.map((column) => {
                          const value = row[column.id];
                          const daysUntilDue = getDaysDifference(new Date(), new Date(row["due_date"]))
                          return (
                            <TableCell sx={{ paddingTop: "0.4rem", paddingBottom: "0.4rem" }}>
                              {column.ValType === "link" && (

                                <span
                                  onClick={() => {
                                    Navigate(`/obligations/${row.obligation_id}/${row.member}/overview`)
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: theme.palette.primaryColors[200],
                                    textDecoration: "underline",
                                    fontWeight: "500",
                                    fontSize: "87.5%"
                                  }}
                                >
                                  {/* <a style={{textDecoration:"none"}} href={value.Linkto} target="blank"> */}
                                  {value}
                                  {/* </a>  */}
                                </span>

                              )}
                              {(column.ValType === "Val") && (
                                <span style={{ cursor: "default", fontSize: "100%", color: theme.palette.baseColors.grey }}>
                                  {value}
                                </span>
                              )}



                              {(column.ValType === "Date") && (
                                <span style={{ fontWeight: "500", cursor: "default", fontSize: "100%", color: theme.palette.baseColors.grey }}>

                                  {dayjs(value)?.format('DD MMM YYYY')}

                                </span>
                              )}
                              {column.ValType === "dateRange" && (
                                <span style={{ fontWeight: "500", cursor: "default", fontSize: "100%", color: theme.palette.baseColors.grey }}>
                                  {value.startDate}{" "}
                                  <ArrowRightIcon
                                    style={{ margin: "0 1vh 0 1vh" }}
                                  />{" "}
                                  {value.endDate}
                                </span>
                              )}

                              {column.ValType === "lablesArr" &&
                                value?.length > 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {value?.map((val) => {
                                      return (
                                        <span
                                          style={{
                                            fontSize: "0.625rem",
                                            backgroundColor: "#FFECD9",
                                            color: "#AD5700",

                                            padding: " 0.5vh 1vh 0.5vh 1vh",
                                            margin: "1vh 0 1vh 0",
                                            width: "fit-content",
                                            fontWeight: "bold",
                                            borderRadius: "3px",
                                          }}
                                        >

                                          {val.Desc}
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              {/* Add Additional Logic below Here Make your own elements like made above.inside Table cell only */}
                              {column.ValType === 'Tag' && <TextTag size='s'
                                text={value === "OVERDUE" ? `OVERDUE • ${-daysUntilDue}D` : value}
                                variant={value.toUpperCase().includes('OVERDUE') ? 'red' : value.toUpperCase().includes('DUE') ? 'yellow' : 'green'} />}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          }

        </Paper>
      )}
    </>
  );
}
