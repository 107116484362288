import { Box, Stack, Typography, useTheme } from '@mui/material';
import { BtnStandard } from '../Buttons/BtnStandard';
import { BtnFilled } from '../Buttons/BtnFilled';
import { Modal } from '@mui/material';
import { CloseIcon } from '../CustomIcons/CloseIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 'none',
  overflow: "hidden"
};

export const GenericModal = ({
  title,

  btnText,
  secondaryBtnText,
  cancelBtnText,

  closeHandler,
  submitHandler,
  secondaryHandler,
  cancelHandler,

  open,
  loading,

  disableSubmitBtn = false,
  disableSecondaryBtn = false,
  disableActionBar = false,
  disableChildrenPadding = false,

  width="60vw",
  height,
  children,
}) => {
  const theme = useTheme();
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      sx={{ border: 'none' }}>
      <Stack
        padding='2rem'
        // gap='3rem'
        sx={{
          borderRadius: '0.25rem',
          backgroundColor: theme.palette.baseColors.white,
          border: `1px solid ${theme.palette.random[700]}`,
          borderTop: 'none',
          width: width,
          height: height ?? "initial",
          ...style,
        }}>
        <Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'>
          <Typography
            variant='h5'
            fontWeight='medium'
            color={theme.palette.baseColors.grey}>
            {title}
          </Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={closeHandler}
          />
        </Stack>
        <Box my={disableChildrenPadding ? "" : "1rem"} height={1}>
          {children}
        </Box>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          gap='0.5rem'>
          {secondaryHandler && (
            <BtnStandard
              btnTxt={secondaryBtnText ?? 'Edit'}
              onClick={secondaryHandler}
              disabled={disableSecondaryBtn}
              styling={{ 
                border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                "&:hover": { border: `1px solid ${theme.palette.neutralColors.black[200]}` }
              }}
              disabledStyling={{ border: `1px solid ${theme.palette.neutralColors.black[200]}` }}
            />
          )}
          {!disableActionBar &&
            <Stack direction="row" gap='0.5rem'>
              {cancelHandler && (
                <BtnStandard
                  btnTxt={cancelBtnText ?? 'Cancel'}
                  onClick={cancelHandler}
                />
              )}
              {submitHandler &&(
                <BtnFilled
                  size='large'
                  styling={
                    loading
                      ? {
                          '&:disabled': {
                            backgroundColor: theme.palette.primaryColors[200],
                            color: theme.palette.secondaryColors[100],
                          },
                        }
                      : {}
                  }
                  btnTxt={btnText ?? 'Save'}
                  onClick={submitHandler}
                  loading={loading}
                  disabled={disableSubmitBtn || loading}
                />
              )}
            </Stack>
          }
        </Stack>
      </Stack>
    </Modal>
  );
};
