import { useState } from 'react';
import * as mui from '@mui/material';
import { WhiteCard } from '../../../WhiteCard';
import { WarningPopup } from '../../../../../components/common/Modal/WarningPopup';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_FILE_TYPES,
  FORMAT_TO_EXTENSIONS_MAPPING,
} from '../../../../../components/common/FileUploader/constants';
import { typographyStyle } from '../../../Reconciliations';
import { CloseIcon } from '../../../../../components/common/CustomIcons/CloseIcon';
import { TextArea } from '../../../../../components/common/Input/TextArea';
import { FileUploader } from '../../../../../components/common/FileUploader/FileUploader';
import { ListUploadedFiles } from '../../../Notes/ListUploadedFiles';
import { BtnStandard } from '../../../../../components/common/Buttons/BtnStandard';
import { BtnFilled } from '../../../../../components/common/Buttons/BtnFilled';
import { useObligation, useUser } from '../../../../../context';
import { useNavigate } from 'react-router-dom';

export const AcceptModal = ({ setOpen, selectedRows, handleResolveStatus }) => {
  const theme = mui.useTheme();
  const navigate = useNavigate();
  const { userToken, userId } = useUser();
  const { selectedObgDetails } = useObligation();

  const HeaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1244px',
    padding: '32px',
    pb: 0,
  };
  const style = {
    width: '100%',
    height: '30%',
    border: 'none',
    padding: '2px',
    pr: '1rem',
    pb: '5rem',
    overflow: 'auto',
  };

  const [attachments, setAttachments] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(-1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState(null);
  const [cancel, setCancel] = useState(false);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => setCancel(true);

  const fileUploadHandler = (e) => {
    const uploadedPreviously = [...attachments];
    const uploadedNow = e.target.files;
    const remainingCapacity = 50 - uploadedPreviously.length;
    if (uploadedNow.length > remainingCapacity) {
      setUploadError(`File upload failed. You can only upload a maximum of ${50} files.`);
      return;
    }
    const isInvalidFileType = [...e.target.files].some((f) => {
      if (!ALLOWED_FILE_TYPES.includes(f.type) || f.name?.split('.')?.length > 2) {
        return true;
      }
      return false;
    });
    if (isInvalidFileType) {
      setUploadError(
        `File upload failed. You can only upload ${ALLOWED_EXTENSIONS.join(
          ', '
        )} file formats. Multi-extension files are not allowed.`
      );
      return;
    }
    const isInvalidFileSize = [...e.target.files].some((f) => {
      const fileSizeInKB = f.size / 1024;
      if (fileSizeInKB > 5120) {
        return true;
      }
      return false;
    });
    if (isInvalidFileSize) {
      setUploadError(`File upload failed. You can upload files with size upto 10 MB only`);
      return;
    }
    setUploadError('');
    const totalUniqueFiles = [];
    for (const file of uploadedNow) {
      const res = uploadedPreviously.filter((f) => f.name === file.name);
      if (res?.length === 0) {
        totalUniqueFiles.push(file);
      }
    }
    setAttachments((prev) => [...prev, ...totalUniqueFiles]);
  };

  const sortFileDataHandler = (colName) => {
    if (colName === 'Attachment') {
      setSortColumn('Attachment name');
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...attachments].sort(
        (a, b) => attachmentNameSortOrder * a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setAttachments(sortedData);
    } else if (colName === 'Attachment type') {
      setSortColumn('Attachment type');
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...attachments].sort((a, b) => {
        const extensionA = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === a.type
        )?.extension;
        const extensionB = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === b.type
        )?.extension;
        return attachmentTypeSortOrder * extensionA.localeCompare(extensionB);
      });
      setAttachments(sortedData);
    }
  };

  const fileDeleteHandler = (name) => {
    const remainingFiles = [...attachments].filter((f) => f.name !== name);
    setAttachments(remainingFiles);
  };

  const resolveHandler = () => {
    if (userToken) {
      const hasInvalidFile = attachments.some(
        (f) => f.name?.split('.').length > 2 || !ALLOWED_FILE_TYPES.includes(f.type)
      );
      if (hasInvalidFile) {
        return;
      }
      setLoading(true);
      let url = '';
      url =
        process.env.REACT_APP_BACKEND_URL +
        `/exception/acceptexception?obligation_id=${selectedObgDetails.obligation_id}&added_by=${userId}&note_description=${reason}`;
      const formData = new FormData();
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          formData.append('files', attachments[i]);
        }
      }
      if (selectedRows.length > 0) {
        for (let i = 0; i < selectedRows.length; i++) {
          formData.append('id', selectedRows[i]);
        }
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          if (data.status === 'Error') {
            if (data.detail.includes('Invalid document type')) {
              handleResolveStatus('failure', data.detail);
            } else throw new Error(`Something went wrong`);
          } else {
            handleResolveStatus('success', 'Exception accepted successfully');
          }
        })
        .catch((err) => {
          handleResolveStatus('failure', 'Exception acceptance failed');
        })
        .finally(() => {
          setOpen(false);
          setLoading(false);
        });
    }
  };

  return (
    <mui.Modal open={true}>
      <WhiteCard style={HeaderStyle}>
        <mui.Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          sx={{
            borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
          }}>
          <mui.Typography
            id='modal-modal-title'
            sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey)}>
            {`Accepting ${selectedRows?.length} exception(s)`}
          </mui.Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </mui.Stack>
        <WhiteCard style={style}>
          <mui.Stack gap={'2rem'}>
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                Reason for acceptance
              </mui.Typography>
              <TextArea
                onChange={(e) => setReason(e.target.value)}
                placeholder={'Explain why you are comfortable accepting this exception...'}
              />
            </mui.Stack>
            <FileUploader
              fileSize='5MB'
              onChange={fileUploadHandler}
              errorMsg={uploadError}
            />
            <mui.Stack>
              {attachments.length > 0 && (
                <>
                  <mui.Typography sx={typographyStyle('1rem', 700, theme.palette.baseColors.grey)}>
                    Attachments
                  </mui.Typography>
                  <ListUploadedFiles
                    uploadedFiles={attachments}
                    fileDeleteHandler={fileDeleteHandler}
                    sortFileDataHandler={sortFileDataHandler}
                    sortColumn={sortColumn}
                    attachmentNameSortOrder={attachmentNameSortOrder}
                    attachmentTypeSortOrder={attachmentTypeSortOrder}
                    extensionProcessingRequired={true}
                  />
                </>
              )}
            </mui.Stack>
          </mui.Stack>
        </WhiteCard>
        <mui.Stack
          flexDirection='row'
          justifyContent='flex-end'
          gap='0.5rem'
          pb='2rem'>
          <BtnStandard
            btnTxt={'Cancel'}
            onClick={handleClose}
            disabled={loading}
            styling={{
              height: '2.75rem',
              padding: '0.5rem 1.5rem',
              fontSize: '1rem',
            }}
          />
          <BtnFilled
            size='medium'
            styling={{
              '&:disabled': {
                backgroundColor: theme.palette.primaryColors[200],
                color: theme.palette.secondaryColors[100],
              },
            }}
            btnTxt={'Resolve'}
            loading={loading}
            disabled={loading}
            onClick={resolveHandler}
          />
        </mui.Stack>
        {cancel && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setCancel(false);
            }}
            continueHandler={() => {
              setCancel(false);
              setOpen(false);
            }}
            warningTitle={'Discard?'}
          />
        )}
      </WhiteCard>
    </mui.Modal>
  );
};
