import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import '../../components/common/Filters/Datestyle.css';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useTheme } from '@mui/material';

export const DashboardSelect = ({
  onChange,
  options,
  value,
  label,
  loading,
  disabled,
  children
}) => {
  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        color: theme.palette.baseColors.grey,
      },
    },
  };

  const renderValue = (value) => {
    if (value) {
      let selectedOption = options?.find((option) => option === value);
      return <span>{selectedOption}</span>;
    } else return <span>{label}</span>;
  };

  return (
    <FormControl sx={{ maxHeight: '3.25rem' }}>
      <Select
        displayEmpty
        disabled={disabled || loading}
        value={value}
        renderValue={renderValue}
        IconComponent={ChevronDownIcon}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        MenuProps={MenuProps}
        sx={{
          height: 1,
          width: '12rem',
          border: `1px solid ${theme.palette.random[500]} `,
          color:
            value.length === 0
              ? theme.palette.secondaryColors[300]
              : theme.palette.baseColors.black,
          backgroundColor: theme.palette.baseColors.white,
          '& legend': { display: 'none', width: 'unset' },
          '& fieldset': { top: 0, border: 'none' },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.neutralColors.black[100],
            borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
            borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
          },
        }}>
        <MenuItem
          value=''
          disabled>
          <em>{label}</em>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem
            sx={{
              whiteSpace: 'normal',
              textOverflow: 'ellipsis',
              overflow: 'hidden',

              '&:hover': {
                backgroundColor: theme.palette.neutralColors.black[300],
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.neutralColors.black[300],
                borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
              },
              '&.Mui-selected:hover': {
                backgroundColor: theme.palette.neutralColors.black[300],
                borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
              },
              '&.Mui-selected.Mui-focusVisible': {
                backgroundColor: theme.palette.neutralColors.black[300],
                borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
              },
            }}
            key={option}
            value={option}>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
