import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ChevronRightIcon } from '@radix-ui/react-icons'
import { useTheme, Skeleton } from "@mui/material";
import { useObligation } from "../../context";

const BreadcrumbsComp = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { selectedObgDetails } = useObligation();
  const obligationName = selectedObgDetails?.obligation_name;

  let pathname = location.pathname;
  const basePathnames = pathname?.split("/").filter(x => x);
  const isTopLevelPage = basePathnames.length <= 1;
  let displayPathnames = [...basePathnames];
  let routingPathnames = [...basePathnames];

  if (!isTopLevelPage && basePathnames[0] === "obligations" && obligationName) {
    displayPathnames[1] = obligationName // Set the second pathname item to the obligation name, if any
    displayPathnames.splice(2, 1) // Remove the third pathname item (the obligation member)
    if (["exception-dashboard", "transaction-dashboard"].includes(basePathnames[2]))
      displayPathnames.splice(2, 1) // Remove the fourth pathname item (Top 10, if it exists). NOTE: The fourth original pathname item is now the third item.

  }

  // The isLoading variable only matters for the Individual Obligations screens.
  const isLoading = basePathnames[0] === "obligations" && !obligationName

  const capitalizeString = (str) => {
    let words = str.split("-");
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  };

  // Helper function to determine if query parameters should be included
  const shouldIncludeParams = (path) => {
    return path.includes("individual-transaction");
  };

  return (
    !isTopLevelPage && (
      <Breadcrumbs
        sx={{ backgroundColor: theme.palette.baseColors.white, padding: "1rem 2.5rem ", borderBottom: `0.5px solid ${theme.palette.neutralColors.black[200]}` }}
        separator={<ChevronRightIcon />}
        aria-label="breadcrumb"
      >
        {isLoading ? (
          <Skeleton variant="text" sx={{ width: "50rem" }} />
        ) : (
          displayPathnames?.map((name, displayIdx) => {
            const routingIdx = routingPathnames.findIndex(path => path === name);
            let routeTo = `/${routingPathnames?.slice(0, routingIdx + 1).join("/")}`;
            const isLast = routingIdx === routingPathnames?.length - 1;

            // Append query parameters if necessary
            if (shouldIncludeParams(routeTo)) {
              const queryString = searchParams.toString();
              routeTo += `?${queryString}`;
            }

            return isLast || (routingPathnames[0] === 'obligations' && displayIdx === 1) ? (
              <Typography sx={{ cursor: "default", color: "black", fontSize: "1rem" }} key={name}>
                {capitalizeString(name)}
              </Typography>
            ) : (
              <Link
                sx={{
                  cursor: "pointer",
                  color: theme.palette.neutralColors.black[500],
                  textDecoration: "none",
                  fontSize: "1rem",
                }}
                key={name}
                onClick={() => navigate(routeTo)}
              >
                {capitalizeString(name)}
              </Link>
            );
          })
        )}
      </Breadcrumbs>
    )
  );
};

export default BreadcrumbsComp;
