

export const ALLOWED_FILE_TYPES = ['image/bmp', 'image/gif', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/zip'];

export const ALLOWED_EXTENSIONS = ['.bmp', '.jpg', '.jpeg','.gif', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv', '.zip'];

export const FORMAT_TO_EXTENSIONS_MAPPING = [
    {
        format: 'image/bmp',
        extension: '.bmp'
    },
    {
        format: 'image/gif',
        extension: '.gif'
    },
    {
        format: 'image/jpeg',
        extension: '.jpg'
    },
    {
        format: 'image/jpeg',
        extension: '.jpeg'
    },
    {
        format: 'application/pdf',
        extension: '.pdf'
    },
    {
        format: 'application/msword',
        extension: '.doc'
    },
    {
        format: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        extension: '.docx'
    },
    {
        format: 'application/vnd.ms-excel',
        extension: '.xls'
    },
    {
        format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        extension: '.xlsx'
    },
    {
        format: 'text/csv',
        extension: '.csv'
    },
    {
        format: 'application/zip',
        extension: '.zip'
    }
];

//  image/bmp for .bmp
//  image/gif for .gif
//  image/jpeg for .jpg/.jpeg
//  application/pdf for .pdf
//  application/msword for .doc
//  application/vnd.openxmlformats-officedocument.wordprocessingml.document for .docx
//  application/vnd.ms-excel for .xls
//  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet for .xlsx
//  text/csv for .csv
//  application/zip for .zip