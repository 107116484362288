import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { useEffect, useState, useRef } from "react";
import { SortIcon } from "../../../components/common/CustomIcons/SortIcon";
import { SortDescIcon } from "../../../components/common/CustomIcons/SortDescIcon";
import { SortAscIcon } from "../../../components/common/CustomIcons/SortAscIcon";
import { DownloadIcon } from "../../../components/common/CustomIcons/DownloadIcon";
import { BtnFilled } from "../../../components/common/Buttons/BtnFilled";
import { useUser } from "../../../context";
import { useNavigate } from "react-router-dom";
import { saveAs } from 'file-saver';

const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: "0.875rem",
  padding: "0.75rem 1rem",
  cursor: "pointer",
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: "0.875rem",
  padding: "0.75rem 1rem",
  fontWeight: 500,
  // todo: check below styling again
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: theme.palette.baseColors.grey,
  width: 100,
  maxWidth: 100,
}));



export const DownloadAttachments = ({
  closePopupHandler,
  note,
  downloadFailureHandler,
}) => {
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(1);
  const { userToken } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (note?.attachments) {
      const attachments = note.attachments?.map((attachment) => {
        const arr = attachment?.split("/");
        const fileName = arr[arr.length - 1];
        const extension = fileName.split(".").pop();
        const filePath = attachment;
        const obj = { name: fileName, extension, path: filePath };
        return obj;
      });
      setFiles(attachments);
    }
  }, [note]);

  const sortFileDataHandler = (colName) => {
    if (colName === "Attachment") {
      setSortColumn("Attachment name");
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...files].sort(
        (a, b) =>
          attachmentNameSortOrder *
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setFiles(sortedData);
    } else if (colName === "Attachment type") {
      setSortColumn("Attachment type");
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...files].sort(
        (a, b) =>
          attachmentTypeSortOrder *
          a.extension.toLowerCase().localeCompare(b.extension.toLowerCase())
      );
      setFiles(sortedData);
    }
  };

  const fileDownloadHandler = (no_of_files, path) => {
    let url;
    const noteId = note.note_id;
    if (no_of_files === "single") {
      url = `/note/getattachment?note_id=${noteId}&files=${path}`;
    } else {
      let allFiles = [];
      files.forEach((f) => {
        allFiles.push(f.path);
      });
      allFiles = allFiles.join(",");
      url = `/note/getattachment?note_id=${noteId}&files=${allFiles}`;
    }
    fetch(process.env.REACT_APP_BACKEND_URL + url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      credentials: "include",
    })
      .then((res) => {
        const blob = res.blob();
        if (!res.ok || !blob) {
          throw new Error(`Something went wrong, status code: ${res.status}`);
        }
        const contentDisposition = res.headers.get("Content-Disposition");
        const requiredIndex = contentDisposition?.indexOf("filename=") + 9;
        const fileName = contentDisposition?.slice(requiredIndex);

        return blob.then((blob) => ({ blob, fileName }));

      })
      .then(({ blob, fileName }) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.error("Error downloading file:", error.message);
        downloadFailureHandler();
      });
  };

  return (
    <Modal
      title="View Attachments"
      submitHandler={closePopupHandler}
      btnText="Close"
    >
      <Stack
        width="100%"
        mt="2rem"
        gap="2.5rem"
        sx={{ height: "65vh", overflowY: "scroll" }}
        pb="0.5rem"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={700} color={theme.palette.baseColors.grey}>
            Attachments
          </Typography>
          {files?.length > 0 && (
            <BtnFilled
              size="small"
              iconPosition="start"
              btnTxt="Download all attachments"
              icon={<DownloadIcon />}
              onClick={() => {
                fileDownloadHandler("multiple", files);
              }}
            />
          )}
        </Stack>
        <Stack gap="1rem">
          {files?.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", overflow: "initial" }}
            >
              <Table
                aria-label="selected files"
                sx={{
                  borderRadius: "0.5rem",
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  overflow: "scroll",
                }}
              >
                <TableHead
                  sx={{
                    backgroundColor: theme.palette.neutralColors.black[100],
                    height: "2.5rem",
                  }}
                >
                  <TableRow variant="body2">
                    <StyledTableHeadingCell
                      style={{ width: "65%", maxWidth: "65%" }}
                      onClick={() => {
                        sortFileDataHandler("Attachment");
                      }}
                    >
                      Attachment
                      <IconButton disableRipple>
                        {sortColumn === "Attachment name" ? (
                          attachmentNameSortOrder === 1 ? (
                            <SortAscIcon />
                          ) : (
                            <SortDescIcon />
                          )
                        ) : (
                          <SortIcon />
                        )}
                      </IconButton>
                    </StyledTableHeadingCell>
                    <StyledTableHeadingCell
                      style={{ width: "30%", maxWidth: "30%" }}
                      onClick={() => {
                        sortFileDataHandler("Attachment type");
                      }}
                    >
                      Attachment type
                      <IconButton disableRipple>
                        {sortColumn === "Attachment type" ? (
                          attachmentTypeSortOrder === 1 ? (
                            <SortAscIcon />
                          ) : (
                            <SortDescIcon />
                          )
                        ) : (
                          <SortIcon />
                        )}
                      </IconButton>
                    </StyledTableHeadingCell>
                    <StyledTableHeadingCell
                      style={{ width: "5%", maxWidth: "5%" }}
                    ></StyledTableHeadingCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files?.map((file) => (
                    <TableRow
                      key={file.name}
                      sx={{
                        height: "3rem",
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}
                    >
                      <StyledTableBodyCell
                        sx={{
                          color: theme.palette.primaryColors[200],
                          textDecoration: "underline",
                        }}
                      >
                        {file.name}
                      </StyledTableBodyCell>

                      <StyledTableBodyCell>
                        {file.extension.toUpperCase()}
                      </StyledTableBodyCell>

                      <StyledTableBodyCell>
                        <DownloadIcon
                          sx={{
                            cursor: "pointer",
                            color: theme.palette.primaryColors[200],
                          }}
                          onClick={() => {
                            fileDownloadHandler("single", file.path);
                          }}
                        />

                      </StyledTableBodyCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            "No attachments available"
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
