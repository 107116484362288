import { BtnStandard } from '../../../components/common/Buttons/BtnStandard';
import { ArrowLeftIcon } from '../../../components/common/CustomIcons/ArrowLeftIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { WhiteCard } from '../WhiteCard';
import { PaginatedTable } from '../../../components/Paginated Table/PaginatedTable';
import { TransactionFilter } from '../../../components/common/Filters/TransactionFilter';
import { useEffect, useState } from 'react';
import { useObligation, useUser } from '../../../context';
import {
  formatDate,
  reverseDate,
  conditionalEntityCodeDefaultQuery,
  getCurrentRelativePath,
  currencyFormatterWithoutSymbol,
  nullableInvoiceNumber,
} from '../../../utils';
import { Box, Stack, TableCell, Typography, styled, useTheme } from '@mui/material';
import { BackButton } from '../../../components/common/Buttons/Utility/BackButton';
import { Toast } from '../Toast';
import { AuthError, CheckAuthorization } from '../Authorization/Authorization';
import { UseAuth } from '../../../context/AuthContext';

export const ListPurchaseTransaction = () => {
  const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    fontSize: '0.875rem',
    padding: '0.25rem 0.5rem',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.baseColors.grey,
    height: '3rem',
  }));

  const theme = useTheme();
  const navigate = useNavigate();
  const { userToken } = useUser();
  const { id, member } = useParams();
  const { selectedObgDetails, selectedObgHandler } = useObligation();
  const [customFilters, setCustomFilters] = useState([]);
  const [toast, setToast] = useState({ type: '', message: '' });
  const { authorized, setAuthorized, authLoader, setAuthLoader } = UseAuth();

  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (!userToken) return;

    const url =
      process.env.REACT_APP_BACKEND_URL +
      `/obligationsummary/listall?limit=1&filter_query=obligation_id__eq=${id}%26member__eq=${member}`;
    let _obligation;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {

        if (res.ok) {
          return res.json();
        }
        throw new Error(
          `Something went wrong while fetching Obligation ID ${id}, Member ${member}. Status code: ${res.statusCode}`
        );
      })
      .then((data) => {
        if (data?.summary.length === 0)
          throw new Error(
            `Obligation with ID ${id} and Member ${member} could not be found in the Database!`
          );
        _obligation = data?.summary[0];
      })
      .catch((err) => {
        setToast({
          type: 'failure',
          message: 'Obligation data could not be fetched.',
        });
      })
      .finally(() => {
        selectedObgHandler(_obligation);
      });
  }, [userToken, id, member]);

  // #region Filter options.
  const [taxcodeOptions, setTaxcodeOptions] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);

  const [areOptionsLoading, setAreOptionsLoading] = useState(false);

  useEffect(() => {
    if (!userToken) return;

    setAreOptionsLoading(true);
    setTaxcodeOptions([]);
    setEntityOptions([]);

    const taxcodeOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL + `/transaction/distincttaxcodes?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setTaxcodeOptions(data);
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    const entityOptionsPromise = fetch(
      process.env.REACT_APP_BACKEND_URL +
      `/obligationentities/getdistinctmember?obligation_id=${id}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        if (!data) throw new Error(`API response empty.`);
        setEntityOptions(data.member_names.map((entity) => entity.name));
      })
      .catch((err) => {
        console.error(err);
        setToast({
          type: 'failure',
          message: 'Filter value suggestions could not be loaded.',
        });
      });

    Promise.all([taxcodeOptionsPromise, entityOptionsPromise]).finally(() => {
      setAreOptionsLoading(false);
    });
  }, [userToken, id]);
  // #endregion

  const COLUMNS = [
    { label: 'Inv No.', property: 'invoiceno' },
    { label: 'Entity', property: 'entityname' },
    { label: 'Statutory Currency', property: 'statcurrencycode' },
    { label: 'Gross', property: 'statgrossamount' },
    { label: 'Net', property: 'statnetamount' },
    { label: 'VAT', property: 'statvatamount' },
    { label: 'Inv Date', property: 'invoicedate' },
    { label: 'Posting Date', property: 'postingdate' },
    { label: 'Tax Code', property: 'taxcode' },
    { label: 'CounterParty', property: 'compliancecounterpartycompanyname' },

    // { label: 'UnResolved', property: 'unresolved_group_names', sortable: false },
    // { label: 'Resolved', property: 'resolved_group_names', sortable: false },
  ];

  useEffect(() => {
    if (!userToken) return;
    CheckAuthorization(userToken, setAuthorized, id, setAuthLoader);
  }, [userToken]);

  return (
    !authLoader &&
    (authorized === 'Authorized' ? (
      <>
        <Stack>
          <Box
            padding={'1.875rem 2.5rem'}
            sx={{
              backgroundColor: theme.palette.baseColors.white,
            }}>
            <BackButton
              backAddress={`/obligations/${id}/${member}/analytics/high-value-purchase`}
            />
            <Typography
              component='h1'
              sx={{
                color: theme.palette.random[300],
                fontSize: '2.25rem',
                fontWeight: '700',
              }}>
              Purchase Transactions
            </Typography>
          </Box>

          <WhiteCard style={{ m: '2.5rem', p: '24px 24px 24px 24px' }}>
            <PaginatedTable
              api={{
                endpoint: '/transaction/listall',
                records: 'transactions',
                count: 'count',
              }}
              defaultColumn={false}
              columns={COLUMNS}
              filterInfo={{
                component: (
                  <TransactionFilter
                    fields={[
                      {
                        column_name: 'Transaction ID',
                        mapping: 'id',
                        type: 'text_case_sensitive',
                      },
                      { column_name: 'Flow', mapping: 'flow', type: 'text' },
                      { column_name: 'Inv No', mapping: 'invoiceno', type: 'text' },
                      { column_name: 'Inv Date', mapping: 'invoicedate', type: 'date' },
                      { column_name: 'Net', mapping: 'statnetamount', type: 'numeric' },
                      {
                        column_name: 'VAT',
                        mapping: 'statvatamount',
                        type: 'numeric',
                      },
                      {
                        column_name: 'Entity',
                        mapping: 'entityname',
                        type: 'text',
                        options: entityOptions,
                      },
                      {
                        column_name: 'Counterparty Name',
                        mapping: 'compliancecounterpartycompanyname',
                        type: 'text',
                      },
                      {
                        column_name: 'Tax Code',
                        mapping: 'taxcode',
                        type: 'text',
                        options: taxcodeOptions,
                      },
                    ]}
                    autocompleteProps={{
                      isLoading: areOptionsLoading,
                    }}
                  />
                ),
                default: [
                  `obligation_id__eq=${id}`,
                  conditionalEntityCodeDefaultQuery(selectedObgDetails),
                  `source__eq=AP`,
                ],
              }}
              a11y={{
                tableAriaLabel: 'All transaction list',
                rowPrefix: 'transaction',
              }}
              customFilters={customFilters}
              hasCustomFilters
              rowItem={(transactionData) => {
                // TODO: Modify this once API response is modelled
                const goToTransaction = (transaction) =>
                  navigate(
                    `/obligations/${id}/${member}/individual-transaction?id=${transaction.id
                    }&code=${transaction.entitycode}&backAddress=${getCurrentRelativePath()}`
                  );
                return [
                  <StyledTableBodyCell>
                    <Typography
                      onClick={() => goToTransaction(transactionData)}
                      sx={{
                        display: 'inline-block',
                        fontSize: '0.875rem',
                        color: theme.palette.functional.tableLink,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}>
                      {nullableInvoiceNumber(
                        transactionData.invoiceno,
                        transactionData.erpdocumentno
                      )}
                    </Typography>
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>{transactionData.entityname}</StyledTableBodyCell>,
                  <StyledTableBodyCell>{transactionData.statcurrencycode}</StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {currencyFormatterWithoutSymbol(transactionData.statgrossamount, false, {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {currencyFormatterWithoutSymbol(transactionData.statnetamount, false, {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {currencyFormatterWithoutSymbol(transactionData.statvatamount, false, {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {formatDate(reverseDate(transactionData.invoicedate, '-'), '-')}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {formatDate(reverseDate(transactionData.postingdate, '-'), '-')}
                  </StyledTableBodyCell>,
                  <StyledTableBodyCell>{transactionData.taxcode}</StyledTableBodyCell>,
                  <StyledTableBodyCell>
                    {transactionData.compliancecounterpartycompanyname}
                  </StyledTableBodyCell>,
                ];
              }}
            />
          </WhiteCard>
        </Stack>

        {toast?.message && (
          <Toast
            closeAlertHandler={() => {
              setToast({
                type: '',
                message: '',
              });
            }}
            toast={toast}
          />
        )}
      </>
    ) : (
      <AuthError />
    ))
  );
};
