import { Modal } from '../../../components/common/Modal/Modal';
import { WarningPopup } from '../../../components/common/Modal/WarningPopup';
import { useState } from 'react';
import { useTheme, Typography, Stack } from '@mui/material';
import { SimpleSelect } from '../../../components/common/Dropdowns/SimpleSelect';
import { FileUploader } from '../../../components/common/FileUploader/FileUploader';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_EXTENSIONS,
  FORMAT_TO_EXTENSIONS_MAPPING,
} from '../../../components/common/FileUploader/constants';
import { useObligation, useUser } from '../../../context';
import { ListUploadedFiles } from '../Notes/ListUploadedFiles';
import { useNavigate } from 'react-router-dom';

export const LodgingModal = ({ closePopupHandler, handleResolveStatus }) => {
  const theme = useTheme();
  const { userId, userToken } = useUser();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [cancel, setCancel] = useState(false);

  const [status, setStatus] = useState('Lodged');

  const {
    selectedObgDetails: obligation,
    sisterObligations,
    triggerObligationReload,
  } = useObligation();
  const isStandAlone = sisterObligations?.length > 0 && sisterObligations.length === 1;

  const resolveHandler = () => {
    if (userToken) {
      const hasInvalidFile = attachments.some(
        (f) => f.name?.split('.').length > 2 || !ALLOWED_FILE_TYPES.includes(f.type)
      );
      if (hasInvalidFile) {
        return;
      }
      setIsProcessing(true);
      const endpointsByStatus = {
        Lodged: 'obligationtask/updatestatusonfinalize',
        'Not Lodged': 'obligationtask/updatestatusonnotlodged',
      };
      const authorityByStatus = {
        Lodged: 'finalize',
        'Not Lodged': 'reviewer',
      };
      const noteDescriptionByStatus = {
        Lodged: 'Obligation acknowledged with lodging.',
        'Not Lodged': 'Obligation acknowledged without lodging.',
      };
      const url = `${process.env.REACT_APP_BACKEND_URL}/${endpointsByStatus[status]}?obligation_id=${obligation.obligation_id}&authority=${authorityByStatus[status]}&added_by=${userId}&note_title=${status}&note_description=${noteDescriptionByStatus[status]}&relates_to=Export&tax_type=${obligation.tax_type}&entity=${obligation.org_heirarchy_code}&org_heirarchy_code=${obligation.org_heirarchy_code}&standalone=${isStandAlone}`;

      const formData = new FormData();
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          formData.append('files', attachments[i]);
        }
      }
      fetch(url, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        body: attachments.length > 0 ? formData : null,
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 400) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          if (data.status === 'Error') {
            if (data.detail.includes('Invalid document type')) {
              handleResolveStatus('failure', data.detail);
            } else throw new Error(`Something went wrong`);
          } else if (data.status === 'success') {
            handleResolveStatus('success', 'Your acknowledgement has been processed successfully!');
            if (status === 'Not Lodged') {
              navigate(`/obligations/${obligation.obligation_id}/${obligation.member}/checklist`);
            }
          } else throw new Error('Something went wrong.');
        })
        .catch((err) => {
          console.error(err);
          handleResolveStatus('failure', 'There was an error processing your acknowledgment.');
        })
        .finally(() => {
          setIsProcessing(false);
          triggerObligationReload();
          closePopupHandler();
        });
    }
  };

  // #region Attachments
  const [attachments, setAttachments] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(-1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState(null);

  const fileUploadHandler = (e) => {
    const uploadedPreviously = [...attachments];
    const uploadedNow = e.target.files;
    const maximumCapacity = 50;
    const remainingCapacity = maximumCapacity - uploadedPreviously.length;
    if (uploadedNow.length > remainingCapacity) {
      setUploadError(
        `File upload failed. You can only upload a maximum of ${maximumCapacity} files.`
      );
      return;
    }

    const isInvalidFileType = [...e.target.files].some((f) => {
      if (!ALLOWED_FILE_TYPES.includes(f.type) || f.name?.split('.')?.length > 2) {
        return true;
      }
      return false;
    });
    if (isInvalidFileType) {
      setUploadError(
        `File upload failed. You can only upload ${ALLOWED_EXTENSIONS.join(
          ', '
        )} file formats. Multi-extension files are not allowed.`
      );
      return;
    }
    const maximumFileSizeInMB = 5;
    const isInvalidFileSize = [...e.target.files].some((f) => {
      const fileSizeInMB = f.size / 1024 / 1024;
      return fileSizeInMB > maximumFileSizeInMB;
    });
    if (isInvalidFileSize) {
      setUploadError(
        `File upload failed. You can upload files with size up to ${maximumFileSizeInMB} MB only`
      );
      return;
    }
    setUploadError('');
    const totalUniqueFiles = [];
    for (const file of uploadedNow) {
      const res = uploadedPreviously.filter((f) => f.name === file.name);
      if (res?.length === 0) {
        totalUniqueFiles.push(file);
      }
    }
    setAttachments((prev) => [...prev, ...totalUniqueFiles]);
  };

  const sortFileDataHandler = (colName) => {
    if (colName === 'Attachment') {
      setSortColumn('Attachment name');
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...attachments].sort(
        (a, b) => attachmentNameSortOrder * a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setAttachments(sortedData);
    } else if (colName === 'Attachment type') {
      setSortColumn('Attachment type');
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...attachments].sort((a, b) => {
        const extensionA = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === a.type
        )?.extension;
        const extensionB = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === b.type
        )?.extension;
        return attachmentTypeSortOrder * extensionA.localeCompare(extensionB);
      });
      setAttachments(sortedData);
    }
  };

  const fileDeleteHandler = (name) => {
    const remainingFiles = [...attachments].filter((f) => f.name !== name);
    setAttachments(remainingFiles);
  };
  // #endregion

  return (
    <Modal
      title='Acknowledgement of Lodgement'
      submitHandler={resolveHandler}
      cancelHandler={() => {
        setCancel(true);
      }}
      loading={isProcessing}
      btnText='Confirm'>
      <Stack
        width='100%'
        mt='2rem'
        gap='1.5rem'
        sx={{ height: '65vh', overflowY: 'scroll' }}
        pb='0.5rem'>
        <Stack gap='.5rem'>
          <Typography sx={{ color: theme.palette.baseColors.grey, fontWeight: 500 }}>
            Lodgement Status
          </Typography>
          <SimpleSelect
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            renderValue={(p) => p}
            options={['Lodged', 'Not Lodged']}
          />
        </Stack>
        <FileUploader
          fileSize='5MB'
          onChange={fileUploadHandler}
          errorMsg={uploadError}
        />
        <Stack>
          {attachments && attachments.length > 0 && (
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 700,
                color: theme.palette.baseColors.grey,
              }}>
              Attachments
            </Typography>
          )}
          <ListUploadedFiles
            uploadedFiles={attachments}
            fileDeleteHandler={fileDeleteHandler}
            sortFileDataHandler={sortFileDataHandler}
            sortColumn={sortColumn}
            attachmentNameSortOrder={attachmentNameSortOrder}
            attachmentTypeSortOrder={attachmentTypeSortOrder}
            extensionProcessingRequired={true}
          />
        </Stack>
        <Typography sx={{ color: theme.palette.baseColors.grey, fontWeight: 500, mt: '-.5rem' }}>
          Disclaimer: By clicking confirm, you agree to [content goes here].
        </Typography>
      </Stack>

      {cancel && (
        <WarningPopup
          closeWarningPopupHandler={() => {
            setCancel(false);
          }}
          continueHandler={() => {
            setCancel(false);
            closePopupHandler();
          }}
        />
      )}
    </Modal>
  );
};
