import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import LeftIcon from "../CustomIcons/LeftIcon";
import RightIcon from "../CustomIcons/RightIcon";
import { useUser } from "../../../context";
import { BtnStandard } from "../Buttons/BtnStandard";

export default function Paginator({ pageNoChangeHandler, pageNo, totalRecords, disabled }) {
  const pageSizeOptions = [10, 25, 50, 100, 250, 500]
  const theme = useTheme();
  const { pageSize, pageSizeHandler } = useUser();
  const handleChangePagePrev = (currentPage) => {
    pageNoChangeHandler(currentPage - 1);
  };
  const handleChangePageNext = (currentPage) => {
    pageNoChangeHandler(currentPage + 1);
  };

  const handleChange = (event) => {
    pageSizeHandler(event.target.value);
    pageNoChangeHandler(1);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mt="1rem"
      sx={{
        backgroundColor: "white",
        fontSize: '1rem'
      }}
    >
      {totalRecords > 0 &&
        <Stack direction="row" alignItems="center">
          <Typography sx={{ display: "inline", fontSize: "1rem" }}>
            Show
          </Typography>
          <FormControl fullWidth disabled={disabled} sx={{
            marginLeft: "0.5rem",
            marginRight: "0.5rem"
          }}>
            <Select
              sx={{
                height: "2rem",
                width: "5rem"
              }}
              value={pageSize}
              id="demo-select-small"
              onChange={handleChange}
              IconComponent={ChevronDownIcon}
            >
              {pageSizeOptions.map(option => <MenuItem
                disableRipple
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.neutralColors.black[300],
                  },
                  "&.Mui-selected": {
                    backgroundColor: theme.palette.neutralColors.black[300],
                    borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                    borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: theme.palette.neutralColors.black[300],
                    borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                    borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  },
                  "&.Mui-selected.Mui-focusVisible": {
                    backgroundColor: theme.palette.neutralColors.black[300],
                    borderTop: `1px solid ${theme.palette.neutralColors.black[300]}`,
                    borderBottom: `1px solid ${theme.palette.neutralColors.black[300]}`,
                  }
                }}
                value={option}
                key={option}
              >
                {option}
              </MenuItem>)}
            </Select>
          </FormControl>{" "}
          <Typography>
            Items&nbsp;/&nbsp;Page
          </Typography>
        </Stack>
      }
      {totalRecords > pageSize && <Stack direction="row" alignItems="center">
        <BtnStandard btnTxt='Previous' iconPosition='start' icon={<LeftIcon />} size='small' onClick={() => {
          handleChangePagePrev(pageNo);
        }}
          disabled={disabled || pageNo === 1}
          styling={{
            fontSize: '1rem',
            '&:hover': {
              backgroundColor: 'inherit',

            }
          }}
        />

        <Typography
          sx={{
            display: "inline",
            marginLeft: "1rem",
            marginRight: "1rem",
            textAlign: "center",
            border: "1px solid #D1D1D1",
            borderRadius: "4px",
            padding: "0.4rem 1rem 0.4rem 1rem",
          }}
        >
          {pageNo}
        </Typography>

        <Typography sx={{ marginRight: "1rem", display: "inline" }}>
          of {Math.ceil(totalRecords / pageSize)}
        </Typography>
        <BtnStandard btnTxt='Next' iconPosition='end' icon={<RightIcon />} size='small' onClick={() => {
          handleChangePageNext(pageNo);
        }}
          disabled={disabled || pageNo === Math.ceil(totalRecords / pageSize)}
          styling={{
            fontSize: '1rem',
            '&:hover': {
              backgroundColor: 'inherit'
            }
          }}
        />
      </Stack>}
    </Stack>
  );
}