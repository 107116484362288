let dtStatic = {
    columns: [
        { id: "member", label: "Member", minWidth: "22%", ValType: "link" },
        {
            id: "obligation_name",
            label: "Obligation Name",
            minWidth: "23%",
            ValType: "Val",
        },
        {
            id: "country",
            label: "Country",
            minWidth: "9%",
            ValType: "Val",
        },
        {
            id: "stage",
            label: "Stage",
            minWidth: "11.5%",
            ValType: "Val",
        },
        {
            id: "due_date",
            label: "Due Date",
            minWidth: "14.5%",
            ValType: "Date",
        },
        {
            id: "obligation_status",
            label: "Status",
            minWidth: "20%",
            ValType: "Tag",
        },
    ],
    //       rows:[

    //         {
    //           "code":1,

    //           "obligation_id":"Test Obl Id",


    //           "obligation_group_name": "PwC AR Group",

    //           "status": "Data Load",
    //           "due_date": "09/01/2024",
    //           "abc": [
    //               {
    //                   "task": "danger",
    //                   "Desc": "Overdue+12d"
    //               }
    //           ]
    //       },
    //       {
    //         "code":1,

    //         "obligation_id":"Test Obl Id",


    //         "obligation_group_name": "PwC AR Group",

    //         "status": "Data Load",
    //         "due_date": "09/01/2024",
    //         "abc": [
    //             {
    //                 "task": "danger",
    //                 "Desc": "Overdue+12 days"
    //             }
    //         ]
    //     },

    //     {
    //       "code":1,

    //       "obligation_id":"Test Obl Id",


    //       "obligation_group_name": "PwC AR Group",

    //       "status": "Data Load",
    //       "due_date": "09/01/2024",
    //       "abc": [
    //           {
    //               "task": "danger",
    //               "Desc": "Overdue+12d"
    //           }
    //       ]
    //   },


    //   {
    //     "code":1,

    //     "obligation_id":"Test Obl Id",


    //     "obligation_group_name": "PwC AR Group",

    //     "status": "Data Load",
    //     "due_date": "09/01/2024",
    //     "abc": [
    //         {
    //             "task": "danger",
    //             "Desc": "Overdue+12d"
    //         }
    //     ]
    // },


    // {
    //   "code":1,

    //   "obligation_id":"Test Obl Id",


    //   "obligation_group_name": "PwC AR Group",

    //   "status": "Data Load",
    //   "due_date": "09/01/2024",
    //   "abc": [
    //       {
    //           "task": "danger",
    //           "Desc": "Overdue+12d"
    //       }
    //   ]
    // },

    //     ]
}
export default dtStatic;