import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useTheme,
} from "@mui/material";
import { BinIcon } from "../../../components/common/CustomIcons/BinIcon";
import { FORMAT_TO_EXTENSIONS_MAPPING } from "../../../components/common/FileUploader/constants";
import { SortIcon } from "../../../components/common/CustomIcons/SortIcon";
import { SortAscIcon } from "../../../components/common/CustomIcons/SortAscIcon";
import { SortDescIcon } from "../../../components/common/CustomIcons/SortDescIcon";
import { useNavigate } from "react-router-dom";

const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: "0.875rem",
  padding: "0.75rem 1rem",
  cursor: "pointer",
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: "0.875rem",
  padding: "0.75rem 1rem",
  fontWeight: 500,
  // todo: check below styling again
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: theme.palette.baseColors.grey,
  width: 100,
  maxWidth: 100,
}));

export const ListUploadedFiles = ({
  uploadedFiles,
  fileDeleteHandler,
  sortFileDataHandler,
  sortColumn,
  attachmentNameSortOrder,
  attachmentTypeSortOrder,
  extensionProcessingRequired,
}) => {
  const theme = useTheme();
  const navigate = useNavigate()
  return (
    uploadedFiles?.length > 0 && (
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", overflow: "initial" }}
      >
        <Table
          aria-label="selected files"
          sx={{
            borderRadius: "0.5rem",
            border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            overflow: "scroll",
          }}
        >
          <TableHead
            sx={{
              backgroundColor: theme.palette.neutralColors.black[100],
              height: "2.5rem",
            }}
          >
            <TableRow variant="body2">
              <StyledTableHeadingCell
                style={{ width: "65%", maxWidth: "65%" }}
                onClick={() => {
                  sortFileDataHandler("Attachment");
                }}
              >
                Attachment
                <IconButton disableRipple>
                  {sortColumn === "Attachment name" ? (
                    attachmentNameSortOrder === 1 ? (
                      <SortAscIcon />
                    ) : (
                      <SortDescIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              <StyledTableHeadingCell
                style={{ width: "30%", maxWidth: "30%" }}
                onClick={() => {
                  sortFileDataHandler("Attachment type");
                }}
              >
                Attachment type
                <IconButton disableRipple>
                  {sortColumn === "Attachment type" ? (
                    attachmentTypeSortOrder === 1 ? (
                      <SortAscIcon />
                    ) : (
                      <SortDescIcon />
                    )
                  ) : (
                    <SortIcon />
                  )}
                </IconButton>
              </StyledTableHeadingCell>
              {extensionProcessingRequired && (
                <StyledTableHeadingCell
                  style={{ width: "5%", maxWidth: "5%" }}
                ></StyledTableHeadingCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadedFiles?.map((file) => (
              <TableRow
                key={file.name}
                sx={{
                  height: "3rem",
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                }}
              >
                <StyledTableBodyCell
                  sx={{
                    color: theme.palette.primaryColors[200],
                    textDecoration: "underline",
                  }}
                >
                  {file.name}
                </StyledTableBodyCell>

                <StyledTableBodyCell>
                  {extensionProcessingRequired
                    ? FORMAT_TO_EXTENSIONS_MAPPING?.filter(
                      (obj) => obj.format === file.type
                    )[0]
                      ?.extension?.substring(1)
                      ?.toUpperCase()
                    : file?.extension}
                </StyledTableBodyCell>

                {extensionProcessingRequired && (
                  <StyledTableBodyCell>
                    <BinIcon
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.baseColors.grey,
                        "&:hover": {
                          color: theme.palette.functional.error,
                        },
                      }}
                      onClick={() => {
                        fileDeleteHandler(file.name);
                      }}
                    />
                  </StyledTableBodyCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};
