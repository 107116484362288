import { Button, CircularProgress, styled, useTheme } from '@mui/material';

const StyledFilledBtn = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primaryColors[200],
  color: theme.palette.secondaryColors[100],
  textTransform: 'none',
  borderRadius: '0.326rem',
  minWidth: 'max-content',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: theme.palette.primaryColors[100],
    color: theme.palette.primaryColors[200]
  },
  '&:disabled': {
    backgroundColor: theme.palette.secondaryColors[200],
    color: theme.palette.secondaryColors[400]
  }
}))

export const BtnFilled = ({ btnTxt, disabled, size, iconPosition, icon, onClick, loading, styling }) => {
  const theme = useTheme();
  return (
    <StyledFilledBtn
      variant='filled'
      startIcon={(iconPosition === "start" && !loading) ? icon : null}
      endIcon={(iconPosition === "end" && !loading) ? icon : null}
      disabled={disabled}
      sx={{
        height: size === "small" ? '2rem' : size === 'medium' ? '2.75rem' : '3.25rem',
        padding: size === "small" ? '0.5rem 1rem' : '0.5rem 1.5rem',
        fontSize: size === "small" ? '0.875rem' : '1rem',
        ...styling
      }}
      onClick={onClick}
      disableRipple
    >
      {loading && <CircularProgress size='1.2rem' sx={{ marginRight: '0.5rem', color: theme.palette.secondaryColors[100] }} />}
      {btnTxt}
    </StyledFilledBtn>
  )
}
