import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Tooltip, useTheme } from '@mui/material';
import { useUser } from '../../context';
import { UseActive } from '../../context/Active Users/ActiveUsersContext';

const AvatarIconsGroup = () => {
  const { activeUsers: arr } = UseActive()
  const { userName: uName } = useUser()
  const theme = useTheme();

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: theme.palette.primaryColors[200],
        ml: "3vw"

      },
      children: `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`,
    };
  }
  return (arr?.length > 0 && <AvatarGroup spacing={0} max={4} sx={{ mr: "1rem", fontSize: "1rem", fontWeight: "700" }}>
    {arr?.length === 0 && uName && <Tooltip title={uName?.toUpperCase()} placement="top"><Avatar {...stringAvatar(uName?.toUpperCase())} /></Tooltip>}
    {arr?.length > 0 && arr?.map((us) => {
      return (<Tooltip title={us?.user?.user_name?.toUpperCase()} placement="top"><Avatar {...stringAvatar(us?.user?.user_name?.toUpperCase())} /></Tooltip>)
    })}
  </AvatarGroup>
  )
}

export default AvatarIconsGroup