import { SvgIcon } from '@mui/material';

export const SupplyExceptionsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 5V1C5.5 0.734784 5.60536 0.48043 5.79289 0.292893C5.98043 0.105357 6.23478 0 6.5 0H19.5C19.7652 0 20.0196 0.105357 20.2071 0.292893C20.3946 0.48043 20.5 0.734784 20.5 1V14C20.5 14.2652 20.3946 14.5196 20.2071 14.7071C20.0196 14.8946 19.7652 15 19.5 15H15.5V18.993C15.5 19.549 15.051 20 14.493 20H1.507C1.37472 20.0001 1.24372 19.9742 1.12148 19.9236C0.999248 19.8731 0.888185 19.7989 0.794651 19.7053C0.701116 19.6118 0.626946 19.5008 0.576386 19.3785C0.525826 19.2563 0.499869 19.1253 0.5 18.993L0.503001 6.007C0.503001 5.451 0.952 5 1.51 5H5.5ZM7.5 5H14.493C15.049 5 15.5 5.449 15.5 6.007V13H18.5V2H7.5V5Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};