import { FormControl, Select, MenuItem, useTheme, Typography } from '@mui/material';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TextTag from '../../components/common/Text Tags/TextTag';
import { useObligation, useUser } from '../../context';
import { Toast } from './Toast';
import { DUMMY_OBLIGATIONS_DATA } from './dummyData';
import { createGlobalStyle } from 'styled-components';

function ViewingTag() {
  const theme = useTheme();
  return (
    <span
      style={{
        color: theme.palette.secondaryColors[400],
        marginLeft: '.5rem',
      }}>
      (Viewing)
    </span>
  );
}

export default function EntityDropdown({ isFetchingData, setIsFetchingData }) {
  const theme = useTheme();
  const { userToken } = useUser();
  const navigate = useNavigate();
  const { member, tab, subtab } = useParams();
  const current = member;

  const {
    selectedObgDetails,
    sisterObligations,
    setSisterObligations,
    groupObligation,
    setGroupObligation,
  } = useObligation();
  // const obligation = selectedObgDetails;

  const [toast, setToast] = useState({ type: '', message: '' });
  //   const [isFetchingData, setIsFetchingData] = useState(false);

  const isLoading = isFetchingData || !selectedObgDetails || !selectedObgDetails?.obligation_id;

  const handleEntityChange = (e) => {
    const entity = e.target.value;
    const newObligation = sisterObligations.find((o) => o.member === entity);
    const tabPath =
      newObligation.differentiator === 'member' && tab === 'export'
        ? 'overview'
        : `${tab}${subtab ? '/' + subtab : ''}`;
    navigate(`/obligations/${newObligation.obligation_id}/${newObligation.member}/${tabPath}`);
    setIsFetchingData(true);
  };

  const maxCharsCurrentMember = 20;
  const maxCharsNonCurrentMember = 30;

  return (
    <>
      <Typography
        fontWeight={500}
        paddingBottom='.5rem'
        marginTop='1rem'>
        Member
      </Typography>
      <FormControl>
        <Select
          id='member-dropdown'
          defaultValue={current ?? ''}
          onChange={handleEntityChange}
          renderValue={(p) => (isLoading ? 'Loading...' : p)}
          disabled={isLoading}
          sx={{
            width: '25rem',
            height: '3.25rem',
            fontSize: '1.0625rem',
            color: theme.palette.random[200],
            backgroundColor: theme.palette.baseColors.white,
            // THIS OVERRIDES THE BORDER COLORS FOR THE MAIN SELECT OBJECT.
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
              borderColor: theme.palette.random[500],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
              borderColor: theme.palette.baseColors.grey,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important',
              borderColor: '#00000033',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                width: '25rem',
                maxHeight: '39.0625rem',
                // marginLeft: "1.5rem",
                marginTop: '.5rem',
                '& .MuiMenuItem-root.Mui-selected': { backgroundColor: 'white !important' },
                '& .MuiMenuItem-root:hover': { backgroundColor: '#E6E8E8 !important' },
                '& .MuiMenuItem-root.Mui-selected:hover': { backgroundColor: '#E6E8E8 !important' },
              },
            },
            // The Mui-selected class just wouldn't play nice, so I'm using !important. I'm sorry.
            MenuListProps: {
              sx: {
                pt: '0',
                pb: '0',
                '& .Mui-hovered': { backgroundColor: '#E6E8E8 !important' },
                '& .Mui-selected': { backgroundColor: 'white !important' },
              },
            },
            sx: {
              display: isLoading ? 'none' : 'default',
            },
          }}
          IconComponent={(props) => (
            <ChevronDownIcon
              {...props}
              color={theme.palette.baseColors.grey}
            />
          )}>
          {sisterObligations.length > 0 &&
            sisterObligations.map((o) => {
              const itemSx = {
                display: 'flex',
                justifyContent: 'space-between',
                height: '3.90625rem',
                fontSize: '0.875rem',
                padding: '1.25rem',
              };
              const isGroupObligation = o.member === groupObligation?.member;
              const isBlocking = o.stage === groupObligation?.stage;
              const isCurrent = o.member === current;

              return (
                <MenuItem
                  key={o.member}
                  value={o.member}
                  title={o.member}
                  sx={{
                    ...itemSx,
                    paddingLeft: isGroupObligation ? '1.5rem' : '2.375rem',
                  }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {!isGroupObligation && (
                      <span
                        style={{
                          display: 'inline-block',
                          width: '1rem',
                          height: '1rem',
                          borderRadius: '50%',
                          marginRight: '0.5625rem',
                          backgroundColor: isBlocking ? theme.palette.functional.error : 'white',
                          border: '1px solid ' + (isBlocking ? 'none' : 'rgba(0, 0, 0, .2)'),
                          textOverflow: 'ellipsis',
                        }}></span>
                    )}
                    {isCurrent && o.member.length > maxCharsCurrentMember
                      ? o.member.slice(0, maxCharsCurrentMember) + '...'
                      : o.member.length > maxCharsNonCurrentMember
                      ? o.member.slice(0, maxCharsNonCurrentMember) + '...'
                      : o.member}
                    {isCurrent && <ViewingTag />}
                  </span>
                  <TextTag
                    size='s'
                    variant='grey'
                    text={o.stage}
                  />
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
}
