import { Stack, Typography, useTheme } from '@mui/material';
import { BtnStandard } from '../Buttons/BtnStandard';
import { BtnFilled } from '../Buttons/BtnFilled';
import { Modal as MuiModal } from '@mui/material';
import { CloseIcon } from '../CustomIcons/CloseIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 'none',
};

export const Modal = ({
  title,
  children,
  cancelHandler,
  closePopupHandler,
  submitHandler,
  btnText,
  loading,
  secondaryBtnText,
  enableEditingHandler,
  disableSecondaryButton,
  enableEditing,
}) => {
  const theme = useTheme();
  return (
    <MuiModal
      // open={open}
      open={true}
      onClose={closePopupHandler}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      sx={{ border: 'none' }}>
      <Stack
        padding='2rem'
        // gap='3rem'
        sx={{
          borderRadius: '0.25rem',
          backgroundColor: theme.palette.baseColors.white,
          border: `1px solid ${theme.palette.random[700]}`,
          borderTop: 'none',
          width: '60vw',
          ...style,
        }}>
        <Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'>
          <Typography
            variant='h5'
            fontWeight='medium'
            color={theme.palette.baseColors.grey}>
            {title}
          </Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={btnText === 'Close' ? submitHandler : cancelHandler}
          />
        </Stack>
        {children}
        <Stack
          mt='3rem'
          flexDirection='row'
          justifyContent='flex-end'
          gap='0.5rem'>
          {(cancelHandler || secondaryBtnText) && (
            <BtnStandard
              btnTxt={secondaryBtnText ? secondaryBtnText : 'Cancel'}
              onClick={secondaryBtnText === 'Edit' ? enableEditingHandler : cancelHandler}
              styling={
                secondaryBtnText === 'Edit'
                  ? { border: `1px solid ${theme.palette.neutralColors.black[200]}` }
                  : {}
              }
              disabled={disableSecondaryButton}
              disabledStyling={
                secondaryBtnText === 'Edit'
                  ? { border: `1px solid ${theme.palette.neutralColors.black[200]}` }
                  : {}
              }
            />
          )}
          <BtnFilled
            size='large'
            styling={
              loading
                ? {
                    '&:disabled': {
                      backgroundColor: theme.palette.primaryColors[200],
                      color: theme.palette.secondaryColors[100],
                    },
                  }
                : {}
            }
            btnTxt={btnText ? btnText : 'Save'}
            onClick={submitHandler}
            loading={loading}
            disabled={(secondaryBtnText === 'Edit' && !enableEditing) || loading}
          />
        </Stack>
      </Stack>
    </MuiModal>
  );
};
