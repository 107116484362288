import { useLocation } from 'react-router-dom';
import { IframeScreen } from '../../../../components/common/Iframe/IframeScreen';
import { GENERAL_ANALYTICS_CARDS_DATA, getGeneralAnalyticsCardDescription } from '../constants';
import { useEffect, useState } from 'react';
import { useObligation } from '../../../../context';
import { Looker } from '../../../../components/common/Looker/Looker';
import { lookerEmbedDomain, lookerEntityCodeList } from '../../../../utils';
import { Box, Grid, Stack } from '@mui/material';
import { WhiteCard } from '../../WhiteCard';
import { PageSkeleton } from '../PageSkeleton';
import { ExpandableLookerGrid } from "../../../../components/common/Looker/ExpandableLookerGrid";
import { TAX_BREAKDOWN_CARD_INFO } from '../../lookerConstants';

export const TaxBreakdown = () => {
  const { isGroupSelected, sisterObligations, selectedObgDetails } = useObligation();
  // const obligation = selectedObgDetails

  return (
    <PageSkeleton
      title="Tax Breakdown"
      description={getGeneralAnalyticsCardDescription("Tax Breakdown")}
      url=".."
      isURLRelative
    >
      <ExpandableLookerGrid
        cards={TAX_BREAKDOWN_CARD_INFO}
        dashboardParameters={{
          "Obligation ID": selectedObgDetails?.obligation_id,
          "Entitycode": lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails)
        }}
        downloadConstants={{
          dashboard: process.env.REACT_APP_LOOKER_TAX_BREAKDOWN,
          filename: `${selectedObgDetails?.obligation_name}_Tax_Breakdown`,
          title: "Tax Breakdown"
        }}
      />
    </PageSkeleton>
  )
};