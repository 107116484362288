import { Alert, Box, Fade, Slide, Snackbar } from "@mui/material"
import { useEffect, useState } from "react";
import { useNotification } from "../../../context";
import { Success } from "./Success";

export const Notification = () => {
    const { show, setShow, notificationInfo, setNotificationInfo } = useNotification();
    return (
        <Box sx={{
            position: 'absolute',
            top: `calc(7rem - 24px)`,
            left: 0,
            width: '100%',
        }}>
            <Box sx={{
                position:'relative',
                width: '100%',
                // height: '200px',
            }}>
                <Snackbar
                    open={show}
                    autoHideDuration={50000}
                    sx={{
                        position: 'absolute',
                        width:'100%'
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={() => {
                        setShow(false);
                        // setNotificationInfo({
                        //     type: '',
                        //     message: '',
                        //     icon: null
                        // })
                    }}
                    TransitionComponent={Fade}
                    transitionDuration={1}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{width:'100%'}}
                    >
                        {notificationInfo.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    )
}
