import { useTheme } from "@mui/material"
import { Link } from "react-router-dom"

export const DownloadLink = ({ children, filename = children, urlObject, toastSetter, sx }) => {
    const theme = useTheme()

    return <Link
        to={urlObject ? urlObject : ""}
        download={urlObject ? filename : undefined}
        onClick={() => {
            const toast = urlObject ? {
                type: "success",
                message: "Downloading file...",
            } : {
                type: "failure",
                message: "Couldn't download file.",
            }
            toastSetter(toast);
        }}
        target={urlObject ? "_blank" : undefined}
        rel={urlObject ? "noreferrer" : undefined}
        style={{
            fontSize: '0.875rem',
            color: theme.palette.functional.tableLink,
            textDecoration: 'underline',
            ...sx
        }}
    >
        {children}
    </Link>
}