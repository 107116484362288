import * as mui from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';

import { WhiteCard } from '../../../WhiteCard';
import { typographyStyle } from '../../index';
import { LockIcon } from '../../../../../components/common/CustomIcons/LockIcon';
import { FileUploader } from '../../../../../components/common/FileUploader/FileUploader';
import { WarningPopup } from '../../../../../components/common/Modal/WarningPopup';
import { BtnFilled } from '../../../../../components/common/Buttons/BtnFilled';
import { BtnStandard } from '../../../../../components/common/Buttons/BtnStandard';
import { ListUploadedFiles } from '../../../Notes/ListUploadedFiles';
import {
  ALLOWED_FILE_TYPES,
  ALLOWED_EXTENSIONS,
  FORMAT_TO_EXTENSIONS_MAPPING,
} from '../../../../../components/common/FileUploader/constants';
import { CloseIcon } from '../../../../../components/common/CustomIcons/CloseIcon';
import { useUser } from '../../../../../context';
import { getCurrencySymbol, roundOffValue } from '../../Utility/helperFunctions';
import { useReconciliation } from '../../../../../context/Reconciliation/ReconciliationContext';

export const CreateNewTransactionModal = ({
  preValues,
  item,
  handleResolveStatus,
  setPrePopulateValue,
}) => {
  const theme = mui.useTheme();
  const navigate = useNavigate()
  const HeaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1244px',
    padding: '32px',
    pb: 0,
  };
  const style = {
    width: '100%',
    height: '50vh',
    border: 'none',
    padding: '2px',
    pb: '5rem',
    pr: '1rem',
    overflow: 'auto',
  };
  const TextFieldStyle = {
    width: '368px',
    height: '50px',
    borderRadius: '4px',
    pt: 0,
    border: `1px solid ${theme.palette.secondaryColors[400]}`,
    bgcolor: theme.palette.random[1100],
  };

  const { userToken, userId } = useUser();
  const { modalNo, setModalNo } = useReconciliation();
  const { id } = useParams();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [attachments, setAttachments] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [attachmentNameSortOrder, setAttachmentNameSortOrder] = useState(-1);
  const [attachmentTypeSortOrder, setAttachmentTypeSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState(null);
  const [cancel, setCancel] = useState(false);
  const [prePopulateValues, setPrePopulateValues] = useState();
  const [loading, setLoading] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);

  const DisabledInputFields = [
    { name: 'Transaction ID', value: preValues?.transaction_id },
    { name: 'Entry Date', value: dayjs(preValues?.entry_date).format('MM/DD/YYYY') },
    { name: 'Flow', value: preValues?.flow },
  ];
  const [complianceEntity, setComplianceEntity] = useState({
    VatRegistrationNo: 0,
    CompanyName: '',
    ComplianceReportingCountryCode: '',
    ComplianceReportingCountryRegion: '',
  });
  const ComplianceEntityInputFields = [
    {
      name: 'VAT Registration No.',
      value: complianceEntity.VatRegistrationNo,
    },
    { name: 'Company Name', value: complianceEntity.CompanyName },
    {
      name: 'Compliance Reporting Country Code',
      value: complianceEntity.ComplianceReportingCountryCode,
    },
    {
      name: 'Compliance Reporting Country Region',
      value: complianceEntity.ComplianceReportingCountryRegion,
    },
  ];
  const [complianceCounterParty, setComplianceCounterParty] = useState({
    VatRegistrationNo: 0,
    CompanyName: '',
    ComplianceReportingCountryCode: '',
  });
  const ComplianceCounterPartyInputFields = [
    {
      name: 'VAT Registration No.',
      value: complianceCounterParty.VatRegistrationNo,
    },
    { name: 'Company Name', value: complianceCounterParty.CompanyName },
    {
      name: 'Compliance Reporting Country Code',
      value: complianceCounterParty.ComplianceReportingCountryCode,
    },
  ];
  const [taxTreatments, setTaxTreatments] = useState({
    TaxTreatment: '',
    TaxCode: '',
    ERPReportingCountryCode: '',
    CountryCode: '',
    TaxPointDate: '',
    ComplianceReportingDate: '',
  });
  const TaxTreatmentsInputFields = [
    { name: 'Tax Treatment', value: taxTreatments.TaxTreatment },
    { name: 'Tax Code', value: taxTreatments.TaxCode },
    { name: 'ERP Reporting Country Code', value: taxTreatments.ERPReportingCountryCode },
    { name: 'Country Code', value: taxTreatments.CountryCode },
    { name: 'Tax Point Date', value: taxTreatments.TaxPointDate, type: 'Date' },
    {
      name: 'Compliance Reporting Date',
      value: taxTreatments.ComplianceReportingDate,
      type: 'Date',
    },
  ];
  const [complianceAmount, setComplianceAmount] = useState({
    Currency: 'AUD',
    Net: 0.0,
    Vat: 0.0,
    Gross: 0.0,
    Recoverable: 0.0,
    NonRecoverable: 0.0,
  });
  const [statutoryReportingAmount, setStatutoryReportingAmount] = useState({
    Currency: 'AUD',
    Net: 0.0,
    Vat: 0.0,
    Gross: 0.0,
    Recoverable: 0.0,
    NonRecoverable: 0.0,
  });
  const AmountTableConstants = [
    {
      label: 'Currency',
      inputType: 'text',
      value: [statutoryReportingAmount.Currency, complianceAmount.Currency],
    },
    {
      label: 'Net',
      inputType: 'number',
      value: [statutoryReportingAmount.Net, complianceAmount.Net],
    },
    {
      label: 'Vat',
      inputType: 'number',
      value: [statutoryReportingAmount.Vat, complianceAmount.Vat],
    },
    {
      label: 'Gross',
      inputType: 'number',
      value: [statutoryReportingAmount.Gross, complianceAmount.Gross],
    },
    {
      label: 'Recoverable',
      inputType: 'number',
      value: [statutoryReportingAmount.Recoverable, complianceAmount.Recoverable],
    },
    {
      label: 'Non Recoverable',
      inputType: 'number',
      value: [statutoryReportingAmount.NonRecoverable, complianceAmount.NonRecoverable],
    },
  ];
  const handleClose = () => setCancel(true);

  const fileUploadHandler = (e) => {
    const uploadedPreviously = [...attachments];
    const uploadedNow = e.target.files;
    const remainingCapacity = 50 - uploadedPreviously.length;
    if (uploadedNow.length > remainingCapacity) {
      setUploadError(`File upload failed. You can only upload a maximum of ${50} files.`);
      return;
    }
    const isInvalidFileType = [...e.target.files].some((f) => {
      if ((!ALLOWED_FILE_TYPES.includes(f.type)) || f.name?.split('.')?.length > 2) {
        return true;
      }
      return false;
    });
    if (isInvalidFileType) {
      setUploadError(
        `File upload failed. You can only upload ${ALLOWED_EXTENSIONS.join(', ')} file formats.`
      );
      return;
    }
    const isInvalidFileSize = [...e.target.files].some((f) => {
      const fileSizeInKB = f.size / 1024;
      if (fileSizeInKB > 5120) {
        return true;
      }
      return false;
    });
    if (isInvalidFileSize) {
      setUploadError(`File upload failed. You can upload files with size upto 10 MB only`);
      return;
    }
    setUploadError('');
    const totalUniqueFiles = [];
    for (const file of uploadedNow) {
      const res = uploadedPreviously.filter((f) => f.name === file.name);
      if (res?.length === 0) {
        totalUniqueFiles.push(file);
      }
    }
    setAttachments((prev) => [...prev, ...totalUniqueFiles]);
  };

  const sortFileDataHandler = (colName) => {
    if (colName === 'Attachment') {
      setSortColumn('Attachment name');
      setAttachmentTypeSortOrder(1);
      setAttachmentNameSortOrder(attachmentNameSortOrder * -1);
      const sortedData = [...attachments].sort(
        (a, b) => attachmentNameSortOrder * a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setAttachments(sortedData);
    } else if (colName === 'Attachment type') {
      setSortColumn('Attachment type');
      setAttachmentNameSortOrder(1);
      setAttachmentTypeSortOrder(attachmentTypeSortOrder * -1);
      const sortedData = [...attachments].sort((a, b) => {
        const extensionA = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === a.type
        )?.extension;
        const extensionB = FORMAT_TO_EXTENSIONS_MAPPING.find(
          (obj) => obj.format === b.type
        )?.extension;
        return attachmentTypeSortOrder * extensionA.localeCompare(extensionB);
      });
      setAttachments(sortedData);
    }
  };

  const fileDeleteHandler = (name) => {
    const remainingFiles = [...attachments].filter((f) => f.name !== name);
    setAttachments(remainingFiles);
  };
  let entitycode = item?.Entity?.split('-')[0].trim();
  let entityname = item?.Entity?.split('-')[1].trim();
  let glaccount = item?.GLAccount?.split('-')[0].trim();
  let glDesc = item?.GLAccount?.split('-')[1].trim();

  const fetchPrePopulateData = (filter) => {
    let baseurl =
      process.env.REACT_APP_BACKEND_URL +
      `/transaction/listall?filter_query=obligation_id__eq=${id}%26entitycode__eq=${entitycode}%26entityname__eq=${entityname}
%26glaccountdescription__eq=${glDesc}%26glaccountno__eq=${glaccount}%26flow__eq=${preValues?.flow}`;
    let url = filter ? baseurl + filter : baseurl;
    fetch(url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      credentials: 'include',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {
        let details = data?.transactions[0];
        setPrePopulateValues(details);
        if (filter) {
          setComplianceCounterParty({
            ...complianceCounterParty,
            VatRegistrationNo: details?.compliancecounterpartyvatregistrationno,
            CompanyName: details?.compliancecounterpartycompanyname,
            ComplianceReportingCountryCode: details?.compliancecounterpartycountrycode,
          });
        }
        if (!filter) {
          setComplianceEntity({
            ...complianceEntity,
            VatRegistrationNo: details?.complianceentityvatregistrationno,
            CompanyName: details?.complianceentitycompanyname,
            ComplianceReportingCountryCode: details?.compliancereportingcountrycode,
            ComplianceReportingCountryRegion: details?.compliancereportingcountryregion,
          });
          setComplianceCounterParty({
            ...complianceCounterParty,
            VatRegistrationNo: details?.compliancecounterpartyvatregistrationno,
            CompanyName: details?.compliancecounterpartycompanyname,
            ComplianceReportingCountryCode: details?.compliancecounterpartycountrycode,
          });
          setTaxTreatments({
            ...taxTreatments,
            TaxTreatment: details?.transaction_features?.taxtreatmentname,
            TaxCode: details?.taxcode,
            ERPReportingCountryCode: details?.erpreportingcountrycode,
            CountryCode:
              preValues.source === 'AP'
                ? details?.suppliercountrycode
                : details?.customercountrycode,
            TaxPointDate: details?.taxpointdate,
            ComplianceReportingDate: details?.compliancereportingdate,
          });
          setStatutoryReportingAmount({
            ...statutoryReportingAmount,
            Currency: details?.statcurrencycode,
            Net: roundOffValue(parseFloat(item.Difference) * 10.0, item.Difference),
            Vat: parseFloat(item.Difference),
          });
          setComplianceAmount({
            ...complianceAmount,
            Currency: details?.compliancecurrencycode ?? details?.statcurrencycode,
            Net: roundOffValue(parseFloat(item.Difference) * 10.0, item.Difference),
            Vat: parseFloat(item.Difference),
          });
        }
      })
      .catch((err) => { })
      .finally(() => { });
  };

  const ComplianceEntityChangeHandler = (e, value) => {
    switch (value) {
      case 0:
        setComplianceEntity({ ...complianceEntity, VatRegistrationNo: e.target.value });
        break;
      case 1:
        setComplianceEntity({ ...complianceEntity, CompanyName: e.target.value });
        break;
      case 2:
        setComplianceEntity({
          ...complianceEntity,
          ComplianceReportingCountryCode: e.target.value,
        });
        break;
      case 3:
        setComplianceEntity({
          ...complianceEntity,
          ComplianceReportingCountryRegion: e.target.value,
        });
        break;
      default:
        return;
    }
  };

  const complianceCounterPartyChangeHandler = (e, value) => {
    switch (value) {
      case 0:
        setComplianceCounterParty({ ...complianceCounterParty, VatRegistrationNo: e.target.value });
        break;
      case 1:
        setComplianceCounterParty({ ...complianceCounterParty, CompanyName: e.target.value });
        break;
      case 2:
        setComplianceCounterParty({
          ...complianceCounterParty,
          ComplianceReportingCountryCode: e.target.value,
        });
        break;
      default:
        return;
    }
  };

  const taxTreatmentChangeHandler = (e, value) => {
    switch (value) {
      case 0:
        setTaxTreatments({ ...taxTreatments, TaxTreatment: e.target.value });
        break;
      case 1:
        setTaxTreatments({ ...taxTreatments, TaxCode: e.target.value });
        break;
      case 2:
        setTaxTreatments({
          ...taxTreatments,
          ERPReportingCountryCode: e.target.value,
        });
        break;
      case 3:
        setTaxTreatments({
          ...taxTreatments,
          CountryCode: e.target.value,
        });
        break;
      case 4:
        setTaxTreatments({
          ...taxTreatments,
          TaxPointDate: e.target.value,
        });
        break;
      case 5:
        setTaxTreatments({
          ...taxTreatments,
          ComplianceReportingDate: e.target.value,
        });
        break;
      default:
        return;
    }
  };

  const amountChangeHandler = (e, name, type) => {
    switch (name) {
      case 0:
        type === 1
          ? setStatutoryReportingAmount({ ...statutoryReportingAmount, Currency: e.target.value })
          : setComplianceAmount({ ...complianceAmount, Currency: e.target.value });
        break;
      case 1:
        type === 1
          ? setStatutoryReportingAmount({
            ...statutoryReportingAmount,
            Net: e.target.value,
          })
          : setComplianceAmount({
            ...complianceAmount,
            Net: e.target.value,
          });
        break;
      case 2:
        type === 1
          ? setStatutoryReportingAmount({
            ...statutoryReportingAmount,
            Vat: e.target.value,
          })
          : setComplianceAmount({
            ...complianceAmount,
            Vat: e.target.value,
          });
        break;
      case 4:
        type === 1
          ? setStatutoryReportingAmount({
            ...statutoryReportingAmount,
            Recoverable: e.target.value,
          })
          : setComplianceAmount({ ...complianceAmount, Recoverable: e.target.value });
        break;
      case 5:
        type === 1
          ? setStatutoryReportingAmount({
            ...statutoryReportingAmount,
            NonRecoverable: e.target.value,
          })
          : setComplianceAmount({ ...complianceAmount, NonRecoverable: e.target.value });
        break;
      default:
        return;
    }
  };

  const CreateTransactionHandler = () => {
    if (
      parseFloat(statutoryReportingAmount.Recoverable) +
      parseFloat(statutoryReportingAmount.NonRecoverable) !==
      parseFloat(statutoryReportingAmount.Vat) ||
      parseFloat(complianceAmount.Recoverable) + parseFloat(complianceAmount.NonRecoverable) !==
      parseFloat(complianceAmount.Vat)
    ) {
      setWarningPopup(true);
    }
    else if (userToken) {
      const hasInvalidFile = attachments.some(f => f.name?.split('.').length > 2 ||
        !(ALLOWED_FILE_TYPES.includes(f.type)));
      if (hasInvalidFile) {
        return;
      }
      setLoading(true);
      let url = '';
      url =
        process.env.REACT_APP_BACKEND_URL +
        `/reconciliation/createnewtransaction?transaction_id=${preValues?.transaction_id}&
obligation_id=${id}&entity=${item?.Entity}&glaccount=${item?.GLAccount}&
entry_date=${preValues?.entry_date}&flow=${preValues?.flow}&
complianceentityvatregistrationno=${complianceEntity.VatRegistrationNo}&
complianceentitycompanyname=${complianceEntity.CompanyName}&
compliancereportingcountrycode=${complianceEntity.ComplianceReportingCountryCode}&
compliancereportingcountryregion=${complianceEntity.ComplianceReportingCountryRegion}&
compliancecounterpartyvatregistrationno=${complianceCounterParty.VatRegistrationNo}&
compliancecounterpartycompanyname=${complianceCounterParty.CompanyName}&
compliancecounterpartycountrycode=${complianceCounterParty.ComplianceReportingCountryCode}&
taxtreatment=${taxTreatments.TaxTreatment}&
erpreportingcountrycode=${taxTreatments.ERPReportingCountryCode}&
compliancereportingdate=${dayjs(taxTreatments.ComplianceReportingDates).format('YYYY-MM-DD')}&
suppliercountrycode=${prePopulateValues?.suppliercountrycode}&
customercountrycode=${prePopulateValues?.customercountrycode}&
taxpointdate=${dayjs(taxTreatments.TaxPointDate).format('YYYY-MM-DD')}&
compliancecurrencycode=${complianceAmount.Currency}&
statcurrencycode=${statutoryReportingAmount.Currency}&
compliancenetamount=${complianceAmount.Net}&
statnetamount=${statutoryReportingAmount.Net}&
compliancevatamount=${complianceAmount.Vat}&
statvatamount=${statutoryReportingAmount.Vat}&
compliancerecoverablevatamount=${complianceAmount.Recoverable}&
statrecoverablevatamount=${statutoryReportingAmount.Recoverable}&
compliancenonrecoverablevatamount=${complianceAmount.NonRecoverable}&
statnonrecoverablevatamount=${statutoryReportingAmount.NonRecoverable}&
source=${preValues?.source}&
taxcode=${taxTreatments.TaxCode}&
difference=${item?.Difference}`;

      const formData = new FormData();
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          formData.append('files', attachments[i]);
        }
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        body: attachments.length > 0 ? formData : null,
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          setModalNo(-1);
          setPrePopulateValue({});
          setPrePopulateValues({});
          handleResolveStatus('success', 'New transaction creation success');
        })
        .catch((err) => {
          handleResolveStatus('failure', 'New transaction creation failed');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (userToken && preValues.flow && modalNo === 3) {
      fetchPrePopulateData();
    }
  }, [id, preValues, userToken]);

  return (
    <mui.Modal
      open={true}
      style={{ position: 'fixed' }}>
      <WhiteCard style={HeaderStyle}>
        <mui.Stack
          width='100%'
          alignItems='center'
          justifyContent='space-between'
          direction='row'
          sx={{ borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}` }}>
          <mui.Typography
            id='modal-modal-title'
            sx={typographyStyle('1.5rem', 700, theme.palette.baseColors.grey, {
              borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
            })}>
            Create New Transaction
          </mui.Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={handleClose}
          />
        </mui.Stack>

        <WhiteCard style={style}>
          <mui.Stack gap={'3rem'}>
            <mui.Grid
              container
              width={'100%'}
              direction={'row'}
              spacing={2}>
              {DisabledInputFields.map((inputField) => {
                return (
                  <mui.Grid
                    item
                    xs={4}>
                    <mui.FormControl>
                      <mui.FormHelperText
                        id='helper-text'
                        sx={{ m: 0 }}>
                        <mui.Typography
                          sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                          {inputField.name}
                        </mui.Typography>
                      </mui.FormHelperText>
                      <mui.FilledInput
                        style={TextFieldStyle}
                        sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                        id='filled-adornment-weight'
                        endAdornment={
                          <mui.InputAdornment position='end'>
                            <LockIcon />
                          </mui.InputAdornment>
                        }
                        aria-describedby='helper-text'
                        disableUnderline
                        hiddenLabel
                        disabled
                        value={inputField.value}
                      />
                    </mui.FormControl>
                  </mui.Grid>
                );
              })}
            </mui.Grid>
            <mui.Stack gap={'2rem'}>
              <mui.Typography
                sx={typographyStyle('1.125rem', 500, '#000')}
                style={{ borderBottom: `1px solid ${theme.palette.neutralColors.black[100]}` }}>
                Compliance Entity
              </mui.Typography>
              <mui.Grid
                container
                spacing={2}>
                {ComplianceEntityInputFields.map((inputField, index) => {
                  return (
                    <mui.Grid
                      item
                      xs={4}>
                      <mui.FormControl>
                        <mui.FormHelperText
                          id='helper-text'
                          sx={{ m: 0 }}>
                          <mui.Typography
                            sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                            {inputField.name}
                          </mui.Typography>
                        </mui.FormHelperText>
                        <mui.OutlinedInput
                          style={{ width: '368px', height: '50px', borderRadius: '4px', pt: 0 }}
                          sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                          id='filled-adornment-weight'
                          aria-describedby='helper-text'
                          type={inputField.inputType ?? 'text'}
                          disableUnderline
                          autoComplete='off'
                          hiddenLabel
                          onChange={(e) => ComplianceEntityChangeHandler(e, index)}
                          value={inputField.value}
                        />
                      </mui.FormControl>
                    </mui.Grid>
                  );
                })}
              </mui.Grid>
            </mui.Stack>
            <mui.Stack gap={'2rem'}>
              <mui.Typography
                sx={typographyStyle('1.125rem', 500, '#000')}
                style={{ borderBottom: `1px solid ${theme.palette.neutralColors.black[100]}` }}>
                Compliance Counterparty
              </mui.Typography>
              <mui.Grid
                container
                spacing={2}>
                {ComplianceCounterPartyInputFields.map((inputField, index) => {
                  return (
                    <mui.Grid
                      item
                      xs={4}>
                      <mui.FormControl>
                        <mui.FormHelperText
                          id='helper-text'
                          sx={{ m: 0 }}>
                          <mui.Typography
                            sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                            {inputField.name}
                          </mui.Typography>
                        </mui.FormHelperText>
                        <mui.OutlinedInput
                          style={{ width: '368px', height: '50px', borderRadius: '4px', pt: 0 }}
                          sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                          id='filled-adornment-weight'
                          aria-describedby='helper-text'
                          disableUnderline
                          type={inputField.inputType ?? 'text'}
                          hiddenLabel
                          autoComplete='off'
                          onChange={(e) => complianceCounterPartyChangeHandler(e, index)}
                          onBlur={(e) =>
                            fetchPrePopulateData(
                              `%26compliancecounterpartyvatregistrationno__eq=${e.target.value}`
                            )
                          }
                          value={inputField.value}
                        />
                      </mui.FormControl>
                    </mui.Grid>
                  );
                })}
              </mui.Grid>
            </mui.Stack>
            <mui.Stack gap={'2rem'}>
              <mui.Typography
                sx={typographyStyle('1.125rem', 500, '#000')}
                style={{ borderBottom: `1px solid ${theme.palette.neutralColors.black[100]}` }}>
                Tax Treatments
              </mui.Typography>
              <mui.Grid
                container
                spacing={2}>
                {TaxTreatmentsInputFields.map((inputField, index) => {
                  return (
                    <mui.Grid
                      item
                      xs={4}>
                      <mui.FormControl>
                        <mui.FormHelperText
                          id='helper-text'
                          sx={{ m: 0 }}>
                          <mui.Typography
                            sx={typographyStyle('1rem', 500, theme.palette.baseColors.grey)}>
                            {inputField.name}
                          </mui.Typography>
                        </mui.FormHelperText>
                        <mui.OutlinedInput
                          style={{ width: '368px', height: '50px', borderRadius: '4px', pt: 0 }}
                          sx={typographyStyle('1rem', 400, theme.palette.secondaryColors[800])}
                          id='filled-adornment-weight'
                          type={inputField.type}
                          aria-describedby='helper-text'
                          disableUnderline
                          hiddenLabel
                          autoComplete='off'
                          format={'DD/MM/YYYY'}
                          value={inputField.value}
                          onChange={(e) => taxTreatmentChangeHandler(e, index)}
                        />
                      </mui.FormControl>
                    </mui.Grid>
                  );
                })}
              </mui.Grid>
            </mui.Stack>
            <mui.Stack>
              <mui.Typography
                sx={typographyStyle('1.125rem', 500, '#000')}
                style={{
                  borderBottom: `1px solid ${theme.palette.neutralColors.black[100]}`,
                  marginBottom: '1rem',
                }}>
                Amount
              </mui.Typography>
              <mui.Stack
                style={{
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  borderRadius: '8px',
                  width: '1155px',
                }}>
                <mui.Grid
                  container
                  spacing={1}
                  sx={{
                    m: 0,
                    width: '1153px',
                    borderRadius: '8px 8px 0 0',
                    boder: '1px solid',
                    textAlign: 'end',
                    bgcolor: theme.palette.secondaryColors[100],
                    height: '40px',
                  }}
                  gap={2}>
                  <mui.Grid
                    item
                    style={{ width: 684 }}></mui.Grid>
                  <mui.Grid
                    item
                    style={{ width: 210 }}>
                    <mui.Typography
                      sx={typographyStyle('.875rem', 700, theme.palette.baseColors.grey)}>
                      {'Statutory Reporting'}
                    </mui.Typography>
                  </mui.Grid>
                  <mui.Grid
                    item
                    style={{ width: 210 }}>
                    <mui.Typography
                      sx={typographyStyle('.875rem', 700, theme.palette.baseColors.grey)}>
                      {'Compliance'}
                    </mui.Typography>
                  </mui.Grid>
                </mui.Grid>
                {AmountTableConstants.map((col, index) => {
                  return (
                    <mui.Grid
                      container
                      spacing={1}
                      gap={2}
                      sx={{
                        m: 0,
                        width: '1153px',
                        boder: '1px solid',
                        height: '48px',
                        borderBottom: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <mui.Grid
                        item
                        sx={{ width: '684px', p: 0, alignContent: 'center' }}>
                        <mui.Typography
                          sx={
                            index === 0
                              ? typographyStyle('.875rem', 500, theme.palette.baseColors.grey, {
                                fontStyle: 'italic',
                              })
                              : index === 3
                                ? typographyStyle('.875rem', 700, theme.palette.baseColors.grey, {
                                  fontStyle: 'italic',
                                })
                                : typographyStyle('.875rem', 500, theme.palette.baseColors.grey)
                          }>
                          {col.label}
                        </mui.Typography>
                      </mui.Grid>
                      <mui.Grid
                        item
                        sx={{ pl: '4px' }}>
                        <mui.OutlinedInput
                          sx={{ height: 35, width: 200 }}
                          id='filled-adornment-weight'
                          type={col.inputType}
                          autoComplete='off'
                          value={
                            index === 3
                              ? roundOffValue(
                                parseFloat(statutoryReportingAmount.Net) +
                                parseFloat(statutoryReportingAmount.Vat),
                                statutoryReportingAmount.Net,
                                statutoryReportingAmount.Vat
                              )
                              : col.value[0]
                          }
                          aria-describedby='helper-text'
                          onChange={(e) => amountChangeHandler(e, index, 1)}
                          disabled={index === 3}
                          startAdornment={
                            col.inputType === 'number' && (
                              <mui.InputAdornment position='start'>
                                {getCurrencySymbol(statutoryReportingAmount?.Currency)}
                              </mui.InputAdornment>
                            )
                          }
                        />
                      </mui.Grid>
                      <mui.Grid
                        item
                        sx={{ pl: '4px' }}>
                        <mui.OutlinedInput
                          sx={{ height: 35, width: 200 }}
                          id='outlined-adornment-amount'
                          type={col.inputType}
                          autoComplete='off'
                          onChange={(e) => amountChangeHandler(e, index, 2)}
                          value={
                            index === 3
                              ? roundOffValue(
                                parseFloat(complianceAmount.Net) +
                                parseFloat(complianceAmount.Vat),
                                complianceAmount.Net,
                                complianceAmount.Vat
                              )
                              : col.value[1]
                          }
                          startAdornment={
                            col.inputType === 'number' && (
                              <mui.InputAdornment position='start'>
                                {getCurrencySymbol(complianceAmount?.Currency)}
                              </mui.InputAdornment>
                            )
                          }
                          disabled={index === 3}
                        />
                      </mui.Grid>
                    </mui.Grid>
                  );
                })}
              </mui.Stack>
            </mui.Stack>
            <FileUploader
              fileSize='5MB'
              onChange={fileUploadHandler}
              errorMsg={uploadError}
            />
            <mui.Stack>
              <mui.Typography sx={typographyStyle('1rem', 700, theme.palette.baseColors.grey)}>
                Attachments
              </mui.Typography>
              <ListUploadedFiles
                uploadedFiles={attachments}
                fileDeleteHandler={fileDeleteHandler}
                sortFileDataHandler={sortFileDataHandler}
                sortColumn={sortColumn}
                attachmentNameSortOrder={attachmentNameSortOrder}
                attachmentTypeSortOrder={attachmentTypeSortOrder}
                extensionProcessingRequired={true}
              />
            </mui.Stack>
          </mui.Stack>
        </WhiteCard>
        <mui.Stack
          flexDirection='row'
          justifyContent='flex-end'
          gap='0.5rem'
          pb='2rem'>
          <BtnStandard
            btnTxt={'Cancel'}
            onClick={handleClose}
            disabled={loading}
            styling={{ height: '2.75rem', padding: '0.5rem 1.5rem', fontSize: '1rem' }}
          />
          <BtnFilled
            size='medium'
            btnTxt={'Create Transaction'}
            styling={{
              '&:disabled': {
                backgroundColor: theme.palette.primaryColors[200],
                color: theme.palette.secondaryColors[100],
              },
            }}
            disabled={loading}
            loading={loading}
            onClick={CreateTransactionHandler}
          />
        </mui.Stack>
        {cancel && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setCancel(false);
            }}
            continueHandler={() => {
              setCancel(false);
              setPrePopulateValue({});
              setPrePopulateValues({});
              setModalNo(-1);
            }}
          />
        )}
        {warningPopup && (
          <WarningPopup
            closeWarningPopupHandler={() => {
              setWarningPopup(false);
            }}
            continueHandler={() => {
              setWarningPopup(false);
            }}
            warningTitle={'Warning!'}
            warningMessage={
              <>
                <mui.Typography>Resolve conflicts in amount to match the criteria</mui.Typography>
                <mui.Typography>Net + Vat = Gross</mui.Typography>
                <mui.Typography>Recoverable + NonRecoverable = Vat</mui.Typography>
              </>
            }
          />
        )}
      </WhiteCard>
    </mui.Modal>
  );
};
