import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { LeftArrowIcon } from '../CustomIcons/LeftArrowIcon';
import { DownArrowIcon } from '../CustomIcons/DownArrowIcon';
import { OutlinedCircleIcon } from '../CustomIcons/Checklist/OutlinedCircleIcon';
import { CheckFilledIcon } from '../CustomIcons/Checklist/CheckFilledIcon';
import { BtnOutlined } from '../Buttons/BtnOutlined';
import dayjs from 'dayjs';
import { BtnFilled } from '../Buttons/BtnFilled';
import { SubmitChecklistConfirmation } from '../../../pages/Obligations/Checklist/SubmitCheklistConfirmation';

export const StagesAccordian = ({
  heading,
  status,
  isOpen,
  stageSwitchHandler,
  stage,
  checkboxes,
  enableSave,
  enableSendBack,
  checkboxHandler,
  saveHandler,
  sendbackHandler,
  isGroup,
  savedStage,
  setSavedStage,
  sentBackStage,
  setSentBackStage,
  completedStageData,
  clearStageCheckBoxesHandler,
}) => {
  const theme = useTheme();
  const checkedArr = checkboxes.map((c) => c.checked);
  const edited = checkboxes.map((c) => c.edited);
  const editedArr = checkboxes
    .filter((item) => item.edited)
    .map((item) => ({ id: item.step.id, option_selected: item.checked }));
  const [submitChecklist, setSubmitChecklist] = useState(false);
  return (
    <Box
      width='100%'
      sx={{
        borderRadius: '0.5rem',
        padding: '1.5rem',
        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
        backgroundColor: `${theme.palette.baseColors.white}`,
      }}>
      <>
        <Box
          flex={1}
          height='3.62rem'
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          onClick={() => {
            stageSwitchHandler(stage);
          }}>
          <Stack
            direction='row'
            alignItems='center'
            gap='0.5rem'>
            {!isOpen ? (
              <LeftArrowIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
            ) : (
              <DownArrowIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
            )}

            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 500,
                color: theme.palette.random[300],
              }}
              component='span'>
              {heading}
            </Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            gap='0.25rem'>
            {status === 'Done' ? (
              <CheckFilledIcon />
            ) : (
              <OutlinedCircleIcon
                sx={{
                  color:
                    status === 'Not Started'
                      ? theme.palette.secondaryColors[400]
                      : theme.palette.primaryColors[200],
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                color:
                  status === 'Done'
                    ? theme.palette.primaryColors[200]
                    : theme.palette.baseColors.grey,
              }}
              component='span'>
              {status}
            </Typography>
          </Stack>
        </Box>
        {isOpen && (
          <>
            <Box
              flex={1}
              p='1rem 0rem'>
              <Stack sx={{ gap: '1rem' }}>
                {checkboxes?.map((checkboxStep, index) => (
                  <Stack sx={{ gap: '0.3rem' }}>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        p: '0 0.5rem 0 2rem',
                      }}>
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 500,
                          color: theme.palette.baseColors.grey,
                        }}>
                        {checkboxStep?.step?.description}
                      </Typography>
                      <ToggleButtonGroup
                        disabled={checkboxStep.checkDisabled||savedStage === stage}
                        value={checkboxStep?.checked}
                        disableRipple
                        sx={{
                          height: '1.625rem',
                          '& .MuiToggleButton-root.Mui-selected': {
                            bgcolor: theme.palette.primaryColors[200],
                            color: theme.palette.baseColors.white,
                          },
                          '.MuiToggleButton-root:hover ': {
                            bgcolor: 'inherit',
                          },
                          '& .MuiToggleButton-root.Mui-selected:hover': {
                            bgcolor: theme.palette.primaryColors[200],
                            color: theme.palette.baseColors.white,
                          },
                        }}
                        exclusive
                        onChange={(event, newValue) => {
                          checkboxHandler(stage, index, newValue);
                        }}
                        aria-label='text alignment'>
                        <ToggleButton
                          value='YES'
                          sx={{}}
                          aria-label='left aligned'>
                          YES
                        </ToggleButton>
                        <ToggleButton
                          value='NA'
                          aria-label='centered'>
                          N/A
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Stack>
                    <Stack
                      direction='row'
                      gap='1.5rem'
                      sx={{ pl: '0.6rem' }}>
                      {checkboxStep?.step?.confirmed_by && (
                        <Stack direction='row'>
                          <Typography
                            sx={{
                              paddingLeft: '1.4rem',
                              fontSize: '0.75rem',
                              color: theme.palette.secondaryColors[300],
                            }}>
                            Confirmed by:{' '}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '0.75rem',
                              color: theme.palette.baseColors,
                              textTransform: 'capitalize',
                            }}>
                            {checkboxStep?.step?.user?.user_name}
                          </Typography>
                        </Stack>
                      )}
                      {checkboxStep?.step?.confirmed_date && (
                        <Stack direction='row'>
                          <Typography
                            sx={{
                              paddingLeft: '1.4rem',
                              fontSize: '0.75rem',
                              color: theme.palette.secondaryColors[300],
                            }}>
                            Date Confirmed:{' '}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '0.75rem',
                              color: theme.palette.baseColors,
                              textTransform: 'capitalize',
                            }}>
                            {dayjs(checkboxStep?.step?.confirmed_date)?.format('DD MMM YYYY')}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <Stack
              direction='row'
              justifyContent='flex-end'
              gap='1rem'>
              {enableSendBack && (
                <Stack alignItems='flex-end'>
                  <BtnOutlined
                    btnTxt='Send back'
                    onClick={() => {
                      setSentBackStage(stage);
                      let authority =
                        stage === 'Data Load'
                          ? 'data_load'
                          : stage === 'Prepare'
                          ? 'preparer'
                          : stage === 'Review'
                          ? 'reviewer'
                          : stage === 'Approve'
                          ? 'approver'
                          : null;
                      sendbackHandler(authority);
                    }}
                    loading={sentBackStage === stage}
                    disabled={sentBackStage === stage}
                    progressColor={theme.palette.baseColors.grey}
                  />
                </Stack>
              )}
              <Stack
                justifyContent='flex-end'
                flexDirection='row'>
                {enableSave && (
                  <BtnOutlined
                    btnTxt='Cancel'
                    onClick={() => {
                      clearStageCheckBoxesHandler(stage);
                    }}
                    disabled={savedStage === stage || !edited.includes(true)}
                    sx={{ marginRight: '0.5rem' }}
                  />
                )}
                {enableSave && (
                  <BtnOutlined
                    btnTxt='Save'
                    sx={{
                      color: theme.palette.primaryColors[200],
                      border: `1px solid ${theme.palette.primaryColors[200]}`,
                      '&:hover': {
                        backgroundColor: theme.palette.primaryColors[200],
                        borderColor: 'none',
                        color: theme.palette.baseColors.white,
                      },
                    }}
                    onClick={() => {
                      setSavedStage(stage);
                      let authority =
                        stage === 'Data Load'
                          ? 'data_load'
                          : stage === 'Prepare'
                          ? 'preparer'
                          : stage === 'Review'
                          ? 'reviewer'
                          : stage === 'Approve'
                          ? 'approver'
                          : null;
                      saveHandler(authority, checkedArr.includes(null), editedArr);
                    }}
                    loading={savedStage === stage}
                    disabled={savedStage === stage}
                    disabledStyling={{
                      backgroundColor: theme.palette.baseColors.white,
                      color: theme.palette.primaryColors[200],
                      border: `1px solid ${theme.palette.primaryColors[200]}`,
                    }}
                  />
                )}

                {isGroup && stage === 'Approve' && (
                  <BtnFilled
                    size='small'
                    btnTxt='Submit'
                    onClick={() => {
                      setSubmitChecklist(true);
                    }}
                    disabled={status !== 'Done' || !completedStageData.enableSave}
                    styling={{ marginLeft: '0.5rem' }}
                  />
                )}
                {submitChecklist && (
                  <SubmitChecklistConfirmation
                    closePopupHandler={() => {
                      setSubmitChecklist(false);
                    }}
                    saveHandler={saveHandler}
                    stage={stage}
                    loading={savedStage === stage}
                    setSavedStage={setSavedStage}
                  />
                )}
              </Stack>
            </Stack>
          </>
        )}
      </>
    </Box>
  );
};
