import { SvgIcon } from '@mui/material';

export const DownArrowIconColored = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='11'
        height='8'
        viewBox='0 0 11 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1.75L5.5 6.25L10 1.75'
          stroke='white'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};
