import { useLocation } from 'react-router-dom';
import { useObligation } from '../../../../context';
import { IframeScreen } from '../../../../components/common/Iframe/IframeScreen';
import { OPERATING_ANALYTICS_CARDS_DATA, getOperatingAnalyticsCardDescription } from '../constants';
import { Looker } from '../../../../components/common/Looker/Looker';
import { lookerEmbedDomain, lookerEntityCodeList } from '../../../../utils';
import { PageSkeleton } from '../PageSkeleton';
import { ExpandableLookerGrid } from '../../../../components/common/Looker/ExpandableLookerGrid';
import { ATTRIBUTION_CARD_INFO } from '../../lookerConstants';

export const Attribution = () => {
  const { isGroupSelected, sisterObligations, selectedObgDetails } = useObligation();

  return (
    <PageSkeleton
      title="Attribution"
      description={getOperatingAnalyticsCardDescription("Attribution")}
      url=".."
      isURLRelative
    >
      <ExpandableLookerGrid
        cards={ATTRIBUTION_CARD_INFO}
        dashboardParameters={{
          "Obligation ID": selectedObgDetails?.obligation_id,
          "Entitycode": lookerEntityCodeList(isGroupSelected, sisterObligations, selectedObgDetails)
        }}
        downloadConstants={{
          dashboard: process.env.REACT_APP_LOOKER_ATTRIBUTION,
          filename: `${selectedObgDetails?.obligation_name}_Attribution`,
          title: "Attribution"
        }}
      />
    </PageSkeleton>
  )
};
