import React from 'react';
import { WhiteCard } from '../WhiteCard';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { DatabaseIcon } from '../../../components/common/CustomIcons/DatabaseIcon';
import { CheckboxMultipleLineIcon } from '../../../components/common/CustomIcons/CheckboxMultipleLineIcon';
import { InconsistentCodingIcon } from '../../../components/common/CustomIcons/Analytics';
import { CheckCircleOutlinedIcon } from '../../../components/common/CustomIcons/CheckCircleOutlinedIcon';
import { LineChartLineIcon } from '../../../components/common/CustomIcons/LineChartLineIcon';
import { EditBoxLineIcon } from '../../../components/common/CustomIcons/EditBoxLineIcon';
import { InfoCard } from './InfoCard';

export const ObligationSummary = ({
  id,
  member,
  data,
  dataSummary,
  reconciliationSummary,
  adjustmentSummary,
  exceptionSummary,
  theme,
}) => {
  const matches1080pxTo1500px = useMediaQuery('(min-width:1080px) and (max-width:1500px)');
  const matchesBelow1080px = useMediaQuery('(max-width:1080px)');

  const gridTemplateColumns = matchesBelow1080px
    ? 'repeat(1, 1fr)'
    : matches1080pxTo1500px
    ? 'repeat(2, 1fr)'
    : 'repeat(3, 1fr)';

  return (
    <WhiteCard>
      <Typography
        component='h4'
        sx={{
          color: theme.palette.random[300],
          fontSize: '1.125rem',
          fontWeight: '600',
        }}>
        Obligation Summary
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: gridTemplateColumns,
          gap: 2,
        }}>
        <Box>
          <InfoCard
            title='Data Summary'
            data={dataSummary}
            link={{
              text: 'View',
              url: `/obligations/${id}/${member}/data`,
            }}>
            <DatabaseIcon sx={{ fontSize: '2rem' }} />
          </InfoCard>
        </Box>
        <Box>
          <InfoCard
            title='Reconciliations Summary'
            data={reconciliationSummary?.transactionanalytics}
            format={['variance']}
            link={{
              text: 'View',
              url: `/obligations/${id}/${member}/reconciliations`,
            }}>
            <CheckboxMultipleLineIcon sx={{ fontSize: '2rem' }} />
          </InfoCard>
        </Box>
        <Box>
          <InfoCard
            title='Exceptions Summary'
            data={exceptionSummary?.exceptionanalytics}
            link={{
              text: 'View',
              url: `/obligations/${id}/${member}/exceptions`,
            }}>
            <InconsistentCodingIcon sx={{ fontSize: '2rem' }} />
          </InfoCard>
        </Box>
        <Box>
          <InfoCard
            title='Analytics Summary'
            text='View obligation level analytics to drill down into specific areas of focus.'
            link={{
              text: 'View',
              url: `/obligations/${id}/${member}/analytics`,
            }}>
            <LineChartLineIcon sx={{ fontSize: '2rem' }} />
          </InfoCard>
        </Box>
        <Box>
          <InfoCard
            title='Adjustments Summary'
            data={adjustmentSummary?.adjustment}
            format={['input_vat_net', 'output_vat_net']}
            link={{
              text: 'View',
              url: `/obligations/${id}/${member}/adjustments`,
            }}>
            <EditBoxLineIcon sx={{ fontSize: '2rem' }} />
          </InfoCard>
        </Box>
        <Box>
          <InfoCard
            title='Checklist'
            text='Complete the checklist to update the progress of your obligation activities.'
            link={{
              text: 'View',
              url: `/obligations/${id}/${member}/checklist`,
            }}>
            <CheckCircleOutlinedIcon sx={{ fontSize: '2rem' }} />
          </InfoCard>
        </Box>
      </Box>
    </WhiteCard>
  );
};
