import { SvgIcon } from '@mui/material';
import React from 'react'

export const SignificantSupliersIcon = (props) => {
    return (
        <SvgIcon {...props}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1298_16950)">
        <path d="M16 18.6666V21.3333C13.8783 21.3333 11.8435 22.1761 10.3432 23.6764C8.8429 25.1767 8.00004 27.2115 8.00004 29.3333H5.33337C5.33337 26.5043 6.45718 23.7912 8.45757 21.7908C10.458 19.7904 13.1711 18.6666 16 18.6666ZM16 17.3333C11.58 17.3333 8.00004 13.7533 8.00004 9.33325C8.00004 4.91325 11.58 1.33325 16 1.33325C20.42 1.33325 24 4.91325 24 9.33325C24 13.7533 20.42 17.3333 16 17.3333ZM16 14.6666C18.9467 14.6666 21.3334 12.2799 21.3334 9.33325C21.3334 6.38659 18.9467 3.99992 16 3.99992C13.0534 3.99992 10.6667 6.38659 10.6667 9.33325C10.6667 12.2799 13.0534 14.6666 16 14.6666ZM24 28.6666L20.0814 30.7266L20.8294 26.3639L17.66 23.2733L22.0414 22.6359L24 18.6666L25.96 22.6359L30.34 23.2733L27.1707 26.3639L27.9174 30.7266L24 28.6666Z" fill="#474747"/>
        </g>
        <defs>
        <clipPath id="clip0_1298_16950">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        </SvgIcon>
    )
};
