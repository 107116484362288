import { Box, Stack, Typography, useTheme } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { LeftArrowIcon } from '../CustomIcons/LeftArrowIcon';
import { DownArrowIcon } from '../CustomIcons/DownArrowIcon';

export const AccordionPanel = forwardRef(({ heading, amount, children }, headerRef) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  useImperativeHandle(headerRef, () => ({
    open() { setOpen(true) }
  }))

  return (
    <Box
      width='100%'
      sx={{
        borderRadius: '0.5rem',
        padding: '0rem 2rem',
        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
        backgroundColor: `${theme.palette.baseColors.white}`
      }}>

      <Box
        flex={1}
        height='4.75rem'
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={() => { setOpen(prev => !prev) }}
        ref={headerRef}
      >

        <Stack direction='row' alignItems='center' gap='0.5rem'>

          {!open ?
            <LeftArrowIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
            : <DownArrowIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
          }

          <Typography
            sx={{
              fontSize: '1.125rem',
              fontWeight: 700,
              color: theme.palette.random[300]
          }}>
            {heading}
          </Typography>

        </Stack>

        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 500,
            color: theme.palette.baseColors.grey
          }}
          component='span'
        >
          {amount}
        </Typography>

      </Box>
      {open && <Box flex={1} p='1rem 0rem'>{children}</Box>}
    </Box>
  )
})
