import { useTheme } from "@mui/material";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BtnStandard } from "../BtnStandard";

export const BackButton = ({ backAddress, isBackAddressRelative }) => {
    const navigate = useNavigate()
    const theme = useTheme()
    
    const [searchParams] = useSearchParams()
    const urlBackAddress = useMemo(() => searchParams.get("backAddress"), [searchParams])

    return <BtnStandard
        btnTxt='Back'
        iconPosition='start'
        icon={<ArrowLeftIcon />}
        size='small'
        styling={{
            '&:hover': { backgroundColor: 'inherit' },
            color: theme.palette.random[300],
            paddingLeft: 0
        }}
        onClick={() => {
            navigate(
                backAddress ?? // By default, go back to the address passed through props.
                urlBackAddress ?? // Otherwise, try to extrack the back address from the URL query parameters.
                -1 // As a very last resort, just go back in the browser history.
                , isBackAddressRelative ? { relative: "path" } : undefined
            );
        }}
    />
}