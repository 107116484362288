import { createContext, useContext, useEffect, useState } from 'react';
import { getInitials } from '../../utils';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};
//todo: make api call to get this userdata

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    userName: '',
    userInitials: '',
    userEmail: '',
    pageSize: '10',
    userId: '',
    userToken: '',
    rolesByEntity: '',
  });

  const RefreshToken = (tok) => {
    setUserDetails((prev) => {
      let dt = { ...prev };
      dt.userToken = tok;
      return dt;
    });
  };
  // const { userId, userToken } = useUser();
  const pageSizeHandler = (page_size) => {
    setUserDetails((prev) => ({ ...prev, pageSize: page_size }));
    fetch(process.env.REACT_APP_BACKEND_URL + `/userpref/upsertuserpref`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('myToken')}`,
      },
      body: JSON.stringify({
        user_id: userDetails.userId,
        preference_key: 'page_size',
        preference_value: page_size.toString(),
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
      })
      .then((data) => {})
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  useEffect(() => {
    //return
    const token = localStorage.getItem('myToken');
    const userId = localStorage.getItem('myUserid');
    const audit_Id = localStorage.getItem('audit_id');
    if (userId) {
      fetch(process.env.REACT_APP_BACKEND_URL + `/user/getuser/${userId}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.status === 403) {
            fetch(
              process.env.REACT_APP_BACKEND_URL + `/auth/updateuseraudit?audit_id=${audit_Id}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: 'error', event_type: 'Logged_Out' }),
              }
            )
              .then((res) => {})
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                localStorage.clear();
                const ssoUrl = `${process.env.REACT_APP_SSO_BASE_URL}&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SSO_REDIRECT_URI}`;
                window.location.href = ssoUrl;
              });
          } else if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          const pagePref = Number(
            data?.user?.preferences?.find((obj) => obj.preference_key === 'page_size')
              ?.preference_value
          );
          const email = data?.user?.email_id;
          const name = data?.user?.user_name;
          const initials = getInitials(name);
          const policyAcceptance = data?.user?.policy_acceptance;

          var rolesByEntity = {};
          data?.user?.entity_roles?.forEach((entry) => {
            rolesByEntity[entry.entity_id] = [...entry.roles];
          });

          setUserDetails({
            userId: localStorage.getItem('myUserid'),
            userToken: localStorage.getItem('myToken'),
            RefreshToken: localStorage.getItem('myRefreshToken'),
            userName: name,
            userInitials: initials,
            userEmail: email,
            pageSize: pagePref ? pagePref : 10,
            policyAcceptance: policyAcceptance,
            rolesByEntity: rolesByEntity,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [userDetails.userToken]);

  return (
    <UserContext.Provider value={{ ...userDetails, pageSizeHandler, setUserDetails, RefreshToken }}>
      {children}
    </UserContext.Provider>
  );
};
