import React, { useEffect } from 'react'
import { PageSkeleton } from '../PageSkeleton'
import { Stats } from './Stats'
import { Top30NetTransactions } from './Top30NetTransactions'
import { Top30BearingTransactions } from './Top30BearingTransactions'
import { GENERAL_ANALYTICS_CARDS_DATA, OPERATING_ANALYTICS_CARDS_DATA } from '../constants'
import { useLocation, useParams } from 'react-router-dom'

export const HighValueSupplies = () => {
  const { id, member } = useParams()

  return (
    <PageSkeleton
      title='High Value Supplies'
      description={OPERATING_ANALYTICS_CARDS_DATA.find(obj => obj.title === 'High Value Supplies')?.description}
      url={`/obligations/${id}/${member}/analytics`}
    >
      <Stats />
      <Top30NetTransactions />
      <Top30BearingTransactions />
    </PageSkeleton>
  )
}
