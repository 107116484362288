import { InputBase, InputLabel, Stack, useTheme, Typography } from "@mui/material";
import { RedWarningOutlinedIcon } from "../CustomIcons/RedWarningOutlinedIcon";

export const TextArea = ({ id, placeholder, name, required, error, rows, width, hint, value, onChange, maxLength, disabled }) => {
    const theme = useTheme();
    return (
        <Stack component='form' gap='0.5rem' width={width}>
            <InputLabel
                htmlFor={id}
                sx={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    color: theme.palette.baseColors.grey,
                    width: 'max-content'
                }}
            >
                {name}&nbsp;
                {required && <Typography component="span"
                    sx={{
                        fontWeight: 400,
                        color: theme.palette.functional.error
                    }}
                >
                    (Required)
                </Typography>}
            </InputLabel>
            <InputBase
                component='textarea'
                id={id}
                placeholder={placeholder}
                sx={{
                    padding: '1rem',
                    borderRadius: '0.25rem',
                    border: error ? `1px solid ${theme.palette.functional.error}` : `1px solid ${theme.palette.random[500]}`,
                    color: theme.palette.baseColors.grey,
                    maxHeight: '175px',
                    overflow: 'auto' // make it scrollable if content exceeds max height to ensure text area doesn't stretch its parents height
                }}
                inputProps={{
                    maxLength: maxLength,
                    sx: {
                        '&::placeholder': {
                            color: theme.palette.secondaryColors[400],
                            opacity: 1
                        }
                    }
                }}
                multiline={true}
                rows={rows}
                spellCheck='false'
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
            {hint && <Typography variant="body2" color={theme.palette.baseColors.grey}>
                {hint}
            </Typography>}
            {error && <Stack direction='row' alignItems='center' gap='0.25rem'>
                <RedWarningOutlinedIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
                <Typography variant="body2" color={theme.palette.functional.error}>
                    {error}
                </Typography>
            </Stack>}
        </Stack>
    )
}
