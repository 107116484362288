import React, { useEffect, useState } from 'react';
import { useTheme, Box } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useObligation, useUser } from '../../../../../context';
import { PageSkeleton } from '../PageSkeleton';
import { ParentAccordion } from '../Accordion';
import { UnresolvedExceptionListsTable } from './UnresolvedExceptionListsTable';
import { BtnOutlined } from '../../../../../components/common/Buttons/BtnOutlined';
import { formatDate, reverseDate } from '../../../../../utils';

export const UnresolvedExceptionLists = () => {
  const theme = useTheme();
  const { userToken } = useUser();
  const { isObligationLocked } = useObligation();
  const [unresolvedData, setUnresolvedData] = useState([]);
  const [resolvedData, setResolvedData] = useState([]);
  const [searchParams] = useSearchParams();
  const [selectedUnresolvedRows, setSelectedUnresolvedRows] = useState([]);
  const [selectedResolvedRows, setSelectedResolvedRows] = useState([]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const transactionId = searchParams.get('id');
  const entityCode = searchParams.get('code');
  const backAddress = searchParams.get('backAddress');
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [Loader, setLoader] = useState(true);
  const { id } = useParams();

  const HeaderButtons = ({ multipleSelected, disabled }) => (
    <Box>
      <BtnOutlined
        size='medium'
        btnTxt={'Resolve Exceptions'}
        disabled={!multipleSelected || isObligationLocked}
        onClick={() =>
          navigate(
            `${backAddress}&resolveException=[${encodeURIComponent(selectedUnresolvedRows)}]`
          )
        }
        disabledStyling={{
          border: 'solid 1px',
          bgcolor: theme.palette.baseColors.white,
          cursor: 'initial',
        }}
        sx={{
          width: 104,
          height: 32,
          color: theme.palette.primaryColors[200],
          borderColor: theme.palette.primaryColors[200],
          mr: 1,
        }}
      />
      <BtnOutlined
        size='medium'
        disabled={!multipleSelected || isObligationLocked}
        onClick={() => setAcceptModal(true)}
        disabledStyling={{
          border: 'solid 1px',
          bgcolor: theme.palette.baseColors.white,
          cursor: 'initial',
        }}
        sx={{
          width: 104,
          height: 32,
          color: theme.palette.primaryColors[200],
          borderColor: theme.palette.primaryColors[200],
        }}
        btnTxt={'Accept Exceptions'}
      />
    </Box>
  );

  useEffect(() => {
    if (userToken && transactionId && entityCode) {
      setLoader(true);
      const url =
        process.env.REACT_APP_BACKEND_URL +
        `/transaction/listall?filter_query=obligation_id__eq=${id}%26id__eq=${transactionId}%26entitycode__eq=${entityCode}`;
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error('Something went wrong');
        })
        .then((data) => {
          setFormData(data.transactions[0]);
        })
        .catch((err) => { })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [transactionId, entityCode]);

  const [reload, setReload] = useState(false);

  const pageTitle = [
    formData?.compliancecounterpartycompanyname,
    formData?.invoiceno,
    reverseDate(formatDate(formData?.invoicedate, '-'), ' '),
  ]
    .filter((p) => p)
    .join(' - ');

  return (
    !Loader && (
      <PageSkeleton
        title={pageTitle ?? ''}
        description=' '
        hasNavMenu={false}
        url={backAddress}>
        <ParentAccordion
          title={`Unresolved ${unresolvedData?.length ? `(${unresolvedData.length})` : ''}`}
          theme={theme}
          button={
            <HeaderButtons
              multipleSelected={selectedUnresolvedRows?.length > 1}
              disabled={selectedUnresolvedRows?.length === 0}
            />
          }
          expandOnRender={unresolvedData?.length > 0}
          styles={{ width: '100%' }}
          selectedRows={selectedUnresolvedRows}>
          <UnresolvedExceptionListsTable
            tableType='unresolved'
            reload={reload}
            setReload={setReload}
            transactionId={transactionId}
            resolveUrl={backAddress}
            selectedUnresolvedRows={selectedUnresolvedRows}
            setSelectedUnresolvedRows={setSelectedUnresolvedRows}
            acceptModal={acceptModal}
            setAcceptModal={setAcceptModal}
            setUnresolvedData={setUnresolvedData}
          />
        </ParentAccordion>
        <ParentAccordion
          title={`Resolved ${resolvedData?.length ? `(${resolvedData.length})` : ''}`}
          theme={theme}
          expandOnRender={resolvedData?.length > 0}
          styles={{ width: '100%' }}>
          <UnresolvedExceptionListsTable
            tableType='resolved'
            reload={reload}
            setReload={setReload}
            transactionId={transactionId}
            selectedResolvedRows={selectedResolvedRows}
            setSelectedResolvedRows={setSelectedResolvedRows}
            reasonModal={reasonModal}
            setReasonModal={setReasonModal}
            setResolvedData={setResolvedData}
          />
        </ParentAccordion>
      </PageSkeleton>
    )
  );
};
