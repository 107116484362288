
// These Cards will be used as 2 top left cards in Home Screen.ie. Global Tax Management Dashboards and Obligations 
import { Button, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CardTypeA = (props) => {
  const theme = useTheme();
  const Navigate = useNavigate()
  return (
    <Stack sx={{ maxHeight: "auto", maxWidth: "100%", border: `1px solid ${theme.palette.neutralColors.black[200]} `, mt: "0vh", backgroundColor: theme.palette.baseColors.white, borderRadius: "0.5rem", padding: "1rem 1.5rem 1rem 1.5rem ", flex: "1 1 0" }}>
      <h3 style={{ marginTop: "0", marginBottom: "1vh", fontSize: "112.5%", color: theme.palette.baseColors.grey }}>{props.heading}</h3>
      <Typography sx={{ fontSize: "87.5%", color: theme.palette.random[200] }}>{props.content}</Typography>
      <Button disableRipple disableTouchRipple disableFocusRipple onClick={() => {
        Navigate(props.linkTo)
      }} variant="text" sx={{
        mt: "1vh", width: "fit-content", padding: "0",
        color: theme.palette.primaryColors[200], fontWeight: "500", fontSize: "87.5%", textTransform: "none", "&:hover": {

          backgroundColor: "transparent"
        },
      }}>{props.linkLabel}</Button>
    </Stack>
  )
}

export default CardTypeA