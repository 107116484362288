export const formatAsCurrency = (number, currencyCode) => {
  // Use 'narrowSymbol' to get the symbol only, if supported
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode ?? "USD",
    currencyDisplay: "narrowSymbol",
  }).format(number);

  // Ensure there is a space between the currency symbol and the number
  // This is achieved by inserting a space between any non-digit character(s) at the start and the first digit
  return formatted.replace(/(\D+)(\d)/, "$1 $2");
};

export const getCurrencySymbol = (currencyCode) => {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode && currencyCode.length === 3 ? currencyCode : "USD",
    currencyDisplay: "narrowSymbol",
  }).format(0);

  // Remove digits and extra characters, leaving only the currency symbol
  const symbol = formatted.replace(/[\d.,\s]/g, "").trim();

  return symbol;
};

export const formatNumberWithCommas = (number) =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(number);
