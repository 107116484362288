import { Stack } from '@mui/material';

import { TrialBalanceReconciliations } from './TrialBalanceReconciliations';
import { GeneralLedgerReconciliations } from './GeneralLedgerReconciliations';
import { AdjustmentForReversalList } from './AdjustmentForReversalList';
import { ListNotes } from '../Notes/ListNotes';
import { useNavigate } from 'react-router-dom';

export const typographyStyle = (size, weight, color, styles) => {
  const sx = {
    fontSize: size,
    fontWeight: weight,
    color: color,
    ...styles,
  };
  return sx;
};

export const Reconciliations = () => {
  return (
    <Stack gap={3}>
      <TrialBalanceReconciliations />
      <GeneralLedgerReconciliations />
      <AdjustmentForReversalList />
      <ListNotes />
    </Stack>
  );
};

export const fetchData = (navigate, userToken, url, dataSetter, toastMsg, setToast, setLoading) => {
  let _summaryDetails = {};
  fetch(url, {
    method: 'GET',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
      Authorization: `Bearer ${userToken}`,
    },
    credentials: 'include',
  })
    .then((res) => {

      if (res.ok) {
        return res.json();
      }
      throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
    })
    .then((data) => {
      _summaryDetails = data;
    })
    .catch((err) => {
      setToast({
        type: 'failure',
        message: `${toastMsg} related data could not be fetched.`,
      });
    })
    .finally(() => {
      dataSetter(_summaryDetails);
      if (setLoading) setLoading(false);
    });
  return;
};

export const reconciliationBaseUrl = (obligation, filterQuery) => {
  if (filterQuery) {
    if (obligation.member === obligation.obligation_group_name)
      return `obligation_id__eq=${obligation.obligation_id}`;
    else
      return `obligation_id__eq=${obligation.obligation_id}%26entity__contains=${obligation.org_heirarchy_code}`;
  } else {
    if (obligation.member === obligation.obligation_group_name) return `obligation_id=${obligation.obligation_id}`;
    else
      return `obligation_id=${obligation.obligation_id}&entitycode=${obligation.org_heirarchy_code}`;
  }
};
