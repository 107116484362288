import { Box, Stack, TableCell, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { BtnStandard } from '../../../../components/common/Buttons/BtnStandard';
import { WhiteCard } from '../../WhiteCard';
import { ArrowLeftIcon } from '../../../../components/common/CustomIcons/ArrowLeftIcon';
import TextTag from '../../../../components/common/Text Tags/TextTag';
import { IndividualTestTransactionFilters } from './IndividualTestTransactionFilters';
import { PaginatedTable } from '../../../../components/Paginated Table/PaginatedTable';
import { LoadingView } from './LoadingView';
import {
  conditionalEntityCodeDefaultQuery,
  currencyFormatterWithoutSymbol,
  formatDate,
  getCurrentRelativePath,
  nullableInvoiceNumber,
  reverseDate,
} from '../../../../utils';
import { useObligation } from '../../../../context';
import { BackButton } from '../../../../components/common/Buttons/Utility/BackButton';
import { SelectableRowsTable } from '../TransactionDetail/ResolveDrilldown/SelectableRowsTable';
import { BtnOutlined } from '../../../../components/common/Buttons/BtnOutlined';
import { TransactionAcceptModal } from './TransactionAcceptModal';
import { Toast } from '../../Toast';

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  height: '3rem',
}));

export const IndividualTestTransactionTable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id, member, subTab } = useParams();
  const { selectedObgDetails: obligation, isObligationLocked } = useObligation();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [initialSearchParams, setInitialSearchParams] = useState({});
  const [selectedTransactionRows, setSelectedTransactionRows] = useState([]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [entityLoader, setEntityLoader] = useState(true);
  const [toast, setToast] = useState();
  const [reload, setReload] = useState(false);

  const COLUMNS = [
    { label: 'Inv No.', property: 'invoiceno' },
    { label: 'Entity', property: 'entityname' },
    { label: 'Statutory Currency', property: 'statcurrencycode' },
    { label: 'Gross', property: 'statgrossamount' },
    { label: 'Net', property: 'statnetamount' },
    { label: 'VAT', property: 'statvatamount' },
    { label: 'Inv Date', property: 'invoicedate' },
    { label: 'Posting Date', property: 'postingdate' },
    { label: 'Tax Code', property: 'taxcode' },
    { label: 'Test Result', property: 'Test_Result' },
  ];

  const [filters, setFilters] = useState({
    Test_Result: {
      field: {
        column_name: 'Test Result',
        mapping: 'Test_Result',
        type: 'text',
      },
      condition: {
        label: 'Equals to',
        operator: 'eq',
      },
      queryValue: undefined,
      displayValue: undefined,
    },
    entitycode: {
      field: {
        column_name: 'Entity Code',
        mapping: 'entitycode',
        type: 'text',
      },
      condition: {
        label: 'Equals to',
        operator: 'eq',
      },
      queryValue: undefined,
      displayValue: undefined,
    },
  });

  useEffect(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setInitialSearchParams({ ...params });
  }, []);

  useEffect(() => {
    const testResult = searchParams.get('testResult');

    if (isLoading && testResult && !filters.Test_Result.queryValue) {
      const newFilters = { ...filters };
      newFilters['Test_Result'].queryValue = testResult;
      newFilters['Test_Result'].displayValue = testResult.toUpperCase();
      setFilters({ ...newFilters });
    }
  }, [filters, searchParams, isLoading]);

  useEffect(() => initialSearchParams?.ruleId && setIsLoading(false), [initialSearchParams]);

  useEffect(() => {
    setEntityLoader(true);
    if (obligation?.org_heirarchy_code !== obligation.obligation_group_id) {
      const newFilters = { ...filters };
      newFilters['entitycode'].queryValue = obligation?.org_heirarchy_code;
      newFilters['entitycode'].displayValue = member;
      setFilters({ ...newFilters });
      setEntityLoader(false);
    } else if (obligation?.obligation_id) {
      setEntityLoader(false);
    }
  }, [member, obligation]);

  const handleResolveStatus = (type, message) => {
    setToast({
      type: type,
      message: message,
    });
    setSelectedTransactionRows([]);
    setReload(!reload);
  };

  const handleFilterChange = (name, value, clearAll, displayValue) => {
    const newFilters = { ...filters };
    if (clearAll) {
      Object.keys(newFilters).forEach((key) => {
        newFilters[key].queryValue = undefined;
        newFilters[key].displayValue = undefined;
      });
    } else {
      if (!filters[name]) return console.error('Unknown filter! ' + name);

      newFilters[name].queryValue = value;
      newFilters[name].displayValue = displayValue ?? value;
    }
    setFilters({ ...newFilters });
  };

  const customFilters = Object.values(filters).filter((filter) => filter?.queryValue !== undefined);

  return (
    <>
      <Stack
        gap={'24px'}
        sx={{ py: '24px' }}>
        <WhiteCard style={{ mx: '2rem', p: '24px 24px 24px 24px' }}>
          <Stack
            sx={{
              alignItems: 'flex-start',
            }}>
            <BackButton backAddress={`/obligations/${id}/${member}/exceptions/${subTab}`} />
            <Typography
              sx={{
                fontSize: '2.25rem',
                fontWeight: 700,
                color: theme.palette.baseColors.grey,
              }}>
              {initialSearchParams?.description}
            </Typography>
          </Stack>
          {isLoading || entityLoader ? (
            <LoadingView />
          ) : (
            <SelectableRowsTable
              dummyData={[]}
              api={{
                endpoint: '/exception/exception_rule',
                records: 'exception_summary',
                count: 'count',
              }}
              columns={COLUMNS}
              defaultColumn='id'
              customFilters={customFilters}
              isSelectable={true}
              selectedRows={selectedTransactionRows}
              setSelectedRows={setSelectedTransactionRows}
              reload={reload}
              // source__eq:
              // Purchase Tax Exceptions = "AP"
              // Supply Tax Exceptions = "AR"
              filterInfo={{
                componentWithData: IndividualTestTransactionFilters,
                componentProps: {
                  selectedTransactionRows,
                  isObligationLocked,
                  setAcceptModal,
                },
                default: [`obligation_id__eq=${id}`, `rule_id__eq=${initialSearchParams?.ruleId}`],
              }}
              a11y={{
                tableAriaLabel: 'individual test list',
                rowPrefix: 'individual test',
              }}
              disableCheckbox={true}
              checkboxSelectAll={(rows) => {
                return rows.filter((row) => row.Test_Result === 'UNRESOLVED');
              }}
              checkBoxDisable={(transaction) => {
                return transaction.Test_Result !== 'UNRESOLVED';
              }}
              rowItem={(transaction) => [
                <StyledTableBodyCell>
                  <Typography
                    onClick={() =>
                      navigate(
                        `/obligations/${id}/${member}/individual-transaction?id=${
                          transaction.id
                        }&code=${transaction.entitycode}&ruleId=${
                          initialSearchParams?.ruleId
                        }&description=${encodeURIComponent(
                          initialSearchParams?.description
                        )}&testResult=${
                          initialSearchParams?.testResult
                        }&backAddress=${getCurrentRelativePath()}`
                      )
                    }
                    sx={{
                      display: 'inline-block',
                      fontSize: '0.875rem',
                      color: theme.palette.functional.tableLink,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}>
                    {nullableInvoiceNumber(transaction.invoiceno, transaction.erpdocumentno)}
                  </Typography>
                </StyledTableBodyCell>,
                <StyledTableBodyCell>{transaction.entityname}</StyledTableBodyCell>,
                <StyledTableBodyCell>{transaction.statcurrencycode}</StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {/* {formatAsCurrency(transaction.net, transaction.currency_code)} */}
                  {currencyFormatterWithoutSymbol(transaction.statgrossamount, false, {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {/* {formatAsCurrency(transaction.net, transaction.currency_code)} */}
                  {currencyFormatterWithoutSymbol(transaction.statnetamount, false, {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {/* {formatAsCurrency(transaction.vat, transaction.currency_code)} */}
                  {currencyFormatterWithoutSymbol(transaction.statvatamount, false, {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {formatDate(reverseDate(transaction.invoicedate, '-'), '-')}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>
                  {formatDate(reverseDate(transaction.postingdate, '-'), '-')}
                </StyledTableBodyCell>,
                <StyledTableBodyCell>{transaction.taxcode}</StyledTableBodyCell>,
                <StyledTableBodyCell>
                  <TextTag
                    text={transaction.Test_Result}
                    variant='orange'
                    size='m'
                    style={{
                      color: theme.palette.baseColors.white,
                      backgroundColor: theme.palette.graph.accentSecondary,
                    }}
                  />
                </StyledTableBodyCell>,
              ]}
            />
          )}
        </WhiteCard>
      </Stack>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
      {acceptModal && (
        <TransactionAcceptModal
          setOpen={setAcceptModal}
          ruleId={initialSearchParams.ruleId}
          handleResolveStatus={handleResolveStatus}
          selectedRows={selectedTransactionRows}
        />
      )}
    </>
  );
};
