import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import TextTag from '../../components/common/Text Tags/TextTag';
import { getDaysDifference, formatDate } from '../../utils';
import { SortIcon } from '../../components/common/CustomIcons/SortIcon';
import { useObligation, useUser } from '../../context';
import { SortAscIcon } from '../../components/common/CustomIcons/SortAscIcon';
import { SortDescIcon } from '../../components/common/CustomIcons/SortDescIcon';
import { Toast } from './Toast';
import Paginator from '../../components/common/Paginator/Paginator';
import { Filter } from '../../components/common/Filters/Filter';
import { FilterContext } from './FilterContext';

import { DUMMY_OBLIGATIONS_DATA } from './dummyData';
import { StyledLinearProgress } from '../../components/common/Progress/StyledLinearProgress';

// STYLING
const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));
const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.25rem 0.5rem',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.baseColors.grey,
  height: '3rem',
}));

export const ObligationTable = () => {
  const COLUMNS = [
    { label: 'Group', property: 'member', cellWidth: 12 },
    { label: 'Obligation Name', property: 'obligation_name', cellWidth: 12 },
    { label: 'Period', property: 'start_date', cellWidth: 15 },
    { label: 'Tax Type', property: 'tax_type', cellWidth: 7 },
    { label: 'Region', property: 'region', cellWidth: 7 },
    { label: 'Country', property: 'country', cellWidth: 8 },
    { label: 'Stage', property: 'stage', cellWidth: -1 },
    { label: 'Due Date', property: 'due_date', cellWidth: -1 },
    { label: 'Status', property: 'obligation_status', cellWidth: 11 },
  ];

  // QUERY DATA
  const [obligations, setObligations] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null);

  // SORTING AND PAGINATION
  const [sortedColumn, setSortedColumn] = useState('obligation_name');
  const [sortingOrder, setSortingOrder] = useState('asc');
  const { pageSize } = useUser();
  const [pageNo, setPageNo] = useState(1);

  // FILTERING
  const { filters, setIsTableLoading } = useContext(FilterContext);

  // UTILITY
  const { userToken } = useUser();
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState({ type: '', message: '' });
  const navigate = useNavigate();
  const theme = useTheme();

  const getSortedDataHandler = (col_name) => {
    if (col_name === sortedColumn) {
      setSortingOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortedColumn(col_name);
      // Due Date has the special case of starting to sort in Descending Order FIRST.
      setSortingOrder(col_name === 'due_date' ? 'desc' : 'asc');
    }
  };

  const pageNoChangeHandler = (new_page_no) => {
    setPageNo(new_page_no);
  };

  useEffect(() => setPageNo(1), [filters]);

  useEffect(() => setIsTableLoading(loading), [loading]);

  // Get the Obligation data from the database. Pagination + sorting is done on the backend.
  useEffect(() => {
    // Toggle between !userToken and userToken when testing locally and deploying.
    if (userToken) {
      // FILTER QUERY CONSTRUCTION
      //DD-MM-YYYY => YYYY-MM-DD
      const formatDate = (date) =>
        `${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}`;
      const fieldExists = (record) =>
        filters[record] !== undefined && filters[record] !== null && filters[record].length !== 0;

      const filterQueryParts = [
        fieldExists('dueDate')
          ? 'due_date__between=' +
          formatDate(filters.dueDate[0]) +
          ',' +
          formatDate(filters.dueDate[1]) ?? formatDate(filters.dueDate[0])
          : null,
        fieldExists('selectedRegions') ? 'region__in_=' + filters.selectedRegions.join() : null,
        fieldExists('selectedCountries')
          ? 'country__in_=' + filters.selectedCountries.join()
          : null,
        fieldExists('tax') ? 'tax_type__in_=' + filters.tax.join() : null,
        fieldExists('obligationgroup')
          ? 'obligation_group_name__in_=' + filters.obligationgroup.join()
          : null,
        fieldExists('member') ? 'member__in_=' + filters.member.join() : null,
        fieldExists('status') &&
          filters.status.filter((s) => s.toLowerCase() !== 'new data').length !== 0
          ? 'obligation_status__in_=' +
          filters.status
            .filter((s) => s.toLowerCase() !== 'new data')
            .map((s) =>
              s === 'Due this week'
                ? 'Due within 7 days'
                : s === 'Due next week'
                  ? 'Due within 14 days'
                  : 'OVERDUE'
            )
            .join()
          : null,
        fieldExists('status') &&
          filters.status.find((s) => s.toLowerCase() === 'new data') !== undefined
          ? 'new_data__eq=true'
          : null,
        // TAB SPECIFIC QUERIES
        fieldExists('_currentTab') && filters['_currentTab'] === 'approved'
          ? 'stage__eq=Approve'
          : null,
      ].filter((p) => p); // Only get the non-nulls
      const filterQuery = filterQueryParts.join('%26');
      let url = '';

      setLoading(true);

      // If current tab is "open", then we have a special endpoint.
      let baseurl;
      if (filters['_currentTab'] === 'open') {
        baseurl = process.env.REACT_APP_BACKEND_URL + `/obligationopensummary/listall`;
      } else baseurl = process.env.REACT_APP_BACKEND_URL + `/obligationsummary/listall`;

      url =
        baseurl +
        `?sort_column=${sortedColumn}&sort_dir=${sortingOrder}&limit=${pageSize}&page=${pageNo}`;

      if (filterQueryParts.length > 0)
        url +=
          filters['_currentTab'] === 'open'
            ? '&filter_query=' + filterQuery
            : '&filter_query=differentiator__eq=group%26' + filterQuery;
      else url += filters['_currentTab'] === 'open' ? '' : '&filter_query=differentiator__eq=group';

      let _obligations;
      let _count;
      fetch(url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
        })
        .then((data) => {
          _obligations = data?.summary;
          _count = data?.count;
        })
        .catch((err) => {
          _count = 0;
          console.error(err);
          setToast({
            type: 'failure',
            message: 'Data could not be sorted.',
          });
        })
        .finally(() => {
          setObligations(_obligations);
          setTotalRecords(_count);

          setLoading(false);
        });
    }
  }, [sortingOrder, sortedColumn, userToken, pageNo, pageSize, filters]);
  return (
    <>
      <Filter />
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          overflow: 'hidden',
        }}>
        <Box
          sx={{
            overflowX: 'scroll !important',
          }}>
          <Table
            aria-label='obligations list'
            sx={{
              borderRadius: '0.5rem',
              border: `1px solid ${theme.palette.neutralColors.black[200]}`,
            }}>
            <TableHead
              sx={{
                backgroundColor: theme.palette.neutralColors.black[100],
                height: '2.5rem',
              }}>
              <TableRow variant='body2'>
                {/* Automatically generate the header cells. Width values calculated as % of 1296px */}
                {COLUMNS.map((column) => (
                  <StyledTableHeadingCell
                    key={column.property}
                    style={column.cellWidth !== -1 ? { maxWidth: column.cellWidth + '%' } : {}}
                    onClick={() => {
                      getSortedDataHandler(column.property);
                    }}>
                    {column.label}
                    <IconButton disableRipple>
                      {sortedColumn === column.property ? (
                        sortingOrder === 'asc' ? (
                          <SortDescIcon />
                        ) : (
                          <SortAscIcon />
                        )
                      ) : (
                        <SortIcon />
                      )}
                    </IconButton>
                  </StyledTableHeadingCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={COLUMNS.length}
                    sx={{ p: 0 }}>
                    <StyledLinearProgress />
                  </TableCell>
                </TableRow>
              )}
              {obligations?.length > 0 ? (
                obligations?.map((obligation, index) => {
                  const goToObligation = () => {
                    navigate(
                      `/obligations/${obligation['obligation_id']}/${obligation['member']}/overview`
                    );
                  };
                  const daysUntilDue = getDaysDifference(
                    new Date(),
                    new Date(obligation['due_date'])
                  );
                  const transformDate = (date) => date.split('-').reverse().join('-');

                  // Generate obligation row.
                  return (
                    <TableRow
                      key={`entity:${obligation['member']}-obligation:${obligation['obligation_name']}${index}`}
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <StyledTableBodyCell>
                        <Typography
                          onClick={goToObligation}
                          sx={{
                            display: 'inline-block',
                            fontSize: '0.875rem',
                            color: theme.palette.functional.tableLink,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}>
                          {obligation['member']}
                        </Typography>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>{obligation['obligation_name']}</StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <Stack
                          direction='row'
                          gap={0.25}
                          alignItems='center'>
                          {formatDate(transformDate(obligation['start_date']), '-')}
                          <ArrowRightIcon />
                          {formatDate(transformDate(obligation['end_date']), '-')}
                        </Stack>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>{obligation['tax_type']}</StyledTableBodyCell>
                      <StyledTableBodyCell>{obligation['region']}</StyledTableBodyCell>
                      <StyledTableBodyCell>{obligation['country']}</StyledTableBodyCell>
                      <StyledTableBodyCell>{obligation['stage']}</StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {formatDate(transformDate(obligation['due_date']), '-')}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <Stack direction='column'>
                          {['OVERDUE', 'Due within 7 days', 'Due within 14 days'].includes(
                            obligation['obligation_status']
                          ) && (
                              <TextTag
                                text={
                                  obligation['obligation_status'] === 'OVERDUE'
                                    ? `OVERDUE • ${-daysUntilDue}D`
                                    : obligation['obligation_status']
                                }
                                variant={
                                  obligation['obligation_status'] === 'OVERDUE' ? 'red' : 'yellow'
                                }
                                size='xs'
                              />
                            )}
                          {obligation['new_data'] === true && (
                            <TextTag
                              text='New Data'
                              variant='grey'
                              size='xs'
                            />
                          )}
                          {['Completed', 'Finalized'].includes(obligation['stage']) && (
                            <TextTag
                              text={obligation['stage']}
                              variant='green'
                              size='xs'
                            />
                          )}
                        </Stack>
                      </StyledTableBodyCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  sx={{
                    height: '3rem',
                    border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                  }}>
                  <TableCell
                    colSpan={COLUMNS.length}
                    sx={{ textAlign: 'center' }}>
                    {loading ? 'Fetching data...' : 'No data found'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <Box mt='-1.5rem'>
        <Paginator
          totalRecords={totalRecords}
          pageNoChangeHandler={pageNoChangeHandler}
          pageNo={pageNo}
          disabled={loading}
        />
      </Box>
      {toast?.message && (
        <Toast
          closeAlertHandler={() => {
            setToast({
              type: '',
              message: '',
            });
          }}
          toast={toast}
        />
      )}
    </>
  );
};
