import {
  Stack,
  Typography,
  useTheme,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableContainer,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { WhiteCard } from '../WhiteCard';
import { conditionalEntityCodeQuery, formatDate } from '../../../utils';
import { DownloadIcon } from '../../../components/common/CustomIcons/DownloadIcon';
import { BtnFilled } from '../../../components/common/Buttons/BtnFilled';
import { useEffect, useState, useMemo } from 'react';
import { ListNotes } from '../Notes/ListNotes';
import { LodgingModal } from './LodgingModal';
import { useObligation, useUser } from '../../../context';
import { DownloadLink } from '../../../components/common/Buttons/Utility/DownloadLink';
import { Toast } from '../Toast';
import { StyledLinearProgress } from '../../../components/common/Progress/StyledLinearProgress';
import { useNavigate, useParams } from 'react-router-dom';

// STYLING
const StyledTableHeadingCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondaryColors[600],
  fontWeight: 700,
  fontSize: '0.875rem',
  padding: '0.75rem 1rem',
  whiteSpace: 'nowrap',
}));
const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '0.75rem 1rem',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: theme.palette.random[1300],
  height: '3rem',
}));

export const Export = () => {
  const theme = useTheme();

  const [isLodgingModalOpen, setIsLodgingModalOpen] = useState(false);
  const [isLodged, setIsLodged] = useState(false);
  const [show, setShow] = useState(false);
  const { userToken } = useUser();
  const { selectedObgDetails: obligation } = useObligation();
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState({ type: '', message: '' });

  const [summaryDownload, setSummaryDownload] = useState(null);
  const [adjustmentDownload, setAdjustmentDownload] = useState(null);
  const { tab } = useParams();
  const navigate = useNavigate();
  const todaysDate = useMemo(() => {
    const now = new Date();
    return `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }, []);
  const date = new Date();
  const currentDate = formatDate(
    `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
    '/'
  );

  useEffect(() => {
    setIsLodged(obligation.stage === 'Finalized');
  }, [obligation.stage]);

  useEffect(() => {
    if (obligation.differentiator === 'member' && tab === 'export') {
      navigate(`/obligations/${obligation.obligation_id}/${obligation.member}/overview`);
    } else if (!userToken || !obligation || !obligation?.obligation_id) return;
    setShow(true);
    setIsLoading(true);

    const entityQueryPart = conditionalEntityCodeQuery(obligation);
    const summaryURL =
      process.env.REACT_APP_BACKEND_URL +
      `/export/download?obligation_id=${obligation.obligation_id}&filename=summary_of_tax_code_${todaysDate}.xls${entityQueryPart}`;
    const adjustmentURL =
      process.env.REACT_APP_BACKEND_URL +
      `/export/download?obligation_id=${obligation.obligation_id}&filename=Adjustment_export.xls${entityQueryPart}`;

    // We all requests into an array, then we resolve them, and we see what happens after all of them with Promise.all
    const fetchArray = [
      {
        url: summaryURL,
        setter: setSummaryDownload,
      },
      {
        url: adjustmentURL,
        setter: setAdjustmentDownload,
      },
    ].map((request) => {
      fetch(request.url, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          const blob = res.blob(); // We have to consume this first because it's a stream.

          if (!res.ok || !blob)
            throw new Error(`Something went wrong. Status code: ${res.statusCode}`);
          return blob;
        })
        .then((blob) => window.URL.createObjectURL(blob))
        .then((urlObject) => request.setter(urlObject))
        .catch((err) => {
          console.error(err);
        });
    });
    Promise.all(fetchArray).finally(() => {
      setIsLoading(false);
    });

    return () => {
      if (summaryDownload) window.URL.revokeObjectURL(summaryDownload);
      if (adjustmentDownload) window.URL.revokeObjectURL(adjustmentDownload);
    };
  }, [userToken, obligation, todaysDate]);

  return (
    show && (
      <Stack spacing='2.5rem'>
        <WhiteCard>
          <Typography
            sx={{
              color: theme.palette.random[300],
              fontWeight: 700,
              fontSize: '1.125rem',
            }}>
            Export Files
          </Typography>

          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
              borderRadius: '.5rem',
            }}>
            <Box
              sx={{
                overflowX: 'scroll !important',
              }}>
              <Table
                aria-label='Exportable files list'
                sx={{
                  borderRadius: '0.5rem',
                  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                }}>
                <TableHead
                  sx={{
                    backgroundColor: theme.palette.neutralColors.black[100],
                    height: '2.5rem',
                  }}>
                  <TableRow variant='body2'>
                    <StyledTableHeadingCell key='Name'>Name</StyledTableHeadingCell>
                    <StyledTableHeadingCell key='Description'>Description</StyledTableHeadingCell>
                    <StyledTableHeadingCell key='Date Created'>Date Created</StyledTableHeadingCell>
                    <StyledTableHeadingCell key='Document Type'>
                      Document Type
                    </StyledTableHeadingCell>
                    <StyledTableHeadingCell key='Download'></StyledTableHeadingCell>
                  </TableRow>
                </TableHead>
                {isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{ p: 0 }}>
                        <StyledLinearProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {!isLoading ? (
                  <TableBody>
                    <TableRow
                      key='Summary of tax code'
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <StyledTableBodyCell>
                        <Typography
                          // TODO: CHANGE THIS.
                          onClick={() => { }}
                          sx={{
                            display: 'inline-block',
                            fontSize: '0.875rem',
                            color: theme.palette.functional.tableLink,
                            textDecoration: 'underline',
                            //cursor: "pointer",
                          }}>
                          {`Summary of tax code_${todaysDate}`}
                        </Typography>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>View summary of VAT by tax code</StyledTableBodyCell>
                      <StyledTableBodyCell>{currentDate}</StyledTableBodyCell>
                      <StyledTableBodyCell>XLSX</StyledTableBodyCell>
                      <StyledTableBodyCell sx={{ display: 'flex', alignItems: 'center' }}>
                        <DownloadLink
                          filename={`Summary of tax code_${todaysDate}`}
                          urlObject={summaryDownload}
                          toastSetter={setToast}>
                          <DownloadIcon
                            sx={{
                              color: theme.palette.primaryColors[200],
                            }}
                          />
                        </DownloadLink>
                      </StyledTableBodyCell>
                    </TableRow>

                    <TableRow
                      key='Adjustment export'
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <StyledTableBodyCell>
                        <Typography
                          // TODO: CHANGE THIS.
                          onClick={() => { }}
                          sx={{
                            display: 'inline-block',
                            fontSize: '0.875rem',
                            color: theme.palette.functional.tableLink,
                            textDecoration: 'underline',
                            //cursor: "pointer",
                          }}>
                          Adjustment export
                        </Typography>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        Export of transaction adjustments for the obligation period
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>{currentDate}</StyledTableBodyCell>
                      <StyledTableBodyCell>XLSX</StyledTableBodyCell>
                      <StyledTableBodyCell sx={{ display: 'flex', alignItems: 'center' }}>
                        <DownloadLink
                          filename='Adjustment export'
                          urlObject={adjustmentDownload}
                          toastSetter={setToast}>
                          <DownloadIcon
                            sx={{
                              color: theme.palette.primaryColors[200],
                            }}
                          />
                        </DownloadLink>
                      </StyledTableBodyCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      sx={{
                        height: '3rem',
                        border: `1px solid ${theme.palette.neutralColors.black[200]}`,
                      }}>
                      <TableCell
                        colSpan={5}
                        sx={{ textAlign: 'center' }}>
                        Fetching data...
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </Box>
          </TableContainer>
        </WhiteCard>

        {!isLodged ? (
          <WhiteCard>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'>
              <Typography
                sx={{
                  color: theme.palette.random[300],
                  fontWeight: 700,
                  fontSize: '1.125rem',
                }}>
                Acknowledgment of Lodgement
              </Typography>
              <BtnFilled
                btnTxt='Acknowledge'
                size='medium'
                onClick={() => setIsLodgingModalOpen(true)}
              />
            </Stack>
          </WhiteCard>
        ) : (
          <ListNotes />
        )}
        {isLodgingModalOpen && (
          <LodgingModal
            closePopupHandler={() => {
              setIsLodgingModalOpen(false);
            }}
            handleResolveStatus={(type, message) => {
              setToast({ type, message });
              if (type === 'success') setIsLodged(true);
            }}
          />
        )}
        {toast?.message && (
          <Toast
            closeAlertHandler={() => {
              setToast({
                type: '',
                message: '',
              });
            }}
            toast={toast}
          />
        )}
      </Stack>
    )
  );
};
