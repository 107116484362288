import { SvgIcon } from '@mui/material';

export const InReviewObligationsIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.0551994 10.0005H2.0702C2.31249 11.6652 3.14607 13.1871 4.41843 14.2877C5.69079 15.3883 7.31688 15.994 8.9992 15.994C10.6815 15.994 12.3076 15.3883 13.58 14.2877C14.8523 13.1871 15.6859 11.6652 15.9282 10.0005H17.9442C17.6977 12.2003 16.6491 14.2322 14.999 15.7077C13.3488 17.1833 11.2128 17.999 8.9992 17.999C6.78557 17.999 4.64956 17.1833 2.99942 15.7077C1.34928 14.2322 0.300742 12.2003 0.0541992 10.0005H0.0551994ZM0.0551994 8.00047C0.301742 5.80061 1.35028 3.76874 3.00042 2.29321C4.65056 0.817672 6.78657 0.00195312 9.0002 0.00195312C11.2138 0.00195312 13.3498 0.817672 15 2.29321C16.6501 3.76874 17.6987 5.80061 17.9452 8.00047H15.9302C15.6879 6.33569 14.8543 4.81379 13.582 3.7132C12.3096 2.61261 10.6835 2.00691 9.0012 2.00691C7.31888 2.00691 5.69279 2.61261 4.42043 3.7132C3.14807 4.81379 2.31449 6.33569 2.0722 8.00047H0.0551994Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    )
};