import { SvgIcon } from '@mui/material';

export const CheckFilledIcon = ({ props }) => {
    return (
        <SvgIcon {...props}>
            <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 20.9612C4.477 20.9612 0 16.4842 0 10.9612C0 5.43824 4.477 0.961243 10 0.961243C15.523 0.961243 20 5.43824 20 10.9612C20 16.4842 15.523 20.9612 10 20.9612ZM9.003 14.9612L16.073 7.89024L14.659 6.47624L9.003 12.1332L6.174 9.30424L4.76 10.7182L9.003 14.9612Z" fill="#D04A02" />
            </svg>
        </SvgIcon>
    )
}
