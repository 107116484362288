import { createContext, useContext, useState } from 'react';

const ReconciliationContext = createContext();

export const useReconciliation = () => {
  return useContext(ReconciliationContext);
};

export const ReconciliationContextProvider = ({ children }) => {
  const [modalToast, setModalToast] = useState({ type: '', message: '' });
  const [modalNo, setModalNo] = useState(-1);

  return (
    <ReconciliationContext.Provider
      value={{
        modalToast,
        setModalToast,
        modalNo,
        setModalNo,
      }}>
      {children}
    </ReconciliationContext.Provider>
  );
};
