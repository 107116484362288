import { SvgIcon } from '@mui/material';

export const LeftArrowIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1727 12.9618L9.34375 9.90725L10.7577 8.37891L15.0007 12.9618L10.7577 17.5447L9.34375 16.0163L12.1727 12.9618Z" fill="#252C32" />
            </svg>

        </SvgIcon>
    )
};
