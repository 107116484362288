import { createGlobalStyle } from 'styled-components';
import { palette } from './mui-theme/palette';

const GlobalStyle = createGlobalStyle` 
  
  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background-color: ${palette.secondaryColors[100]};
    // color: ${palette.random[600]};
    font-family: 'Helvetica Neue Regular', sans-serif;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }

  @font-face {
    font-family: 'Helvetica Neue Bold';
    src: url(../assets/fonts/HelveticaNeueBold.ttf) format(ttf);
  }

  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url(../assets/fonts/HelveticaNeueRegular.ttf) format(ttf);
  }

  //Scrollbar styles below 

  *::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem
  }

  *::-webkit-scrollbar-track {
    // background:${palette.primaryColors[200]};
    background:${palette.baseColors.white};
  }

  *::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius:20px
  }

  *::-webkit-scrollbar-thumb:hover {
    background: grey;
  }

${'' /*   .rs-input-group {
    top: 0;
    padding: 0;
    border: 2px solid #CCCFD8 !important;
  } */}

`;

export { GlobalStyle };