import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import { CaretDownIcon } from '@radix-ui/react-icons';
import TextTag from '../../../../components/common/Text Tags/TextTag';
import { styled } from '@mui/material/styles';
import { BtnFilled } from '../../../../components/common/Buttons/BtnFilled';

const StyledAccordion = styled(Accordion)(({ theme, selected }) => ({
  marginTop: '15px',
  backgroundColor: selected ? theme.palette.primaryColors[100] : 'inherit',
  '&:before': {
    backgroundColor: 'transparent',
  },
  border: `1px solid ${theme.palette.neutralColors.black[200]}`,
  borderRadius: '8px',
  boxShadow: 'none',
}));

export const UnresolvedExceptions = ({
  selected,
  setSelected,
  setRelatedFields,
  exceptions,
  handleResolveException,
  getRelatedFields,
  resolveExceptionsArray = [],
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelect = (panel, issue, ruleId) => {
    getRelatedFields(ruleId);
    setSelected(selected === panel ? null : panel);
  };

  return (
    <div>
      {exceptions.map((issue, index) => (
        <StyledAccordion
          mt={2}
          disabled={
            resolveExceptionsArray?.length > 0 ? !resolveExceptionsArray?.includes(issue.id) : false
          }
          key={issue.id}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          selected={selected === `panel${index}`}
          onClick={() => handleSelect(`panel${index}`, issue, issue.rule_id)}>
          <AccordionSummary
            expandIcon={<CaretDownIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}>
            <Box
              display='flex'
              alignItems='center'>
              <TextTag
                text={issue?.group_name}
                variant='grey'
                size='m'
                style={{ padding: '4px 8px', borderRadius: '2px' }}
              />
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 700,
                  marginLeft: '8px',
                }}>
                {issue?.rule_description}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>{issue?.reason}</Typography>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </div>
  );
};
